import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AIActionListAkurieApi from '../apis/AIActionListAkurieApi.js';
import * as TaxEstimatesApi from '../apis/TaxEstimatesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  ScreenContainer,
  Surface,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  Keyboard,
  Text,
  View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const BusinessIdeaSetup21Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [Calculation_fields, setCalculation_fields] = React.useState(false);
  const [SE_estimated, setSE_estimated] = React.useState('');
  const [Taxable_income, setTaxable_income] = React.useState('');
  const [Total_taxes, setTotal_taxes] = React.useState('');
  const [afterTaxNumber, setAfterTaxNumber] = React.useState('');
  const [errMsg, setErrMsg] = React.useState('');
  const [estimated_taxWarning_view, setEstimated_taxWarning_view] =
    React.useState(false);
  const [fed_estimate, setFed_estimate] = React.useState('');
  const [filingStatusPickerValue, setFilingStatusPickerValue] =
    React.useState('');
  const [grossRevenue, setGrossRevenue] = React.useState('');
  const [ownershipPercentage, setOwnershipPercentage] = React.useState('');
  const [postTaxNumValue, setPostTaxNumValue] = React.useState('');
  const [state_estimate, setState_estimate] = React.useState('');
  const [tax_entity_type, setTax_entity_type] = React.useState('');
  const [tax_info, setTax_info] = React.useState(false);
  const [tax_rate, setTax_rate] = React.useState('');
  const [taxes_for_state, setTaxes_for_state] = React.useState('');
  const isEqualTo = (thingOne, thingTwo) => {
    return thingOne === thingTwo;
  };
  const aIActionListAkuriePrefRevenuePOST =
    AIActionListAkurieApi.usePrefRevenuePOST();
  const taxEstimatesPersonalLLCTaxesPOST =
    TaxEstimatesApi.usePersonalLLCTaxesPOST();
  const taxEstimatesPersonalTaxesPOST = TaxEstimatesApi.usePersonalTaxesPOST();

  return (
    <ScreenContainer
      hasBottomSafeArea={false}
      hasSafeArea={false}
      hasTopSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors['Akurie Dark Blue'] },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'cover'}
        source={Images.Asset6}
        style={StyleSheet.applyWidth(
          {
            alignItems: [
              { minWidth: Breakpoints.BigScreen, value: 'center' },
              { minWidth: Breakpoints.Laptop, value: 'center' },
              { minWidth: Breakpoints.Tablet, value: 'center' },
            ],
            backgroundColor: {
              minWidth: Breakpoints.Laptop,
              value: theme.colors['Akurie Dark Blue'],
            },
            flexWrap: 'wrap',
            height: '100%',
            justifyContent: [
              { minWidth: Breakpoints.BigScreen, value: 'center' },
              { minWidth: Breakpoints.Laptop, value: 'center' },
              { minWidth: Breakpoints.Tablet, value: 'center' },
              { minWidth: Breakpoints.Mobile, value: 'center' },
            ],
            paddingBottom: '10%',
            paddingLeft: [
              { minWidth: Breakpoints.Mobile, value: '5%' },
              { minWidth: Breakpoints.Tablet, value: '15%' },
            ],
            paddingRight: [
              { minWidth: Breakpoints.Mobile, value: '5%' },
              { minWidth: Breakpoints.Tablet, value: '15%' },
            ],
            paddingTop: [
              { minWidth: Breakpoints.Mobile, value: '10%' },
              { minWidth: Breakpoints.Laptop, value: '5%' },
            ],
            width: '100%',
          },
          dimensions.width
        )}
      >
        <KeyboardAwareScrollView
          extraHeight={300}
          keyboardShouldPersistTaps={'always'}
          showsVerticalScrollIndicator={true}
          style={StyleSheet.applyWidth(
            { height: '100%', width: '100%' },
            dimensions.width
          )}
        >
          {/* View 2 */}
          <>
            {!(dimensions.width >= Breakpoints.Laptop) ? null : (
              <View
                style={StyleSheet.applyWidth(
                  { width: { minWidth: Breakpoints.Laptop, value: '80%' } },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'contain'}
                  source={Images.MainLogoUpdate1u2}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'],
                      {
                        height: { minWidth: Breakpoints.Laptop, value: 50 },
                        width: { minWidth: Breakpoints.Laptop, value: '30%' },
                      }
                    ),
                    dimensions.width
                  )}
                />
              </View>
            )}
          </>
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: [
                  { minWidth: Breakpoints.Tablet, value: 'center' },
                  { minWidth: Breakpoints.Mobile, value: 'center' },
                ],
                backgroundColor: theme.colors['Background'],
                borderRadius: 12,
                height: '100%',
                justifyContent: [
                  { minWidth: Breakpoints.Tablet, value: 'center' },
                  { minWidth: Breakpoints.Mobile, value: 'center' },
                ],
                marginTop: 20,
                padding: 20,
                paddingBottom: { minWidth: Breakpoints.Tablet, value: 40 },
                width: { minWidth: Breakpoints.Laptop, value: '100%' },
              },
              dimensions.width
            )}
          >
            {/* Unique Card Frame */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexGrow: 1,
                  flexShrink: 0,
                  height: [
                    { minWidth: Breakpoints.Tablet, value: '70%' },
                    { minWidth: Breakpoints.BigScreen, value: '70%' },
                    { minWidth: Breakpoints.Mobile, value: '100%' },
                  ],
                  marginBottom: { minWidth: Breakpoints.Tablet, value: 30 },
                  marginTop: { minWidth: Breakpoints.Tablet, value: 30 },
                  opacity: { minWidth: Breakpoints.Laptop, value: 0.95 },
                  width: [
                    { minWidth: Breakpoints.Tablet, value: '70%' },
                    { minWidth: Breakpoints.Mobile, value: '100%' },
                    { minWidth: Breakpoints.Laptop, value: '50%' },
                  ],
                },
                dimensions.width
              )}
            >
              {/* Content */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 1,
                    flexShrink: 0,
                    justifyContent: 'center',
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                {/* Text Frame  */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexGrow: 0,
                      flexShrink: 0,
                      paddingBottom: 12,
                      paddingLeft: 5,
                      paddingRight: 5,
                      paddingTop: 5,
                    },
                    dimensions.width
                  )}
                >
                  {/* Step Count */}
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Akurie Primary'],
                        fontFamily: [
                          {
                            minWidth: Breakpoints.Laptop,
                            value: 'Inter_400Regular',
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: 'Inter_500Medium',
                          },
                        ],
                        fontSize: [
                          { minWidth: Breakpoints.Laptop, value: 13 },
                          { minWidth: Breakpoints.Mobile, value: 14 },
                        ],
                        marginBottom: 15,
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {'Step 4 of 5'}
                  </Text>
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        paddingBottom: 10,
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 10,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Akurie Primary'],
                            fontFamily: 'Inter_600SemiBold',
                            fontSize: 16,
                            textAlign: 'left',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'About The Money'}
                    </Text>
                  </View>
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        marginBottom: 5,
                        marginTop: 5,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { width: '100%' },
                        dimensions.width
                      )}
                    >
                      {/* Disclaimer */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors.strong,
                            fontFamily: [
                              {
                                minWidth: Breakpoints.Laptop,
                                value: 'Inter_400Regular',
                              },
                              {
                                minWidth: Breakpoints.Mobile,
                                value: 'Inter_600SemiBold',
                              },
                            ],
                            fontSize: [
                              { minWidth: Breakpoints.Laptop, value: 13 },
                              { minWidth: Breakpoints.Mobile, value: 14 },
                            ],
                            marginBottom: 10,
                            marginTop: 15,
                            paddingLeft: 15,
                            paddingRight: 15,
                            textAlign: 'left',
                          },
                          dimensions.width
                        )}
                      >
                        {
                          'How much money would you like your business to make a year after taxes?'
                        }
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              { color: theme.colors['Error'] }
                            ),
                            dimensions.width
                          )}
                        >
                          {'*'}
                        </Text>
                      </Text>
                    </View>

                    <Surface
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.SurfaceStyles(theme)['Surface'],
                          {
                            alignItems: 'center',
                            backgroundColor: theme.colors['Surface'],
                            borderRadius: 9,
                            flexDirection: 'row',
                            justifyContent: 'center',
                            marginBottom: 10,
                            marginTop: 5,
                            minHeight: 60,
                            paddingBottom: 10,
                            paddingTop: 10,
                            width: '100%',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {/* postTaxNum */}
                      <TextInput
                        autoCapitalize={'none'}
                        changeTextDelay={500}
                        keyboardType={'numbers-and-punctuation'}
                        onChangeText={newPostTaxNumValue => {
                          try {
                            setPostTaxNumValue(newPostTaxNumValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        onSubmitEditing={() => {
                          try {
                            Keyboard.dismiss();
                            setTax_info(true);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        placeholder={'ex.$120,000'}
                        placeholderTextColor={theme.colors['Light']}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextInputStyles(theme)['Text Input'],
                            {
                              color: theme.colors['Medium'],
                              minHeight: 50,
                              width: '60%',
                            }
                          ),
                          dimensions.width
                        )}
                        value={postTaxNumValue}
                      />
                      <Button
                        onPress={() => {
                          const handler = async () => {
                            try {
                              setAfterTaxNumber(postTaxNumValue);
                              const revResponse = (
                                await aIActionListAkuriePrefRevenuePOST.mutateAsync(
                                  {
                                    afterTaxNumber: postTaxNumValue,
                                    userID: Constants['ak_userID'],
                                  }
                                )
                              )?.json;
                              const error = revResponse?.message;
                              setErrMsg(error);
                              if (error) {
                                return;
                              }
                              setTax_info(true);
                              Keyboard.dismiss();
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ButtonStyles(theme)['Button'],
                            { borderRadius: 9, minHeight: 50 }
                          ),
                          dimensions.width
                        )}
                        title={'Calculate'}
                      />
                    </Surface>
                    {/* View 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'flex-start', width: '100%' },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Error'],
                              fontFamily: 'Inter_400Regular',
                              textDecorationLine: 'underline',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {errMsg}
                      </Text>
                    </View>
                  </View>
                  {/* View 3 */}
                  <>
                    {!tax_info ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            paddingBottom: 5,
                            paddingLeft: 15,
                            paddingRight: 15,
                            paddingTop: 10,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: theme.colors['Akurie Primary'],
                                fontFamily: 'Inter_600SemiBold',
                                fontSize: 16,
                                textAlign: 'left',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Taxes & Revenue'}
                        </Text>

                        <AIActionListAkurieApi.FetchRevenueNumbersGET
                          userID={Constants['ak_userID']}
                        >
                          {({
                            loading,
                            error,
                            data,
                            refetchRevenueNumbers,
                          }) => {
                            const fetchData = data?.json;
                            if (loading) {
                              return <ActivityIndicator />;
                            }

                            if (
                              error ||
                              data?.status < 200 ||
                              data?.status >= 300
                            ) {
                              return <ActivityIndicator />;
                            }

                            return (
                              <>
                                {/* Text 2 */}
                                <Text
                                  accessible={true}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      {
                                        fontFamily: 'Inter_500Medium',
                                        marginTop: 5,
                                        textAlign: 'left',
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'State Of '}
                                  {fetchData?.result1?.afterTaxNumbers?.State}
                                  {'\n\nBefore Taxes: $'}
                                  {fetchData?.result1?.afterTaxNumbers &&
                                    fetchData?.result1?.afterTaxNumbers[
                                      'Estimated Gross Revenue Required'
                                    ]}
                                  {'\nAfter Taxes: $'}
                                  {fetchData?.result1?.afterTaxNumbers?.Net}
                                  {'\n'}
                                </Text>
                                {/* View 5 */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      marginTop: 10,
                                      paddingBottom: 5,
                                      paddingRight: 15,
                                      width: '100%',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Text 3 */}
                                  <Text
                                    accessible={true}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text'],
                                        {
                                          fontFamily: 'Inter_400Regular',
                                          fontSize: 12,
                                          marginBottom: 10,
                                          marginTop: 5,
                                          textAlign: 'left',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {'Note - (assuming a 40-hour workweek)'}
                                  </Text>
                                  {/* Text 2 */}
                                  <Text
                                    accessible={true}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text'],
                                        {
                                          fontFamily: 'Inter_500Medium',
                                          lineHeight: 18,
                                          marginTop: 5,
                                          textAlign: 'left',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {'Total: $'}
                                    {fetchData?.result1?.afterTaxNumbers?.Net}
                                    {'\n\nMonthly: $'}
                                    {
                                      fetchData?.result2?.revenueNumbers
                                        ?.Monthly
                                    }
                                    {'\nWeekly:  $'}
                                    {fetchData?.result2?.revenueNumbers?.Weekly}
                                    {'\nDaily: $'}
                                    {fetchData?.result2?.revenueNumbers?.Daily}
                                    {'\nHourly: $'}
                                    {fetchData?.result2?.revenueNumbers?.Hourly}
                                    {'\n'}
                                  </Text>
                                  {/* Text 2 */}
                                  <Text
                                    accessible={true}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text'],
                                        {
                                          color: theme.colors['Strong'],
                                          fontFamily: 'Inter_400Regular',
                                          fontSize: 14,
                                          marginTop: 20,
                                          textAlign: 'left',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {
                                      'All data is based on a business structured as an LLC - Limited Liability Company and a tax filing status of single.'
                                    }
                                  </Text>
                                </View>
                              </>
                            );
                          }}
                        </AIActionListAkurieApi.FetchRevenueNumbersGET>
                      </View>
                    )}
                  </>
                  {/* View 4 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', paddingTop: 5, width: '100%' },
                      dimensions.width
                    )}
                  >
                    {/* View 3 */}
                    <>
                      {!tax_info ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'row',
                              justifyContent: 'space-evenly',
                              marginTop: 10,
                              paddingLeft: 15,
                              paddingRight: 15,
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        >
                          {/* Button 2 */}
                          <Button
                            onPress={() => {
                              try {
                                navigation.navigate(
                                  'BusinessIdeaSetup3Screen',
                                  {
                                    bus_name:
                                      props.route?.params?.bus_name ?? '',
                                    entityType:
                                      props.route?.params?.entityType ?? null,
                                  }
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ButtonStyles(theme)['Button'],
                                {
                                  backgroundColor: theme.colors['Akurie green'],
                                  borderRadius: 9,
                                  color: theme.colors['Akurie Dark Blue'],
                                  marginTop: 5,
                                  minHeight: 50,
                                  width: '70%',
                                }
                              ),
                              dimensions.width
                            )}
                            title={'View Next Steps'}
                          />
                        </View>
                      )}
                    </>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </KeyboardAwareScrollView>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(BusinessIdeaSetup21Screen);
