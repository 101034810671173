import React from 'react';
import { View, ActivityIndicator } from 'react-native';
import * as SplashScreen from 'expo-splash-screen';
import AsyncStorage from '@react-native-async-storage/async-storage';

export const DeviceVariables = {
  AKE_REVCAT_CURR_CUS: '',
  SKYE_AUTHORIZATION_HEADER: null,
  Session_ID: '',
  pushToken: null,
  __env__: 'Production',
};
export const AppVariables = {
  AppBG:
    'https://apps-draftbit-com.s3.amazonaws.com/apps/oh4dO59b/assets/sv6oc_I0zRNQY6tvqzAPL',
  ProfilePicture: 'some ProfilePicture',
  Tags: ['some Tag'],
  ak_akuriecardApply: '',
  ak_bankdataPresent: '',
  ak_businessID: null,
  ak_businessName: null,
  ak_businessState: null,
  ak_businessStatus: 'Existing',
  ak_entityType: '',
  ak_errorMessage: '',
  ak_isLoading: '',
  ak_networkingConsent: '',
  ak_subscriptionType: '',
  ak_supportedEntites: ['C Corporation', 'Limited Liability Company - LLC'],
  ak_supportedStates: [
    'Arkansas',
    'California',
    'Delaware',
    'Florida',
    'Georgia',
    'Illinois',
    'Indiana',
    'Louisiana',
    'Mississippi',
    'Montana',
    'Nevada',
    'New York',
    'Ohio',
    'South Carolina',
    'Texas',
    'Wyoming',
  ],
  ak_userID: null,
  ak_userTitle: '',
  ak_uuid: '',
  aku_supported_entites: ['C Corporation', 'Limited Liability Company - LLC'],
  aku_supported_states_vari: [
    'Arkansas',
    'California',
    'Delaware',
    'Florida',
    'Georgia',
    'Illinois',
    'Indiana',
    'Louisiana',
    'Mississippi',
    'Montana',
    'Nevada',
    'New York',
    'Ohio',
    'South Carolina',
    'Texas',
    'Wyoming',
  ],
  audit_reports: 'Locked',
  audit_reports2: 'Locked',
  businessIdea: '',
  businessStatus: '',
  business_action_plan: 'Locked',
  business_action_plan2: 'Locked',
  business_conducting_type_vari: '',
  business_docs_filings: 'Locked',
  business_selector_boxview: '',
  card_apply: false,
  conducting_business_type: ['Domestic', 'Foreign'],
  contactInfo: '',
  link: '',
  my_website: 'Locked',
  ownershipInfo: '',
  registeredagentInfo: '',
  resource_center_full: 'Locked',
  subActive: false,
  the_financials: 'Locked',
  the_financials2: 'Locked',
  welcomeMessage: false,
};
const GlobalVariableContext = React.createContext();
const GlobalVariableUpdater = React.createContext();
const keySuffix = '';

// Attempt to parse a string as JSON. If the parse fails, return the string as-is.
// This is necessary to account for variables which are already present in local
// storage, but were not stored in JSON syntax (e.g. 'hello' instead of '"hello"').
function tryParseJson(str) {
  try {
    return JSON.parse(str);
  } catch {
    return str;
  }
}

class GlobalVariable {
  /**
   *  Filters an object of key-value pairs for those that should be
   *  persisted to storage, and persists them.
   *
   *  @param values Record<string, string>
   */
  static async syncToLocalStorage(values) {
    const update = Object.entries(values)
      .filter(([key]) => key in DeviceVariables)
      .map(([key, value]) => [key + keySuffix, JSON.stringify(value)]);

    if (update.length > 0) {
      await AsyncStorage.multiSet(update);
    }

    return update;
  }

  static async loadLocalStorage() {
    const keys = Object.keys(DeviceVariables);
    const entries = await AsyncStorage.multiGet(
      keySuffix ? keys.map(k => k + keySuffix) : keys
    );

    // If values isn't set, use the default. These will be written back to
    // storage on the next render.
    const withDefaults = entries.map(([key_, value]) => {
      // Keys only have the suffix appended in storage; strip the key
      // after they are retrieved
      const key = keySuffix ? key_.replace(keySuffix, '') : key_;
      return [key, value ? tryParseJson(value) : DeviceVariables[key]];
    });

    return Object.fromEntries(withDefaults);
  }
}

class State {
  static defaultValues = {
    ...AppVariables,
    ...DeviceVariables,
  };

  static reducer(state, { type, payload }) {
    switch (type) {
      case 'RESET':
        return { values: State.defaultValues, __loaded: true };
      case 'LOAD_FROM_ASYNC_STORAGE':
        return { values: { ...state.values, ...payload }, __loaded: true };
      case 'UPDATE':
        return state.__loaded
          ? {
              ...state,
              values: {
                ...state.values,
                [payload.key]: payload.value,
              },
            }
          : state;
      default:
        return state;
    }
  }

  static initialState = {
    __loaded: false,
    values: State.defaultValues,
  };
}

export function GlobalVariableProvider({ children }) {
  const [state, dispatch] = React.useReducer(State.reducer, State.initialState);

  React.useEffect(() => {
    async function prepare() {
      await SplashScreen.preventAutoHideAsync();
    }

    prepare();
  }, []);

  // This effect runs on mount to overwrite the default value of any
  // key that has a local value.
  React.useEffect(() => {
    async function initialStorageLoader() {
      try {
        const payload = await GlobalVariable.loadLocalStorage();
        if (
          payload?.__env__ &&
          DeviceVariables.__env__ &&
          payload.__env__ !== DeviceVariables.__env__
        ) {
          console.log(
            `Publication Environment changed from ${payload.__env__} to ${DeviceVariables.__env__}. Refreshing variables`
          );
          dispatch({
            type: 'LOAD_FROM_ASYNC_STORAGE',
            payload: DeviceVariables,
          });
        } else {
          dispatch({ type: 'LOAD_FROM_ASYNC_STORAGE', payload });
        }
      } catch (err) {
        console.error(err);
      }
    }
    initialStorageLoader();
  }, []);

  // This effect runs on every state update after the initial load. Gives us
  // best of both worlds: React state updates sync, but current state made
  // durable next async tick.
  React.useEffect(() => {
    async function syncToAsyncStorage() {
      try {
        await GlobalVariable.syncToLocalStorage(state.values);
      } catch (err) {
        console.error(err);
      }
    }
    if (state.__loaded) {
      syncToAsyncStorage();
    }
  }, [state]);

  const onLayoutRootView = React.useCallback(async () => {
    if (state.__loaded) {
      await SplashScreen.hideAsync();
    }
  }, [state.__loaded]);

  // We won't want an app to read a default state when there might be one
  // incoming from storage.
  if (!state.__loaded) {
    return null;
  }

  return (
    <GlobalVariableUpdater.Provider
      value={dispatch}
      onLayout={onLayoutRootView}
    >
      <GlobalVariableContext.Provider value={state.values}>
        {children}
      </GlobalVariableContext.Provider>
    </GlobalVariableUpdater.Provider>
  );
}

// Hooks
export function useSetValue() {
  const dispatch = React.useContext(GlobalVariableUpdater);
  return ({ key, value }) => {
    dispatch({ type: 'UPDATE', payload: { key, value } });
    return value;
  };
}

export function useValues() {
  return React.useContext(GlobalVariableContext);
}
