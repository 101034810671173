import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AuthAkurieApi from '../apis/AuthAkurieApi.js';
import * as Logs$SupportAkurieApi from '../apis/Logs$SupportAkurieApi.js';
import DesktopHeader13024SignedInBlock from '../components/DesktopHeader13024SignedInBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Circle,
  Icon,
  NumberInput,
  Picker,
  ScreenContainer,
  Surface,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ImageBackground, StatusBar, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const AskMyAssistantScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [ServiceTypepicker, setServiceTypepicker] = React.useState('');
  const [Submission_message, setSubmission_message] = React.useState(false);
  const [callOptionPickerValue, setCallOptionPickerValue] = React.useState('');
  const [call_option, setCall_option] = React.useState(['Yes', 'No']);
  const [image_upload, setImage_upload] = React.useState('');
  const [issuePickerValue, setIssuePickerValue] = React.useState('');
  const [issue_options, setIssue_options] = React.useState([
    'App Crashing / Freezing',
    'Information Not Visible',
    'Link / Button Not Working',
    'Incorrect Data',
    'Other',
  ]);
  const [notesInputValue, setNotesInputValue] = React.useState('');
  const [numberInputValue, setNumberInputValue] = React.useState('');
  const [numberInputValue2, setNumberInputValue2] = React.useState('');
  const [phoneNumberInputValue, setPhoneNumberInputValue] = React.useState('');
  const [pickerValue, setPickerValue] = React.useState('');
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const logs$SupportAkurieSupportServicesPOST =
    Logs$SupportAkurieApi.useSupportServicesPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const authResponse = (await AuthAkurieApi.meGET(Constants))?.json;
        const tokenExpire = authResponse?.message;
        if (tokenExpire) {
          navigation.navigate('SessionExpiredScreen');
        }
        if (tokenExpire) {
          return;
        }
        StatusBar.setBarStyle('dark-content');
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer hasTopSafeArea={true}>
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <DesktopHeader13024SignedInBlock />
        )}
      </>
      {/* Navigation Frame */}
      <View
        style={StyleSheet.applyWidth(
          {
            borderBottomWidth: 2,
            borderColor: theme.colors['Akurie green'],
            height: 120,
          },
          dimensions.width
        )}
      >
        <ImageBackground
          resizeMode={'cover'}
          source={Images.Asset13}
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              backgroundColor: theme.colors['Akurie Dark Blue'],
              flexDirection: 'row',
              height: '100%',
              paddingLeft: { minWidth: Breakpoints.Laptop, value: '20%' },
              paddingRight: { minWidth: Breakpoints.Laptop, value: '20%' },
            },
            dimensions.width
          )}
        >
          {/* Left Side Frame */}
          <View
            style={StyleSheet.applyWidth(
              { paddingLeft: 9, paddingRight: 9 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  setSubmission_message(false);
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Icon Flex Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                {/* Icon  */}
                <Icon
                  color={theme.colors['Background']}
                  name={'Ionicons/arrow-back-outline'}
                  size={24}
                />
              </View>
            </Touchable>
          </View>
          {/* Middle Frame */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexGrow: 1,
                flexShrink: 0,
                justifyContent: 'center',
              },
              dimensions.width
            )}
          >
            {/* Text Frame  */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: 'rgba(8, 31, 61, 0.5)',
                  flexGrow: 0,
                  flexShrink: 0,
                  paddingBottom: 12,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 12,
                },
                dimensions.width
              )}
            >
              {/* Text 12 18 Regular */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Akurie green'],
                    fontFamily: 'Inter_400Regular',
                    fontSize: 22,
                    lineHeight: 18,
                    paddingTop: 8,
                  },
                  dimensions.width
                )}
              >
                {'Ask My Assistant'}
              </Text>
            </View>
          </View>
          {/* RIght Side Frame */}
          <View
            style={StyleSheet.applyWidth(
              { paddingLeft: 9, paddingRight: 9 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('RootNavigator');
                  setSubmission_message(false);
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Icon Flex Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                {/* Icon  */}
                <Icon
                  color={theme.colors['Background']}
                  name={'AntDesign/home'}
                  size={24}
                />
              </View>
            </Touchable>
          </View>
        </ImageBackground>
      </View>

      <KeyboardAwareScrollView
        extraHeight={500}
        keyboardShouldPersistTaps={'never'}
        showsVerticalScrollIndicator={true}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: { minWidth: Breakpoints.Tablet, value: 'center' },
              height: '100%',
              justifyContent: 'center',
              paddingBottom: 10,
              paddingLeft: [
                { minWidth: Breakpoints.Mobile, value: 35 },
                { minWidth: Breakpoints.Laptop, value: '15%' },
                { minWidth: Breakpoints.BigScreen, value: '20%' },
              ],
              paddingRight: [
                { minWidth: Breakpoints.Mobile, value: 35 },
                { minWidth: Breakpoints.Laptop, value: '15%' },
                { minWidth: Breakpoints.BigScreen, value: '20%' },
              ],
              paddingTop: [
                { minWidth: Breakpoints.Mobile, value: 10 },
                { minWidth: Breakpoints.Laptop, value: 25 },
                { minWidth: Breakpoints.Desktop, value: 50 },
                { minWidth: Breakpoints.BigScreen, value: 100 },
              ],
            },
            dimensions.width
          )}
        >
          {/* Top */}
          <View
            style={StyleSheet.applyWidth(
              {
                marginTop: 40,
                width: [
                  { minWidth: Breakpoints.Tablet, value: '70%' },
                  { minWidth: Breakpoints.Laptop, value: '50%' },
                ],
              },
              dimensions.width
            )}
          >
            {/* Content Wrapper */}
            <View>
              {/* Issue Type */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Custom #101010'],
                    fontFamily: 'Inter_500Medium',
                    fontSize: 14,
                    marginTop: 10,
                  },
                  dimensions.width
                )}
              >
                {'Service Type:'}
              </Text>
              {/* Required */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Custom #101010'],
                    fontFamily: 'Inter_200ExtraLight',
                    fontSize: 12,
                    marginBottom: 3,
                  },
                  dimensions.width
                )}
              >
                {'Required*'}
              </Text>
              {/* Issue */}
              <Surface
                elevation={3}
                style={StyleSheet.applyWidth(
                  { borderRadius: 9, marginBottom: 8 },
                  dimensions.width
                )}
              >
                {/* Service Type */}
                <Picker
                  iconColor={theme.colors['Akurie Primary']}
                  iconSize={24}
                  leftIconMode={'inset'}
                  onValueChange={newServiceTypeValue => {
                    try {
                      setServiceTypepicker(newServiceTypeValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  options={[
                    'Document Filing',
                    'Goals & Action Plans',
                    'General Consulting',
                    'Other',
                  ]}
                  placeholder={'Select an option'}
                  rightIconName={'AntDesign/downcircle'}
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: theme.colors['Divider'],
                      color: theme.colors['Strong'],
                      fontFamily: 'Inter_400Regular',
                      paddingRight: 8,
                    },
                    dimensions.width
                  )}
                  type={'solid'}
                  value={ServiceTypepicker}
                />
              </Surface>
              {/* Would you like a call */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Custom #101010'],
                    fontFamily: 'Inter_500Medium',
                    fontSize: 14,
                    marginBottom: 3,
                    paddingTop: 4,
                  },
                  dimensions.width
                )}
              >
                {'Would you like to request a call?'}
              </Text>
              {/* Call Wanted */}
              <Surface
                elevation={3}
                style={StyleSheet.applyWidth(
                  { borderRadius: 9, marginBottom: 12 },
                  dimensions.width
                )}
              >
                {/* Call Option Picker */}
                <Picker
                  iconColor={theme.colors['Akurie Primary']}
                  iconSize={24}
                  leftIconMode={'inset'}
                  onValueChange={newCallOptionPickerValue => {
                    try {
                      setCallOptionPickerValue(newCallOptionPickerValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  options={call_option}
                  placeholder={'Select an option'}
                  rightIconName={'AntDesign/downcircle'}
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: theme.colors['Divider'],
                      color: theme.colors['Strong'],
                      fontFamily: 'Inter_400Regular',
                      paddingRight: 8,
                    },
                    dimensions.width
                  )}
                  type={'solid'}
                  value={callOptionPickerValue}
                />
              </Surface>
              {/* Phone Number */}
              <>
                {!(callOptionPickerValue === 'Yes') ? null : (
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Custom #101010'],
                        fontFamily: 'Inter_400Regular',
                        fontSize: 14,
                        marginBottom: 3,
                        paddingTop: 4,
                      },
                      dimensions.width
                    )}
                  >
                    {'What number would you like us to call?'}
                  </Text>
                )}
              </>
              {/* Phone Number */}
              <>
                {!(callOptionPickerValue === 'Yes') ? null : (
                  <Surface
                    elevation={3}
                    style={StyleSheet.applyWidth(
                      { borderRadius: 9, marginBottom: 12 },
                      dimensions.width
                    )}
                  >
                    {/* Phone Number Input */}
                    <NumberInput
                      editable={true}
                      keyboardType={'number-pad'}
                      onChangeText={newPhoneNumberInputValue => {
                        try {
                          setPhoneNumberInputValue(newPhoneNumberInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'###-###-####'}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: 1,
                          borderColor: theme.colors['Communial_Icon_Gray'],
                          borderLeftWidth: 1,
                          borderRadius: 8,
                          borderRightWidth: 1,
                          borderTopWidth: 1,
                          minHeight: 60,
                          paddingBottom: 8,
                          paddingLeft: 12,
                          paddingRight: 8,
                          paddingTop: 8,
                        },
                        dimensions.width
                      )}
                      value={phoneNumberInputValue}
                    />
                  </Surface>
                )}
              </>
              {/* Additional Notes */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Custom #101010'],
                    fontFamily: 'Inter_500Medium',
                    fontSize: 14,
                    paddingTop: 4,
                  },
                  dimensions.width
                )}
              >
                {'Short Description'}
              </Text>
              {/* Required */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Custom #101010'],
                    fontFamily: 'Inter_200ExtraLight',
                    fontSize: 12,
                    marginBottom: 3,
                  },
                  dimensions.width
                )}
              >
                {'Required*'}
              </Text>
              {/* Notes */}
              <Surface
                elevation={3}
                style={StyleSheet.applyWidth(
                  { borderRadius: 9, marginBottom: 3, minHeight: 80 },
                  dimensions.width
                )}
              >
                {/* Notes Input */}
                <TextInput
                  editable={true}
                  multiline={true}
                  numberOfLines={2}
                  onChangeText={newNotesInputValue => {
                    try {
                      setNotesInputValue(newNotesInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={
                    'Please provide any information that could be helpful.'
                  }
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: theme.colors['Communial_Icon_Gray'],
                      borderRadius: 8,
                      fontFamily: 'Inter_300Light',
                      height: 80,
                      paddingBottom: 5,
                      paddingLeft: 8,
                      paddingRight: 8,
                      paddingTop: 8,
                    },
                    dimensions.width
                  )}
                  textAlignVertical={'top'}
                  value={notesInputValue}
                />
              </Surface>
              <>
                {!Submission_message ? null : (
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Akurie Primary'],
                          fontFamily: 'Inter_400Regular',
                          marginTop: 10,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Your request has been submitted.'}
                  </Text>
                )}
              </>
              <Button
                onPress={() => {
                  const handler = async () => {
                    try {
                      const requestResult = (
                        await logs$SupportAkurieSupportServicesPOST.mutateAsync(
                          {
                            Notes: notesInputValue,
                            Service_Type: ServiceTypepicker,
                            call_requested: callOptionPickerValue,
                            contact_number: phoneNumberInputValue,
                            user_business_basic_data_id:
                              Constants['ak_businessID'],
                            user_id: Constants['ak_userID'],
                          }
                        )
                      )?.json;
                      const errMsg = requestResult?.message;
                      setGlobalVariableValue({
                        key: 'ak_errorMessage',
                        value: errMsg,
                      });
                      if (errMsg) {
                        return;
                      }
                      setGlobalVariableValue({
                        key: 'ak_errorMessage',
                        value: false,
                      });
                      setServiceTypepicker(false);
                      setCallOptionPickerValue(' ');
                      setPhoneNumberInputValue('');
                      setNotesInputValue('');
                      setSubmission_message(true);
                      navigation.navigate('UserDashboardScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Akurie green'],
                    borderRadius: 12,
                    color: theme.colors['Strong'],
                    fontFamily: 'Inter_400Regular',
                    fontSize: 16,
                    marginBottom: 20,
                    marginTop: 20,
                    minHeight: 50,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
                title={'Submit Contact Request'}
              />
            </View>
          </View>
        </View>
      </KeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(AskMyAssistantScreen);
