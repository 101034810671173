import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AIActionListAkurieApi from '../apis/AIActionListAkurieApi.js';
import * as BusinessDataAkurieApi from '../apis/BusinessDataAkurieApi.js';
import * as Logs$SupportAkurieApi from '../apis/Logs$SupportAkurieApi.js';
import * as Web$AppServicesApi from '../apis/Web$AppServicesApi.js';
import LoadingViewBlock from '../components/LoadingViewBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Checkbox,
  Circle,
  Icon,
  IconButton,
  Picker,
  ScreenContainer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import * as Linking from 'expo-linking';
import { Image, ImageBackground, Keyboard, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const BusinessLLCSetupInfo1Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [Addr1Value, setAddr1Value] = React.useState('');
  const [Addr2Value, setAddr2Value] = React.useState('');
  const [businessPurposeValue, setBusinessPurposeValue] = React.useState('');
  const [businessStateValue, setBusinessStateValue] = React.useState('');
  const [cityValue, setCityValue] = React.useState('');
  const [errorMsg, setErrorMsg] = React.useState('');
  const [llcNameValue, setLlcNameValue] = React.useState('');
  const [membership_disclaimer, setMembership_disclaimer] =
    React.useState(false);
  const [zipCodeValue, setZipCodeValue] = React.useState('');
  const businessDataAkurieBusinessFormationPOST =
    BusinessDataAkurieApi.useBusinessFormationPOST();
  const businessDataAkurieName$ProblemUpdatePOST =
    BusinessDataAkurieApi.useName$ProblemUpdatePOST();
  const aIActionListAkurieCreateProblemSolutionsPOST =
    AIActionListAkurieApi.useCreateProblemSolutionsPOST();
  const aIActionListAkurieGetTargetMarketPOST =
    AIActionListAkurieApi.useGetTargetMarketPOST();
  const aIActionListAkurieGetIndustryPOST =
    AIActionListAkurieApi.useGetIndustryPOST();
  const logs$SupportAkurieBasicActivityPOST =
    Logs$SupportAkurieApi.useBasicActivityPOST();
  const businessDataAkurieCreateIdeaBusinessPOST =
    BusinessDataAkurieApi.useCreateIdeaBusinessPOST();
  const web$AppServicesInitializeWebServicesPOST =
    Web$AppServicesApi.useInitializeWebServicesPOST();
  const aIActionListAkurieBasicStepsSetupPOST =
    AIActionListAkurieApi.useBasicStepsSetupPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setMembership_disclaimer(true);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);
  const businessPurposezGpCOapIRef = React.useRef();

  return (
    <ScreenContainer
      hasBottomSafeArea={false}
      hasSafeArea={false}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: theme.colors['Background'],
          justifyContent: 'space-between',
        },
        dimensions.width
      )}
    >
      {/* site header update 12-8 signed out */}
      <>
        {!membership_disclaimer ? null : (
          <View
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ViewStyles(theme)[
                  'Akurie Header Desktop Header 9'
                ],
                {
                  borderBottomWidth: {
                    minWidth: Breakpoints.Laptop,
                    value: 0.5,
                  },
                  borderColor: {
                    minWidth: Breakpoints.Laptop,
                    value: theme.colors['Akurie green'],
                  },
                  height: { minWidth: Breakpoints.Laptop, value: 119.6 },
                  justifyContent: {
                    minWidth: Breakpoints.Laptop,
                    value: 'center',
                  },
                  paddingLeft: { minWidth: Breakpoints.Laptop, value: '0%' },
                  paddingRight: { minWidth: Breakpoints.Laptop, value: '0%' },
                  width: {
                    minWidth: Breakpoints.Laptop,
                    value: dimensions.width,
                  },
                }
              ),
              dimensions.width
            )}
          >
            {/* View 4 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                  flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
                  height: { minWidth: Breakpoints.Laptop, value: 68.48 },
                  justifyContent: {
                    minWidth: Breakpoints.Laptop,
                    value: 'space-between',
                  },
                  width: { minWidth: Breakpoints.Laptop, value: '74%' },
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    height: { minWidth: Breakpoints.Laptop, value: '100%' },
                    width: { minWidth: Breakpoints.Laptop, value: '16.93%' },
                  },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    try {
                      /* 'Open App Link' action requires configuration: select URL */
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      height: { minWidth: Breakpoints.Laptop, value: '100%' },
                      width: { minWidth: Breakpoints.Laptop, value: '100%' },
                    },
                    dimensions.width
                  )}
                >
                  <Image
                    resizeMode={'contain'}
                    source={Images.MainLogoUpdate1u2}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                          width: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  />
                </Touchable>
              </View>
            </View>
          </View>
        )}
      </>
      <ImageBackground
        resizeMode={'cover'}
        source={Images.Asset6}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ImageBackgroundStyles(theme)['Image Background'],
            { justifyContent: 'center' }
          ),
          dimensions.width
        )}
      >
        <>{!Constants['ak_isLoading'] ? null : <LoadingViewBlock />}</>
        {/* Blur View 2 */}
        <>
          {!membership_disclaimer ? null : (
            <BlurView
              intensity={60}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.BlurViewStyles(theme)['delete blur confirm'],
                  {
                    alignItems: {
                      minWidth: Breakpoints.Tablet,
                      value: 'center',
                    },
                    height: [
                      { minWidth: Breakpoints.Mobile, value: null },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: dimensions.height,
                      },
                    ],
                    justifyContent: 'center',
                    zIndex: 1,
                  }
                ),
                dimensions.width
              )}
              tint={'dark'}
            >
              {/* Membership Required */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Akurie Dark Blue'],
                    borderRadius: 10,
                    marginBottom: 80,
                    marginLeft: 10,
                    marginRight: 10,
                    paddingBottom: 30,
                    paddingLeft: 30,
                    paddingRight: 30,
                    paddingTop: 30,
                    width: [
                      { minWidth: Breakpoints.Tablet, value: '60%' },
                      { minWidth: Breakpoints.Desktop, value: '50%' },
                      { minWidth: Breakpoints.BigScreen, value: '40%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      height: 30,
                      marginBottom: 8,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Akurie Primary'],
                        fontFamily: 'Inter_600SemiBold',
                        fontSize: 22,
                        marginRight: 5,
                      },
                      dimensions.width
                    )}
                  >
                    {'Notice'}
                  </Text>
                  <Icon
                    color={theme.colors['Strong Inverse']}
                    name={'AntDesign/exclamationcircle'}
                    size={28}
                    style={StyleSheet.applyWidth(
                      { height: 30, width: 30 },
                      dimensions.width
                    )}
                  />
                </View>

                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Strong Inverse'],
                      fontSize: 15,
                      marginBottom: 16,
                      marginTop: 10,
                      textAlign: {
                        minWidth: Breakpoints.Tablet,
                        value: 'left',
                      },
                    },
                    dimensions.width
                  )}
                >
                  {
                    'Akurie offers free business filing services, one of many benefits exclusive to our Assistant Pro Plan at $99.99/month. However, after setting up your business, enjoy a free 30-day trial.'
                  }
                </Text>
                {/* View 3 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: 'space-between',
                        },
                        { minWidth: Breakpoints.Tablet, value: 'space-evenly' },
                      ],
                      marginBottom: 8,
                      marginTop: 20,
                    },
                    dimensions.width
                  )}
                >
                  <Button
                    onPress={() => {
                      try {
                        navigation.goBack();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        {
                          backgroundColor: theme.colors['Akurie Primary'],
                          padding: 10,
                          width: '65%',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Return to Previous Page'}
                  />
                  {/* Button 2 */}
                  <Button
                    onPress={() => {
                      try {
                        setMembership_disclaimer(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        {
                          backgroundColor: theme.colors['Akurie green'],
                          color: theme.colors['Akurie Dark Blue'],
                          padding: 10,
                          width: '30%',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Continue'}
                  />
                </View>
              </View>
            </BlurView>
          )}
        </>
        <KeyboardAwareScrollView
          enableAutomaticScroll={true}
          enableOnAndroid={false}
          enableResetScrollToCoords={false}
          extraHeight={100}
          extraScrollHeight={100}
          keyboardShouldPersistTaps={'always'}
          showsVerticalScrollIndicator={false}
          style={StyleSheet.applyWidth(
            { height: dimensions.height },
            dimensions.width
          )}
          contentContainerStyle={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              justifyContent: { minWidth: Breakpoints.Tablet, value: 'center' },
              marginTop: 10,
              paddingLeft: [
                { minWidth: Breakpoints.Tablet, value: '10%' },
                { minWidth: Breakpoints.Laptop, value: '0%' },
              ],
              paddingRight: [
                { minWidth: Breakpoints.Laptop, value: '0%' },
                { minWidth: Breakpoints.Tablet, value: '10%' },
              ],
              paddingTop: [
                { minWidth: Breakpoints.Tablet, value: 50 },
                { minWidth: Breakpoints.Laptop, value: 0 },
              ],
            },
            dimensions.width
          )}
        >
          {/* View 2 */}
          <>
            {!(dimensions.width >= Breakpoints.Laptop) ? null : (
              <View
                style={StyleSheet.applyWidth(
                  { width: { minWidth: Breakpoints.Laptop, value: '80%' } },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'contain'}
                  source={Images.MainLogoUpdate2u}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'],
                      {
                        height: { minWidth: Breakpoints.Laptop, value: 50 },
                        width: { minWidth: Breakpoints.Laptop, value: '30%' },
                      }
                    ),
                    dimensions.width
                  )}
                />
              </View>
            )}
          </>
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: [
                  { minWidth: Breakpoints.Laptop, value: 'center' },
                  { minWidth: Breakpoints.Mobile, value: 'center' },
                ],
                flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
                height: [
                  { minWidth: Breakpoints.Mobile, value: '100%' },
                  { minWidth: Breakpoints.Laptop, value: '85%' },
                ],
                justifyContent: [
                  { minWidth: Breakpoints.Laptop, value: 'center' },
                  { minWidth: Breakpoints.Mobile, value: 'center' },
                ],
                marginTop: [
                  { minWidth: Breakpoints.Laptop, value: -30 },
                  { minWidth: Breakpoints.Desktop, value: -60 },
                ],
                width: '100%',
              },
              dimensions.width
            )}
          >
            {/* Master Form View 2 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: [
                        {
                          minWidth: Breakpoints.Tablet,
                          value: theme.colors['Akurie Dark Blue'],
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors['Akurie Dark Blue'],
                        },
                      ],
                      borderBottomLeftRadius: {
                        minWidth: Breakpoints.Laptop,
                        value: 12,
                      },
                      borderRadius: [
                        { minWidth: Breakpoints.Tablet, value: 12 },
                        { minWidth: Breakpoints.Mobile, value: 12 },
                        { minWidth: Breakpoints.Laptop, value: 0 },
                      ],
                      borderTopLeftRadius: {
                        minWidth: Breakpoints.Laptop,
                        value: 12,
                      },
                      height: [
                        { minWidth: Breakpoints.Tablet, value: '80%' },
                        { minWidth: Breakpoints.Laptop, value: '70%' },
                      ],
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      marginBottom: [
                        { minWidth: Breakpoints.Mobile, value: 80 },
                        { minWidth: Breakpoints.Laptop, value: 20 },
                      ],
                      marginTop: 80,
                      opacity: [
                        { minWidth: Breakpoints.Tablet, value: 0.95 },
                        { minWidth: Breakpoints.Mobile, value: 0.95 },
                      ],
                      padding: [
                        { minWidth: Breakpoints.Tablet, value: 20 },
                        { minWidth: Breakpoints.Mobile, value: 10 },
                        { minWidth: Breakpoints.BigScreen, value: 40 },
                      ],
                      paddingLeft: [
                        { minWidth: Breakpoints.Mobile, value: 20 },
                        { minWidth: Breakpoints.Desktop, value: 40 },
                        { minWidth: Breakpoints.Laptop, value: 10 },
                        { minWidth: Breakpoints.BigScreen, value: 60 },
                      ],
                      paddingRight: [
                        { minWidth: Breakpoints.Mobile, value: 20 },
                        { minWidth: Breakpoints.Desktop, value: 40 },
                        { minWidth: Breakpoints.Laptop, value: 10 },
                        { minWidth: Breakpoints.BigScreen, value: 60 },
                      ],
                      width: [
                        { minWidth: Breakpoints.Mobile, value: '90%' },
                        { minWidth: Breakpoints.Laptop, value: '40%' },
                        { minWidth: Breakpoints.Desktop, value: '40%' },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        marginBottom: 10,
                        marginTop: 10,
                        padding: 5,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <>
                      {!(dimensions.width >= Breakpoints.Tablet) ? null : (
                        <IconButton
                          color={theme.colors['Akurie green']}
                          icon={'MaterialCommunityIcons/arrow-left-circle'}
                          onPress={() => {
                            try {
                              navigation.goBack();
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          size={40}
                          style={StyleSheet.applyWidth(
                            { marginRight: 10 },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Strong_Inverse'],
                            fontFamily: 'Inter_400Regular',
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 22 },
                              { minWidth: Breakpoints.Laptop, value: 18 },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Creating Your LLC'}
                    </Text>
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: [
                          { minWidth: Breakpoints.Mobile, value: 'row' },
                          { minWidth: Breakpoints.Laptop, value: 'column' },
                        ],
                        justifyContent: 'center',
                        marginBottom: 10,
                        marginTop: 10,
                        padding: 5,
                        paddingLeft: {
                          minWidth: Breakpoints.BigScreen,
                          value: 50,
                        },
                        paddingRight: {
                          minWidth: Breakpoints.BigScreen,
                          value: 50,
                        },
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: [
                              {
                                minWidth: Breakpoints.Mobile,
                                value: theme.colors['Strong_Inverse'],
                              },
                              {
                                minWidth: Breakpoints.Laptop,
                                value: theme.colors['Akurie Primary'],
                              },
                            ],
                            fontFamily: [
                              {
                                minWidth: Breakpoints.Mobile,
                                value: 'Inter_400Regular',
                              },
                              {
                                minWidth: Breakpoints.Laptop,
                                value: 'Inter_400Regular',
                              },
                              {
                                minWidth: Breakpoints.BigScreen,
                                value: 'Inter_600SemiBold',
                              },
                            ],
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 22 },
                              { minWidth: Breakpoints.Laptop, value: 32 },
                              { minWidth: Breakpoints.BigScreen, value: 26 },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Articles Of Organization'}
                    </Text>
                    {/* Text 2 */}
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: [
                              {
                                minWidth: Breakpoints.Mobile,
                                value: theme.colors['Strong_Inverse'],
                              },
                              {
                                minWidth: Breakpoints.Laptop,
                                value: theme.colors['Akurie green'],
                              },
                            ],
                            fontFamily: [
                              {
                                minWidth: Breakpoints.Mobile,
                                value: 'Inter_400Regular',
                              },
                              {
                                minWidth: Breakpoints.Laptop,
                                value: 'Inter_400Regular',
                              },
                              {
                                minWidth: Breakpoints.BigScreen,
                                value: 'Inter_400Regular',
                              },
                            ],
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 22 },
                              { minWidth: Breakpoints.Laptop, value: 18 },
                            ],
                            marginTop: {
                              minWidth: Breakpoints.Laptop,
                              value: 10,
                            },
                            textAlign: [
                              {
                                minWidth: Breakpoints.Desktop,
                                value: 'center',
                              },
                              { minWidth: Breakpoints.Laptop, value: 'center' },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        'This information will be used in the documents we file with the state to bring your business to life.'
                      }
                    </Text>
                  </View>
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        marginBottom: {
                          minWidth: Breakpoints.Laptop,
                          value: 10,
                        },
                        marginTop: { minWidth: Breakpoints.Laptop, value: 10 },
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'contain'}
                      source={Images.Asset29}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: 150,
                            },
                            width: { minWidth: Breakpoints.Laptop, value: 150 },
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                </View>
              )}
            </>
            {/* Master Form View */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: [
                    {
                      minWidth: Breakpoints.Tablet,
                      value: theme.colors['Akurie Dark Blue'],
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: theme.colors['Akurie Dark Blue'],
                    },
                  ],
                  borderColor: {
                    minWidth: Breakpoints.Laptop,
                    value: theme.colors['Medium'],
                  },
                  borderLeftWidth: { minWidth: Breakpoints.Laptop, value: 0.5 },
                  borderRadius: [
                    { minWidth: Breakpoints.Tablet, value: 12 },
                    { minWidth: Breakpoints.Mobile, value: 12 },
                    { minWidth: Breakpoints.Laptop, value: 12 },
                  ],
                  height: [
                    { minWidth: Breakpoints.Tablet, value: '80%' },
                    { minWidth: Breakpoints.Desktop, value: '100%' },
                    { minWidth: Breakpoints.BigScreen, value: '100%' },
                    { minWidth: Breakpoints.Laptop, value: '100%' },
                  ],
                  justifyContent: [
                    { minWidth: Breakpoints.BigScreen, value: 'center' },
                    { minWidth: Breakpoints.Laptop, value: 'center' },
                  ],
                  marginBottom: [
                    { minWidth: Breakpoints.Mobile, value: 80 },
                    { minWidth: Breakpoints.Laptop, value: 20 },
                    { minWidth: Breakpoints.Desktop, value: 60 },
                  ],
                  marginTop: 80,
                  opacity: [
                    { minWidth: Breakpoints.Tablet, value: 0.95 },
                    { minWidth: Breakpoints.Mobile, value: 0.95 },
                  ],
                  padding: [
                    { minWidth: Breakpoints.Tablet, value: 20 },
                    { minWidth: Breakpoints.Mobile, value: 10 },
                    { minWidth: Breakpoints.BigScreen, value: 40 },
                  ],
                  paddingBottom: 20,
                  paddingLeft: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.BigScreen, value: 40 },
                    { minWidth: Breakpoints.Desktop, value: 40 },
                    { minWidth: Breakpoints.Laptop, value: 20 },
                  ],
                  paddingRight: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.BigScreen, value: 40 },
                    { minWidth: Breakpoints.Desktop, value: 40 },
                    { minWidth: Breakpoints.Laptop, value: 20 },
                  ],
                  width: [
                    { minWidth: Breakpoints.Mobile, value: '90%' },
                    { minWidth: Breakpoints.Laptop, value: '30%' },
                    { minWidth: Breakpoints.Desktop, value: '30%' },
                  ],
                },
                dimensions.width
              )}
            >
              <>
                {dimensions.width >= Breakpoints.Laptop ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        marginBottom: 10,
                        marginTop: 10,
                        padding: 5,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <IconButton
                      color={theme.colors['Akurie Primary']}
                      icon={'MaterialCommunityIcons/arrow-left-circle'}
                      onPress={() => {
                        try {
                          navigation.goBack();
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      size={40}
                      style={StyleSheet.applyWidth(
                        { marginRight: 10 },
                        dimensions.width
                      )}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Strong_Inverse'],
                            fontFamily: 'Inter_400Regular',
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 22 },
                              { minWidth: Breakpoints.Laptop, value: 18 },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Creating Your LLC'}
                    </Text>
                  </View>
                )}
              </>
              <>
                {dimensions.width >= Breakpoints.Laptop ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: 'row',
                        justifyContent: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    {/* First Name View */}
                    <View
                      style={StyleSheet.applyWidth(
                        { paddingBottom: 5, paddingTop: 5, width: '100%' },
                        dimensions.width
                      )}
                    >
                      {/* First Name */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Akurie green'],
                            fontFamily: 'Inter_600SemiBold',
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 16 },
                              { minWidth: Breakpoints.Laptop, value: 14 },
                            ],
                          },
                          dimensions.width
                        )}
                      >
                        {'Articles of Organization Info'}
                      </Text>
                      {/* First Name 2 */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Strong Inverse'],
                            fontFamily: 'Inter_400Regular',
                            fontSize: [
                              { minWidth: Breakpoints.Laptop, value: 14 },
                              { minWidth: Breakpoints.Mobile, value: 14 },
                            ],
                            marginTop: 5,
                            textAlign: 'left',
                          },
                          dimensions.width
                        )}
                      >
                        {
                          'This information will be used in the documents we file with the state.'
                        }
                      </Text>
                    </View>
                  </View>
                )}
              </>
              {/* Form */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'stretch',
                    marginTop: 10,
                    opacity: 1,
                    width: [
                      { minWidth: Breakpoints.Mobile, value: '100%' },
                      { minWidth: Breakpoints.Laptop, value: '95%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                {/* Email View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    { paddingBottom: 5, paddingTop: 5 },
                    dimensions.width
                  )}
                >
                  {/* Email */}
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Strong Inverse'],
                        fontFamily: 'Inter_600SemiBold',
                        fontSize: { minWidth: Breakpoints.Laptop, value: 13 },
                        marginBottom: 5,
                      },
                      dimensions.width
                    )}
                  >
                    {'Name of the LLC'}
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          { color: theme.colors['Error'] }
                        ),
                        dimensions.width
                      )}
                    >
                      {'*'}
                    </Text>
                  </Text>
                  {/* Business Name */}
                  <TextInput
                    enablesReturnKeyAutomatically={false}
                    onChangeText={newBusinessNameValue => {
                      try {
                        setLlcNameValue(newBusinessNameValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    onSubmitEditing={() => {
                      try {
                        businessPurposezGpCOapIRef.current.focus();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'ex. My Business LLC'}
                    placeholderTextColor={theme.colors['Communial_Icon_Gray']}
                    returnKeyType={'next'}
                    spellcheck={true}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Background'],
                        borderBottomWidth: 1,
                        borderColor: theme.colors['Communial_Icon_Gray'],
                        borderLeftWidth: 1,
                        borderRadius: 8,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        fontFamily: 'Inter_400Regular',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 16 },
                          { minWidth: Breakpoints.Laptop, value: 14 },
                        ],
                        height: [
                          { minWidth: Breakpoints.Mobile, value: 60 },
                          { minWidth: Breakpoints.Laptop, value: 60 },
                        ],
                        paddingBottom: 12,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 12,
                      },
                      dimensions.width
                    )}
                    textContentType={'emailAddress'}
                    value={llcNameValue}
                  />
                </View>
                {/* First Name 3 */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Akurie green'],
                      fontFamily: 'Inter_600SemiBold',
                      fontSize: [
                        { minWidth: Breakpoints.Laptop, value: 14 },
                        { minWidth: Breakpoints.Mobile, value: 14 },
                      ],
                      marginTop: 15,
                    },
                    dimensions.width
                  )}
                >
                  {'Business Purpose'}
                </Text>
                {/* Email View 3 */}
                <View
                  style={StyleSheet.applyWidth(
                    { paddingBottom: 5, paddingTop: 5 },
                    dimensions.width
                  )}
                >
                  {/* Email */}
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Strong Inverse'],
                        fontFamily: 'Inter_600SemiBold',
                        fontSize: { minWidth: Breakpoints.Laptop, value: 13 },
                        marginBottom: 5,
                      },
                      dimensions.width
                    )}
                  >
                    {'What does your business do?'}
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          { color: theme.colors['Error'] }
                        ),
                        dimensions.width
                      )}
                    >
                      {'*'}
                    </Text>
                  </Text>
                  {/* Business Purpose */}
                  <TextInput
                    enablesReturnKeyAutomatically={false}
                    multiline={true}
                    numberOfLines={3}
                    onChangeText={newBusinessPurposeValue => {
                      try {
                        setBusinessPurposeValue(newBusinessPurposeValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    onSubmitEditing={() => {
                      try {
                        Keyboard.dismiss();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={
                      'ex. Our business assists small business owners by providing professional services tailored to their industry, streamlining their operations for greater efficiency and success.'
                    }
                    placeholderTextColor={theme.colors['Communial_Icon_Gray']}
                    ref={businessPurposezGpCOapIRef}
                    spellcheck={true}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Background'],
                        borderBottomWidth: 1,
                        borderColor: theme.colors['Communial_Icon_Gray'],
                        borderLeftWidth: 1,
                        borderRadius: 8,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        fontFamily: 'Inter_400Regular',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 16 },
                          { minWidth: Breakpoints.Laptop, value: 14 },
                        ],
                        height: { minWidth: Breakpoints.Laptop, value: 60 },
                        paddingBottom: 12,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 12,
                      },
                      dimensions.width
                    )}
                    textContentType={'emailAddress'}
                    value={businessPurposeValue}
                  />
                </View>
                {/* First Name 4 */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Akurie green'],
                      fontFamily: 'Inter_600SemiBold',
                      fontSize: [
                        { minWidth: Breakpoints.Laptop, value: 14 },
                        { minWidth: Breakpoints.Mobile, value: 14 },
                      ],
                      marginTop: 15,
                    },
                    dimensions.width
                  )}
                >
                  {'Principal Place of Business'}
                </Text>
                {/* Email View 5 */}
                <View
                  style={StyleSheet.applyWidth(
                    { paddingBottom: 5, paddingTop: 5 },
                    dimensions.width
                  )}
                >
                  {/* Email */}
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Strong Inverse'],
                        fontFamily: 'Inter_600SemiBold',
                        fontSize: { minWidth: Breakpoints.Laptop, value: 13 },
                        marginBottom: 5,
                      },
                      dimensions.width
                    )}
                  >
                    {'Address'}
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          { color: theme.colors['Error'] }
                        ),
                        dimensions.width
                      )}
                    >
                      {'*'}
                    </Text>
                  </Text>
                  {/* Addr1 */}
                  <TextInput
                    enablesReturnKeyAutomatically={false}
                    onChangeText={newAddr1Value => {
                      try {
                        setAddr1Value(newAddr1Value);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    onSubmitEditing={() => {
                      try {
                        Keyboard.dismiss();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'1234 Main St'}
                    placeholderTextColor={theme.colors['Communial_Icon_Gray']}
                    spellcheck={true}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Background'],
                        borderBottomWidth: 1,
                        borderColor: theme.colors['Communial_Icon_Gray'],
                        borderLeftWidth: 1,
                        borderRadius: 8,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        fontFamily: 'Inter_400Regular',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 16 },
                          { minWidth: Breakpoints.Laptop, value: 14 },
                        ],
                        height: [
                          { minWidth: Breakpoints.Mobile, value: 60 },
                          { minWidth: Breakpoints.Laptop, value: 60 },
                        ],
                        paddingBottom: 12,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 12,
                      },
                      dimensions.width
                    )}
                    textContentType={'emailAddress'}
                    value={Addr1Value}
                  />
                </View>
                {/* Email View 6 */}
                <View
                  style={StyleSheet.applyWidth(
                    { paddingBottom: 5, paddingTop: 5 },
                    dimensions.width
                  )}
                >
                  {/* Email */}
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Strong Inverse'],
                        fontFamily: 'Inter_600SemiBold',
                        fontSize: { minWidth: Breakpoints.Laptop, value: 13 },
                        marginBottom: 5,
                      },
                      dimensions.width
                    )}
                  >
                    {'Address 2'}
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          { color: theme.colors['Error'] }
                        ),
                        dimensions.width
                      )}
                    >
                      {'*'}
                    </Text>
                  </Text>
                  {/* Addr2 */}
                  <TextInput
                    enablesReturnKeyAutomatically={false}
                    onChangeText={newAddr2Value => {
                      try {
                        setAddr2Value(newAddr2Value);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    onSubmitEditing={() => {
                      try {
                        Keyboard.dismiss();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Suite, Apt, Flr, etc.'}
                    placeholderTextColor={theme.colors['Communial_Icon_Gray']}
                    spellcheck={true}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Background'],
                        borderBottomWidth: 1,
                        borderColor: theme.colors['Communial_Icon_Gray'],
                        borderLeftWidth: 1,
                        borderRadius: 8,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        fontFamily: 'Inter_400Regular',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 16 },
                          { minWidth: Breakpoints.Laptop, value: 14 },
                        ],
                        height: [
                          { minWidth: Breakpoints.Mobile, value: 60 },
                          { minWidth: Breakpoints.Laptop, value: 60 },
                        ],
                        paddingBottom: 12,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 12,
                      },
                      dimensions.width
                    )}
                    textContentType={'emailAddress'}
                    value={Addr2Value}
                  />
                </View>
                {/* Email View 4 */}
                <View
                  style={StyleSheet.applyWidth(
                    { paddingBottom: 5, paddingTop: 5 },
                    dimensions.width
                  )}
                >
                  {/* Email */}
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Strong Inverse'],
                        fontFamily: 'Inter_600SemiBold',
                        fontSize: { minWidth: Breakpoints.Laptop, value: 13 },
                        marginBottom: 5,
                      },
                      dimensions.width
                    )}
                  >
                    {'City'}
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          { color: theme.colors['Error'] }
                        ),
                        dimensions.width
                      )}
                    >
                      {'*'}
                    </Text>
                  </Text>
                  {/* City */}
                  <TextInput
                    enablesReturnKeyAutomatically={false}
                    onChangeText={newCityValue => {
                      try {
                        setCityValue(newCityValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    onSubmitEditing={() => {
                      try {
                        Keyboard.dismiss();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'ex. Houston'}
                    placeholderTextColor={theme.colors['Communial_Icon_Gray']}
                    spellcheck={true}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Background'],
                        borderBottomWidth: 1,
                        borderColor: theme.colors['Communial_Icon_Gray'],
                        borderLeftWidth: 1,
                        borderRadius: 8,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        fontFamily: 'Inter_400Regular',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 16 },
                          { minWidth: Breakpoints.Laptop, value: 14 },
                        ],
                        height: [
                          { minWidth: Breakpoints.Mobile, value: 60 },
                          { minWidth: Breakpoints.Laptop, value: 60 },
                        ],
                        paddingBottom: 12,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 12,
                      },
                      dimensions.width
                    )}
                    textContentType={'emailAddress'}
                    value={cityValue}
                  />
                </View>
                {/* Title View */}
                <View
                  style={StyleSheet.applyWidth(
                    { paddingBottom: 5, paddingTop: 5 },
                    dimensions.width
                  )}
                >
                  {/* Title */}
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Strong Inverse'],
                        fontFamily: 'Inter_600SemiBold',
                        fontSize: { minWidth: Breakpoints.Laptop, value: 13 },
                        marginBottom: 5,
                        marginTop: 10,
                      },
                      dimensions.width
                    )}
                  >
                    {'State'}
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          { color: theme.colors['Error'] }
                        ),
                        dimensions.width
                      )}
                    >
                      {'*'}
                    </Text>
                  </Text>
                  {/* State Picker */}
                  <Picker
                    iconColor={theme.colors['Akurie Primary']}
                    iconSize={24}
                    leftIconMode={'inset'}
                    onValueChange={newStatePickerValue => {
                      try {
                        setBusinessStateValue(newStatePickerValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    options={Constants['ak_supportedStates']}
                    placeholder={'Select Your State'}
                    placeholderTextColor={theme.colors['Communial_Icon_Gray']}
                    rightIconName={'AntDesign/downcircle'}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Background'],
                        borderColor: theme.colors['Communial_Icon_Gray'],
                        borderRadius: 9,
                        color: theme.colors['Strong'],
                        fontFamily: 'Inter_400Regular',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 16 },
                          { minWidth: Breakpoints.Laptop, value: 14 },
                        ],
                        paddingRight: 15,
                      },
                      dimensions.width
                    )}
                    type={'solid'}
                    value={businessStateValue}
                  />
                </View>
                {/* Password View */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      marginBottom: [
                        { minWidth: Breakpoints.Mobile, value: 10 },
                        { minWidth: Breakpoints.Desktop, value: 5 },
                      ],
                      paddingBottom: 5,
                      paddingTop: 10,
                    },
                    dimensions.width
                  )}
                >
                  {/* Password */}
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Strong Inverse'],
                        fontFamily: 'Inter_600SemiBold',
                        fontSize: { minWidth: Breakpoints.Laptop, value: 13 },
                        marginBottom: 5,
                        marginTop: 5,
                      },
                      dimensions.width
                    )}
                  >
                    {'Zip Code'}
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          { color: theme.colors['Error'] }
                        ),
                        dimensions.width
                      )}
                    >
                      {'*'}
                    </Text>
                  </Text>
                  {/* Password Input */}
                  <TextInput
                    keyboardType={'numeric'}
                    onChangeText={newPasswordInputValue => {
                      try {
                        setZipCodeValue(newPasswordInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    onSubmitEditing={() => {
                      try {
                        Keyboard.dismiss();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'ex. 77002'}
                    placeholderTextColor={theme.colors['Communial_Icon_Gray']}
                    secureTextEntry={false}
                    spellcheck={true}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Background'],
                        borderBottomWidth: 1,
                        borderColor: theme.colors['Communial_Icon_Gray'],
                        borderLeftWidth: 1,
                        borderRadius: 8,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        fontSize: 16,
                        height: [
                          { minWidth: Breakpoints.Mobile, value: 60 },
                          { minWidth: Breakpoints.Laptop, value: 60 },
                        ],
                        paddingBottom: 10,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 10,
                      },
                      dimensions.width
                    )}
                    textContentType={'password'}
                    value={zipCodeValue}
                  />
                </View>
              </View>
              {/* Button Holder View */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    marginBottom: [
                      { minWidth: Breakpoints.Tablet, value: 40 },
                      { minWidth: Breakpoints.Laptop, value: 0 },
                    ],
                    paddingBottom: 10,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                {/* Error Msg 2 */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Error'],
                      fontFamily: 'Inter_400Regular',
                      fontSize: { minWidth: Breakpoints.Laptop, value: 13 },
                      paddingBottom: 10,
                      paddingTop: 10,
                    },
                    dimensions.width
                  )}
                >
                  {errorMsg}
                </Text>
                {/* Button */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderRadius: 0,
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'row',
                      },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'space-evenly',
                      },
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* Touchable Frame */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        width: [
                          { minWidth: Breakpoints.Mobile, value: '100%' },
                          { minWidth: Breakpoints.Laptop, value: '50%' },
                        ],
                        zIndex: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        const handler = async () => {
                          try {
                            setGlobalVariableValue({
                              key: 'ak_isLoading',
                              value: true,
                            });
                            const formationResponse = (
                              await businessDataAkurieBusinessFormationPOST.mutateAsync(
                                {
                                  addr1: Addr1Value,
                                  addr2: Addr2Value,
                                  business_purpose: businessPurposeValue,
                                  city: cityValue,
                                  entity_name: llcNameValue,
                                  state: businessStateValue,
                                  users_id: props.route?.params?.userID ?? null,
                                  zipCode: zipCodeValue,
                                }
                              )
                            )?.json;
                            (
                              await businessDataAkurieName$ProblemUpdatePOST.mutateAsync(
                                {
                                  Industry: null,
                                  Services_Products: null,
                                  business_Idea: businessPurposeValue,
                                  business_Name: llcNameValue,
                                  problem_Solving: null,
                                  target_MarketGroups: null,
                                  users_id: Constants['ak_userID'],
                                }
                              )
                            )?.json;
                            const problemResult = (
                              await aIActionListAkurieCreateProblemSolutionsPOST.mutateAsync(
                                { userId: Constants['ak_userID'] }
                              )
                            )?.json;
                            const marketResult = (
                              await aIActionListAkurieGetTargetMarketPOST.mutateAsync(
                                { userId: Constants['ak_userID'] }
                              )
                            )?.json;
                            setGlobalVariableValue({
                              key: 'ak_userID',
                              value: props.route?.params?.userID ?? null,
                            });
                            (
                              await businessDataAkurieName$ProblemUpdatePOST.mutateAsync(
                                {
                                  Industry: null,
                                  Services_Products: null,
                                  business_Idea: businessPurposeValue,
                                  business_Name: llcNameValue,
                                  problem_Solving: problemResult,
                                  target_MarketGroups: marketResult,
                                  users_id: Constants['ak_userID'],
                                }
                              )
                            )?.json;
                            const industryResult = (
                              await aIActionListAkurieGetIndustryPOST.mutateAsync(
                                { userID: Constants['ak_userID'] }
                              )
                            )?.json;
                            const ErrorMsgResults = formationResponse?.message;
                            setErrorMsg(ErrorMsgResults);
                            if (ErrorMsgResults) {
                              return;
                            }
                            (
                              await logs$SupportAkurieBasicActivityPOST.mutateAsync(
                                {
                                  buttonName: 'Creating Formation Request',
                                  session_id: Constants['Session_ID'],
                                }
                              )
                            )?.json;
                            const busIdeaResult = (
                              await businessDataAkurieCreateIdeaBusinessPOST.mutateAsync(
                                {
                                  Business_Name: llcNameValue,
                                  Business_Status: 'Existing Business',
                                  Entity_Type:
                                    'Limited Liability Company - LLC',
                                  State: businessStateValue,
                                  User_id: props.route?.params?.userID ?? null,
                                  industry_type:
                                    industryResult?.businessIndustry?.Industry,
                                }
                              )
                            )?.json;
                            setGlobalVariableValue({
                              key: 'ak_businessID',
                              value: busIdeaResult?.id,
                            });
                            (
                              await web$AppServicesInitializeWebServicesPOST.mutateAsync(
                                { bus_id: busIdeaResult?.id }
                              )
                            )?.json;
                            (
                              await aIActionListAkurieBasicStepsSetupPOST.mutateAsync(
                                { businessID: busIdeaResult?.id }
                              )
                            )?.json;
                            navigation.navigate('BusinessLLCSetupInfo2Screen', {
                              state: businessStateValue,
                            });
                            setGlobalVariableValue({
                              key: 'ak_isLoading',
                              value: false,
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        { borderRadius: 0 },
                        dimensions.width
                      )}
                    >
                      {/* CTA Frame */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            backgroundColor: [
                              {
                                minWidth: Breakpoints.Laptop,
                                value: theme.colors['Akurie green'],
                              },
                              {
                                minWidth: Breakpoints.Mobile,
                                value: theme.colors['Akurie green'],
                              },
                            ],
                            borderRadius: 12,
                            flexDirection: 'row',
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'space-between',
                            },
                            paddingBottom: 12,
                            paddingLeft: 18,
                            paddingRight: 18,
                            paddingTop: 12,
                            zIndex: 10,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Label Frame */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexGrow: 1,
                              flexShrink: 0,
                              justifyContent: 'center',
                              width: {
                                minWidth: Breakpoints.Laptop,
                                value: '100%',
                              },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Continue Label */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: [
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: theme.colors['Akurie Dark Blue'],
                                  },
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: theme.colors['Akurie Dark Blue'],
                                  },
                                ],
                                fontFamily: 'Inter_400Regular',
                                fontSize: [
                                  { minWidth: Breakpoints.Mobile, value: 15 },
                                  { minWidth: Breakpoints.Laptop, value: 13 },
                                ],
                                lineHeight: 21,
                                marginLeft: [
                                  { minWidth: Breakpoints.Mobile, value: 42 },
                                  { minWidth: Breakpoints.Desktop, value: 0 },
                                  { minWidth: Breakpoints.Laptop, value: 0 },
                                ],
                                textTransform: 'uppercase',
                              },
                              dimensions.width
                            )}
                          >
                            {'Continue'}
                          </Text>
                        </View>
                        {/* Icon Group Frame */}
                        <>
                          {dimensions.width >= Breakpoints.Laptop ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  marginLeft: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 10,
                                  },
                                  opacity: 1,
                                },
                                dimensions.width
                              )}
                            >
                              <Circle
                                bgColor={theme.colors['Custom Color_18']}
                                size={42}
                              >
                                {/* Icon Flex Frame */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexGrow: 0,
                                      flexShrink: 0,
                                      paddingBottom: 12,
                                      paddingLeft: 12,
                                      paddingRight: 12,
                                      paddingTop: 12,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Arrow Icon  */}
                                  <Icon
                                    color={theme.colors['Akurie green']}
                                    name={'AntDesign/arrowright'}
                                    size={18}
                                  />
                                </View>
                              </Circle>
                            </View>
                          )}
                        </>
                      </View>
                    </Touchable>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </KeyboardAwareScrollView>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(BusinessLLCSetupInfo1Screen);
