import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as RevenueCat from '../custom-files/RevenueCat';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { Icon, withTheme } from '@draftbit/ui';
import { Alert, Platform, Text, View } from 'react-native';

const ServiceBoxForProBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const purchaseItem = async item => {
    try {
      setIsLoading(true);
      const { customerInfo, productIdentifier: packageName } =
        await RevenueCat.Purchases.purchasePackage(item);

      return { packageName, customerInfo };
      // navigate
    } catch (e) {
      if (!e.userCancelled) {
        Alert.alert('Subscription Canceled!');
      }

      return { packageName: false, customerInfo: false };
    } finally {
      setIsLoading(false);
    }
    return {};
  };
  React.useEffect(() => {
    let offerings;

    const fetchOfferings = async () => {
      try {
        setIsLoading(true);
        offerings = await RevenueCat.Purchases.getOfferings();

        if (offerings.all['All Subscriptions'].availablePackages.length !== 0) {
          setCurrentOfferings(
            offerings.all['All Subscriptions'].availablePackages
          );
        }
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    };

    fetchOfferings();
  }, []);

  return (
    <View
      style={StyleSheet.applyWidth(
        { alignItems: 'center', marginBottom: 10, marginTop: 10, width: '80%' },
        dimensions.width
      )}
    >
      <View
        style={StyleSheet.applyWidth(
          {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginTop: 5,
            paddingLeft: 10,
            width: '100%',
          },
          dimensions.width
        )}
      >
        <Icon
          color={theme.colors['Akurie Primary']}
          name={'AntDesign/checkcircle'}
          size={24}
          style={StyleSheet.applyWidth({ marginRight: 10 }, dimensions.width)}
        />
        <Text
          accessible={true}
          allowFontScaling={true}
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['Text'],
            dimensions.width
          )}
        >
          {'Networking Event Access'}
        </Text>
      </View>
      {/* View 7 */}
      <View
        style={StyleSheet.applyWidth(
          {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginTop: 5,
            paddingLeft: 10,
            width: '100%',
          },
          dimensions.width
        )}
      >
        <Icon
          color={theme.colors['Akurie Primary']}
          name={'AntDesign/checkcircle'}
          size={24}
          style={StyleSheet.applyWidth({ marginRight: 10 }, dimensions.width)}
        />
        <Text
          accessible={true}
          allowFontScaling={true}
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['Text'],
            dimensions.width
          )}
        >
          {'Accountability Assistant'}
        </Text>
      </View>
      {/* View 9 */}
      <View
        style={StyleSheet.applyWidth(
          {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginTop: 5,
            paddingLeft: 10,
            width: '100%',
          },
          dimensions.width
        )}
      >
        <Icon
          color={theme.colors['Akurie Primary']}
          name={'AntDesign/checkcircle'}
          size={24}
          style={StyleSheet.applyWidth({ marginRight: 10 }, dimensions.width)}
        />
        <Text
          accessible={true}
          allowFontScaling={true}
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['Text'],
            dimensions.width
          )}
        >
          {'Goal Achievement Plan '}
        </Text>
      </View>
      {/* View 3 */}
      <View
        style={StyleSheet.applyWidth(
          {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginTop: 5,
            paddingLeft: 10,
            width: '100%',
          },
          dimensions.width
        )}
      >
        <Icon
          color={theme.colors['Akurie Primary']}
          name={'AntDesign/checkcircle'}
          size={24}
          style={StyleSheet.applyWidth({ marginRight: 10 }, dimensions.width)}
        />
        <Text
          accessible={true}
          allowFontScaling={true}
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['Text'],
            dimensions.width
          )}
        >
          {'Business Information Management'}
        </Text>
      </View>
      {/* View 4 */}
      <View
        style={StyleSheet.applyWidth(
          {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginTop: 5,
            paddingLeft: 10,
            width: '100%',
          },
          dimensions.width
        )}
      >
        <Icon
          color={theme.colors['Akurie Primary']}
          name={'AntDesign/checkcircle'}
          size={24}
          style={StyleSheet.applyWidth({ marginRight: 10 }, dimensions.width)}
        />
        <Text
          accessible={true}
          allowFontScaling={true}
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['Text'],
            dimensions.width
          )}
        >
          {'Business Filing Tracker'}
        </Text>
      </View>
      {/* View 5 */}
      <View
        style={StyleSheet.applyWidth(
          {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginTop: 5,
            paddingLeft: 10,
            width: '100%',
          },
          dimensions.width
        )}
      >
        <Icon
          color={theme.colors['Akurie Primary']}
          name={'AntDesign/checkcircle'}
          size={24}
          style={StyleSheet.applyWidth({ marginRight: 10 }, dimensions.width)}
        />
        <Text
          accessible={true}
          allowFontScaling={true}
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['Text'],
            dimensions.width
          )}
        >
          {'Financial Resources'}
        </Text>
      </View>
      {/* View 10 */}
      <View
        style={StyleSheet.applyWidth(
          {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginTop: 5,
            paddingLeft: 10,
            width: '100%',
          },
          dimensions.width
        )}
      >
        <Icon
          color={theme.colors['Akurie Primary']}
          name={'AntDesign/checkcircle'}
          size={24}
          style={StyleSheet.applyWidth({ marginRight: 10 }, dimensions.width)}
        />
        <Text
          accessible={true}
          allowFontScaling={true}
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['Text'],
            dimensions.width
          )}
        >
          {'Service Discounts & Pay Later Options'}
        </Text>
      </View>
      {/* View 8 */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginTop: 5,
            paddingLeft: 10,
            width: '100%',
          },
          dimensions.width
        )}
      >
        <Icon
          color={theme.colors['Akurie Primary']}
          name={'AntDesign/checkcircle'}
          size={24}
          style={StyleSheet.applyWidth({ marginRight: 10 }, dimensions.width)}
        />
        <Text
          accessible={true}
          allowFontScaling={true}
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['Text'],
            dimensions.width
          )}
        >
          {'Business Website\nCreation & Management*'}
        </Text>
      </View>
    </View>
  );
};

export default withTheme(ServiceBoxForProBlock);
