import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AuthAkurieApi from '../apis/AuthAkurieApi.js';
import * as BusinessDataAkurieApi from '../apis/BusinessDataAkurieApi.js';
import * as Logs$SupportAkurieApi from '../apis/Logs$SupportAkurieApi.js';
import * as Web$AppServicesApi from '../apis/Web$AppServicesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openImagePickerUtil from '../utils/openImagePicker';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  ScreenContainer,
  Spacer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  Image,
  ImageBackground,
  Keyboard,
  ScrollView,
  StatusBar,
  Text,
  View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const WebsiteBuildInfo3Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [AccentPicker, setAccentPicker] = React.useState(false);
  const [Business_Exist, setBusiness_Exist] = React.useState(true);
  const [General_error_msg, setGeneral_error_msg] = React.useState('');
  const [Param_error_msg, setParam_error_msg] = React.useState('');
  const [PrimaryPicker, setPrimaryPicker] = React.useState(false);
  const [SecondaryPicker, setSecondaryPicker] = React.useState(false);
  const [accentColor, setAccentColor] = React.useState('');
  const [accentColorValue, setAccentColorValue] = React.useState('');
  const [additionalNotesValue, setAdditionalNotesValue] = React.useState('');
  const [colorPicker, setColorPicker] = React.useState([
    '#808000',
    '#FAF0E6',
    '#8FBC8F',
    '#FF4500',
    '#F0E68C',
    '#FFA07A',
    '#FFFFF0',
    '#00CED1',
    '#FF69B4',
    '#808080',
    '#6B8E23',
    '#F08080',
    '#A9A9A9',
    '#C71585',
    '#1E90FF',
    '#FFFFE0',
    '#DC143C',
    '#48D1CC',
    '#FFE4C4',
    '#CD5C5C',
    '#9400D3',
    '#F8F8FF',
    '#FFF0F5',
    '#3CB371',
    '#8A2BE2',
    '#B22222',
    '#DCDCDC',
    '#008B8B',
    '#20B2AA',
    '#FFD700',
    '#00FF00',
    '#00BFFF',
    '#FFFAF0',
    '#2F4F4F',
    '#4B0082',
    '#7FFF00',
    '#FFE4B5',
    '#A52A2A',
    '#FF8C00',
    '#F0FFF0',
    '#BDB76B',
    '#66CDAA',
    '#7CFC00',
    '#FF1493',
    '#556B2F',
    '#800000',
    '#6495ED',
    '#00FA9A',
    '#E0FFFF',
    '#B8860B',
    '#FFA500',
    '#8B008B',
    '#9932CC',
    '#00008B',
    '#DA70D6',
    '#32CD32',
    '#F0F8FF',
    '#006400',
    '#228B22',
    '#000080',
    '#FFE4E1',
    '#5F9EA0',
    '#00FFFF',
    '#778899',
    '#000000',
    '#7FFFD4',
    '#FF00FF',
    '#8B0000',
    '#FFF8DC',
    '#008000',
    '#F5FFFA',
    '#0000FF',
    '#9370DB',
    '#DEB887',
    '#F5F5DC',
    '#FDF5E6',
    '#696969',
    '#ADD8E6',
    '#BA55D3',
    '#0000CD',
    '#D2691E',
    '#FF7F50',
    '#FFDEAD',
    '#B0C4DE',
    '#F0FFFF',
    '#DAA520',
    '#E9967A',
    '#87CEFA',
    '#ADFF2F',
    '#D3D3D3',
    '#E6E6FA',
    '#7B68EE',
    '#FFEBCD',
    '#FFFACD',
    '#483D8B',
    '#FFB6C1',
    '#FAEBD7',
    '#191970',
    '#FAFAD2',
    '#90EE90',
  ]);
  const [error_msg, setError_msg] = React.useState('');
  const [logo, setLogo] = React.useState('');
  const [pickerValue, setPickerValue] = React.useState('');
  const [primaryColor, setPrimaryColor] = React.useState('');
  const [primaryColorValue, setPrimaryColorValue] = React.useState('');
  const [primaryFontValue, setPrimaryFontValue] = React.useState('');
  const [secondaryColor, setSecondaryColor] = React.useState('');
  const [secondaryColorValue, setSecondaryColorValue] = React.useState('');
  const [secondaryFontValue, setSecondaryFontValue] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const [textInputValue2, setTextInputValue2] = React.useState('');
  const [textInputValue3, setTextInputValue3] = React.useState('');
  const web$AppServicesWebsiteBrandingPOST =
    Web$AppServicesApi.useWebsiteBrandingPOST();
  const logs$SupportAkurieBasicActivityPOST =
    Logs$SupportAkurieApi.useBasicActivityPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      StatusBar.setBarStyle('dark-content');
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);
  const additionalNotes5bdbRRrgRef = React.useRef();

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors['Background'] },
        dimensions.width
      )}
    >
      {/* Nav Frame 12-27 */}
      <View
        style={StyleSheet.applyWidth(
          {
            borderBottomWidth: 2,
            borderColor: theme.colors['Akurie green'],
            height: 80,
            marginBottom: 15,
            marginTop: 45,
          },
          dimensions.width
        )}
      >
        <ImageBackground
          resizeMode={'cover'}
          source={Images.Asset13}
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              backgroundColor: theme.colors['Akurie Dark Blue'],
              flexDirection: 'row',
              height: '100%',
              justifyContent: 'flex-start',
            },
            dimensions.width
          )}
        >
          {/* Left Side Frame */}
          <View
            style={StyleSheet.applyWidth(
              { paddingLeft: 9, paddingRight: 9 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Icon Flex Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                {/* Icon  */}
                <Icon
                  color={theme.colors['Background']}
                  name={'Ionicons/arrow-back-outline'}
                  size={24}
                />
              </View>
            </Touchable>
          </View>
          {/* Middle Frame */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexGrow: 1,
                flexShrink: 0,
                justifyContent: 'center',
                marginRight: 30,
              },
              dimensions.width
            )}
          >
            {/* Text Frame  */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexGrow: 0,
                  flexShrink: 0,
                  paddingBottom: 12,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 12,
                },
                dimensions.width
              )}
            >
              {/* Text 12 18 Regular */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Akurie green'],
                    fontFamily: 'System',
                    fontSize: 22,
                    fontWeight: '400',
                    lineHeight: 18,
                    paddingTop: 8,
                  },
                  dimensions.width
                )}
              >
                {'Building Your Site'}
              </Text>
            </View>
          </View>
          {/* Left Side Frame */}
          <View
            style={StyleSheet.applyWidth(
              { paddingLeft: 9, paddingRight: 9 },
              dimensions.width
            )}
          />
        </ImageBackground>
      </View>

      <ImageBackground
        resizeMode={'cover'}
        source={Images.FirefoxPHKyvBthWe}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ImageBackgroundStyles(theme)['Image Background'],
            {
              backgroundColor: theme.colors['Background'],
              height: '100%',
              justifyContent: 'center',
            }
          ),
          dimensions.width
        )}
      >
        {/* steps */}
        <View
          style={StyleSheet.applyWidth(
            { marginTop: 10, minHeight: 20, width: '100%' },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            allowFontScaling={true}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                fontFamily: 'Inter_600SemiBold',
                textAlign: 'center',
              }),
              dimensions.width
            )}
          >
            {'Step 2 of 3'}
          </Text>
        </View>

        <KeyboardAwareScrollView
          contentContainerStyle={StyleSheet.applyWidth(
            {
              justifyContent: 'center',
              padding: 10,
              paddingLeft: 10,
              paddingRight: 10,
            },
            dimensions.width
          )}
          enableOnAndroid={false}
          extraHeight={400}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors['Akurie Dark Blue'],
                borderTopLeftRadius: 12,
                borderTopRightRadius: 12,
                paddingLeft: 40,
                paddingRight: 40,
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth({ marginTop: 16 }, dimensions.width)}
            >
              {/* Continue Your Profile */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Strong Inverse'],
                    fontFamily: 'Inter_700Bold',
                    fontSize: 22,
                    marginTop: 20,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {'Branding & Colors'}
              </Text>

              <Spacer bottom={4} left={8} right={8} top={4}>
                <BusinessDataAkurieApi.FetchGetBusinessGET
                  business_id={Constants['ak_businessID']}
                >
                  {({ loading, error, data, refetchGetBusiness }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <>
                        {/* Business Name */}
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors['Strong Inverse'],
                              fontFamily: 'Inter_600SemiBold',
                              paddingBottom: 5,
                              paddingTop: 10,
                              textAlign: 'center',
                            },
                            dimensions.width
                          )}
                        >
                          {fetchData?.user_business_name}
                          {' - ID: '}
                          {null}
                        </Text>
                      </>
                    );
                  }}
                </BusinessDataAkurieApi.FetchGetBusinessGET>
              </Spacer>
              <Spacer bottom={4} left={8} right={8} top={4} />
              {/* Sub Text */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Akurie Primary'],
                    fontFamily: 'Inter_500Medium',
                    fontSize: 14,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {'These details help bring your \nwebsite to life.'}
              </Text>
            </View>
          </View>

          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors['Akurie Dark Blue'],
                borderBottomLeftRadius: 12,
                borderBottomRightRadius: 12,
                marginBottom: 90,
                paddingBottom: 20,
                paddingLeft: 20,
                paddingRight: 20,
              },
              dimensions.width
            )}
          >
            {/* Form 1 */}
            <>
              {!Business_Exist ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { opacity: 1, width: '95%' },
                    dimensions.width
                  )}
                >
                  {/* View 10 */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingBottom: 5 },
                      dimensions.width
                    )}
                  >
                    {/* Logo */}
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Akurie green'],
                          fontFamily: 'Inter_600SemiBold',
                          fontSize: 18,
                          paddingBottom: 10,
                          paddingTop: 10,
                          textAlign: 'center',
                        },
                        dimensions.width
                      )}
                    >
                      {'Upload Your Logo'}
                    </Text>

                    <Touchable
                      onPress={() => {
                        const handler = async () => {
                          try {
                            const logoUpload = await openImagePickerUtil({
                              mediaTypes: 'Images',
                              allowsEditing: true,
                              quality: 0.2,
                              allowsMultipleSelection: false,
                            });

                            setLogo(logoUpload);
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        { marginTop: 10 },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: theme.colors['Background'],
                            borderRadius: 9,
                            flexDirection: 'row',
                            justifyContent: 'center',
                            padding: 15,
                          },
                          dimensions.width
                        )}
                      >
                        <>
                          {logo ? null : (
                            <Image
                              resizeMode={'contain'}
                              source={Images.AdobeStock345059232}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ImageStyles(theme)['Image'],
                                  { borderRadius: 9, height: 200, width: 200 }
                                ),
                                dimensions.width
                              )}
                            />
                          )}
                        </>
                        {/* Image 2 */}
                        <>
                          {!logo ? null : (
                            <Image
                              resizeMode={'contain'}
                              source={{ uri: `${logo}` }}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ImageStyles(theme)['Image'],
                                  { borderRadius: 9, height: 200, width: 200 }
                                ),
                                dimensions.width
                              )}
                            />
                          )}
                        </>
                        <Icon
                          name={'AntDesign/pluscircleo'}
                          size={24}
                          style={StyleSheet.applyWidth(
                            { bottom: 3, position: 'absolute' },
                            dimensions.width
                          )}
                        />
                      </View>
                    </Touchable>

                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          justifyContent: 'center',
                          minHeight: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <>
                        {!logo ? null : (
                          <Button
                            onPress={() => {
                              try {
                                setLogo(false);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ButtonStyles(theme)['Button'],
                                {
                                  backgroundColor: theme.colors['Error'],
                                  borderRadius: 9,
                                  marginTop: 20,
                                  width: '40%',
                                }
                              ),
                              dimensions.width
                            )}
                            title={'Clear'}
                          />
                        )}
                      </>
                    </View>
                  </View>
                  {/* View 4 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'space-around',
                        paddingBottom: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <View>
                      {/* Primary Color */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Strong Inverse'],
                            fontFamily: 'Inter_600SemiBold',
                            marginBottom: 10,
                            paddingBottom: 5,
                            paddingTop: 10,
                          },
                          dimensions.width
                        )}
                      >
                        {"Don't have access to you logo?"}
                      </Text>
                    </View>
                    <Button
                      onPress={() => {
                        try {
                          setLogo(
                            'https://assets.draftbit.app/apps/oh4dO59b/assets/Ej96kRIQ_RnRAwbBLEKTx'
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            backgroundColor: theme.colors['Akurie green'],
                            borderRadius: 9,
                            color: theme.colors['Akurie Dark Blue'],
                            fontFamily: 'Inter_700Bold',
                            width: '60%',
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Add Later'}
                    />
                  </View>
                  {/* Primary Touchable */}
                  <Touchable
                    onPress={() => {
                      try {
                        if (SecondaryPicker) {
                          return;
                        }
                        if (AccentPicker) {
                          return;
                        }
                        setPrimaryPicker(true);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* Primary Color */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: theme.colors['Surface'],
                          borderBottomWidth: 0.5,
                          borderColor: theme.colors['Divider'],
                          borderTopLeftRadius: 9,
                          borderTopRightRadius: 9,
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginTop: 20,
                          minHeight: 60,
                          paddingBottom: 5,
                          paddingLeft: 10,
                          paddingRight: 10,
                          paddingTop: 5,
                        },
                        dimensions.width
                      )}
                    >
                      {/* View 3 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', flexDirection: 'row' },
                          dimensions.width
                        )}
                      >
                        {/* Primary Color */}
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors['Akurie Dark Blue'],
                              fontFamily: 'Inter_600SemiBold',
                            },
                            dimensions.width
                          )}
                        >
                          {'Select Primary Color   '}
                          {primaryColor}
                        </Text>
                        <>
                          {PrimaryPicker ? null : (
                            <Icon
                              color={theme.colors['Medium']}
                              name={'AntDesign/downcircle'}
                              size={24}
                              style={StyleSheet.applyWidth(
                                { marginLeft: 5 },
                                dimensions.width
                              )}
                            />
                          )}
                        </>
                      </View>

                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: theme.colors['Surface'],
                            borderRadius: 9,
                            flexDirection: 'row',
                            justifyContent: 'center',
                            minHeight: 40,
                            paddingLeft: 5,
                            paddingRight: 5,
                          },
                          dimensions.width
                        )}
                      ></View>
                      {/* View 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: primaryColor,
                            borderRadius: 9,
                            borderWidth: 0.5,
                            height: 30,
                            width: 40,
                          },
                          dimensions.width
                        )}
                      />
                    </View>
                  </Touchable>
                  {/* Primary Color Picker */}
                  <>
                    {!PrimaryPicker ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { marginBottom: 10, width: '100%' },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              backgroundColor: theme.colors['Divider'],
                              flexDirection: 'row',
                              justifyContent: 'center',
                              minHeight: 40,
                            },
                            dimensions.width
                          )}
                        >
                          <Button
                            onPress={() => {
                              try {
                                setPrimaryPicker(false);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ButtonStyles(theme)['Button'],
                                {
                                  backgroundColor: theme.colors['Akurie green'],
                                  borderRadius: 9,
                                  color: theme.colors['Akurie Dark Blue'],
                                  fontFamily: 'Inter_500Medium',
                                  fontSize: 13,
                                  margin: 3,
                                  width: '40%',
                                }
                              ),
                              dimensions.width
                            )}
                            title={'Confirm Color'}
                          />
                        </View>
                        {/* View 9 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              backgroundColor: theme.colors['Surface'],
                              borderBottomLeftRadius: 9,
                              borderBottomRightRadius: 9,
                              borderBottomWidth: 0.5,
                              borderColor: theme.colors['Divider'],
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                              minHeight: 60,
                              paddingBottom: 20,
                              paddingTop: 20,
                            },
                            dimensions.width
                          )}
                        >
                          <Icon name={'AntDesign/up'} size={10} />
                          <ScrollView
                            bounces={true}
                            showsHorizontalScrollIndicator={true}
                            showsVerticalScrollIndicator={false}
                            style={StyleSheet.applyWidth(
                              { width: '100%' },
                              dimensions.width
                            )}
                            contentContainerStyle={StyleSheet.applyWidth(
                              { maxHeight: 240 },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  backgroundColor: theme.colors['Surface'],
                                  borderRadius: 9,
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  minHeight: 40,
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                            >
                              <FlatList
                                data={colorPicker}
                                keyExtractor={(listData, index) =>
                                  listData?.id ??
                                  listData?.uuid ??
                                  index.toString()
                                }
                                listKey={'r7Mtx0Iz'}
                                numColumns={1}
                                onEndReachedThreshold={0.5}
                                renderItem={({ item, index }) => {
                                  const listData = item;
                                  return (
                                    <Touchable
                                      onPress={() => {
                                        try {
                                          setPrimaryColor(listData);
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'center',
                                            backgroundColor: listData,
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            marginBottom: 5,
                                            marginTop: 5,
                                            minHeight: 50,
                                            width: '100%',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          allowFontScaling={true}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ],
                                              { textTransform: 'uppercase' }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {listData}
                                        </Text>
                                      </View>
                                    </Touchable>
                                  );
                                }}
                                showsHorizontalScrollIndicator={true}
                                showsVerticalScrollIndicator={true}
                              />
                            </View>
                          </ScrollView>
                          {/* Icon 2 */}
                          <Icon name={'AntDesign/down'} size={10} />
                        </View>
                      </View>
                    )}
                  </>
                  {/* Secondary Touchable  */}
                  <Touchable
                    onPress={() => {
                      try {
                        if (AccentPicker) {
                          return;
                        }
                        if (PrimaryPicker) {
                          return;
                        }
                        setSecondaryPicker(true);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* Secondary Color */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: theme.colors['Surface'],
                          borderBottomWidth: 0.5,
                          borderColor: theme.colors['Divider'],
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          minHeight: 60,
                          paddingBottom: 5,
                          paddingLeft: 10,
                          paddingRight: 10,
                          paddingTop: 5,
                        },
                        dimensions.width
                      )}
                    >
                      {/* View 3 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', flexDirection: 'row' },
                          dimensions.width
                        )}
                      >
                        {/* Secondary Color */}
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors['Akurie Dark Blue'],
                              fontFamily: 'Inter_600SemiBold',
                            },
                            dimensions.width
                          )}
                        >
                          {'Select Secondary Color   '}
                          {secondaryColor}
                        </Text>
                        {/* Icon 2 */}
                        <>
                          {SecondaryPicker ? null : (
                            <Icon
                              color={theme.colors['Medium']}
                              name={'AntDesign/downcircle'}
                              size={24}
                              style={StyleSheet.applyWidth(
                                { marginLeft: 5 },
                                dimensions.width
                              )}
                            />
                          )}
                        </>
                      </View>

                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: theme.colors['Surface'],
                            borderRadius: 9,
                            flexDirection: 'row',
                            justifyContent: 'center',
                            minHeight: 40,
                            paddingLeft: 5,
                            paddingRight: 5,
                          },
                          dimensions.width
                        )}
                      ></View>
                      {/* View 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: secondaryColor,
                            borderRadius: 9,
                            borderWidth: 0.5,
                            height: 30,
                            width: 40,
                          },
                          dimensions.width
                        )}
                      />
                    </View>
                  </Touchable>
                  {/* Secondary Color Picker */}
                  <>
                    {!SecondaryPicker ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { marginBottom: 10, width: '100%' },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              backgroundColor: theme.colors['Divider'],
                              flexDirection: 'row',
                              justifyContent: 'center',
                              minHeight: 40,
                            },
                            dimensions.width
                          )}
                        >
                          <Button
                            onPress={() => {
                              try {
                                setSecondaryPicker(false);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ButtonStyles(theme)['Button'],
                                {
                                  backgroundColor: theme.colors['Akurie green'],
                                  borderRadius: 9,
                                  color: theme.colors['Akurie Dark Blue'],
                                  fontFamily: 'Inter_500Medium',
                                  fontSize: 13,
                                  margin: 3,
                                  width: '60%',
                                }
                              ),
                              dimensions.width
                            )}
                            title={'Confirm Color'}
                          />
                        </View>
                        {/* View 9 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              backgroundColor: theme.colors['Surface'],
                              borderBottomLeftRadius: 9,
                              borderBottomRightRadius: 9,
                              borderBottomWidth: 0.5,
                              borderColor: theme.colors['Divider'],
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                              minHeight: 60,
                              paddingBottom: 20,
                              paddingTop: 20,
                            },
                            dimensions.width
                          )}
                        >
                          <Icon name={'AntDesign/up'} size={10} />
                          <ScrollView
                            bounces={true}
                            showsHorizontalScrollIndicator={true}
                            showsVerticalScrollIndicator={false}
                            style={StyleSheet.applyWidth(
                              { width: '100%' },
                              dimensions.width
                            )}
                            contentContainerStyle={StyleSheet.applyWidth(
                              { maxHeight: 240 },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  backgroundColor: theme.colors['Surface'],
                                  borderRadius: 9,
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  minHeight: 40,
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                            >
                              <FlatList
                                data={colorPicker}
                                keyExtractor={(listData, index) =>
                                  listData?.id ??
                                  listData?.uuid ??
                                  index.toString()
                                }
                                listKey={'I532GoHj'}
                                numColumns={1}
                                onEndReachedThreshold={0.5}
                                renderItem={({ item, index }) => {
                                  const listData = item;
                                  return (
                                    <Touchable
                                      onPress={() => {
                                        try {
                                          setSecondaryColor(listData);
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'center',
                                            backgroundColor: listData,
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            marginBottom: 5,
                                            marginTop: 5,
                                            minHeight: 50,
                                            width: '100%',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          allowFontScaling={true}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ],
                                              { textTransform: 'uppercase' }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {listData}
                                        </Text>
                                      </View>
                                    </Touchable>
                                  );
                                }}
                                showsHorizontalScrollIndicator={true}
                                showsVerticalScrollIndicator={true}
                              />
                            </View>
                          </ScrollView>
                          {/* Icon 2 */}
                          <Icon name={'AntDesign/down'} size={10} />
                        </View>
                      </View>
                    )}
                  </>
                  {/* Accent Touchable  2 */}
                  <Touchable
                    onPress={() => {
                      try {
                        if (PrimaryPicker) {
                          return;
                        }
                        if (SecondaryPicker) {
                          return;
                        }
                        setAccentPicker(true);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* Accent Color */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: theme.colors['Surface'],
                          borderBottomLeftRadius: 9,
                          borderBottomRightRadius: 9,
                          borderBottomWidth: 0.5,
                          borderColor: theme.colors['Divider'],
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          minHeight: 60,
                          paddingBottom: 5,
                          paddingLeft: 10,
                          paddingRight: 10,
                          paddingTop: 5,
                        },
                        dimensions.width
                      )}
                    >
                      {/* View 3 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', flexDirection: 'row' },
                          dimensions.width
                        )}
                      >
                        {/* Accent Color */}
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors['Akurie Dark Blue'],
                              fontFamily: 'Inter_600SemiBold',
                            },
                            dimensions.width
                          )}
                        >
                          {'Select Accent Color   '}
                          {accentColor}
                        </Text>
                        {/* Icon 3 */}
                        <>
                          {AccentPicker ? null : (
                            <Icon
                              color={theme.colors['Medium']}
                              name={'AntDesign/downcircle'}
                              size={24}
                              style={StyleSheet.applyWidth(
                                { marginLeft: 5 },
                                dimensions.width
                              )}
                            />
                          )}
                        </>
                      </View>

                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: theme.colors['Surface'],
                            borderRadius: 9,
                            flexDirection: 'row',
                            justifyContent: 'center',
                            minHeight: 40,
                            paddingLeft: 5,
                            paddingRight: 5,
                          },
                          dimensions.width
                        )}
                      ></View>
                      {/* View 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: accentColor,
                            borderRadius: 9,
                            borderWidth: 0.5,
                            height: 30,
                            width: 40,
                          },
                          dimensions.width
                        )}
                      />
                    </View>
                  </Touchable>
                  {/* Accent Color Picker 2 */}
                  <>
                    {!AccentPicker ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { marginBottom: 10, width: '100%' },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              backgroundColor: theme.colors['Divider'],
                              flexDirection: 'row',
                              justifyContent: 'center',
                              minHeight: 40,
                            },
                            dimensions.width
                          )}
                        >
                          <Button
                            onPress={() => {
                              try {
                                setAccentPicker(false);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ButtonStyles(theme)['Button'],
                                {
                                  backgroundColor: theme.colors['Akurie green'],
                                  borderRadius: 9,
                                  color: theme.colors['Akurie Dark Blue'],
                                  fontFamily: 'Inter_500Medium',
                                  fontSize: 13,
                                  margin: 3,
                                  width: '60%',
                                }
                              ),
                              dimensions.width
                            )}
                            title={'Confirm Color'}
                          />
                        </View>
                        {/* View 9 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              backgroundColor: theme.colors['Surface'],
                              borderBottomLeftRadius: 9,
                              borderBottomRightRadius: 9,
                              borderBottomWidth: 0.5,
                              borderColor: theme.colors['Divider'],
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                              minHeight: 60,
                              paddingBottom: 20,
                              paddingTop: 20,
                            },
                            dimensions.width
                          )}
                        >
                          <Icon name={'AntDesign/up'} size={10} />
                          <ScrollView
                            bounces={true}
                            showsHorizontalScrollIndicator={true}
                            showsVerticalScrollIndicator={false}
                            style={StyleSheet.applyWidth(
                              { width: '100%' },
                              dimensions.width
                            )}
                            contentContainerStyle={StyleSheet.applyWidth(
                              { maxHeight: 240 },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  backgroundColor: theme.colors['Surface'],
                                  borderRadius: 9,
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  minHeight: 40,
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                            >
                              <FlatList
                                data={colorPicker}
                                keyExtractor={(listData, index) =>
                                  listData?.id ??
                                  listData?.uuid ??
                                  index.toString()
                                }
                                listKey={'zeTamboK'}
                                numColumns={1}
                                onEndReachedThreshold={0.5}
                                renderItem={({ item, index }) => {
                                  const listData = item;
                                  return (
                                    <Touchable
                                      onPress={() => {
                                        try {
                                          setAccentColor(listData);
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'center',
                                            backgroundColor: listData,
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            marginBottom: 5,
                                            marginTop: 5,
                                            minHeight: 50,
                                            width: '100%',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          allowFontScaling={true}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ],
                                              { textTransform: 'uppercase' }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {listData}
                                        </Text>
                                      </View>
                                    </Touchable>
                                  );
                                }}
                                showsHorizontalScrollIndicator={true}
                                showsVerticalScrollIndicator={true}
                              />
                            </View>
                          </ScrollView>
                          {/* Icon 2 */}
                          <Icon name={'AntDesign/down'} size={10} />
                        </View>
                      </View>
                    )}
                  </>
                  {/* View 8 */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingBottom: 5, paddingTop: 5 },
                      dimensions.width
                    )}
                  >
                    {/* Notes */}
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Strong Inverse'],
                          fontFamily: 'Inter_600SemiBold',
                          paddingBottom: 5,
                          paddingTop: 10,
                        },
                        dimensions.width
                      )}
                    >
                      {'Additional Notes'}
                    </Text>
                    {/* Notes Subtitle */}
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Strong Inverse'],
                          fontFamily: 'Inter_400Regular',
                          fontSize: 12,
                          paddingBottom: 5,
                          paddingTop: 5,
                        },
                        dimensions.width
                      )}
                    >
                      {'Additional notes about your brand.'}
                    </Text>
                    {/* Additional Notes */}
                    <TextInput
                      allowFontScaling={true}
                      autoCapitalize={'none'}
                      changeTextDelay={500}
                      multiline={true}
                      numberOfLines={3}
                      onChangeText={newAdditionalNotesValue => {
                        try {
                          setAdditionalNotesValue(newAdditionalNotesValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      onSubmitEditing={() => {
                        try {
                          Keyboard.dismiss();
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'My business brand has a modern feel.'}
                      placeholderTextColor={theme.colors['Light']}
                      ref={additionalNotes5bdbRRrgRef}
                      spellcheck={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextInputStyles(theme)['Text Input'],
                          {
                            borderRadius: 9,
                            fontFamily: 'Inter_500Medium',
                            paddingLeft: 15,
                            paddingRight: 15,
                          }
                        ),
                        dimensions.width
                      )}
                      value={additionalNotesValue}
                    />
                  </View>

                  <View>
                    {/* Error Message */}
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Error'],
                          fontFamily: 'Inter_400Regular',
                          paddingTop: 10,
                        },
                        dimensions.width
                      )}
                    >
                      {Constants['ak_errorMessage']}
                    </Text>
                  </View>
                  {/* Continue Button */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        paddingBottom: 40,
                        paddingTop: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Button
                      onPress={() => {
                        const handler = async () => {
                          try {
                            const createResult = (
                              await web$AppServicesWebsiteBrandingPOST.mutateAsync(
                                {
                                  Accent_color: accentColor,
                                  Additional_Notes: additionalNotesValue,
                                  Primary_color: primaryColor,
                                  Secondary_color: secondaryColor,
                                  business_data_id: Constants['ak_businessID'],
                                  company_logo: null,
                                  file: logo,
                                }
                              )
                            )?.json;
                            const msg_error = createResult?.message;
                            setGlobalVariableValue({
                              key: 'ak_errorMessage',
                              value: msg_error,
                            });
                            if (msg_error) {
                              return;
                            }
                            setGlobalVariableValue({
                              key: 'ak_errorMessage',
                              value: false,
                            });
                            (
                              await logs$SupportAkurieBasicActivityPOST.mutateAsync(
                                {
                                  buttonName: 'Web Form Complete 3',
                                  session_id: Constants['Session_ID'],
                                }
                              )
                            )?.json;
                            navigation.navigate('WebsiteBuildInfo4Screen');
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            backgroundColor: theme.colors['Akurie green'],
                            borderRadius: 9,
                            color: theme.colors['Strong'],
                            fontFamily: 'Inter_500Medium',
                            fontSize: 16,
                            height: 50,
                            width: '100%',
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Continue'}
                    />
                  </View>
                </View>
              )}
            </>
          </View>
        </KeyboardAwareScrollView>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(WebsiteBuildInfo3Screen);
