import { I18nManager, Platform, StyleSheet, Text, View } from 'react-native';
import { systemWeights } from 'react-native-typography';
import { Icon, Touchable } from '@draftbit/ui';
import { NavigationContainer, DefaultTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import theme from './themes/Draftbit.js';
import LinkingConfiguration from './LinkingConfiguration.js';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import React from 'react';
import * as GlobalVariables from './config/GlobalVariableContext';
import Breakpoints from './utils/Breakpoints';
import useWindowDimensions from './utils/useWindowDimensions';

import AllowNotificationsScreen from './screens/AllowNotificationsScreen';
import AskMyAssistantScreen from './screens/AskMyAssistantScreen';
import AutoFilingSetup1Screen from './screens/AutoFilingSetup1Screen';
import AutoFilingSetup2Screen from './screens/AutoFilingSetup2Screen';
import AutoFilingSetup3Screen from './screens/AutoFilingSetup3Screen';
import BusinessGoalsActionPlansScreen from './screens/BusinessGoalsActionPlansScreen';
import BusinessIdeaSetup12Screen from './screens/BusinessIdeaSetup12Screen';
import BusinessIdeaSetup13Screen from './screens/BusinessIdeaSetup13Screen';
import BusinessIdeaSetup1OtherScreen from './screens/BusinessIdeaSetup1OtherScreen';
import BusinessIdeaSetup1Screen from './screens/BusinessIdeaSetup1Screen';
import BusinessIdeaSetup21Screen from './screens/BusinessIdeaSetup21Screen';
import BusinessIdeaSetup3Screen from './screens/BusinessIdeaSetup3Screen';
import BusinessInfoScreen from './screens/BusinessInfoScreen';
import BusinessLLCSetupInfo1Screen from './screens/BusinessLLCSetupInfo1Screen';
import BusinessLLCSetupInfo2Screen from './screens/BusinessLLCSetupInfo2Screen';
import BusinessLLCSetupInfo3Screen from './screens/BusinessLLCSetupInfo3Screen';
import DesktopMyAccountScreen from './screens/DesktopMyAccountScreen';
import DetailedActionPlanScreen from './screens/DetailedActionPlanScreen';
import EmailVerificationScreen from './screens/EmailVerificationScreen';
import GeneralSettingsScreen from './screens/GeneralSettingsScreen';
import HelpAndSupportNonAuthScreen from './screens/HelpAndSupportNonAuthScreen';
import HelpSupportScreen from './screens/HelpSupportScreen';
import LoginScreen from './screens/LoginScreen';
import ManageMyServicesScreen from './screens/ManageMyServicesScreen';
import ManageMySiteScreen from './screens/ManageMySiteScreen';
import MyAkurieWebServicesScreen from './screens/MyAkurieWebServicesScreen';
import MyBusinessInfoScreen from './screens/MyBusinessInfoScreen';
import MyFilingsReportsScreen from './screens/MyFilingsReportsScreen';
import NetworkingIDPassScreen from './screens/NetworkingIDPassScreen';
import NotificationScreen from './screens/NotificationScreen';
import OwnershipInformationScreen from './screens/OwnershipInformationScreen';
import PasswordChangeScreen from './screens/PasswordChangeScreen';
import PasswordResetScreen from './screens/PasswordResetScreen';
import Paywall2Screen from './screens/Paywall2Screen';
import PaywallProCurrentMonth2Screen from './screens/PaywallProCurrentMonth2Screen';
import PaywallProCurrentMonth3Screen from './screens/PaywallProCurrentMonth3Screen';
import PersonalProfileInfoScreen from './screens/PersonalProfileInfoScreen';
import ProfessionalServicesScreen from './screens/ProfessionalServicesScreen';
import PurchaseCompleteScreen from './screens/PurchaseCompleteScreen';
import PurchaseFailPageScreen from './screens/PurchaseFailPageScreen';
import RegisteredAgentInformationScreen from './screens/RegisteredAgentInformationScreen';
import ResourceCenterScreen from './screens/ResourceCenterScreen';
import SecurityScreen from './screens/SecurityScreen';
import SessionExpiredScreen from './screens/SessionExpiredScreen';
import SignUpScreen from './screens/SignUpScreen';
import SignupPt12Screen from './screens/SignupPt12Screen';
import Signuppt1Screen from './screens/Signuppt1Screen';
import SplashScreenWelcomeScreen from './screens/SplashScreenWelcomeScreen';
import UpdateBusinessInformationScreen from './screens/UpdateBusinessInformationScreen';
import UserDashboardScreen from './screens/UserDashboardScreen';
import WebsiteBuildInfo3Screen from './screens/WebsiteBuildInfo3Screen';
import WebsiteBuildInfo4Screen from './screens/WebsiteBuildInfo4Screen';
import WebsiteBuildInfoScreen from './screens/WebsiteBuildInfoScreen';
import WhatWeDoSignUpScreen from './screens/WhatWeDoSignUpScreen';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

function DefaultAndroidBackIcon({ tintColor }) {
  return (
    <View style={[styles.headerContainer, styles.headerContainerLeft]}>
      <Icon
        name="AntDesign/arrowleft"
        size={24}
        color={tintColor}
        style={[styles.headerIcon, styles.headerIconLeft]}
      />
    </View>
  );
}

export default function RootAppNavigator() {
  const Constants = GlobalVariables.useValues();
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  return (
    <NavigationContainer
      theme={{
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          background: '#FBFCFD',
        },
      }}
      linking={LinkingConfiguration}
    >
      <Stack.Navigator
        initialRouteName="SplashScreenWelcomeScreen"
        presentation="modal"
        screenOptions={({ navigation }) => ({
          headerBackImage:
            Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
          headerMode: 'none',
          headerShown: false,
        })}
      >
        <Stack.Screen
          name="LoginScreen"
          component={LoginScreen}
          options={({ navigation }) => ({
            title: 'Login',
          })}
        />
        <Stack.Screen
          name="Signuppt1Screen"
          component={Signuppt1Screen}
          options={({ navigation }) => ({
            title: 'Signup pt 1',
          })}
        />
        <Stack.Screen
          name="SplashScreenWelcomeScreen"
          component={SplashScreenWelcomeScreen}
          options={({ navigation }) => ({
            title: 'Splash Screen / Welcome',
          })}
        />
        <Stack.Screen
          name="SignUpScreen"
          component={SignUpScreen}
          options={({ navigation }) => ({
            title: 'Sign Up',
          })}
        />
        <Stack.Screen
          name="AllowNotificationsScreen"
          component={AllowNotificationsScreen}
          options={({ navigation }) => ({
            title: 'Allow Notifications',
          })}
        />
        <Stack.Screen
          name="EmailVerificationScreen"
          component={EmailVerificationScreen}
          options={({ navigation }) => ({
            title: 'Email Verification',
          })}
        />
        <Stack.Screen
          name="UserDashboardScreen"
          component={UserDashboardScreen}
          options={({ navigation }) => ({
            title: 'User Dashboard',
          })}
        />
        <Stack.Screen
          name="WhatWeDoSignUpScreen"
          component={WhatWeDoSignUpScreen}
          options={({ navigation }) => ({
            title: 'What We Do - Sign Up',
          })}
        />
        <Stack.Screen
          name="HelpSupportScreen"
          component={HelpSupportScreen}
          options={({ navigation }) => ({
            title: 'Help & Support',
          })}
        />
        <Stack.Screen
          name="PersonalProfileInfoScreen"
          component={PersonalProfileInfoScreen}
          options={({ navigation }) => ({
            title: 'Personal Profile Info',
          })}
        />
        <Stack.Screen
          name="GeneralSettingsScreen"
          component={GeneralSettingsScreen}
          options={({ navigation }) => ({
            title: 'General Settings',
          })}
        />
        <Stack.Screen
          name="SecurityScreen"
          component={SecurityScreen}
          options={({ navigation }) => ({
            title: 'Security',
          })}
        />
        <Stack.Screen
          name="MyBusinessInfoScreen"
          component={MyBusinessInfoScreen}
          options={({ navigation }) => ({
            title: 'My Business Info',
          })}
        />
        <Stack.Screen
          name="NotificationScreen"
          component={NotificationScreen}
          options={({ navigation }) => ({
            title: 'Notification',
          })}
        />
        <Stack.Screen
          name="ResourceCenterScreen"
          component={ResourceCenterScreen}
          options={({ navigation }) => ({
            title: 'Resource Center',
          })}
        />
        <Stack.Screen
          name="BusinessGoalsActionPlansScreen"
          component={BusinessGoalsActionPlansScreen}
          options={({ navigation }) => ({
            title: 'Business Goals & Action Plans',
          })}
        />
        <Stack.Screen
          name="UpdateBusinessInformationScreen"
          component={UpdateBusinessInformationScreen}
          options={({ navigation }) => ({
            title: 'Update Business Information',
          })}
        />
        <Stack.Screen
          name="RegisteredAgentInformationScreen"
          component={RegisteredAgentInformationScreen}
          options={({ navigation }) => ({
            title: 'Registered Agent Information',
          })}
        />
        <Stack.Screen
          name="OwnershipInformationScreen"
          component={OwnershipInformationScreen}
          options={({ navigation }) => ({
            title: 'Ownership Information',
          })}
        />
        <Stack.Screen
          name="MyFilingsReportsScreen"
          component={MyFilingsReportsScreen}
          options={({ navigation }) => ({
            title: 'My Filings & Reports',
          })}
        />
        <Stack.Screen
          name="DetailedActionPlanScreen"
          component={DetailedActionPlanScreen}
          options={({ navigation }) => ({
            title: 'Detailed Action Plan',
          })}
        />
        <Stack.Screen
          name="AskMyAssistantScreen"
          component={AskMyAssistantScreen}
          options={({ navigation }) => ({
            title: 'Ask My Assistant',
          })}
        />
        <Stack.Screen
          name="NetworkingIDPassScreen"
          component={NetworkingIDPassScreen}
          options={({ navigation }) => ({
            title: 'Networking ID Pass',
          })}
        />
        <Stack.Screen
          name="PasswordResetScreen"
          component={PasswordResetScreen}
          options={({ navigation }) => ({
            title: 'Password Reset',
          })}
        />
        <Stack.Screen
          name="SessionExpiredScreen"
          component={SessionExpiredScreen}
          options={({ navigation }) => ({
            title: 'Session Expired',
          })}
        />
        <Stack.Screen
          name="PasswordChangeScreen"
          component={PasswordChangeScreen}
          options={({ navigation }) => ({
            title: 'Password Change',
          })}
        />
        <Stack.Screen
          name="DesktopMyAccountScreen"
          component={DesktopMyAccountScreen}
          options={({ navigation }) => ({
            title: 'Desktop My Account',
          })}
        />
        <Stack.Screen
          name="MyAkurieWebServicesScreen"
          component={MyAkurieWebServicesScreen}
          options={({ navigation }) => ({
            title: 'My Akurie Web Services',
          })}
        />
        <Stack.Screen
          name="WebsiteBuildInfoScreen"
          component={WebsiteBuildInfoScreen}
          options={({ navigation }) => ({
            title: 'Website Build Info',
          })}
        />
        <Stack.Screen
          name="WebsiteBuildInfo3Screen"
          component={WebsiteBuildInfo3Screen}
          options={({ navigation }) => ({
            title: 'Website Build Info 3',
          })}
        />
        <Stack.Screen
          name="WebsiteBuildInfo4Screen"
          component={WebsiteBuildInfo4Screen}
          options={({ navigation }) => ({
            title: 'Website Build Info 4',
          })}
        />
        <Stack.Screen
          name="BusinessInfoScreen"
          component={BusinessInfoScreen}
          options={({ navigation }) => ({
            title: 'Business Info',
          })}
        />
        <Stack.Screen
          name="AutoFilingSetup2Screen"
          component={AutoFilingSetup2Screen}
          options={({ navigation }) => ({
            title: 'Auto Filing Setup 2',
          })}
        />
        <Stack.Screen
          name="AutoFilingSetup3Screen"
          component={AutoFilingSetup3Screen}
          options={({ navigation }) => ({
            title: 'Auto Filing Setup 3',
          })}
        />
        <Stack.Screen
          name="ManageMySiteScreen"
          component={ManageMySiteScreen}
          options={({ navigation }) => ({
            title: 'Manage My Site',
          })}
        />
        <Stack.Screen
          name="ManageMyServicesScreen"
          component={ManageMyServicesScreen}
          options={({ navigation }) => ({
            title: 'Manage My Services',
          })}
        />
        <Stack.Screen
          name="AutoFilingSetup1Screen"
          component={AutoFilingSetup1Screen}
          options={({ navigation }) => ({
            title: 'Auto Filing Setup 1',
          })}
        />
        <Stack.Screen
          name="Paywall2Screen"
          component={Paywall2Screen}
          options={({ navigation }) => ({
            title: 'Paywall 2',
          })}
        />
        <Stack.Screen
          name="PurchaseCompleteScreen"
          component={PurchaseCompleteScreen}
          options={({ navigation }) => ({
            title: 'Purchase Complete',
          })}
        />
        <Stack.Screen
          name="PurchaseFailPageScreen"
          component={PurchaseFailPageScreen}
          options={({ navigation }) => ({
            title: 'Purchase Fail Page',
          })}
        />
        <Stack.Screen
          name="HelpAndSupportNonAuthScreen"
          component={HelpAndSupportNonAuthScreen}
          options={({ navigation }) => ({
            title: 'Help & Support Non Auth',
          })}
        />
        <Stack.Screen
          name="SignupPt12Screen"
          component={SignupPt12Screen}
          options={({ navigation }) => ({
            title: 'Signup pt 1-2',
          })}
        />
        <Stack.Screen
          name="BusinessIdeaSetup1Screen"
          component={BusinessIdeaSetup1Screen}
          options={({ navigation }) => ({
            title: 'Business Idea Setup 1',
          })}
        />
        <Stack.Screen
          name="BusinessIdeaSetup3Screen"
          component={BusinessIdeaSetup3Screen}
          options={({ navigation }) => ({
            title: 'Business Idea Setup 3',
          })}
        />
        <Stack.Screen
          name="PaywallProCurrentMonth2Screen"
          component={PaywallProCurrentMonth2Screen}
          options={({ navigation }) => ({
            title: 'Paywall Pro Current Month 2',
          })}
        />
        <Stack.Screen
          name="BusinessLLCSetupInfo1Screen"
          component={BusinessLLCSetupInfo1Screen}
          options={({ navigation }) => ({
            title: 'Business LLC Setup Info 1',
          })}
        />
        <Stack.Screen
          name="BusinessLLCSetupInfo2Screen"
          component={BusinessLLCSetupInfo2Screen}
          options={({ navigation }) => ({
            title: 'Business LLC Setup Info 2',
          })}
        />
        <Stack.Screen
          name="BusinessLLCSetupInfo3Screen"
          component={BusinessLLCSetupInfo3Screen}
          options={({ navigation }) => ({
            title: 'Business LLC Setup Info 3',
          })}
        />
        <Stack.Screen
          name="ProfessionalServicesScreen"
          component={ProfessionalServicesScreen}
          options={({ navigation }) => ({
            title: 'Professional Services',
          })}
        />
        <Stack.Screen
          name="PaywallProCurrentMonth3Screen"
          component={PaywallProCurrentMonth3Screen}
          options={({ navigation }) => ({
            title: 'Paywall Pro Current Month 3',
          })}
        />
        <Stack.Screen
          name="BusinessIdeaSetup12Screen"
          component={BusinessIdeaSetup12Screen}
          options={({ navigation }) => ({
            title: 'Business Idea Setup 1-2',
          })}
        />
        <Stack.Screen
          name="BusinessIdeaSetup21Screen"
          component={BusinessIdeaSetup21Screen}
          options={({ navigation }) => ({
            title: 'Business Idea Setup 2-1',
          })}
        />
        <Stack.Screen
          name="BusinessIdeaSetup13Screen"
          component={BusinessIdeaSetup13Screen}
          options={({ navigation }) => ({
            title: 'Business Idea Setup 1-3',
          })}
        />
        <Stack.Screen
          name="BusinessIdeaSetup1OtherScreen"
          component={BusinessIdeaSetup1OtherScreen}
          options={({ navigation }) => ({
            title: 'Business Idea Setup 1 other',
          })}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    ...Platform.select({
      ios: null,
      default: {
        marginVertical: 3,
        marginHorizontal: 11,
      },
    }),
  },
  headerContainerLeft: Platform.select({ ios: { marginLeft: 8 } }),
  headerIcon: Platform.select({
    ios: {
      marginVertical: 12,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
    default: {
      margin: 3,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
  }),
  headerIconLeft: Platform.select({ ios: { marginRight: 6 } }),
});
