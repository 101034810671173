import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AuthAkurieApi from '../apis/AuthAkurieApi.js';
import * as Logs$SupportAkurieApi from '../apis/Logs$SupportAkurieApi.js';
import DesktopHeader13024Block from '../components/DesktopHeader13024Block';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Circle,
  Icon,
  Link,
  PinInput,
  ScreenContainer,
  Spacer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { StatusBar, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const EmailVerificationScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [errorMessage, setErrorMessage] = React.useState('');
  const [pinInputValue, setPinInputValue] = React.useState('');
  const [codeInputValue, setCodeInputValue] = React.useState(undefined);
  const logs$SupportAkurieBasicActivityPOST =
    Logs$SupportAkurieApi.useBasicActivityPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        (
          await logs$SupportAkurieBasicActivityPOST.mutateAsync({
            buttonName: 'Email Verification',
            session_id: Constants['Session_ID'],
          })
        )?.json;
        (
          await AuthAkurieApi.emailConfirmationSendGET(Constants, {
            email: props.route?.params?.email ?? '',
          })
        )?.json;
        StatusBar.setBarStyle('light-content');
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors['Akurie Dark Blue'] },
        dimensions.width
      )}
    >
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <DesktopHeader13024Block />
        )}
      </>
      <KeyboardAwareScrollView
        contentContainerStyle={StyleSheet.applyWidth(
          {
            flex: 1,
            justifyContent: 'center',
            paddingBottom: 36,
            paddingLeft: [
              { minWidth: Breakpoints.Mobile, value: 36 },
              { minWidth: Breakpoints.Laptop, value: '30%' },
            ],
            paddingRight: [
              { minWidth: Breakpoints.Mobile, value: 36 },
              { minWidth: Breakpoints.Laptop, value: '30%' },
            ],
            paddingTop: 36,
          },
          dimensions.width
        )}
        keyboardShouldPersistTaps={'never'}
        showsVerticalScrollIndicator={true}
      >
        <View
          style={StyleSheet.applyWidth(
            { padding: { minWidth: Breakpoints.Laptop, value: '8%' } },
            dimensions.width
          )}
        >
          {/* Heading */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center' },
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                {
                  alignSelf: 'auto',
                  color: theme.colors['Akurie green'],
                  fontFamily: 'Inter_400Regular',
                  fontSize: 32,
                },
                dimensions.width
              )}
            >
              {'Email Verification'}
            </Text>
            <Icon
              color={theme.colors['Akurie Primary']}
              name={'MaterialCommunityIcons/email-check-outline'}
              size={80}
              style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
            />
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Strong Inverse'],
                  fontFamily: 'Inter_300Light',
                  fontSize: 16,
                  marginTop: 8,
                  textAlign: 'center',
                },
                dimensions.width
              )}
            >
              {'We sent you an email\nwith a 5-digit code'}
            </Text>
          </View>
          <Spacer bottom={16} left={8} right={8} top={16} />
          {/* Form */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                marginBottom: 15,
              },
              dimensions.width
            )}
          >
            <PinInput
              autoComplete={'one-time-code'}
              blurOnFull={true}
              cellCount={5}
              changeTextDelay={500}
              clearOnCellFocus={true}
              focusedBorderColor={theme.colors.primary}
              keyboardType={'number-pad'}
              onChangeText={newPinInputValue => {
                const codeInputValue = newPinInputValue;
                try {
                  setPinInputValue(newPinInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              onInputFull={finalValue => {
                const handler = async () => {
                  const codeInputValue = finalValue;
                  try {
                    const codeResponse = (
                      await AuthAkurieApi.emailConfirmationConfirmGET(
                        Constants,
                        {
                          emailCode: pinInputValue,
                          user_id: Constants['ak_userID'],
                        }
                      )
                    )?.json;
                    const errorMsg = codeResponse?.message;
                    setErrorMessage(errorMsg);
                    if (errorMsg) {
                      return;
                    }
                    navigation.navigate('UserDashboardScreen', {
                      business_id: props.route?.params?.busID ?? '',
                      userId: props.route?.params?.current_user_id ?? '',
                    });
                    setGlobalVariableValue({
                      key: 'ak_isLoading',
                      value: false,
                    });
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              renderItem={({ cellValue, isFocused }) => {
                return null;
              }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.PinInputStyles(theme)['Pin Input'],
                  {
                    backgroundColor: theme.colors['Background'],
                    fontFamily: 'Inter_400Regular',
                    height: 60,
                  }
                ),
                dimensions.width
              )}
              value={pinInputValue}
            />
            <Text
              accessible={true}
              allowFontScaling={true}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Error'],
                  fontFamily: 'Inter_400Regular',
                  marginTop: 10,
                  textDecorationLine: 'underline',
                }),
                dimensions.width
              )}
            >
              {errorMessage}
            </Text>
          </View>
          {/* Button */}
          <View>
            {/* Touchable Frame */}
            <View
              style={StyleSheet.applyWidth({ zIndex: 10 }, dimensions.width)}
            >
              <Touchable
                onPress={() => {
                  const handler = async () => {
                    try {
                      const confirmResponse = (
                        await AuthAkurieApi.emailConfirmationConfirmGET(
                          Constants,
                          {
                            emailCode: pinInputValue,
                            user_id: Constants['ak_userID'],
                          }
                        )
                      )?.json;
                      const errMsg = confirmResponse?.message;
                      setGlobalVariableValue({
                        key: 'ak_errorMessage',
                        value: errMsg,
                      });
                      if (errMsg) {
                        return;
                      }
                      setGlobalVariableValue({
                        key: 'ak_errorMessage',
                        value: false,
                      });
                      navigation.navigate('UserDashboardScreen', {
                        business_id: props.route?.params?.busID ?? '',
                        userId: props.route?.params?.current_user_id ?? '',
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
              >
                {/* CTA Frame */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: theme.colors['Akurie Primary'],
                      borderRadius: 12,
                      flexDirection: 'row',
                      paddingBottom: 12,
                      paddingLeft: 18,
                      paddingRight: 18,
                      paddingTop: 12,
                      zIndex: 10,
                    },
                    dimensions.width
                  )}
                >
                  {/* Label Frame */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexGrow: 1,
                        flexShrink: 0,
                        justifyContent: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {/* Label */}
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Strong Inverse'],
                          fontFamily: 'Inter_400Regular',
                          fontSize: 15,
                          lineHeight: 21,
                          marginLeft: 42,
                          textTransform: 'uppercase',
                        },
                        dimensions.width
                      )}
                    >
                      {'Continue'}
                    </Text>
                  </View>
                  {/* Icon Group Frame */}
                  <View
                    style={StyleSheet.applyWidth(
                      { opacity: 1 },
                      dimensions.width
                    )}
                  >
                    <Circle bgColor={theme.colors['Custom Color_18']} size={42}>
                      {/* Icon Flex Frame */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexGrow: 0,
                            flexShrink: 0,
                            paddingBottom: 12,
                            paddingLeft: 12,
                            paddingRight: 12,
                            paddingTop: 12,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Icon  */}
                        <Icon
                          color={theme.colors['Akurie green']}
                          name={'AntDesign/arrowright'}
                          size={18}
                        />
                      </View>
                    </Circle>
                  </View>
                </View>
              </Touchable>
            </View>
          </View>
          <Spacer bottom={16} left={8} right={8} top={16} />
          {/* Login */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center' },
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Akurie green'],
                  fontFamily: 'Inter_600SemiBold',
                },
                dimensions.width
              )}
            >
              {"Didn't receive a code?"}
            </Text>
            <Link
              onPress={() => {
                const handler = async () => {
                  try {
                    (
                      await AuthAkurieApi.emailConfirmationSendGET(Constants, {
                        email: props.route?.params?.email ?? '',
                      })
                    )?.json;
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Akurie Primary'],
                  fontFamily: 'Inter_600SemiBold',
                  fontSize: 16,
                  marginTop: 8,
                  textDecorationLine: 'underline',
                },
                dimensions.width
              )}
              title={'RESEND'}
            />
            {/* Text 2 */}
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Strong Inverse'],
                  fontFamily: 'Inter_400Regular',
                  marginTop: 30,
                  textAlign: 'center',
                },
                dimensions.width
              )}
            >
              {
                'Still not recieving the verification code? Contact support at Support@akurie.com'
              }
            </Text>
          </View>
        </View>
      </KeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(EmailVerificationScreen);
