import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AIActionListAkurieApi from '../apis/AIActionListAkurieApi.js';
import * as AuthAkurieApi from '../apis/AuthAkurieApi.js';
import * as BusinessPlanning$GoalsAkurieApi from '../apis/BusinessPlanning$GoalsAkurieApi.js';
import DesktopHeader13024SignedInBlock from '../components/DesktopHeader13024SignedInBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Checkbox,
  Icon,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  ImageBackground,
  ScrollView,
  StatusBar,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const DetailedActionPlanScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [classAccess1, setClassAccess1] = React.useState(true);
  const [classAccess2, setClassAccess2] = React.useState(false);
  const [classAccess3, setClassAccess3] = React.useState(false);
  const [classAccess4, setClassAccess4] = React.useState(false);
  const [menuTab1, setMenuTab1] = React.useState(true);
  const [menuTab2, setMenuTab2] = React.useState(false);
  const [menuTab3, setMenuTab3] = React.useState(false);
  const aIActionListAkurieDeleteGoalDELETE =
    AIActionListAkurieApi.useDeleteGoalDELETE();
  const businessPlanning$GoalsAkurieCompleteGoalStepPOST =
    BusinessPlanning$GoalsAkurieApi.useCompleteGoalStepPOST();
  const businessPlanning$GoalsAkurieIncompleteGoalStepPOST =
    BusinessPlanning$GoalsAkurieApi.useIncompleteGoalStepPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const authResponse = (await AuthAkurieApi.meGET(Constants))?.json;
        const tokenExpire = authResponse?.message;
        if (tokenExpire) {
          navigation.navigate('SessionExpiredScreen');
        }
        if (tokenExpire) {
          return;
        }
        StatusBar.setBarStyle('dark-content');
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors.studilyWhite4 },
        dimensions.width
      )}
    >
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <DesktopHeader13024SignedInBlock />
        )}
      </>
      {/* new header 8-15 */}
      <View>
        {/* Navigation Frame */}
        <View
          style={StyleSheet.applyWidth(
            {
              borderBottomWidth: 3,
              borderColor: theme.colors['Akurie green'],
              height: 90,
              marginTop: 15,
            },
            dimensions.width
          )}
        >
          <ImageBackground
            resizeMode={'cover'}
            source={Images.Asset13}
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors['Akurie Dark Blue'],
                flexDirection: 'row',
                height: '100%',
                justifyContent: 'space-between',
                paddingLeft: { minWidth: Breakpoints.Laptop, value: '20%' },
                paddingRight: { minWidth: Breakpoints.Laptop, value: '20%' },
              },
              dimensions.width
            )}
          >
            {/* Left Side Frame */}
            <View
              style={StyleSheet.applyWidth(
                { paddingLeft: 9, paddingRight: 9 },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    navigation.goBack();
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* Icon Flex Frame */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexGrow: 0,
                      flexShrink: 0,
                      paddingBottom: 12,
                      paddingLeft: 12,
                      paddingRight: 12,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  {/* Icon  */}
                  <Icon
                    color={theme.colors['Background']}
                    name={'Ionicons/arrow-back-outline'}
                    size={24}
                  />
                </View>
              </Touchable>
            </View>
            {/* Middle Frame */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexGrow: 1,
                  flexShrink: 0,
                  justifyContent: 'center',
                },
                dimensions.width
              )}
            >
              {/* Text Frame  */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                {/* Text 12 18 Regular */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Akurie green'],
                      fontFamily: 'System',
                      fontSize: 22,
                      fontWeight: '400',
                      lineHeight: 18,
                      paddingTop: 8,
                    },
                    dimensions.width
                  )}
                >
                  {'My Action Plan\n'}
                </Text>
              </View>
            </View>
            {/* Left Side Frame */}
            <View
              style={StyleSheet.applyWidth(
                { paddingLeft: 9, paddingRight: 9 },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('RootNavigator');
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* Icon Flex Frame */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexGrow: 0,
                      flexShrink: 0,
                      paddingBottom: 12,
                      paddingLeft: 12,
                      paddingRight: 12,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  {/* Icon  */}
                  <Icon
                    color={theme.colors['Background']}
                    name={'AntDesign/home'}
                    size={24}
                  />
                </View>
              </Touchable>
            </View>
          </ImageBackground>
        </View>
      </View>

      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: { minWidth: Breakpoints.Tablet, value: 'center' },
            paddingLeft: { minWidth: Breakpoints.Laptop, value: '20%' },
            paddingRight: { minWidth: Breakpoints.Laptop, value: '20%' },
            width: [
              { minWidth: Breakpoints.Laptop, value: '100%' },
              { minWidth: Breakpoints.Tablet, value: '100%' },
            ],
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              alignSelf: { minWidth: Breakpoints.Tablet, value: 'auto' },
              width: [
                { minWidth: Breakpoints.Tablet, value: '70%' },
                { minWidth: Breakpoints.Laptop, value: '85%' },
              ],
            },
            dimensions.width
          )}
        >
          <BusinessPlanning$GoalsAkurieApi.FetchGetSpecificGoalGET
            goal_id={props.route?.params?.item_id ?? 31}
          >
            {({ loading, error, data, refetchGetSpecificGoal }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  {/* goal layout view */}
                  <View
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ViewStyles(theme)['goal layout view'],
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          marginLeft: 10,
                          marginRight: 10,
                          padding: 10,
                          paddingLeft: 20,
                          paddingRight: 20,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      {/* View 3 */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: theme.colors['Akurie Dark Blue'],
                            borderRadius: 9,
                            padding: 5,
                            width: '55%',
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors['Akurie green'],
                              fontFamily: 'Inter_500Medium',
                              fontSize: 14,
                              textAlign: 'left',
                            },
                            dimensions.width
                          )}
                        >
                          {'Due Date: '}
                          {fetchData?.due_date}
                        </Text>
                      </View>
                      {/* View 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginTop: 10,
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { marginTop: 20, width: '80%' },
                            dimensions.width
                          )}
                        >
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Akurie Dark Blue'],
                                fontFamily: 'Inter_600SemiBold',
                                fontSize: 18,
                              },
                              dimensions.width
                            )}
                          >
                            {fetchData?.Main_Goal}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            marginTop: 20,
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors['Strong'],
                              fontFamily: 'Inter_400Regular',
                              fontSize: 14,
                            },
                            dimensions.width
                          )}
                        >
                          {'Priority: '}
                          {fetchData?.result_1?.priority}
                        </Text>
                        <>
                          {!(fetchData?.priority === 'Low') ? null : (
                            <Icon
                              color={theme.colors['Akurie Primary']}
                              name={'AntDesign/exclamationcircle'}
                              size={20}
                              style={StyleSheet.applyWidth(
                                { marginLeft: 10 },
                                dimensions.width
                              )}
                            />
                          )}
                        </>
                        <>
                          {!(fetchData?.priority === 'Medium') ? null : (
                            <Icon
                              color={theme.colors['Custom #ffa500']}
                              name={'AntDesign/exclamationcircle'}
                              size={20}
                              style={StyleSheet.applyWidth(
                                { marginLeft: 10 },
                                dimensions.width
                              )}
                            />
                          )}
                        </>
                        <>
                          {!(fetchData?.priority === 'High') ? null : (
                            <Icon
                              color={
                                theme.colors['Internal_Orange_Secondary_Alt']
                              }
                              name={'AntDesign/exclamationcircle'}
                              size={20}
                              style={StyleSheet.applyWidth(
                                { marginLeft: 10 },
                                dimensions.width
                              )}
                            />
                          )}
                        </>
                        <>
                          {!(fetchData?.priority === 'Urgent') ? null : (
                            <Icon
                              color={theme.colors['Error']}
                              name={'AntDesign/exclamationcircle'}
                              size={20}
                              style={StyleSheet.applyWidth(
                                { marginLeft: 10 },
                                dimensions.width
                              )}
                            />
                          )}
                        </>
                      </View>

                      <View
                        style={StyleSheet.applyWidth(
                          { flexDirection: 'row', marginTop: 5 },
                          dimensions.width
                        )}
                      >
                        <View />
                        <BusinessPlanning$GoalsAkurieApi.FetchGoalStatusGET
                          goal_id={fetchData?.id}
                        >
                          {({ loading, error, data, refetchGoalStatus }) => {
                            const fetchData = data?.json;
                            if (loading) {
                              return <ActivityIndicator />;
                            }

                            if (
                              error ||
                              data?.status < 200 ||
                              data?.status >= 300
                            ) {
                              return <ActivityIndicator />;
                            }

                            return (
                              <Text
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors['Strong'],
                                    fontFamily: 'Inter_400Regular',
                                  },
                                  dimensions.width
                                )}
                              >
                                {fetchData?.completedCount}
                                {' of '}
                                {fetchData?.totalCount}
                                {' Actionable Steps Completed'}
                              </Text>
                            );
                          }}
                        </BusinessPlanning$GoalsAkurieApi.FetchGoalStatusGET>
                      </View>
                      {/* Text Frame  */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            flexGrow: 0,
                            flexShrink: 0,
                            justifyContent: 'flex-start',
                            marginBottom: 10,
                            marginTop: 15,
                          },
                          dimensions.width
                        )}
                      >
                        <Button
                          icon={'Ionicons/chatbox-ellipses-outline'}
                          onPress={() => {
                            try {
                              navigation.navigate('AskMyAssistantScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'],
                              {
                                backgroundColor: theme.colors['Akurie Primary'],
                                color: theme.colors['Strong_Inverse'],
                                fontSize: 12,
                              }
                            ),
                            dimensions.width
                          )}
                          title={'Contact Your Assistant'}
                        />
                        <Button
                          icon={'Ionicons/trash-outline'}
                          onPress={() => {
                            const handler = async () => {
                              try {
                                (
                                  await aIActionListAkurieDeleteGoalDELETE.mutateAsync(
                                    {
                                      goal_id:
                                        props.route?.params?.item_id ?? 31,
                                    }
                                  )
                                )?.json;
                                navigation.navigate(
                                  'BusinessGoalsActionPlansScreen'
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'],
                              {
                                backgroundColor: theme.colors['Error'],
                                color: theme.colors['Strong_Inverse'],
                                fontFamily: 'Inter_500Medium',
                                fontSize: 12,
                                marginLeft: 15,
                                marginRight: 15,
                              }
                            ),
                            dimensions.width
                          )}
                          title={'Delete Goal'}
                        />
                      </View>
                    </View>
                  </View>
                </>
              );
            }}
          </BusinessPlanning$GoalsAkurieApi.FetchGetSpecificGoalGET>
        </View>

        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: 5,
              paddingLeft: 20,
              paddingRight: 20,
              width: [
                { minWidth: Breakpoints.Mobile, value: '100%' },
                { minWidth: Breakpoints.Tablet, value: '70%' },
                { minWidth: Breakpoints.Laptop, value: '85%' },
              ],
            },
            dimensions.width
          )}
        >
          <Text
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                fontFamily: 'Inter_400Regular',
                textAlign: 'left',
              }),
              dimensions.width
            )}
          >
            {'Current Action Plan Item:'}
          </Text>

          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flexDirection: 'row' },
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  fontFamily: 'Inter_400Regular',
                  textAlign: 'left',
                }),
                dimensions.width
              )}
            >
              {'Status'}
            </Text>
            <Icon
              name={'AntDesign/checksquare'}
              size={24}
              style={StyleSheet.applyWidth(
                { marginLeft: 5, marginRight: 5 },
                dimensions.width
              )}
            />
          </View>
        </View>
        {/* List Scroll View Tab 1 */}
        <>
          {!menuTab1 ? null : (
            <ScrollView
              bounces={true}
              horizontal={false}
              showsVerticalScrollIndicator={true}
              style={StyleSheet.applyWidth(
                {
                  width: [
                    { minWidth: Breakpoints.Tablet, value: '70%' },
                    { minWidth: Breakpoints.Laptop, value: '85%' },
                  ],
                },
                dimensions.width
              )}
              contentContainerStyle={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: theme.colors['Akurie Dark Blue'],
                  padding: 10,
                  paddingBottom: 500,
                },
                dimensions.width
              )}
            >
              <BusinessPlanning$GoalsAkurieApi.FetchGetSpecificGoalGET
                goal_id={props.route?.params?.item_id ?? 31}
              >
                {({ loading, error, data, refetchGetSpecificGoal }) => {
                  const fetchData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <FlatList
                      data={fetchData?.actionable_steps}
                      keyExtractor={(listData, index) =>
                        listData?.id ?? listData?.uuid ?? index.toString()
                      }
                      listKey={'hf2ahiBb'}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <>
                            {/* Data Entry Frame */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  backgroundColor: theme.colors.white,
                                  borderRadius: 9,
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  marginTop: 10,
                                  minHeight: 60,
                                  padding: 10,
                                  paddingLeft: 12,
                                  paddingRight: 12,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Right Side */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { justifyContent: 'center', width: '85%' },
                                  dimensions.width
                                )}
                              >
                                {/* Text Frame  */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexGrow: 0,
                                      flexShrink: 0,
                                      paddingLeft: 12,
                                      paddingRight: 12,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Heading 18 24 Bold 2 */}
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      {
                                        color: theme.colors['Strong'],
                                        fontFamily: 'Inter_600SemiBold',
                                        fontSize: 14,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {listData?.step}
                                    {'\n'}
                                  </Text>
                                </View>
                              </View>

                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  },
                                  dimensions.width
                                )}
                              >
                                <Checkbox
                                  checkedIcon={'AntDesign/checksquare'}
                                  color={theme.colors['Akurie Primary']}
                                  defaultValue={listData?.completion_status}
                                  onCheck={() => {
                                    const handler = async () => {
                                      const checkboxValue = undefined;
                                      try {
                                        (
                                          await businessPlanning$GoalsAkurieCompleteGoalStepPOST.mutateAsync(
                                            {
                                              goal_id: fetchData?.id,
                                              stepID: listData?.id,
                                            }
                                          )
                                        )?.json;
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    };
                                    handler();
                                  }}
                                  onUncheck={() => {
                                    const handler = async () => {
                                      const checkboxValue = undefined;
                                      try {
                                        (
                                          await businessPlanning$GoalsAkurieIncompleteGoalStepPOST.mutateAsync(
                                            {
                                              goal_id: fetchData?.id,
                                              stepID: listData?.id,
                                            }
                                          )
                                        )?.json;
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    };
                                    handler();
                                  }}
                                  size={30}
                                  style={StyleSheet.applyWidth(
                                    { height: '33%' },
                                    dimensions.width
                                  )}
                                  uncheckedColor={
                                    theme.colors['Akurie Dark Blue']
                                  }
                                  uncheckedIcon={
                                    'MaterialCommunityIcons/checkbox-blank-outline'
                                  }
                                />
                              </View>
                            </View>
                          </>
                        );
                      }}
                      showsHorizontalScrollIndicator={true}
                      showsVerticalScrollIndicator={true}
                      style={StyleSheet.applyWidth(
                        { width: '100%' },
                        dimensions.width
                      )}
                    />
                  );
                }}
              </BusinessPlanning$GoalsAkurieApi.FetchGetSpecificGoalGET>
            </ScrollView>
          )}
        </>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(DetailedActionPlanScreen);
