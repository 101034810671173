import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as Logs$SupportAkurieApi from '../apis/Logs$SupportAkurieApi.js';
import MobileHeaderBlock from '../components/MobileHeaderBlock';
import WebHeader2Block from '../components/WebHeader2Block';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Divider,
  Icon,
  ScreenContainer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ImageBackground, Text, View } from 'react-native';

const PurchaseFailPageScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [Manage_bus_view, setManage_bus_view] = React.useState(false);
  const [accessLock_Docs_Filing, setAccessLock_Docs_Filing] =
    React.useState(false);
  const [accessLock_Plans_Goals, setAccessLock_Plans_Goals] =
    React.useState(false);
  const [accessLock_Web_Services, setAccessLock_Web_Services] =
    React.useState(false);
  const [autoFilingButton, setAutoFilingButton] = React.useState(false);
  const [changebus_view, setChangebus_view] = React.useState(false);
  const [financials_available, setFinancials_available] = React.useState('');
  const [goal_view, setGoal_view] = React.useState(false);
  const [loading_goals, setLoading_goals] = React.useState(false);
  const [selectProfile2Value, setSelectProfile2Value] = React.useState('');
  const [selectProfile2Value2, setSelectProfile2Value2] = React.useState('');
  const [topMenu_view, setTopMenu_view] = React.useState(false);
  const [user_bus, setUser_bus] = React.useState('');
  const [user_first_name, setUser_first_name] = React.useState('');
  const [user_last_name, setUser_last_name] = React.useState('');
  const [userbusinessPro_Picker, setUserbusinessPro_Picker] =
    React.useState('');
  const [usersBusinesses, setUsersBusinesses] = React.useState([]);
  const logs$SupportAkurieBasicActivityPOST =
    Logs$SupportAkurieApi.useBasicActivityPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      console.log('Screen ON_SCREEN_FOCUS Start');
      let error = null;
      try {
        if (!isFocused) {
          return;
        }
        console.log('Start ON_SCREEN_FOCUS:0 FETCH_REQUEST');
        (
          await logs$SupportAkurieBasicActivityPOST.mutateAsync({
            buttonName: 'Payment Issue',
            session_id: Constants['Session_ID'],
          })
        )?.json;
        console.log('Complete ON_SCREEN_FOCUS:0 FETCH_REQUEST');
      } catch (err) {
        console.error(err);
        error = err.message ?? err;
      }
      console.log(
        'Screen ON_SCREEN_FOCUS Complete',
        error ? { error } : 'no error'
      );
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          alignSelf: 'stretch',
          backgroundColor: theme.colors['Akurie Dark Blue'],
        },
        dimensions.width
      )}
    >
      <WebHeader2Block />
      <ImageBackground
        resizeMode={'cover'}
        source={Images.FirefoxPHKyvBthWe}
        style={StyleSheet.applyWidth(
          { backgroundColor: theme.colors['Background'], height: '100%' },
          dimensions.width
        )}
      >
        <MobileHeaderBlock />
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: { minWidth: Breakpoints.Laptop, value: 'stretch' },
              flexDirection: { minWidth: Breakpoints.Laptop, value: 'column' },
              height: [
                { minWidth: Breakpoints.Mobile, value: '100%' },
                { minWidth: Breakpoints.Laptop, value: dimensions.height },
              ],
              marginBottom: { minWidth: Breakpoints.Tablet, value: 100 },
              width: [
                { minWidth: Breakpoints.Laptop, value: '100%' },
                { minWidth: Breakpoints.Desktop, value: '100%' },
              ],
            },
            dimensions.width
          )}
        >
          {/* View 2 */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                height: '80%',
                justifyContent: 'center',
                padding: 25,
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: theme.colors['Akurie Dark Blue'],
                  borderRadius: 120,
                  height: 120,
                  justifyContent: 'center',
                  width: 120,
                },
                dimensions.width
              )}
            >
              <Icon
                color={theme.colors['Akurie green']}
                name={'AntDesign/exclamationcircle'}
                size={80}
              />
            </View>

            <Text
              accessible={true}
              allowFontScaling={true}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  fontFamily: 'Inter_400Regular',
                  fontSize: 24,
                  marginBottom: 10,
                  marginTop: 10,
                }),
                dimensions.width
              )}
            >
              {'Something Went Wrong!'}
            </Text>
            <Divider
              color={theme.colors['Akurie Primary']}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.DividerStyles(theme)['Divider'],
                  { height: 2, marginBottom: 20, marginTop: 10, width: '75%' }
                ),
                dimensions.width
              )}
            />
            <Button
              onPress={() => {
                try {
                  navigation.navigate('PaywallScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
                  borderRadius: 9,
                  color: theme.colors['Akurie green'],
                  fontFamily: 'System',
                  fontSize: 16,
                  fontWeight: '400',
                  height: 50,
                }),
                dimensions.width
              )}
              title={'Return to Purchase Page'}
            />
          </View>
        </View>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(PurchaseFailPageScreen);
