import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as Logs$SupportAkurieApi from '../apis/Logs$SupportAkurieApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { Icon, Surface, Touchable, withTheme } from '@draftbit/ui';
import { useNavigation } from '@react-navigation/native';
import { Text, View } from 'react-native';

const SsBusinessCardBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const navigation = useNavigation();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const logs$SupportAkurieBasicActivityPOST =
    Logs$SupportAkurieApi.useBasicActivityPOST();

  return (
    <View
      style={StyleSheet.applyWidth(
        {
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          marginBottom: 10,
          marginTop: 10,
          paddingLeft: 10,
          paddingRight: 10,
          width: [
            { minWidth: Breakpoints.Tablet, value: '80%' },
            { minWidth: Breakpoints.Desktop, value: '70%' },
          ],
        },
        dimensions.width
      )}
    >
      {/* Resource Center */}
      <Touchable
        onPress={() => {
          const handler = async () => {
            try {
              (
                await logs$SupportAkurieBasicActivityPOST.mutateAsync({
                  buttonName: 'Secured Card',
                  session_id: Constants['Session_ID'],
                })
              )?.json;
              navigation.navigate('AkurieSecuredCardScreen');
            } catch (err) {
              console.error(err);
            }
          };
          handler();
        }}
      >
        <Surface
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.SurfaceStyles(theme)['Surface'], {
              alignItems: 'center',
              backgroundColor: theme.colors['Akurie Dark Blue'],
              borderColor: theme.colors['Akurie green'],
              borderRadius: 15,
              borderWidth: 0.5,
              flexDirection: 'row',
              justifyContent: 'space-between',
              minHeight: 100,
              minWidth: 180,
              paddingBottom: 5,
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 5,
            }),
            dimensions.width
          )}
        >
          {/* Banner */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                marginRight: 10,
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  paddingBottom: 10,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 10,
                },
                dimensions.width
              )}
            >
              {/* Title */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Background'],
                    fontFamily: 'Inter_500Medium',
                    fontSize: 16,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {'Secured Business Card'}
              </Text>
              {/* Title 2 */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Akurie green'],
                    fontFamily: 'Inter_500Medium',
                    fontSize: 12,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {'Join the wait list for the Akurie Card'}
              </Text>
            </View>
          </View>

          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', marginLeft: 10 },
              dimensions.width
            )}
          >
            <Icon
              color={theme.colors['Akurie Primary']}
              name={'Ionicons/card'}
              size={80}
            />
          </View>
        </Surface>
      </Touchable>
    </View>
  );
};

export default withTheme(SsBusinessCardBlock);
