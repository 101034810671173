import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AuthAkurieApi from '../apis/AuthAkurieApi.js';
import * as BusinessDataAkurieApi from '../apis/BusinessDataAkurieApi.js';
import * as PlaidAuthAkurieApi from '../apis/PlaidAuthAkurieApi.js';
import * as Web$AppServicesApi from '../apis/Web$AppServicesApi.js';
import DesktopHeader13024SignedInBlock from '../components/DesktopHeader13024SignedInBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Circle,
  CircularProgress,
  Icon,
  IconButton,
  Link,
  Picker,
  ScreenContainer,
  Surface,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const DesktopMyAccountScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [Manage_bus_view, setManage_bus_view] = React.useState(false);
  const [changebus_view, setChangebus_view] = React.useState(false);
  const [financials_available, setFinancials_available] = React.useState('');
  const [goal_view, setGoal_view] = React.useState(false);
  const [loading_goals, setLoading_goals] = React.useState(false);
  const [selectProfile2Value, setSelectProfile2Value] = React.useState('');
  const [selectProfile2Value2, setSelectProfile2Value2] = React.useState('');
  const [topMenu_view, setTopMenu_view] = React.useState(false);
  const [user_bus, setUser_bus] = React.useState('');
  const [user_first_name, setUser_first_name] = React.useState('');
  const [user_last_name, setUser_last_name] = React.useState('');
  const [userbusinessPro_Picker, setUserbusinessPro_Picker] =
    React.useState('');
  const [usersBusinesses, setUsersBusinesses] = React.useState([]);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      console.log('Screen ON_SCREEN_FOCUS Start');
      let error = null;
      try {
        if (!isFocused) {
          return;
        }
        console.log('Start ON_SCREEN_FOCUS:0 FETCH_REQUEST');
        const authResponse = (await AuthAkurieApi.meGET(Constants))?.json;
        console.log('Complete ON_SCREEN_FOCUS:0 FETCH_REQUEST', {
          authResponse,
        });
        console.log('Start ON_SCREEN_FOCUS:1 EXTRACT_KEY');
        const tokenExpired = authResponse?.message;
        console.log('Complete ON_SCREEN_FOCUS:1 EXTRACT_KEY', { tokenExpired });
        console.log('Start ON_SCREEN_FOCUS:2 NAVIGATE');
        if (tokenExpired) {
          navigation.navigate('SessionExpiredScreen');
          console.log('Complete ON_SCREEN_FOCUS:2 NAVIGATE');
        } else {
          console.log('Skipped ON_SCREEN_FOCUS:2 NAVIGATE: condition not met');
        }
        console.log('Start ON_SCREEN_FOCUS:3 CONDITIONAL_STOP');
        if (tokenExpired) {
          return;
        }
        console.log('Complete ON_SCREEN_FOCUS:3 CONDITIONAL_STOP');
        console.log('Start ON_SCREEN_FOCUS:4 SET_VARIABLE');
        if (Constants['ak_userID'] === '') {
          setGlobalVariableValue({
            key: 'ak_userID',
            value: null,
          });
          console.log('Complete ON_SCREEN_FOCUS:4 SET_VARIABLE');
        } else {
          console.log(
            'Skipped ON_SCREEN_FOCUS:4 SET_VARIABLE: condition not met'
          );
        }
        console.log('Start ON_SCREEN_FOCUS:5 SET_VARIABLE');
        if (Constants['ak_businessID'] === '') {
          setGlobalVariableValue({
            key: 'ak_businessID',
            value: null,
          });
          console.log('Complete ON_SCREEN_FOCUS:5 SET_VARIABLE');
        } else {
          console.log(
            'Skipped ON_SCREEN_FOCUS:5 SET_VARIABLE: condition not met'
          );
        }
        console.log('Start ON_SCREEN_FOCUS:6 FETCH_REQUEST');
        const bankResponse = (
          await PlaidAuthAkurieApi.accountStatusCheckGET(Constants, {
            user_business_id: Constants['ak_businessID'],
          })
        )?.json;
        console.log('Complete ON_SCREEN_FOCUS:6 FETCH_REQUEST', {
          bankResponse,
        });
        console.log('Start ON_SCREEN_FOCUS:7 SET_VARIABLE');
        setGlobalVariableValue({
          key: 'ak_bankdataPresent',
          value: bankResponse,
        });
        console.log('Complete ON_SCREEN_FOCUS:7 SET_VARIABLE');
        console.log('Start ON_SCREEN_FOCUS:8 FETCH_REQUEST');
        const userResponse = (
          await AuthAkurieApi.getSpecificUserGET(Constants, {
            user_id: Constants['ak_userID'],
          })
        )?.json;
        console.log('Complete ON_SCREEN_FOCUS:8 FETCH_REQUEST', {
          userResponse,
        });
        console.log('Start ON_SCREEN_FOCUS:9 EXTRACT_KEY');
        const emailResult = userResponse?.email;
        console.log('Complete ON_SCREEN_FOCUS:9 EXTRACT_KEY', { emailResult });
        console.log('Start ON_SCREEN_FOCUS:10 EXTRACT_KEY');
        const emailVerification = userResponse?.email_verified;
        console.log('Complete ON_SCREEN_FOCUS:10 EXTRACT_KEY', {
          emailVerification,
        });
        console.log('Start ON_SCREEN_FOCUS:11 NAVIGATE');
        if (emailVerification === false) {
          navigation.navigate('EmailVerificationScreen', {
            busID: null,
            email: emailResult,
            current_user_id: null,
          });
          console.log('Complete ON_SCREEN_FOCUS:11 NAVIGATE');
        } else {
          console.log('Skipped ON_SCREEN_FOCUS:11 NAVIGATE: condition not met');
        }
        console.log('Start ON_SCREEN_FOCUS:12 EXTRACT_KEY');
        const subResult = userResponse?.Subscription_Type;
        console.log('Complete ON_SCREEN_FOCUS:12 EXTRACT_KEY', { subResult });
        console.log('Start ON_SCREEN_FOCUS:13 IF');
        if (subResult === 'Akurie Free') {
        } else {
          if (subResult === 'Assistant Lite') {
            setGlobalVariableValue({
              key: 'business_docs_filings',
              value: 'Unlocked',
            });
          } else {
            if (subResult === 'Assistant Plus') {
              setGlobalVariableValue({
                key: 'business_docs_filings',
                value: 'Unlocked',
              });
              setGlobalVariableValue({
                key: 'my_website',
                value: 'Unlocked',
              });
              setGlobalVariableValue({
                key: 'the_financials2',
                value: 'Unlocked',
              });
              setGlobalVariableValue({
                key: 'business_action_plan2',
                value: 'Unlocked',
              });
              setGlobalVariableValue({
                key: 'audit_reports2',
                value: 'Unlocked',
              });
            } else {
              if (subResult === 'Assistant Pro') {
                setGlobalVariableValue({
                  key: 'business_action_plan',
                  value: 'Unlocked',
                });
                setGlobalVariableValue({
                  key: 'the_financials',
                  value: 'Unlocked',
                });
                setGlobalVariableValue({
                  key: 'business_docs_filings',
                  value: 'Unlocked',
                });
                setGlobalVariableValue({
                  key: 'my_website',
                  value: 'Unlocked',
                });
                setGlobalVariableValue({
                  key: 'audit_reports',
                  value: 'Unlocked',
                });
              } else {
              }
            }
          }
        }
        console.log('Complete ON_SCREEN_FOCUS:13 IF');
        console.log('Start ON_SCREEN_FOCUS:14 FETCH_REQUEST');
        const businessResponse = (
          await BusinessDataAkurieApi.getBusinessGET(Constants, {
            business_id: Constants['ak_businessID'],
          })
        )?.json;
        console.log('Complete ON_SCREEN_FOCUS:14 FETCH_REQUEST', {
          businessResponse,
        });
        console.log('Start ON_SCREEN_FOCUS:15 FETCH_REQUEST');
        const alluserBusinesses = (
          await BusinessDataAkurieApi.getAllUsersBusinessesGET(Constants, {
            user_id: Constants['ak_userID'],
          })
        )?.json;
        console.log('Complete ON_SCREEN_FOCUS:15 FETCH_REQUEST', {
          alluserBusinesses,
        });
        console.log('Start ON_SCREEN_FOCUS:16 SET_VARIABLE');
        setUsersBusinesses(alluserBusinesses);
        console.log('Complete ON_SCREEN_FOCUS:16 SET_VARIABLE');
        console.log('Start ON_SCREEN_FOCUS:17 EXTRACT_KEY');
        const businessName = businessResponse?.user_business_name;
        console.log('Complete ON_SCREEN_FOCUS:17 EXTRACT_KEY', {
          businessName,
        });
        console.log('Start ON_SCREEN_FOCUS:18 SET_VARIABLE');
        setGlobalVariableValue({
          key: 'ak_businessName',
          value: businessName,
        });
        console.log('Complete ON_SCREEN_FOCUS:18 SET_VARIABLE');
        console.log('Start ON_SCREEN_FOCUS:19 EXTRACT_KEY');
        const businessStatus = businessResponse?.Business_Status;
        console.log('Complete ON_SCREEN_FOCUS:19 EXTRACT_KEY', {
          businessStatus,
        });
        console.log('Start ON_SCREEN_FOCUS:20 SET_VARIABLE');
        setGlobalVariableValue({
          key: 'ak_businessStatus',
          value: businessStatus,
        });
        console.log('Complete ON_SCREEN_FOCUS:20 SET_VARIABLE');
        console.log('Start ON_SCREEN_FOCUS:21 EXTRACT_KEY');
        const stateFormed = businessResponse?.state_formed_in;
        console.log('Complete ON_SCREEN_FOCUS:21 EXTRACT_KEY', { stateFormed });
        console.log('Start ON_SCREEN_FOCUS:22 SET_VARIABLE');
        setGlobalVariableValue({
          key: 'ak_businessState',
          value: stateFormed,
        });
        console.log('Complete ON_SCREEN_FOCUS:22 SET_VARIABLE');
        console.log('Start ON_SCREEN_FOCUS:23 EXTRACT_KEY');
        const entityResult = businessResponse?.entity_type;
        console.log('Complete ON_SCREEN_FOCUS:23 EXTRACT_KEY', {
          entityResult,
        });
        console.log('Start ON_SCREEN_FOCUS:24 SET_VARIABLE');
        setGlobalVariableValue({
          key: 'ak_subscriptionType',
          value: subResult,
        });
        console.log('Complete ON_SCREEN_FOCUS:24 SET_VARIABLE');
        console.log('Start ON_SCREEN_FOCUS:25 SET_VARIABLE');
        setGlobalVariableValue({
          key: 'ak_entityType',
          value: entityResult,
        });
        console.log('Complete ON_SCREEN_FOCUS:25 SET_VARIABLE');
        console.log('Start ON_SCREEN_FOCUS:26 SET_VARIABLE');
        if (Constants['ak_bankdataPresent'] === '') {
          setGlobalVariableValue({
            key: 'ak_isLoading',
            value: false,
          });
          console.log('Complete ON_SCREEN_FOCUS:26 SET_VARIABLE');
        } else {
          console.log(
            'Skipped ON_SCREEN_FOCUS:26 SET_VARIABLE: condition not met'
          );
        }
      } catch (err) {
        console.error(err);
        error = err.message ?? err;
      }
      console.log(
        'Screen ON_SCREEN_FOCUS Complete',
        error ? { error } : 'no error'
      );
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { alignSelf: 'stretch', backgroundColor: theme.colors['Background'] },
        dimensions.width
      )}
    >
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <DesktopHeader13024SignedInBlock />
        )}
      </>
      {/* Page Title Block */}
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                borderBottomWidth: 2,
                borderColor: theme.colors['Akurie green'],
                height: [
                  { minWidth: Breakpoints.Mobile, value: 120 },
                  { minWidth: Breakpoints.Laptop, value: 60 },
                ],
              },
              dimensions.width
            )}
          >
            <ImageBackground
              resizeMode={'cover'}
              source={Images.Asset13}
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: theme.colors['Akurie Dark Blue'],
                  flexDirection: 'row',
                  height: '100%',
                  justifyContent: {
                    minWidth: Breakpoints.Laptop,
                    value: 'space-around',
                  },
                  paddingLeft: { minWidth: Breakpoints.Laptop, value: '20%' },
                  paddingRight: { minWidth: Breakpoints.Laptop, value: '20%' },
                },
                dimensions.width
              )}
            >
              {/* Left Side Frame */}
              <View
                style={StyleSheet.applyWidth(
                  { paddingLeft: 9, paddingRight: 9 },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    try {
                      navigation.goBack();
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* Icon Flex Frame */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexGrow: 0,
                        flexShrink: 0,
                        paddingBottom: 12,
                        paddingLeft: 12,
                        paddingRight: 12,
                        paddingTop: 12,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Icon  */}
                    <Icon
                      color={theme.colors['Background']}
                      name={'Ionicons/arrow-back-outline'}
                      size={24}
                    />
                  </View>
                </Touchable>
              </View>
              {/* Middle Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: [
                      { minWidth: Breakpoints.Mobile, value: 'flex-start' },
                      { minWidth: Breakpoints.Laptop, value: 'center' },
                      { minWidth: Breakpoints.Tablet, value: 'center' },
                    ],
                    backgroundColor: 'rgba(8, 31, 61, 0.4)',
                    flexGrow: 1,
                    flexShrink: 0,
                    justifyContent: 'center',
                  },
                  dimensions.width
                )}
              >
                {/* Text Frame  */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexGrow: 0,
                      flexShrink: 0,
                      justifyContent: 'center',
                      paddingBottom: 12,
                      paddingLeft: 12,
                      paddingRight: 12,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  {/* Text 12 18 Regular */}
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Akurie green'],
                        fontFamily: 'Inter_400Regular',
                        fontSize: 22,
                        lineHeight: 18,
                      },
                      dimensions.width
                    )}
                  >
                    {'My Account'}
                  </Text>
                </View>
              </View>
              {/* RIght Side Frame */}
              <>
                {dimensions.width >= Breakpoints.Laptop ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingLeft: 9, paddingRight: 9 },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('RootNavigator');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {/* Icon Flex Frame */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexGrow: 0,
                            flexShrink: 0,
                            paddingBottom: 12,
                            paddingLeft: 12,
                            paddingRight: 12,
                            paddingTop: 12,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Icon  */}
                        <Icon
                          color={theme.colors['Background']}
                          name={'AntDesign/home'}
                          size={24}
                        />
                      </View>
                    </Touchable>
                  </View>
                )}
              </>
            </ImageBackground>
          </View>
        )}
      </>
      <ImageBackground
        resizeMode={'cover'}
        source={Images.FirefoxPHKyvBthWe}
        style={StyleSheet.applyWidth(
          { backgroundColor: theme.colors['Background'], height: '100%' },
          dimensions.width
        )}
      >
        {/* Greeting View 2 */}
        <>
          {dimensions.width >= Breakpoints.Laptop ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'stretch',
                  backgroundColor: theme.colors['Akurie Dark Blue'],
                  borderBottomWidth: 2,
                  borderColor: theme.colors['Akurie green'],
                  flexDirection: 'row',
                  height: '12%',
                  justifyContent: 'space-between',
                  paddingLeft: 30,
                  paddingRight: 30,
                  width: '100%',
                  zIndex: 9,
                },
                dimensions.width
              )}
            >
              {/* View 3 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: [
                      { minWidth: Breakpoints.Mobile, value: 'flex-start' },
                      { minWidth: Breakpoints.Tablet, value: 'flex-start' },
                    ],
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: '50%',
                  },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'contain'}
                  source={Images.MainLogoUpdate1u2}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'],
                      {
                        height: [
                          { minWidth: Breakpoints.Desktop, value: 100 },
                          { minWidth: Breakpoints.Mobile, value: 100 },
                        ],
                        width: [
                          { minWidth: Breakpoints.Desktop, value: 180 },
                          { minWidth: Breakpoints.BigScreen, value: 180 },
                          { minWidth: Breakpoints.Laptop, value: 180 },
                          { minWidth: Breakpoints.Tablet, value: 180 },
                          { minWidth: Breakpoints.Mobile, value: 180 },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                />
              </View>

              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    height: '100%',
                    justifyContent: 'flex-end',
                    minWidth: 80,
                  },
                  dimensions.width
                )}
              ></View>
              <>
                {changebus_view ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'flex-end', justifyContent: 'center' },
                      dimensions.width
                    )}
                  >
                    <>
                      {topMenu_view ? null : (
                        <Circle
                          size={13}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.CircleStyles(theme)['Circle'],
                              {
                                backgroundColor: theme.colors['Akurie Primary'],
                              }
                            ),
                            dimensions.width
                          )}
                        ></Circle>
                      )}
                    </>
                    <>
                      {topMenu_view ? null : (
                        <IconButton
                          color={theme.colors['Akurie green']}
                          icon={'FontAwesome/user-o'}
                          onPress={() => {
                            try {
                              setTopMenu_view(true);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          size={32}
                          style={StyleSheet.applyWidth(
                            { marginLeft: 10, position: 'relative', top: -6 },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* Icon Button 2 */}
                    <>
                      {!topMenu_view ? null : (
                        <IconButton
                          color={theme.colors['Akurie green']}
                          icon={'AntDesign/close'}
                          onPress={() => {
                            try {
                              setTopMenu_view(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          size={36}
                          style={StyleSheet.applyWidth(
                            { marginLeft: 10, position: 'relative', top: -6 },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                )}
              </>
            </View>
          )}
        </>
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              backgroundColor: theme.colors['Akurie Dark Blue'],
              borderBottomWidth: 0.1,
              borderColor: theme.colors['Akurie green'],
              borderTopWidth: 0.1,
              height: '100%',
              justifyContent: 'flex-start',
              opacity: 1,
              width: '100%',
            },
            dimensions.width
          )}
        >
          {/* Profile Header 2 */}
          <>
            {dimensions.width >= Breakpoints.Laptop ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    borderColor: theme.colors['Akurie green'],
                    justifyContent: 'center',
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                <AuthAkurieApi.FetchGetSpecificUserGET
                  user_id={Constants['ak_userID']}
                >
                  {({ loading, error, data, refetchGetSpecificUser }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <>
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors['Strong_Inverse'],
                              fontFamily: 'Inter_700Bold',
                              fontSize: 21,
                              marginTop: 20,
                            },
                            dimensions.width
                          )}
                        >
                          {fetchData?.First_name} {fetchData?.Last_name}
                        </Text>

                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'row',
                              marginBottom: 20,
                              marginTop: 3,
                              paddingLeft: 10,
                              paddingRight: 10,
                            },
                            dimensions.width
                          )}
                        >
                          <Icon
                            color={theme.colors['Akurie Primary']}
                            name={'Foundation/torso-business'}
                            size={15}
                          />
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Strong_Inverse'],
                                fontFamily: 'Inter_600SemiBold',
                                fontSize: 16,
                                paddingLeft: 8,
                                paddingRight: 5,
                                textAlign: 'center',
                                textTransform: 'capitalize',
                              },
                              dimensions.width
                            )}
                          >
                            {fetchData?.title_or_position}
                            {' - '}
                            {Constants['ak_businessName']}
                          </Text>
                        </View>

                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors['Akurie green'],
                              fontFamily: 'Inter_600SemiBold',
                              fontSize: 16,
                              paddingBottom: 8,
                              paddingTop: 2,
                              textAlign: 'center',
                            },
                            dimensions.width
                          )}
                        >
                          {'Current Plan: '}
                          {fetchData?.Subscription_Type}
                        </Text>
                        {/* Text 2 */}
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors['Light'],
                              fontSize: 12,
                              paddingBottom: 8,
                              paddingTop: 2,
                              textAlign: 'center',
                            },
                            dimensions.width
                          )}
                        >
                          {'UUID: '}
                          {fetchData?.user_id_code}
                        </Text>
                      </>
                    );
                  }}
                </AuthAkurieApi.FetchGetSpecificUserGET>
              </View>
            )}
          </>
          <ImageBackground
            resizeMode={'cover'}
            source={Images.FirefoxPHKyvBthWe}
            style={StyleSheet.applyWidth(
              {
                alignItems: [
                  { minWidth: Breakpoints.BigScreen, value: 'center' },
                  { minWidth: Breakpoints.Laptop, value: 'center' },
                ],
                backgroundColor: theme.colors['Surface'],
                borderColor: theme.colors['Akurie green'],
                borderTopLeftRadius: 12,
                borderTopWidth: 2,
                flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
                height: [
                  { minWidth: Breakpoints.Mobile, value: '98%' },
                  { minWidth: Breakpoints.Laptop, value: '90%' },
                  { minWidth: Breakpoints.Desktop, value: '90%' },
                ],
                justifyContent: {
                  minWidth: Breakpoints.BigScreen,
                  value: 'center',
                },
                paddingLeft: [
                  { minWidth: Breakpoints.Mobile, value: '5%' },
                  { minWidth: Breakpoints.Tablet, value: '20%' },
                ],
                paddingRight: [
                  { minWidth: Breakpoints.Mobile, value: '5%' },
                  { minWidth: Breakpoints.Tablet, value: '20%' },
                ],
                width: '100%',
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: {
                    minWidth: Breakpoints.Laptop,
                    value: theme.colors['Akurie Dark Blue'],
                  },
                  borderRadius: { minWidth: Breakpoints.Laptop, value: 9 },
                  height: [
                    { minWidth: Breakpoints.Laptop, value: '80%' },
                    { minWidth: Breakpoints.BigScreen, value: '50%' },
                  ],
                  justifyContent: {
                    minWidth: Breakpoints.Laptop,
                    value: 'center',
                  },
                  marginRight: { minWidth: Breakpoints.Laptop, value: 30 },
                  marginTop: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Laptop, value: 0 },
                  ],
                  width: [
                    { minWidth: Breakpoints.Mobile, value: '100%' },
                    { minWidth: Breakpoints.Laptop, value: '50%' },
                  ],
                },
                dimensions.width
              )}
            >
              {/* Profile Header */}
              <>
                {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        borderColor: theme.colors['Akurie green'],
                        justifyContent: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <AuthAkurieApi.FetchGetSpecificUserGET
                      user_id={Constants['ak_userID']}
                    >
                      {({ loading, error, data, refetchGetSpecificUser }) => {
                        const fetchData = data?.json;
                        if (loading) {
                          return <ActivityIndicator />;
                        }

                        if (
                          error ||
                          data?.status < 200 ||
                          data?.status >= 300
                        ) {
                          return <ActivityIndicator />;
                        }

                        return (
                          <>
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors['Strong_Inverse'],
                                  fontFamily: 'Inter_700Bold',
                                  fontSize: 21,
                                  marginTop: 20,
                                },
                                dimensions.width
                              )}
                            >
                              {fetchData?.First_name} {fetchData?.Last_name}
                            </Text>

                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                  marginBottom: 20,
                                  marginTop: 3,
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                },
                                dimensions.width
                              )}
                            >
                              <Icon
                                color={theme.colors['Akurie Primary']}
                                name={'Foundation/torso-business'}
                                size={15}
                              />
                              <Text
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors['Strong_Inverse'],
                                    fontFamily: 'Inter_600SemiBold',
                                    fontSize: 16,
                                    paddingLeft: 8,
                                    paddingRight: 5,
                                    textAlign: 'center',
                                    textTransform: 'capitalize',
                                  },
                                  dimensions.width
                                )}
                              >
                                {fetchData?.title_or_position}
                                {' - '}
                                {Constants['ak_businessName']}
                              </Text>
                            </View>
                            {/* View 2 */}
                            <View></View>

                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors['Akurie green'],
                                  fontFamily: 'Inter_600SemiBold',
                                  fontSize: 16,
                                  paddingBottom: 8,
                                  paddingTop: 2,
                                  textAlign: 'center',
                                },
                                dimensions.width
                              )}
                            >
                              {'Current Plan: '}
                              {fetchData?.Subscription_Type}
                            </Text>
                            {/* Text 2 */}
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors['Light'],
                                  fontSize: 12,
                                  paddingBottom: 8,
                                  paddingTop: 2,
                                  textAlign: 'center',
                                },
                                dimensions.width
                              )}
                            >
                              {'UUID: '}
                              {fetchData?.user_id_code}
                            </Text>
                          </>
                        );
                      }}
                    </AuthAkurieApi.FetchGetSpecificUserGET>
                  </View>
                )}
              </>
            </View>
            {/* View 2 */}
            <>
              {changebus_view ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Laptop,
                        value: 'stretch',
                      },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      paddingLeft: {
                        minWidth: Breakpoints.Desktop,
                        value: '5%',
                      },
                      paddingRight: {
                        minWidth: Breakpoints.Desktop,
                        value: '5%',
                      },
                      width: { minWidth: Breakpoints.Laptop, value: '50%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  />
                  {/* Top options 2 */}
                  <Surface
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Akurie Dark Blue'],
                        borderBottomWidth: 1,
                        borderColor: theme.colors['Divider'],
                        borderLeftWidth: 1,
                        borderRadius: 10,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        justifyContent: 'center',
                        marginLeft: 5,
                        marginRight: 5,
                        marginTop: 10,
                        minHeight: 40,
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('PaywallScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            borderBottomWidth: 1,
                            borderColor: theme.colors['Divider'],
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            paddingBottom: 12,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 12,
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', flexDirection: 'row' },
                            dimensions.width
                          )}
                        >
                          <Icon
                            color={theme.colors['Akurie green']}
                            name={'MaterialCommunityIcons/account-box'}
                            size={24}
                          />
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Akurie green'],
                                fontFamily: 'Inter_500Medium',
                                fontSize: [
                                  { minWidth: Breakpoints.Mobile, value: 15 },
                                  { minWidth: Breakpoints.Laptop, value: 14 },
                                ],
                                paddingLeft: 16,
                              },
                              dimensions.width
                            )}
                          >
                            {'Manage Current Subscription'}
                          </Text>
                        </View>
                        <Icon
                          color={theme.colors['Akurie green']}
                          name={'Entypo/chevron-right'}
                          size={24}
                        />
                      </View>
                    </Touchable>
                  </Surface>
                  {/* Top options */}
                  <Surface
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: theme.colors['Divider'],
                        borderLeftWidth: 1,
                        borderRadius: 10,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        justifyContent: 'center',
                        marginLeft: 5,
                        marginRight: 5,
                        marginTop: 16,
                        minHeight: 40,
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('PersonalProfileInfoScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            borderBottomWidth: 1,
                            borderColor: theme.colors['Divider'],
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            paddingBottom: 12,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 12,
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', flexDirection: 'row' },
                            dimensions.width
                          )}
                        >
                          <Icon
                            color={theme.colors['Akurie Dark Blue']}
                            name={'FontAwesome/user-circle'}
                            size={24}
                          />
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors.medium,
                                fontFamily: 'Inter_500Medium',
                                fontSize: [
                                  { minWidth: Breakpoints.Mobile, value: 15 },
                                  { minWidth: Breakpoints.Laptop, value: 14 },
                                ],
                                paddingLeft: 16,
                              },
                              dimensions.width
                            )}
                          >
                            {'Update Personal Information'}
                          </Text>
                        </View>
                        <Icon
                          color={theme.colors['Akurie Dark Blue']}
                          name={'Entypo/chevron-right'}
                          size={24}
                        />
                      </View>
                    </Touchable>
                  </Surface>
                  {/* Top options */}
                  <Surface
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: theme.colors['Divider'],
                        borderLeftWidth: 1,
                        borderRadius: 10,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        justifyContent: 'center',
                        marginLeft: 5,
                        marginRight: 5,
                        marginTop: 10,
                        minHeight: 40,
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate(
                            'UpdateBusinessInformationScreen'
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            borderBottomWidth: 1,
                            borderColor: theme.colors['Divider'],
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            paddingBottom: 12,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 12,
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', flexDirection: 'row' },
                            dimensions.width
                          )}
                        >
                          <Icon
                            color={theme.colors['Akurie Dark Blue']}
                            name={'Ionicons/business'}
                            size={24}
                          />
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors.medium,
                                fontFamily: 'Inter_500Medium',
                                fontSize: [
                                  { minWidth: Breakpoints.Mobile, value: 15 },
                                  { minWidth: Breakpoints.Laptop, value: 14 },
                                ],
                                paddingLeft: 16,
                              },
                              dimensions.width
                            )}
                          >
                            {'Update Business Information'}
                          </Text>
                        </View>
                        <Icon
                          color={theme.colors['Akurie Dark Blue']}
                          name={'Entypo/chevron-right'}
                          size={24}
                        />
                      </View>
                    </Touchable>
                  </Surface>
                  {/* Bottom option */}
                  <Surface
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: theme.colors.divider,
                        borderLeftWidth: 1,
                        borderRadius: 10,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        justifyContent: 'center',
                        marginLeft: 5,
                        marginRight: 5,
                        marginTop: 25,
                        minHeight: 40,
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('SecurityScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            borderBottomWidth: 1,
                            borderColor: theme.colors.divider,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            paddingBottom: 12,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 12,
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', flexDirection: 'row' },
                            dimensions.width
                          )}
                        >
                          <Icon
                            color={theme.colors['Akurie Dark Blue']}
                            name={'Entypo/key'}
                            size={24}
                          />
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors.medium,
                                fontFamily: 'Inter_500Medium',
                                fontSize: [
                                  { minWidth: Breakpoints.Mobile, value: 15 },
                                  { minWidth: Breakpoints.Laptop, value: 14 },
                                ],
                                paddingLeft: 16,
                              },
                              dimensions.width
                            )}
                          >
                            {'Security'}
                          </Text>
                        </View>
                        <Icon
                          color={theme.colors['Akurie Dark Blue']}
                          name={'Entypo/chevron-right'}
                          size={24}
                        />
                      </View>
                    </Touchable>

                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('GeneralSettingsScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            borderBottomWidth: 1,
                            borderColor: theme.colors.divider,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            paddingBottom: 12,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 12,
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', flexDirection: 'row' },
                            dimensions.width
                          )}
                        >
                          <Icon
                            color={theme.colors['Akurie Dark Blue']}
                            name={'Ionicons/ios-settings-sharp'}
                            size={24}
                          />
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors.medium,
                                fontFamily: 'Inter_500Medium',
                                fontSize: 15,
                                paddingLeft: 16,
                              },
                              dimensions.width
                            )}
                          >
                            {'Account Settings & Policies'}
                          </Text>
                        </View>
                        <Icon
                          color={theme.colors['Akurie Dark Blue']}
                          name={'Entypo/chevron-right'}
                          size={24}
                        />
                      </View>
                    </Touchable>
                  </Surface>

                  <View
                    style={StyleSheet.applyWidth(
                      { marginLeft: 16, marginRight: 16, marginTop: 20 },
                      dimensions.width
                    )}
                  >
                    {/* Sign Out Touchable  */}
                    <Touchable
                      onPress={() => {
                        try {
                          setGlobalVariableValue({
                            key: 'SKYE_AUTHORIZATION_HEADER',
                            value: 'False',
                          });
                          navigation.navigate('SplashScreenWelcomeScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {/* Button Wrapper */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: theme.colors['Error'],
                            borderRadius: 8,
                            flexGrow: 1,
                            flexShrink: 0,
                            justifyContent: 'center',
                            marginBottom: 10,
                            minHeight: 54,
                            paddingLeft: 15,
                            paddingRight: 15,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Sign Out Link */}
                        <Link
                          onPress={() => {
                            try {
                              setTopMenu_view(false);
                              setGlobalVariableValue({
                                key: 'SKYE_AUTHORIZATION_HEADER',
                                value: '',
                              });
                              navigation.navigate('LoginScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors['Strong Inverse'],
                              fontFamily: 'Inter_400Regular',
                              paddingBottom: 10,
                              paddingTop: 10,
                              textAlign: 'center',
                            },
                            dimensions.width
                          )}
                          title={'Sign Out'}
                        />
                      </View>
                    </Touchable>
                    {/* Sign Out Touchable  2 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.goBack();
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {/* Button Wrapper */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: theme.colors['Akurie Dark Blue'],
                            borderRadius: 8,
                            flexGrow: 1,
                            flexShrink: 0,
                            justifyContent: 'center',
                            marginBottom: 10,
                            minHeight: 54,
                            paddingLeft: 15,
                            paddingRight: 15,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Sign Out Link */}
                        <Link
                          onPress={() => {
                            try {
                              navigation.goBack();
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors['Strong Inverse'],
                              fontFamily: 'Inter_400Regular',
                              paddingBottom: 10,
                              paddingTop: 10,
                              textAlign: 'center',
                            },
                            dimensions.width
                          )}
                          title={'Return to Previous Page'}
                        />
                      </View>
                    </Touchable>
                  </View>
                </View>
              )}
            </>
          </ImageBackground>
        </View>
        <>
          {!Constants['ak_isLoading'] ? null : (
            <BlurView
              intensity={80}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.BlurViewStyles(theme)['Blur View'],
                  {
                    alignItems: 'center',
                    height: '100%',
                    justifyContent: 'center',
                    position: 'absolute',
                    width: '100%',
                    zIndex: 10,
                  }
                ),
                dimensions.width
              )}
              tint={'dark'}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: theme.colors['Akurie Dark Blue'],
                    borderRadius: 19,
                    bottom: 50,
                    height: [
                      { minWidth: Breakpoints.Mobile, value: '40%' },
                      { minWidth: Breakpoints.Tablet, value: '60%' },
                      { minWidth: Breakpoints.Laptop, value: '70%' },
                    ],
                    justifyContent: 'center',
                    opacity: 1,
                    width: [
                      { minWidth: Breakpoints.Tablet, value: '60%' },
                      { minWidth: Breakpoints.Laptop, value: '50%' },
                      { minWidth: Breakpoints.Desktop, value: '40%' },
                      { minWidth: Breakpoints.Mobile, value: '70%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                <IconButton
                  color={theme.colors['Akurie Primary']}
                  icon={'Feather/refresh-ccw'}
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'ak_isLoading',
                        value: false,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  size={32}
                  style={StyleSheet.applyWidth(
                    { position: 'absolute', right: 10, top: 10 },
                    dimensions.width
                  )}
                />
                <CircularProgress
                  animationDuration={7000}
                  color={theme.colors['Akurie green']}
                  indeterminate={true}
                  isAnimated={true}
                  lineCap={'round'}
                  showTrack={true}
                  startPosition={'bottom'}
                  style={StyleSheet.applyWidth(
                    { bottom: -20, height: '70%', width: '70%' },
                    dimensions.width
                  )}
                  thickness={15}
                  trackColor={theme.colors.divider}
                  trackLineCap={'round'}
                />
                <Text
                  accessible={true}
                  allowFontScaling={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Strong Inverse'],
                      fontFamily: 'Inter_600SemiBold',
                      fontSize: 20,
                      marginTop: 10,
                    }),
                    dimensions.width
                  )}
                >
                  {'Loading Your Business'}
                </Text>
              </View>
            </BlurView>
          )}
        </>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(DesktopMyAccountScreen);
