import React from 'react';
import DesktopHeader13024Block from '../components/DesktopHeader13024Block';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Circle,
  Icon,
  ScreenContainer,
  Spacer,
  Swiper,
  SwiperItem,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ImageBackground, StatusBar, Text, View } from 'react-native';

const WhatWeDoSignUpScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const [emailfield, setEmailfield] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [full_name_field, setFull_name_field] = React.useState('');
  const [nameValue, setNameValue] = React.useState('');
  const [name_input, setName_input] = React.useState('');
  const [namefield, setNamefield] = React.useState('');
  const [passwordfield, setPasswordfield] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const [textInputValue2, setTextInputValue2] = React.useState('');
  const [textInputValue3, setTextInputValue3] = React.useState('');
  const [textInputValue4, setTextInputValue4] = React.useState('');
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      StatusBar.setBarStyle('light-content');
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors['Custom Color_18'] },
        dimensions.width
      )}
    >
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <DesktopHeader13024Block />
        )}
      </>
      {/* View 2 */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: { minWidth: Breakpoints.Desktop, value: 'center' },
            height: [
              { minWidth: Breakpoints.Laptop, value: '100%' },
              { minWidth: Breakpoints.Tablet, value: '100%' },
            ],
            justifyContent: { minWidth: Breakpoints.Tablet, value: 'center' },
            paddingLeft: { minWidth: Breakpoints.Tablet, value: '10%' },
            paddingRight: { minWidth: Breakpoints.Tablet, value: '10%' },
            position: { minWidth: Breakpoints.Tablet, value: 'absolute' },
            width: [
              { minWidth: Breakpoints.Desktop, value: '100%' },
              { minWidth: Breakpoints.Tablet, value: '100%' },
            ],
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              marginTop: { minWidth: Breakpoints.Laptop, value: 80 },
              paddingBottom: { minWidth: Breakpoints.Tablet, value: 15 },
              paddingLeft: 15,
              paddingRight: 15,
              paddingTop: 15,
              width: { minWidth: Breakpoints.Desktop, value: '60%' },
            },
            dimensions.width
          )}
        >
          {/* Heading */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'flex-start', marginTop: 20 },
              dimensions.width
            )}
          >
            <Touchable
              style={StyleSheet.applyWidth({ width: '100%' }, dimensions.width)}
            >
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Akurie Primary'],
                    fontFamily: 'Inter_400Regular',
                    fontSize: 18,
                    paddingBottom: 10,
                    paddingRight: 8,
                    paddingTop: 40,
                    textAlign: 'center',
                    textTransform: 'none',
                  },
                  dimensions.width
                )}
              >
                {'How We Assist'}
              </Text>
            </Touchable>
          </View>

          <View
            style={StyleSheet.applyWidth({ height: 450 }, dimensions.width)}
          >
            <Swiper
              dotActiveColor={theme.colors['Akurie green']}
              dotColor={theme.colors['Medium']}
              dotsTouchable={true}
              style={StyleSheet.applyWidth(
                { height: '100%', width: '100%' },
                dimensions.width
              )}
            >
              <SwiperItem>
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Akurie green'],
                      fontFamily: 'Inter_600SemiBold',
                      fontSize: 32,
                      paddingBottom: 8,
                      paddingTop: 8,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                >
                  {'Goal Tracking &\nAccountability'}
                </Text>

                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Background'],
                      fontFamily: {
                        minWidth: Breakpoints.BigScreen,
                        value: 'Inter_400Regular',
                      },
                      fontSize: [
                        { minWidth: Breakpoints.Mobile, value: 16 },
                        { minWidth: Breakpoints.BigScreen, value: 14 },
                      ],
                      paddingBottom: 10,
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 10,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                >
                  {
                    'This includes tools that turn your goals and deadlines into a clear list of steps. If you fall behind, your assistant will reach out to offer help and keep you on track towards your objectives. We combine technology with personalized support to ensure your success.'
                  }
                </Text>
                <ImageBackground
                  resizeMode={'contain'}
                  source={Images.Asset4}
                  style={StyleSheet.applyWidth(
                    { height: '100%', paddingTop: 15, width: '100%' },
                    dimensions.width
                  )}
                />
              </SwiperItem>

              <SwiperItem>
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Akurie green'],
                      fontFamily: 'Inter_600SemiBold',
                      fontSize: 32,
                      paddingBottom: 8,
                      paddingTop: 8,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                >
                  {'Business Filings & Compliance Audits'}
                </Text>

                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Background'],
                      fontSize: 16,
                      paddingBottom: 10,
                      paddingLeft: 40,
                      paddingRight: 40,
                      paddingTop: 10,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                >
                  {
                    'Designed to simplify your state report filings and ensure compliance. Just input your required information, and ComplianceEase will not only track your filing deadlines but also handle the filings for you, charging only the state fees.'
                  }
                </Text>
                <ImageBackground
                  resizeMode={'contain'}
                  source={Images.Asset7}
                  style={StyleSheet.applyWidth(
                    { height: '100%', paddingTop: 15, width: '100%' },
                    dimensions.width
                  )}
                />
              </SwiperItem>
              {/* Swiper Item 2 */}
              <SwiperItem>
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Akurie green'],
                      fontFamily: 'Inter_600SemiBold',
                      fontSize: 32,
                      paddingBottom: 8,
                      paddingTop: 8,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                >
                  {'Business Financial\nTools & Resources'}
                </Text>

                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Background'],
                      fontSize: 16,
                      paddingBottom: 10,
                      paddingLeft: 40,
                      paddingRight: 40,
                      paddingTop: 10,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                >
                  {
                    'We provide financial resource tools for your business including estimated taxes calculators, business funding sources and live banking information with insights and spending habits.'
                  }
                </Text>
                <ImageBackground
                  resizeMode={'contain'}
                  source={Images.Asset9}
                  style={StyleSheet.applyWidth(
                    { height: '100%', paddingTop: 15, width: '100%' },
                    dimensions.width
                  )}
                />
              </SwiperItem>

              <SwiperItem>
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Akurie green'],
                      fontFamily: 'Inter_600SemiBold',
                      fontSize: 32,
                      paddingBottom: 8,
                      paddingTop: 8,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                >
                  {'Website & App Management'}
                </Text>

                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Background'],
                      fontSize: 16,
                      paddingBottom: 10,
                      paddingLeft: 50,
                      paddingRight: 50,
                      paddingTop: 10,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                >
                  {
                    "This tool effortlessly auto-generates a custom website for your business, perfectly aligned with Google's SEO and your industry's specific standards. With this, enjoy the convenience of having all your Akurie web services, from expert consulting to dynamic content updates, seamlessly integrated and managed."
                  }
                </Text>
                <ImageBackground
                  resizeMode={'contain'}
                  source={Images.Asset21}
                  style={StyleSheet.applyWidth(
                    { height: '90%', paddingTop: 15, width: '100%' },
                    dimensions.width
                  )}
                />
              </SwiperItem>
              {/* Swiper Item 3 */}
              <SwiperItem>
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Akurie green'],
                      fontFamily: 'Inter_600SemiBold',
                      fontSize: 32,
                      paddingBottom: 8,
                      paddingTop: 8,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                >
                  {'Business AI \nSolutions'}
                </Text>

                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Background'],
                      fontSize: 16,
                      paddingBottom: 10,
                      paddingLeft: 50,
                      paddingRight: 50,
                      paddingTop: 10,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                >
                  {
                    "We analyze your business's industry and explore innovative AI solutions tailored to enhance and optimize your operations."
                  }
                </Text>
                <ImageBackground
                  resizeMode={'contain'}
                  source={Images.Asset14}
                  style={StyleSheet.applyWidth(
                    { height: '90%', paddingTop: 15, width: '100%' },
                    dimensions.width
                  )}
                />
              </SwiperItem>
            </Swiper>
          </View>

          <Text
            style={StyleSheet.applyWidth(
              {
                color: theme.colors['Background'],
                fontFamily: 'Inter_600SemiBold',
                fontSize: 16,
                paddingTop: 15,
                textAlign: 'center',
              },
              dimensions.width
            )}
          >
            {'< < Swipe to see more > >'}
          </Text>
          <Spacer bottom={24} left={8} right={8} top={24} />
          {/* Button */}
          <View
            style={StyleSheet.applyWidth({ borderRadius: 0 }, dimensions.width)}
          >
            {/* Touchable Frame */}
            <View
              style={StyleSheet.applyWidth({ zIndex: 10 }, dimensions.width)}
            >
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('SignUpScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { borderRadius: 0 },
                  dimensions.width
                )}
              >
                {/* CTA Frame */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: theme.colors['Akurie Primary'],
                      borderRadius: 12,
                      flexDirection: 'row',
                      paddingBottom: 12,
                      paddingLeft: 18,
                      paddingRight: 18,
                      paddingTop: 12,
                      zIndex: 10,
                    },
                    dimensions.width
                  )}
                >
                  {/* Label Frame */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexGrow: 1,
                        flexShrink: 0,
                        justifyContent: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {/* Label */}
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.studilyWhite,
                          fontFamily: 'Inter_400Regular',
                          fontSize: 15,
                          lineHeight: 21,
                          marginLeft: 42,
                          textTransform: 'uppercase',
                        },
                        dimensions.width
                      )}
                    >
                      {'Continue'}
                    </Text>
                  </View>
                  {/* Icon Group Frame */}
                  <View>
                    <Circle bgColor={theme.colors.studilyOpacity25} size={42}>
                      {/* Icon Flex Frame */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexGrow: 0,
                            flexShrink: 0,
                            paddingBottom: 12,
                            paddingLeft: 12,
                            paddingRight: 12,
                            paddingTop: 12,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Icon  */}
                        <Icon
                          color={theme.colors['Akurie green']}
                          name={'AntDesign/arrowright'}
                          size={18}
                        />
                      </View>
                    </Circle>
                  </View>
                </View>
              </Touchable>
            </View>

            <Text
              style={StyleSheet.applyWidth(
                { color: theme.colors.error, textAlign: 'center' },
                dimensions.width
              )}
            >
              {errorMessage}
            </Text>
          </View>
          <Spacer bottom={16} left={8} right={8} top={16} />
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(WhatWeDoSignUpScreen);
