import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AuthAkurieApi from '../apis/AuthAkurieApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import getPushTokenUtil from '../utils/getPushToken';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Circle,
  Divider,
  Icon,
  ScreenContainer,
  Surface,
  Switch,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, ImageBackground, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const NotificationScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const authAkurieGetExpoPushTokenPOST =
    AuthAkurieApi.useGetExpoPushTokenPOST();

  return (
    <ScreenContainer hasSafeArea={true} scrollable={true}>
      <ImageBackground
        resizeMode={'cover'}
        style={StyleSheet.applyWidth(
          { height: '100%', width: '100%' },
          dimensions.width
        )}
      >
        {/* Navigation Frame */}
        <View
          style={StyleSheet.applyWidth(
            {
              borderBottomWidth: 2,
              borderColor: theme.colors['Akurie green'],
              height: 90,
              marginBottom: 15,
              marginTop: 15,
            },
            dimensions.width
          )}
        >
          <ImageBackground
            resizeMode={'cover'}
            source={Images.Asset13}
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors['Akurie Dark Blue'],
                flexDirection: 'row',
                height: '100%',
              },
              dimensions.width
            )}
          >
            {/* Left Side Frame */}
            <View
              style={StyleSheet.applyWidth(
                { paddingLeft: 9, paddingRight: 9 },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    navigation.goBack();
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* Icon Flex Frame */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexGrow: 0,
                      flexShrink: 0,
                      paddingBottom: 12,
                      paddingLeft: 12,
                      paddingRight: 12,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  {/* Icon  */}
                  <Icon
                    color={theme.colors['Background']}
                    name={'Ionicons/arrow-back-outline'}
                    size={24}
                  />
                </View>
              </Touchable>
            </View>
            {/* Middle Frame */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexGrow: 1,
                  flexShrink: 0,
                  justifyContent: 'center',
                },
                dimensions.width
              )}
            >
              {/* Text Frame  */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                {/* Text 12 18 Regular */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Akurie green'],
                      fontFamily: 'System',
                      fontSize: 22,
                      fontWeight: '400',
                      paddingTop: 8,
                    },
                    dimensions.width
                  )}
                >
                  {'Notification Settings'}
                </Text>
              </View>
            </View>
            {/* Left Side Frame */}
            <View
              style={StyleSheet.applyWidth(
                { paddingLeft: 9, paddingRight: 9 },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('UserDashboardScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* Icon Flex Frame */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexGrow: 0,
                      flexShrink: 0,
                      paddingBottom: 12,
                      paddingLeft: 12,
                      paddingRight: 12,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  {/* Icon  */}
                  <Icon
                    color={theme.colors['Background']}
                    name={'AntDesign/home'}
                    size={24}
                  />
                </View>
              </Touchable>
            </View>
          </ImageBackground>
        </View>

        <Surface
          style={StyleSheet.applyWidth(
            { minHeight: 40, paddingBottom: 30, paddingTop: 20 },
            dimensions.width
          )}
        >
          {/* Notifications */}
          <View
            style={StyleSheet.applyWidth(
              {
                paddingBottom: 32,
                paddingLeft: 32,
                paddingRight: 32,
                paddingTop: 24,
              },
              dimensions.width
            )}
          >
            {/* Direct Messages 2 */}
            <View>
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', flex: 1, width: '100%' },
                    dimensions.width
                  )}
                >
                  <Button
                    onPress={() => {
                      const handler = async () => {
                        try {
                          const tokenResponse = await getPushTokenUtil({
                            failMessage:
                              'Failed to get push token for push notification!',
                            deviceMessage:
                              'Must use physical device for Push Notifications',
                          });

                          (
                            await authAkurieGetExpoPushTokenPOST.mutateAsync({
                              pushToken: tokenResponse,
                              user_id: Constants['ak_userID'],
                            })
                          )?.json;
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        { borderRadius: 9, width: '80%' }
                      ),
                      dimensions.width
                    )}
                    title={'Enable Notifications'}
                  />
                  {/* Button 2 */}
                  <Button
                    onPress={() => {
                      const handler = async () => {
                        try {
                          const tokenResponse = await getPushTokenUtil({
                            failMessage:
                              'Failed to get push token for push notification!',
                            deviceMessage:
                              'Must use physical device for Push Notifications',
                          });

                          (
                            await authAkurieGetExpoPushTokenPOST.mutateAsync({
                              pushToken: tokenResponse,
                              user_id: Constants['ak_userID'],
                            })
                          )?.json;
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        {
                          backgroundColor: theme.colors['Error'],
                          borderRadius: 9,
                          marginTop: 20,
                          width: '80%',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Disable Notifications'}
                  />
                </View>
              </View>
            </View>
            <Divider
              color={theme.colors.divider}
              height={1}
              style={StyleSheet.applyWidth(
                { height: 1, marginBottom: 24, marginTop: 24 },
                dimensions.width
              )}
            />
            {/* Direct Messages */}
            <View>
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', flex: 1, flexDirection: 'row' },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                  >
                    <Text
                      allowFontScaling={true}
                      ellipsizeMode={'tail'}
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.strong,
                          fontFamily: 'Inter_500Medium',
                          typography: theme.typography.headline6,
                        },
                        dimensions.width
                      )}
                      textBreakStrategy={'highQuality'}
                    >
                      {'Email Updated'}
                    </Text>

                    <Text
                      allowFontScaling={true}
                      ellipsizeMode={'tail'}
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.medium,
                          fontFamily: 'Inter_300Light',
                          typography: theme.typography.caption,
                        },
                        dimensions.width
                      )}
                      textBreakStrategy={'highQuality'}
                    >
                      {
                        'You will be notified when your account email is changed'
                      }
                    </Text>
                  </View>
                </View>
                <Switch
                  activeTrackColor={theme.colors['Akurie Other Green']}
                  color={theme.colors['Akurie Primary']}
                />
              </View>
            </View>
            {/* Divider 2 */}
            <Divider
              color={theme.colors.divider}
              height={1}
              style={StyleSheet.applyWidth(
                { height: 1, marginBottom: 24, marginTop: 24 },
                dimensions.width
              )}
            />
            {/* Mentions */}
            <View>
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', flex: 1, flexDirection: 'row' },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                  >
                    <Text
                      allowFontScaling={true}
                      ellipsizeMode={'tail'}
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.strong,
                          fontFamily: 'Inter_500Medium',
                          typography: theme.typography.headline6,
                        },
                        dimensions.width
                      )}
                      textBreakStrategy={'highQuality'}
                    >
                      {'Password Updated '}
                    </Text>

                    <Text
                      allowFontScaling={true}
                      ellipsizeMode={'tail'}
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.medium,
                          fontFamily: 'Inter_300Light',
                          typography: theme.typography.caption,
                        },
                        dimensions.width
                      )}
                      textBreakStrategy={'highQuality'}
                    >
                      {
                        'You will be notified when your account password is changed'
                      }
                    </Text>
                  </View>
                </View>
                <Switch
                  activeTrackColor={theme.colors['Akurie Other Green']}
                  color={theme.colors['Akurie Primary']}
                />
              </View>
            </View>
            <Divider
              color={theme.colors.divider}
              height={1}
              style={StyleSheet.applyWidth(
                { height: 1, marginBottom: 24, marginTop: 24 },
                dimensions.width
              )}
            />
            {/* Posts */}
            <View>
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', flex: 1, flexDirection: 'row' },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                  >
                    <Text
                      allowFontScaling={true}
                      ellipsizeMode={'tail'}
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.strong,
                          fontFamily: 'Inter_500Medium',
                          typography: theme.typography.headline6,
                        },
                        dimensions.width
                      )}
                      textBreakStrategy={'highQuality'}
                    >
                      {'Business Filings Alerts'}
                    </Text>

                    <Text
                      allowFontScaling={true}
                      ellipsizeMode={'tail'}
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.medium,
                          fontFamily: 'Inter_300Light',
                          typography: theme.typography.caption,
                        },
                        dimensions.width
                      )}
                      textBreakStrategy={'highQuality'}
                    >
                      {
                        'You will be notified when you have \nbusiness filings or deadlines approaching'
                      }
                    </Text>
                  </View>
                </View>
                <Switch
                  activeTrackColor={theme.colors['Akurie Other Green']}
                  color={theme.colors['Akurie Primary']}
                />
              </View>
            </View>
            <Divider
              color={theme.colors.divider}
              height={1}
              style={StyleSheet.applyWidth(
                { height: 1, marginBottom: 24, marginTop: 24 },
                dimensions.width
              )}
            />
            {/* App Updates */}
            <View>
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', flex: 1, flexDirection: 'row' },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                  >
                    <Text
                      allowFontScaling={true}
                      ellipsizeMode={'tail'}
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.strong,
                          fontFamily: 'Inter_500Medium',
                          typography: theme.typography.headline6,
                        },
                        dimensions.width
                      )}
                      textBreakStrategy={'highQuality'}
                    >
                      {'App Updates'}
                    </Text>

                    <Text
                      allowFontScaling={true}
                      ellipsizeMode={'tail'}
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.medium,
                          fontFamily: 'Inter_300Light',
                          typography: theme.typography.caption,
                        },
                        dimensions.width
                      )}
                      textBreakStrategy={'highQuality'}
                    >
                      {'New features and announcements'}
                    </Text>
                  </View>
                </View>
                <Switch
                  activeTrackColor={theme.colors['Akurie Other Green']}
                  color={theme.colors['Akurie Primary']}
                />
              </View>
            </View>
          </View>
        </Surface>
        {/* Permissions */}
        <View
          style={StyleSheet.applyWidth(
            { alignSelf: 'center', marginTop: 50 },
            dimensions.width
          )}
        >
          {/* Button */}
          <View
            style={StyleSheet.applyWidth({ borderRadius: 0 }, dimensions.width)}
          >
            <AuthAkurieApi.FetchMeGET>
              {({ loading, error, data, refetchMe }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <>
                    {/* Touchable Frame */}
                    <View
                      style={StyleSheet.applyWidth(
                        { zIndex: 10 },
                        dimensions.width
                      )}
                    >
                      <Touchable
                        style={StyleSheet.applyWidth(
                          { borderRadius: 0 },
                          dimensions.width
                        )}
                      >
                        {/* CTA Frame */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: theme.colors['Custom Color'],
                              borderRadius: 12,
                              flexDirection: 'row',
                              paddingBottom: 12,
                              paddingLeft: 18,
                              paddingRight: 18,
                              paddingTop: 12,
                              zIndex: 10,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Label Frame */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexGrow: 1,
                                flexShrink: 0,
                                justifyContent: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Label */}
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors.studilyWhite,
                                  fontFamily: 'Inter_500Medium',
                                  fontSize: 15,
                                  lineHeight: 21,
                                  marginLeft: 40,
                                  marginRight: 15,
                                  textTransform: 'uppercase',
                                },
                                dimensions.width
                              )}
                            >
                              {'View Permissions'}
                            </Text>
                          </View>
                          {/* Icon Group Frame */}
                          <View>
                            <Circle
                              bgColor={theme.colors.studilyOpacity25}
                              size={42}
                            >
                              {/* Icon Flex Frame */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    flexGrow: 0,
                                    flexShrink: 0,
                                    paddingBottom: 12,
                                    paddingLeft: 12,
                                    paddingRight: 12,
                                    paddingTop: 12,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Icon  */}
                                <Icon
                                  color={theme.colors['Akurie green']}
                                  name={'AntDesign/arrowright'}
                                  size={18}
                                />
                              </View>
                            </Circle>
                          </View>
                        </View>
                      </Touchable>
                    </View>
                  </>
                );
              }}
            </AuthAkurieApi.FetchMeGET>
            <Text
              style={StyleSheet.applyWidth(
                { color: theme.colors.error, textAlign: 'center' },
                dimensions.width
              )}
            >
              {null}
            </Text>
          </View>
        </View>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(NotificationScreen);
