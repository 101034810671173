import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import encodeQueryParam from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const completeTutorialPUT = (Constants, { users_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:DPK_ES21/users/tutorial_status/${
      typeof users_id === 'string' ? users_id : JSON.stringify(users_id ?? '')
    }`,
    {
      body: JSON.stringify({ key: 'value' }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    }
  ).then(res => handleResponse(res, handlers));

export const useCompleteTutorialPUT = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      completeTutorialPUT(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('user_details', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('user_detail');
        queryClient.invalidateQueries('user_details');
      },
    }
  );
};

export const deleteUserAccountDELETE = (
  Constants,
  { users_id },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:DPK_ES21/users/${
      typeof users_id === 'string' ? users_id : JSON.stringify(users_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  ).then(res => handleResponse(res, handlers));

export const useDeleteUserAccountDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteUserAccountDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('user_details', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('user_detail');
        queryClient.invalidateQueries('user_details');
      },
    }
  );
};

export const deleteUserAccount2DELETE = (
  Constants,
  { users_id },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:DPK_ES21/users/${
      typeof users_id === 'string' ? users_id : JSON.stringify(users_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  ).then(res => handleResponse(res, handlers));

export const useDeleteUserAccount2DELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteUserAccount2DELETE(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('user_details', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('user_detail');
        queryClient.invalidateQueries('user_details');
      },
    }
  );
};

export const editTimeZonePATCH = (
  Constants,
  { timezone, user_id },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:DPK_ES21/user/update/time_zone/${
      typeof user_id === 'string' ? user_id : JSON.stringify(user_id ?? '')
    }`,
    {
      body: JSON.stringify({ key: 'value' }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
    }
  ).then(res => handleResponse(res, handlers));

export const useEditTimeZonePATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => editTimeZonePATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('user_details', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('user_detail');
        queryClient.invalidateQueries('user_details');
      },
    }
  );
};

export const emailConfirmationConfirmGET = (
  Constants,
  { emailCode, user_id },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:DPK_ES21/auth/email_verification_confirm?user_id=${encodeQueryParam(
      `${typeof user_id === 'string' ? user_id : JSON.stringify(user_id ?? '')}`
    )}&emailCode=${encodeQueryParam(
      `${
        typeof emailCode === 'string'
          ? emailCode
          : JSON.stringify(emailCode ?? '')
      }`
    )}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useEmailConfirmationConfirmGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['email', args],
    () => emailConfirmationConfirmGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['emails']),
    }
  );
};

export const FetchEmailConfirmationConfirmGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  emailCode,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useEmailConfirmationConfirmGET(
    { emailCode, user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchEmailConfirmationConfirm: refetch,
  });
};

export const emailConfirmationSendGET = (Constants, { email }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:DPK_ES21/auth/email_verification_send?email=${encodeQueryParam(
      `${typeof email === 'string' ? email : JSON.stringify(email ?? '')}`
    )}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useEmailConfirmationSendGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['email', args],
    () => emailConfirmationSendGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['emails']),
    }
  );
};

export const FetchEmailConfirmationSendGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  email,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useEmailConfirmationSendGET(
    { email },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchEmailConfirmationSend: refetch,
  });
};

export const getAllUsersGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:DPK_ES21/user/get/profiles`, {
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
  }).then(res => handleResponse(res, handlers));

export const useGetAllUsersGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['user_details', args],
    () => getAllUsersGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetAllUsersGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetAllUsersGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetAllUsers: refetch });
};

export const getExpoPushTokenPOST = (
  Constants,
  { pushToken, user_id },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:DPK_ES21/getPushToken/${
      typeof user_id === 'string' ? user_id : JSON.stringify(user_id ?? '')
    }`,
    {
      body: JSON.stringify({ pushToken: pushToken }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useGetExpoPushTokenPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      getExpoPushTokenPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('notifcationToken', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('notifcationToken');
        queryClient.invalidateQueries('notifcationTokens');
      },
    }
  );
};

export const FetchGetExpoPushTokenPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  pushToken,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useGetExpoPushTokenPOST(
    { pushToken, user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetExpoPushToken: refetch });
};

export const getSimpleUserDataGET = (Constants, { id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:DPK_ES21/user/get/profiles/${
      typeof id === 'string' ? id : JSON.stringify(id ?? '')
    }/simple`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetSimpleUserDataGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['user_detail', args],
    () => getSimpleUserDataGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['user_details']),
    }
  );
};

export const FetchGetSimpleUserDataGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetSimpleUserDataGET(
    { id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetSimpleUserData: refetch });
};

export const getSpecificUserGET = (Constants, { user_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:DPK_ES21/user/get/profiles/${
      typeof user_id === 'string' ? user_id : JSON.stringify(user_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetSpecificUserGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['user_detail', args],
    () => getSpecificUserGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['user_details']),
    }
  );
};

export const FetchGetSpecificUserGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetSpecificUserGET(
    { user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetSpecificUser: refetch });
};

export const getSpecificUser2GET = (Constants, { user_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:DPK_ES21/user/get/profiles/${
      typeof user_id === 'string' ? user_id : JSON.stringify(user_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetSpecificUser2GET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['user_detail', args],
    () => getSpecificUser2GET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['user_details']),
    }
  );
};

export const FetchGetSpecificUser2GET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetSpecificUser2GET(
    { user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetSpecificUser2: refetch });
};

export const loginPOST = (Constants, { email, password }, handlers = {}) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:DPK_ES21/auth/login`, {
    body: JSON.stringify({ email: email, password: password }),
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useLoginPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['authAkurieLoginPOST', args],
    () => loginPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['authAkurieLoginPOSTS']),
    }
  );
};

export const FetchLoginPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  email,
  password,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useLoginPOST(
    { email, password },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchLogin: refetch });
};

export const meGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:DPK_ES21/auth/me`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useMeGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['authAkurieMeGET', args],
    () => meGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['authAkurieMeGETS']),
    }
  );
};

export const FetchMeGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useMeGET({}, { refetchInterval, handlers: { onData, ...handlers } });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchMe: refetch });
};

export const newPasswordPOST = (
  Constants,
  { Password, Password_Confirm },
  handlers = {}
) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:DPK_ES21/auth/password_change`, {
    body: JSON.stringify({
      Password: Password,
      Password_Confirm: Password_Confirm,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useNewPasswordPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => newPasswordPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('security', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('security');
        queryClient.invalidateQueries('securities');
      },
    }
  );
};

export const FetchNewPasswordPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  Password,
  Password_Confirm,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useNewPasswordPOST(
    { Password, Password_Confirm },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchNewPassword: refetch });
};

export const passLoginPOST = (Constants, { id, password }, handlers = {}) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:DPK_ES21/auth/pass_login`, {
    body: JSON.stringify({ password: password, id: id }),
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const usePassLoginPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => passLoginPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('user_details', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('user_detail');
        queryClient.invalidateQueries('user_details');
      },
    }
  );
};

export const FetchPassLoginPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  id,
  password,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = usePassLoginPOST(
    { id, password },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchPassLogin: refetch });
};

export const passwordVerifyPOST = (
  Constants,
  { password, user_ID },
  handlers = {}
) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:DPK_ES21/auth/password_verify`, {
    body: JSON.stringify({ password: password, user_ID: user_ID }),
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const usePasswordVerifyPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      passwordVerifyPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('user_details', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('user_detail');
        queryClient.invalidateQueries('user_details');
      },
    }
  );
};

export const FetchPasswordVerifyPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  password,
  user_ID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = usePasswordVerifyPOST(
    { password, user_ID },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchPasswordVerify: refetch });
};

export const profileUpdatePATCH = (
  Constants,
  {
    First_name,
    Last_name,
    Networking_Consent,
    email,
    home_state,
    title_or_position,
    user_id,
  },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:DPK_ES21/user/update/${
      typeof user_id === 'string' ? user_id : JSON.stringify(user_id ?? '')
    }`,
    {
      body: JSON.stringify({
        user_id: user_id,
        First_name: First_name,
        Last_name: Last_name,
        title_or_position: title_or_position,
        email: email,
        home_state: home_state,
        Networking_Consent: Networking_Consent,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
    }
  ).then(res => handleResponse(res, handlers));

export const useProfileUpdatePATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      profileUpdatePATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('user_details', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('user_detail');
        queryClient.invalidateQueries('user_details');
      },
    }
  );
};

export const signupPOST = (
  Constants,
  {
    First_name,
    Last_name,
    Networking_Consent,
    Password,
    birthday,
    email,
    home_state,
    title_or_position,
  },
  handlers = {}
) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:DPK_ES21/auth/signup`, {
    body: JSON.stringify({
      First_name: First_name,
      email: email,
      Last_name: Last_name,
      birthday: birthday,
      title_or_position: title_or_position,
      home_state: home_state,
      Password: Password,
      Networking_Consent: Networking_Consent,
    }),
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useSignupPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['authAkurieSignupPOST', args],
    () => signupPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['authAkurieSignupPOSTS']),
    }
  );
};

export const FetchSignupPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  First_name,
  Last_name,
  Networking_Consent,
  Password,
  birthday,
  email,
  home_state,
  title_or_position,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useSignupPOST(
    {
      First_name,
      Last_name,
      Networking_Consent,
      Password,
      birthday,
      email,
      home_state,
      title_or_position,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchSignup: refetch });
};

export const signupSoftCheckPOST = (
  Constants,
  { first_nam, last_name, state },
  handlers = {}
) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:DPK_ES21/Signup/soft_check`, {
    body: JSON.stringify({
      first_name: first_nam,
      last_name: last_name,
      state: state,
    }),
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useSignupSoftCheckPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      signupSoftCheckPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('soft_check', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('soft_check');
        queryClient.invalidateQueries('soft_checks');
      },
    }
  );
};

export const FetchSignupSoftCheckPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  first_nam,
  last_name,
  state,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useSignupSoftCheckPOST(
    { first_nam, last_name, state },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchSignupSoftCheck: refetch });
};

export const subscriptionAvailabilityGET = (
  Constants,
  { user_id },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:DPK_ES21/Subscription_Status/current/${
      typeof user_id === 'string' ? user_id : JSON.stringify(user_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useSubscriptionAvailabilityGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['sub_availablitiy', args],
    () => subscriptionAvailabilityGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['sub_availablitiys']),
    }
  );
};

export const FetchSubscriptionAvailabilityGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useSubscriptionAvailabilityGET(
    { user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchSubscriptionAvailability: refetch,
  });
};

export const tempLoginPOST = (Constants, { email, password }, handlers = {}) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:DPK_ES21/auth/login_temp`, {
    body: JSON.stringify({ email: email, password: password }),
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useTempLoginPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['security', args],
    () => tempLoginPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['securities']),
    }
  );
};

export const FetchTempLoginPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  email,
  password,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useTempLoginPOST(
    { email, password },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchTempLogin: refetch });
};

export const tempPasswordGET = (Constants, { email }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:DPK_ES21/auth/temp_password?email=${encodeQueryParam(
      `${typeof email === 'string' ? email : JSON.stringify(email ?? '')}`
    )}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useTempPasswordGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['security', args],
    () => tempPasswordGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['securities']),
    }
  );
};

export const FetchTempPasswordGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  email,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useTempPasswordGET(
    { email },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchTempPassword: refetch });
};

export const tokenCheckGET = (Constants, { user_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:DPK_ES21/getPushToken/check/${
      typeof user_id === 'string' ? user_id : JSON.stringify(user_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useTokenCheckGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['notifcationToken', args],
    () => tokenCheckGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['notifcationTokens']),
    }
  );
};

export const FetchTokenCheckGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useTokenCheckGET(
    { user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchTokenCheck: refetch });
};

export const userPasswordUpdatePATCH = (
  Constants,
  { confirm_password, new_password, password, user_id },
  handlers = {}
) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:DPK_ES21/user/security/update`, {
    body: JSON.stringify({
      password: password,
      user_id: user_id,
      new_password: new_password,
      confirm_password: confirm_password,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }).then(res => handleResponse(res, handlers));

export const useUserPasswordUpdatePATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      userPasswordUpdatePATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('user_details', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('user_detail');
        queryClient.invalidateQueries('user_details');
      },
    }
  );
};
