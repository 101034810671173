import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AuthAkurieApi from '../apis/AuthAkurieApi.js';
import * as Logs$SupportAkurieApi from '../apis/Logs$SupportAkurieApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import revCatLogin from '../global-functions/revCatLogin';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  IconButton,
  Link,
  ScreenContainer,
  Surface,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Image, ImageBackground, StatusBar, Text, View } from 'react-native';

const PasswordResetScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [emailview, setEmailview] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [loginEmailValue, setLoginEmailValue] = React.useState('');
  const [loginPasswordValue, setLoginPasswordValue] = React.useState('');
  const [passwordView, setPasswordView] = React.useState(false);
  const [successMsg, setSuccessMsg] = React.useState(false);
  const logs$SupportAkurieLoginLogPOST =
    Logs$SupportAkurieApi.useLoginLogPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      StatusBar.setBarStyle('dark-content');
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { alignItems: 'stretch', backgroundColor: theme.colors['Background'] },
        dimensions.width
      )}
    >
      {/* website header updated 10-31 */}
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <View
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ViewStyles(theme)[
                  'Akurie Header Desktop Header 9'
                ],
                {
                  borderBottomWidth: {
                    minWidth: Breakpoints.Laptop,
                    value: 0.5,
                  },
                  borderColor: {
                    minWidth: Breakpoints.Laptop,
                    value: theme.colors['Akurie green'],
                  },
                  height: { minWidth: Breakpoints.Laptop, value: 110 },
                  paddingLeft: { minWidth: Breakpoints.Laptop, value: '8%' },
                  paddingRight: { minWidth: Breakpoints.Laptop, value: '8%' },
                }
              ),
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  marginRight: { minWidth: Breakpoints.Laptop, value: 20 },
                  width: { minWidth: Breakpoints.Laptop, value: '20%' },
                },
                dimensions.width
              )}
            >
              <Image
                resizeMode={'contain'}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    height: { minWidth: Breakpoints.Laptop, value: 50 },
                    width: { minWidth: Breakpoints.Laptop, value: '100%' },
                  }),
                  dimensions.width
                )}
              />
            </View>
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
                  justifyContent: {
                    minWidth: Breakpoints.Laptop,
                    value: 'space-around',
                  },
                  marginRight: [
                    { minWidth: Breakpoints.Laptop, value: 20 },
                    { minWidth: Breakpoints.Desktop, value: 0 },
                  ],
                  width: { minWidth: Breakpoints.Laptop, value: '50%' },
                },
                dimensions.width
              )}
            >
              <Link
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'], {
                    color: {
                      minWidth: Breakpoints.Laptop,
                      value: theme.colors['Strong_Inverse'],
                    },
                    fontFamily: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: 'Inter_500Medium',
                      },
                      {
                        minWidth: Breakpoints.BigScreen,
                        value: 'Inter_400Regular',
                      },
                    ],
                    fontSize: [
                      { minWidth: Breakpoints.Laptop, value: 12 },
                      { minWidth: Breakpoints.Desktop, value: 14 },
                    ],
                  }),
                  dimensions.width
                )}
                title={'Home'}
              />
              <Link
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'], {
                    color: {
                      minWidth: Breakpoints.Laptop,
                      value: theme.colors['Strong_Inverse'],
                    },
                    fontFamily: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: 'Inter_500Medium',
                      },
                      {
                        minWidth: Breakpoints.BigScreen,
                        value: 'Inter_400Regular',
                      },
                    ],
                    fontSize: [
                      { minWidth: Breakpoints.Laptop, value: 12 },
                      { minWidth: Breakpoints.Desktop, value: 14 },
                    ],
                  }),
                  dimensions.width
                )}
                title={'Networking Events'}
              />
              <Link
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'], {
                    color: {
                      minWidth: Breakpoints.Laptop,
                      value: theme.colors['Strong_Inverse'],
                    },
                    fontFamily: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: 'Inter_500Medium',
                      },
                      {
                        minWidth: Breakpoints.BigScreen,
                        value: 'Inter_400Regular',
                      },
                    ],
                    fontSize: [
                      { minWidth: Breakpoints.Laptop, value: 12 },
                      { minWidth: Breakpoints.Desktop, value: 14 },
                    ],
                  }),
                  dimensions.width
                )}
                title={'Features'}
              />
              <Link
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'], {
                    color: {
                      minWidth: Breakpoints.Laptop,
                      value: theme.colors['Strong_Inverse'],
                    },
                    fontFamily: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: 'Inter_500Medium',
                      },
                      {
                        minWidth: Breakpoints.BigScreen,
                        value: 'Inter_400Regular',
                      },
                    ],
                    fontSize: [
                      { minWidth: Breakpoints.Laptop, value: 12 },
                      { minWidth: Breakpoints.Desktop, value: 14 },
                    ],
                  }),
                  dimensions.width
                )}
                title={'Pricing'}
              />
              <Link
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'], {
                    color: {
                      minWidth: Breakpoints.Laptop,
                      value: theme.colors['Strong_Inverse'],
                    },
                    fontFamily: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: 'Inter_500Medium',
                      },
                      {
                        minWidth: Breakpoints.BigScreen,
                        value: 'Inter_400Regular',
                      },
                    ],
                    fontSize: [
                      { minWidth: Breakpoints.Laptop, value: 12 },
                      { minWidth: Breakpoints.Desktop, value: 14 },
                    ],
                  }),
                  dimensions.width
                )}
                title={'Contact Us'}
              />
            </View>
            {/* View 3 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: [
                    { minWidth: Breakpoints.BigScreen, value: 'center' },
                    { minWidth: Breakpoints.Desktop, value: 'center' },
                  ],
                  flexDirection: [
                    { minWidth: Breakpoints.BigScreen, value: 'row' },
                    { minWidth: Breakpoints.Desktop, value: 'row' },
                    { minWidth: Breakpoints.Laptop, value: 'row' },
                  ],
                  justifyContent: {
                    minWidth: Breakpoints.Laptop,
                    value: 'center',
                  },
                  marginLeft: { minWidth: Breakpoints.Desktop, value: 20 },
                  marginRight: { minWidth: Breakpoints.Desktop, value: 20 },
                  width: [
                    { minWidth: Breakpoints.Laptop, value: '30%' },
                    { minWidth: Breakpoints.Desktop, value: '20%' },
                    { minWidth: Breakpoints.BigScreen, value: '15%' },
                  ],
                },
                dimensions.width
              )}
            >
              {/* Touchable Frame 2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: {
                      minWidth: Breakpoints.Laptop,
                      value: 'flex-end',
                    },
                    marginRight: { minWidth: Breakpoints.Laptop, value: 5 },
                    width: [
                      { minWidth: Breakpoints.Laptop, value: '50%' },
                      { minWidth: Breakpoints.Mobile, value: '100%' },
                    ],
                    zIndex: 10,
                  },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    const handler = async () => {
                      try {
                        const loginResponse = (
                          await AuthAkurieApi.loginPOST(Constants, {
                            email: loginEmailValue,
                            password: loginPasswordValue,
                          })
                        )?.json;
                        const authToken = loginResponse?.authToken;
                        const Error_Msg = loginResponse?.message;
                        setGlobalVariableValue({
                          key: 'ak_errorMessage',
                          value: Error_Msg,
                        });
                        if (Error_Msg) {
                          return;
                        }
                        setGlobalVariableValue({
                          key: 'SKYE_AUTHORIZATION_HEADER',
                          value: 'Bearer ' + authToken,
                        });
                        const currentUserid = loginResponse?.authUser?.id;
                        setGlobalVariableValue({
                          key: 'ak_userID',
                          value: currentUserid,
                        });
                        const Businessid = loginResponse?.businessData.id;
                        setGlobalVariableValue({
                          key: 'ak_businessID',
                          value: Businessid,
                        });
                        (
                          await logs$SupportAkurieLoginLogPOST.mutateAsync({
                            user_id: currentUserid,
                          })
                        )?.json;
                        setGlobalVariableValue({
                          key: 'ak_errorMessage',
                          value: false,
                        });
                        setGlobalVariableValue({
                          key: 'ak_isLoading',
                          value: true,
                        });
                        await revCatLogin(
                          setGlobalVariableValue,
                          currentUserid
                        );
                        navigation.navigate('RootNavigator');
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  style={StyleSheet.applyWidth(
                    { borderRadius: 0 },
                    dimensions.width
                  )}
                >
                  {/* CTA Frame */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors['Akurie Primary'],
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Akurie green'],
                          },
                        ],
                        borderRadius: 12,
                        flexDirection: 'row',
                        maxWidth: { minWidth: Breakpoints.Laptop, value: 120 },
                        minHeight: { minWidth: Breakpoints.Laptop, value: 45 },
                        paddingBottom: [
                          { minWidth: Breakpoints.Mobile, value: 12 },
                          { minWidth: Breakpoints.Laptop, value: 6 },
                          { minWidth: Breakpoints.BigScreen, value: 12 },
                          { minWidth: Breakpoints.Desktop, value: 2 },
                        ],
                        paddingLeft: [
                          { minWidth: Breakpoints.Mobile, value: 18 },
                          { minWidth: Breakpoints.Laptop, value: 12 },
                        ],
                        paddingRight: [
                          { minWidth: Breakpoints.Mobile, value: 18 },
                          { minWidth: Breakpoints.Laptop, value: 12 },
                        ],
                        paddingTop: [
                          { minWidth: Breakpoints.Mobile, value: 12 },
                          { minWidth: Breakpoints.Laptop, value: 6 },
                          { minWidth: Breakpoints.BigScreen, value: 12 },
                          { minWidth: Breakpoints.Desktop, value: 2 },
                        ],
                        zIndex: 10,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Label Frame */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexGrow: 1,
                          flexShrink: 0,
                          justifyContent: 'center',
                        },
                        dimensions.width
                      )}
                    >
                      {/* Cotinue */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: [
                              {
                                minWidth: Breakpoints.Mobile,
                                value: theme.colors['Strong Inverse'],
                              },
                              {
                                minWidth: Breakpoints.Laptop,
                                value: theme.colors['Akurie Dark Blue'],
                              },
                            ],
                            fontFamily: [
                              {
                                minWidth: Breakpoints.Mobile,
                                value: 'Inter_500Medium',
                              },
                              {
                                minWidth: Breakpoints.Laptop,
                                value: 'Inter_400Regular',
                              },
                            ],
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 15 },
                              { minWidth: Breakpoints.Laptop, value: 13 },
                            ],
                            lineHeight: 21,
                            paddingBottom: {
                              minWidth: Breakpoints.Laptop,
                              value: 3,
                            },
                            paddingTop: {
                              minWidth: Breakpoints.Laptop,
                              value: 3,
                            },
                            textTransform: 'uppercase',
                          },
                          dimensions.width
                        )}
                      >
                        {'Try For Free'}
                      </Text>
                    </View>
                  </View>
                </Touchable>
              </View>
              {/* Touchable Frame 3 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: {
                      minWidth: Breakpoints.Laptop,
                      value: 'flex-start',
                    },
                    width: [
                      { minWidth: Breakpoints.Mobile, value: '100%' },
                      { minWidth: Breakpoints.Laptop, value: '50%' },
                    ],
                    zIndex: 10,
                  },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    const handler = async () => {
                      try {
                        const loginResponse = (
                          await AuthAkurieApi.loginPOST(Constants, {
                            email: loginEmailValue,
                            password: loginPasswordValue,
                          })
                        )?.json;
                        const authToken = loginResponse?.authToken;
                        const Error_Msg = loginResponse?.message;
                        setGlobalVariableValue({
                          key: 'ak_errorMessage',
                          value: Error_Msg,
                        });
                        if (Error_Msg) {
                          return;
                        }
                        setGlobalVariableValue({
                          key: 'SKYE_AUTHORIZATION_HEADER',
                          value: 'Bearer ' + authToken,
                        });
                        const currentUserid = loginResponse?.authUser?.id;
                        setGlobalVariableValue({
                          key: 'ak_userID',
                          value: currentUserid,
                        });
                        const Businessid = loginResponse?.businessData.id;
                        setGlobalVariableValue({
                          key: 'ak_businessID',
                          value: Businessid,
                        });
                        (
                          await logs$SupportAkurieLoginLogPOST.mutateAsync({
                            user_id: currentUserid,
                          })
                        )?.json;
                        setGlobalVariableValue({
                          key: 'ak_errorMessage',
                          value: false,
                        });
                        setGlobalVariableValue({
                          key: 'ak_isLoading',
                          value: true,
                        });
                        await revCatLogin(
                          setGlobalVariableValue,
                          currentUserid
                        );
                        navigation.navigate('RootNavigator');
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  style={StyleSheet.applyWidth(
                    { borderRadius: 0 },
                    dimensions.width
                  )}
                >
                  {/* CTA Frame */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors['Akurie Primary'],
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Akurie Primary'],
                          },
                        ],
                        borderRadius: 12,
                        flexDirection: 'row',
                        maxWidth: { minWidth: Breakpoints.Laptop, value: 120 },
                        minHeight: { minWidth: Breakpoints.Laptop, value: 45 },
                        paddingBottom: [
                          { minWidth: Breakpoints.Mobile, value: 12 },
                          { minWidth: Breakpoints.Laptop, value: 6 },
                          { minWidth: Breakpoints.BigScreen, value: 12 },
                          { minWidth: Breakpoints.Desktop, value: 2 },
                        ],
                        paddingLeft: [
                          { minWidth: Breakpoints.Mobile, value: 18 },
                          { minWidth: Breakpoints.Laptop, value: 12 },
                        ],
                        paddingRight: [
                          { minWidth: Breakpoints.Mobile, value: 18 },
                          { minWidth: Breakpoints.Laptop, value: 12 },
                        ],
                        paddingTop: [
                          { minWidth: Breakpoints.Mobile, value: 12 },
                          { minWidth: Breakpoints.Laptop, value: 6 },
                          { minWidth: Breakpoints.BigScreen, value: 12 },
                          { minWidth: Breakpoints.Desktop, value: 2 },
                        ],
                        zIndex: 10,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Label Frame */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexGrow: 1,
                          flexShrink: 0,
                          justifyContent: 'center',
                        },
                        dimensions.width
                      )}
                    >
                      {/* Cotinue */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: [
                              {
                                minWidth: Breakpoints.Mobile,
                                value: theme.colors['Strong Inverse'],
                              },
                              {
                                minWidth: Breakpoints.Laptop,
                                value: theme.colors['Akurie Dark Blue'],
                              },
                            ],
                            fontFamily: [
                              {
                                minWidth: Breakpoints.Mobile,
                                value: 'Inter_500Medium',
                              },
                              {
                                minWidth: Breakpoints.Laptop,
                                value: 'Inter_400Regular',
                              },
                            ],
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 15 },
                              { minWidth: Breakpoints.Laptop, value: 13 },
                            ],
                            lineHeight: 21,
                            paddingBottom: {
                              minWidth: Breakpoints.Laptop,
                              value: 3,
                            },
                            paddingTop: {
                              minWidth: Breakpoints.Laptop,
                              value: 3,
                            },
                            textTransform: 'uppercase',
                          },
                          dimensions.width
                        )}
                      >
                        {'Sign In'}
                      </Text>
                    </View>
                  </View>
                </Touchable>
              </View>
            </View>
          </View>
        )}
      </>
      <ImageBackground
        resizeMode={'cover'}
        source={Images.Waves}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ImageBackgroundStyles(theme)['Image Background'],
            {
              alignItems: 'center',
              height: '100%',
              justifyContent: 'center',
              width: '100%',
            }
          ),
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              backgroundColor: theme.colors['Akurie Dark Blue'],
              borderBottomWidth: 0.1,
              borderColor: theme.colors['Akurie green'],
              borderRadius: 19,
              borderTopWidth: 0.1,
              bottom: 50,
              height: [
                { minWidth: Breakpoints.Mobile, value: '75%' },
                { minWidth: Breakpoints.Tablet, value: '80%' },
                { minWidth: Breakpoints.Laptop, value: '60%' },
              ],
              justifyContent: 'center',
              opacity: 1,
              width: [
                { minWidth: Breakpoints.Mobile, value: '90%' },
                { minWidth: Breakpoints.Tablet, value: '70%' },
                { minWidth: Breakpoints.Desktop, value: '50%' },
              ],
            },
            dimensions.width
          )}
        >
          <Surface
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.SurfaceStyles(theme)['Surface'], {
                alignItems: 'center',
                borderRadius: 19,
                height: '80%',
                justifyContent: 'center',
                opacity: 1,
                padding: 20,
                width: '90%',
              }),
              dimensions.width
            )}
          >
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'flex-end', width: '100%' },
                dimensions.width
              )}
            >
              <IconButton
                color={theme.colors['Akurie Dark Blue']}
                icon={'AntDesign/closecircle'}
                onPress={() => {
                  try {
                    navigation.navigate('LoginScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                size={26}
              />
            </View>

            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginBottom: 10,
                  padding: 2,
                  width: '100%',
                },
                dimensions.width
              )}
            >
              <>
                {!emailview ? null : (
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Akurie Dark Blue'],
                          fontFamily: 'Inter_600SemiBold',
                          fontSize: 18,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Forgot My Password'}
                  </Text>
                )}
              </>
              {/* Enter Password */}
              <>
                {!passwordView ? null : (
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Akurie Dark Blue'],
                          fontFamily: 'Inter_600SemiBold',
                          fontSize: 18,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Enter Password'}
                  </Text>
                )}
              </>
            </View>

            <View
              style={StyleSheet.applyWidth(
                { marginBottom: 20, marginTop: 5 },
                dimensions.width
              )}
            >
              <>
                {!emailview ? null : (
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Inter_400Regular',
                          fontSize: 14,
                          marginBottom: 5,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {
                      'Please enter the email associated with your account and you will be sent a temporary password.'
                    }
                  </Text>
                )}
              </>
              {/* Text 2 */}
              <>
                {!passwordView ? null : (
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Inter_400Regular',
                          fontSize: 14,
                          marginBottom: 5,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {
                      'We have sent a temporary code to your email, valid for the next 15 minutes.'
                    }
                  </Text>
                )}
              </>
            </View>
            <>
              {!emailview ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'flex-start', width: '100%' },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        { textDecorationLine: 'none' }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Email:'}
                  </Text>
                  <TextInput
                    allowFontScaling={true}
                    autoCapitalize={'none'}
                    changeTextDelay={500}
                    onChangeText={newTextInputValue => {
                      try {
                        setLoginEmailValue(newTextInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'email@abc.com'}
                    placeholderTextColor={theme.colors['Light']}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextInputStyles(theme)['Text Input'],
                        {
                          color: theme.colors['Strong'],
                          height: 50,
                          width: '100%',
                        }
                      ),
                      dimensions.width
                    )}
                    value={loginEmailValue}
                  />
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Error'],
                          marginTop: 5,
                          textDecorationLine: 'underline',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {errorMessage}
                  </Text>
                  <Button
                    onPress={() => {
                      const handler = async () => {
                        try {
                          const tempResponse = (
                            await AuthAkurieApi.tempPasswordGET(Constants, {
                              email: loginEmailValue,
                            })
                          )?.json;
                          const errorMsg = tempResponse?.message;
                          setErrorMessage(errorMsg);
                          if (errorMsg) {
                            return;
                          }
                          setPasswordView(true);
                          setEmailview(false);
                          setErrorMessage(false);
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        {
                          backgroundColor: theme.colors['Akurie Primary'],
                          borderRadius: 10,
                          color: theme.colors['Strong_Inverse'],
                          fontFamily: 'Inter_400Regular',
                          marginBottom: 10,
                          marginTop: 20,
                          width: '100%',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Send Temporary Password'}
                  />
                </View>
              )}
            </>
            {/* View 3 */}
            <>
              {!passwordView ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'flex-start', width: '100%' },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Text'],
                      dimensions.width
                    )}
                  >
                    {'Temporary Password:'}
                  </Text>
                  <TextInput
                    allowFontScaling={true}
                    autoCapitalize={'none'}
                    changeTextDelay={500}
                    onChangeText={newTextInputValue => {
                      try {
                        setLoginPasswordValue(newTextInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'*******'}
                    placeholderTextColor={theme.colors['Light']}
                    secureTextEntry={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextInputStyles(theme)['Text Input'],
                        {
                          color: theme.colors['Strong'],
                          height: 50,
                          width: '100%',
                        }
                      ),
                      dimensions.width
                    )}
                    value={loginPasswordValue}
                  />
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Error'],
                          marginTop: 5,
                          textDecorationLine: 'underline',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {errorMessage}
                  </Text>
                  <Button
                    onPress={() => {
                      const handler = async () => {
                        try {
                          const loginResponse = (
                            await AuthAkurieApi.tempLoginPOST(Constants, {
                              email: loginEmailValue,
                              password: loginPasswordValue,
                            })
                          )?.json;
                          const errorMsg = loginResponse?.message;
                          const tempToken = loginResponse?.authToken;
                          setErrorMessage(errorMsg);
                          if (errorMsg) {
                            return;
                          }
                          setGlobalVariableValue({
                            key: 'SKYE_AUTHORIZATION_HEADER',
                            value: tempToken,
                          });
                          navigation.navigate('PasswordChangeScreen');
                          setErrorMessage(false);
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        {
                          backgroundColor: theme.colors['Akurie Primary'],
                          borderRadius: 10,
                          color: theme.colors['Strong_Inverse'],
                          fontFamily: 'Inter_400Regular',
                          marginBottom: 10,
                          marginTop: 20,
                          width: '100%',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Reset Password'}
                  />
                  {/* Button 2 */}
                  <Button
                    onPress={() => {
                      try {
                        setEmailview(true);
                        setPasswordView(false);
                        setErrorMessage(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        {
                          backgroundColor: theme.colors['Akurie Dark Blue'],
                          borderRadius: 10,
                          color: theme.colors['Strong_Inverse'],
                          fontFamily: 'Inter_400Regular',
                          marginBottom: 10,
                          marginTop: 5,
                          width: '100%',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Resend Code'}
                  />
                </View>
              )}
            </>
          </Surface>
        </View>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(PasswordResetScreen);
