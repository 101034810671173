import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AuthAkurieApi from '../apis/AuthAkurieApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  ScreenContainer,
  Surface,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  StatusBar,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const SignupPt12Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const isEqualTo = (thingOne, thingTwo) => {
    return thingOne === thingTwo;
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      StatusBar.setBarStyle('dark-content');
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasBottomSafeArea={false}
      hasSafeArea={false}
      hasTopSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors['Akurie Dark Blue'] },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'cover'}
        source={Images.FirefoxPHKyvBthWe}
        style={StyleSheet.applyWidth(
          {
            alignItems: [
              { minWidth: Breakpoints.BigScreen, value: 'center' },
              { minWidth: Breakpoints.Laptop, value: 'center' },
              { minWidth: Breakpoints.Tablet, value: 'center' },
            ],
            backgroundColor: {
              minWidth: Breakpoints.Laptop,
              value: theme.colors['Akurie Dark Blue'],
            },
            flexDirection: [
              { minWidth: Breakpoints.Laptop, value: 'row' },
              { minWidth: Breakpoints.Desktop, value: 'column' },
            ],
            flexWrap: 'wrap',
            height: '100%',
            justifyContent: [
              { minWidth: Breakpoints.BigScreen, value: 'center' },
              { minWidth: Breakpoints.Laptop, value: 'center' },
              { minWidth: Breakpoints.Tablet, value: 'center' },
              { minWidth: Breakpoints.Mobile, value: 'center' },
            ],
            paddingBottom: '10%',
            paddingLeft: [
              { minWidth: Breakpoints.Mobile, value: '5%' },
              { minWidth: Breakpoints.Tablet, value: '15%' },
              { minWidth: Breakpoints.Laptop, value: '0%' },
            ],
            paddingRight: [
              { minWidth: Breakpoints.Mobile, value: '5%' },
              { minWidth: Breakpoints.Tablet, value: '15%' },
              { minWidth: Breakpoints.Laptop, value: '0%' },
            ],
            paddingTop: '10%',
          },
          dimensions.width
        )}
      >
        {/* akurie pwa header logo */}
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <View
              style={StyleSheet.applyWidth(
                { width: { minWidth: Breakpoints.Laptop, value: '80%' } },
                dimensions.width
              )}
            >
              <Image
                resizeMode={'contain'}
                source={Images.MainLogoUpdate2u}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    height: { minWidth: Breakpoints.Laptop, value: 50 },
                    width: { minWidth: Breakpoints.Laptop, value: '30%' },
                  }),
                  dimensions.width
                )}
              />
            </View>
          )}
        </>
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: [
                { minWidth: Breakpoints.Tablet, value: 'center' },
                { minWidth: Breakpoints.Mobile, value: 'center' },
              ],
              backgroundColor: [
                {
                  minWidth: Breakpoints.Mobile,
                  value: theme.colors['Background'],
                },
                { minWidth: Breakpoints.Laptop, value: 'rgba(0, 0, 0, 0)' },
              ],
              borderRadius: 12,
              height: [
                { minWidth: Breakpoints.Mobile, value: '100%' },
                { minWidth: Breakpoints.Desktop, value: '90%' },
              ],
              justifyContent: [
                { minWidth: Breakpoints.Tablet, value: 'center' },
                { minWidth: Breakpoints.Mobile, value: 'center' },
              ],
              padding: 20,
              paddingBottom: { minWidth: Breakpoints.Tablet, value: 40 },
              paddingTop: 20,
              width: [
                { minWidth: Breakpoints.Mobile, value: '100%' },
                { minWidth: Breakpoints.Laptop, value: '80%' },
              ],
            },
            dimensions.width
          )}
        >
          {/* Unique Card Frame */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexGrow: 1,
                flexShrink: 0,
                height: [
                  { minWidth: Breakpoints.Tablet, value: '70%' },
                  { minWidth: Breakpoints.BigScreen, value: '70%' },
                  { minWidth: Breakpoints.Mobile, value: '100%' },
                ],
                marginBottom: { minWidth: Breakpoints.Tablet, value: 30 },
                marginTop: { minWidth: Breakpoints.Tablet, value: 30 },
                opacity: { minWidth: Breakpoints.Laptop, value: 0.95 },
                width: [
                  { minWidth: Breakpoints.Tablet, value: '70%' },
                  { minWidth: Breakpoints.Mobile, value: '100%' },
                  { minWidth: Breakpoints.Laptop, value: '50%' },
                ],
              },
              dimensions.width
            )}
          >
            {/* Content */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexGrow: 1,
                  flexShrink: 0,
                  justifyContent: 'center',
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* Text Frame  */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 5,
                  },
                  dimensions.width
                )}
              >
                <AuthAkurieApi.FetchGetSpecificUserGET
                  user_id={Constants['ak_userID']}
                >
                  {({ loading, error, data, refetchGetSpecificUser }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <>
                        {/* Almost Done */}
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors.strong,
                              fontFamily: 'Inter_600SemiBold',
                              fontSize: [
                                { minWidth: Breakpoints.Laptop, value: 32 },
                                { minWidth: Breakpoints.Mobile, value: 22 },
                              ],
                              marginTop: 40,
                              paddingLeft: 40,
                              paddingRight: 40,
                              textAlign: 'center',
                            },
                            dimensions.width
                          )}
                        >
                          {'Where would you like to start '}
                          {fetchData?.First_name}
                          {'!'}
                        </Text>
                      </>
                    );
                  }}
                </AuthAkurieApi.FetchGetSpecificUserGET>
                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: 10 },
                    dimensions.width
                  )}
                >
                  {/* Disclaimer */}
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Medium'],
                        fontFamily: [
                          {
                            minWidth: Breakpoints.Laptop,
                            value: 'Inter_400Regular',
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: 'Inter_400Regular',
                          },
                        ],
                        fontSize: [
                          { minWidth: Breakpoints.Laptop, value: 13 },
                          { minWidth: Breakpoints.Mobile, value: 14 },
                        ],
                        marginBottom: 15,
                        marginTop: 15,
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {'Select an option to continue'}
                  </Text>

                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('BusinessIdeaSetup1Screen', {
                          user_id_pass: props.route?.params?.user_id_pass ?? 7,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <Surface
                      elevation={3}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.SurfaceStyles(theme)['Surface'],
                          {
                            alignItems: 'center',
                            backgroundColor: theme.colors['Akurie Dark Blue'],
                            borderRadius: 9,
                            justifyContent: 'center',
                            marginBottom: 10,
                            marginTop: 10,
                            minHeight: 100,
                            paddingBottom: 10,
                            paddingTop: 10,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', flexDirection: 'row' },
                          dimensions.width
                        )}
                      >
                        <Icon
                          color={theme.colors['Akurie Primary']}
                          name={'MaterialCommunityIcons/lightbulb-on-outline'}
                          size={24}
                          style={StyleSheet.applyWidth(
                            { marginLeft: 5, marginRight: 5 },
                            dimensions.width
                          )}
                        />
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: theme.colors['Akurie green'],
                                fontFamily: 'Lato_700Bold',
                                fontSize: 18,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'A Business Idea'}
                        </Text>
                      </View>
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Strong Inverse'],
                              marginBottom: 10,
                              marginTop: 5,
                              textAlign: 'center',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {"Let's turn your idea into a business."}
                      </Text>
                    </Surface>
                  </Touchable>
                  {/* Touchable 2 */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('BusinessLLCSetupInfo1Screen', {
                          userID: props.route?.params?.user_id_pass ?? 7,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <Surface
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.SurfaceStyles(theme)['Surface'],
                          {
                            alignItems: 'center',
                            backgroundColor: theme.colors['Akurie Dark Blue'],
                            borderRadius: 9,
                            justifyContent: 'center',
                            marginBottom: 10,
                            marginTop: 10,
                            minHeight: 100,
                            paddingBottom: 10,
                            paddingTop: 10,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', flexDirection: 'row' },
                          dimensions.width
                        )}
                      >
                        <Icon
                          color={theme.colors['Akurie Primary']}
                          name={'Ionicons/rocket-outline'}
                          size={24}
                          style={StyleSheet.applyWidth(
                            { marginLeft: 5, marginRight: 5 },
                            dimensions.width
                          )}
                        />
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: theme.colors['Akurie green'],
                                fontFamily: 'Lato_700Bold',
                                fontSize: 18,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Launch My Business'}
                        </Text>
                      </View>
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Strong Inverse'],
                              marginBottom: 10,
                              marginTop: 5,
                              textAlign: 'center',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Get your business filed with the state.'}
                      </Text>
                    </Surface>
                  </Touchable>
                  {/* Touchable 3 */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('BusinessIdeaSetup1OtherScreen', {
                          user_id_pass: props.route?.params?.user_id_pass ?? 7,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <Surface
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.SurfaceStyles(theme)['Surface'],
                          {
                            alignItems: 'center',
                            backgroundColor: theme.colors['Akurie Dark Blue'],
                            borderRadius: 9,
                            justifyContent: 'center',
                            marginBottom: 10,
                            marginTop: 10,
                            minHeight: 100,
                            paddingBottom: 10,
                            paddingTop: 10,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', flexDirection: 'row' },
                          dimensions.width
                        )}
                      >
                        <Icon
                          color={theme.colors['Akurie Primary']}
                          name={'MaterialCommunityIcons/progress-wrench'}
                          size={24}
                          style={StyleSheet.applyWidth(
                            { marginLeft: 5, marginRight: 5 },
                            dimensions.width
                          )}
                        />
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: theme.colors['Akurie green'],
                                fontFamily: 'Lato_700Bold',
                                fontSize: 18,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'My Current Business'}
                        </Text>
                      </View>
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Strong Inverse'],
                              marginBottom: 10,
                              marginTop: 5,
                              textAlign: 'center',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {"Let's strengthen your current business."}
                      </Text>
                    </Surface>
                  </Touchable>
                </View>
              </View>
            </View>
          </View>
        </View>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(SignupPt12Screen);
