import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as Web$AppServicesApi from '../apis/Web$AppServicesApi.js';
import DesktopHeader13024SignedInBlock from '../components/DesktopHeader13024SignedInBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  IconButton,
  ScreenContainer,
  Surface,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  FlatList,
  ImageBackground,
  ScrollView,
  StatusBar,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const ManageMyServicesScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [appPresent, setAppPresent] = React.useState(false);
  const [itemBlurView, setItemBlurView] = React.useState(false);
  const [serviceID, setServiceID] = React.useState(651);
  const [websitePresent, setWebsitePresent] = React.useState(false);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      StatusBar.setBarStyle('dark-content');
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
    >
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <DesktopHeader13024SignedInBlock />
        )}
      </>
      <ImageBackground
        resizeMode={'cover'}
        source={Images.FirefoxPHKyvBthWe}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ImageBackgroundStyles(theme)['Image Background'],
            { height: '100%' }
          ),
          dimensions.width
        )}
      >
        {/* newBlurView */}
        <>
          {!itemBlurView ? null : (
            <BlurView
              intensity={80}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.BlurViewStyles(theme)['Blur View'],
                  {
                    alignItems: 'center',
                    height: '100%',
                    justifyContent: 'center',
                    position: 'absolute',
                    width: '100%',
                    zIndex: 10,
                  }
                ),
                dimensions.width
              )}
              tint={'default'}
            >
              <Web$AppServicesApi.FetchSpecificServiceGET
                business_id={Constants['ak_businessID']}
                serviceID={serviceID}
              >
                {({ loading, error, data, refetchSpecificService }) => {
                  const fetchData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: theme.colors['Akurie Dark Blue'],
                          borderBottomWidth: 0.1,
                          borderColor: theme.colors['Akurie green'],
                          borderRadius: 19,
                          borderTopWidth: 0.1,
                          bottom: 50,
                          height: [
                            { minWidth: Breakpoints.Tablet, value: '80%' },
                            { minWidth: Breakpoints.Laptop, value: '60%' },
                            { minWidth: Breakpoints.Mobile, value: '80%' },
                          ],
                          justifyContent: 'center',
                          marginTop: 50,
                          opacity: 1,
                          width: [
                            { minWidth: Breakpoints.Mobile, value: '90%' },
                            { minWidth: Breakpoints.Tablet, value: '70%' },
                            { minWidth: Breakpoints.Desktop, value: '50%' },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      <Surface
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.SurfaceStyles(theme)['Surface'],
                            {
                              alignItems: 'center',
                              borderRadius: 19,
                              height: '90%',
                              justifyContent: 'space-around',
                              opacity: 1,
                              padding: 20,
                              width: '90%',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {/* View 3 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '100%' },
                            dimensions.width
                          )}
                        >
                          {/* View 2 */}
                          <View
                            style={StyleSheet.applyWidth(
                              { alignItems: 'flex-end', width: '100%' },
                              dimensions.width
                            )}
                          >
                            <IconButton
                              color={theme.colors['Akurie Dark Blue']}
                              icon={'AntDesign/closecircle'}
                              onPress={() => {
                                try {
                                  setItemBlurView(false);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              size={26}
                            />
                          </View>

                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                marginBottom: 30,
                                padding: 2,
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Akurie Dark Blue'],
                                    fontFamily: 'Inter_600SemiBold',
                                    fontSize: 18,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Service: ID '}
                              {fetchData?.id}
                            </Text>
                          </View>
                          {/* View 5 */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'flex-start',
                                borderBottomWidth: 0.5,
                                borderColor: theme.colors['Divider'],
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                marginBottom: 10,
                                minHeight: 30,
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Text 2 */}
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    fontFamily: 'Inter_600SemiBold',
                                    fontSize: 14,
                                    marginBottom: 5,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Service Type:'}
                            </Text>

                            <Text
                              accessible={true}
                              dataDetectorType={'none'}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Akurie Dark Blue'],
                                    fontFamily: 'Inter_400Regular',
                                    fontSize: 18,
                                    marginBottom: 5,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {fetchData?.serviceType}
                            </Text>
                          </View>
                          {/* View 2 */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'flex-start',
                                borderBottomWidth: 0.5,
                                borderColor: theme.colors['Divider'],
                                justifyContent: 'center',
                                marginBottom: 10,
                                width: '90%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Text 3 */}
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Strong'],
                                    fontFamily: 'Inter_400Regular',
                                    fontSize: 14,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Estimated Completion Date'}
                            </Text>
                            {/* Text 4 */}
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Strong'],
                                    fontFamily: 'Inter_700Bold',
                                    fontSize: 14,
                                    marginBottom: 5,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {fetchData?.estimatedCompletionDate}
                            </Text>
                          </View>
                          {/* View 4 */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'flex-start',
                                borderBottomWidth: 0.5,
                                borderColor: theme.colors['Divider'],
                                justifyContent: 'center',
                                marginBottom: 10,
                                width: '90%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Text 3 */}
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Strong'],
                                    fontFamily: 'Inter_400Regular',
                                    fontSize: 14,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Completion Date'}
                            </Text>
                            {/* Text 4 */}
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Strong'],
                                    fontFamily: 'Inter_700Bold',
                                    fontSize: 14,
                                    marginBottom: 5,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {fetchData?.completionDate}
                            </Text>
                          </View>
                          {/* View 3 */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                  justifyContent: 'flex-start',
                                  maxHeight: 30,
                                  minHeight: 30,
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                            >
                              {/* Text 2 */}
                              <Text
                                accessible={true}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      fontFamily: 'Inter_600SemiBold',
                                      fontSize: 14,
                                      marginRight: 5,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Status:'}
                              </Text>
                              {/* Pending */}
                              <>
                                {!(fetchData?.Status === 'Pending') ? null : (
                                  <Icon
                                    color={theme.colors['Akurie Primary']}
                                    name={'Entypo/controller-record'}
                                    size={18}
                                    style={StyleSheet.applyWidth(
                                      { marginRight: 10 },
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                              {/* In-Progress */}
                              <>
                                {!(
                                  fetchData?.Status === 'In-Progress'
                                ) ? null : (
                                  <Icon
                                    color={
                                      theme.colors['Internal_Yellow_Secondary']
                                    }
                                    name={'Entypo/controller-record'}
                                    size={18}
                                    style={StyleSheet.applyWidth(
                                      { marginRight: 10 },
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                              {/* Completed */}
                              <>
                                {!(fetchData?.Status === 'Completed') ? null : (
                                  <Icon
                                    color={theme.colors['Akurie Other Green']}
                                    name={'Entypo/controller-record'}
                                    size={18}
                                    style={StyleSheet.applyWidth(
                                      { marginRight: 10 },
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                              {/* Action Required */}
                              <>
                                {!(
                                  fetchData?.Status === 'Action Required'
                                ) ? null : (
                                  <Icon
                                    color={theme.colors['Error']}
                                    name={'Entypo/controller-record'}
                                    size={18}
                                    style={StyleSheet.applyWidth(
                                      { marginRight: 10 },
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                              <Text
                                accessible={true}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      fontFamily: 'Inter_500Medium',
                                      fontSize: 14,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Pending'}
                              </Text>
                            </View>
                          </View>

                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'flex-start',
                                borderBottomWidth: 0.5,
                                borderColor: theme.colors['Divider'],
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                marginBottom: 10,
                                marginTop: 10,
                                minHeight: 30,
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Text 2 */}
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    fontFamily: 'Inter_500Medium',
                                    marginBottom: 10,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Akurie Agent Notes:'}
                            </Text>

                            <Text
                              accessible={true}
                              dataDetectorType={'none'}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Akurie Dark Blue'],
                                    fontFamily: 'Inter_400Regular',
                                    fontSize: 14,
                                    marginBottom: 5,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {fetchData?.techNotes}
                            </Text>
                          </View>
                        </View>
                      </Surface>
                    </View>
                  );
                }}
              </Web$AppServicesApi.FetchSpecificServiceGET>
            </BlurView>
          )}
        </>
        {/* Nav Frame 12-27 */}
        <View
          style={StyleSheet.applyWidth(
            {
              borderBottomWidth: 2,
              borderColor: theme.colors['Akurie green'],
              height: 80,
              marginBottom: 15,
            },
            dimensions.width
          )}
        >
          <ImageBackground
            resizeMode={'cover'}
            source={Images.Asset13}
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors['Akurie Dark Blue'],
                flexDirection: 'row',
                height: '100%',
                paddingLeft: { minWidth: Breakpoints.Laptop, value: '20%' },
                paddingRight: { minWidth: Breakpoints.Laptop, value: '20%' },
              },
              dimensions.width
            )}
          >
            {/* Left Side Frame */}
            <View
              style={StyleSheet.applyWidth(
                { paddingLeft: 9, paddingRight: 9 },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    navigation.goBack();
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* Icon Flex Frame */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexGrow: 0,
                      flexShrink: 0,
                      paddingBottom: 12,
                      paddingLeft: 12,
                      paddingRight: 12,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  {/* Icon  */}
                  <Icon
                    color={theme.colors['Background']}
                    name={'Ionicons/arrow-back-outline'}
                    size={24}
                  />
                </View>
              </Touchable>
            </View>
            {/* Middle Frame */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: [
                    {
                      minWidth: Breakpoints.Mobile,
                      value: 'rgba(8, 31, 61, 0.62)',
                    },
                    { minWidth: Breakpoints.Laptop, value: 'rgba(0, 0, 0, 0)' },
                  ],
                  flexGrow: 1,
                  flexShrink: 0,
                  justifyContent: 'center',
                },
                dimensions.width
              )}
            >
              {/* Text Frame  */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Strong Inverse'],
                      fontFamily: 'Inter_400Regular',
                      fontSize: 24,
                    }),
                    dimensions.width
                  )}
                >
                  {'Manage My Services'}
                </Text>
              </View>
            </View>
            {/* Left Side Frame */}
            <View
              style={StyleSheet.applyWidth(
                { paddingLeft: 9, paddingRight: 9 },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('RootNavigator');
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* Icon Flex Frame */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexGrow: 0,
                      flexShrink: 0,
                      paddingBottom: 12,
                      paddingLeft: 12,
                      paddingRight: 12,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  {/* Icon  */}
                  <Icon
                    color={theme.colors['Background']}
                    name={'AntDesign/home'}
                    size={24}
                  />
                </View>
              </Touchable>
            </View>
          </ImageBackground>
        </View>

        <ScrollView
          bounces={true}
          showsHorizontalScrollIndicator={true}
          showsVerticalScrollIndicator={true}
        >
          <View>
            {/* View 3 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  alignSelf: { minWidth: Breakpoints.Tablet, value: 'center' },
                  justifyContent: 'space-evenly',
                  marginTop: 20,
                  padding: 10,
                  width: [
                    { minWidth: Breakpoints.Tablet, value: '70%' },
                    { minWidth: Breakpoints.Desktop, value: '50%' },
                    { minWidth: Breakpoints.Laptop, value: '50%' },
                    { minWidth: Breakpoints.BigScreen, value: '40%' },
                  ],
                },
                dimensions.width
              )}
            >
              {/* View 4 */}
              <View
                style={StyleSheet.applyWidth({ padding: 20 }, dimensions.width)}
              >
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      fontFamily: 'Inter_600SemiBold',
                      fontSize: 16,
                      textAlign: 'center',
                    }),
                    dimensions.width
                  )}
                >
                  {'Select an active or previous service to view more details.'}
                </Text>
              </View>
              {/* My Website */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: theme.colors['Akurie Dark Blue'],
                    borderRadius: 9,
                    justifyContent: 'center',
                    marginBottom: 10,
                    marginTop: 10,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                {/* Active Services */}
                <View
                  style={StyleSheet.applyWidth(
                    { minHeight: 10, padding: 10, width: '100%' },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Akurie green'],
                          fontFamily: 'Inter_400Regular',
                          fontSize: 18,
                          marginBottom: 5,
                          marginLeft: 10,
                          marginTop: 5,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Active Services'}
                  </Text>

                  <Web$AppServicesApi.FetchWebServicesGET
                    business_id={Constants['ak_businessID']}
                  >
                    {({ loading, error, data, refetchWebServices }) => {
                      const fetchData = data?.json;
                      if (loading) {
                        return <ActivityIndicator />;
                      }

                      if (error || data?.status < 200 || data?.status >= 300) {
                        return <ActivityIndicator />;
                      }

                      return (
                        <>
                          <FlatList
                            data={fetchData?.noncompletedServices}
                            keyExtractor={(listData, index) =>
                              listData?.id ?? listData?.uuid ?? index.toString()
                            }
                            listKey={'pemM5vyC'}
                            numColumns={1}
                            onEndReachedThreshold={0.5}
                            renderItem={({ item, index }) => {
                              const listData = item;
                              return (
                                <Touchable
                                  onPress={() => {
                                    try {
                                      setServiceID(listData?.id);
                                      setItemBlurView(true);
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                >
                                  {/* Surface 5 */}
                                  <Surface
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.SurfaceStyles(theme)[
                                          'Surface'
                                        ],
                                        {
                                          alignItems: 'center',
                                          backgroundColor:
                                            theme.colors['Divider'],
                                          borderRadius: 9,
                                          flexDirection: 'column',
                                          justifyContent: 'center',
                                          marginBottom: 5,
                                          marginTop: 5,
                                          minHeight: 100,
                                          padding: 8,
                                          paddingLeft: 15,
                                          paddingRight: 15,
                                          width: '100%',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { flexDirection: 'row', width: '100%' },
                                        dimensions.width
                                      )}
                                    >
                                      {/* View 2 */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'flex-start',
                                            justifyContent: 'center',
                                            width: '70%',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          dataDetectorType={'none'}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ],
                                              {
                                                color:
                                                  theme.colors[
                                                    'Akurie Dark Blue'
                                                  ],
                                                fontFamily: 'Inter_400Regular',
                                                fontSize: 14,
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {'ID:'}
                                          {listData?.id}
                                        </Text>

                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              flexDirection: 'row',
                                              justifyContent: 'flex-start',
                                              maxHeight: 30,
                                              minHeight: 30,
                                              width: '100%',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Icon
                                            name={'Ionicons/pricetag'}
                                            size={24}
                                            style={StyleSheet.applyWidth(
                                              { marginRight: 5 },
                                              dimensions.width
                                            )}
                                          />
                                          {/* Text 2 */}
                                          <Text
                                            accessible={true}
                                            dataDetectorType={'none'}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ],
                                                {
                                                  color:
                                                    theme.colors[
                                                      'Akurie Dark Blue'
                                                    ],
                                                  fontFamily:
                                                    'Inter_600SemiBold',
                                                  fontSize: 14,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.serviceType}
                                          </Text>
                                        </View>
                                        {/* Text 4 */}
                                        <Text
                                          accessible={true}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ],
                                              {
                                                color: theme.colors['Strong'],
                                                fontFamily: 'Inter_400Regular',
                                                fontSize: 14,
                                                marginTop: 5,
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {'Est. Completion: '}
                                          {listData?.estimatedCompletionDate}
                                        </Text>
                                      </View>
                                      {/* View 3 */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'flex-end',
                                            justifyContent: 'center',
                                            width: '30%',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Text 2 */}
                                        <Text
                                          accessible={true}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ],
                                              {
                                                fontFamily: 'Inter_500Medium',
                                                fontSize: 14,
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {'Status'}
                                        </Text>

                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              flexDirection: 'row',
                                              justifyContent: 'flex-end',
                                              maxHeight: 30,
                                              minHeight: 30,
                                              width: '100%',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Pending */}
                                          <>
                                            {!(
                                              listData?.Status === 'Pending'
                                            ) ? null : (
                                              <Icon
                                                color={
                                                  theme.colors['Akurie Primary']
                                                }
                                                name={
                                                  'Entypo/controller-record'
                                                }
                                                size={18}
                                                style={StyleSheet.applyWidth(
                                                  { marginRight: 10 },
                                                  dimensions.width
                                                )}
                                              />
                                            )}
                                          </>
                                          {/* In-Progress */}
                                          <>
                                            {!(
                                              listData?.Status === 'In-Progress'
                                            ) ? null : (
                                              <Icon
                                                color={
                                                  theme.colors[
                                                    'Internal_Yellow_Secondary'
                                                  ]
                                                }
                                                name={
                                                  'Entypo/controller-record'
                                                }
                                                size={18}
                                                style={StyleSheet.applyWidth(
                                                  { marginRight: 10 },
                                                  dimensions.width
                                                )}
                                              />
                                            )}
                                          </>
                                          {/* Completed */}
                                          <>
                                            {!(
                                              listData?.Status === 'Completed'
                                            ) ? null : (
                                              <Icon
                                                color={
                                                  theme.colors[
                                                    'Akurie Other Green'
                                                  ]
                                                }
                                                name={
                                                  'Entypo/controller-record'
                                                }
                                                size={18}
                                                style={StyleSheet.applyWidth(
                                                  { marginRight: 10 },
                                                  dimensions.width
                                                )}
                                              />
                                            )}
                                          </>
                                          {/* Action Required */}
                                          <>
                                            {!(
                                              listData?.Status ===
                                              'Action Required'
                                            ) ? null : (
                                              <Icon
                                                color={theme.colors['Error']}
                                                name={
                                                  'Entypo/controller-record'
                                                }
                                                size={18}
                                                style={StyleSheet.applyWidth(
                                                  { marginRight: 10 },
                                                  dimensions.width
                                                )}
                                              />
                                            )}
                                          </>
                                          <Text
                                            accessible={true}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ],
                                                {
                                                  fontFamily: 'Inter_500Medium',
                                                  fontSize: 14,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {'Pending'}
                                          </Text>
                                        </View>
                                      </View>
                                    </View>
                                  </Surface>
                                </Touchable>
                              );
                            }}
                            showsHorizontalScrollIndicator={true}
                            showsVerticalScrollIndicator={true}
                          />
                        </>
                      );
                    }}
                  </Web$AppServicesApi.FetchWebServicesGET>
                </View>
              </View>
              {/* My Website 2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: theme.colors['Akurie Dark Blue'],
                    borderRadius: 9,
                    justifyContent: 'center',
                    marginBottom: 10,
                    marginTop: 10,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                {/* Active Services */}
                <View
                  style={StyleSheet.applyWidth(
                    { minHeight: 10, padding: 10, width: '100%' },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Akurie green'],
                          fontFamily: 'Inter_400Regular',
                          fontSize: 18,
                          marginBottom: 5,
                          marginLeft: 10,
                          marginTop: 5,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Previous Services'}
                  </Text>
                  {/* Fetch 2 */}
                  <Web$AppServicesApi.FetchWebServicesGET
                    business_id={Constants['ak_businessID']}
                  >
                    {({ loading, error, data, refetchWebServices }) => {
                      const fetch2Data = data?.json;
                      if (loading) {
                        return <ActivityIndicator />;
                      }

                      if (error || data?.status < 200 || data?.status >= 300) {
                        return <ActivityIndicator />;
                      }

                      return (
                        <FlatList
                          data={fetch2Data?.completedServices}
                          keyExtractor={(listData, index) =>
                            listData?.id ?? listData?.uuid ?? index.toString()
                          }
                          listKey={'0QBJT7Q6'}
                          numColumns={1}
                          onEndReachedThreshold={0.5}
                          renderItem={({ item, index }) => {
                            const listData = item;
                            return (
                              <Touchable
                                onPress={() => {
                                  try {
                                    setServiceID(listData?.id);
                                    setItemBlurView(true);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                              >
                                {/* Surface 5 */}
                                <Surface
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.SurfaceStyles(theme)[
                                        'Surface'
                                      ],
                                      {
                                        alignItems: 'center',
                                        backgroundColor:
                                          theme.colors['Divider'],
                                        borderRadius: 9,
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        marginBottom: 5,
                                        marginTop: 5,
                                        minHeight: 100,
                                        padding: 8,
                                        paddingLeft: 15,
                                        paddingRight: 15,
                                        width: '100%',
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { flexDirection: 'row', width: '100%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* View 2 */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'flex-start',
                                          justifyContent: 'center',
                                          width: '70%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Text 2 */}
                                      <Text
                                        accessible={true}
                                        dataDetectorType={'none'}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ],
                                            {
                                              color:
                                                theme.colors[
                                                  'Akurie Dark Blue'
                                                ],
                                              fontFamily: 'Inter_400Regular',
                                              fontSize: 14,
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {'ID:'}
                                        {listData?.id}
                                      </Text>

                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'center',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            maxHeight: 30,
                                            minHeight: 30,
                                            width: '100%',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Icon
                                          name={'Ionicons/pricetag'}
                                          size={24}
                                          style={StyleSheet.applyWidth(
                                            { marginRight: 5 },
                                            dimensions.width
                                          )}
                                        />
                                        <Text
                                          accessible={true}
                                          dataDetectorType={'none'}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ],
                                              {
                                                color:
                                                  theme.colors[
                                                    'Akurie Dark Blue'
                                                  ],
                                                fontFamily: 'Inter_600SemiBold',
                                                fontSize: 14,
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.serviceType}
                                        </Text>
                                      </View>
                                      {/* Text 4 */}
                                      <Text
                                        accessible={true}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ],
                                            {
                                              color: theme.colors['Strong'],
                                              fontFamily: 'Inter_400Regular',
                                              fontSize: 14,
                                              marginTop: 5,
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {'Est. Completion: '}
                                        {listData?.estimatedCompletionDate}
                                      </Text>
                                    </View>
                                    {/* View 3 */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'flex-end',
                                          justifyContent: 'center',
                                          width: '30%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Text 2 */}
                                      <Text
                                        accessible={true}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ],
                                            {
                                              fontFamily: 'Inter_500Medium',
                                              fontSize: 14,
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {'Status'}
                                      </Text>

                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'center',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-end',
                                            maxHeight: 30,
                                            minHeight: 30,
                                            width: '100%',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Pending */}
                                        <>
                                          {!(
                                            listData?.Status === 'Pending'
                                          ) ? null : (
                                            <Icon
                                              color={
                                                theme.colors['Akurie Primary']
                                              }
                                              name={'Entypo/controller-record'}
                                              size={18}
                                              style={StyleSheet.applyWidth(
                                                { marginRight: 10 },
                                                dimensions.width
                                              )}
                                            />
                                          )}
                                        </>
                                        {/* In-Progress */}
                                        <>
                                          {!(
                                            listData?.Status === 'In-Progress'
                                          ) ? null : (
                                            <Icon
                                              color={
                                                theme.colors[
                                                  'Internal_Yellow_Secondary'
                                                ]
                                              }
                                              name={'Entypo/controller-record'}
                                              size={18}
                                              style={StyleSheet.applyWidth(
                                                { marginRight: 10 },
                                                dimensions.width
                                              )}
                                            />
                                          )}
                                        </>
                                        {/* Completed */}
                                        <>
                                          {!(
                                            listData?.Status === 'Completed'
                                          ) ? null : (
                                            <Icon
                                              color={
                                                theme.colors[
                                                  'Akurie Other Green'
                                                ]
                                              }
                                              name={'Entypo/controller-record'}
                                              size={18}
                                              style={StyleSheet.applyWidth(
                                                { marginRight: 10 },
                                                dimensions.width
                                              )}
                                            />
                                          )}
                                        </>
                                        {/* Action Required */}
                                        <>
                                          {!(
                                            listData?.Status ===
                                            'Action Required'
                                          ) ? null : (
                                            <Icon
                                              color={theme.colors['Error']}
                                              name={'Entypo/controller-record'}
                                              size={18}
                                              style={StyleSheet.applyWidth(
                                                { marginRight: 10 },
                                                dimensions.width
                                              )}
                                            />
                                          )}
                                        </>
                                        <Text
                                          accessible={true}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ],
                                              {
                                                fontFamily: 'Inter_500Medium',
                                                fontSize: 14,
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {'Pending'}
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                </Surface>
                              </Touchable>
                            );
                          }}
                          showsHorizontalScrollIndicator={true}
                          showsVerticalScrollIndicator={true}
                        />
                      );
                    }}
                  </Web$AppServicesApi.FetchWebServicesGET>
                </View>
              </View>
              {/* aws block */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: theme.colors['Akurie Dark Blue'],
                    borderRadius: 9,
                    justifyContent: 'center',
                    marginBottom: 120,
                    marginTop: 10,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderBottomWidth: 0.5,
                      borderColor: theme.colors['Akurie Dark Blue'],
                      flexDirection: 'row',
                      justifyContent: 'center',
                      minHeight: 60,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Akurie green'],
                          fontFamily: 'Inter_600SemiBold',
                          fontSize: 18,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Service List'}
                  </Text>
                </View>

                <View
                  style={StyleSheet.applyWidth(
                    { minHeight: 10, padding: 10, width: '100%' },
                    dimensions.width
                  )}
                >
                  {/* Web Services */}
                  <Surface
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.SurfaceStyles(theme)['Surface'],
                        {
                          backgroundColor: theme.colors['Background'],
                          borderRadius: 9,
                          padding: 10,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {/* Create site */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          marginBottom: 20,
                          marginTop: 20,
                          paddingBottom: 5,
                          paddingTop: 5,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Touchable 4 */}
                      <Touchable
                        style={StyleSheet.applyWidth(
                          { width: '100%' },
                          dimensions.width
                        )}
                      >
                        {/* Surface 4 */}
                        <Surface
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.SurfaceStyles(theme)['Surface'],
                              {
                                alignItems: 'center',
                                borderRadius: 9,
                                marginBottom: 5,
                                marginTop: 5,
                                padding: 8,
                                width: '100%',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {/* View 2 */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                justifyContent: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  maxHeight: 30,
                                  minHeight: 30,
                                },
                                dimensions.width
                              )}
                            >
                              <Icon
                                color={theme.colors['Akurie Dark Blue']}
                                name={'AntDesign/tag'}
                                size={18}
                                style={StyleSheet.applyWidth(
                                  { marginRight: 10 },
                                  dimensions.width
                                )}
                              />
                              <Text
                                accessible={true}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      fontFamily: 'Inter_600SemiBold',
                                      fontSize: 16,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Technical Consulting'}
                              </Text>
                            </View>
                            {/* Text 2 */}
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    fontFamily: 'Inter_400Regular',
                                    fontSize: 14,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'$35.00 per hour'}
                            </Text>
                          </View>
                        </Surface>
                      </Touchable>
                      {/* Touchable 3 */}
                      <Touchable
                        style={StyleSheet.applyWidth(
                          { width: '100%' },
                          dimensions.width
                        )}
                      >
                        {/* Surface 3 */}
                        <Surface
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.SurfaceStyles(theme)['Surface'],
                              {
                                alignItems: 'center',
                                borderRadius: 9,
                                marginBottom: 5,
                                marginTop: 5,
                                padding: 8,
                                width: '100%',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {/* View 2 */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                justifyContent: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  maxHeight: 30,
                                  minHeight: 30,
                                },
                                dimensions.width
                              )}
                            >
                              <Icon
                                color={theme.colors['Akurie Dark Blue']}
                                name={'AntDesign/tag'}
                                size={18}
                                style={StyleSheet.applyWidth(
                                  { marginRight: 10 },
                                  dimensions.width
                                )}
                              />
                              <Text
                                accessible={true}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      fontFamily: 'Inter_600SemiBold',
                                      fontSize: 16,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Web UI/UX Design\n'}
                              </Text>
                            </View>
                            {/* Text 2 */}
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    fontFamily: 'Inter_400Regular',
                                    fontSize: 14,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'starting at $350.00'}
                            </Text>
                          </View>
                        </Surface>
                      </Touchable>
                      {/* Touchable 5 */}
                      <Touchable
                        style={StyleSheet.applyWidth(
                          { width: '100%' },
                          dimensions.width
                        )}
                      >
                        {/* Surface 5 */}
                        <Surface
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.SurfaceStyles(theme)['Surface'],
                              {
                                alignItems: 'center',
                                borderRadius: 9,
                                marginBottom: 5,
                                marginTop: 5,
                                padding: 8,
                                width: '100%',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {/* View 2 */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                justifyContent: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  maxHeight: 30,
                                  minHeight: 30,
                                },
                                dimensions.width
                              )}
                            >
                              <Icon
                                color={theme.colors['Akurie Dark Blue']}
                                name={'AntDesign/tag'}
                                size={18}
                                style={StyleSheet.applyWidth(
                                  { marginRight: 10 },
                                  dimensions.width
                                )}
                              />
                              <Text
                                accessible={true}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      fontFamily: 'Inter_600SemiBold',
                                      fontSize: 16,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Custom Web Integrations'}
                              </Text>
                            </View>
                            {/* Text 2 */}
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    fontFamily: 'Inter_400Regular',
                                    fontSize: 14,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'starting at $250.00'}
                            </Text>
                          </View>
                        </Surface>
                      </Touchable>
                      <Button
                        onPress={() => {
                          const handler = async () => {
                            try {
                              await WebBrowser.openBrowserAsync(
                                'https://calendly.com/akurie/30min'
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ButtonStyles(theme)['Button'],
                            {
                              backgroundColor: theme.colors['Akurie green'],
                              borderRadius: 9,
                              color: theme.colors['Akurie Dark Blue'],
                              fontFamily: 'Inter_700Bold',
                              marginTop: 20,
                            }
                          ),
                          dimensions.width
                        )}
                        title={'Schedule A Setup Meeting'}
                      />
                    </View>
                  </Surface>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(ManageMyServicesScreen);
