import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import encodeQueryParam from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const domesticCorpInfoAllStatesGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:KiS2UWRh/domestic_entities/corp/get_info`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useDomesticCorpInfoAllStatesGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['dom_corp_infos', args],
    () => domesticCorpInfoAllStatesGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchDomesticCorpInfoAllStatesGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useDomesticCorpInfoAllStatesGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchDomesticCorpInfoAllStates: refetch,
  });
};

export const domesticCorpInfoByStateGET = (
  Constants,
  { state_name },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:KiS2UWRh/domestic_entities/corp/get_info/${
      typeof state_name === 'string'
        ? state_name
        : JSON.stringify(state_name ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useDomesticCorpInfoByStateGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['dom_corp_info', args],
    () => domesticCorpInfoByStateGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['dom_corp_infos']),
    }
  );
};

export const FetchDomesticCorpInfoByStateGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  state_name,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useDomesticCorpInfoByStateGET(
    { state_name },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchDomesticCorpInfoByState: refetch,
  });
};

export const domesticLLCInfoAllStatesGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:KiS2UWRh/domestic_entities/llc/get_info`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useDomesticLLCInfoAllStatesGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['dom_llc_infos', args],
    () => domesticLLCInfoAllStatesGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchDomesticLLCInfoAllStatesGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useDomesticLLCInfoAllStatesGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchDomesticLLCInfoAllStates: refetch,
  });
};

export const domesticLLCInfoByStateGET = (
  Constants,
  { state_name },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:KiS2UWRh/domestic_entities/llc/get_info/${
      typeof state_name === 'string'
        ? state_name
        : JSON.stringify(state_name ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useDomesticLLCInfoByStateGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['dom_llc_infos', args],
    () => domesticLLCInfoByStateGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchDomesticLLCInfoByStateGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  state_name,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useDomesticLLCInfoByStateGET(
    { state_name },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchDomesticLLCInfoByState: refetch,
  });
};

export const entityDomesticGET = (Constants, { state_search }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:KiS2UWRh/entity_domestic/get/${
      typeof state_search === 'string'
        ? state_search
        : JSON.stringify(state_search ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useEntityDomesticGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['domestic_entities', args],
    () => entityDomesticGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchEntityDomesticGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  state_search,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useEntityDomesticGET(
    { state_search },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchEntityDomestic: refetch });
};

export const lLCFormationCostGET = (Constants, { state }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:KiS2UWRh/formation_costs/llc/${
      typeof state === 'string' ? state : JSON.stringify(state ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useLLCFormationCostGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['dom_llc_info', args],
    () => lLCFormationCostGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['dom_llc_infos']),
    }
  );
};

export const FetchLLCFormationCostGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  state,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useLLCFormationCostGET(
    { state },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchLLCFormationCost: refetch });
};

export const nacisCodes2022GET = (
  Constants,
  { industry_search },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:KiS2UWRh/naics/2022/get/${
      typeof industry_search === 'string'
        ? industry_search
        : JSON.stringify(industry_search ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useNacisCodes2022GET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['business_naics_top_levels', args],
    () => nacisCodes2022GET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchNacisCodes2022GET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  industry_search,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useNacisCodes2022GET(
    { industry_search },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchNacisCodes2022: refetch });
};

export const secretaryOfStateAllStatesGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:KiS2UWRh/Secretary_Of_States/get`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useSecretaryOfStateAllStatesGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['business_info_sos', args],
    () => secretaryOfStateAllStatesGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchSecretaryOfStateAllStatesGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useSecretaryOfStateAllStatesGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchSecretaryOfStateAllStates: refetch,
  });
};

export const secretaryOfStateByStateGET = (
  Constants,
  { state_name },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:KiS2UWRh/Secretary_Of_States/get/${
      typeof state_name === 'string'
        ? state_name
        : JSON.stringify(state_name ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useSecretaryOfStateByStateGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['business_info_so', args],
    () => secretaryOfStateByStateGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['business_info_sos']),
    }
  );
};

export const FetchSecretaryOfStateByStateGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  state_name,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useSecretaryOfStateByStateGET(
    { state_name },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchSecretaryOfStateByState: refetch,
  });
};

export const supportedStatesGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:KiS2UWRh/supported_states/get`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useSupportedStatesGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['supported_states', args],
    () => supportedStatesGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchSupportedStatesGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useSupportedStatesGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchSupportedStates: refetch });
};

export const supportedStatesSearchGET = (
  Constants,
  { state_search },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:KiS2UWRh/supported_states/get/${
      typeof state_search === 'string'
        ? state_search
        : JSON.stringify(state_search ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useSupportedStatesSearchGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['supported_states', args],
    () => supportedStatesSearchGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchSupportedStatesSearchGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  state_search,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useSupportedStatesSearchGET(
    { state_search },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchSupportedStatesSearch: refetch,
  });
};
