import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as Logs$SupportAkurieApi from '../apis/Logs$SupportAkurieApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';
import { Image, ImageBackground, StatusBar, Text, View } from 'react-native';

const SplashScreenWelcomeScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const logs$SupportAkurieCreateSessionIDPOST =
    Logs$SupportAkurieApi.useCreateSessionIDPOST();
  const logs$SupportAkurieBasicActivityPOST =
    Logs$SupportAkurieApi.useBasicActivityPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const sessionResult = (
          await logs$SupportAkurieCreateSessionIDPOST.mutateAsync()
        )?.json;
        setGlobalVariableValue({
          key: 'Session_ID',
          value: sessionResult,
        });
        (
          await logs$SupportAkurieBasicActivityPOST.mutateAsync({
            buttonName: 'Accessed Splash Screen',
            session_id: Constants['Session_ID'],
          })
        )?.json;
        StatusBar.setBarStyle('light-content');
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: [
            {
              minWidth: Breakpoints.Mobile,
              value: theme.colors['Background'],
            },
            { minWidth: Breakpoints.Laptop, value: theme.colors['Background'] },
          ],
        },
        dimensions.width
      )}
    >
      {/* Entire Screen BG */}
      <ImageBackground
        resizeMode={'cover'}
        source={Images.FirefoxPHKyvBthWe}
        style={StyleSheet.applyWidth(
          {
            alignContent: 'flex-start',
            alignItems: 'center',
            backgroundColor: [
              {
                minWidth: Breakpoints.Mobile,
                value: theme.colors['Akurie Dark Blue'],
              },
              {
                minWidth: Breakpoints.Laptop,
                value: theme.colors['Background'],
              },
            ],
            height: '100%',
            justifyContent: 'center',
            opacity: 1,
          },
          dimensions.width
        )}
      >
        {/* All Content Holder View */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: [
                {
                  minWidth: Breakpoints.Laptop,
                  value: theme.colors['Background'],
                },
                {
                  minWidth: Breakpoints.Tablet,
                  value: theme.colors['Background'],
                },
                { minWidth: Breakpoints.Mobile, value: '"rgba(0, 0, 0, 0)"' },
              ],
              borderRadius: [
                { minWidth: Breakpoints.Laptop, value: 12 },
                { minWidth: Breakpoints.Tablet, value: 12 },
              ],
              height: [
                { minWidth: Breakpoints.Mobile, value: '100%' },
                { minWidth: Breakpoints.Tablet, value: '50%' },
                { minWidth: Breakpoints.Laptop, value: '70%' },
                { minWidth: Breakpoints.BigScreen, value: '50%' },
                { minWidth: Breakpoints.Desktop, value: '60%' },
              ],
              justifyContent: [
                { minWidth: Breakpoints.Mobile, value: 'center' },
                { minWidth: Breakpoints.Laptop, value: 'center' },
              ],
              opacity: [
                { minWidth: Breakpoints.Laptop, value: 0.95 },
                { minWidth: Breakpoints.Tablet, value: 0.95 },
                { minWidth: Breakpoints.Mobile, value: 1 },
              ],
              paddingBottom: 40,
              paddingTop: 40,
              width: [
                { minWidth: Breakpoints.Mobile, value: '100%' },
                { minWidth: Breakpoints.Tablet, value: '70%' },
                { minWidth: Breakpoints.Desktop, value: '50%' },
                { minWidth: Breakpoints.BigScreen, value: '40%' },
              ],
            },
            dimensions.width
          )}
        >
          {/* Logo Hero Box */}
          <View
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ViewStyles(theme)['new logo box'],
                {
                  marginTop: 40,
                  opacity: 0.9,
                  paddingBottom: 20,
                  paddingTop: 20,
                  width: '100%',
                }
              ),
              dimensions.width
            )}
          >
            {/* Logo */}
            <Image
              resizeMode={'contain'}
              source={Images.MainLogoUpdate2u}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                  height: [
                    { minWidth: Breakpoints.Mobile, value: 60 },
                    { minWidth: Breakpoints.Tablet, value: 80 },
                    { minWidth: Breakpoints.Laptop, value: 70 },
                  ],
                  width: [
                    { minWidth: Breakpoints.Mobile, value: '70%' },
                    { minWidth: Breakpoints.Laptop, value: '50%' },
                  ],
                }),
                dimensions.width
              )}
            />
            {/* Text Holder 2 */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', width: '100%' },
                dimensions.width
              )}
            ></View>
          </View>
          {/* Nav Buttons Holder */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: { minWidth: Breakpoints.Laptop, value: 'center' } },
              dimensions.width
            )}
          >
            {/* Button Holder View */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderRadius: 4,
                  marginBottom: 10,
                  opacity: 0.95,
                  paddingLeft: 10,
                  paddingRight: 10,
                  width: [
                    { minWidth: Breakpoints.Mobile, value: '100%' },
                    { minWidth: Breakpoints.Laptop, value: '40%' },
                  ],
                },
                dimensions.width
              )}
            >
              {/* Buttons */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    marginBottom: 10,
                    paddingBottom: 10,
                    paddingLeft: [
                      { minWidth: Breakpoints.Mobile, value: 20 },
                      { minWidth: Breakpoints.Tablet, value: 40 },
                    ],
                    paddingRight: [
                      { minWidth: Breakpoints.Mobile, value: 20 },
                      { minWidth: Breakpoints.Tablet, value: 40 },
                    ],
                    paddingTop: 10,
                  },
                  dimensions.width
                )}
              >
                {/* User Login */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'stretch',
                      borderRadius: 0,
                      justifyContent: 'center',
                      paddingBottom: 12,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  {/* Touchable Frame */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'stretch', zIndex: 10 },
                      dimensions.width
                    )}
                  >
                    {/* Touchable Login Button */}
                    <Touchable
                      onPress={() => {
                        const handler = async () => {
                          try {
                            navigation.navigate('LoginScreen');
                            (
                              await logs$SupportAkurieBasicActivityPOST.mutateAsync(
                                {
                                  buttonName: 'Splash Screen - Login',
                                  session_id: Constants['Session_ID'],
                                }
                              )
                            )?.json;
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        { borderRadius: 0 },
                        dimensions.width
                      )}
                    >
                      {/* CTA Frame */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignContent: 'center',
                            alignItems: 'center',
                            backgroundColor: theme.colors['Akurie Primary'],
                            borderRadius: 12,
                            paddingBottom: [
                              { minWidth: Breakpoints.Mobile, value: 18 },
                              { minWidth: Breakpoints.Laptop, value: 10 },
                            ],
                            paddingLeft: 18,
                            paddingRight: 18,
                            paddingTop: [
                              { minWidth: Breakpoints.Mobile, value: 18 },
                              { minWidth: Breakpoints.Laptop, value: 10 },
                            ],
                            zIndex: 10,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Label Frame */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              alignSelf: 'center',
                              flexDirection: 'row',
                              flexGrow: 1,
                              flexShrink: 0,
                              justifyContent: 'center',
                            },
                            dimensions.width
                          )}
                        >
                          {/* User Login */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Strong Inverse'],
                                fontFamily: 'Inter_400Regular',
                                fontSize: [
                                  { minWidth: Breakpoints.Mobile, value: 16 },
                                  { minWidth: Breakpoints.Laptop, value: 14 },
                                ],
                                lineHeight: 24,
                                paddingRight: 10,
                                textTransform: 'uppercase',
                              },
                              dimensions.width
                            )}
                          >
                            {'User Login'}
                          </Text>
                          {/* Arrow Icon */}
                          <Icon
                            color={theme.colors['Background']}
                            name={'AntDesign/rightcircleo'}
                            size={21}
                          />
                        </View>
                      </View>
                    </Touchable>
                  </View>
                </View>
                {/* Create Account */}
                <View
                  style={StyleSheet.applyWidth(
                    { borderRadius: 0 },
                    dimensions.width
                  )}
                >
                  {/* Touchable Frame */}
                  <View
                    style={StyleSheet.applyWidth(
                      { zIndex: 10 },
                      dimensions.width
                    )}
                  >
                    {/* Create Account */}
                    <Touchable
                      onPress={() => {
                        const handler = async () => {
                          try {
                            navigation.navigate('SignUpScreen');
                            (
                              await logs$SupportAkurieBasicActivityPOST.mutateAsync(
                                {
                                  buttonName: 'Splash Screen - Create Account',
                                  session_id: Constants['Session_ID'],
                                }
                              )
                            )?.json;
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        { borderRadius: 0 },
                        dimensions.width
                      )}
                    >
                      {/* CTA Frame */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: theme.colors['Akurie green'],
                            borderRadius: 12,
                            flexDirection: 'row',
                            justifyContent: 'center',
                            paddingBottom: [
                              { minWidth: Breakpoints.Mobile, value: 15 },
                              { minWidth: Breakpoints.Laptop, value: 10 },
                            ],
                            paddingLeft: 18,
                            paddingRight: 18,
                            paddingTop: [
                              { minWidth: Breakpoints.Mobile, value: 15 },
                              { minWidth: Breakpoints.Laptop, value: 10 },
                            ],
                            zIndex: 10,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Label Frame */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexGrow: 1,
                              flexShrink: 0,
                              justifyContent: 'center',
                              paddingBottom: 6,
                              paddingTop: 6,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Create Account */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Strong'],
                                fontFamily: 'Inter_400Regular',
                                fontSize: [
                                  { minWidth: Breakpoints.Mobile, value: 16 },
                                  { minWidth: Breakpoints.Laptop, value: 14 },
                                ],
                                lineHeight: 21,
                                textAlign: 'center',
                                textTransform: 'uppercase',
                              },
                              dimensions.width
                            )}
                          >
                            {'Create Account'}
                          </Text>
                        </View>
                      </View>
                    </Touchable>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(SplashScreenWelcomeScreen);
