import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AuthAkurieApi from '../apis/AuthAkurieApi.js';
import * as Logs$SupportAkurieApi from '../apis/Logs$SupportAkurieApi.js';
import SsBusinessCardBlock from '../components/SsBusinessCardBlock';
import TaxCalculatorBlock from '../components/TaxCalculatorBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Divider,
  Icon,
  IconButton,
  Link,
  ScreenContainer,
  Surface,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Image, ImageBackground, StatusBar, Text, View } from 'react-native';

const ResourceCenterScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [classAccess1, setClassAccess1] = React.useState(true);
  const [classAccess2, setClassAccess2] = React.useState(false);
  const [classAccess3, setClassAccess3] = React.useState(false);
  const [classAccess4, setClassAccess4] = React.useState(false);
  const [datePickerValue, setDatePickerValue] = React.useState(new Date());
  const [numberInputValue, setNumberInputValue] = React.useState('');
  const [pickerValue, setPickerValue] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const logs$SupportAkurieLoginLogPOST =
    Logs$SupportAkurieApi.useLoginLogPOST();
  const logs$SupportAkurieBasicActivityPOST =
    Logs$SupportAkurieApi.useBasicActivityPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (tokenExpire) {
        return;
      }
      StatusBar.setBarStyle('dark-content');
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors.studilyWhite4 },
        dimensions.width
      )}
    >
      {/* site header update 11-14--2 */}
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <View
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ViewStyles(theme)[
                  'Akurie Header Desktop Header 9'
                ],
                {
                  borderBottomWidth: {
                    minWidth: Breakpoints.Laptop,
                    value: 0.5,
                  },
                  borderColor: {
                    minWidth: Breakpoints.Laptop,
                    value: theme.colors['Akurie green'],
                  },
                  height: { minWidth: Breakpoints.Laptop, value: 110 },
                  paddingLeft: { minWidth: Breakpoints.Laptop, value: '8%' },
                  paddingRight: { minWidth: Breakpoints.Laptop, value: '8%' },
                }
              ),
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  marginRight: { minWidth: Breakpoints.Laptop, value: 20 },
                  width: { minWidth: Breakpoints.Laptop, value: '20%' },
                },
                dimensions.width
              )}
            >
              <Image
                resizeMode={'contain'}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    height: { minWidth: Breakpoints.Laptop, value: 50 },
                    width: { minWidth: Breakpoints.Laptop, value: '100%' },
                  }),
                  dimensions.width
                )}
              />
            </View>
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                  flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
                  height: { minWidth: Breakpoints.Laptop, value: '80%' },
                  justifyContent: {
                    minWidth: Breakpoints.Laptop,
                    value: 'space-around',
                  },
                  marginRight: [
                    { minWidth: Breakpoints.Laptop, value: 20 },
                    { minWidth: Breakpoints.Desktop, value: 0 },
                  ],
                  width: { minWidth: Breakpoints.Laptop, value: '60%' },
                },
                dimensions.width
              )}
            >
              <Link
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'], {
                    color: {
                      minWidth: Breakpoints.Laptop,
                      value: theme.colors['Strong_Inverse'],
                    },
                    fontFamily: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: 'Inter_500Medium',
                      },
                      {
                        minWidth: Breakpoints.BigScreen,
                        value: 'Inter_400Regular',
                      },
                    ],
                    fontSize: [
                      { minWidth: Breakpoints.Laptop, value: 12 },
                      { minWidth: Breakpoints.Desktop, value: 14 },
                    ],
                  }),
                  dimensions.width
                )}
                title={'Dashboard'}
              />
              <Link
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'], {
                    color: {
                      minWidth: Breakpoints.Laptop,
                      value: theme.colors['Strong_Inverse'],
                    },
                    fontFamily: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: 'Inter_500Medium',
                      },
                      {
                        minWidth: Breakpoints.BigScreen,
                        value: 'Inter_400Regular',
                      },
                    ],
                    fontSize: [
                      { minWidth: Breakpoints.Laptop, value: 12 },
                      { minWidth: Breakpoints.Desktop, value: 14 },
                    ],
                  }),
                  dimensions.width
                )}
                title={'Networking Events'}
              />
              <Link
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'], {
                    color: {
                      minWidth: Breakpoints.Laptop,
                      value: theme.colors['Strong_Inverse'],
                    },
                    fontFamily: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: 'Inter_500Medium',
                      },
                      {
                        minWidth: Breakpoints.BigScreen,
                        value: 'Inter_400Regular',
                      },
                    ],
                    fontSize: [
                      { minWidth: Breakpoints.Laptop, value: 12 },
                      { minWidth: Breakpoints.Desktop, value: 14 },
                    ],
                  }),
                  dimensions.width
                )}
                title={'My Business info'}
              />
              <Link
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'], {
                    color: {
                      minWidth: Breakpoints.Laptop,
                      value: theme.colors['Strong_Inverse'],
                    },
                    fontFamily: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: 'Inter_500Medium',
                      },
                      {
                        minWidth: Breakpoints.BigScreen,
                        value: 'Inter_400Regular',
                      },
                    ],
                    fontSize: [
                      { minWidth: Breakpoints.Laptop, value: 12 },
                      { minWidth: Breakpoints.Desktop, value: 14 },
                    ],
                  }),
                  dimensions.width
                )}
                title={'The Financials'}
              />
              <Link
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'], {
                    color: {
                      minWidth: Breakpoints.Laptop,
                      value: theme.colors['Strong_Inverse'],
                    },
                    fontFamily: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: 'Inter_500Medium',
                      },
                      {
                        minWidth: Breakpoints.BigScreen,
                        value: 'Inter_400Regular',
                      },
                    ],
                    fontSize: [
                      { minWidth: Breakpoints.Laptop, value: 12 },
                      { minWidth: Breakpoints.Desktop, value: 14 },
                    ],
                  }),
                  dimensions.width
                )}
                title={'Docs & Filings'}
              />
            </View>
            {/* View 3 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: [
                    { minWidth: Breakpoints.BigScreen, value: 'center' },
                    { minWidth: Breakpoints.Desktop, value: 'center' },
                  ],
                  flexDirection: [
                    { minWidth: Breakpoints.BigScreen, value: 'row' },
                    { minWidth: Breakpoints.Desktop, value: 'row' },
                    { minWidth: Breakpoints.Laptop, value: 'row' },
                  ],
                  justifyContent: {
                    minWidth: Breakpoints.Laptop,
                    value: 'center',
                  },
                  marginLeft: { minWidth: Breakpoints.Desktop, value: 20 },
                  marginRight: { minWidth: Breakpoints.Desktop, value: 20 },
                  width: [
                    { minWidth: Breakpoints.Laptop, value: '30%' },
                    { minWidth: Breakpoints.Desktop, value: '20%' },
                    { minWidth: Breakpoints.BigScreen, value: '15%' },
                  ],
                },
                dimensions.width
              )}
            >
              {/* Touchable Frame 2 */}
              <>
                {!(
                  Constants['ak_subscriptionType'] === 'Akurie Free'
                ) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'flex-end',
                        },
                        marginRight: { minWidth: Breakpoints.Laptop, value: 5 },
                        width: [
                          { minWidth: Breakpoints.Laptop, value: '50%' },
                          { minWidth: Breakpoints.Mobile, value: '100%' },
                        ],
                        zIndex: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        const handler = async () => {
                          try {
                            const loginResponse = (
                              await AuthAkurieApi.loginPOST(Constants, {})
                            )?.json;
                            const authToken = loginResponse?.authToken;
                            const Error_Msg = loginResponse?.message;
                            setGlobalVariableValue({
                              key: 'ak_errorMessage',
                              value: Error_Msg,
                            });
                            if (Error_Msg) {
                              return;
                            }
                            setGlobalVariableValue({
                              key: 'SKYE_AUTHORIZATION_HEADER',
                              value: 'Bearer ' + authToken,
                            });
                            const currentUserid = loginResponse?.authUser?.id;
                            setGlobalVariableValue({
                              key: 'ak_userID',
                              value: currentUserid,
                            });
                            const Businessid = loginResponse?.businessData.id;
                            setGlobalVariableValue({
                              key: 'ak_businessID',
                              value: Businessid,
                            });
                            (
                              await logs$SupportAkurieLoginLogPOST.mutateAsync({
                                user_id: currentUserid,
                              })
                            )?.json;
                            setGlobalVariableValue({
                              key: 'ak_errorMessage',
                              value: false,
                            });
                            setGlobalVariableValue({
                              key: 'ak_isLoading',
                              value: true,
                            });
                            navigation.navigate('RootNavigator');
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        { borderRadius: 0 },
                        dimensions.width
                      )}
                    >
                      {/* CTA Frame */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: [
                              {
                                minWidth: Breakpoints.Mobile,
                                value: theme.colors['Akurie Primary'],
                              },
                              {
                                minWidth: Breakpoints.Laptop,
                                value: theme.colors['Akurie green'],
                              },
                            ],
                            borderRadius: 12,
                            flexDirection: 'row',
                            maxWidth: {
                              minWidth: Breakpoints.Laptop,
                              value: 120,
                            },
                            minHeight: {
                              minWidth: Breakpoints.Laptop,
                              value: 45,
                            },
                            paddingBottom: [
                              { minWidth: Breakpoints.Mobile, value: 12 },
                              { minWidth: Breakpoints.Laptop, value: 6 },
                              { minWidth: Breakpoints.BigScreen, value: 12 },
                              { minWidth: Breakpoints.Desktop, value: 2 },
                            ],
                            paddingLeft: [
                              { minWidth: Breakpoints.Mobile, value: 18 },
                              { minWidth: Breakpoints.Laptop, value: 12 },
                            ],
                            paddingRight: [
                              { minWidth: Breakpoints.Mobile, value: 18 },
                              { minWidth: Breakpoints.Laptop, value: 12 },
                            ],
                            paddingTop: [
                              { minWidth: Breakpoints.Mobile, value: 12 },
                              { minWidth: Breakpoints.Laptop, value: 6 },
                              { minWidth: Breakpoints.BigScreen, value: 12 },
                              { minWidth: Breakpoints.Desktop, value: 2 },
                            ],
                            zIndex: 10,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Label Frame */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexGrow: 1,
                              flexShrink: 0,
                              justifyContent: 'center',
                            },
                            dimensions.width
                          )}
                        >
                          {/* Cotinue */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: [
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: theme.colors['Strong Inverse'],
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: theme.colors['Akurie Dark Blue'],
                                  },
                                ],
                                fontFamily: [
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: 'Inter_500Medium',
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'Inter_400Regular',
                                  },
                                ],
                                fontSize: [
                                  { minWidth: Breakpoints.Mobile, value: 15 },
                                  { minWidth: Breakpoints.Laptop, value: 13 },
                                ],
                                lineHeight: 21,
                                paddingBottom: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 3,
                                },
                                paddingTop: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 3,
                                },
                                textTransform: 'uppercase',
                              },
                              dimensions.width
                            )}
                          >
                            {'Try For Free'}
                          </Text>
                        </View>
                      </View>
                    </Touchable>
                  </View>
                )}
              </>
              {/* Touchable Frame 3 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: {
                      minWidth: Breakpoints.Laptop,
                      value: 'flex-start',
                    },
                    justifyContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                    width: [
                      { minWidth: Breakpoints.Mobile, value: '100%' },
                      { minWidth: Breakpoints.Laptop, value: '50%' },
                    ],
                    zIndex: 10,
                  },
                  dimensions.width
                )}
              ></View>
            </View>
          </View>
        )}
      </>
      {/* Navigation Frame */}
      <View
        style={StyleSheet.applyWidth(
          {
            borderBottomWidth: 2,
            borderColor: theme.colors['Akurie green'],
            height: 100,
          },
          dimensions.width
        )}
      >
        <ImageBackground
          resizeMode={'cover'}
          source={Images.Asset13}
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              backgroundColor: theme.colors['Akurie Dark Blue'],
              borderBottomWidth: 1,
              borderColor: theme.colors['Akurie green'],
              flexDirection: 'row',
              height: '100%',
            },
            dimensions.width
          )}
        >
          {/* Left Side Frame */}
          <View
            style={StyleSheet.applyWidth(
              { paddingLeft: 9, paddingRight: 9 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Icon Flex Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                {/* Icon  */}
                <Icon
                  color={theme.colors['Background']}
                  name={'Ionicons/arrow-back-outline'}
                  size={24}
                />
              </View>
            </Touchable>
          </View>
          {/* Middle Frame */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'flex-start',
                flexGrow: 1,
                flexShrink: 0,
                justifyContent: 'center',
              },
              dimensions.width
            )}
          >
            {/* Text Frame  */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexGrow: 0,
                  flexShrink: 0,
                  marginLeft: 30,
                  paddingBottom: 12,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 12,
                },
                dimensions.width
              )}
            ></View>
          </View>
          {/* Left Side Frame 2 */}
          <View
            style={StyleSheet.applyWidth(
              { paddingLeft: 9, paddingRight: 9 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('HelpSupportScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Icon Flex Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                {/* Icon  */}
                <Icon
                  color={theme.colors['Background']}
                  name={'Ionicons/help-circle-outline'}
                  size={32}
                />
              </View>
            </Touchable>
          </View>
        </ImageBackground>
      </View>

      <ImageBackground
        resizeMode={'cover'}
        source={Images.FirefoxPHKyvBthWe}
        style={StyleSheet.applyWidth(
          {
            backgroundColor: theme.colors['Akurie Dark Blue'],
            height: '100%',
            justifyContent: 'center',
            width: '100%',
          },
          dimensions.width
        )}
      >
        {/* menu title holder */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'column',
              height: 100,
              justifyContent: 'center',
              opacity: 1,
              width: '100%',
            },
            dimensions.width
          )}
        >
          <Text
            style={StyleSheet.applyWidth(
              {
                color: theme.colors['Akurie Dark Blue'],
                fontFamily: 'Inter_400Regular',
                fontSize: 24,
                marginTop: 30,
                textAlign: 'center',
              },
              dimensions.width
            )}
          >
            {'Funding & Tax Resources'}
          </Text>
          <Divider
            color={theme.colors['Akurie Primary']}
            style={StyleSheet.applyWidth(
              { height: 3, marginTop: 5 },
              dimensions.width
            )}
          />
        </View>
        {/* new content menu */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: { minWidth: Breakpoints.Tablet, value: 'center' },
              borderRadius: 0,
              borderTopLeftRadius: 30,
              borderTopRightRadius: 30,
              flex: 1,
              height: 400,
              justifyContent: [
                { minWidth: Breakpoints.Mobile, value: 'center' },
                { minWidth: Breakpoints.BigScreen, value: 'center' },
                { minWidth: Breakpoints.Tablet, value: 'center' },
              ],
              marginLeft: { minWidth: Breakpoints.BigScreen, value: 10 },
              marginRight: { minWidth: Breakpoints.BigScreen, value: 10 },
              marginTop: 15,
              opacity: 0.95,
              paddingLeft: [
                { minWidth: Breakpoints.Laptop, value: '10%' },
                { minWidth: Breakpoints.Desktop, value: '15%' },
              ],
              paddingRight: [
                { minWidth: Breakpoints.Laptop, value: '10%' },
                { minWidth: Breakpoints.Desktop, value: '15%' },
              ],
            },
            dimensions.width
          )}
        >
          {/* Third Menu Row 4 */}
          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                marginBottom: 10,
                marginTop: 10,
                paddingLeft: 10,
                paddingRight: 10,
                width: { minWidth: Breakpoints.Tablet, value: '80%' },
              },
              dimensions.width
            )}
          >
            {/* Resource Center */}
            <Touchable
              onPress={() => {
                const handler = async () => {
                  try {
                    (
                      await logs$SupportAkurieBasicActivityPOST.mutateAsync({
                        buttonName: 'Business Funding ',
                        session_id: Constants['Session_ID'],
                      })
                    )?.json;
                    navigation.navigate('BusinessFundingScreen');
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
            >
              <Surface
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.SurfaceStyles(theme)['Surface'],
                    {
                      alignItems: 'center',
                      backgroundColor: theme.colors['Akurie Dark Blue'],
                      borderColor: theme.colors['Akurie green'],
                      borderRadius: 15,
                      borderWidth: [
                        { minWidth: Breakpoints.Tablet, value: 0.5 },
                        { minWidth: Breakpoints.Mobile, value: 0.5 },
                      ],
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      minHeight: 100,
                      minWidth: 180,
                      paddingBottom: 5,
                      paddingLeft: 30,
                      paddingRight: 30,
                      paddingTop: 5,
                    }
                  ),
                  dimensions.width
                )}
              >
                {/* Banner */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      marginRight: 10,
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                        paddingBottom: 10,
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 10,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Title */}
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Background'],
                          fontFamily: 'Inter_500Medium',
                          fontSize: 16,
                          textAlign: 'center',
                        },
                        dimensions.width
                      )}
                    >
                      {'Loans & Grants'}
                    </Text>
                    {/* Title 2 */}
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Akurie green'],
                          fontFamily: 'Inter_500Medium',
                          fontSize: 12,
                          marginTop: 5,
                          textAlign: 'left',
                        },
                        dimensions.width
                      )}
                    >
                      {'A list of Business Loans & Grants'}
                    </Text>
                  </View>
                </View>

                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', marginLeft: 10 },
                    dimensions.width
                  )}
                >
                  <Icon
                    color={theme.colors['Akurie Primary']}
                    name={'MaterialIcons/account-balance'}
                    size={80}
                  />
                </View>
              </Surface>
            </Touchable>
          </View>
          <TaxCalculatorBlock />
          <SsBusinessCardBlock />
        </View>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(ResourceCenterScreen);
