import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import encodeQueryParam from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const basicActivityPOST = (
  Constants,
  { buttonName, session_id },
  handlers = {}
) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:Pm4hWjWO/basic_activity_log`, {
    body: JSON.stringify({ buttonName: buttonName, session_id: session_id }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useBasicActivityPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => basicActivityPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Log_Activity', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Log_Activity');
        queryClient.invalidateQueries('Log_Activities');
      },
    }
  );
};

export const FetchBasicActivityPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  buttonName,
  session_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useBasicActivityPOST(
    { buttonName, session_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchBasicActivity: refetch });
};

export const createSessionIDPOST = (Constants, _args, handlers = {}) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:Pm4hWjWO/create_session_id`, {
    body: JSON.stringify({ key: 'value' }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useCreateSessionIDPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      createSessionIDPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Log_Activity', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Log_Activity');
        queryClient.invalidateQueries('Log_Activities');
      },
    }
  );
};

export const FetchCreateSessionIDPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateSessionIDPOST(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateSessionID: refetch });
};

export const createSupportTicketPOST = (
  Constants,
  {
    File,
    Notes,
    call_requested,
    contact_number,
    issue_problem,
    user_business_basic_data_id,
    user_id,
  },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:Pm4hWjWO/support_tickets/create`,
    {
      body: JSON.stringify({
        'issue-problem': issue_problem,
        Notes: Notes,
        user_id: user_id,
        user_business_basic_data_id: user_business_basic_data_id,
        call_requested: call_requested,
        contact_number: contact_number,
        File: File,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useCreateSupportTicketPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      createSupportTicketPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('support_ticket', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('support_ticket');
        queryClient.invalidateQueries('support_tickets');
      },
    }
  );
};

export const FetchCreateSupportTicketPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  File,
  Notes,
  call_requested,
  contact_number,
  issue_problem,
  user_business_basic_data_id,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateSupportTicketPOST(
    {
      File,
      Notes,
      call_requested,
      contact_number,
      issue_problem,
      user_business_basic_data_id,
      user_id,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchCreateSupportTicket: refetch,
  });
};

export const getSupportTicketsGET = (Constants, { bus_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:Pm4hWjWO/support_tickets/get/${
      typeof bus_id === 'string' ? bus_id : JSON.stringify(bus_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetSupportTicketsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['support_tickets', args],
    () => getSupportTicketsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetSupportTicketsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  bus_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetSupportTicketsGET(
    { bus_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetSupportTickets: refetch });
};

export const loginLogPOST = (Constants, { user_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:Pm4hWjWO/login_activity/create`,
    {
      body: JSON.stringify({ user_id: user_id }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useLoginLogPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => loginLogPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Log_Activity', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Log_Activity');
        queryClient.invalidateQueries('Log_Activities');
      },
    }
  );
};

export const FetchLoginLogPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useLoginLogPOST(
    { user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchLoginLog: refetch });
};

export const supportNoAuthPOST = (
  Constants,
  { File, Notes, call_requested, contact_number, email, issueproblem },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:Pm4hWjWO/support_tickets/create/non-auth`,
    {
      body: JSON.stringify({
        'issue-problem': issueproblem,
        Notes: Notes,
        call_requested: call_requested,
        contact_number: contact_number,
        email: email,
        File: File,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useSupportNoAuthPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => supportNoAuthPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('support_ticket', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('support_ticket');
        queryClient.invalidateQueries('support_tickets');
      },
    }
  );
};

export const FetchSupportNoAuthPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  File,
  Notes,
  call_requested,
  contact_number,
  email,
  issueproblem,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useSupportNoAuthPOST(
    { File, Notes, call_requested, contact_number, email, issueproblem },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchSupportNoAuth: refetch });
};

export const supportServicesPOST = (
  Constants,
  {
    Notes,
    Service_Type,
    call_requested,
    contact_number,
    user_business_basic_data_id,
    user_id,
  },
  handlers = {}
) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:Pm4hWjWO/support_services`, {
    body: JSON.stringify({
      Service_Type: Service_Type,
      Notes: Notes,
      user_id: user_id,
      user_business_basic_data_id: user_business_basic_data_id,
      call_requested: call_requested,
      contact_number: contact_number,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useSupportServicesPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      supportServicesPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('service_request', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('service_request');
        queryClient.invalidateQueries('service_requests');
      },
    }
  );
};

export const FetchSupportServicesPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  Notes,
  Service_Type,
  call_requested,
  contact_number,
  user_business_basic_data_id,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useSupportServicesPOST(
    {
      Notes,
      Service_Type,
      call_requested,
      contact_number,
      user_business_basic_data_id,
      user_id,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchSupportServices: refetch });
};
