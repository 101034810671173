import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AuthAkurieApi from '../apis/AuthAkurieApi.js';
import * as BusinessDataAkurieApi from '../apis/BusinessDataAkurieApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Circle,
  Icon,
  NumberInput,
  Picker,
  ScreenContainer,
  Surface,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  ImageBackground,
  StatusBar,
  Text,
  View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const RegisteredAgentInformationScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [agent_exist, setAgent_exist] = React.useState(false);
  const [birthdayPickerValue, setBirthdayPickerValue] = React.useState(
    new Date()
  );
  const [cityInputValue, setCityInputValue] = React.useState('');
  const [classAccess1, setClassAccess1] = React.useState(true);
  const [classAccess2, setClassAccess2] = React.useState(false);
  const [classAccess3, setClassAccess3] = React.useState(false);
  const [classAccess4, setClassAccess4] = React.useState(false);
  const [countyInputValue, setCountyInputValue] = React.useState('');
  const [datePickerValue, setDatePickerValue] = React.useState(new Date());
  const [emailInputValue, setEmailInputValue] = React.useState('');
  const [firstNameInputValue, setFirstNameInputValue] = React.useState('');
  const [jobTitlePickerValue, setJobTitlePickerValue] = React.useState('');
  const [lastNameInputValue, setLastNameInputValue] = React.useState('');
  const [
    newOrExistingBusinessPickerValue,
    setNewOrExistingBusinessPickerValue,
  ] = React.useState('');
  const [numberInputValue, setNumberInputValue] = React.useState('');
  const [numberInputValue2, setNumberInputValue2] = React.useState('');
  const [pickerValue, setPickerValue] = React.useState('');
  const [registeredAgentAdd1InputValue, setRegisteredAgentAdd1InputValue] =
    React.useState('');
  const [registeredAgentAdd2InputValue, setRegisteredAgentAdd2InputValue] =
    React.useState('');
  const [registeredAgentNameInputValue, setRegisteredAgentNameInputValue] =
    React.useState('');
  const [statePickerValue, setStatePickerValue] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const [zipCodeInputValue, setZipCodeInputValue] = React.useState('');
  const businessDataAkurieAddRegisteredAgentPOST =
    BusinessDataAkurieApi.useAddRegisteredAgentPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const authResponse = (await AuthAkurieApi.meGET(Constants))?.json;
        const tokenExpire = authResponse?.message;
        if (tokenExpire) {
          navigation.navigate('SessionExpiredScreen');
        }
        if (tokenExpire) {
          return;
        }
        StatusBar.setBarStyle('dark-content');
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);
  const registeredAgentAdd1Input8hGSiqujRef = React.useRef();
  const registeredAgentAdd2InputU6txZVEjRef = React.useRef();
  const cityInputea6bAvnFRef = React.useRef();
  const zipCodeInput7fsHmeaaRef = React.useRef();

  return (
    <ScreenContainer
      hasSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors.studilyWhite4 },
        dimensions.width
      )}
    >
      {/* Navigation Frame */}
      <View
        style={StyleSheet.applyWidth(
          {
            borderBottomWidth: 2,
            borderColor: theme.colors['Akurie green'],
            height: 80,
            marginBottom: 15,
            marginTop: 15,
          },
          dimensions.width
        )}
      >
        <ImageBackground
          resizeMode={'cover'}
          source={Images.Asset13}
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              backgroundColor: theme.colors['Akurie Dark Blue'],
              flexDirection: 'row',
              height: '100%',
            },
            dimensions.width
          )}
        >
          {/* Left Side Frame */}
          <View
            style={StyleSheet.applyWidth(
              { paddingLeft: 9, paddingRight: 9 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Icon Flex Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                {/* Icon  */}
                <Icon
                  color={theme.colors['Background']}
                  name={'Ionicons/arrow-back-outline'}
                  size={24}
                />
              </View>
            </Touchable>
          </View>
          {/* Middle Frame */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'flex-start',
                flexGrow: 1,
                flexShrink: 0,
                justifyContent: 'center',
              },
              dimensions.width
            )}
          >
            {/* Text Frame  */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: 'rgba(8, 31, 61, 0.5)',
                  flexGrow: 0,
                  flexShrink: 0,
                  paddingBottom: 12,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 12,
                },
                dimensions.width
              )}
            >
              {/* Text 12 18 Regular */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Akurie green'],
                    fontFamily: 'Inter_400Regular',
                    fontSize: 22,
                    lineHeight: 18,
                    paddingTop: 8,
                  },
                  dimensions.width
                )}
              >
                {'Registered Agent Info'}
              </Text>
            </View>
          </View>
          {/* Left Side Frame 2 */}
          <View
            style={StyleSheet.applyWidth(
              { paddingLeft: 9, paddingRight: 9 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Icon Flex Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                {/* Icon  */}
                <Icon
                  color={theme.colors['Background']}
                  name={'Ionicons/help-circle-outline'}
                  size={32}
                />
              </View>
            </Touchable>
          </View>
        </ImageBackground>
      </View>

      <ImageBackground
        resizeMode={'stretch'}
        style={StyleSheet.applyWidth(
          { height: '100%', width: '100%' },
          dimensions.width
        )}
      >
        <>
          {!agent_exist ? null : (
            <BusinessDataAkurieApi.FetchGetRegisteredAgentGET>
              {({ loading, error, data, refetchGetRegisteredAgent }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <>
                    <View
                      style={StyleSheet.applyWidth(
                        { paddingLeft: 20, paddingRight: 20 },
                        dimensions.width
                      )}
                    />
                    <>
                      {!fetchData ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: theme.colors['Custom Color_18'],
                              borderRadius: 9,
                              marginBottom: 15,
                              marginLeft: 20,
                              marginRight: 20,
                              marginTop: 25,
                              paddingBottom: 10,
                              paddingLeft: 35,
                              paddingRight: 35,
                              paddingTop: 10,
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Custom Color'],
                                fontFamily: 'System',
                                fontWeight: '600',
                              },
                              dimensions.width
                            )}
                          >
                            {'Current Information:'}
                          </Text>

                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Medium Inverse'],
                                fontFamily: 'System',
                                fontWeight: '400',
                              },
                              dimensions.width
                            )}
                          >
                            {fetchData?.registered_agent_name}
                          </Text>

                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Medium Inverse'],
                                fontFamily: 'System',
                                fontWeight: '400',
                              },
                              dimensions.width
                            )}
                          >
                            {fetchData?.State}
                            {' - '}
                            {null}
                            {' County'}
                          </Text>
                        </View>
                      )}
                    </>
                  </>
                );
              }}
            </BusinessDataAkurieApi.FetchGetRegisteredAgentGET>
          )}
        </>
        <KeyboardAwareScrollView
          extraHeight={350}
          keyboardShouldPersistTaps={'never'}
          showsVerticalScrollIndicator={true}
        >
          {/* General Settings Frame */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: 'rgba(0, 0, 0, 0)',
                borderBottomWidth: 1,
                borderColor: theme.colors.studilyGray3,
                borderLeftWidth: 1,
                borderRadius: 6,
                borderRightWidth: 1,
                borderTopWidth: 1,
                marginBottom: 18,
                marginTop: 18,
                paddingBottom: 6,
                paddingLeft: 6,
                paddingRight: 6,
                paddingTop: 6,
              },
              dimensions.width
            )}
          >
            <View>
              {/* Bottom option */}
              <Surface
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: theme.colors.divider,
                    borderLeftWidth: 1,
                    borderRadius: 10,
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    justifyContent: 'center',
                    marginLeft: 16,
                    marginRight: 16,
                    marginTop: 25,
                    minHeight: 40,
                  },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderBottomWidth: 1,
                      borderColor: theme.colors.divider,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      minHeight: 80,
                      paddingBottom: 12,
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  {/* Name */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flexDirection: 'column', width: '100%' },
                      dimensions.width
                    )}
                  >
                    {/* Registered Agent Name View */}
                    <View
                      style={StyleSheet.applyWidth(
                        { paddingBottom: 5, paddingTop: 5 },
                        dimensions.width
                      )}
                    >
                      {/* Registered Agent Name */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Medium'],
                            fontFamily: 'Inter_600SemiBold',
                            marginBottom: 8,
                            marginLeft: 5,
                          },
                          dimensions.width
                        )}
                      >
                        {'Registered Agent Name'}
                      </Text>
                      {/* Registered Agent Name Input */}
                      <TextInput
                        enablesReturnKeyAutomatically={false}
                        onChangeText={newRegisteredAgentNameInputValue => {
                          try {
                            setRegisteredAgentNameInputValue(
                              newRegisteredAgentNameInputValue
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        onSubmitEditing={() => {
                          try {
                            registeredAgentAdd1Input8hGSiqujRef.current.focus();
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        placeholder={'Enter a value...'}
                        returnKeyType={'next'}
                        spellcheck={true}
                        style={StyleSheet.applyWidth(
                          {
                            borderBottomWidth: 1,
                            borderColor: theme.colors['Custom #999999'],
                            borderRadius: 0,
                            height: 50,
                            paddingBottom: 12,
                            paddingLeft: 8,
                            paddingRight: 8,
                            paddingTop: 12,
                          },
                          dimensions.width
                        )}
                        textContentType={'name'}
                        value={registeredAgentNameInputValue}
                      />
                    </View>
                  </View>
                </View>
                {/* Address 1 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderBottomWidth: 1,
                      borderColor: theme.colors.divider,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      minHeight: 80,
                      paddingBottom: 12,
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  {/* Registered Agent Add1 View */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        marginBottom: 10,
                        paddingBottom: 5,
                        paddingTop: 5,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    {/* Registered Agent Add1 */}
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Medium'],
                          fontFamily: 'Inter_600SemiBold',
                          marginBottom: 8,
                          marginLeft: 5,
                        },
                        dimensions.width
                      )}
                    >
                      {'Registered Agent Address'}
                    </Text>
                    {/* Registered Agent Add1 Input */}
                    <TextInput
                      enablesReturnKeyAutomatically={false}
                      onChangeText={newRegisteredAgentAdd1InputValue => {
                        try {
                          setRegisteredAgentAdd1InputValue(
                            newRegisteredAgentAdd1InputValue
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      onSubmitEditing={() => {
                        try {
                          registeredAgentAdd2InputU6txZVEjRef.current.focus();
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Enter a value...'}
                      ref={registeredAgentAdd1Input8hGSiqujRef}
                      returnKeyType={'next'}
                      spellcheck={true}
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['Background'],
                          borderBottomWidth: 1,
                          borderColor: theme.colors['Custom #999999'],
                          borderRadius: 0,
                          height: 50,
                          paddingBottom: 12,
                          paddingLeft: 8,
                          paddingRight: 8,
                          paddingTop: 12,
                        },
                        dimensions.width
                      )}
                      textContentType={'name'}
                      value={registeredAgentAdd1InputValue}
                    />
                  </View>
                </View>
                {/* Address 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderBottomWidth: 1,
                      borderColor: theme.colors.divider,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      minHeight: 80,
                      paddingBottom: 12,
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  {/* Registered Agent Add2 View */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingBottom: 5, paddingTop: 5, width: '100%' },
                      dimensions.width
                    )}
                  >
                    {/* Registered Agent Add2 */}
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Medium'],
                          fontFamily: 'Inter_600SemiBold',
                          marginBottom: 8,
                          marginLeft: 5,
                        },
                        dimensions.width
                      )}
                    >
                      {'Address 2'}
                    </Text>
                    {/* Registered Agent Add2 Input */}
                    <TextInput
                      enablesReturnKeyAutomatically={false}
                      onChangeText={newRegisteredAgentAdd2InputValue => {
                        try {
                          setRegisteredAgentAdd2InputValue(
                            newRegisteredAgentAdd2InputValue
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      onSubmitEditing={() => {
                        try {
                          cityInputea6bAvnFRef.current.focus();
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Suite,Floor,Building,Apt...'}
                      ref={registeredAgentAdd2InputU6txZVEjRef}
                      returnKeyType={'next'}
                      spellcheck={true}
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['Background'],
                          borderBottomWidth: 1,
                          borderColor: theme.colors['Custom #999999'],
                          borderRadius: 0,
                          height: 50,
                          paddingBottom: 12,
                          paddingLeft: 8,
                          paddingRight: 8,
                          paddingTop: 12,
                        },
                        dimensions.width
                      )}
                      textContentType={'name'}
                      value={registeredAgentAdd2InputValue}
                    />
                  </View>
                </View>
                {/* City */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderBottomWidth: 1,
                      borderColor: theme.colors.divider,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      minHeight: 80,
                      paddingBottom: 12,
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  {/* City View */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingBottom: 5, paddingTop: 5, width: '100%' },
                      dimensions.width
                    )}
                  >
                    {/* City */}
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Medium'],
                          fontFamily: 'Inter_600SemiBold',
                          marginBottom: 8,
                          marginLeft: 5,
                        },
                        dimensions.width
                      )}
                    >
                      {'City'}
                    </Text>
                    {/* City Input */}
                    <TextInput
                      enablesReturnKeyAutomatically={false}
                      onChangeText={newCityInputValue => {
                        try {
                          setCityInputValue(newCityInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      onSubmitEditing={() => {
                        try {
                          zipCodeInput7fsHmeaaRef.current.focus();
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Enter a value...'}
                      ref={cityInputea6bAvnFRef}
                      returnKeyType={'next'}
                      spellcheck={true}
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['Background'],
                          borderBottomWidth: 1,
                          borderColor: theme.colors['Custom #999999'],
                          borderRadius: 0,
                          height: 50,
                          paddingBottom: 12,
                          paddingLeft: 8,
                          paddingRight: 8,
                          paddingTop: 12,
                        },
                        dimensions.width
                      )}
                      textContentType={'name'}
                      value={cityInputValue}
                    />
                  </View>
                </View>
                {/* Zip Code */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderBottomWidth: 1,
                      borderColor: theme.colors.divider,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      minHeight: 80,
                      paddingBottom: 12,
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  {/* Zip Code View */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingBottom: 5, paddingTop: 5, width: '100%' },
                      dimensions.width
                    )}
                  >
                    {/* Zip Code */}
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Medium'],
                          fontFamily: 'Inter_600SemiBold',
                          marginBottom: 8,
                          marginLeft: 5,
                        },
                        dimensions.width
                      )}
                    >
                      {'Zip Code'}
                    </Text>
                    {/* Zip Code Input */}
                    <NumberInput
                      editable={true}
                      onChangeText={newZipCodeInputValue => {
                        try {
                          setZipCodeInputValue(newZipCodeInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Enter a number...'}
                      ref={zipCodeInput7fsHmeaaRef}
                      returnKeyType={'next'}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: 1,
                          borderColor: theme.colors['Medium'],
                          borderRadius: 0,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          paddingRight: 8,
                          paddingTop: 8,
                        },
                        dimensions.width
                      )}
                      value={zipCodeInputValue}
                    />
                  </View>
                </View>
                {/* State */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderBottomWidth: 1,
                      borderColor: theme.colors.divider,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      minHeight: 80,
                      paddingBottom: 12,
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  {/* State View */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingBottom: 5, paddingTop: 5, width: '100%' },
                      dimensions.width
                    )}
                  >
                    {/* State */}
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Medium'],
                          fontFamily: 'Inter_600SemiBold',
                          marginBottom: 8,
                          marginLeft: 5,
                        },
                        dimensions.width
                      )}
                    >
                      {'State'}
                    </Text>
                    {/* State Picker */}
                    <Picker
                      iconColor={theme.colors['Akurie Primary']}
                      iconSize={24}
                      label={'Select A State'}
                      leftIconMode={'inset'}
                      onValueChange={newStatePickerValue => {
                        try {
                          setStatePickerValue(newStatePickerValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      options={Constants['aku_supported_states_vari']}
                      placeholderTextColor={theme.colors['Custom #666666']}
                      rightIconName={'AntDesign/downcircle'}
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['Background'],
                          color: theme.colors['Custom #101010'],
                          paddingRight: 15,
                        },
                        dimensions.width
                      )}
                      type={'solid'}
                      value={statePickerValue}
                    />
                  </View>
                </View>
                {/* County */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderBottomWidth: 1,
                      borderColor: theme.colors.divider,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      minHeight: 80,
                      paddingBottom: 12,
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  {/* County View */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingBottom: 5, paddingTop: 5, width: '100%' },
                      dimensions.width
                    )}
                  >
                    {/* County */}
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Medium'],
                          fontFamily: 'Inter_600SemiBold',
                          marginBottom: 8,
                          marginLeft: 5,
                        },
                        dimensions.width
                      )}
                    >
                      {'County'}
                    </Text>
                    {/* County Input */}
                    <TextInput
                      enablesReturnKeyAutomatically={false}
                      onChangeText={newCountyInputValue => {
                        try {
                          setCountyInputValue(newCountyInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Enter a value...'}
                      returnKeyType={'done'}
                      spellcheck={false}
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['Background'],
                          borderBottomWidth: 1,
                          borderColor: theme.colors['Custom #999999'],
                          borderRadius: 0,
                          height: 50,
                          paddingBottom: 12,
                          paddingLeft: 8,
                          paddingRight: 8,
                          paddingTop: 12,
                        },
                        dimensions.width
                      )}
                      textContentType={'name'}
                      value={countyInputValue}
                    />
                  </View>
                </View>
              </Surface>
            </View>

            <View
              style={StyleSheet.applyWidth(
                {
                  paddingBottom: 15,
                  paddingLeft: 15,
                  paddingRight: 15,
                  paddingTop: 8,
                },
                dimensions.width
              )}
            >
              <View>
                {/* Form */}
                <View
                  style={StyleSheet.applyWidth(
                    { opacity: 0.8, paddingLeft: 20, paddingRight: 20 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Error'],
                          fontFamily: 'Inter_400Regular',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {Constants['ak_errorMessage']}
                  </Text>
                </View>
              </View>

              <View
                style={StyleSheet.applyWidth(
                  {
                    marginBottom: 40,
                    paddingBottom: 10,
                    paddingLeft: 40,
                    paddingRight: 40,
                    paddingTop: 10,
                  },
                  dimensions.width
                )}
              >
                {/* Button */}
                <View
                  style={StyleSheet.applyWidth(
                    { borderRadius: 0 },
                    dimensions.width
                  )}
                >
                  {/* Touchable Frame */}
                  <View
                    style={StyleSheet.applyWidth(
                      { zIndex: 10 },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        const handler = async () => {
                          try {
                            const infoResponse = (
                              await businessDataAkurieAddRegisteredAgentPOST.mutateAsync(
                                {
                                  Address: registeredAgentAdd1InputValue,
                                  Address_2: registeredAgentAdd2InputValue,
                                  City: cityInputValue,
                                  County: countyInputValue,
                                  State: statePickerValue,
                                  registered_agent_name:
                                    registeredAgentNameInputValue,
                                  user_business_id: Constants['ak_businessID'],
                                  zip_code: zipCodeInputValue,
                                }
                              )
                            )?.json;
                            const errorMsg = infoResponse?.message;
                            setGlobalVariableValue({
                              key: 'ak_errorMessage',
                              value: errorMsg,
                            });
                            if (errorMsg) {
                              return;
                            }
                            setGlobalVariableValue({
                              key: 'ak_errorMessage',
                              value: false,
                            });
                            navigation.navigate('MyBusinessInfoScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        { borderRadius: 0 },
                        dimensions.width
                      )}
                    >
                      {/* CTA Frame */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: theme.colors['Akurie green'],
                            borderRadius: 12,
                            flexDirection: 'row',
                            paddingBottom: 12,
                            paddingLeft: 18,
                            paddingRight: 18,
                            paddingTop: 12,
                            zIndex: 10,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Label Frame */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexGrow: 1,
                              flexShrink: 0,
                              justifyContent: 'center',
                            },
                            dimensions.width
                          )}
                        >
                          {/* Label */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Strong'],
                                fontFamily: 'Inter_500Medium',
                                fontSize: 15,
                                lineHeight: 21,
                                marginLeft: 42,
                                textTransform: 'uppercase',
                              },
                              dimensions.width
                            )}
                          >
                            {'Update Info'}
                          </Text>
                        </View>
                        {/* Icon Group Frame */}
                        <View>
                          <Circle
                            bgColor={theme.colors.studilyOpacity25}
                            size={42}
                          >
                            {/* Icon Flex Frame */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  flexGrow: 0,
                                  flexShrink: 0,
                                  paddingBottom: 12,
                                  paddingLeft: 12,
                                  paddingRight: 12,
                                  paddingTop: 12,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Icon  */}
                              <Icon
                                color={theme.colors.studilyWhite}
                                name={'AntDesign/arrowright'}
                                size={18}
                              />
                            </View>
                          </Circle>
                        </View>
                      </View>
                    </Touchable>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </KeyboardAwareScrollView>
        <Surface
          style={StyleSheet.applyWidth(
            {
              backgroundColor: 'rgba(0, 0, 0, 0)',
              borderRadius: 6,
              minHeight: 40,
              opacity: 0.88,
            },
            dimensions.width
          )}
        />
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(RegisteredAgentInformationScreen);
