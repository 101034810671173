import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AuthAkurieApi from '../apis/AuthAkurieApi.js';
import * as BusinessDataAkurieApi from '../apis/BusinessDataAkurieApi.js';
import * as Filings$DocsApi from '../apis/Filings$DocsApi.js';
import * as StateBusinessDataAkurieApi from '../apis/StateBusinessDataAkurieApi.js';
import DesktopHeader13024SignedInBlock from '../components/DesktopHeader13024SignedInBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { MapMarker, MapView } from '@draftbit/maps';
import {
  Button,
  Divider,
  Icon,
  ScreenContainer,
  Spacer,
  SwipeableItem,
  SwipeableItemButton,
  SwipeableList,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  ImageBackground,
  ScrollView,
  StatusBar,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const MyFilingsReportsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [Blur_report_id, setBlur_report_id] = React.useState('');
  const [Filingbuttons, setFilingbuttons] = React.useState(true);
  const [business_state, setBusiness_state] = React.useState('');
  const [checkboxRowValue, setCheckboxRowValue] = React.useState('');
  const [classAccess1, setClassAccess1] = React.useState(true);
  const [classAccess2, setClassAccess2] = React.useState(false);
  const [classAccess3, setClassAccess3] = React.useState(false);
  const [classAccess4, setClassAccess4] = React.useState(false);
  const [confirming_filed_windowshow, setConfirming_filed_windowshow] =
    React.useState(false);
  const [datePickerValue, setDatePickerValue] = React.useState(new Date());
  const [datePickerValue2, setDatePickerValue2] = React.useState(new Date());
  const [fed_confirming_filed_window, setFed_confirming_filed_window] =
    React.useState(false);
  const [fed_filingbuttons, setFed_filingbuttons] = React.useState(true);
  const [numberInputValue, setNumberInputValue] = React.useState('');
  const [pickerValue, setPickerValue] = React.useState('');
  const [previous_reports, setPrevious_reports] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState('');
  const filings$DocsDeleteAFilingDELETE =
    Filings$DocsApi.useDeleteAFilingDELETE();
  const filings$DocsUndoFilingPATCH = Filings$DocsApi.useUndoFilingPATCH();
  const filings$DocsUpdateFilingCompletedPATCH =
    Filings$DocsApi.useUpdateFilingCompletedPATCH();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const authResponse = (await AuthAkurieApi.meGET(Constants))?.json;
        const tokenExpire = authResponse?.message;
        if (tokenExpire) {
          navigation.navigate('SessionExpiredScreen');
        }
        if (tokenExpire) {
          return;
        }
        const business_data = (
          await BusinessDataAkurieApi.getBusinessGET(Constants, {
            business_id: Constants['ak_businessID'],
          })
        )?.json;
        const state = business_data?.business_state;
        setBusiness_state(state);
        StatusBar.setBarStyle('dark-content');
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors.studilyWhite4, height: '100%' },
        dimensions.width
      )}
    >
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <DesktopHeader13024SignedInBlock />
        )}
      </>
      {/* Main Header w /c  */}
      <View
        style={StyleSheet.applyWidth(
          {
            paddingLeft: { minWidth: Breakpoints.Laptop, value: '15%' },
            paddingRight: { minWidth: Breakpoints.Laptop, value: '15%' },
          },
          dimensions.width
        )}
      >
        {/* Navigation Frame */}
        <View
          style={StyleSheet.applyWidth(
            {
              borderBottomWidth: 2,
              borderColor: theme.colors['Akurie green'],
              height: 90,
            },
            dimensions.width
          )}
        >
          <ImageBackground
            resizeMode={'cover'}
            source={Images.Asset13}
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors['Akurie Dark Blue'],
                flexDirection: 'row',
                height: '100%',
                justifyContent: 'space-evenly',
              },
              dimensions.width
            )}
          >
            {/* Left Side Frame */}
            <View
              style={StyleSheet.applyWidth(
                { paddingLeft: 9, paddingRight: 9 },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    navigation.goBack();
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* Icon Flex Frame */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexGrow: 0,
                      flexShrink: 0,
                      paddingBottom: 12,
                      paddingLeft: 12,
                      paddingRight: 12,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  {/* Icon  */}
                  <Icon
                    color={theme.colors['Background']}
                    name={'Ionicons/arrow-back-outline'}
                    size={24}
                  />
                </View>
              </Touchable>
            </View>
            {/* Middle Frame */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'flex-start',
                  flexGrow: 1,
                  flexShrink: 0,
                  justifyContent: 'center',
                },
                dimensions.width
              )}
            >
              {/* Text Frame  */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: 'rgba(8, 31, 61, 0.5)',
                    flexGrow: 0,
                    flexShrink: 0,
                    marginLeft: 20,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                {/* Text 12 18 Regular */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Akurie green'],
                      fontFamily: 'Inter_400Regular',
                      fontSize: 22,
                      lineHeight: 18,
                      paddingTop: 8,
                    },
                    dimensions.width
                  )}
                >
                  {'My Annual Report Filing'}
                </Text>
              </View>
            </View>
          </ImageBackground>
        </View>
      </View>

      <ImageBackground
        resizeMode={'stretch'}
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            height: '100%',
            justifyContent: 'center',
            width: '100%',
          },
          dimensions.width
        )}
      >
        <>
          {!confirming_filed_windowshow ? null : (
            <BlurView
              intensity={80}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.BlurViewStyles(theme)['Blur View'],
                  {
                    alignItems: 'center',
                    height: '100%',
                    justifyContent: 'center',
                    width: '100%',
                    zIndex: 1,
                  }
                ),
                dimensions.width
              )}
              tint={'dark'}
            >
              <>
                {!confirming_filed_windowshow ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        height: '70%',
                        justifyContent: 'flex-start',
                        marginTop: 60,
                        width: [
                          { minWidth: Breakpoints.Mobile, value: '70%' },
                          { minWidth: Breakpoints.Tablet, value: '60%' },
                          { minWidth: Breakpoints.Desktop, value: '50%' },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    {/* Card */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['Divider'],
                          borderRadius: 12,
                          height: '80%',
                          minWidth: '90%',
                          overflow: 'hidden',
                        },
                        dimensions.width
                      )}
                    >
                      <Filings$DocsApi.FetchGetOneFilingGET
                        filing_id={Blur_report_id}
                      >
                        {({ loading, error, data, refetchGetOneFiling }) => {
                          const fetchData = data?.json;
                          if (loading) {
                            return <ActivityIndicator />;
                          }

                          if (
                            error ||
                            data?.status < 200 ||
                            data?.status >= 300
                          ) {
                            return <ActivityIndicator />;
                          }

                          return (
                            <ImageBackground
                              resizeMode={'cover'}
                              source={Images.FirefoxPHKyvBthWe}
                              style={StyleSheet.applyWidth(
                                { height: '100%' },
                                dimensions.width
                              )}
                            >
                              <ImageBackground
                                resizeMode={'cover'}
                                source={Images.AdobeStock606166034}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ImageBackgroundStyles(theme)[
                                      'Image Background'
                                    ],
                                    {
                                      alignItems: 'center',
                                      height: 250,
                                      justifyContent: 'center',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                      marginBottom: 30,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text'],
                                        {
                                          color: theme.colors['Strong_Inverse'],
                                          fontFamily: 'Inter_400Regular',
                                          fontSize: 22,
                                          textAlign: 'center',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {'Warning Deleting'}
                                  </Text>
                                  <Icon
                                    color={theme.colors['Akurie green']}
                                    name={'AntDesign/warning'}
                                    size={32}
                                    style={StyleSheet.applyWidth(
                                      { marginLeft: 15 },
                                      dimensions.width
                                    )}
                                  />
                                </View>

                                <Text
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      {
                                        color: theme.colors['Strong_Inverse'],
                                        fontFamily: 'Inter_400Regular',
                                        fontSize: 18,
                                        textAlign: 'center',
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {business_state}
                                  {' - '}
                                  {fetchData?.Filing_Type}
                                  {'\nDue: '}
                                  {fetchData?.Due_Date}
                                </Text>

                                <Text
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      {
                                        color: theme.colors['Akurie Primary'],
                                        fontFamily: 'Inter_300Light',
                                        fontSize: 14,
                                        textAlign: 'center',
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'Filing File ID: '}
                                  {Blur_report_id}
                                </Text>
                              </ImageBackground>
                              {/* Body */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    paddingBottom: 24,
                                    paddingLeft: 24,
                                    paddingRight: 24,
                                    paddingTop: 24,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors['Custom #101010'],
                                      fontFamily: 'Inter_400Regular',
                                      fontSize: 14,
                                      paddingLeft: 15,
                                      paddingRight: 15,
                                      textAlign: 'left',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {
                                    'Please confirm that you would like to delete this '
                                  }
                                  {business_state}
                                  {' state filing.'}
                                </Text>
                                {/* Topics */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexDirection: 'row',
                                      justifyContent: 'space-around',
                                      marginTop: 16,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Button
                                    onPress={() => {
                                      try {
                                        setConfirming_filed_windowshow(false);
                                        setFilingbuttons(true);
                                        setBlur_report_id('');
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    style={StyleSheet.applyWidth(
                                      {
                                        backgroundColor:
                                          theme.colors['Akurie Dark Blue'],
                                        borderRadius: 8,
                                        color: theme.colors['Akurie green'],
                                        fontFamily: 'System',
                                        fontWeight: '700',
                                        marginRight: 8,
                                        textAlign: 'center',
                                        width: 130,
                                      },
                                      dimensions.width
                                    )}
                                    title={'Cancel'}
                                  />
                                  <Button
                                    onPress={() => {
                                      const handler = async () => {
                                        try {
                                          setConfirming_filed_windowshow(false);
                                          setFilingbuttons(true);
                                          (
                                            await filings$DocsDeleteAFilingDELETE.mutateAsync(
                                              { filing_id: Blur_report_id }
                                            )
                                          )?.json;
                                          undefined;
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      };
                                      handler();
                                    }}
                                    style={StyleSheet.applyWidth(
                                      {
                                        backgroundColor: theme.colors['Error'],
                                        borderRadius: 8,
                                        fontFamily: 'System',
                                        fontWeight: '700',
                                        textAlign: 'center',
                                        width: 130,
                                      },
                                      dimensions.width
                                    )}
                                    title={'Delete'}
                                  />
                                </View>
                              </View>
                            </ImageBackground>
                          );
                        }}
                      </Filings$DocsApi.FetchGetOneFilingGET>
                    </View>
                  </View>
                )}
              </>
            </BlurView>
          )}
        </>
        <>
          {confirming_filed_windowshow ? null : (
            <ScrollView
              bounces={true}
              showsVerticalScrollIndicator={false}
              style={StyleSheet.applyWidth(
                {
                  width: [
                    { minWidth: Breakpoints.Mobile, value: '100%' },
                    { minWidth: Breakpoints.Laptop, value: '50%' },
                  ],
                },
                dimensions.width
              )}
              contentContainerStyle={StyleSheet.applyWidth(
                {
                  marginBottom: 200,
                  paddingBottom: 40,
                  paddingLeft: 10,
                  paddingRight: 10,
                },
                dimensions.width
              )}
            >
              {/* State Stats View */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 10, width: '100%' },
                  dimensions.width
                )}
              >
                <>
                  {!previous_reports ? null : (
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.strong,
                          fontFamily: 'Inter_400Regular',
                          fontSize: 22,
                          marginTop: 20,
                          textAlign: 'center',
                        },
                        dimensions.width
                      )}
                    >
                      {'My Filed '}
                      {business_state}
                      {' State Filings'}
                    </Text>
                  )}
                </>
                <>
                  {previous_reports ? null : (
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.strong,
                          fontFamily: 'Inter_400Regular',
                          fontSize: 22,
                          marginTop: 20,
                          textAlign: 'center',
                        },
                        dimensions.width
                      )}
                    >
                      {'Current '}
                      {business_state}
                      {' State Reports'}
                    </Text>
                  )}
                </>
                <Divider
                  color={theme.colors['Akurie Dark Blue']}
                  style={StyleSheet.applyWidth(
                    { height: 2, marginTop: 10 },
                    dimensions.width
                  )}
                />
                {/* State Stats View */}
                <>
                  {previous_reports ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { marginTop: 25, paddingLeft: 10, paddingRight: 10 },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: theme.colors['Custom Color'],
                            borderRadius: 16,
                            height: 109,
                            opacity: 0.92,
                          },
                          dimensions.width
                        )}
                      />
                      <Filings$DocsApi.FetchGetFilings$NumbersGET
                        bus_id={Constants['ak_businessID']}
                      >
                        {({
                          loading,
                          error,
                          data,
                          refetchGetFilings$Numbers,
                        }) => {
                          const fetchData = data?.json;
                          if (loading) {
                            return <ActivityIndicator />;
                          }

                          if (
                            error ||
                            data?.status < 200 ||
                            data?.status >= 300
                          ) {
                            return <ActivityIndicator />;
                          }

                          return (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  borderRadius: 16,
                                  flexDirection: 'row',
                                  height: 109,
                                  justifyContent: 'space-evenly',
                                  left: 0,
                                  marginLeft: 16,
                                  marginRight: 16,
                                  opacity: 1,
                                  position: 'absolute',
                                  right: 0,
                                  top: 0,
                                },
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flex: 1,
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                  },
                                  dimensions.width
                                )}
                              >
                                <View>
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      {
                                        color: theme.colors['Custom Color_2'],
                                        textAlign: 'center',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'Upcoming\nFilings Due'}
                                  </Text>

                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        marginTop: 8,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      style={StyleSheet.applyWidth(
                                        {
                                          color: theme.colors['Custom Color_2'],
                                          fontFamily: 'Inter_700Bold',
                                          fontSize: 20,
                                          textAlign: 'center',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {fetchData?.Number_Of_Filings}
                                    </Text>
                                  </View>
                                </View>
                                <Divider
                                  color={theme.colors['Custom Color_2']}
                                  style={StyleSheet.applyWidth(
                                    {
                                      height: 40,
                                      position: 'absolute',
                                      right: 0,
                                      width: 1,
                                    },
                                    dimensions.width
                                  )}
                                />
                              </View>

                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flex: 1,
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                  },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      flexDirection: 'column',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      {
                                        color: theme.colors['Custom Color_2'],
                                        textAlign: 'center',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'Past Due\nFilings'}
                                  </Text>

                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        marginTop: 8,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      style={StyleSheet.applyWidth(
                                        {
                                          color: theme.colors['Custom Color_2'],
                                          fontFamily: 'Inter_700Bold',
                                          fontSize: 20,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {'0'}
                                    </Text>
                                  </View>
                                </View>
                                <Divider
                                  color={theme.colors['Custom Color_2']}
                                  style={StyleSheet.applyWidth(
                                    {
                                      height: 40,
                                      position: 'absolute',
                                      right: 0,
                                      width: 1,
                                    },
                                    dimensions.width
                                  )}
                                />
                              </View>

                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flex: 1,
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                  },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    { alignItems: 'center' },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignSelf: 'center',
                                        color: theme.colors['Custom Color_2'],
                                        textAlign: 'center',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'Filings \nCompleted'}
                                  </Text>

                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        marginTop: 8,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      style={StyleSheet.applyWidth(
                                        {
                                          color: theme.colors['Custom Color_2'],
                                          fontFamily: 'Inter_700Bold',
                                          fontSize: 20,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {fetchData?.Number_Of_Filed_Docs}
                                    </Text>
                                  </View>
                                </View>
                              </View>
                            </View>
                          );
                        }}
                      </Filings$DocsApi.FetchGetFilings$NumbersGET>
                    </View>
                  )}
                </>
              </View>
              {/* State Filings */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, marginTop: 20 },
                  dimensions.width
                )}
              >
                {/* Past Filings */}
                <>
                  {!previous_reports ? null : (
                    <View>
                      {/* Fetch 2 */}
                      <Filings$DocsApi.FetchGetFiledFilingsGET
                        bus_id={Constants['ak_businessID']}
                      >
                        {({ loading, error, data, refetchGetFiledFilings }) => {
                          const fetch2Data = data?.json;
                          if (loading) {
                            return <ActivityIndicator />;
                          }

                          if (
                            error ||
                            data?.status < 200 ||
                            data?.status >= 300
                          ) {
                            return <ActivityIndicator />;
                          }

                          return (
                            <SwipeableList
                              data={fetch2Data?.Past_Filings}
                              disableScrollWhenSwiping={true}
                              estimatedItemSize={50}
                              keyExtractor={(swipeableListData, index) =>
                                swipeableListData?.id ??
                                swipeableListData?.uuid ??
                                index.toString()
                              }
                              listComponent={'FlatList'}
                              listKey={'qkgxHKbR'}
                              numColumns={1}
                              onEndReachedThreshold={0.5}
                              renderItem={({ item, index }) => {
                                const swipeableListData = item;
                                return (
                                  <SwipeableItem
                                    closeOnPress={true}
                                    friction={20}
                                    onSwipedRight={swipedIndex => {
                                      const handler = async () => {
                                        try {
                                          (
                                            await filings$DocsUndoFilingPATCH.mutateAsync(
                                              {
                                                filings_id:
                                                  swipeableListData?.id,
                                              }
                                            )
                                          )?.json;
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      };
                                      handler();
                                    }}
                                    rightSwipeBackgroundColor={
                                      theme.colors['Akurie Dark Blue']
                                    }
                                    rightSwipeColor={
                                      theme.colors['Akurie green']
                                    }
                                    rightSwipeIcon={'EvilIcons/undo'}
                                    rightSwipeTitle={'Undo Filing'}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.SwipeableItemStyles(theme)[
                                        'Swipeable Item'
                                      ],
                                      dimensions.width
                                    )}
                                    swipeActivationPercentage={80}
                                    swipeToClosePercent={50}
                                    swipeToOpenPercent={50}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          backgroundColor:
                                            theme.colors['BG Gray'],
                                          justifyContent: 'center',
                                          paddingBottom: 8,
                                          paddingLeft: 10,
                                          paddingRight: 15,
                                          paddingTop: 8,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            backgroundColor:
                                              theme.colors['Custom Color_18'],
                                            borderRadius: 9,
                                            padding: 15,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              flexDirection: 'row',
                                              marginRight: 10,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            numberOfLines={4}
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignSelf: 'flex-start',
                                                color:
                                                  theme.colors['Background'],
                                                fontFamily: 'System',
                                                fontSize: 18,
                                                fontWeight: '600',
                                                marginBottom: 12,
                                                paddingRight: 30,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {swipeableListData?.Filing_Type}
                                          </Text>
                                        </View>

                                        <Text
                                          style={StyleSheet.applyWidth(
                                            {
                                              color:
                                                theme.colors['Akurie green'],
                                              fontFamily: 'System',
                                              fontSize: 16,
                                              fontWeight: '600',
                                              paddingRight: 80,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {'Cost: $'}
                                          {swipeableListData?.Cost}{' '}
                                        </Text>

                                        <Text
                                          style={StyleSheet.applyWidth(
                                            {
                                              color:
                                                theme.colors['Custom Color'],
                                              fontFamily: 'System',
                                              fontSize: 16,
                                              fontWeight: '600',
                                              paddingRight: 50,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {'Due: '}
                                          {swipeableListData?.Due_Date}
                                        </Text>
                                        <Spacer
                                          bottom={8}
                                          left={8}
                                          right={8}
                                          top={8}
                                        />
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            {
                                              color: theme.colors['Background'],
                                              fontFamily: 'System',
                                              fontWeight: '600',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {'Frequency: '}
                                          {swipeableListData?.Frequency}
                                        </Text>
                                        <Spacer
                                          bottom={8}
                                          left={8}
                                          right={8}
                                          top={8}
                                        />
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            {
                                              color: theme.colors['Background'],
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {'File With: '}
                                          {swipeableListData?.Filing_Authority}
                                        </Text>
                                        <Spacer
                                          bottom={8}
                                          left={8}
                                          right={8}
                                          top={8}
                                        />
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            {
                                              color: theme.colors['Background'],
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {'Notes: \n'}
                                          {swipeableListData?.Filing_Notes}
                                          {'\n\n'}
                                          {swipeableListData?.Notes_2}
                                          {'\n\nPenalty: '}
                                          {swipeableListData?.Penalty}
                                          {'\n'}
                                        </Text>
                                        <Spacer
                                          bottom={8}
                                          left={8}
                                          right={8}
                                          top={8}
                                        />
                                      </View>
                                      {/* View 4 */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'center',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            marginTop: 20,
                                            padding: 4,
                                            paddingLeft: 20,
                                            paddingRight: 20,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Touchable
                                          onPress={() => {
                                            const handler = async () => {
                                              try {
                                                (
                                                  await filings$DocsUndoFilingPATCH.mutateAsync(
                                                    {
                                                      filings_id:
                                                        swipeableListData?.id,
                                                    }
                                                  )
                                                )?.json;
                                              } catch (err) {
                                                console.error(err);
                                              }
                                            };
                                            handler();
                                          }}
                                        >
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                backgroundColor:
                                                  theme.colors['Akurie green'],
                                                borderRadius: 10,
                                                flexDirection: 'row',
                                                padding: 12,
                                                paddingLeft: 20,
                                                paddingRight: 20,
                                                width: '100%',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    fontFamily:
                                                      'Inter_400Regular',
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {'Undo Filing'}
                                            </Text>
                                            <Icon
                                              name={
                                                'MaterialCommunityIcons/file-undo'
                                              }
                                              size={24}
                                              style={StyleSheet.applyWidth(
                                                { marginLeft: 5 },
                                                dimensions.width
                                              )}
                                            />
                                          </View>
                                        </Touchable>
                                        {/* Touchable 2 */}
                                        <Touchable
                                          onPress={() => {
                                            try {
                                              setBlur_report_id(
                                                swipeableListData?.id
                                              );
                                              setConfirming_filed_windowshow(
                                                true
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                        >
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                backgroundColor:
                                                  theme.colors['Error'],
                                                borderRadius: 10,
                                                flexDirection: 'row',
                                                padding: 12,
                                                paddingLeft: 20,
                                                paddingRight: 20,
                                                width: '100%',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    color:
                                                      theme.colors[
                                                        'Strong Inverse'
                                                      ],
                                                    fontFamily:
                                                      'Inter_400Regular',
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {'Remove'}
                                            </Text>
                                            <Icon
                                              color={
                                                theme.colors['Strong Inverse']
                                              }
                                              name={'FontAwesome/trash'}
                                              size={24}
                                              style={StyleSheet.applyWidth(
                                                { marginLeft: 5 },
                                                dimensions.width
                                              )}
                                            />
                                          </View>
                                        </Touchable>
                                      </View>
                                    </View>
                                    {/* Swipeable Item Button 2 */}
                                    <SwipeableItemButton
                                      backgroundColor={theme.colors['Error']}
                                      closeOnPress={true}
                                      color={theme.colors['Strong_Inverse']}
                                      icon={'FontAwesome/trash-o'}
                                      onPress={() => {
                                        try {
                                          setConfirming_filed_windowshow(true);
                                          setBlur_report_id(
                                            swipeableListData?.id
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      revealSwipeDirection={'left'}
                                      title={'Delete'}
                                    />
                                  </SwipeableItem>
                                );
                              }}
                              showsHorizontalScrollIndicator={true}
                              showsVerticalScrollIndicator={true}
                            />
                          );
                        }}
                      </Filings$DocsApi.FetchGetFiledFilingsGET>
                    </View>
                  )}
                </>
                {/* Current Filings */}
                <>
                  {previous_reports ? null : (
                    <View>
                      <Filings$DocsApi.FetchGetFilings$NumbersGET
                        bus_id={Constants['ak_businessID']}
                      >
                        {({
                          loading,
                          error,
                          data,
                          refetchGetFilings$Numbers,
                        }) => {
                          const fetchData = data?.json;
                          if (loading) {
                            return <ActivityIndicator />;
                          }

                          if (
                            error ||
                            data?.status < 200 ||
                            data?.status >= 300
                          ) {
                            return <ActivityIndicator />;
                          }

                          return (
                            <SwipeableList
                              data={fetchData?.Needed_Filings}
                              disableScrollWhenSwiping={true}
                              estimatedItemSize={50}
                              keyExtractor={(swipeableListData, index) =>
                                swipeableListData?.id ??
                                swipeableListData?.uuid ??
                                index.toString()
                              }
                              listComponent={'FlatList'}
                              listKey={'GkmW2gwJ'}
                              numColumns={1}
                              onEndReachedThreshold={0.5}
                              renderItem={({ item, index }) => {
                                const swipeableListData = item;
                                return (
                                  <SwipeableItem
                                    closeOnPress={true}
                                    friction={20}
                                    onSwipedRight={swipedIndex => {
                                      const handler = async () => {
                                        try {
                                          (
                                            await filings$DocsUpdateFilingCompletedPATCH.mutateAsync(
                                              {
                                                filings_id:
                                                  swipeableListData?.id,
                                              }
                                            )
                                          )?.json;
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      };
                                      handler();
                                    }}
                                    rightSwipeBackgroundColor={
                                      theme.colors['Akurie Dark Blue']
                                    }
                                    rightSwipeColor={
                                      theme.colors['Akurie green']
                                    }
                                    rightSwipeIcon={
                                      'MaterialIcons/file-download-done'
                                    }
                                    rightSwipeTitle={'Filing Complete'}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.SwipeableItemStyles(theme)[
                                        'Swipeable Item'
                                      ],
                                      dimensions.width
                                    )}
                                    swipeActivationPercentage={80}
                                    swipeToClosePercent={50}
                                    swipeToOpenPercent={50}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          backgroundColor:
                                            theme.colors['BG Gray'],
                                          justifyContent: 'center',
                                          paddingBottom: 8,
                                          paddingLeft: 10,
                                          paddingRight: 15,
                                          paddingTop: 8,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            backgroundColor:
                                              theme.colors['Custom Color_18'],
                                            borderRadius: 9,
                                            padding: 15,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              flexDirection: 'row',
                                              justifyContent: 'center',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            numberOfLines={4}
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignSelf: 'flex-start',
                                                color:
                                                  theme.colors['Background'],
                                                fontFamily: 'Inter_600SemiBold',
                                                fontSize: 18,
                                                marginBottom: 12,
                                                paddingRight: 30,
                                                textAlign: 'center',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {swipeableListData?.Filing_Type}
                                          </Text>
                                        </View>

                                        <Text
                                          style={StyleSheet.applyWidth(
                                            {
                                              color:
                                                theme.colors['Akurie green'],
                                              fontFamily: 'Inter_600SemiBold',
                                              fontSize: 18,
                                              paddingRight: 80,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {'Cost: $'}
                                          {swipeableListData?.Cost}{' '}
                                        </Text>
                                        <Spacer
                                          bottom={8}
                                          left={8}
                                          right={8}
                                          top={8}
                                        />
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            {
                                              color: theme.colors['Background'],
                                              fontFamily: 'System',
                                              fontWeight: '600',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {'Frequency: '}
                                          {swipeableListData?.Frequency}
                                        </Text>
                                        <Spacer
                                          bottom={8}
                                          left={8}
                                          right={8}
                                          top={8}
                                        />
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            {
                                              color: theme.colors['Background'],
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {'File With: '}
                                          {swipeableListData?.Filing_Authority}
                                        </Text>
                                        <Spacer
                                          bottom={8}
                                          left={8}
                                          right={8}
                                          top={8}
                                        />
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            {
                                              color: theme.colors['Background'],
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {'Notes: \n'}
                                          {swipeableListData?.Filing_Notes}
                                          {'\n\n'}
                                          {swipeableListData?.Notes_2}
                                          {'\n\nPenalty: '}
                                          {swipeableListData?.Penalty}
                                          {'\n'}
                                        </Text>
                                        <Spacer
                                          bottom={8}
                                          left={8}
                                          right={8}
                                          top={8}
                                        />
                                      </View>
                                      {/* View 2 */}
                                      <>
                                        {previous_reports ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                marginTop: 20,
                                                padding: 4,
                                                paddingLeft: 20,
                                                paddingRight: 20,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Touchable
                                              onPress={() => {
                                                const handler = async () => {
                                                  try {
                                                    (
                                                      await filings$DocsUpdateFilingCompletedPATCH.mutateAsync(
                                                        {
                                                          filings_id:
                                                            swipeableListData?.id,
                                                        }
                                                      )
                                                    )?.json;
                                                  } catch (err) {
                                                    console.error(err);
                                                  }
                                                };
                                                handler();
                                              }}
                                            >
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    backgroundColor:
                                                      theme.colors[
                                                        'Akurie green'
                                                      ],
                                                    borderRadius: 10,
                                                    flexDirection: 'row',
                                                    padding: 12,
                                                    paddingLeft: 20,
                                                    paddingRight: 20,
                                                    width: '100%',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                <Text
                                                  style={StyleSheet.applyWidth(
                                                    StyleSheet.compose(
                                                      GlobalStyles.TextStyles(
                                                        theme
                                                      )['Text'],
                                                      {
                                                        fontFamily:
                                                          'Inter_400Regular',
                                                      }
                                                    ),
                                                    dimensions.width
                                                  )}
                                                >
                                                  {'Completed'}
                                                </Text>
                                                <Icon
                                                  name={'AntDesign/checkcircle'}
                                                  size={24}
                                                  style={StyleSheet.applyWidth(
                                                    { marginLeft: 5 },
                                                    dimensions.width
                                                  )}
                                                />
                                              </View>
                                            </Touchable>
                                            {/* Touchable 2 */}
                                            <Touchable
                                              onPress={() => {
                                                try {
                                                  navigation.navigate(
                                                    'AskMyAssistantScreen'
                                                  );
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              }}
                                            >
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    backgroundColor:
                                                      theme.colors[
                                                        'Akurie Primary'
                                                      ],
                                                    borderRadius: 10,
                                                    flexDirection: 'row',
                                                    padding: 12,
                                                    paddingLeft: 20,
                                                    paddingRight: 20,
                                                    width: '100%',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                <Text
                                                  style={StyleSheet.applyWidth(
                                                    StyleSheet.compose(
                                                      GlobalStyles.TextStyles(
                                                        theme
                                                      )['Text'],
                                                      {
                                                        color:
                                                          theme.colors[
                                                            'Akurie Dark Blue'
                                                          ],
                                                        fontFamily:
                                                          'Inter_400Regular',
                                                      }
                                                    ),
                                                    dimensions.width
                                                  )}
                                                >
                                                  {'File For Me'}
                                                </Text>
                                                <Icon
                                                  name={
                                                    'Ionicons/file-tray-stacked-sharp'
                                                  }
                                                  size={24}
                                                  style={StyleSheet.applyWidth(
                                                    { marginLeft: 5 },
                                                    dimensions.width
                                                  )}
                                                />
                                              </View>
                                            </Touchable>
                                          </View>
                                        )}
                                      </>
                                    </View>
                                    <>
                                      {previous_reports ? null : (
                                        <SwipeableItemButton
                                          backgroundColor={
                                            theme.colors['Akurie Primary']
                                          }
                                          closeOnPress={true}
                                          color={theme.colors['Strong_Inverse']}
                                          icon={
                                            'MaterialCommunityIcons/file-send'
                                          }
                                          onPress={() => {
                                            try {
                                              navigation.navigate(
                                                'AskMyAssistantScreen'
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          revealSwipeDirection={'left'}
                                          title={'File For Me'}
                                        />
                                      )}
                                    </>
                                  </SwipeableItem>
                                );
                              }}
                              showsHorizontalScrollIndicator={true}
                              showsVerticalScrollIndicator={true}
                            />
                          );
                        }}
                      </Filings$DocsApi.FetchGetFilings$NumbersGET>
                    </View>
                  )}
                </>
              </View>

              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 20, paddingLeft: 10, paddingRight: 10 },
                  dimensions.width
                )}
              >
                <>
                  {previous_reports ? null : (
                    <Button
                      icon={'AntDesign/book'}
                      onPress={() => {
                        try {
                          setPrevious_reports(true);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['Custom Color_18'],
                          borderRadius: 8,
                          color: theme.colors['Akurie green'],
                          fontFamily: 'Inter_600SemiBold',
                          height: 60,
                          paddingLeft: 20,
                          paddingRight: 20,
                          textAlign: 'center',
                        },
                        dimensions.width
                      )}
                      title={'View Previous State Filings'}
                    />
                  )}
                </>
                <>
                  {!previous_reports ? null : (
                    <Button
                      icon={'AntDesign/book'}
                      onPress={() => {
                        try {
                          setPrevious_reports(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['Akurie Dark Blue'],
                          borderRadius: 8,
                          color: theme.colors['Akurie green'],
                          fontFamily: 'System',
                          fontWeight: '700',
                          height: 60,
                          paddingLeft: 20,
                          paddingRight: 20,
                          textAlign: 'center',
                        },
                        dimensions.width
                      )}
                      title={'View Current State Filings'}
                    />
                  )}
                </>
              </View>
              {/* Explore Other Forms */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    marginBottom: 10,
                    marginTop: 10,
                    paddingBottom: 10,
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingTop: 10,
                  },
                  dimensions.width
                )}
              >
                <StateBusinessDataAkurieApi.FetchSecretaryOfStateByStateGET
                  state_name={business_state}
                >
                  {({
                    loading,
                    error,
                    data,
                    refetchSecretaryOfStateByState,
                  }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <Button
                        onPress={() => {
                          try {
                            Linking.openURL(`${fetchData?.sos_website}`);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: theme.colors['Akurie green'],
                            borderRadius: 8,
                            color: theme.colors['Strong'],
                            fontFamily: 'Inter_600SemiBold',
                            height: 60,
                            textAlign: 'center',
                          },
                          dimensions.width
                        )}
                        title={'Explore Other Forms & Filings'}
                      />
                    );
                  }}
                </StateBusinessDataAkurieApi.FetchSecretaryOfStateByStateGET>
              </View>

              <View>
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: theme.colors.surface,
                      borderRadius: 16,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      paddingRight: 8,
                      paddingTop: 8,
                    },
                    dimensions.width
                  )}
                >
                  <StateBusinessDataAkurieApi.FetchSecretaryOfStateByStateGET
                    state_name={business_state}
                  >
                    {({
                      loading,
                      error,
                      data,
                      refetchSecretaryOfStateByState,
                    }) => {
                      const fetchData = data?.json;
                      if (loading) {
                        return <ActivityIndicator />;
                      }

                      if (error || data?.status < 200 || data?.status >= 300) {
                        return <ActivityIndicator />;
                      }

                      return (
                        <>
                          {/* Secretary Of State */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors.strong,
                                fontFamily: 'System',
                                fontSize: 18,
                                fontWeight: '700',
                                paddingBottom: 6,
                                paddingTop: 6,
                              },
                              dimensions.width
                            )}
                          >
                            {fetchData?.state_name}
                            {' Secretary Of State Office '}
                          </Text>
                          <Divider
                            color={theme.colors['Akurie Primary']}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.DividerStyles(theme)['Divider'],
                                { height: 2, marginBottom: 10 }
                              ),
                              dimensions.width
                            )}
                          />
                          <Touchable
                            onPress={() => {
                              try {
                                Linking.openURL(
                                  `tel:${fetchData?.phone_number}`
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            {/* SOS Number */}
                            <Text
                              accessibilityRole={'none'}
                              dataDetectorType={'phoneNumber'}
                              selectable={true}
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors.strong,
                                  fontFamily: 'System',
                                  fontSize: 16,
                                  fontWeight: '600',
                                  paddingBottom: 5,
                                },
                                dimensions.width
                              )}
                            >
                              {fetchData?.phone_number}
                            </Text>
                          </Touchable>
                          {/* SOS Office */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors.strong,
                                fontFamily: 'System',
                                fontSize: 16,
                                fontWeight: '400',
                                marginBottom: 10,
                                paddingBottom: 14,
                              },
                              dimensions.width
                            )}
                          >
                            {fetchData?.location_1_Address}
                          </Text>
                          <Button
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  await WebBrowser.openBrowserAsync(
                                    `${fetchData?.sos_website}`
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ButtonStyles(theme)['Button'],
                                {
                                  backgroundColor:
                                    theme.colors['Akurie Dark Blue'],
                                  borderRadius: 12,
                                  fontFamily: 'Inter_400Regular',
                                  width: '50%',
                                }
                              ),
                              dimensions.width
                            )}
                            title={'Visit Official Website'}
                          />
                        </>
                      );
                    }}
                  </StateBusinessDataAkurieApi.FetchSecretaryOfStateByStateGET>
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderRadius: 10,
                        height: 240,
                        marginTop: 10,
                        overflow: 'hidden',
                      },
                      dimensions.width
                    )}
                  >
                    <StateBusinessDataAkurieApi.FetchSecretaryOfStateByStateGET
                      state_name={business_state}
                    >
                      {({
                        loading,
                        error,
                        data,
                        refetchSecretaryOfStateByState,
                      }) => {
                        const fetchData = data?.json;
                        if (loading) {
                          return <ActivityIndicator />;
                        }

                        if (
                          error ||
                          data?.status < 200 ||
                          data?.status >= 300
                        ) {
                          return <ActivityIndicator />;
                        }

                        return (
                          <MapView
                            apiKey={'AIzaSyBzktToWosjNgrrUawZnbslB9NSXSXCkwo'}
                            latitude={fetchData?.location_1_Lat}
                            loadingEnabled={true}
                            longitude={fetchData?.location_1_Lon}
                            rotateEnabled={true}
                            scrollEnabled={true}
                            showsPointsOfInterest={true}
                            style={StyleSheet.applyWidth(
                              { flex: 1 },
                              dimensions.width
                            )}
                            zoom={16}
                            zoomEnabled={true}
                          >
                            <MapMarker
                              latitude={fetchData?.location_1_Lat}
                              longitude={fetchData?.location_1_Lon}
                              pinColor={theme.colors['Akurie Primary']}
                            />
                          </MapView>
                        );
                      }}
                    </StateBusinessDataAkurieApi.FetchSecretaryOfStateByStateGET>
                  </View>
                </View>
              </View>
              <Spacer bottom={8} left={8} right={8} top={30} />
              <Spacer bottom={50} left={8} right={8} top={50} />
            </ScrollView>
          )}
        </>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(MyFilingsReportsScreen);
