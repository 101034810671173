import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import encodeQueryParam from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const afterTaxNumbersPOST = (
  Constants,
  { afterTaxNumber, state },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:dyI8_hXg/AIassistant/aftTaxes/${
      typeof afterTaxNumber === 'string'
        ? afterTaxNumber
        : JSON.stringify(afterTaxNumber ?? '')
    }`,
    {
      body: JSON.stringify({ afterTaxNumber: afterTaxNumber, state: state }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useAfterTaxNumbersPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      afterTaxNumbersPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('AI_Assistant', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('AI_Assistant');
        queryClient.invalidateQueries('AI_Assistants');
      },
    }
  );
};

export const FetchAfterTaxNumbersPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  afterTaxNumber,
  state,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useAfterTaxNumbersPOST(
    { afterTaxNumber, state },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchAfterTaxNumbers: refetch });
};

export const basicStepsSetupPOST = (Constants, { businessID }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:dyI8_hXg/AIassistant/Create/BasicSteps/${
      typeof businessID === 'string'
        ? businessID
        : JSON.stringify(businessID ?? '')
    }`,
    {
      body: JSON.stringify({ key: 'value' }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useBasicStepsSetupPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      basicStepsSetupPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('AI_Assistant', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('AI_Assistant');
        queryClient.invalidateQueries('AI_Assistants');
      },
    }
  );
};

export const FetchBasicStepsSetupPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  businessID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useBasicStepsSetupPOST(
    { businessID },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchBasicStepsSetup: refetch });
};

export const completeStepPATCH = (Constants, { step_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:dyI8_hXg/business_action_plans/status_completion/${
      typeof step_id === 'string' ? step_id : JSON.stringify(step_id ?? '')
    }`,
    {
      body: JSON.stringify({ key: 'value' }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
    }
  ).then(res => handleResponse(res, handlers));

export const useCompleteStepPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => completeStepPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Goal_list', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Goal_list');
        queryClient.invalidateQueries('Goal_lists');
      },
    }
  );
};

export const createAiGoalPOST = (
  Constants,
  { busID, date, prompt },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:dyI8_hXg/AIassistant/Create/Goal/${
      typeof busID === 'string' ? busID : JSON.stringify(busID ?? '')
    }`,
    {
      body: JSON.stringify({ prompt: prompt, date: date }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useCreateAiGoalPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => createAiGoalPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Goal_list', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Goal_list');
        queryClient.invalidateQueries('Goal_lists');
      },
    }
  );
};

export const FetchCreateAiGoalPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  busID,
  date,
  prompt,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateAiGoalPOST(
    { busID, date, prompt },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateAiGoal: refetch });
};

export const createBusNameAIPOST = (Constants, { userId }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:dyI8_hXg/AIassistant/createName`,
    {
      body: JSON.stringify({ userId: userId }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useCreateBusNameAIPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      createBusNameAIPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('AI_Assistant', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('AI_Assistant');
        queryClient.invalidateQueries('AI_Assistants');
      },
    }
  );
};

export const FetchCreateBusNameAIPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  userId,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateBusNameAIPOST(
    { userId },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateBusNameAI: refetch });
};

export const createProblemSolutionsPOST = (
  Constants,
  { userId },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:dyI8_hXg/AIassistant/problemSolution`,
    {
      body: JSON.stringify({ userId: userId }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useCreateProblemSolutionsPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      createProblemSolutionsPOST(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('AI_Assistant', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('AI_Assistant');
        queryClient.invalidateQueries('AI_Assistants');
      },
    }
  );
};

export const FetchCreateProblemSolutionsPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  userId,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateProblemSolutionsPOST(
    { userId },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchCreateProblemSolutions: refetch,
  });
};

export const deleteGoalDELETE = (Constants, { goal_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:dyI8_hXg/business_action_plans/delete/${
      typeof goal_id === 'string' ? goal_id : JSON.stringify(goal_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  ).then(res => handleResponse(res, handlers));

export const useDeleteGoalDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => deleteGoalDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Goal_list', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Goal_list');
        queryClient.invalidateQueries('Goal_lists');
      },
    }
  );
};

export const genenrateActionPlanPOST = (
  Constants,
  { bus_id, due_date, priority, request },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:dyI8_hXg/business_action_plans/generatelist/${
      typeof bus_id === 'string' ? bus_id : JSON.stringify(bus_id ?? '')
    }`,
    {
      body: JSON.stringify({
        request: request,
        due_date: due_date,
        priority: priority,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useGenenrateActionPlanPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      genenrateActionPlanPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Goal_list', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Goal_list');
        queryClient.invalidateQueries('Goal_lists');
      },
    }
  );
};

export const FetchGenenrateActionPlanPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  bus_id,
  due_date,
  priority,
  request,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useGenenrateActionPlanPOST(
    { bus_id, due_date, priority, request },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGenenrateActionPlan: refetch,
  });
};

export const getAllGoalsForBusinessGET = (
  Constants,
  { bus_id },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:dyI8_hXg/business_action_plans/get_all_bus/${
      typeof bus_id === 'string' ? bus_id : JSON.stringify(bus_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetAllGoalsForBusinessGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Goal_lists', args],
    () => getAllGoalsForBusinessGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetAllGoalsForBusinessGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  bus_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetAllGoalsForBusinessGET(
    { bus_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetAllGoalsForBusiness: refetch,
  });
};

export const getAllStepsGET = (Constants, { goal_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:dyI8_hXg/business_action_plans/steps/${
      typeof goal_id === 'string' ? goal_id : JSON.stringify(goal_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetAllStepsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Goal_lists', args],
    () => getAllStepsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetAllStepsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  goal_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetAllStepsGET(
    { goal_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetAllSteps: refetch });
};

export const getIndustryPOST = (Constants, { userID }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:dyI8_hXg/AIassistant/industry/${
      typeof userID === 'string' ? userID : JSON.stringify(userID ?? '')
    }`,
    {
      body: JSON.stringify({ userID: userID }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useGetIndustryPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => getIndustryPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('AI_Assistant', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('AI_Assistant');
        queryClient.invalidateQueries('AI_Assistants');
      },
    }
  );
};

export const FetchGetIndustryPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  userID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useGetIndustryPOST(
    { userID },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetIndustry: refetch });
};

export const getSpecificGoalGET = (Constants, { goal_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:dyI8_hXg/business_action_plans/specific/${
      typeof goal_id === 'string' ? goal_id : JSON.stringify(goal_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetSpecificGoalGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Goal_list', args],
    () => getSpecificGoalGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Goal_lists']),
    }
  );
};

export const FetchGetSpecificGoalGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  goal_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetSpecificGoalGET(
    { goal_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetSpecificGoal: refetch });
};

export const getTargetMarketPOST = (Constants, { userId }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:dyI8_hXg/AIassistant/targetmarket`,
    {
      body: JSON.stringify({ userId: userId }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useGetTargetMarketPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      getTargetMarketPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('AI_Assistant', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('AI_Assistant');
        queryClient.invalidateQueries('AI_Assistants');
      },
    }
  );
};

export const FetchGetTargetMarketPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  userId,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useGetTargetMarketPOST(
    { userId },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetTargetMarket: refetch });
};

export const incompleteStepPATCH = (Constants, { step_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:dyI8_hXg/business_action_plans/status_incomplete/${
      typeof step_id === 'string' ? step_id : JSON.stringify(step_id ?? '')
    }`,
    {
      body: JSON.stringify({ key: 'value' }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
    }
  ).then(res => handleResponse(res, handlers));

export const useIncompleteStepPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      incompleteStepPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Goal_list', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Goal_list');
        queryClient.invalidateQueries('Goal_lists');
      },
    }
  );
};

export const prefRevenuePOST = (
  Constants,
  { afterTaxNumber, userID },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:dyI8_hXg/AIassistant/pref_revenue`,
    {
      body: JSON.stringify({ afterTaxNumber: afterTaxNumber, userID: userID }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const usePrefRevenuePOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => prefRevenuePOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('AI_Assistant', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('AI_Assistant');
        queryClient.invalidateQueries('AI_Assistants');
      },
    }
  );
};

export const FetchPrefRevenuePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  afterTaxNumber,
  userID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = usePrefRevenuePOST(
    { afterTaxNumber, userID },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchPrefRevenue: refetch });
};

export const revenueNumbersGET = (Constants, { userID }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:dyI8_hXg/AIassistant/Get/revenuePref/${
      typeof userID === 'string' ? userID : JSON.stringify(userID ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useRevenueNumbersGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['AI_Assistant', args],
    () => revenueNumbersGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['AI_Assistants']),
    }
  );
};

export const FetchRevenueNumbersGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  userID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useRevenueNumbersGET(
    { userID },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchRevenueNumbers: refetch });
};

export const revenueNumbersPOST = (
  Constants,
  { preTaxNumber },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:dyI8_hXg/AIassistant/revenueNumbers`,
    {
      body: JSON.stringify({ preTaxNumber: preTaxNumber }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useRevenueNumbersPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      revenueNumbersPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('AI_Assistant', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('AI_Assistant');
        queryClient.invalidateQueries('AI_Assistants');
      },
    }
  );
};

export const FetchRevenueNumbersPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  preTaxNumber,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useRevenueNumbersPOST(
    { preTaxNumber },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchRevenueNumbers: refetch });
};
