import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { Button, Touchable, withTheme } from '@draftbit/ui';
import { useNavigation } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { Image, Text, View } from 'react-native';

const DesktopHeader13024Block = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const navigation = useNavigation();

  return (
    <View
      style={StyleSheet.applyWidth(
        StyleSheet.compose(
          GlobalStyles.ViewStyles(theme)['Akurie Header Desktop Header 9'],
          {
            borderBottomWidth: { minWidth: Breakpoints.Laptop, value: 0.5 },
            borderColor: {
              minWidth: Breakpoints.Laptop,
              value: theme.colors['Akurie green'],
            },
            height: { minWidth: Breakpoints.Laptop, value: 119.6 },
            justifyContent: { minWidth: Breakpoints.Laptop, value: 'center' },
            paddingLeft: { minWidth: Breakpoints.Laptop, value: '0%' },
            paddingRight: { minWidth: Breakpoints.Laptop, value: '0%' },
            width: { minWidth: Breakpoints.Laptop, value: dimensions.width },
          }
        ),
        dimensions.width
      )}
    >
      {/* View 4 */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
            flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
            height: { minWidth: Breakpoints.Laptop, value: 68.48 },
            justifyContent: {
              minWidth: Breakpoints.Laptop,
              value: 'space-between',
            },
            width: [
              { minWidth: Breakpoints.Laptop, value: '75%' },
              { minWidth: Breakpoints.Desktop, value: '70%' },
              { minWidth: Breakpoints.BigScreen, value: '60%' },
            ],
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              height: { minWidth: Breakpoints.Laptop, value: '100%' },
              width: { minWidth: Breakpoints.Laptop, value: '17%' },
            },
            dimensions.width
          )}
        >
          <Touchable
            onPress={() => {
              try {
                /* 'Open App Link' action requires configuration: select URL */
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              {
                height: { minWidth: Breakpoints.Laptop, value: '100%' },
                width: { minWidth: Breakpoints.Laptop, value: '100%' },
              },
              dimensions.width
            )}
          >
            <Image
              resizeMode={'contain'}
              source={Images.MainLogoUpdate1u2}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                  height: { minWidth: Breakpoints.Laptop, value: '100%' },
                  width: { minWidth: Breakpoints.Laptop, value: '100%' },
                }),
                dimensions.width
              )}
            />
          </Touchable>
        </View>
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
              flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
              height: { minWidth: Breakpoints.Laptop, value: '80%' },
              justifyContent: {
                minWidth: Breakpoints.Laptop,
                value: 'space-evenly',
              },
              marginRight: { minWidth: Breakpoints.Desktop, value: 0 },
              width: { minWidth: Breakpoints.Laptop, value: '55.9%' },
            },
            dimensions.width
          )}
        >
          <Touchable
            onPress={() => {
              try {
                Linking.openURL('https://akurie.com/home/');
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              {
                borderColor: {
                  minWidth: Breakpoints.Laptop,
                  value: theme.colors['Akurie green'],
                },
                height: { minWidth: Breakpoints.Laptop, value: '100%' },
              },
              dimensions.width
            )}
          >
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                  height: { minWidth: Breakpoints.Laptop, value: '100%' },
                  justifyContent: {
                    minWidth: Breakpoints.Laptop,
                    value: 'center',
                  },
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: {
                      minWidth: Breakpoints.Laptop,
                      value: theme.colors['Strong Inverse'],
                    },
                    fontFamily: {
                      minWidth: Breakpoints.Laptop,
                      value: 'Inter_500Medium',
                    },
                    fontSize: [
                      { minWidth: Breakpoints.Laptop, value: 13 },
                      { minWidth: Breakpoints.Desktop, value: 14 },
                    ],
                    paddingLeft: { minWidth: Breakpoints.Desktop, value: 10 },
                    paddingRight: { minWidth: Breakpoints.Desktop, value: 10 },
                    textAlign: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                  }),
                  dimensions.width
                )}
              >
                {'Home'}
              </Text>
            </View>
          </Touchable>
          {/* Touchable 3 */}
          <Touchable
            onPress={() => {
              try {
                Linking.openURL('https://akurie.com/featuresservices/');
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              { height: { minWidth: Breakpoints.Laptop, value: '100%' } },
              dimensions.width
            )}
          >
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                  height: { minWidth: Breakpoints.Laptop, value: '100%' },
                  justifyContent: {
                    minWidth: Breakpoints.Laptop,
                    value: 'center',
                  },
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: {
                      minWidth: Breakpoints.Laptop,
                      value: theme.colors['Strong Inverse'],
                    },
                    fontFamily: {
                      minWidth: Breakpoints.Laptop,
                      value: 'Inter_500Medium',
                    },
                    fontSize: [
                      { minWidth: Breakpoints.Laptop, value: 13 },
                      { minWidth: Breakpoints.Desktop, value: 14 },
                    ],
                    paddingLeft: { minWidth: Breakpoints.Desktop, value: 10 },
                    paddingRight: { minWidth: Breakpoints.Desktop, value: 10 },
                    textAlign: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                  }),
                  dimensions.width
                )}
              >
                {'Features'}
              </Text>
            </View>
          </Touchable>
          {/* Touchable 4 */}
          <Touchable
            onPress={() => {
              try {
                Linking.openURL('https://akurie.com/pricing/');
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              { height: { minWidth: Breakpoints.Laptop, value: '100%' } },
              dimensions.width
            )}
          >
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                  height: { minWidth: Breakpoints.Laptop, value: '100%' },
                  justifyContent: {
                    minWidth: Breakpoints.Laptop,
                    value: 'center',
                  },
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: {
                      minWidth: Breakpoints.Laptop,
                      value: theme.colors['Strong Inverse'],
                    },
                    fontFamily: {
                      minWidth: Breakpoints.Laptop,
                      value: 'Inter_500Medium',
                    },
                    fontSize: [
                      { minWidth: Breakpoints.Laptop, value: 13 },
                      { minWidth: Breakpoints.Desktop, value: 14 },
                    ],
                    paddingLeft: { minWidth: Breakpoints.Desktop, value: 10 },
                    paddingRight: { minWidth: Breakpoints.Desktop, value: 10 },
                    textAlign: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                  }),
                  dimensions.width
                )}
              >
                {'Pricing'}
              </Text>
            </View>
          </Touchable>
          {/* Touchable 5 */}
          <Touchable
            onPress={() => {
              try {
                Linking.openURL('https://akurie.com/contact-us/');
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              { height: { minWidth: Breakpoints.Laptop, value: '100%' } },
              dimensions.width
            )}
          >
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                  height: { minWidth: Breakpoints.Laptop, value: '100%' },
                  justifyContent: {
                    minWidth: Breakpoints.Laptop,
                    value: 'center',
                  },
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: {
                      minWidth: Breakpoints.Laptop,
                      value: theme.colors['Strong Inverse'],
                    },
                    fontFamily: {
                      minWidth: Breakpoints.Laptop,
                      value: 'Inter_500Medium',
                    },
                    fontSize: [
                      { minWidth: Breakpoints.Laptop, value: 13 },
                      { minWidth: Breakpoints.Desktop, value: 14 },
                    ],
                    paddingLeft: { minWidth: Breakpoints.Desktop, value: 10 },
                    paddingRight: { minWidth: Breakpoints.Desktop, value: 10 },
                    textAlign: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                  }),
                  dimensions.width
                )}
              >
                {'The Akurie App'}
              </Text>
            </View>
          </Touchable>
          {/* Touchable 2 */}
          <Touchable
            onPress={() => {
              try {
                Linking.openURL('https://akurie.com/networking-events/');
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              { height: { minWidth: Breakpoints.Laptop, value: '100%' } },
              dimensions.width
            )}
          >
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                  height: { minWidth: Breakpoints.Laptop, value: '100%' },
                  justifyContent: {
                    minWidth: Breakpoints.Laptop,
                    value: 'center',
                  },
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: {
                      minWidth: Breakpoints.Laptop,
                      value: theme.colors['Strong Inverse'],
                    },
                    fontFamily: {
                      minWidth: Breakpoints.Laptop,
                      value: 'Inter_500Medium',
                    },
                    fontSize: [
                      { minWidth: Breakpoints.Laptop, value: 13 },
                      { minWidth: Breakpoints.Desktop, value: 14 },
                    ],
                    paddingLeft: { minWidth: Breakpoints.Desktop, value: 10 },
                    paddingRight: { minWidth: Breakpoints.Desktop, value: 10 },
                    textAlign: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                  }),
                  dimensions.width
                )}
              >
                {'Our Network'}
              </Text>
            </View>
          </Touchable>
        </View>
        {/* View 3 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: [
                { minWidth: Breakpoints.BigScreen, value: 'center' },
                { minWidth: Breakpoints.Desktop, value: 'center' },
              ],
              flexDirection: [
                { minWidth: Breakpoints.BigScreen, value: 'row' },
                { minWidth: Breakpoints.Desktop, value: 'row' },
                { minWidth: Breakpoints.Laptop, value: 'row' },
              ],
              justifyContent: {
                minWidth: Breakpoints.Laptop,
                value: 'flex-end',
              },
              minHeight: { minWidth: Breakpoints.Laptop, value: 45 },
              width: { minWidth: Breakpoints.Laptop, value: '27.06%' },
            },
            dimensions.width
          )}
        >
          <Button
            onPress={() => {
              try {
                navigation.navigate('WhatWeDoSignUpScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
                backgroundColor: {
                  minWidth: Breakpoints.Laptop,
                  value: theme.colors['Akurie green'],
                },
                borderRadius: { minWidth: Breakpoints.Laptop, value: 9 },
                color: {
                  minWidth: Breakpoints.Laptop,
                  value: theme.colors['Akurie Dark Blue'],
                },
                fontFamily: [
                  { minWidth: Breakpoints.Laptop, value: 'Inter_400Regular' },
                  {
                    minWidth: Breakpoints.BigScreen,
                    value: 'Inter_400Regular',
                  },
                ],
                fontSize: { minWidth: Breakpoints.Laptop, value: 13 },
                marginRight: [
                  { minWidth: Breakpoints.Desktop, value: 5 },
                  { minWidth: Breakpoints.Laptop, value: 5 },
                ],
                width: { minWidth: Breakpoints.Laptop, value: '50%' },
              }),
              dimensions.width
            )}
            title={'Get Started'}
          />
          {/* Button 3 */}
          <Button
            onPress={() => {
              try {
                navigation.navigate('LoginScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
                backgroundColor: {
                  minWidth: Breakpoints.Laptop,
                  value: 'rgba(0, 0, 0, 0)',
                },
                borderColor: {
                  minWidth: Breakpoints.Laptop,
                  value: theme.colors['Strong Inverse'],
                },
                borderRadius: { minWidth: Breakpoints.Laptop, value: 9 },
                borderWidth: { minWidth: Breakpoints.Laptop, value: 0.5 },
                color: {
                  minWidth: Breakpoints.Laptop,
                  value: theme.colors['Strong Inverse'],
                },
                fontFamily: [
                  { minWidth: Breakpoints.Laptop, value: 'Inter_400Regular' },
                  {
                    minWidth: Breakpoints.BigScreen,
                    value: 'Inter_400Regular',
                  },
                ],
                fontSize: { minWidth: Breakpoints.Laptop, value: 13 },
                width: { minWidth: Breakpoints.Laptop, value: '40%' },
              }),
              dimensions.width
            )}
            title={'Log In'}
          />
        </View>
      </View>
    </View>
  );
};

export default withTheme(DesktopHeader13024Block);
