import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import encodeQueryParam from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const brandingCheckGET = (Constants, { bus_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:aoLOoCN-/website_create/branding/Check/${
      typeof bus_id === 'string' ? bus_id : JSON.stringify(bus_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useBrandingCheckGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['website', args],
    () => brandingCheckGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['websites']),
    }
  );
};

export const FetchBrandingCheckGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  bus_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useBrandingCheckGET(
    { bus_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchBrandingCheck: refetch });
};

export const createRequestPOST = (
  Constants,
  { business_id, request, request_Type },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:aoLOoCN-/akurie_web_app_services/createRequest/${
      typeof business_id === 'string'
        ? business_id
        : JSON.stringify(business_id ?? '')
    }`,
    {
      body: JSON.stringify({
        business_id: business_id,
        request: request,
        request_Type: request_Type,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useCreateRequestPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['website', args],
    () => createRequestPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['websites']),
    }
  );
};

export const FetchCreateRequestPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  business_id,
  request,
  request_Type,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useCreateRequestPOST(
    { business_id, request, request_Type },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateRequest: refetch });
};

export const createSiteProducts$ServicesPOST = (
  Constants,
  {
    business_data_id,
    service_or_product_1,
    service_or_product_2,
    service_or_product_3,
    service_or_product_4,
  },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:aoLOoCN-/website_create/service-products`,
    {
      body: JSON.stringify({
        business_data_id: business_data_id,
        service_or_product_1: service_or_product_1,
        service_or_product_2: service_or_product_2,
        service_or_product_3: service_or_product_3,
        service_or_product_4: service_or_product_4,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useCreateSiteProducts$ServicesPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      createSiteProducts$ServicesPOST(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('website', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('website');
        queryClient.invalidateQueries('websites');
      },
    }
  );
};

export const FetchCreateSiteProducts$ServicesPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  business_data_id,
  service_or_product_1,
  service_or_product_2,
  service_or_product_3,
  service_or_product_4,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateSiteProducts$ServicesPOST(
    {
      business_data_id,
      service_or_product_1,
      service_or_product_2,
      service_or_product_3,
      service_or_product_4,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchCreateSiteProducts$Services: refetch,
  });
};

export const createWebsiteStartPOST = (
  Constants,
  { business_ID, business_description, domainName },
  handlers = {}
) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:aoLOoCN-/website_create/start`, {
    body: JSON.stringify({
      business_ID: business_ID,
      business_description: business_description,
      domainName: domainName,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useCreateWebsiteStartPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      createWebsiteStartPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('website', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('website');
        queryClient.invalidateQueries('websites');
      },
    }
  );
};

export const FetchCreateWebsiteStartPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  business_ID,
  business_description,
  domainName,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateWebsiteStartPOST(
    { business_ID, business_description, domainName },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateWebsiteStart: refetch });
};

export const getAllBrandingGET = (Constants, { bus_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:aoLOoCN-/website_create/branding/GET/${
      typeof bus_id === 'string' ? bus_id : JSON.stringify(bus_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetAllBrandingGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['website', args],
    () => getAllBrandingGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['websites']),
    }
  );
};

export const FetchGetAllBrandingGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  bus_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetAllBrandingGET(
    { bus_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetAllBranding: refetch });
};

export const getApp$WebServiceUserGET = (
  Constants,
  { business_id },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:aoLOoCN-/akurie_web_app_services/${
      typeof business_id === 'string'
        ? business_id
        : JSON.stringify(business_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetApp$WebServiceUserGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['website', args],
    () => getApp$WebServiceUserGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['websites']),
    }
  );
};

export const FetchGetApp$WebServiceUserGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  business_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetApp$WebServiceUserGET(
    { business_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetApp$WebServiceUser: refetch,
  });
};

export const getBusinessLogoGET = (Constants, { bus_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:aoLOoCN-/website_create/branding/getlogo/${
      typeof bus_id === 'string' ? bus_id : JSON.stringify(bus_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetBusinessLogoGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['website', args],
    () => getBusinessLogoGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['websites']),
    }
  );
};

export const FetchGetBusinessLogoGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  bus_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetBusinessLogoGET(
    { bus_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetBusinessLogo: refetch });
};

export const getRequestGET = (
  Constants,
  { business_id, requestID },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:aoLOoCN-/akurie_web_app_services/detailedsiteRequest/${
      typeof requestID === 'string'
        ? requestID
        : JSON.stringify(requestID ?? '')
    }/${
      typeof business_id === 'string'
        ? business_id
        : JSON.stringify(business_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetRequestGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['website', args],
    () => getRequestGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['websites']),
    }
  );
};

export const FetchGetRequestGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  business_id,
  requestID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetRequestGET(
    { business_id, requestID },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetRequest: refetch });
};

export const getSiteRequestsGET = (Constants, { business_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:aoLOoCN-/akurie_web_app_services/siteRequests/${
      typeof business_id === 'string'
        ? business_id
        : JSON.stringify(business_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetSiteRequestsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['websites', args],
    () => getSiteRequestsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetSiteRequestsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  business_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetSiteRequestsGET(
    { business_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetSiteRequests: refetch });
};

export const initializeWebServicesPOST = (
  Constants,
  { bus_id },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:aoLOoCN-/website_create/initialize/${
      typeof bus_id === 'string' ? bus_id : JSON.stringify(bus_id ?? '')
    }`,
    {
      body: JSON.stringify({ key: 'value' }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useInitializeWebServicesPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      initializeWebServicesPOST(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('website', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('website');
        queryClient.invalidateQueries('websites');
      },
    }
  );
};

export const FetchInitializeWebServicesPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  bus_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useInitializeWebServicesPOST(
    { bus_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchInitializeWebServices: refetch,
  });
};

export const openToMarketingPOST = (
  Constants,
  { bus_id, business_response },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:aoLOoCN-/business_data_assist_response/${
      typeof bus_id === 'string' ? bus_id : JSON.stringify(bus_id ?? '')
    }`,
    {
      body: JSON.stringify({ business_response: business_response }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useOpenToMarketingPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      openToMarketingPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('marketing', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('marketing');
        queryClient.invalidateQueries('marketings');
      },
    }
  );
};

export const FetchOpenToMarketingPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  bus_id,
  business_response,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useOpenToMarketingPOST(
    { bus_id, business_response },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchOpenToMarketing: refetch });
};

export const publicContactInfoPOST = (
  Constants,
  { City, business_ID, business_email, phone_number, state },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:aoLOoCN-/website_create/contact_info`,
    {
      body: JSON.stringify({
        business_ID: business_ID,
        business_email: business_email,
        phone_number: phone_number,
        City: City,
        state: state,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const usePublicContactInfoPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      publicContactInfoPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('website', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('website');
        queryClient.invalidateQueries('websites');
      },
    }
  );
};

export const FetchPublicContactInfoPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  City,
  business_ID,
  business_email,
  phone_number,
  state,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = usePublicContactInfoPOST(
    { City, business_ID, business_email, phone_number, state },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchPublicContactInfo: refetch });
};

export const specificServiceGET = (
  Constants,
  { business_id, serviceID },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:aoLOoCN-/akurie_web_app_services/detailedService/${
      typeof serviceID === 'string'
        ? serviceID
        : JSON.stringify(serviceID ?? '')
    }/${
      typeof business_id === 'string'
        ? business_id
        : JSON.stringify(business_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useSpecificServiceGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['website', args],
    () => specificServiceGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['websites']),
    }
  );
};

export const FetchSpecificServiceGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  business_id,
  serviceID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useSpecificServiceGET(
    { business_id, serviceID },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchSpecificService: refetch });
};

export const webServicesGET = (Constants, { business_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:aoLOoCN-/akurie_web_app_services/services/${
      typeof business_id === 'string'
        ? business_id
        : JSON.stringify(business_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useWebServicesGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['websites', args],
    () => webServicesGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchWebServicesGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  business_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useWebServicesGET(
    { business_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchWebServices: refetch });
};

export const websiteBrandingPOST = (
  Constants,
  {
    Accent_color,
    Additional_Notes,
    Primary_color,
    Secondary_color,
    business_data_id,
    company_logo,
    file,
  },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:aoLOoCN-/website_create/branding`,
    {
      body: JSON.stringify({
        company_logo: company_logo,
        business_data_id: business_data_id,
        Primary_color: Primary_color,
        Secondary_color: Secondary_color,
        Accent_color: Accent_color,
        Additional_Notes: Additional_Notes,
        file: file,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useWebsiteBrandingPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      websiteBrandingPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('website', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('website');
        queryClient.invalidateQueries('websites');
      },
    }
  );
};

export const FetchWebsiteBrandingPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  Accent_color,
  Additional_Notes,
  Primary_color,
  Secondary_color,
  business_data_id,
  company_logo,
  file,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useWebsiteBrandingPOST(
    {
      Accent_color,
      Additional_Notes,
      Primary_color,
      Secondary_color,
      business_data_id,
      company_logo,
      file,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchWebsiteBranding: refetch });
};

export const websiteCreationDetailsPOST = (
  Constants,
  {
    Core_value_1,
    Core_value_2,
    Core_value_3,
    Website_name,
    business_ID,
    business_description,
    business_target_market,
    domainName,
    mission_statement,
    reason_business_started,
  },
  handlers = {}
) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:aoLOoCN-/website_create`, {
    body: JSON.stringify({
      business_ID: business_ID,
      reason_business_started: reason_business_started,
      business_description: business_description,
      mission_statement: mission_statement,
      business_target_market: business_target_market,
      Core_value_1: Core_value_1,
      Core_value_2: Core_value_2,
      Core_value_3: Core_value_3,
      Website_name: Website_name,
      domainName: domainName,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useWebsiteCreationDetailsPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      websiteCreationDetailsPOST(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('website', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('website');
        queryClient.invalidateQueries('websites');
      },
    }
  );
};

export const FetchWebsiteCreationDetailsPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  Core_value_1,
  Core_value_2,
  Core_value_3,
  Website_name,
  business_ID,
  business_description,
  business_target_market,
  domainName,
  mission_statement,
  reason_business_started,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useWebsiteCreationDetailsPOST(
    {
      Core_value_1,
      Core_value_2,
      Core_value_3,
      Website_name,
      business_ID,
      business_description,
      business_target_market,
      domainName,
      mission_statement,
      reason_business_started,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchWebsiteCreationDetails: refetch,
  });
};
