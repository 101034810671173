import React from 'react';
import { Alert } from 'react-native';
import * as RevenueCat from '../custom-files/RevenueCat';

const revCatLogin = async (setGlobalVariableValue, id) => {
  try {
    const { customerInfo, created } = await RevenueCat.Purchases.logIn(
      id.toString()
    );
    return { customerInfo, created };
  } catch (e) {
    Alert.alert('rev cat login error', e.message);
    // Error fetching customer info
    console.log(e?.message);
    return {};
  }

  // return false
};

export default revCatLogin;
