import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import encodeQueryParam from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const accountStatusCheckGET = (
  Constants,
  { user_business_id },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:FWK87G5W/Account_exist_check/${
      typeof user_business_id === 'string'
        ? user_business_id
        : JSON.stringify(user_business_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useAccountStatusCheckGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['account_info', args],
    () => accountStatusCheckGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['account_infos']),
    }
  );
};

export const FetchAccountStatusCheckGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  user_business_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useAccountStatusCheckGET(
    { user_business_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchAccountStatusCheck: refetch });
};

export const doesTokenExistGET = (Constants, { business_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:FWK87G5W/plaid_table_1_exst/${
      typeof business_id === 'string'
        ? business_id
        : JSON.stringify(business_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useDoesTokenExistGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['token_status', args],
    () => doesTokenExistGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['token_statuses']),
    }
  );
};

export const FetchDoesTokenExistGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  business_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useDoesTokenExistGET(
    { business_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchDoesTokenExist: refetch });
};

export const getAccountsGET = (Constants, { userbusiness_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:FWK87G5W/Get_Accounts/${
      typeof userbusiness_id === 'string'
        ? userbusiness_id
        : JSON.stringify(userbusiness_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetAccountsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['account_info', args],
    () => getAccountsGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['account_infos']),
    }
  );
};

export const FetchGetAccountsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  userbusiness_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetAccountsGET(
    { userbusiness_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetAccounts: refetch });
};

export const getBalanceGET = (Constants, { user_business_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:FWK87G5W/GET_ACCOUNTS_BAL/${
      typeof user_business_id === 'string'
        ? user_business_id
        : JSON.stringify(user_business_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetBalanceGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['get_balance', args],
    () => getBalanceGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['get_balances']),
    }
  );
};

export const FetchGetBalanceGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  user_business_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetBalanceGET(
    { user_business_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetBalance: refetch });
};

export const getLinkTokenGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:FWK87G5W/get_link_token`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useGetLinkTokenGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['plaidAuthAkurieGetLinkTokenGET', args],
    () => getLinkTokenGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['plaidAuthAkurieGetLinkTokenGETS']),
    }
  );
};

export const FetchGetLinkTokenGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetLinkTokenGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetLinkToken: refetch });
};

export const getStoredAccountInfoGET = (
  Constants,
  { businessID },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:FWK87G5W/plaid_user_account_ids/${
      typeof businessID === 'string'
        ? businessID
        : JSON.stringify(businessID ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetStoredAccountInfoGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['account_info', args],
    () => getStoredAccountInfoGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['account_infos']),
    }
  );
};

export const FetchGetStoredAccountInfoGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  businessID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetStoredAccountInfoGET(
    { businessID },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetStoredAccountInfo: refetch,
  });
};

export const monthlyTransactions$TotalsGET = (
  Constants,
  { User_business_ID },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:FWK87G5W/Get_Transactions_monthly_to_date/${
      typeof User_business_ID === 'string'
        ? User_business_ID
        : JSON.stringify(User_business_ID ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useMonthlyTransactions$TotalsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['account_info', args],
    () => monthlyTransactions$TotalsGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['account_infos']),
    }
  );
};

export const FetchMonthlyTransactions$TotalsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  User_business_ID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useMonthlyTransactions$TotalsGET(
    { User_business_ID },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchMonthlyTransactions$Totals: refetch,
  });
};

export const topFiveCategoriesSpendingGET = (
  Constants,
  { User_business_ID },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:FWK87G5W/Get_Transactions_monthly_to_date/top_categories/${
      typeof User_business_ID === 'string'
        ? User_business_ID
        : JSON.stringify(User_business_ID ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useTopFiveCategoriesSpendingGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['transactions', args],
    () => topFiveCategoriesSpendingGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchTopFiveCategoriesSpendingGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  User_business_ID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useTopFiveCategoriesSpendingGET(
    { User_business_ID },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchTopFiveCategoriesSpending: refetch,
  });
};
