export default {
  AdobeStock345059232: require('../assets/images/AdobeStock345059232.jpeg'),
  Asset9: require('../assets/images/Asset9.png'),
  Asset5: require('../assets/images/Asset5.png'),
  Asset7: require('../assets/images/Asset7.png'),
  Asset12: require('../assets/images/Asset12.png'),
  Asset13: require('../assets/images/Asset13.png'),
  ApplicationFrameHost73m1r9cydb: require('../assets/images/ApplicationFrameHost73m1r9cydb.png'),
  FirefoxPHKyvBthWe: require('../assets/images/FirefoxPHKyvBthWe.png'),
  UserPngIcon26: require('../assets/images/UserPngIcon26.jpg'),
  AdobeStock412821610: require('../assets/images/AdobeStock412821610.jpeg'),
  AdobeStock461741933Icon: require('../assets/images/AdobeStock461741933Icon.png'),
  AdobeStock606166034: require('../assets/images/AdobeStock606166034.jpeg'),
  Cc21ak2: require('../assets/images/Cc21ak2.png'),
  AdobeStock532784807: require('../assets/images/AdobeStock532784807.png'),
  AdobeStock590713507: require('../assets/images/AdobeStock590713507.png'),
  AdobeStock590711729: require('../assets/images/AdobeStock590711729.png'),
  Cc21ak: require('../assets/images/Cc21ak.png'),
  AdobeStock590711701: require('../assets/images/AdobeStock590711701.png'),
  Asset1: require('../assets/images/Asset1.png'),
  Asset2: require('../assets/images/Asset2.png'),
  Asset8: require('../assets/images/Asset8.png'),
  Asset6: require('../assets/images/Asset6.png'),
  Asset3: require('../assets/images/Asset3.png'),
  Asset4: require('../assets/images/Asset4.png'),
  Cc21ak32: require('../assets/images/Cc21ak3(2).png'),
  Asset30: require('../assets/images/Asset30.png'),
  AdobeStock30972880drk: require('../assets/images/AdobeStock30972880drk.jpg'),
  AdobeStock175086954: require('../assets/images/AdobeStock175086954.jpeg'),
  AkurieCircle: require('../assets/images/AkurieCircle.png'),
  QrcodeAkuriecom: require('../assets/images/QrcodeAkuriecom.png'),
  Asset60: require('../assets/images/Asset60.png'),
  Asset14: require('../assets/images/Asset14.png'),
  Asset900: require('../assets/images/Asset900.png'),
  Asset21: require('../assets/images/Asset21.png'),
  Asset26: require('../assets/images/Asset26.png'),
  Asset29: require('../assets/images/Asset29.png'),
  Asset23: require('../assets/images/Asset23.png'),
  Asset15: require('../assets/images/Asset15.png'),
  Asset16: require('../assets/images/Asset16.png'),
  AppIcon2: require('../assets/images/AppIcon2.png'),
  AdobeStock627348790: require('../assets/images/AdobeStock627348790.png'),
  Waves: require('../assets/images/Waves.png'),
  AssetKmsdabnfkan: require('../assets/images/AssetKmsdabnfkan.png'),
  MainLogoUpdate1u: require('../assets/images/MainLogoUpdate1u.png'),
  MainLogoUpdate2u: require('../assets/images/MainLogoUpdate2u.png'),
  MainLogoUpdate1u2: require('../assets/images/MainLogoUpdate1u2.png'),
  MainLogoUpdate1u2g: require('../assets/images/MainLogoUpdate1u2g.png'),
  Icon3: require('../assets/images/Icon3.png'),
  AdobeStock27362277: require('../assets/images/AdobeStock27362277.jpeg'),
  RingIcon: require('../assets/images/RingIcon.png'),
  Apple: require('../assets/images/Apple.png'),
  Google: require('../assets/images/Google.png'),
  AdobeStock605786136: require('../assets/images/AdobeStock605786136.jpeg'),
  ChromeG5Jc9kgkrN: require('../assets/images/ChromeG5Jc9kgkrN.jpg'),
  ChromeG5Jc9kgkrN2: require('../assets/images/ChromeG5Jc9kgkrN2.jpg'),
  MainLogoUpdate1u2Pa: require('../assets/images/MainLogoUpdate1u2Pa.png'),
  MainLogoUpdate1u2Pa2: require('../assets/images/MainLogoUpdate1u2Pa2.png'),
  MainLogoUpdate1u2Pa3: require('../assets/images/MainLogoUpdate1u2Pa3.png'),
  Icon2Update: require('../assets/images/Icon2Update.png'),
};
