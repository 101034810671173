import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { withTheme } from '@draftbit/ui';
import { Image, View } from 'react-native';

const MobileHeaderBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();

  return (
    <View
      style={StyleSheet.applyWidth(
        {
          alignItems: 'stretch',
          backgroundColor: theme.colors['Akurie Dark Blue'],
          borderBottomWidth: 2,
          borderColor: theme.colors['Akurie green'],
          flexDirection: 'row',
          height: '12%',
          justifyContent: 'space-between',
          paddingLeft: 30,
          paddingRight: 30,
          width: '100%',
          zIndex: 30,
        },
        dimensions.width
      )}
    >
      {/* View 3 */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: [
              { minWidth: Breakpoints.Mobile, value: 'flex-start' },
              { minWidth: Breakpoints.Tablet, value: 'flex-start' },
            ],
            flexDirection: 'column',
            justifyContent: 'center',
            width: '60%',
          },
          dimensions.width
        )}
      >
        <Image
          resizeMode={'contain'}
          source={Images.MainLogoUpdate1u2}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
              height: [
                { minWidth: Breakpoints.Desktop, value: 100 },
                { minWidth: Breakpoints.Mobile, value: 100 },
              ],
              width: [
                { minWidth: Breakpoints.Desktop, value: 180 },
                { minWidth: Breakpoints.BigScreen, value: 180 },
                { minWidth: Breakpoints.Mobile, value: '90%' },
                { minWidth: Breakpoints.Laptop, value: 180 },
                { minWidth: Breakpoints.Tablet, value: 180 },
              ],
            }),
            dimensions.width
          )}
        />
      </View>
    </View>
  );
};

export default withTheme(MobileHeaderBlock);
