import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AuthAkurieApi from '../apis/AuthAkurieApi.js';
import * as BusinessDataAkurieApi from '../apis/BusinessDataAkurieApi.js';
import * as Logs$SupportAkurieApi from '../apis/Logs$SupportAkurieApi.js';
import * as Web$AppServicesApi from '../apis/Web$AppServicesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  NumberInput,
  Picker,
  ScreenContainer,
  Spacer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  ImageBackground,
  Keyboard,
  StatusBar,
  Text,
  View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const WebsiteBuildInfo4Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [Business_Exist, setBusiness_Exist] = React.useState(true);
  const [General_error_msg, setGeneral_error_msg] = React.useState('');
  const [Param_error_msg, setParam_error_msg] = React.useState('');
  const [address2Value, setAddress2Value] = React.useState('');
  const [addressValue, setAddressValue] = React.useState('');
  const [businessAndSupportEmailValue, setBusinessAndSupportEmailValue] =
    React.useState('');
  const [cityValue, setCityValue] = React.useState('');
  const [contactStateValue, setContactStateValue] = React.useState('');
  const [error_msg, setError_msg] = React.useState('');
  const [numberInputValue, setNumberInputValue] = React.useState('');
  const [numberInputValue2, setNumberInputValue2] = React.useState('');
  const [numberInputValue3, setNumberInputValue3] = React.useState('');
  const [phoneNumberValue, setPhoneNumberValue] = React.useState('');
  const [pickerValue, setPickerValue] = React.useState('');
  const [stateValue, setStateValue] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const [zipCodeValue, setZipCodeValue] = React.useState('');
  const web$AppServicesPublicContactInfoPOST =
    Web$AppServicesApi.usePublicContactInfoPOST();
  const logs$SupportAkurieBasicActivityPOST =
    Logs$SupportAkurieApi.useBasicActivityPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const authResponse = (await AuthAkurieApi.meGET(Constants))?.json;
        const tokenExpire = authResponse?.message;
        if (tokenExpire) {
          navigation.navigate('SessionExpiredScreen');
        }
        if (tokenExpire) {
          return;
        }
        StatusBar.setBarStyle('dark-content');
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);
  const phoneNumbera25Ab8WxRef = React.useRef();
  const addressvxhXuJEyRef = React.useRef();
  const address2J4mL6QyuRef = React.useRef();
  const cityfwJO4bbiRef = React.useRef();

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors['Background'] },
        dimensions.width
      )}
    >
      {/* Nav Frame 12-27 */}
      <View
        style={StyleSheet.applyWidth(
          {
            borderBottomWidth: 2,
            borderColor: theme.colors['Akurie green'],
            height: 80,
            marginBottom: 15,
            marginTop: 45,
          },
          dimensions.width
        )}
      >
        <ImageBackground
          resizeMode={'cover'}
          source={Images.Asset13}
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              backgroundColor: theme.colors['Akurie Dark Blue'],
              flexDirection: 'row',
              height: '100%',
              justifyContent: 'flex-start',
            },
            dimensions.width
          )}
        >
          {/* Left Side Frame */}
          <View
            style={StyleSheet.applyWidth(
              { paddingLeft: 9, paddingRight: 9 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Icon Flex Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                {/* Icon  */}
                <Icon
                  color={theme.colors['Background']}
                  name={'Ionicons/arrow-back-outline'}
                  size={24}
                />
              </View>
            </Touchable>
          </View>
          {/* Middle Frame */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexGrow: 1,
                flexShrink: 0,
                justifyContent: 'center',
                marginRight: 30,
              },
              dimensions.width
            )}
          >
            {/* Text Frame  */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexGrow: 0,
                  flexShrink: 0,
                  paddingBottom: 12,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 12,
                },
                dimensions.width
              )}
            >
              {/* Text 12 18 Regular */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Akurie green'],
                    fontFamily: 'System',
                    fontSize: 22,
                    fontWeight: '400',
                    lineHeight: 18,
                    paddingTop: 8,
                  },
                  dimensions.width
                )}
              >
                {'Building Your Site'}
              </Text>
            </View>
          </View>
          {/* Left Side Frame */}
          <View
            style={StyleSheet.applyWidth(
              { paddingLeft: 9, paddingRight: 9 },
              dimensions.width
            )}
          />
        </ImageBackground>
      </View>

      <ImageBackground
        resizeMode={'cover'}
        source={Images.FirefoxPHKyvBthWe}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ImageBackgroundStyles(theme)['Image Background'],
            {
              backgroundColor: theme.colors['Background'],
              height: '100%',
              justifyContent: 'center',
            }
          ),
          dimensions.width
        )}
      >
        {/* steps */}
        <View
          style={StyleSheet.applyWidth(
            { marginTop: 10, minHeight: 20, width: '100%' },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                fontFamily: 'Inter_600SemiBold',
                textAlign: 'center',
              }),
              dimensions.width
            )}
          >
            {'Step 3 of 3'}
          </Text>
        </View>

        <KeyboardAwareScrollView
          contentContainerStyle={StyleSheet.applyWidth(
            {
              justifyContent: 'center',
              padding: 10,
              paddingLeft: 10,
              paddingRight: 10,
            },
            dimensions.width
          )}
          enableOnAndroid={false}
          extraHeight={400}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors['Akurie Dark Blue'],
                borderTopLeftRadius: 12,
                borderTopRightRadius: 12,
                paddingBottom: 10,
                paddingLeft: 40,
                paddingRight: 40,
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth({ marginTop: 16 }, dimensions.width)}
            >
              {/* Continue Your Profile */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Strong Inverse'],
                    fontFamily: 'Inter_400Regular',
                    fontSize: 22,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {'Customer Contact Info'}
              </Text>

              <Spacer bottom={4} left={8} right={8} top={4}>
                <BusinessDataAkurieApi.FetchGetBusinessGET
                  business_id={Constants['ak_businessID']}
                >
                  {({ loading, error, data, refetchGetBusiness }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <>
                        {/* Business Name */}
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors['Strong Inverse'],
                              fontFamily: 'Inter_600SemiBold',
                              paddingBottom: 5,
                              paddingTop: 10,
                              textAlign: 'center',
                            },
                            dimensions.width
                          )}
                        >
                          {fetchData?.user_business_name}
                          {' - ID: '}
                          {null}
                        </Text>
                      </>
                    );
                  }}
                </BusinessDataAkurieApi.FetchGetBusinessGET>
              </Spacer>
              <Spacer bottom={4} left={8} right={8} top={4} />
              {/* Sub Text */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Akurie Primary'],
                    fontFamily: 'Inter_300Light',
                    fontSize: 14,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {
                  'This contact information will be posted on your site for your customers to contact you.'
                }
              </Text>
            </View>
          </View>

          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors['Akurie Dark Blue'],
                borderBottomLeftRadius: 12,
                borderBottomRightRadius: 12,
                marginBottom: 90,
                paddingBottom: 20,
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 10,
              },
              dimensions.width
            )}
          >
            {/* Form 1 */}
            <>
              {!Business_Exist ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { opacity: 1, width: '90%' },
                    dimensions.width
                  )}
                >
                  {/* View 4 */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingBottom: 5, paddingTop: 5 },
                      dimensions.width
                    )}
                  >
                    {/* Business and Support Email */}
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Strong Inverse'],
                          fontFamily: 'Inter_600SemiBold',
                          paddingBottom: 5,
                          paddingTop: 10,
                        },
                        dimensions.width
                      )}
                    >
                      {'Business / Support Email'}
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            { color: theme.colors['Error'] }
                          ),
                          dimensions.width
                        )}
                      >
                        {'*'}
                      </Text>
                    </Text>
                    {/* Business and Support Email */}
                    <TextInput
                      autoCapitalize={'none'}
                      changeTextDelay={500}
                      keyboardType={'email-address'}
                      multiline={false}
                      onChangeText={newBusinessAndSupportEmailValue => {
                        try {
                          setBusinessAndSupportEmailValue(
                            newBusinessAndSupportEmailValue
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      onSubmitEditing={() => {
                        try {
                          phoneNumbera25Ab8WxRef.current.focus();
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'support@abc.com'}
                      placeholderTextColor={theme.colors['Light']}
                      returnKeyType={'next'}
                      spellcheck={true}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextInputStyles(theme)['Text Input'],
                        dimensions.width
                      )}
                      value={businessAndSupportEmailValue}
                    />
                  </View>
                  {/* View 5 */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingBottom: 5, paddingTop: 5 },
                      dimensions.width
                    )}
                  >
                    {/* Phone Number */}
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Strong Inverse'],
                          fontFamily: 'Inter_600SemiBold',
                          paddingBottom: 5,
                          paddingTop: 10,
                        },
                        dimensions.width
                      )}
                    >
                      {'Phone Number'}
                    </Text>
                    {/* Phone Number */}
                    <NumberInput
                      changeTextDelay={500}
                      enablesReturnKeyAutomatically={true}
                      onChangeText={newPhoneNumberValue => {
                        try {
                          setPhoneNumberValue(newPhoneNumberValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      onSubmitEditing={() => {
                        try {
                          addressvxhXuJEyRef.current.focus();
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'123.456.7890'}
                      placeholderTextColor={theme.colors['Light']}
                      ref={phoneNumbera25Ab8WxRef}
                      returnKeyType={'next'}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.NumberInputStyles(theme)['Number Input'],
                          { borderColor: null }
                        ),
                        dimensions.width
                      )}
                      value={phoneNumberValue}
                    />
                  </View>
                  {/* View 8 */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingBottom: 5, paddingTop: 5 },
                      dimensions.width
                    )}
                  >
                    {/* City */}
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Strong Inverse'],
                          fontFamily: 'Inter_600SemiBold',
                          paddingBottom: 5,
                          paddingTop: 10,
                        },
                        dimensions.width
                      )}
                    >
                      {'City'}
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            { color: theme.colors['Error'] }
                          ),
                          dimensions.width
                        )}
                      >
                        {'*'}
                      </Text>
                    </Text>
                    {/* City */}
                    <TextInput
                      autoCapitalize={'none'}
                      changeTextDelay={500}
                      multiline={false}
                      onChangeText={newCityValue => {
                        try {
                          setCityValue(newCityValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      onSubmitEditing={() => {
                        try {
                          Keyboard.dismiss();
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Houston'}
                      placeholderTextColor={theme.colors['Light']}
                      ref={cityfwJO4bbiRef}
                      returnKeyType={'next'}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextInputStyles(theme)['Text Input'],
                        dimensions.width
                      )}
                      value={cityValue}
                    />
                  </View>
                  {/* View 9 */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingBottom: 5, paddingTop: 5 },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Strong Inverse'],
                            fontFamily: 'Inter_600SemiBold',
                            paddingBottom: 5,
                            paddingTop: 10,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'State'}
                      {/* State */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Error'],
                            fontFamily: 'Inter_600SemiBold',
                            paddingBottom: 5,
                            paddingTop: 10,
                          },
                          dimensions.width
                        )}
                      >
                        {'*\n'}
                      </Text>
                    </Text>
                    {/* contactState */}
                    <Picker
                      autoDismissKeyboard={true}
                      dropDownBackgroundColor={theme.colors.background}
                      dropDownBorderColor={theme.colors.divider}
                      dropDownBorderRadius={8}
                      dropDownBorderWidth={1}
                      dropDownTextColor={theme.colors.strong}
                      iconSize={24}
                      leftIconMode={'inset'}
                      mode={'native'}
                      onValueChange={newContactStateValue => {
                        try {
                          setContactStateValue(newContactStateValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      options={Constants['ak_supportedStates']}
                      placeholder={'Select a State'}
                      placeholderTextColor={theme.colors['Light']}
                      selectedIconColor={theme.colors.strong}
                      selectedIconName={'Feather/check'}
                      selectedIconSize={20}
                      type={'solid'}
                      value={contactStateValue}
                    />
                  </View>

                  <View>
                    {/* Error Message */}
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Error'],
                          fontFamily: 'Inter_400Regular',
                          paddingTop: 10,
                        },
                        dimensions.width
                      )}
                    >
                      {Constants['ak_errorMessage']}
                    </Text>
                  </View>
                  {/* Continue Button */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        paddingBottom: 40,
                        paddingTop: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Button
                      onPress={() => {
                        const handler = async () => {
                          try {
                            const createResult = (
                              await web$AppServicesPublicContactInfoPOST.mutateAsync(
                                {
                                  City: cityValue,
                                  business_ID: Constants['ak_businessID'],
                                  business_email: businessAndSupportEmailValue,
                                  phone_number: phoneNumberValue,
                                  state: contactStateValue,
                                }
                              )
                            )?.json;
                            const msg_error = createResult?.message;
                            setGlobalVariableValue({
                              key: 'ak_errorMessage',
                              value: msg_error,
                            });
                            if (msg_error) {
                              return;
                            }
                            setGlobalVariableValue({
                              key: 'ak_errorMessage',
                              value: false,
                            });
                            (
                              await logs$SupportAkurieBasicActivityPOST.mutateAsync(
                                {
                                  buttonName: 'Web Form Complete 4',
                                  session_id: Constants['Session_ID'],
                                }
                              )
                            )?.json;
                            /* Navigate action skipped because My Akurie Web Services is hidden */
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            backgroundColor: theme.colors['Akurie green'],
                            borderRadius: 9,
                            color: theme.colors['Strong'],
                            fontFamily: 'Inter_500Medium',
                            fontSize: 16,
                            height: 50,
                            width: '100%',
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Continue'}
                    />
                  </View>
                </View>
              )}
            </>
          </View>
        </KeyboardAwareScrollView>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(WebsiteBuildInfo4Screen);
