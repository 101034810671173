import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AuthAkurieApi from '../apis/AuthAkurieApi.js';
import * as BusinessDataAkurieApi from '../apis/BusinessDataAkurieApi.js';
import * as Filings$DocsApi from '../apis/Filings$DocsApi.js';
import * as Logs$SupportAkurieApi from '../apis/Logs$SupportAkurieApi.js';
import * as StripeAkurieApi from '../apis/StripeAkurieApi.js';
import * as Web$AppServicesApi from '../apis/Web$AppServicesApi.js';
import ServiceBoxForProBlock from '../components/ServiceBoxForProBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as RevenueCat from '../custom-files/RevenueCat';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Circle,
  Icon,
  IconButton,
  Link,
  ScreenContainer,
  Surface,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { H5 } from '@expo/html-elements';
import { useIsFocused } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import * as Linking from 'expo-linking';
import {
  ActivityIndicator,
  Alert,
  FlatList,
  Image,
  ImageBackground,
  Platform,
  Text,
  View,
} from 'react-native';

const PaywallProCurrentMonth2Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [currentMOfferings, setCurrentMOfferings] = React.useState([]);
  const [currentOfferings, setCurrentOfferings] = React.useState([]);
  const [current_file_upload, setCurrent_file_upload] = React.useState('');
  const [f1BusinessNameInputValue, setF1BusinessNameInputValue] =
    React.useState('');
  const [f1BusinessStartDatePickerValue, setF1BusinessStartDatePickerValue] =
    React.useState(new Date());
  const [f1BusinessZipCodeInputValue, setF1BusinessZipCodeInputValue] =
    React.useState('');
  const [f1EntityTypePickerValue, setF1EntityTypePickerValue] =
    React.useState('');
  const [f1StateOfBusinessPickerValue, setF1StateOfBusinessPickerValue] =
    React.useState('');
  const [f1WebsiteInputValue, setF1WebsiteInputValue] = React.useState('');
  const [industrySelectionValue, setIndustrySelectionValue] =
    React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [planCompareView, setPlanCompareView] = React.useState(false);
  const [platformType, setPlatformType] = React.useState('');
  const purchaseItem = async item => {
    try {
      setIsLoading(true);
      const { customerInfo, productIdentifier: packageName } =
        await RevenueCat.Purchases.purchasePackage(item);

      return { packageName, customerInfo };
      // navigate
    } catch (e) {
      if (!e.userCancelled) {
        Alert.alert('Subscription Canceled!');
      }

      return { packageName: false, customerInfo: false };
    } finally {
      setIsLoading(false);
    }
    return {};
  };
  React.useEffect(() => {
    let offerings;

    const fetchOfferings = async () => {
      try {
        setIsLoading(true);
        offerings = await RevenueCat.Purchases.getOfferings();

        if (
          offerings.all['Assistant Pro Monthly'].availablePackages.length !== 0
        ) {
          setCurrentOfferings(
            offerings.all['Assistant Pro Monthly'].availablePackages
          );
        }
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    };

    fetchOfferings();
  }, []);
  const logs$SupportAkurieLoginLogPOST =
    Logs$SupportAkurieApi.useLoginLogPOST();
  const logs$SupportAkurieBasicActivityPOST =
    Logs$SupportAkurieApi.useBasicActivityPOST();
  const stripeAkurieCreateSessionPOST = StripeAkurieApi.useCreateSessionPOST();
  const businessDataAkurieCreateRegisteredBusinessPOST =
    BusinessDataAkurieApi.useCreateRegisteredBusinessPOST();
  const web$AppServicesInitializeWebServicesPOST =
    Web$AppServicesApi.useInitializeWebServicesPOST();
  const filings$DocsCreateBusinessFilingsPOST =
    Filings$DocsApi.useCreateBusinessFilingsPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (Platform.OS === 'ios') {
        setPlatformType(props.route?.params?.Apple ?? null);
      }
      if (Platform.OS === 'android') {
        setPlatformType('Google Play');
      }
      if (Platform.OS === 'web') {
        setPlatformType(props.route?.params?.Akurie ?? null);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        },
        dimensions.width
      )}
    >
      {/* Image Background 2 */}
      <ImageBackground
        resizeMode={'cover'}
        style={StyleSheet.applyWidth(
          {
            alignItems: { minWidth: Breakpoints.BigScreen, value: 'stretch' },
            backgroundColor: theme.colors['Akurie Dark Blue'],
            height: '100%',
            width: '100%',
          },
          dimensions.width
        )}
      >
        {/* paywall page */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: [
                { minWidth: Breakpoints.Mobile, value: 'stretch' },
                { minWidth: Breakpoints.Desktop, value: 'center' },
                { minWidth: Breakpoints.Laptop, value: 'center' },
              ],
              height: [
                { minWidth: Breakpoints.Tablet, value: '85%' },
                { minWidth: Breakpoints.Laptop, value: '100%' },
              ],
              justifyContent: [
                { minWidth: Breakpoints.Mobile, value: 'space-around' },
                { minWidth: Breakpoints.Desktop, value: 'flex-start' },
              ],
              marginTop: { minWidth: Breakpoints.BigScreen, value: 40 },
              paddingLeft: [
                { minWidth: Breakpoints.Desktop, value: '15%' },
                { minWidth: Breakpoints.Laptop, value: '10%' },
                { minWidth: Breakpoints.BigScreen, value: '20%' },
              ],
              paddingRight: [
                { minWidth: Breakpoints.Desktop, value: '15%' },
                { minWidth: Breakpoints.Laptop, value: '10%' },
                { minWidth: Breakpoints.BigScreen, value: '20%' },
              ],
              paddingTop: 10,
              width: '100%',
            },
            dimensions.width
          )}
        >
          {/* View 3 */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'flex-end',
                marginTop: 60,
                paddingLeft: 25,
                paddingRight: 25,
                width: [
                  { minWidth: Breakpoints.Mobile, value: '100%' },
                  { minWidth: Breakpoints.Laptop, value: '80%' },
                ],
              },
              dimensions.width
            )}
          ></View>
          {/* View 2 */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                marginBottom: 10,
                width: [
                  { minWidth: Breakpoints.Desktop, value: '80%' },
                  { minWidth: Breakpoints.Laptop, value: '80%' },
                ],
              },
              dimensions.width
            )}
          >
            {/* View 3 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  marginBottom: 10,
                },
                dimensions.width
              )}
            >
              <Image
                resizeMode={'contain'}
                source={Images.MainLogoUpdate1u2}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    height: 60,
                    marginLeft: 10,
                    marginRight: 10,
                    width: 220,
                  }),
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Akurie Primary'],
                    fontFamily: 'Inter_600SemiBold',
                    fontSize: 18,
                    letterSpacing: 8,
                  }),
                  dimensions.width
                )}
              >
                {'Assistant Pro'}
              </Text>
            </View>
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', marginTop: 30, width: '90%' },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', width: '100%' },
                  dimensions.width
                )}
              >
                <Surface
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.SurfaceStyles(theme)['Surface'],
                      {
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderRadius: 9,
                        marginBottom: 5,
                        width: { minWidth: Breakpoints.Tablet, value: '100%' },
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        marginBottom: 10,
                        padding: 10,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'contain'}
                      source={Images.Asset23}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            height: 70,
                            marginLeft: 5,
                            marginRight: 10,
                            width: 70,
                          }
                        ),
                        dimensions.width
                      )}
                    />
                    <View
                      style={StyleSheet.applyWidth(
                        { width: '75%' },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Akurie green'],
                              fontFamily: 'Inter_600SemiBold',
                              fontSize: 16,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Business Growth and Planning'}
                      </Text>
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Strong Inverse'],
                              fontFamily: 'Inter_300Light',
                              fontSize: 13,
                              marginTop: 5,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {
                          "We help your business grow, launch, and adapt to your customer's needs with automation and step-by-step guidance."
                        }
                      </Text>
                    </View>
                  </View>
                </Surface>
                {/* Surface 2 */}
                <Surface
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.SurfaceStyles(theme)['Surface'],
                      {
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderRadius: 9,
                        marginBottom: 5,
                        width: { minWidth: Breakpoints.Tablet, value: '100%' },
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        marginBottom: 10,
                        padding: 10,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'contain'}
                      source={Images.Asset60}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            height: 70,
                            marginLeft: 5,
                            marginRight: 10,
                            width: 70,
                          }
                        ),
                        dimensions.width
                      )}
                    />
                    <View
                      style={StyleSheet.applyWidth(
                        { width: '75%' },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Akurie green'],
                              fontFamily: 'Inter_600SemiBold',
                              fontSize: 16,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'My Website & Social Media'}
                      </Text>
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Strong Inverse'],
                              fontFamily: 'Inter_300Light',
                              fontSize: 13,
                              marginTop: 5,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {
                          'Get a free SEO-optimized website along with automated web and social media analytics with detailed insights.'
                        }
                      </Text>
                    </View>
                  </View>
                </Surface>
                {/* Surface 3 */}
                <Surface
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.SurfaceStyles(theme)['Surface'],
                      {
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderRadius: 9,
                        marginBottom: 5,
                        width: { minWidth: Breakpoints.Tablet, value: '100%' },
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {/* View 3 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        marginBottom: 10,
                        padding: 10,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'contain'}
                      source={Images.Asset29}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            height: 70,
                            marginLeft: 5,
                            marginRight: 10,
                            width: 70,
                          }
                        ),
                        dimensions.width
                      )}
                    />
                    <View
                      style={StyleSheet.applyWidth(
                        { width: '75%' },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Akurie green'],
                              fontFamily: 'Inter_600SemiBold',
                              fontSize: 16,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Professional Services'}
                      </Text>
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Strong Inverse'],
                              fontFamily: 'Inter_300Light',
                              fontSize: 13,
                              marginTop: 5,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {
                          'Upon request, we match your business with the best CPAs, Lawyers, and other specialists for your needs.'
                        }
                      </Text>
                    </View>
                  </View>
                </Surface>

                <Surface
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.SurfaceStyles(theme)['Surface'],
                      {
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderRadius: 9,
                        width: { minWidth: Breakpoints.Tablet, value: '100%' },
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {/* View 4 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        marginBottom: 10,
                        padding: 10,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'contain'}
                      source={Images.Asset14}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            height: 70,
                            marginLeft: 5,
                            marginRight: 10,
                            width: 70,
                          }
                        ),
                        dimensions.width
                      )}
                    />
                    <View
                      style={StyleSheet.applyWidth(
                        { width: '75%' },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Akurie green'],
                              fontFamily: 'Inter_600SemiBold',
                              fontSize: 16,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Compliance & Annual Filings'}
                      </Text>
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Strong Inverse'],
                              fontFamily: 'Inter_300Light',
                              fontSize: 13,
                              marginTop: 5,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {
                          'We manage state-required business forms such as annual reports and business formation / organization documents at no cost except state fees.'
                        }
                      </Text>
                    </View>
                  </View>
                </Surface>
              </View>
            </View>
          </View>

          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: 20,
                width: [
                  { minWidth: Breakpoints.Mobile, value: '100%' },
                  { minWidth: Breakpoints.Laptop, value: '80%' },
                ],
              },
              dimensions.width
            )}
          >
            {/* Web List */}
            <>
              {!(Platform.OS === 'web') ? null : (
                <FlatList
                  data={[1]}
                  keyExtractor={(webListData, index) =>
                    webListData?.id ?? webListData?.uuid ?? index.toString()
                  }
                  listKey={'mkSIUNxC'}
                  numColumns={1}
                  onEndReachedThreshold={0.5}
                  renderItem={({ item, index }) => {
                    const webListData = item;
                    return (
                      <>
                        {/* Touchable Web Button */}
                        <Touchable
                          onPress={() => {
                            try {
                              if (Platform.OS === 'web') {
                                navigation.navigate('PurchaseCompleteScreen');
                              } else {
                                navigation.navigate('UserDashboardScreen');
                              }
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              width: [
                                { minWidth: Breakpoints.Tablet, value: '100%' },
                                { minWidth: Breakpoints.Mobile, value: '100%' },
                              ],
                            },
                            dimensions.width
                          )}
                        >
                          <Surface
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.SurfaceStyles(theme)['Surface'],
                                {
                                  alignItems: 'center',
                                  backgroundColor:
                                    theme.colors['Akurie Primary'],
                                  borderRadius: 9,
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  marginBottom: 5,
                                  marginTop: 5,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  borderRadius: 15,
                                  height: 60,
                                  justifyContent: 'center',
                                  marginLeft: 20,
                                  paddingBottom: 10,
                                  paddingLeft: 20,
                                  paddingRight: 20,
                                  paddingTop: 10,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Title */}
                              <Text
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors['Strong_Inverse'],
                                    fontFamily: 'Inter_600SemiBold',
                                    fontSize: 16,
                                  },
                                  dimensions.width
                                )}
                              >
                                {'Start a 1-Month Free Trial'}
                              </Text>
                              {/* Title 2 */}
                              <Text
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors['Strong'],
                                    fontFamily: 'Inter_300Light',
                                    fontSize: 14,
                                  },
                                  dimensions.width
                                )}
                              >
                                {'$99.99/ month after*'}
                              </Text>
                            </View>
                            {/* Activity Indicator 2 */}
                            <>
                              {!isLoading ? null : (
                                <ActivityIndicator
                                  animating={true}
                                  color={theme.colors['Strong Inverse']}
                                  hidesWhenStopped={true}
                                  size={'small'}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ActivityIndicatorStyles(
                                        theme
                                      )['Activity Indicator'],
                                      { marginRight: 20 }
                                    ),
                                    dimensions.width
                                  )}
                                />
                              )}
                            </>
                          </Surface>
                        </Touchable>
                      </>
                    );
                  }}
                  showsHorizontalScrollIndicator={true}
                  showsVerticalScrollIndicator={true}
                  style={StyleSheet.applyWidth(
                    { width: '80%' },
                    dimensions.width
                  )}
                  contentContainerStyle={StyleSheet.applyWidth(
                    {
                      alignItems: [
                        { minWidth: Breakpoints.Laptop, value: 'center' },
                        { minWidth: Breakpoints.Mobile, value: 'stretch' },
                        { minWidth: Breakpoints.Tablet, value: 'center' },
                      ],
                    },
                    dimensions.width
                  )}
                />
              )}
            </>
            <>
              {Platform.OS === 'web' ? null : (
                <FlatList
                  contentContainerStyle={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                    },
                    dimensions.width
                  )}
                  data={currentOfferings}
                  keyExtractor={(listData, index) =>
                    listData?.id ?? listData?.uuid ?? index.toString()
                  }
                  listKey={'iMR81gWd'}
                  numColumns={1}
                  onEndReachedThreshold={0.5}
                  renderItem={({ item, index }) => {
                    const listData = item;
                    return (
                      <>
                        {/* Touchable iOS Button */}
                        <Touchable
                          onPress={() => {
                            const handler = async () => {
                              try {
                                const cusIn = await purchaseItem(listData);
                                if (cusIn?.customerInfo) {
                                  return;
                                }
                                navigation.navigate('UserDashboardScreen');
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              width: [
                                { minWidth: Breakpoints.Mobile, value: '100%' },
                                { minWidth: Breakpoints.Tablet, value: '70%' },
                              ],
                            },
                            dimensions.width
                          )}
                        >
                          {/* Surface 2 */}
                          <Surface
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.SurfaceStyles(theme)['Surface'],
                                {
                                  alignItems: 'center',
                                  backgroundColor:
                                    theme.colors['Akurie Primary'],
                                  borderRadius: 9,
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  marginBottom: 5,
                                  marginTop: 5,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  borderRadius: 15,
                                  height: 60,
                                  justifyContent: 'center',
                                  paddingBottom: 10,
                                  paddingLeft: 20,
                                  paddingRight: 20,
                                  paddingTop: 10,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Title */}
                              <Text
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors['Strong_Inverse'],
                                    fontFamily: 'Inter_600SemiBold',
                                    fontSize: 16,
                                  },
                                  dimensions.width
                                )}
                              >
                                {'Start a 1-Week Free Trial'}
                              </Text>
                              {/* Title 2 */}
                              <Text
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors['Strong'],
                                    fontFamily: 'Inter_300Light',
                                    fontSize: 14,
                                  },
                                  dimensions.width
                                )}
                              >
                                {'$34.99/ month after*'}
                              </Text>
                            </View>
                            {/* Activity Indicator 2 */}
                            <>
                              {!isLoading ? null : (
                                <ActivityIndicator
                                  animating={true}
                                  color={theme.colors['Strong Inverse']}
                                  hidesWhenStopped={true}
                                  size={'small'}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.ActivityIndicatorStyles(theme)[
                                      'Activity Indicator'
                                    ],
                                    dimensions.width
                                  )}
                                />
                              )}
                            </>
                          </Surface>
                        </Touchable>
                      </>
                    );
                  }}
                  showsHorizontalScrollIndicator={true}
                  showsVerticalScrollIndicator={true}
                />
              )}
            </>
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                { paddingLeft: 20, paddingRight: 20, width: '90%' },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Studily_Secondary_UI'],
                    fontFamily: 'Inter_400Regular',
                    fontSize: 13,
                    marginTop: 10,
                    textAlign: 'center',
                    textDecorationLine: 'none',
                  }),
                  dimensions.width
                )}
              >
                {
                  'After your 1-week trial ends, your subscription will renew automatically for '
                }
                {null}
                {
                  ' which will be charged monthly. You can manage or cancel your subscription in your '
                }
                {platformType}
                {
                  ' Account Settings, please note that subscriptions are non-refundable. '
                }
              </Text>
            </View>
          </View>
        </View>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(PaywallProCurrentMonth2Screen);
