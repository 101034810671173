import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import encodeQueryParam from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const addBusManagersPOST = (
  Constants,
  { addr1, addr2, city, full_name, state, user_id, zipCode },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/business_formation_requests/update/Managers/${
      typeof user_id === 'string' ? user_id : JSON.stringify(user_id ?? '')
    }`,
    {
      body: JSON.stringify({
        addr1: addr1,
        addr2: addr2,
        city: city,
        state: state,
        zipCode: zipCode,
        full_name: full_name,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useAddBusManagersPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      addBusManagersPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Business_Formation', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Business_Formation');
        queryClient.invalidateQueries('Business_Formations');
      },
    }
  );
};

export const FetchAddBusManagersPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  addr1,
  addr2,
  city,
  full_name,
  state,
  user_id,
  zipCode,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useAddBusManagersPOST(
    { addr1, addr2, city, full_name, state, user_id, zipCode },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchAddBusManagers: refetch });
};

export const addRegisteredAgentPOST = (
  Constants,
  {
    Address,
    Address_2,
    City,
    County,
    State,
    registered_agent_name,
    user_business_id,
    zip_code,
  },
  handlers = {}
) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/registered_agent`, {
    body: JSON.stringify({
      user_business_id: user_business_id,
      registered_agent_name: registered_agent_name,
      Address: Address,
      Address_2: Address_2,
      State: State,
      City: City,
      zip_code: zip_code,
      County: County,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useAddRegisteredAgentPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      addRegisteredAgentPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Registered_Agent', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Registered_Agent');
        queryClient.invalidateQueries('Registered_Agents');
      },
    }
  );
};

export const FetchAddRegisteredAgentPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  Address,
  Address_2,
  City,
  County,
  State,
  registered_agent_name,
  user_business_id,
  zip_code,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useAddRegisteredAgentPOST(
    {
      Address,
      Address_2,
      City,
      County,
      State,
      registered_agent_name,
      user_business_id,
      zip_code,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchAddRegisteredAgent: refetch });
};

export const addingOwnersPOST = (
  Constants,
  {
    Address,
    Address_2,
    Business__Other_Entity,
    City,
    Entity_Owner_Name,
    First_Name,
    Last_Name,
    Natural_Person,
    Ownership_Percentage,
    State,
    user_business_id,
    zip_code,
  },
  handlers = {}
) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/business_ownership`, {
    body: JSON.stringify({
      user_business_id: user_business_id,
      Natural_Person: Natural_Person,
      Business__Other_Entity: Business__Other_Entity,
      First_Name: First_Name,
      Last_Name: Last_Name,
      Ownership_Percentage: Ownership_Percentage,
      Address: Address,
      Address_2: Address_2,
      City: City,
      State: State,
      zip_code: zip_code,
      Entity_Owner_Name: Entity_Owner_Name,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useAddingOwnersPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => addingOwnersPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Business_Owner', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Business_Owner');
        queryClient.invalidateQueries('Business_Owners');
      },
    }
  );
};

export const FetchAddingOwnersPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  Address,
  Address_2,
  Business__Other_Entity,
  City,
  Entity_Owner_Name,
  First_Name,
  Last_Name,
  Natural_Person,
  Ownership_Percentage,
  State,
  user_business_id,
  zip_code,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useAddingOwnersPOST(
    {
      Address,
      Address_2,
      Business__Other_Entity,
      City,
      Entity_Owner_Name,
      First_Name,
      Last_Name,
      Natural_Person,
      Ownership_Percentage,
      State,
      user_business_id,
      zip_code,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchAddingOwners: refetch });
};

export const akurieServicesPrefUpdatePATCH = (
  Constants,
  { Open_to_Services, user_business_basic_data_id, user_id },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/business_data/update/services_pref`,
    {
      body: JSON.stringify({
        user_id: user_id,
        user_business_basic_data_id: user_business_basic_data_id,
        Open_to_Services: Open_to_Services,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
    }
  ).then(res => handleResponse(res, handlers));

export const useAkurieServicesPrefUpdatePATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      akurieServicesPrefUpdatePATCH(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Business_Data', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Business_Datum');
        queryClient.invalidateQueries('Business_Data');
      },
    }
  );
};

export const businessContactInfoGET = (Constants, { bus_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/business_data_contact_info/${
      typeof bus_id === 'string' ? bus_id : JSON.stringify(bus_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useBusinessContactInfoGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Business_Datum', args],
    () => businessContactInfoGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Business_Data']),
    }
  );
};

export const FetchBusinessContactInfoGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  bus_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useBusinessContactInfoGET(
    { bus_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchBusinessContactInfo: refetch,
  });
};

export const businessFormationPOST = (
  Constants,
  {
    addr1,
    addr2,
    business_purpose,
    city,
    entity_name,
    state,
    users_id,
    zipCode,
  },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/business_formation_requests/create`,
    {
      body: JSON.stringify({
        user_id: users_id,
        entity_name: entity_name,
        business_purpose: business_purpose,
        addr1: addr1,
        addr2: addr2,
        city: city,
        state: state,
        zipCode: zipCode,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useBusinessFormationPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      businessFormationPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Business_Formation', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Business_Formation');
        queryClient.invalidateQueries('Business_Formations');
      },
    }
  );
};

export const FetchBusinessFormationPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  addr1,
  addr2,
  business_purpose,
  city,
  entity_name,
  state,
  users_id,
  zipCode,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useBusinessFormationPOST(
    {
      addr1,
      addr2,
      business_purpose,
      city,
      entity_name,
      state,
      users_id,
      zipCode,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchBusinessFormation: refetch });
};

export const businessIdeaPOST = (
  Constants,
  { business_Idea, users_id },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/business_idea_info/init`,
    {
      body: JSON.stringify({
        users_id: users_id,
        business_Idea: business_Idea,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useBusinessIdeaPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => businessIdeaPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Business_Data', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Business_Datum');
        queryClient.invalidateQueries('Business_Data');
      },
    }
  );
};

export const FetchBusinessIdeaPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  business_Idea,
  users_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useBusinessIdeaPOST(
    { business_Idea, users_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchBusinessIdea: refetch });
};

export const businessLoansGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/business_loans`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useBusinessLoansGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Loans', args],
    () => businessLoansGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchBusinessLoansGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useBusinessLoansGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchBusinessLoans: refetch });
};

export const businessTableCheckFormationGET = (
  Constants,
  { bus_id },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/BusinessTableCheck/${
      typeof bus_id === 'string' ? bus_id : JSON.stringify(bus_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useBusinessTableCheckFormationGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Business_Datum', args],
    () => businessTableCheckFormationGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Business_Data']),
    }
  );
};

export const FetchBusinessTableCheckFormationGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  bus_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useBusinessTableCheckFormationGET(
    { bus_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchBusinessTableCheckFormation: refetch,
  });
};

export const contactInfoCheckGET = (Constants, { bus_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/business_data_contact_info/soft_check/${
      typeof bus_id === 'string' ? bus_id : JSON.stringify(bus_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useContactInfoCheckGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Business_Datum', args],
    () => contactInfoCheckGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Business_Data']),
    }
  );
};

export const FetchContactInfoCheckGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  bus_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useContactInfoCheckGET(
    { bus_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchContactInfoCheck: refetch });
};

export const createContactInfoPOST = (
  Constants,
  {
    Address,
    Address_2,
    City,
    County,
    business_ID,
    business_contact_person,
    business_email,
    business_zip_code,
    phone_number,
  },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/business_data_contact_info/create`,
    {
      body: JSON.stringify({
        business_ID: business_ID,
        business_contact_person: business_contact_person,
        business_email: business_email,
        phone_number: phone_number,
        Address: Address,
        Address_2: Address_2,
        City: City,
        business_zip_code: business_zip_code,
        County: County,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useCreateContactInfoPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      createContactInfoPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Business_Data', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Business_Datum');
        queryClient.invalidateQueries('Business_Data');
      },
    }
  );
};

export const FetchCreateContactInfoPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  Address,
  Address_2,
  City,
  County,
  business_ID,
  business_contact_person,
  business_email,
  business_zip_code,
  phone_number,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateContactInfoPOST(
    {
      Address,
      Address_2,
      City,
      County,
      business_ID,
      business_contact_person,
      business_email,
      business_zip_code,
      phone_number,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateContactInfo: refetch });
};

export const createIdeaBusinessPOST = (
  Constants,
  {
    Business_Name,
    Business_Status,
    Entity_Type,
    State,
    User_id,
    industry_type,
  },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/business_data/create/businessIdea`,
    {
      body: JSON.stringify({
        Business_Name: Business_Name,
        Entity_Type: Entity_Type,
        State: State,
        User_id: User_id,
        industry_type: industry_type,
        Business_Status: Business_Status,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useCreateIdeaBusinessPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      createIdeaBusinessPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Business_Data', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Business_Datum');
        queryClient.invalidateQueries('Business_Data');
      },
    }
  );
};

export const FetchCreateIdeaBusinessPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  Business_Name,
  Business_Status,
  Entity_Type,
  State,
  User_id,
  industry_type,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateIdeaBusinessPOST(
    {
      Business_Name,
      Business_Status,
      Entity_Type,
      State,
      User_id,
      industry_type,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateIdeaBusiness: refetch });
};

export const createNonRegisteredBusinessPOST = (
  Constants,
  { Business_Name, Business_Status, Entity_Type, State, User_id },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/business_data/create/nonreg`,
    {
      body: JSON.stringify({
        Business_Name: Business_Name,
        Entity_Type: Entity_Type,
        State: State,
        User_id: User_id,
        Business_Status: Business_Status,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useCreateNonRegisteredBusinessPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      createNonRegisteredBusinessPOST(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Business_Data', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Business_Datum');
        queryClient.invalidateQueries('Business_Data');
      },
    }
  );
};

export const FetchCreateNonRegisteredBusinessPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  Business_Name,
  Business_Status,
  Entity_Type,
  State,
  User_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateNonRegisteredBusinessPOST(
    { Business_Name, Business_Status, Entity_Type, State, User_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchCreateNonRegisteredBusiness: refetch,
  });
};

export const createPlanPOST = (
  Constants,
  {
    Are_there_any_gaps_is_experience_and_expertise_if_so_how_do_you_plan_to_fill_them,
    Describe_how_you_will_promote_and_sell_your_product_or_service,
    How_do_you_meet_their_requirements,
    How_do_you_plan_to_generate_income_and_monetize_your_product_or_service,
    How_do_you_plan_to_mitigate_those_risks_and_overcome_obstacles,
    How_does_having_a_business_make_you_feel,
    List_key_members_of_your_team_and_their_roles,
    Provide_an_overview_of_your_business_operations,
    What_advantages_make_your_offering_compelling,
    What_are__your_marketing_channels_tactics_and_strategies,
    What_are_some_milestones_timelines_or_goals_that_you_want_to_achieve,
    What_are_the_key_activities_resources_and_processes_required_to_deliver_your_product_or_service,
    What_are_the_potential_risks_and_challenges_that_could_affect_your_business,
    What_are_your_businesss_unique_features,
    What_are_your_primary_sources_of_revenue_for_your_business,
    What_are_your_projected_yearly_expenses,
    What_are_your_solutions,
    What_experience_and_expertise_does_your_team_have,
    What_is_your_projected_yearly_profit,
    What_is_your_projected_yearly_revenue,
    What_legal_or_regulatory_things_are_necessary,
    What_partnerships_or_collaborations_do_yo_have_that_will_help_reach_your_target_audience,
    What_problems_does_your_business_solve,
    What_sets_your_business_apart_from_competitors,
    Whats_your_target_audiences_characteristics,
    Whats_your_target_audiences_needs,
    When_something_gets_hard_whats_something_you_tell_yourself_to_keep_pushing_forward,
    Why_are_these_issues_important,
    user_business_basic_data_id,
  },
  handlers = {}
) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/business_plan/create`, {
    body: JSON.stringify({
      user_business_basic_data_id: user_business_basic_data_id,
      What_problems_does_your_business_solve:
        What_problems_does_your_business_solve,
      Why_are_these_issues_important: Why_are_these_issues_important,
      What_are_your_solutions: What_are_your_solutions,
      Whats_your_target_audiences_characteristics:
        Whats_your_target_audiences_characteristics,
      Whats_your_target_audiences_needs: Whats_your_target_audiences_needs,
      How_do_you_meet_their_requirements: How_do_you_meet_their_requirements,
      What_sets_your_business_apart_from_competitors:
        What_sets_your_business_apart_from_competitors,
      What_are_your_businesss_unique_features:
        What_are_your_businesss_unique_features,
      What_advantages_make_your_offering_compelling:
        What_advantages_make_your_offering_compelling,
      What_are_your_primary_sources_of_revenue_for_your_business:
        What_are_your_primary_sources_of_revenue_for_your_business,
      How_do_you_plan_to_generate_income_and_monetize_your_product_or_service:
        How_do_you_plan_to_generate_income_and_monetize_your_product_or_service,
      Describe_how_you_will_promote_and_sell_your_product_or_service:
        Describe_how_you_will_promote_and_sell_your_product_or_service,
      What_are__your_marketing_channels_tactics_and_strategies:
        What_are__your_marketing_channels_tactics_and_strategies,
      What_partnerships_or_collaborations_do_yo_have_that_will_help_reach_your_target_audience:
        What_partnerships_or_collaborations_do_yo_have_that_will_help_reach_your_target_audience,
      Provide_an_overview_of_your_business_operations:
        Provide_an_overview_of_your_business_operations,
      What_are_the_key_activities_resources_and_processes_required_to_deliver_your_product_or_service:
        What_are_the_key_activities_resources_and_processes_required_to_deliver_your_product_or_service,
      What_legal_or_regulatory_things_are_necessary:
        What_legal_or_regulatory_things_are_necessary,
      List_key_members_of_your_team_and_their_roles:
        List_key_members_of_your_team_and_their_roles,
      What_experience_and_expertise_does_your_team_have:
        What_experience_and_expertise_does_your_team_have,
      Are_there_any_gaps_is_experience_and_expertise_if_so_how_do_you_plan_to_fill_them:
        Are_there_any_gaps_is_experience_and_expertise_if_so_how_do_you_plan_to_fill_them,
      What_is_your_projected_yearly_revenue:
        What_is_your_projected_yearly_revenue,
      What_are_your_projected_yearly_expenses:
        What_are_your_projected_yearly_expenses,
      What_is_your_projected_yearly_profit:
        What_is_your_projected_yearly_profit,
      What_are_the_potential_risks_and_challenges_that_could_affect_your_business:
        What_are_the_potential_risks_and_challenges_that_could_affect_your_business,
      How_do_you_plan_to_mitigate_those_risks_and_overcome_obstacles:
        How_do_you_plan_to_mitigate_those_risks_and_overcome_obstacles,
      What_are_some_milestones_timelines_or_goals_that_you_want_to_achieve:
        What_are_some_milestones_timelines_or_goals_that_you_want_to_achieve,
      How_does_having_a_business_make_you_feel:
        How_does_having_a_business_make_you_feel,
      When_something_gets_hard_whats_something_you_tell_yourself_to_keep_pushing_forward:
        When_something_gets_hard_whats_something_you_tell_yourself_to_keep_pushing_forward,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useCreatePlanPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => createPlanPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Business_Plan', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Business_Plan');
        queryClient.invalidateQueries('Business_Plans');
      },
    }
  );
};

export const FetchCreatePlanPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  Are_there_any_gaps_is_experience_and_expertise_if_so_how_do_you_plan_to_fill_them,
  Describe_how_you_will_promote_and_sell_your_product_or_service,
  How_do_you_meet_their_requirements,
  How_do_you_plan_to_generate_income_and_monetize_your_product_or_service,
  How_do_you_plan_to_mitigate_those_risks_and_overcome_obstacles,
  How_does_having_a_business_make_you_feel,
  List_key_members_of_your_team_and_their_roles,
  Provide_an_overview_of_your_business_operations,
  What_advantages_make_your_offering_compelling,
  What_are__your_marketing_channels_tactics_and_strategies,
  What_are_some_milestones_timelines_or_goals_that_you_want_to_achieve,
  What_are_the_key_activities_resources_and_processes_required_to_deliver_your_product_or_service,
  What_are_the_potential_risks_and_challenges_that_could_affect_your_business,
  What_are_your_businesss_unique_features,
  What_are_your_primary_sources_of_revenue_for_your_business,
  What_are_your_projected_yearly_expenses,
  What_are_your_solutions,
  What_experience_and_expertise_does_your_team_have,
  What_is_your_projected_yearly_profit,
  What_is_your_projected_yearly_revenue,
  What_legal_or_regulatory_things_are_necessary,
  What_partnerships_or_collaborations_do_yo_have_that_will_help_reach_your_target_audience,
  What_problems_does_your_business_solve,
  What_sets_your_business_apart_from_competitors,
  Whats_your_target_audiences_characteristics,
  Whats_your_target_audiences_needs,
  When_something_gets_hard_whats_something_you_tell_yourself_to_keep_pushing_forward,
  Why_are_these_issues_important,
  user_business_basic_data_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreatePlanPOST(
    {
      Are_there_any_gaps_is_experience_and_expertise_if_so_how_do_you_plan_to_fill_them,
      Describe_how_you_will_promote_and_sell_your_product_or_service,
      How_do_you_meet_their_requirements,
      How_do_you_plan_to_generate_income_and_monetize_your_product_or_service,
      How_do_you_plan_to_mitigate_those_risks_and_overcome_obstacles,
      How_does_having_a_business_make_you_feel,
      List_key_members_of_your_team_and_their_roles,
      Provide_an_overview_of_your_business_operations,
      What_advantages_make_your_offering_compelling,
      What_are__your_marketing_channels_tactics_and_strategies,
      What_are_some_milestones_timelines_or_goals_that_you_want_to_achieve,
      What_are_the_key_activities_resources_and_processes_required_to_deliver_your_product_or_service,
      What_are_the_potential_risks_and_challenges_that_could_affect_your_business,
      What_are_your_businesss_unique_features,
      What_are_your_primary_sources_of_revenue_for_your_business,
      What_are_your_projected_yearly_expenses,
      What_are_your_solutions,
      What_experience_and_expertise_does_your_team_have,
      What_is_your_projected_yearly_profit,
      What_is_your_projected_yearly_revenue,
      What_legal_or_regulatory_things_are_necessary,
      What_partnerships_or_collaborations_do_yo_have_that_will_help_reach_your_target_audience,
      What_problems_does_your_business_solve,
      What_sets_your_business_apart_from_competitors,
      Whats_your_target_audiences_characteristics,
      Whats_your_target_audiences_needs,
      When_something_gets_hard_whats_something_you_tell_yourself_to_keep_pushing_forward,
      Why_are_these_issues_important,
      user_business_basic_data_id,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreatePlan: refetch });
};

export const createRegisteredBusinessPOST = (
  Constants,
  { Business_Name, Entity_Type, State, User_id, industry_type },
  handlers = {}
) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/business_data/create`, {
    body: JSON.stringify({
      Business_Name: Business_Name,
      Entity_Type: Entity_Type,
      State: State,
      User_id: User_id,
      industry_type: industry_type,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useCreateRegisteredBusinessPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      createRegisteredBusinessPOST(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Business_Data', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Business_Datum');
        queryClient.invalidateQueries('Business_Data');
      },
    }
  );
};

export const FetchCreateRegisteredBusinessPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  Business_Name,
  Entity_Type,
  State,
  User_id,
  industry_type,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateRegisteredBusinessPOST(
    { Business_Name, Entity_Type, State, User_id, industry_type },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchCreateRegisteredBusiness: refetch,
  });
};

export const deleteBusDataDELETE = (
  Constants,
  { business_id },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/business_data/${
      typeof business_id === 'string'
        ? business_id
        : JSON.stringify(business_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  ).then(res => handleResponse(res, handlers));

export const useDeleteBusDataDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteBusDataDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Business_Data', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Business_Datum');
        queryClient.invalidateQueries('Business_Data');
      },
    }
  );
};

export const deleteManagersPOST = (
  Constants,
  { manager_id, user_id },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/business_formation_requests/delete/Managers/${
      typeof user_id === 'string' ? user_id : JSON.stringify(user_id ?? '')
    }`,
    {
      body: JSON.stringify({ manager_id: manager_id }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useDeleteManagersPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteManagersPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Business_Formation', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Business_Formation');
        queryClient.invalidateQueries('Business_Formations');
      },
    }
  );
};

export const FetchDeleteManagersPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  manager_id,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useDeleteManagersPOST(
    { manager_id, user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchDeleteManagers: refetch });
};

export const deleteOwnerDELETE = (Constants, { owner_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/business_ownership/delete/${
      typeof owner_id === 'string' ? owner_id : JSON.stringify(owner_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  ).then(res => handleResponse(res, handlers));

export const useDeleteOwnerDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => deleteOwnerDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Business_Owner', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Business_Owner');
        queryClient.invalidateQueries('Business_Owners');
      },
    }
  );
};

export const editBusinessPlanPOST = (
  Constants,
  {
    Are_there_any_gaps_is_experience_and_expertise_if_so_how_do_you_plan_to_fill_them,
    Describe_how_you_will_promote_and_sell_your_product_or_service,
    How_do_you_meet_their_requirements,
    How_do_you_plan_to_generate_income_and_monetize_your_product_or_service,
    How_do_you_plan_to_mitigate_those_risks_and_overcome_obstacles,
    How_does_having_a_business_make_you_feel,
    List_key_members_of_your_team_and_their_roles,
    Provide_an_overview_of_your_business_operations,
    What_advantages_make_your_offering_compelling,
    What_are__your_marketing_channels_tactics_and_strategies,
    What_are_some_milestones_timelines_or_goals_that_you_want_to_achieve,
    What_are_the_key_activities_resources_and_processes_required_to_deliver_your_product_or_service,
    What_are_the_potential_risks_and_challenges_that_could_affect_your_business,
    What_are_your_businesss_unique_features,
    What_are_your_primary_sources_of_revenue_for_your_business,
    What_are_your_projected_yearly_expenses,
    What_are_your_solutions,
    What_experience_and_expertise_does_your_team_have,
    What_is_your_projected_yearly_profit,
    What_is_your_projected_yearly_revenue,
    What_legal_or_regulatory_things_are_necessary,
    What_partnerships_or_collaborations_do_yo_have_that_will_help_reach_your_target_audience,
    What_problems_does_your_business_solve,
    What_sets_your_business_apart_from_competitors,
    Whats_your_target_audiences_characteristics,
    Whats_your_target_audiences_needs,
    When_something_gets_hard_whats_something_you_tell_yourself_to_keep_pushing_forward,
    Why_are_these_issues_important,
    user_bus_id,
    user_business_basic_data_id,
  },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/business_plan/edit/${
      typeof user_bus_id === 'string'
        ? user_bus_id
        : JSON.stringify(user_bus_id ?? '')
    }`,
    {
      body: JSON.stringify({
        user_business_basic_data_id: user_business_basic_data_id,
        What_problems_does_your_business_solve:
          What_problems_does_your_business_solve,
        Why_are_these_issues_important: Why_are_these_issues_important,
        What_are_your_solutions: What_are_your_solutions,
        Whats_your_target_audiences_characteristics:
          Whats_your_target_audiences_characteristics,
        Whats_your_target_audiences_needs: Whats_your_target_audiences_needs,
        How_do_you_meet_their_requirements: How_do_you_meet_their_requirements,
        What_sets_your_business_apart_from_competitors:
          What_sets_your_business_apart_from_competitors,
        What_are_your_businesss_unique_features:
          What_are_your_businesss_unique_features,
        What_advantages_make_your_offering_compelling:
          What_advantages_make_your_offering_compelling,
        What_are_your_primary_sources_of_revenue_for_your_business:
          What_are_your_primary_sources_of_revenue_for_your_business,
        How_do_you_plan_to_generate_income_and_monetize_your_product_or_service:
          How_do_you_plan_to_generate_income_and_monetize_your_product_or_service,
        Describe_how_you_will_promote_and_sell_your_product_or_service:
          Describe_how_you_will_promote_and_sell_your_product_or_service,
        What_are__your_marketing_channels_tactics_and_strategies:
          What_are__your_marketing_channels_tactics_and_strategies,
        What_partnerships_or_collaborations_do_yo_have_that_will_help_reach_your_target_audience:
          What_partnerships_or_collaborations_do_yo_have_that_will_help_reach_your_target_audience,
        Provide_an_overview_of_your_business_operations:
          Provide_an_overview_of_your_business_operations,
        What_are_the_key_activities_resources_and_processes_required_to_deliver_your_product_or_service:
          What_are_the_key_activities_resources_and_processes_required_to_deliver_your_product_or_service,
        What_legal_or_regulatory_things_are_necessary:
          What_legal_or_regulatory_things_are_necessary,
        List_key_members_of_your_team_and_their_roles:
          List_key_members_of_your_team_and_their_roles,
        What_experience_and_expertise_does_your_team_have:
          What_experience_and_expertise_does_your_team_have,
        Are_there_any_gaps_is_experience_and_expertise_if_so_how_do_you_plan_to_fill_them:
          Are_there_any_gaps_is_experience_and_expertise_if_so_how_do_you_plan_to_fill_them,
        What_is_your_projected_yearly_revenue:
          What_is_your_projected_yearly_revenue,
        What_are_your_projected_yearly_expenses:
          What_are_your_projected_yearly_expenses,
        What_is_your_projected_yearly_profit:
          What_is_your_projected_yearly_profit,
        What_are_the_potential_risks_and_challenges_that_could_affect_your_business:
          What_are_the_potential_risks_and_challenges_that_could_affect_your_business,
        How_do_you_plan_to_mitigate_those_risks_and_overcome_obstacles:
          How_do_you_plan_to_mitigate_those_risks_and_overcome_obstacles,
        What_are_some_milestones_timelines_or_goals_that_you_want_to_achieve:
          What_are_some_milestones_timelines_or_goals_that_you_want_to_achieve,
        How_does_having_a_business_make_you_feel:
          How_does_having_a_business_make_you_feel,
        When_something_gets_hard_whats_something_you_tell_yourself_to_keep_pushing_forward:
          When_something_gets_hard_whats_something_you_tell_yourself_to_keep_pushing_forward,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useEditBusinessPlanPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      editBusinessPlanPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Business_Plan', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Business_Plan');
        queryClient.invalidateQueries('Business_Plans');
      },
    }
  );
};

export const FetchEditBusinessPlanPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  Are_there_any_gaps_is_experience_and_expertise_if_so_how_do_you_plan_to_fill_them,
  Describe_how_you_will_promote_and_sell_your_product_or_service,
  How_do_you_meet_their_requirements,
  How_do_you_plan_to_generate_income_and_monetize_your_product_or_service,
  How_do_you_plan_to_mitigate_those_risks_and_overcome_obstacles,
  How_does_having_a_business_make_you_feel,
  List_key_members_of_your_team_and_their_roles,
  Provide_an_overview_of_your_business_operations,
  What_advantages_make_your_offering_compelling,
  What_are__your_marketing_channels_tactics_and_strategies,
  What_are_some_milestones_timelines_or_goals_that_you_want_to_achieve,
  What_are_the_key_activities_resources_and_processes_required_to_deliver_your_product_or_service,
  What_are_the_potential_risks_and_challenges_that_could_affect_your_business,
  What_are_your_businesss_unique_features,
  What_are_your_primary_sources_of_revenue_for_your_business,
  What_are_your_projected_yearly_expenses,
  What_are_your_solutions,
  What_experience_and_expertise_does_your_team_have,
  What_is_your_projected_yearly_profit,
  What_is_your_projected_yearly_revenue,
  What_legal_or_regulatory_things_are_necessary,
  What_partnerships_or_collaborations_do_yo_have_that_will_help_reach_your_target_audience,
  What_problems_does_your_business_solve,
  What_sets_your_business_apart_from_competitors,
  Whats_your_target_audiences_characteristics,
  Whats_your_target_audiences_needs,
  When_something_gets_hard_whats_something_you_tell_yourself_to_keep_pushing_forward,
  Why_are_these_issues_important,
  user_bus_id,
  user_business_basic_data_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useEditBusinessPlanPOST(
    {
      Are_there_any_gaps_is_experience_and_expertise_if_so_how_do_you_plan_to_fill_them,
      Describe_how_you_will_promote_and_sell_your_product_or_service,
      How_do_you_meet_their_requirements,
      How_do_you_plan_to_generate_income_and_monetize_your_product_or_service,
      How_do_you_plan_to_mitigate_those_risks_and_overcome_obstacles,
      How_does_having_a_business_make_you_feel,
      List_key_members_of_your_team_and_their_roles,
      Provide_an_overview_of_your_business_operations,
      What_advantages_make_your_offering_compelling,
      What_are__your_marketing_channels_tactics_and_strategies,
      What_are_some_milestones_timelines_or_goals_that_you_want_to_achieve,
      What_are_the_key_activities_resources_and_processes_required_to_deliver_your_product_or_service,
      What_are_the_potential_risks_and_challenges_that_could_affect_your_business,
      What_are_your_businesss_unique_features,
      What_are_your_primary_sources_of_revenue_for_your_business,
      What_are_your_projected_yearly_expenses,
      What_are_your_solutions,
      What_experience_and_expertise_does_your_team_have,
      What_is_your_projected_yearly_profit,
      What_is_your_projected_yearly_revenue,
      What_legal_or_regulatory_things_are_necessary,
      What_partnerships_or_collaborations_do_yo_have_that_will_help_reach_your_target_audience,
      What_problems_does_your_business_solve,
      What_sets_your_business_apart_from_competitors,
      Whats_your_target_audiences_characteristics,
      Whats_your_target_audiences_needs,
      When_something_gets_hard_whats_something_you_tell_yourself_to_keep_pushing_forward,
      Why_are_these_issues_important,
      user_bus_id,
      user_business_basic_data_id,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchEditBusinessPlan: refetch });
};

export const editOwnerPUT = (
  Constants,
  {
    Address,
    Address_2,
    Business__Other_Entity,
    City,
    Entity_Owner_Name,
    First_Name,
    Last_Name,
    Natural_Person,
    Ownership_Percentage,
    State,
    owner_id,
    zip_code,
  },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/business_ownership/edit/single_owner/${
      typeof owner_id === 'string' ? owner_id : JSON.stringify(owner_id ?? '')
    }`,
    {
      body: JSON.stringify({
        owner_id: owner_id,
        Natural_Person: Natural_Person,
        Business__Other_Entity: Business__Other_Entity,
        Entity_Owner_Name: Entity_Owner_Name,
        First_Name: First_Name,
        Last_Name: Last_Name,
        Ownership_Percentage: Ownership_Percentage,
        Address: Address,
        Address_2: Address_2,
        City: City,
        State: State,
        zip_code: zip_code,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    }
  ).then(res => handleResponse(res, handlers));

export const useEditOwnerPUT = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => editOwnerPUT(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Business_Owner', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Business_Owner');
        queryClient.invalidateQueries('Business_Owners');
      },
    }
  );
};

export const editRegisteredAgentPATCH = (
  Constants,
  {
    Address,
    Address_2,
    City,
    County,
    State,
    business_id,
    registered_agent_name,
    zip_code,
  },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/registered_agent/edit/${
      typeof business_id === 'string'
        ? business_id
        : JSON.stringify(business_id ?? '')
    }`,
    {
      body: JSON.stringify({
        registered_agent_name: registered_agent_name,
        Address: Address,
        Address_2: Address_2,
        State: State,
        City: City,
        zip_code: zip_code,
        County: County,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
    }
  ).then(res => handleResponse(res, handlers));

export const useEditRegisteredAgentPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      editRegisteredAgentPATCH(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Registered_Agent', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Registered_Agent');
        queryClient.invalidateQueries('Registered_Agents');
      },
    }
  );
};

export const editUserBusinessPATCH = (
  Constants,
  {
    Address_1,
    Address_2,
    Business_Name,
    City,
    County,
    Entity_Type,
    Formation_Organization_Date,
    NAICS_Industry_Code,
    Number_of_employees,
    State,
    Website,
    Zip_code,
    business_id,
  },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/business_data/update/info/${
      typeof business_id === 'string'
        ? business_id
        : JSON.stringify(business_id ?? '')
    }`,
    {
      body: JSON.stringify({
        Business_Name: Business_Name,
        Entity_Type: Entity_Type,
        State: State,
        Zip_code: Zip_code,
        Website: Website,
        Formation_Organization_Date: Formation_Organization_Date,
        business_id: business_id,
        NAICS_Industry_Code: NAICS_Industry_Code,
        Address_1: Address_1,
        Address_2: Address_2,
        City: City,
        County: County,
        Number_of_employees: Number_of_employees,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
    }
  ).then(res => handleResponse(res, handlers));

export const useEditUserBusinessPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      editUserBusinessPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Business_Data', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Business_Datum');
        queryClient.invalidateQueries('Business_Data');
      },
    }
  );
};

export const formationConfirmationPOST = (
  Constants,
  { user_id, user_initials },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/business_formation/initial/confirm/${
      typeof user_id === 'string' ? user_id : JSON.stringify(user_id ?? '')
    }`,
    {
      body: JSON.stringify({ user_initials: user_initials }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useFormationConfirmationPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      formationConfirmationPOST(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Business_Formation', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Business_Formation');
        queryClient.invalidateQueries('Business_Formations');
      },
    }
  );
};

export const FetchFormationConfirmationPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  user_id,
  user_initials,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useFormationConfirmationPOST(
    { user_id, user_initials },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchFormationConfirmation: refetch,
  });
};

export const getAllBusinessesGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/business_data/get_all`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetAllBusinessesGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Business_Data', args],
    () => getAllBusinessesGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetAllBusinessesGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetAllBusinessesGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetAllBusinesses: refetch });
};

export const getAllUsersBusinessesGET = (
  Constants,
  { user_id },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/business_data/get/multiple/${
      typeof user_id === 'string' ? user_id : JSON.stringify(user_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetAllUsersBusinessesGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Business_Data', args],
    () => getAllUsersBusinessesGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetAllUsersBusinessesGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetAllUsersBusinessesGET(
    { user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetAllUsersBusinesses: refetch,
  });
};

export const getBusinessGET = (Constants, { business_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/business_data/get/${
      typeof business_id === 'string'
        ? business_id
        : JSON.stringify(business_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetBusinessGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Business_Datum', args],
    () => getBusinessGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Business_Data']),
    }
  );
};

export const FetchGetBusinessGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  business_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetBusinessGET(
    { business_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetBusiness: refetch });
};

export const getBusinessByName$UserGET = (
  Constants,
  { user_business_name, user_id },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/business_data/locate/by_userid?user_id=${encodeQueryParam(
      `${typeof user_id === 'string' ? user_id : JSON.stringify(user_id ?? '')}`
    )}&user_business_name=${encodeQueryParam(
      `${
        typeof user_business_name === 'string'
          ? user_business_name
          : JSON.stringify(user_business_name ?? '')
      }`
    )}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetBusinessByName$UserGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Business_Datum', args],
    () => getBusinessByName$UserGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Business_Data']),
    }
  );
};

export const FetchGetBusinessByName$UserGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  user_business_name,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetBusinessByName$UserGET(
    { user_business_name, user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetBusinessByName$User: refetch,
  });
};

export const getBusinessGrantsGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/business_grants`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useGetBusinessGrantsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['grants', args],
    () => getBusinessGrantsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetBusinessGrantsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetBusinessGrantsGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetBusinessGrants: refetch });
};

export const getBusinessLogoGET = (Constants, { business_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/Business_logo/get/${
      typeof business_id === 'string'
        ? business_id
        : JSON.stringify(business_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetBusinessLogoGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Business_Datum', args],
    () => getBusinessLogoGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Business_Data']),
    }
  );
};

export const FetchGetBusinessLogoGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  business_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetBusinessLogoGET(
    { business_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetBusinessLogo: refetch });
};

export const getBusinessOnlyByUserIDGET = (
  Constants,
  { by_userid },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/business_data/locate/only/${
      typeof by_userid === 'string'
        ? by_userid
        : JSON.stringify(by_userid ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetBusinessOnlyByUserIDGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Business_Datum', args],
    () => getBusinessOnlyByUserIDGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Business_Data']),
    }
  );
};

export const FetchGetBusinessOnlyByUserIDGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  by_userid,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetBusinessOnlyByUserIDGET(
    { by_userid },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetBusinessOnlyByUserID: refetch,
  });
};

export const getBusinessOwnerGET = (Constants, { owner_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/business_ownership/get/single_owner/${
      typeof owner_id === 'string' ? owner_id : JSON.stringify(owner_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetBusinessOwnerGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Business_Owner', args],
    () => getBusinessOwnerGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Business_Owners']),
    }
  );
};

export const FetchGetBusinessOwnerGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  owner_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetBusinessOwnerGET(
    { owner_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetBusinessOwner: refetch });
};

export const getBusinessPlanGET = (Constants, { user_bus_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/business_plan/get/${
      typeof user_bus_id === 'string'
        ? user_bus_id
        : JSON.stringify(user_bus_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetBusinessPlanGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Business_Plan', args],
    () => getBusinessPlanGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Business_Plans']),
    }
  );
};

export const FetchGetBusinessPlanGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  user_bus_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetBusinessPlanGET(
    { user_bus_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetBusinessPlan: refetch });
};

export const getBusinessPurposeGET = (Constants, { userId }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/business_idea_info/get/business_purpose/${
      typeof userId === 'string' ? userId : JSON.stringify(userId ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetBusinessPurposeGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Business_Datum', args],
    () => getBusinessPurposeGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Business_Data']),
    }
  );
};

export const FetchGetBusinessPurposeGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  userId,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetBusinessPurposeGET(
    { userId },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetBusinessPurpose: refetch });
};

export const getIdeaInfoGET = (Constants, { user_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/business_idea_info/${
      typeof user_id === 'string' ? user_id : JSON.stringify(user_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetIdeaInfoGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Business_Datum', args],
    () => getIdeaInfoGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Business_Data']),
    }
  );
};

export const FetchGetIdeaInfoGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetIdeaInfoGET(
    { user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetIdeaInfo: refetch });
};

export const getOwnersGET = (Constants, { business_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/business_ownership/get/owner/${
      typeof business_id === 'string'
        ? business_id
        : JSON.stringify(business_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetOwnersGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Business_Owners', args],
    () => getOwnersGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetOwnersGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  business_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetOwnersGET(
    { business_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetOwners: refetch });
};

export const getRegisteredAgentGET = (
  Constants,
  { business_id },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/registered_agent/get/${
      typeof business_id === 'string'
        ? business_id
        : JSON.stringify(business_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetRegisteredAgentGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Registered_Agent', args],
    () => getRegisteredAgentGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Registered_Agents']),
    }
  );
};

export const FetchGetRegisteredAgentGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  business_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetRegisteredAgentGET(
    { business_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetRegisteredAgent: refetch });
};

export const managerCheckGET = (Constants, { user_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/business_formation_requests/${
      typeof user_id === 'string' ? user_id : JSON.stringify(user_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useManagerCheckGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Business_Formation', args],
    () => managerCheckGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Business_Formations']),
    }
  );
};

export const FetchManagerCheckGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useManagerCheckGET(
    { user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchManagerCheck: refetch });
};

export const marketSelectPOST = (
  Constants,
  { marketID, userID },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/BUSINESS_IDEA_INFO/targetMarket/update/select`,
    {
      body: JSON.stringify({ userID: userID, marketID: marketID }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useMarketSelectPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => marketSelectPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Business_Data', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Business_Datum');
        queryClient.invalidateQueries('Business_Data');
      },
    }
  );
};

export const FetchMarketSelectPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  marketID,
  userID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useMarketSelectPOST(
    { marketID, userID },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchMarketSelect: refetch });
};

export const marketUnselectPOST = (
  Constants,
  { marketID, userID },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/BUSINESS_IDEA_INFO/targetMarket/update/unselect`,
    {
      body: JSON.stringify({ userID: userID, marketID: marketID }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useMarketUnselectPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      marketUnselectPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Business_Data', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Business_Datum');
        queryClient.invalidateQueries('Business_Data');
      },
    }
  );
};

export const FetchMarketUnselectPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  marketID,
  userID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useMarketUnselectPOST(
    { marketID, userID },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchMarketUnselect: refetch });
};

export const name$ProblemUpdatePOST = (
  Constants,
  {
    Industry,
    Services_Products,
    business_Idea,
    business_Name,
    problem_Solving,
    target_MarketGroups,
    users_id,
  },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/business_idea_info/update`,
    {
      body: JSON.stringify({
        users_id: users_id,
        business_Idea: business_Idea,
        business_Name: business_Name,
        problem_Solving: problem_Solving,
        target_MarketGroups: target_MarketGroups,
        Services_Products: Services_Products,
        Industry: Industry,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useName$ProblemUpdatePOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      name$ProblemUpdatePOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Business_Data', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Business_Datum');
        queryClient.invalidateQueries('Business_Data');
      },
    }
  );
};

export const FetchName$ProblemUpdatePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  Industry,
  Services_Products,
  business_Idea,
  business_Name,
  problem_Solving,
  target_MarketGroups,
  users_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useName$ProblemUpdatePOST(
    {
      Industry,
      Services_Products,
      business_Idea,
      business_Name,
      problem_Solving,
      target_MarketGroups,
      users_id,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchName$ProblemUpdate: refetch });
};

export const problemSelectPOST = (
  Constants,
  { problemID, userID },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/BUSINESS_IDEA_INFO/problem/update/select`,
    {
      body: JSON.stringify({ userID: userID, problemID: problemID }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useProblemSelectPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => problemSelectPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Business_Data', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Business_Datum');
        queryClient.invalidateQueries('Business_Data');
      },
    }
  );
};

export const FetchProblemSelectPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  problemID,
  userID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useProblemSelectPOST(
    { problemID, userID },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchProblemSelect: refetch });
};

export const professionalServiceRequestPOST = (
  Constants,
  { Description, Service_Type, business_data_id, users_id },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/professional_service_requests/create`,
    {
      body: JSON.stringify({
        Service_Type: Service_Type,
        Description: Description,
        users_id: users_id,
        business_data_id: business_data_id,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useProfessionalServiceRequestPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      professionalServiceRequestPOST(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData(
            'Professional Request',
            previousValue
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Professional Request');
        queryClient.invalidateQueries('Professional Requests');
      },
    }
  );
};

export const FetchProfessionalServiceRequestPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  Description,
  Service_Type,
  business_data_id,
  users_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useProfessionalServiceRequestPOST(
    { Description, Service_Type, business_data_id, users_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchProfessionalServiceRequest: refetch,
  });
};

export const registeringBusinessUpdatePATCH = (
  Constants,
  {
    Business_Status,
    business_id,
    business_location_zip_code,
    entity_type,
    formation_date,
    state_formed_in,
  },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/business_data/update/reg_status/${
      typeof business_id === 'string'
        ? business_id
        : JSON.stringify(business_id ?? '')
    }`,
    {
      body: JSON.stringify({
        business_id: business_id,
        Business_Status: Business_Status,
        entity_type: entity_type,
        state_formed_in: state_formed_in,
        formation_date: formation_date,
        business_location_zip_code: business_location_zip_code,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
    }
  ).then(res => handleResponse(res, handlers));

export const useRegisteringBusinessUpdatePATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      registeringBusinessUpdatePATCH(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Business_Data', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Business_Datum');
        queryClient.invalidateQueries('Business_Data');
      },
    }
  );
};

export const setManagementTypePOST = (
  Constants,
  { managementType, user_id },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/business_formation_requests/update/Management_Type/${
      typeof user_id === 'string' ? user_id : JSON.stringify(user_id ?? '')
    }`,
    {
      body: JSON.stringify({ managementType: managementType }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useSetManagementTypePOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      setManagementTypePOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Business_Formation', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Business_Formation');
        queryClient.invalidateQueries('Business_Formations');
      },
    }
  );
};

export const FetchSetManagementTypePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  managementType,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useSetManagementTypePOST(
    { managementType, user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchSetManagementType: refetch });
};

export const unselectProblemPOST = (
  Constants,
  { problemID, userID },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/BUSINESS_IDEA_INFO/problem/update/unselect`,
    {
      body: JSON.stringify({ userID: userID, problemID: problemID }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useUnselectProblemPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      unselectProblemPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Business_Data', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Business_Datum');
        queryClient.invalidateQueries('Business_Data');
      },
    }
  );
};

export const FetchUnselectProblemPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  problemID,
  userID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useUnselectProblemPOST(
    { problemID, userID },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchUnselectProblem: refetch });
};

export const updateBusinessLogoPATCH = (
  Constants,
  { business_id, image_Logo_file },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:_n7c9k6U/Business_logo/update/${
      typeof business_id === 'string'
        ? business_id
        : JSON.stringify(business_id ?? '')
    }`,
    {
      body: JSON.stringify({ image_Logo_file: image_Logo_file }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
    }
  ).then(res => handleResponse(res, handlers));

export const useUpdateBusinessLogoPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      updateBusinessLogoPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Business_Data', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Business_Datum');
        queryClient.invalidateQueries('Business_Data');
      },
    }
  );
};
