import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AuthAkurieApi from '../apis/AuthAkurieApi.js';
import * as BusinessDataAkurieApi from '../apis/BusinessDataAkurieApi.js';
import * as Networking$EventsAkurieApi from '../apis/Networking$EventsAkurieApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  Link,
  ScreenContainer,
  Surface,
  Touchable,
  WebView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  StatusBar,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const NetworkingIDPassScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [Manage_bus_view, setManage_bus_view] = React.useState(false);
  const [changebus_view, setChangebus_view] = React.useState(false);
  const [goal_view, setGoal_view] = React.useState(false);
  const [loading_goals, setLoading_goals] = React.useState(false);
  const [user_bus, setUser_bus] = React.useState('');
  const [user_first_name, setUser_first_name] = React.useState('');
  const [user_last_name, setUser_last_name] = React.useState('');
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      console.log('Screen ON_SCREEN_FOCUS Start');
      let error = null;
      try {
        if (!isFocused) {
          return;
        }
        console.log('Start ON_SCREEN_FOCUS:4 FETCH_REQUEST');
        const userResponse = (
          await AuthAkurieApi.getSpecificUserGET(Constants, {
            user_id: Constants['ak_userID'],
          })
        )?.json;
        console.log('Complete ON_SCREEN_FOCUS:4 FETCH_REQUEST', {
          userResponse,
        });
        console.log('Start ON_SCREEN_FOCUS:5 FETCH_REQUEST');
        const businessResponse = (
          await BusinessDataAkurieApi.getBusinessGET(Constants, {
            business_id: Constants['ak_businessID'],
          })
        )?.json;
        console.log('Complete ON_SCREEN_FOCUS:5 FETCH_REQUEST', {
          businessResponse,
        });
        console.log('Start ON_SCREEN_FOCUS:6 EXTRACT_KEY');
        const Business_Status = businessResponse?.Business_Status;
        console.log('Complete ON_SCREEN_FOCUS:6 EXTRACT_KEY', {
          Business_Status,
        });
        console.log('Start ON_SCREEN_FOCUS:7 SET_VARIABLE');
        setGlobalVariableValue({
          key: 'ak_businessStatus',
          value: Business_Status,
        });
        console.log('Complete ON_SCREEN_FOCUS:7 SET_VARIABLE');
        console.log('Start ON_SCREEN_FOCUS:8 EXTRACT_KEY');
        const current_bus_id = businessResponse?.id;
        console.log('Complete ON_SCREEN_FOCUS:8 EXTRACT_KEY', {
          current_bus_id,
        });
        console.log('Start ON_SCREEN_FOCUS:9 SET_VARIABLE');
        setGlobalVariableValue({
          key: 'ak_businessID',
          value: current_bus_id,
        });
        console.log('Complete ON_SCREEN_FOCUS:9 SET_VARIABLE');
        StatusBar.setBarStyle('light-content');
      } catch (err) {
        console.error(err);
        error = err.message ?? err;
      }
      console.log(
        'Screen ON_SCREEN_FOCUS Complete',
        error ? { error } : 'no error'
      );
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors['Akurie Dark Blue'] },
        dimensions.width
      )}
    >
      {/* new header 8-15 */}
      <View>
        {/* Navigation Frame */}
        <View
          style={StyleSheet.applyWidth(
            {
              borderBottomWidth: 3,
              borderColor: theme.colors['Akurie green'],
              height: 90,
              marginTop: 15,
            },
            dimensions.width
          )}
        >
          <ImageBackground
            resizeMode={'cover'}
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors['Akurie Dark Blue'],
                flexDirection: 'row',
                height: '100%',
                justifyContent: 'space-between',
              },
              dimensions.width
            )}
          >
            {/* Left Side Frame */}
            <View
              style={StyleSheet.applyWidth(
                { paddingLeft: 9, paddingRight: 9 },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    navigation.goBack();
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* Icon Flex Frame */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexGrow: 0,
                      flexShrink: 0,
                      paddingBottom: 12,
                      paddingLeft: 12,
                      paddingRight: 12,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  {/* Icon  */}
                  <Icon
                    color={theme.colors['Background']}
                    name={'Ionicons/arrow-back-outline'}
                    size={24}
                  />
                </View>
              </Touchable>
            </View>
            {/* Middle Frame */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexGrow: 1,
                  flexShrink: 0,
                  justifyContent: 'center',
                },
                dimensions.width
              )}
            >
              {/* Text Frame  */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                {/* Text 12 18 Regular */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Akurie green'],
                      fontFamily: 'System',
                      fontSize: 22,
                      fontWeight: '400',
                      lineHeight: 18,
                      paddingTop: 8,
                    },
                    dimensions.width
                  )}
                >
                  {'Networking Event Center'}
                </Text>
              </View>
            </View>
            {/* Left Side Frame */}
            <View
              style={StyleSheet.applyWidth(
                { paddingLeft: 9, paddingRight: 9 },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('UserDashboardScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* Icon Flex Frame */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexGrow: 0,
                      flexShrink: 0,
                      paddingBottom: 12,
                      paddingLeft: 12,
                      paddingRight: 12,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  {/* Icon  */}
                  <Icon
                    color={theme.colors['Background']}
                    name={'AntDesign/layout'}
                    size={24}
                  />
                </View>
              </Touchable>
            </View>
          </ImageBackground>
        </View>
      </View>

      <ImageBackground
        resizeMode={'cover'}
        source={Images.ApplicationFrameHost73m1r9cydb}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ImageBackgroundStyles(theme)['Image Background'],
            {
              height: '100%',
              justifyContent: {
                minWidth: Breakpoints.Tablet,
                value: 'space-evenly',
              },
            }
          ),
          dimensions.width
        )}
      >
        <Image
          resizeMode={'cover'}
          source={Images.Asset30}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
              bottom: 0,
              height: '100%',
              left: 0,
              opacity: 0.35,
              position: 'absolute',
              right: 0,
              top: 0,
              width: '100%',
            }),
            dimensions.width
          )}
        />
        <View
          style={StyleSheet.applyWidth(
            {
              height: '100%',
              justifyContent: {
                minWidth: Breakpoints.Tablet,
                value: 'flex-start',
              },
            },
            dimensions.width
          )}
        >
          {/* GreetView */}
          <View
            style={StyleSheet.applyWidth(
              {
                height: '100%',
                marginBottom: { minWidth: Breakpoints.Tablet, value: 20 },
                marginTop: [
                  { minWidth: Breakpoints.Tablet, value: 20 },
                  { minWidth: Breakpoints.BigScreen, value: 40 },
                  { minWidth: Breakpoints.Desktop, value: 40 },
                  { minWidth: Breakpoints.Laptop, value: 40 },
                ],
                paddingBottom: 20,
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 60,
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  paddingLeft: { minWidth: Breakpoints.BigScreen, value: 50 },
                  paddingRight: { minWidth: Breakpoints.BigScreen, value: 50 },
                },
                dimensions.width
              )}
            >
              <AuthAkurieApi.FetchGetSpecificUserGET
                user_id={Constants['ak_userID']}
              >
                {({ loading, error, data, refetchGetSpecificUser }) => {
                  const fetchData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <>
                      {/* View 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            paddingRight: 10,
                          },
                          dimensions.width
                        )}
                      ></View>
                    </>
                  );
                }}
              </AuthAkurieApi.FetchGetSpecificUserGET>
              {/* View 3 */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', flexDirection: 'row', marginTop: 20 },
                  dimensions.width
                )}
              >
                <BusinessDataAkurieApi.FetchGetBusinessGET
                  business_id={Constants['ak_businessID']}
                >
                  {({ loading, error, data, refetchGetBusiness }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            paddingLeft: 20,
                            paddingRight: 20,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        {/* Greet */}
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors['Akurie green'],
                              fontFamily: 'Inter_300Light',
                              fontSize: [
                                { minWidth: Breakpoints.Mobile, value: 14 },
                                { minWidth: Breakpoints.BigScreen, value: 20 },
                                { minWidth: Breakpoints.Tablet, value: 16 },
                              ],
                            },
                            dimensions.width
                          )}
                        >
                          {'Current Business:'}
                        </Text>
                        {/* Business Name */}
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors['Strong_Inverse'],
                              fontFamily: [
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'Inter_500Medium',
                                },
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: 'Inter_600SemiBold',
                                },
                              ],
                              fontSize: [
                                { minWidth: Breakpoints.Mobile, value: 24 },
                                { minWidth: Breakpoints.Laptop, value: 20 },
                                { minWidth: Breakpoints.BigScreen, value: 22 },
                                { minWidth: Breakpoints.Tablet, value: 26 },
                              ],
                              textAlign: 'center',
                            },
                            dimensions.width
                          )}
                        >
                          {fetchData?.user_business_name}
                        </Text>
                        {/* Sub Info */}
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors['Strong_Inverse'],
                              fontFamily: [
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: 'Inter_600SemiBold',
                                },
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'Inter_400Regular',
                                },
                              ],
                              fontSize: [
                                { minWidth: Breakpoints.Mobile, value: 14 },
                                { minWidth: Breakpoints.BigScreen, value: 22 },
                                { minWidth: Breakpoints.Tablet, value: 16 },
                              ],
                            },
                            dimensions.width
                          )}
                        >
                          {fetchData?.business_state}
                          {' - '}
                          {fetchData?.entity_type}
                        </Text>
                      </View>
                    );
                  }}
                </BusinessDataAkurieApi.FetchGetBusinessGET>
              </View>
            </View>
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: {
                    minWidth: Breakpoints.Desktop,
                    value: 'center',
                  },
                  marginTop: 20,
                },
                dimensions.width
              )}
            >
              {/* View 3 */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', flexDirection: 'column' },
                  dimensions.width
                )}
              >
                <AuthAkurieApi.FetchGetSpecificUserGET
                  user_id={Constants['ak_userID']}
                >
                  {({ loading, error, data, refetchGetSpecificUser }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <Text
                        accessible={true}
                        allowFontScaling={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Strong Inverse'],
                              fontFamily: 'Inter_600SemiBold',
                              fontSize: 18,
                              marginBottom: 5,
                              marginTop: 5,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {fetchData?.First_name} {fetchData?.Last_name}
                        {' ID Pass'}
                      </Text>
                    );
                  }}
                </AuthAkurieApi.FetchGetSpecificUserGET>
                <Surface
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.SurfaceStyles(theme)['Surface'],
                      {
                        alignItems: 'center',
                        borderRadius: 19,
                        height: [
                          { minWidth: Breakpoints.Tablet, value: 400 },
                          { minWidth: Breakpoints.Mobile, value: 300 },
                        ],
                        justifyContent: 'center',
                        marginBottom: 20,
                        width: [
                          { minWidth: Breakpoints.Tablet, value: 400 },
                          { minWidth: Breakpoints.Mobile, value: 300 },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <Image
                    resizeMode={'cover'}
                    source={Images.QrcodeAkuriecom}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        { height: '90%', width: '90%' }
                      ),
                      dimensions.width
                    )}
                  />
                </Surface>

                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'column',
                      justifyContent: 'space-evenly',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Strong Inverse'],
                          fontFamily: 'Inter_600SemiBold',
                          fontSize: 16,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'This is your event ticket / pass.'}
                  </Text>
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Strong Inverse'],
                          fontFamily: 'Inter_400Regular',
                          fontSize: 16,
                          marginTop: 5,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'No Upcoming Events For {Month}.'}
                  </Text>
                </View>
                {/* Styled Button */}
                <Touchable
                  onPress={() => {
                    try {
                      navigation.navigate('UserDashboardScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    { marginTop: 20, width: '100%' },
                    dimensions.width
                  )}
                >
                  {/* Banner */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 5,
                        marginLeft: 15,
                        marginRight: 15,
                        marginTop: 5,
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['Akurie Dark Blue'],
                          borderRadius: 15,
                          height: 70,
                          justifyContent: 'center',
                          paddingBottom: 10,
                          paddingLeft: 20,
                          paddingRight: 10,
                          paddingTop: 10,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      {/* Title */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Akurie green'],
                            fontFamily: 'Inter_500Medium',
                            fontSize: 14,
                          },
                          dimensions.width
                        )}
                      >
                        {'Access Akurie Main\nAssistant Dashboard '}
                      </Text>
                    </View>
                    <Image
                      resizeMode={'contain'}
                      source={Images.AkurieCircle}
                      style={StyleSheet.applyWidth(
                        {
                          bottom: 0,
                          height: 50,
                          marginBottom: 10,
                          marginRight: 10,
                          position: 'absolute',
                          right: 0,
                          width: 50,
                        },
                        dimensions.width
                      )}
                    />
                  </View>
                </Touchable>
              </View>
            </View>
          </View>
        </View>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(NetworkingIDPassScreen);
