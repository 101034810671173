import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AuthAkurieApi from '../apis/AuthAkurieApi.js';
import * as BusinessDataAkurieApi from '../apis/BusinessDataAkurieApi.js';
import * as Web$AppServicesApi from '../apis/Web$AppServicesApi.js';
import DesktopHeader13024SignedInBlock from '../components/DesktopHeader13024SignedInBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openImagePickerUtil from '../utils/openImagePicker';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  ActionSheet,
  ActionSheetCancel,
  ActionSheetItem,
  Button,
  Checkbox,
  Circle,
  CircularProgress,
  Divider,
  Icon,
  IconButton,
  ScreenContainer,
  Spacer,
  Surface,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import {
  ActivityIndicator,
  FlatList,
  Image,
  ImageBackground,
  ScrollView,
  StatusBar,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const MyBusinessInfoScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [About_entityview, setAbout_entityview] = React.useState(false);
  const [Registered_Agentview, setRegistered_Agentview] = React.useState(false);
  const [accessLock_Docs_Filing, setAccessLock_Docs_Filing] =
    React.useState(false);
  const [accessLock_Plans_Goals, setAccessLock_Plans_Goals] =
    React.useState(false);
  const [accessLock_Web_Services, setAccessLock_Web_Services] =
    React.useState(false);
  const [additional_info_entity, setAdditional_info_entity] =
    React.useState(false);
  const [aku_busName, setAku_busName] = React.useState('');
  const [aku_entity_type, setAku_entity_type] = React.useState('');
  const [aku_naics, setAku_naics] = React.useState('');
  const [businessNameInputValue, setBusinessNameInputValue] =
    React.useState('');
  const [checkbox2Value, setCheckbox2Value] = React.useState(false);
  const [checkbox3Value, setCheckbox3Value] = React.useState(false);
  const [checkbox4Value, setCheckbox4Value] = React.useState(false);
  const [checkbox5Value, setCheckbox5Value] = React.useState(false);
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [cons_of_entity, setCons_of_entity] = React.useState(false);
  const [industrySearchValue, setIndustrySearchValue] = React.useState('');
  const [logo_actionitem_view, setLogo_actionitem_view] = React.useState(false);
  const [logo_present, setLogo_present] = React.useState(false);
  const [numberOfEmployeesInputValue, setNumberOfEmployeesInputValue] =
    React.useState('');
  const [opStatus, setOpStatus] = React.useState('');
  const [our_help_select, setOur_help_select] = React.useState([
    "Yes please! I'm typically busy",
    'No, I can handle things.',
    "Honestly, I'm not sure.",
  ]);
  const [ownership_detailsview, setOwnership_detailsview] =
    React.useState(false);
  const [problems, setProblems] = React.useState([]);
  const [pros_of_entity, setPros_of_entity] = React.useState(false);
  const [ragent_status, setRagent_status] = React.useState('');
  const [ud_ourHelpPickerValue, setUd_ourHelpPickerValue] = React.useState('');
  const [view_more_view, setView_more_view] = React.useState(false);
  const businessDataAkurieUpdateBusinessLogoPATCH =
    BusinessDataAkurieApi.useUpdateBusinessLogoPATCH();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        /* hidden 'API Request' action */
        /* hidden 'Extract Key' action */
        /* hidden 'Navigate' action */
        /* hidden 'Conditional Stop' action */
        setGlobalVariableValue({
          key: 'ak_isLoading',
          value: true,
        });
        setCheckboxValue(false);
        setCheckbox2Value(false);
        setCheckbox3Value(false);
        setCheckbox4Value(false);
        setCheckbox5Value(false);
        const businessdata = (
          await BusinessDataAkurieApi.getBusinessGET(Constants, {
            business_id: Constants['ak_businessID'],
          })
        )?.json;
        const agentResponse = (
          await BusinessDataAkurieApi.getRegisteredAgentGET(Constants, {
            business_id: Constants['ak_businessID'],
          })
        )?.json;
        const bus_entity = businessdata?.entity_type;
        const businessName = businessdata?.user_business_name;
        const busNaics = businessdata?.industry_supersector_and_naics_name;
        setAku_entity_type(bus_entity);
        const raNamestatus = agentResponse?.registered_agent_name;
        setAku_busName(businessName);
        setRagent_status(raNamestatus);
        const ownersStatus = (
          await BusinessDataAkurieApi.getOwnersGET(Constants, {
            business_id: Constants['ak_businessID'],
          })
        )?.json;
        const ownerpresentStatus = ownersStatus?.Number_Of_Owners;
        setOpStatus(ownerpresentStatus);
        setAku_naics(busNaics);
        const brandingResult = (
          await Web$AppServicesApi.brandingCheckGET(Constants, {
            bus_id: Constants['ak_businessID'],
          })
        )?.json;
        setLogo_present(brandingResult);
        setGlobalVariableValue({
          key: 'ak_isLoading',
          value: false,
        });
        StatusBar.setBarStyle('dark-content');
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={true}
    >
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <DesktopHeader13024SignedInBlock />
        )}
      </>
      <ImageBackground
        resizeMode={'stretch'}
        source={Images.FirefoxPHKyvBthWe}
        style={StyleSheet.applyWidth(
          {
            height: '100%',
            paddingLeft: [
              { minWidth: Breakpoints.Laptop, value: '10%' },
              { minWidth: Breakpoints.Desktop, value: '15%' },
            ],
            paddingRight: [
              { minWidth: Breakpoints.Laptop, value: '10%' },
              { minWidth: Breakpoints.Desktop, value: '15%' },
            ],
            width: '100%',
          },
          dimensions.width
        )}
      >
        {/* Main Header w /c  */}
        <View>
          {/* Navigation Frame */}
          <View
            style={StyleSheet.applyWidth(
              {
                borderBottomWidth: 2,
                borderColor: theme.colors['Akurie green'],
                height: 90,
                marginTop: 15,
              },
              dimensions.width
            )}
          >
            <ImageBackground
              resizeMode={'cover'}
              source={Images.Asset13}
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: theme.colors['Akurie Dark Blue'],
                  flexDirection: 'row',
                  height: '100%',
                  justifyContent: 'space-evenly',
                },
                dimensions.width
              )}
            >
              {/* Left Side Frame */}
              <View
                style={StyleSheet.applyWidth(
                  { paddingLeft: 9, paddingRight: 9 },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    try {
                      navigation.goBack();
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* Icon Flex Frame */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexGrow: 0,
                        flexShrink: 0,
                        paddingBottom: 12,
                        paddingLeft: 12,
                        paddingRight: 12,
                        paddingTop: 12,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Icon  */}
                    <Icon
                      color={theme.colors['Background']}
                      name={'Ionicons/arrow-back-outline'}
                      size={24}
                    />
                  </View>
                </Touchable>
              </View>
              {/* Middle Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexGrow: 1,
                    flexShrink: 0,
                    justifyContent: 'center',
                  },
                  dimensions.width
                )}
              >
                {/* Text Frame  */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexGrow: 0,
                      flexShrink: 0,
                      marginLeft: 20,
                      paddingBottom: 12,
                      paddingLeft: 12,
                      paddingRight: 12,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  {/* Text 12 18 Regular */}
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Akurie green'],
                        fontFamily: 'System',
                        fontSize: 22,
                        fontWeight: '400',
                        lineHeight: 18,
                        paddingTop: 8,
                      },
                      dimensions.width
                    )}
                  >
                    {'My Business Info'}
                  </Text>
                </View>
              </View>
              {/* Left Side Frame */}
              <View
                style={StyleSheet.applyWidth(
                  { paddingLeft: 9, paddingRight: 9 },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    try {
                      navigation.navigate('HelpSupportScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* Icon Flex Frame */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexGrow: 0,
                        flexShrink: 0,
                        paddingBottom: 12,
                        paddingLeft: 12,
                        paddingRight: 12,
                        paddingTop: 12,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Icon  */}
                    <Icon
                      color={theme.colors['Background']}
                      name={'Ionicons/help-circle-outline'}
                      size={32}
                    />
                  </View>
                </Touchable>
              </View>
            </ImageBackground>
          </View>
        </View>
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: [
                { minWidth: Breakpoints.Tablet, value: 'center' },
                { minWidth: Breakpoints.Mobile, value: 'stretch' },
              ],
            },
            dimensions.width
          )}
        >
          <ScrollView
            bounces={true}
            showsVerticalScrollIndicator={false}
            style={StyleSheet.applyWidth(
              {
                width: [
                  { minWidth: Breakpoints.Tablet, value: '100%' },
                  { minWidth: Breakpoints.Mobile, value: '100%' },
                ],
              },
              dimensions.width
            )}
            contentContainerStyle={StyleSheet.applyWidth(
              { marginTop: { minWidth: Breakpoints.Tablet, value: 40 } },
              dimensions.width
            )}
          >
            <>
              {!logo_actionitem_view ? null : (
                <View>
                  <ActionSheet visible={true}>
                    {/* Action Sheet Item 2 */}
                    <ActionSheetItem
                      color={theme.colors.strong}
                      label={'Upload New Logo Image'}
                      onPress={() => {
                        const handler = async () => {
                          try {
                            const Result_Image = await openImagePickerUtil({
                              mediaTypes: 'All',
                              allowsEditing: true,
                              quality: 1,
                              allowsMultipleSelection: false,
                              permissionErrorMessage:
                                'Sorry, we need notifications permissions to make this work.',
                              showAlertOnPermissionError: true,
                            });

                            (
                              await businessDataAkurieUpdateBusinessLogoPATCH.mutateAsync(
                                { image_Logo_file: Result_Image }
                              )
                            )?.json;
                            setLogo_actionitem_view(false);
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ActionSheetItemStyles(theme)[
                          'Action Sheet Item'
                        ],
                        dimensions.width
                      )}
                    />
                    <ActionSheetItem
                      color={theme.colors.strong}
                      label={'Clear Current Image'}
                      onPress={() => {
                        const handler = async () => {
                          try {
                            (
                              await businessDataAkurieUpdateBusinessLogoPATCH.mutateAsync(
                                {
                                  image_Logo_file:
                                    'https://apps-draftbit-com.s3.amazonaws.com/apps/oh4dO59b/assets/0nn9aNPJWEpostAr-YG5O',
                                }
                              )
                            )?.json;
                            setLogo_actionitem_view(false);
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ActionSheetItemStyles(theme)[
                          'Action Sheet Item'
                        ],
                        dimensions.width
                      )}
                    />
                    <ActionSheetCancel
                      label={'Cancel'}
                      onPress={() => {
                        try {
                          setLogo_actionitem_view(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    />
                  </ActionSheet>
                </View>
              )}
            </>
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  marginTop: 5,
                  padding: 10,
                  paddingBottom: 20,
                  paddingTop: 50,
                },
                dimensions.width
              )}
            >
              <Icon
                color={theme.colors['Akurie Dark Blue']}
                name={'Ionicons/finger-print-outline'}
                size={100}
              />
            </View>
            {/* Business Title & Id */}
            <View
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ViewStyles(theme)['bus pro2'], {
                  alignSelf: 'center',
                  borderColor: theme.colors['Custom Color_18'],
                  padding: 10,
                  width: '80%',
                }),
                dimensions.width
              )}
            >
              <BusinessDataAkurieApi.FetchGetBusinessGET
                business_id={Constants['ak_businessID']}
              >
                {({ loading, error, data, refetchGetBusiness }) => {
                  const fetchData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <>
                      {/* Business name */}
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              alignSelf: 'center',
                              fontFamily: 'Inter_600SemiBold',
                              fontSize: 24,
                              textAlign: 'center',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {fetchData?.user_business_name}
                      </Text>
                    </>
                  );
                }}
              </BusinessDataAkurieApi.FetchGetBusinessGET>
              {/* Bus Id */}
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Light'],
                    fontFamily: 'Inter_400Regular',
                    fontSize: 12,
                    marginBottom: 10,
                    marginTop: 10,
                  }),
                  dimensions.width
                )}
              >
                {'Business ID: '}
                {Constants['ak_businessID']}
              </Text>
            </View>
            {/* Content */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['Akurie Dark Blue'],
                  borderRadius: [
                    { minWidth: Breakpoints.Mobile, value: 25 },
                    { minWidth: Breakpoints.Tablet, value: 30 },
                  ],
                  flex: 1,
                  padding: 10,
                  paddingBottom: [
                    { minWidth: Breakpoints.Mobile, value: 100 },
                    { minWidth: Breakpoints.Tablet, value: 140 },
                  ],
                  paddingLeft: [
                    { minWidth: Breakpoints.Tablet, value: 60 },
                    { minWidth: Breakpoints.Mobile, value: 10 },
                  ],
                  paddingRight: [
                    { minWidth: Breakpoints.Tablet, value: 60 },
                    { minWidth: Breakpoints.Mobile, value: 10 },
                  ],
                },
                dimensions.width
              )}
            >
              <View />
              {/* List */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: {
                      minWidth: Breakpoints.Tablet,
                      value: 'center',
                    },
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                    marginTop: 5,
                  },
                  dimensions.width
                )}
              >
                {/* Basic Info */}
                <Touchable
                  onPress={() => {
                    try {
                      if (checkboxValue) {
                        setCheckboxValue(false);
                        setView_more_view(false);
                      } else {
                        setCheckboxValue(true);
                        setView_more_view(true);
                      }
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      width: [
                        { minWidth: Breakpoints.Mobile, value: '100%' },
                        { minWidth: Breakpoints.Tablet, value: '99%' },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  {/* Banner */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: theme.colors['Akurie Primary'],
                        borderRadius: 15,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        margin: 5,
                        paddingLeft: 20,
                        paddingRight: 20,
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={theme.colors['Akurie Dark Blue']}
                      name={'AntDesign/contacts'}
                      size={45}
                    />
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          borderRadius: 15,
                          height: 80,
                          justifyContent: 'center',
                          paddingBottom: 10,
                          paddingTop: 10,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Title */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Strong_Inverse'],
                            fontFamily: 'Inter_600SemiBold',
                            fontSize: 17,
                            textAlign: 'left',
                          },
                          dimensions.width
                        )}
                      >
                        {'General Infomation'}
                      </Text>
                    </View>
                    <Checkbox
                      checkedIcon={'AntDesign/closecircle'}
                      color={theme.colors['Akurie green']}
                      onPress={newCheckboxValue => {
                        const checkboxValue = newCheckboxValue;
                        try {
                          setCheckboxValue(newCheckboxValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      size={35}
                      status={checkboxValue}
                      uncheckedColor={theme.colors['Akurie green']}
                      uncheckedIcon={'AntDesign/downcircle'}
                    />
                  </View>
                </Touchable>
                {/* General Info */}
                <>
                  {!checkboxValue ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['Akurie Dark Blue'],
                          flexDirection: 'column',
                          paddingBottom: 25,
                          paddingLeft: 40,
                          paddingRight: 40,
                          paddingTop: 25,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Fetch 2 */}
                      <BusinessDataAkurieApi.FetchContactInfoCheckGET
                        bus_id={Constants['ak_businessID']}
                      >
                        {({
                          loading,
                          error,
                          data,
                          refetchContactInfoCheck,
                        }) => {
                          const fetch2Data = data?.json;
                          if (loading) {
                            return <ActivityIndicator />;
                          }

                          if (
                            error ||
                            data?.status < 200 ||
                            data?.status >= 300
                          ) {
                            return <ActivityIndicator />;
                          }

                          return (
                            <View
                              style={StyleSheet.applyWidth(
                                { width: '100%' },
                                dimensions.width
                              )}
                            >
                              <>
                                {!fetch2Data ? null : (
                                  <BusinessDataAkurieApi.FetchBusinessContactInfoGET
                                    bus_id={Constants['ak_businessID']}
                                  >
                                    {({
                                      loading,
                                      error,
                                      data,
                                      refetchBusinessContactInfo,
                                    }) => {
                                      const fetchData = data?.json;
                                      if (loading) {
                                        return <ActivityIndicator />;
                                      }

                                      if (
                                        error ||
                                        data?.status < 200 ||
                                        data?.status >= 300
                                      ) {
                                        return <ActivityIndicator />;
                                      }

                                      return (
                                        <>
                                          {!checkboxValue ? null : (
                                            <View>
                                              {/* business_contact_person */}
                                              <Text
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    color:
                                                      theme.colors[
                                                        'Strong_Inverse'
                                                      ],
                                                    fontFamily:
                                                      'Inter_400Regular',
                                                    fontSize: 16,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {'Business Contact: \n'}
                                                {
                                                  fetchData?.business_contact_person
                                                }
                                              </Text>
                                              {/* Phone */}
                                              <Text
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    color:
                                                      theme.colors[
                                                        'Strong_Inverse'
                                                      ],
                                                    fontFamily:
                                                      'Inter_400Regular',
                                                    fontSize: 16,
                                                    marginTop: 5,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {'Phone: '}
                                                {fetchData?.phone_number}
                                              </Text>
                                              {/* business_email */}
                                              <Text
                                                ellipsizeMode={'tail'}
                                                numberOfLines={2}
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    color:
                                                      theme.colors[
                                                        'Strong_Inverse'
                                                      ],
                                                    fontFamily:
                                                      'Inter_400Regular',
                                                    fontSize: 16,
                                                    marginBottom: 10,
                                                    marginTop: 5,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {'Business Email: \n'}
                                                {fetchData?.business_email}
                                              </Text>
                                              <Divider
                                                color={theme.colors.divider}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.DividerStyles(
                                                      theme
                                                    )['Divider'],
                                                    {
                                                      marginBottom: 10,
                                                      marginTop: 10,
                                                      width: '80%',
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              />
                                              {/* Address */}
                                              <Text
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    color:
                                                      theme.colors[
                                                        'Strong_Inverse'
                                                      ],
                                                    fontFamily:
                                                      'Inter_400Regular',
                                                    fontSize: 16,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {'Address: '}
                                                {fetchData?.Address}
                                              </Text>
                                              {/* Address 2 */}
                                              <Text
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    color:
                                                      theme.colors[
                                                        'Strong_Inverse'
                                                      ],
                                                    fontFamily:
                                                      'Inter_400Regular',
                                                    fontSize: 16,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {'Address 2: '}
                                                {fetchData?.Address_2}
                                              </Text>
                                              {/* City */}
                                              <Text
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    color:
                                                      theme.colors[
                                                        'Strong_Inverse'
                                                      ],
                                                    fontFamily:
                                                      'Inter_400Regular',
                                                    fontSize: 16,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {'City: '}
                                                {fetchData?.City}
                                              </Text>

                                              <BusinessDataAkurieApi.FetchGetBusinessGET
                                                business_id={
                                                  Constants['ak_businessID']
                                                }
                                              >
                                                {({
                                                  loading,
                                                  error,
                                                  data,
                                                  refetchGetBusiness,
                                                }) => {
                                                  const fetchData = data?.json;
                                                  if (loading) {
                                                    return (
                                                      <ActivityIndicator />
                                                    );
                                                  }

                                                  if (
                                                    error ||
                                                    data?.status < 200 ||
                                                    data?.status >= 300
                                                  ) {
                                                    return (
                                                      <ActivityIndicator />
                                                    );
                                                  }

                                                  return (
                                                    <>
                                                      {/* State */}
                                                      <Text
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            color:
                                                              theme.colors[
                                                                'Strong_Inverse'
                                                              ],
                                                            fontFamily:
                                                              'Inter_400Regular',
                                                            fontSize: 16,
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {'State: '}
                                                        {
                                                          fetchData?.business_state
                                                        }
                                                      </Text>
                                                    </>
                                                  );
                                                }}
                                              </BusinessDataAkurieApi.FetchGetBusinessGET>
                                              {/* Zip Code */}
                                              <Text
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    color:
                                                      theme.colors[
                                                        'Strong_Inverse'
                                                      ],
                                                    fontFamily:
                                                      'Inter_400Regular',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {'Zip Code: '}
                                                {fetchData?.business_zip_code}
                                              </Text>
                                              {/* County */}
                                              <Text
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    color:
                                                      theme.colors[
                                                        'Strong_Inverse'
                                                      ],
                                                    fontFamily:
                                                      'Inter_400Regular',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {'County: '}
                                                {fetchData?.County}
                                              </Text>
                                              <Spacer
                                                bottom={10}
                                                left={8}
                                                right={8}
                                                top={10}
                                              />
                                              {/* Edit Button */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                    marginTop: 20,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                <Button
                                                  onPress={() => {
                                                    try {
                                                      navigation.navigate(
                                                        'BusinessInfoScreen'
                                                      );
                                                    } catch (err) {
                                                      console.error(err);
                                                    }
                                                  }}
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      backgroundColor:
                                                        theme.colors[
                                                          'Background'
                                                        ],
                                                      borderBottomWidth: 1,
                                                      borderColor:
                                                        theme.colors[
                                                          'Custom Color_18'
                                                        ],
                                                      borderLeftWidth: 1,
                                                      borderRadius: 8,
                                                      borderRightWidth: 1,
                                                      borderTopWidth: 1,
                                                      color:
                                                        theme.colors[
                                                          'Custom Color_18'
                                                        ],
                                                      fontFamily: 'System',
                                                      fontWeight: '700',
                                                      textAlign: 'center',
                                                      width: 130,
                                                    },
                                                    dimensions.width
                                                  )}
                                                  title={'Manage / Edit'}
                                                />
                                              </View>
                                            </View>
                                          )}
                                        </>
                                      );
                                    }}
                                  </BusinessDataAkurieApi.FetchBusinessContactInfoGET>
                                )}
                              </>
                              <>
                                {fetch2Data ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { borderRadius: 15, padding: 5 },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ],
                                          {
                                            color:
                                              theme.colors['Strong Inverse'],
                                            fontFamily: 'Inter_400Regular',
                                            textAlign: 'center',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {
                                        "For networking and for us to contact you. Please provide your business's contact information."
                                      }
                                    </Text>
                                    <Button
                                      onPress={() => {
                                        try {
                                          navigation.navigate(
                                            'BusinessInfoScreen'
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ButtonStyles(theme)[
                                            'Button'
                                          ],
                                          {
                                            backgroundColor:
                                              theme.colors['Akurie green'],
                                            borderRadius: 9,
                                            color:
                                              theme.colors['Akurie Dark Blue'],
                                            fontFamily: 'Inter_700Bold',
                                            marginTop: 20,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                      title={'Get Started'}
                                    />
                                  </View>
                                )}
                              </>
                            </View>
                          );
                        }}
                      </BusinessDataAkurieApi.FetchContactInfoCheckGET>
                    </View>
                  )}
                </>
                {/* Branding */}
                <Touchable
                  onPress={() => {
                    try {
                      if (checkbox2Value) {
                        setCheckbox2Value(false);
                      } else {
                        setCheckbox2Value(true);
                      }
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      width: [
                        { minWidth: Breakpoints.Mobile, value: '100%' },
                        { minWidth: Breakpoints.Tablet, value: '99%' },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  {/* Banner */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: theme.colors['Akurie Primary'],
                        borderRadius: 15,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        margin: 5,
                        paddingLeft: 20,
                        paddingRight: 20,
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={theme.colors['Akurie Dark Blue']}
                      name={'MaterialCommunityIcons/palette-swatch-outline'}
                      size={45}
                    />
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          borderRadius: 15,
                          height: 80,
                          justifyContent: 'center',
                          paddingBottom: 10,
                          paddingTop: 10,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Title */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Strong_Inverse'],
                            fontFamily: 'Inter_500Medium',
                            fontSize: 17,
                            textAlign: 'left',
                          },
                          dimensions.width
                        )}
                      >
                        {'Branding & Colors'}
                      </Text>
                    </View>
                    {/* Checkbox2 */}
                    <Checkbox
                      checkedIcon={'AntDesign/closecircle'}
                      color={theme.colors['Akurie green']}
                      onPress={newCheckbox2Value => {
                        try {
                          setCheckbox2Value(newCheckbox2Value);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      size={35}
                      status={checkbox2Value}
                      uncheckedColor={theme.colors['Akurie green']}
                      uncheckedIcon={'AntDesign/downcircle'}
                    />
                  </View>
                </Touchable>
                <>
                  {!checkbox2Value ? null : (
                    <Web$AppServicesApi.FetchBrandingCheckGET
                      bus_id={Constants['ak_businessID']}
                    >
                      {({ loading, error, data, refetchBrandingCheck }) => {
                        const fetchData = data?.json;
                        if (loading) {
                          return <ActivityIndicator />;
                        }

                        if (
                          error ||
                          data?.status < 200 ||
                          data?.status >= 300
                        ) {
                          return <ActivityIndicator />;
                        }

                        return (
                          <>
                            <>
                              {fetchData ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor:
                                        theme.colors['Strong Inverse'],
                                      borderRadius: 15,
                                      marginLeft: 5,
                                      marginRight: 5,
                                      padding: 40,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    accessible={true}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text'],
                                        {
                                          color: theme.colors['Strong'],
                                          fontFamily: 'Inter_400Regular',
                                          textAlign: 'center',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {
                                      'To help us create your website, graphic content, and design insights please provide your brand colors, logo, and other info.'
                                    }
                                  </Text>
                                  <Button
                                    onPress={() => {
                                      try {
                                        navigation.navigate(
                                          'WebsiteBuildInfo3Screen'
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ButtonStyles(theme)[
                                          'Button'
                                        ],
                                        {
                                          backgroundColor:
                                            theme.colors['Akurie green'],
                                          borderRadius: 9,
                                          color:
                                            theme.colors['Akurie Dark Blue'],
                                          fontFamily: 'Inter_700Bold',
                                          marginTop: 20,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                    title={'Add Branding'}
                                  />
                                </View>
                              )}
                            </>
                            {/* View 3 */}
                            <>
                              {!fetchData ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor:
                                        theme.colors['Strong Inverse'],
                                      borderRadius: 15,
                                      marginLeft: 5,
                                      marginRight: 5,
                                      padding: 30,
                                      width: {
                                        minWidth: Breakpoints.Tablet,
                                        value: '95%',
                                      },
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Web$AppServicesApi.FetchGetAllBrandingGET
                                    bus_id={Constants['ak_businessID']}
                                  >
                                    {({
                                      loading,
                                      error,
                                      data,
                                      refetchGetAllBranding,
                                    }) => {
                                      const fetchData = data?.json;
                                      if (loading) {
                                        return <ActivityIndicator />;
                                      }

                                      if (
                                        error ||
                                        data?.status < 200 ||
                                        data?.status >= 300
                                      ) {
                                        return <ActivityIndicator />;
                                      }

                                      return (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              width: {
                                                minWidth: Breakpoints.Tablet,
                                                value: '100%',
                                              },
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            accessible={true}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ],
                                                {
                                                  fontFamily:
                                                    'Inter_600SemiBold',
                                                  fontSize: 20,
                                                  marginBottom: 10,
                                                  marginTop: 20,
                                                  textAlign: 'center',
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {'Your Logo'}
                                          </Text>
                                          {/* View 3 */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                marginBottom: 20,
                                                marginTop: 20,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignItems: 'center',
                                                  justifyContent: 'center',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              <Image
                                                resizeMode={'contain'}
                                                source={{
                                                  uri: `${fetchData?.company_logo?.url}`,
                                                }}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.ImageStyles(
                                                      theme
                                                    )['Image'],
                                                    {
                                                      height: 150,
                                                      width: '80%',
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              />
                                            </View>
                                          </View>
                                          {/* View 2 */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                justifyContent: 'space-evenly',
                                                marginBottom: 20,
                                                marginTop: 20,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <View
                                              style={StyleSheet.applyWidth(
                                                { alignItems: 'center' },
                                                dimensions.width
                                              )}
                                            >
                                              <Text
                                                accessible={true}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'],
                                                    {
                                                      fontFamily:
                                                        'Inter_600SemiBold',
                                                      marginBottom: 5,
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {'Primary'}
                                              </Text>
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    backgroundColor:
                                                      fetchData?.Primary_color,
                                                    borderRadius: 9,
                                                    borderWidth: 0.5,
                                                    height: 90,
                                                    width: 90,
                                                  },
                                                  dimensions.width
                                                )}
                                              />
                                              {/* Text 2 */}
                                              <Text
                                                accessible={true}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'],
                                                    {
                                                      fontFamily:
                                                        'Inter_600SemiBold',
                                                      marginBottom: 5,
                                                      marginTop: 5,
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {fetchData?.Primary_color}
                                              </Text>
                                            </View>
                                            {/* View 2 */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                { alignItems: 'center' },
                                                dimensions.width
                                              )}
                                            >
                                              <Text
                                                accessible={true}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'],
                                                    {
                                                      fontFamily:
                                                        'Inter_600SemiBold',
                                                      marginBottom: 5,
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {'Secondary'}
                                              </Text>
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    backgroundColor:
                                                      fetchData?.Secondary_color,
                                                    borderRadius: 9,
                                                    borderWidth: 0.5,
                                                    height: 90,
                                                    width: 90,
                                                  },
                                                  dimensions.width
                                                )}
                                              />
                                              {/* Text 2 */}
                                              <Text
                                                accessible={true}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'],
                                                    {
                                                      fontFamily:
                                                        'Inter_600SemiBold',
                                                      marginBottom: 5,
                                                      marginTop: 5,
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {fetchData?.Secondary_color}
                                              </Text>
                                            </View>
                                            {/* View 3 */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                { alignItems: 'center' },
                                                dimensions.width
                                              )}
                                            >
                                              <Text
                                                accessible={true}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'],
                                                    {
                                                      fontFamily:
                                                        'Inter_600SemiBold',
                                                      marginBottom: 5,
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {'Accent'}
                                              </Text>
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    backgroundColor:
                                                      fetchData?.Accent_color,
                                                    borderRadius: 9,
                                                    borderWidth: 0.5,
                                                    height: 90,
                                                    width: 90,
                                                  },
                                                  dimensions.width
                                                )}
                                              />
                                              {/* Text 2 */}
                                              <Text
                                                accessible={true}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'],
                                                    {
                                                      fontFamily:
                                                        'Inter_600SemiBold',
                                                      marginBottom: 5,
                                                      marginTop: 5,
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {fetchData?.Accent_color}
                                              </Text>
                                            </View>
                                          </View>

                                          <View>
                                            <Text
                                              accessible={true}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    fontFamily:
                                                      'Inter_600SemiBold',
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {'Notes:'}
                                            </Text>
                                            {/* Text 2 */}
                                            <Text
                                              accessible={true}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  { marginTop: 5 }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {fetchData?.Additional_Notes}
                                            </Text>
                                          </View>
                                        </View>
                                      );
                                    }}
                                  </Web$AppServicesApi.FetchGetAllBrandingGET>
                                  <Button
                                    onPress={() => {
                                      try {
                                        navigation.navigate(
                                          'WebsiteBuildInfo3Screen'
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ButtonStyles(theme)[
                                          'Button'
                                        ],
                                        {
                                          backgroundColor: 'rgba(0, 0, 0, 0)',
                                          borderColor:
                                            theme.colors['Akurie Dark Blue'],
                                          borderRadius: 9,
                                          borderWidth: 1,
                                          color:
                                            theme.colors['Akurie Dark Blue'],
                                          fontFamily: 'Inter_700Bold',
                                          marginTop: 20,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                    title={'Update Branding'}
                                  />
                                </View>
                              )}
                            </>
                          </>
                        );
                      }}
                    </Web$AppServicesApi.FetchBrandingCheckGET>
                  )}
                </>
                {/* Market & Purpose */}
                <Touchable
                  onPress={() => {
                    try {
                      if (checkbox3Value) {
                        setCheckbox3Value(false);
                      } else {
                        setCheckbox3Value(true);
                      }
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      width: [
                        { minWidth: Breakpoints.Mobile, value: '100%' },
                        { minWidth: Breakpoints.Tablet, value: '99%' },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  {/* Banner */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: theme.colors['Akurie Primary'],
                        borderRadius: 15,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        margin: 5,
                        paddingLeft: 20,
                        paddingRight: 20,
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={theme.colors['Akurie Dark Blue']}
                      name={'Ionicons/megaphone-outline'}
                      size={45}
                    />
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          borderRadius: 15,
                          height: 80,
                          justifyContent: 'center',
                          paddingBottom: 10,
                          paddingTop: 10,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Title */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Strong_Inverse'],
                            fontFamily: 'Inter_500Medium',
                            fontSize: 17,
                            textAlign: 'left',
                          },
                          dimensions.width
                        )}
                      >
                        {'Business Purpose'}
                      </Text>
                    </View>
                    {/* Checkbox3 */}
                    <Checkbox
                      checkedIcon={'AntDesign/closecircle'}
                      color={theme.colors['Akurie green']}
                      onPress={newCheckbox3Value => {
                        try {
                          setCheckbox3Value(newCheckbox3Value);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      size={35}
                      status={checkbox3Value}
                      uncheckedColor={theme.colors['Akurie green']}
                      uncheckedIcon={'AntDesign/downcircle'}
                    />
                  </View>
                </Touchable>
                {/* View 3 */}
                <>
                  {!checkbox3Value ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: theme.colors['Background'],
                          borderRadius: 15,
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          marginLeft: 5,
                          marginRight: 5,
                          padding: 30,
                          paddingLeft: {
                            minWidth: Breakpoints.Tablet,
                            value: 80,
                          },
                          paddingRight: {
                            minWidth: Breakpoints.Tablet,
                            value: 80,
                          },
                          width: [
                            { minWidth: Breakpoints.Tablet, value: '100%' },
                            { minWidth: Breakpoints.Mobile, value: '100%' },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          { width: '100%' },
                          dimensions.width
                        )}
                      >
                        {/* Fetch 2 */}
                        <BusinessDataAkurieApi.FetchGetBusinessPurposeGET
                          userId={Constants['ak_userID']}
                        >
                          {({
                            loading,
                            error,
                            data,
                            refetchGetBusinessPurpose,
                          }) => {
                            const fetch2Data = data?.json;
                            if (loading) {
                              return <ActivityIndicator />;
                            }

                            if (
                              error ||
                              data?.status < 200 ||
                              data?.status >= 300
                            ) {
                              return <ActivityIndicator />;
                            }

                            return (
                              <>
                                {/* Text 2 */}
                                <Text
                                  accessible={true}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      {
                                        color: theme.colors['Akurie Dark Blue'],
                                        fontFamily: 'Inter_600SemiBold',
                                        fontSize: 18,
                                        marginBottom: 5,
                                        marginTop: 20,
                                        textAlign: 'center',
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'Target Customers'}
                                </Text>
                                <Divider
                                  color={theme.colors['Akurie Primary']}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.DividerStyles(theme)[
                                        'Divider'
                                      ],
                                      { width: '100%' }
                                    ),
                                    dimensions.width
                                  )}
                                />
                                <FlatList
                                  contentContainerStyle={StyleSheet.applyWidth(
                                    { padding: 10 },
                                    dimensions.width
                                  )}
                                  data={fetch2Data?.target_MarketGroups}
                                  keyExtractor={(listData, index) =>
                                    listData?.id ??
                                    listData?.uuid ??
                                    index.toString()
                                  }
                                  keyboardShouldPersistTaps={'never'}
                                  listKey={'YxRahJ4A'}
                                  numColumns={1}
                                  onEndReachedThreshold={0.5}
                                  renderItem={({ item, index }) => {
                                    const listData = item;
                                    return (
                                      <Surface
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.SurfaceStyles(theme)[
                                              'Surface'
                                            ],
                                            {
                                              borderRadius: 9,
                                              marginBottom: 5,
                                              marginTop: 5,
                                              padding: 15,
                                              width: '100%',
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              flexDirection: 'row',
                                              justifyContent: 'space-between',
                                              padding: 10,
                                              width: '100%',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <View
                                            style={StyleSheet.applyWidth(
                                              { width: '100%' },
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              accessible={true}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    fontFamily: 'Inter_700Bold',
                                                    fontSize: 16,
                                                    paddingBottom: 10,
                                                    paddingTop: 10,
                                                    textAlign: 'center',
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {listData?.Group}
                                            </Text>
                                            {/* Text 2 */}
                                            <Text
                                              accessible={true}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    fontFamily:
                                                      'Inter_600SemiBold',
                                                    marginTop: 10,
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {'Sub Groups: '}
                                            </Text>
                                            {/* Text 3 */}
                                            <Text
                                              accessible={true}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  { marginTop: 10 }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {listData?.subGroups}
                                            </Text>
                                            <Divider
                                              color={theme.colors.divider}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.DividerStyles(
                                                    theme
                                                  )['Divider'],
                                                  {
                                                    marginBottom: 10,
                                                    marginTop: 10,
                                                    width: '100%',
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            />
                                            {/* Text 4 */}
                                            <Text
                                              accessible={true}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    fontFamily:
                                                      'Inter_500Medium',
                                                    marginTop: 10,
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {listData?.Description}
                                            </Text>
                                          </View>
                                        </View>
                                      </Surface>
                                    );
                                  }}
                                  showsHorizontalScrollIndicator={true}
                                  showsVerticalScrollIndicator={true}
                                />
                              </>
                            );
                          }}
                        </BusinessDataAkurieApi.FetchGetBusinessPurposeGET>
                        <BusinessDataAkurieApi.FetchGetBusinessPurposeGET
                          userId={Constants['ak_userID']}
                        >
                          {({
                            loading,
                            error,
                            data,
                            refetchGetBusinessPurpose,
                          }) => {
                            const fetchData = data?.json;
                            if (loading) {
                              return <ActivityIndicator />;
                            }

                            if (
                              error ||
                              data?.status < 200 ||
                              data?.status >= 300
                            ) {
                              return <ActivityIndicator />;
                            }

                            return (
                              <>
                                {/* Text 2 */}
                                <Text
                                  accessible={true}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      {
                                        color: theme.colors['Akurie Dark Blue'],
                                        fontFamily: 'Inter_600SemiBold',
                                        fontSize: 18,
                                        marginBottom: 5,
                                        marginTop: 20,
                                        textAlign: 'center',
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'Problems '}
                                  {fetchData?.business_Name}
                                  {' Solves'}
                                </Text>
                                <Divider
                                  color={theme.colors['Akurie Primary']}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.DividerStyles(theme)[
                                        'Divider'
                                      ],
                                      { width: '100%' }
                                    ),
                                    dimensions.width
                                  )}
                                />
                                <FlatList
                                  contentContainerStyle={StyleSheet.applyWidth(
                                    { padding: 10 },
                                    dimensions.width
                                  )}
                                  data={fetchData?.problem_Solving}
                                  keyExtractor={(listData, index) =>
                                    listData?.id ??
                                    listData?.uuid ??
                                    index.toString()
                                  }
                                  keyboardShouldPersistTaps={'never'}
                                  listKey={'wDAlNIMc'}
                                  numColumns={1}
                                  onEndReachedThreshold={0.5}
                                  renderItem={({ item, index }) => {
                                    const listData = item;
                                    return (
                                      <Surface
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.SurfaceStyles(theme)[
                                              'Surface'
                                            ],
                                            {
                                              borderRadius: 9,
                                              marginBottom: 5,
                                              marginTop: 5,
                                              padding: 15,
                                              width: '100%',
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              flexDirection: 'column',
                                              justifyContent: 'space-between',
                                              padding: 10,
                                              width: '100%',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <View
                                            style={StyleSheet.applyWidth(
                                              { width: '90%' },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Text 2 */}
                                            <Text
                                              accessible={true}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    fontFamily:
                                                      'Inter_600SemiBold',
                                                    marginTop: 10,
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {listData?.id}
                                              {'. Problem'}
                                            </Text>

                                            <Text
                                              accessible={true}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    fontFamily:
                                                      'Inter_500Medium',
                                                    paddingBottom: 10,
                                                    paddingRight: 20,
                                                    paddingTop: 10,
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {listData?.problem}
                                            </Text>
                                          </View>
                                        </View>
                                      </Surface>
                                    );
                                  }}
                                  showsHorizontalScrollIndicator={true}
                                  showsVerticalScrollIndicator={true}
                                />
                              </>
                            );
                          }}
                        </BusinessDataAkurieApi.FetchGetBusinessPurposeGET>
                      </View>
                    </View>
                  )}
                </>
                {/* Registered Agent */}
                <Touchable
                  onPress={() => {
                    try {
                      if (checkbox4Value) {
                        setCheckbox4Value(false);
                      } else {
                        setCheckbox4Value(true);
                      }
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      width: [
                        { minWidth: Breakpoints.Mobile, value: '100%' },
                        { minWidth: Breakpoints.Tablet, value: '99%' },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  {/* Banner */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: theme.colors['Akurie Primary'],
                        borderRadius: 15,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        margin: 5,
                        paddingLeft: 20,
                        paddingRight: 20,
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={theme.colors['Akurie Dark Blue']}
                      name={'MaterialCommunityIcons/briefcase-account-outline'}
                      size={45}
                    />
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          borderRadius: 15,
                          height: 80,
                          justifyContent: 'center',
                          paddingBottom: 10,
                          paddingTop: 10,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Title */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Strong_Inverse'],
                            fontFamily: 'Inter_500Medium',
                            fontSize: 17,
                            textAlign: 'left',
                          },
                          dimensions.width
                        )}
                      >
                        {'Registered Agent'}
                      </Text>
                    </View>
                    {/* Checkbox4 */}
                    <Checkbox
                      checkedIcon={'AntDesign/upcircle'}
                      color={theme.colors['Akurie green']}
                      onPress={newCheckbox4Value => {
                        try {
                          setCheckbox4Value(newCheckbox4Value);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      size={35}
                      status={checkbox4Value}
                      uncheckedColor={theme.colors['Akurie green']}
                      uncheckedIcon={'AntDesign/downcircle'}
                    />
                  </View>
                </Touchable>
                {/* Content */}
                <>
                  {!checkbox4Value ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['Background'],
                          borderRadius: 15,
                          marginLeft: 5,
                          marginRight: 5,
                          paddingBottom: 20,
                          paddingLeft: 40,
                          paddingRight: 40,
                          paddingTop: 30,
                          width: {
                            minWidth: Breakpoints.Tablet,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <>
                        {ragent_status ? null : (
                          <View>
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    fontFamily: 'Inter_500Medium',
                                    marginBottom: 10,
                                    textAlign: 'center',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {"Setup your registered agent's info."}
                            </Text>
                          </View>
                        )}
                      </>
                      <>
                        {!ragent_status ? null : (
                          <BusinessDataAkurieApi.FetchGetRegisteredAgentGET
                            business_id={Constants['ak_businessID']}
                          >
                            {({
                              loading,
                              error,
                              data,
                              refetchGetRegisteredAgent,
                            }) => {
                              const fetchData = data?.json;
                              if (loading) {
                                return <ActivityIndicator />;
                              }

                              if (
                                error ||
                                data?.status < 200 ||
                                data?.status >= 300
                              ) {
                                return <ActivityIndicator />;
                              }

                              return (
                                <>
                                  {/* Content Details */}
                                  <View>
                                    {/* Registered Agent Name */}
                                    <Text
                                      style={StyleSheet.applyWidth(
                                        {
                                          color: theme.colors.strong,
                                          fontFamily: 'Inter_400Regular',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {'Registered Agent: '}
                                      {fetchData?.registered_agent_name}
                                    </Text>
                                    {/* Address */}
                                    <Text
                                      style={StyleSheet.applyWidth(
                                        {
                                          color: theme.colors.strong,
                                          fontFamily: 'Inter_400Regular',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {'Address: '}
                                      {fetchData?.Address}
                                    </Text>
                                    {/* Address 2 */}
                                    <Text
                                      style={StyleSheet.applyWidth(
                                        {
                                          color: theme.colors.strong,
                                          fontFamily: 'Inter_400Regular',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {'Address 2: '}
                                      {fetchData?.Address_2}
                                    </Text>
                                    {/* State */}
                                    <Text
                                      style={StyleSheet.applyWidth(
                                        {
                                          color: theme.colors.strong,
                                          fontFamily: 'Inter_400Regular',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {'State: '}
                                      {fetchData?.State}
                                    </Text>
                                    {/* City */}
                                    <Text
                                      style={StyleSheet.applyWidth(
                                        {
                                          color: theme.colors.strong,
                                          fontFamily: 'Inter_400Regular',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {'City: '}
                                      {fetchData?.City}
                                    </Text>
                                    {/* Zip Code */}
                                    <Text
                                      style={StyleSheet.applyWidth(
                                        {
                                          color: theme.colors.strong,
                                          fontFamily: 'Inter_400Regular',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {'Zip Code: '}
                                      {fetchData?.zip_code}
                                    </Text>
                                    <Spacer
                                      bottom={8}
                                      left={8}
                                      right={8}
                                      top={8}
                                    />
                                  </View>
                                </>
                              );
                            }}
                          </BusinessDataAkurieApi.FetchGetRegisteredAgentGET>
                        )}
                      </>
                      {/* Edit button */}
                      <>
                        {!ragent_status ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { alignItems: 'center', marginTop: 20 },
                              dimensions.width
                            )}
                          >
                            <Button
                              onPress={() => {
                                try {
                                  navigation.navigate(
                                    'RegisteredAgentInformationScreen'
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor: theme.colors['Background'],
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors['Custom Color_18'],
                                  borderLeftWidth: 1,
                                  borderRadius: 8,
                                  borderRightWidth: 1,
                                  borderTopWidth: 1,
                                  color: theme.colors['Custom Color_18'],
                                  fontFamily: 'System',
                                  fontWeight: '700',
                                  textAlign: 'center',
                                  width: 90,
                                },
                                dimensions.width
                              )}
                              title={'Manage'}
                            />
                          </View>
                        )}
                      </>
                      {/* Edit button 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', marginTop: 20 },
                          dimensions.width
                        )}
                      >
                        <>
                          {ragent_status ? null : (
                            <Button
                              onPress={() => {
                                try {
                                  navigation.navigate(
                                    'RegisteredAgentInformationScreen'
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor: theme.colors['Background'],
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors['Custom Color_18'],
                                  borderLeftWidth: 1,
                                  borderRadius: 8,
                                  borderRightWidth: 1,
                                  borderTopWidth: 1,
                                  color: theme.colors['Custom Color_18'],
                                  fontFamily: 'System',
                                  fontWeight: '700',
                                  textAlign: 'center',
                                  width: 120,
                                },
                                dimensions.width
                              )}
                              title={'Setup Info'}
                            />
                          )}
                        </>
                      </View>
                    </View>
                  )}
                </>
                {/* Ownership Details */}
                <Touchable
                  onPress={() => {
                    try {
                      if (checkbox5Value) {
                        setCheckbox5Value(false);
                      } else {
                        setCheckbox5Value(true);
                      }
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      width: [
                        { minWidth: Breakpoints.Mobile, value: '100%' },
                        { minWidth: Breakpoints.Tablet, value: '99%' },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  {/* Banner */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: theme.colors['Akurie Primary'],
                        borderRadius: 15,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        margin: 5,
                        paddingLeft: 20,
                        paddingRight: 20,
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={theme.colors['Akurie Dark Blue']}
                      name={'Feather/users'}
                      size={45}
                    />
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          borderRadius: 15,
                          height: 80,
                          justifyContent: 'center',
                          paddingBottom: 10,
                          paddingTop: 10,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Title */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Strong_Inverse'],
                            fontFamily: 'Inter_500Medium',
                            fontSize: 17,
                            textAlign: 'left',
                          },
                          dimensions.width
                        )}
                      >
                        {'Ownership Details'}
                      </Text>
                    </View>
                    {/* Checkbox5 */}
                    <Checkbox
                      checkedIcon={'AntDesign/closecircle'}
                      color={theme.colors['Akurie green']}
                      onPress={newCheckbox5Value => {
                        try {
                          setCheckbox5Value(newCheckbox5Value);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      size={35}
                      status={checkbox5Value}
                      uncheckedColor={theme.colors['Akurie green']}
                      uncheckedIcon={'AntDesign/downcircle'}
                    />
                  </View>
                </Touchable>
                <>
                  {!checkbox5Value ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['Background'],
                          borderRadius: 15,
                          marginLeft: 5,
                          marginRight: 5,
                          paddingBottom: 40,
                          paddingLeft: 40,
                          paddingRight: 40,
                          paddingTop: 30,
                          width: {
                            minWidth: Breakpoints.Tablet,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      {/* View 2 */}
                      <>
                        {opStatus ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { width: '100%' },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  alignSelf: 'center',
                                  color: theme.colors['Strong'],
                                  fontFamily: 'Inter_500Medium',
                                  fontSize: 14,
                                  paddingLeft: 40,
                                  paddingRight: 40,
                                  textAlign: 'left',
                                },
                                dimensions.width
                              )}
                            >
                              {'Setup entity ownership info.'}
                            </Text>
                          </View>
                        )}
                      </>
                      <BusinessDataAkurieApi.FetchGetOwnersGET
                        business_id={Constants['ak_businessID']}
                      >
                        {({ loading, error, data, refetchGetOwners }) => {
                          const fetchData = data?.json;
                          if (loading) {
                            return <ActivityIndicator />;
                          }

                          if (
                            error ||
                            data?.status < 200 ||
                            data?.status >= 300
                          ) {
                            return <ActivityIndicator />;
                          }

                          return (
                            <FlatList
                              data={fetchData?.List_Of_Owners}
                              keyExtractor={(listData, index) =>
                                listData?.id ??
                                listData?.uuid ??
                                index.toString()
                              }
                              listKey={'wxN0ufQC'}
                              numColumns={1}
                              onEndReachedThreshold={0.5}
                              renderItem={({ item, index }) => {
                                const listData = item;
                                return (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        marginBottom: 30,
                                        marginTop: 30,
                                        paddingLeft: 20,
                                        paddingRight: 20,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <>
                                      {!listData?.Business__Other_Entity ? null : (
                                        <Icon
                                          color={theme.colors['Akurie Primary']}
                                          name={'FontAwesome/building'}
                                          size={32}
                                          style={StyleSheet.applyWidth(
                                            { marginRight: 10 },
                                            dimensions.width
                                          )}
                                        />
                                      )}
                                    </>
                                    <>
                                      {!listData?.Natural_Person ? null : (
                                        <Icon
                                          color={theme.colors['Akurie Primary']}
                                          name={'FontAwesome/user'}
                                          size={32}
                                          style={StyleSheet.applyWidth(
                                            { marginRight: 10 },
                                            dimensions.width
                                          )}
                                        />
                                      )}
                                    </>
                                    <View>
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          {
                                            color: theme.colors.strong,
                                            fontFamily: 'Inter_500Medium',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {'Owner Name: '}
                                        {listData?.First_Name}{' '}
                                        {listData?.Last_Name}
                                        {listData?.Entity_Owner_Name}
                                      </Text>

                                      <Text
                                        style={StyleSheet.applyWidth(
                                          {
                                            color: theme.colors.strong,
                                            fontFamily: 'Inter_400Regular',
                                            marginBottom: 5,
                                            marginTop: 5,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {'Ownership Percentage: %'}
                                        {listData?.Ownership_Percentage}
                                      </Text>

                                      <Text
                                        style={StyleSheet.applyWidth(
                                          {
                                            color: theme.colors.strong,
                                            fontFamily: 'Inter_400Regular',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {'Address: '}
                                        {listData?.Address}
                                        {'\n'}
                                        {listData?.Address_2} {listData?.City}
                                        {', '}
                                        {listData?.State} {listData?.zip_code}
                                      </Text>
                                    </View>
                                  </View>
                                );
                              }}
                              showsHorizontalScrollIndicator={true}
                              showsVerticalScrollIndicator={true}
                            />
                          );
                        }}
                      </BusinessDataAkurieApi.FetchGetOwnersGET>
                      <>
                        {!opStatus ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { alignItems: 'center', marginTop: 20 },
                              dimensions.width
                            )}
                          >
                            <Button
                              onPress={() => {
                                try {
                                  navigation.navigate(
                                    'OwnershipInformationScreen'
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor: theme.colors['Background'],
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors['Custom Color_18'],
                                  borderLeftWidth: 1,
                                  borderRadius: 8,
                                  borderRightWidth: 1,
                                  borderTopWidth: 1,
                                  color: theme.colors['Custom Color_18'],
                                  fontFamily: 'System',
                                  fontWeight: '700',
                                  textAlign: 'center',
                                  width: 90,
                                },
                                dimensions.width
                              )}
                              title={'Manage'}
                            />
                          </View>
                        )}
                      </>
                      {/* View 2 */}
                      <>
                        {opStatus ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { alignItems: 'center', marginTop: 20 },
                              dimensions.width
                            )}
                          >
                            <Button
                              onPress={() => {
                                try {
                                  navigation.navigate(
                                    'OwnershipInformationScreen'
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor: theme.colors['Background'],
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors['Custom Color_18'],
                                  borderLeftWidth: 1,
                                  borderRadius: 8,
                                  borderRightWidth: 1,
                                  borderTopWidth: 1,
                                  color: theme.colors['Custom Color_18'],
                                  fontFamily: 'System',
                                  fontWeight: '700',
                                  textAlign: 'center',
                                  width: 120,
                                },
                                dimensions.width
                              )}
                              title={'Setup Info'}
                            />
                          </View>
                        )}
                      </>
                    </View>
                  )}
                </>
              </View>
            </View>
            <Spacer bottom={90} left={8} right={8} top={80} />
          </ScrollView>
        </View>
      </ImageBackground>
      {/* loading_Circle */}
      <>
        {!Constants['ak_isLoading'] ? null : (
          <BlurView
            intensity={80}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.BlurViewStyles(theme)['Blur View'],
                {
                  alignItems: 'center',
                  height: '100%',
                  justifyContent: 'center',
                  position: 'absolute',
                  width: '100%',
                  zIndex: 10,
                }
              ),
              dimensions.width
            )}
            tint={'dark'}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: theme.colors['Akurie Dark Blue'],
                  borderRadius: 19,
                  bottom: 50,
                  height: [
                    { minWidth: Breakpoints.Mobile, value: '40%' },
                    { minWidth: Breakpoints.Laptop, value: '60%' },
                  ],
                  justifyContent: 'center',
                  opacity: 1,
                  width: [
                    { minWidth: Breakpoints.Mobile, value: '70%' },
                    { minWidth: Breakpoints.Tablet, value: '50%' },
                    { minWidth: Breakpoints.Desktop, value: '40%' },
                  ],
                },
                dimensions.width
              )}
            >
              <CircularProgress
                animationDuration={7000}
                color={theme.colors['Akurie green']}
                indeterminate={true}
                isAnimated={true}
                lineCap={'round'}
                showTrack={true}
                startPosition={'bottom'}
                style={StyleSheet.applyWidth(
                  { bottom: -20, height: '80%', width: '80%' },
                  dimensions.width
                )}
                thickness={15}
                trackColor={theme.colors.divider}
                trackLineCap={'round'}
              />
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Strong Inverse'],
                    fontFamily: 'Inter_600SemiBold',
                    fontSize: 20,
                    marginTop: 10,
                  }),
                  dimensions.width
                )}
              >
                {'Loading Business'}
              </Text>
            </View>
          </BlurView>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(MyBusinessInfoScreen);
