import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BusinessDataAkurieApi from '../apis/BusinessDataAkurieApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Checkbox,
  Circle,
  Icon,
  IconButton,
  Picker,
  ScreenContainer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import * as Linking from 'expo-linking';
import {
  FlatList,
  Image,
  ImageBackground,
  Keyboard,
  Text,
  View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const BusinessLLCSetupInfo2Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [additonalManagersView, setAdditonalManagersView] =
    React.useState(false);
  const [addr1Value, setAddr1Value] = React.useState('');
  const [addr2Value, setAddr2Value] = React.useState('');
  const [cityValue, setCityValue] = React.useState('');
  const [errorMsg, setErrorMsg] = React.useState('');
  const [fullNameValue, setFullNameValue] = React.useState('');
  const [managementStructureValue, setManagementStructureValue] =
    React.useState('');
  const [stateValue, setStateValue] = React.useState('');
  const [zipCodeValue, setZipCodeValue] = React.useState('');
  const businessDataAkurieAddBusManagersPOST =
    BusinessDataAkurieApi.useAddBusManagersPOST();
  const businessDataAkurieSetManagementTypePOST =
    BusinessDataAkurieApi.useSetManagementTypePOST();

  return (
    <ScreenContainer
      hasBottomSafeArea={false}
      hasSafeArea={false}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: theme.colors['Background'],
          justifyContent: 'space-between',
        },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'cover'}
        source={Images.Asset6}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ImageBackgroundStyles(theme)['Image Background'],
            { height: '100%', justifyContent: 'center' }
          ),
          dimensions.width
        )}
      >
        <KeyboardAwareScrollView
          contentContainerStyle={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              justifyContent: { minWidth: Breakpoints.Tablet, value: 'center' },
              marginTop: 10,
              paddingLeft: [
                { minWidth: Breakpoints.Tablet, value: '10%' },
                { minWidth: Breakpoints.Laptop, value: '0%' },
              ],
              paddingRight: [
                { minWidth: Breakpoints.Laptop, value: '0%' },
                { minWidth: Breakpoints.Tablet, value: '10%' },
              ],
              paddingTop: [
                { minWidth: Breakpoints.Tablet, value: 50 },
                { minWidth: Breakpoints.Laptop, value: 0 },
                { minWidth: Breakpoints.Desktop, value: 40 },
              ],
            },
            dimensions.width
          )}
          enableAutomaticScroll={true}
          enableOnAndroid={false}
          enableResetScrollToCoords={false}
          extraHeight={100}
          extraScrollHeight={100}
          keyboardShouldPersistTaps={'always'}
          showsVerticalScrollIndicator={false}
        >
          {/* View 2 */}
          <>
            {!(dimensions.width >= Breakpoints.Laptop) ? null : (
              <View
                style={StyleSheet.applyWidth(
                  { width: { minWidth: Breakpoints.Laptop, value: '80%' } },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'contain'}
                  source={Images.MainLogoUpdate2u}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'],
                      {
                        height: { minWidth: Breakpoints.Laptop, value: 50 },
                        width: { minWidth: Breakpoints.Laptop, value: '30%' },
                      }
                    ),
                    dimensions.width
                  )}
                />
              </View>
            )}
          </>
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: [
                  { minWidth: Breakpoints.Laptop, value: 'center' },
                  { minWidth: Breakpoints.Mobile, value: 'center' },
                ],
                flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
                justifyContent: [
                  { minWidth: Breakpoints.Laptop, value: 'center' },
                  { minWidth: Breakpoints.Mobile, value: 'center' },
                ],
                marginTop: [
                  { minWidth: Breakpoints.Desktop, value: -20 },
                  { minWidth: Breakpoints.BigScreen, value: -100 },
                  { minWidth: Breakpoints.Laptop, value: -40 },
                ],
                width: '100%',
              },
              dimensions.width
            )}
          >
            {/* Master Form View 2 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: [
                        {
                          minWidth: Breakpoints.Tablet,
                          value: theme.colors['Akurie Dark Blue'],
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors['Akurie Dark Blue'],
                        },
                      ],
                      borderBottomLeftRadius: {
                        minWidth: Breakpoints.Laptop,
                        value: 12,
                      },
                      borderRadius: [
                        { minWidth: Breakpoints.Tablet, value: 12 },
                        { minWidth: Breakpoints.Mobile, value: 12 },
                        { minWidth: Breakpoints.Laptop, value: 0 },
                      ],
                      borderTopLeftRadius: {
                        minWidth: Breakpoints.Laptop,
                        value: 12,
                      },
                      height: [
                        { minWidth: Breakpoints.Tablet, value: '80%' },
                        { minWidth: Breakpoints.Laptop, value: '85%' },
                        { minWidth: Breakpoints.Desktop, value: '70%' },
                        { minWidth: Breakpoints.BigScreen, value: '70%' },
                      ],
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      marginBottom: [
                        { minWidth: Breakpoints.Mobile, value: 80 },
                        { minWidth: Breakpoints.Laptop, value: 20 },
                      ],
                      marginTop: [
                        { minWidth: Breakpoints.Mobile, value: 80 },
                        { minWidth: Breakpoints.Laptop, value: 80 },
                      ],
                      opacity: [
                        { minWidth: Breakpoints.Tablet, value: 0.95 },
                        { minWidth: Breakpoints.Mobile, value: 0.95 },
                      ],
                      padding: [
                        { minWidth: Breakpoints.Tablet, value: 20 },
                        { minWidth: Breakpoints.Mobile, value: 10 },
                        { minWidth: Breakpoints.BigScreen, value: 40 },
                      ],
                      paddingLeft: [
                        { minWidth: Breakpoints.Mobile, value: 20 },
                        { minWidth: Breakpoints.Desktop, value: 40 },
                        { minWidth: Breakpoints.Laptop, value: 10 },
                        { minWidth: Breakpoints.BigScreen, value: 60 },
                      ],
                      paddingRight: [
                        { minWidth: Breakpoints.Mobile, value: 20 },
                        { minWidth: Breakpoints.Desktop, value: 40 },
                        { minWidth: Breakpoints.Laptop, value: 10 },
                        { minWidth: Breakpoints.BigScreen, value: 60 },
                      ],
                      width: [
                        { minWidth: Breakpoints.Mobile, value: '90%' },
                        { minWidth: Breakpoints.Laptop, value: '30%' },
                        { minWidth: Breakpoints.Desktop, value: '35%' },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        marginBottom: 10,
                        marginTop: 10,
                        padding: 5,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <>
                      {!(dimensions.width >= Breakpoints.Tablet) ? null : (
                        <IconButton
                          color={theme.colors['Akurie green']}
                          icon={'MaterialCommunityIcons/arrow-left-circle'}
                          onPress={() => {
                            try {
                              navigation.goBack();
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          size={40}
                          style={StyleSheet.applyWidth(
                            { marginRight: 10 },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Strong_Inverse'],
                            fontFamily: 'Inter_400Regular',
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 22 },
                              { minWidth: Breakpoints.Laptop, value: 18 },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Go Back'}
                    </Text>
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: [
                          { minWidth: Breakpoints.Mobile, value: 'row' },
                          { minWidth: Breakpoints.Laptop, value: 'column' },
                        ],
                        justifyContent: 'center',
                        marginBottom: 10,
                        marginTop: 10,
                        padding: 5,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: [
                              {
                                minWidth: Breakpoints.Mobile,
                                value: theme.colors['Strong_Inverse'],
                              },
                              {
                                minWidth: Breakpoints.Laptop,
                                value: theme.colors['Akurie Primary'],
                              },
                            ],
                            fontFamily: [
                              {
                                minWidth: Breakpoints.Mobile,
                                value: 'Inter_400Regular',
                              },
                              {
                                minWidth: Breakpoints.Laptop,
                                value: 'Inter_400Regular',
                              },
                              {
                                minWidth: Breakpoints.BigScreen,
                                value: 'Inter_600SemiBold',
                              },
                            ],
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 22 },
                              { minWidth: Breakpoints.Laptop, value: 32 },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Articles Of Organization'}
                    </Text>
                    {/* Text 2 */}
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: [
                              {
                                minWidth: Breakpoints.Mobile,
                                value: theme.colors['Strong_Inverse'],
                              },
                              {
                                minWidth: Breakpoints.Laptop,
                                value: theme.colors['Akurie green'],
                              },
                            ],
                            fontFamily: [
                              {
                                minWidth: Breakpoints.Mobile,
                                value: 'Inter_400Regular',
                              },
                              {
                                minWidth: Breakpoints.Laptop,
                                value: 'Inter_400Regular',
                              },
                              {
                                minWidth: Breakpoints.BigScreen,
                                value: 'Inter_400Regular',
                              },
                            ],
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 22 },
                              { minWidth: Breakpoints.Laptop, value: 18 },
                            ],
                            marginTop: {
                              minWidth: Breakpoints.Laptop,
                              value: 10,
                            },
                            textAlign: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        'This information will be used in the documents we file with the state to bring your business to life.'
                      }
                    </Text>
                  </View>
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        marginBottom: {
                          minWidth: Breakpoints.Laptop,
                          value: 10,
                        },
                        marginTop: { minWidth: Breakpoints.Laptop, value: 10 },
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'contain'}
                      source={Images.Asset29}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: 150,
                            },
                            width: { minWidth: Breakpoints.Laptop, value: 150 },
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                </View>
              )}
            </>
            {/* Master Form View */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: [
                    {
                      minWidth: Breakpoints.Tablet,
                      value: theme.colors['Akurie Dark Blue'],
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: theme.colors['Akurie Dark Blue'],
                    },
                  ],
                  borderColor: {
                    minWidth: Breakpoints.Laptop,
                    value: theme.colors['Medium'],
                  },
                  borderLeftWidth: { minWidth: Breakpoints.Laptop, value: 0.5 },
                  borderRadius: [
                    { minWidth: Breakpoints.Tablet, value: 12 },
                    { minWidth: Breakpoints.Mobile, value: 12 },
                    { minWidth: Breakpoints.Laptop, value: 12 },
                  ],
                  height: [
                    { minWidth: Breakpoints.Tablet, value: '80%' },
                    { minWidth: Breakpoints.BigScreen, value: '95%' },
                    { minWidth: Breakpoints.Laptop, value: '100%' },
                  ],
                  justifyContent: [
                    { minWidth: Breakpoints.BigScreen, value: 'center' },
                    { minWidth: Breakpoints.Laptop, value: 'center' },
                  ],
                  marginBottom: [
                    { minWidth: Breakpoints.Mobile, value: 80 },
                    { minWidth: Breakpoints.Laptop, value: 20 },
                  ],
                  marginTop: [
                    { minWidth: Breakpoints.Mobile, value: 80 },
                    { minWidth: Breakpoints.Laptop, value: 80 },
                  ],
                  opacity: [
                    { minWidth: Breakpoints.Tablet, value: 0.95 },
                    { minWidth: Breakpoints.Mobile, value: 0.95 },
                  ],
                  padding: [
                    { minWidth: Breakpoints.Tablet, value: 20 },
                    { minWidth: Breakpoints.Mobile, value: 10 },
                    { minWidth: Breakpoints.BigScreen, value: 40 },
                  ],
                  paddingBottom: 20,
                  paddingLeft: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.BigScreen, value: 40 },
                    { minWidth: Breakpoints.Desktop, value: 40 },
                    { minWidth: Breakpoints.Laptop, value: 20 },
                  ],
                  paddingRight: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.BigScreen, value: 40 },
                    { minWidth: Breakpoints.Desktop, value: 40 },
                    { minWidth: Breakpoints.Laptop, value: 20 },
                  ],
                  width: [
                    { minWidth: Breakpoints.Mobile, value: '90%' },
                    { minWidth: Breakpoints.Laptop, value: '30%' },
                  ],
                },
                dimensions.width
              )}
            >
              <>
                {dimensions.width >= Breakpoints.Laptop ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        marginBottom: 10,
                        marginTop: 10,
                        padding: 5,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <IconButton
                      color={theme.colors['Akurie Primary']}
                      icon={'MaterialCommunityIcons/arrow-left-circle'}
                      onPress={() => {
                        try {
                          navigation.goBack();
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      size={40}
                      style={StyleSheet.applyWidth(
                        { marginRight: 10 },
                        dimensions.width
                      )}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Strong_Inverse'],
                            fontFamily: 'Inter_400Regular',
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 22 },
                              { minWidth: Breakpoints.Laptop, value: 18 },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Creating Your LLC'}
                    </Text>
                  </View>
                )}
              </>
              <>
                {dimensions.width >= Breakpoints.Laptop ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: 'row',
                        justifyContent: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    {/* First Name View */}
                    <View
                      style={StyleSheet.applyWidth(
                        { paddingBottom: 5, paddingTop: 5, width: '100%' },
                        dimensions.width
                      )}
                    >
                      {/* First Name */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Akurie green'],
                            fontFamily: 'Inter_600SemiBold',
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 16 },
                              { minWidth: Breakpoints.Laptop, value: 14 },
                            ],
                          },
                          dimensions.width
                        )}
                      >
                        {'Articles of Organization Info'}
                      </Text>
                      {/* First Name 2 */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Strong Inverse'],
                            fontFamily: 'Inter_400Regular',
                            fontSize: [
                              { minWidth: Breakpoints.Laptop, value: 14 },
                              { minWidth: Breakpoints.Mobile, value: 14 },
                            ],
                            marginTop: 5,
                            textAlign: 'left',
                          },
                          dimensions.width
                        )}
                      >
                        {
                          'This information will be used in the documents we file with the state.'
                        }
                      </Text>
                    </View>
                  </View>
                )}
              </>
              {/* Form */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'stretch',
                    marginTop: 10,
                    opacity: 1,
                    width: [
                      { minWidth: Breakpoints.Mobile, value: '100%' },
                      { minWidth: Breakpoints.Laptop, value: '95%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                {/* First Name 3 */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Akurie green'],
                      fontFamily: 'Inter_600SemiBold',
                      fontSize: [
                        { minWidth: Breakpoints.Laptop, value: 14 },
                        { minWidth: Breakpoints.Mobile, value: 16 },
                      ],
                      marginTop: 15,
                    },
                    dimensions.width
                  )}
                >
                  {'Management Structure'}
                </Text>
                {/* Title View */}
                <View
                  style={StyleSheet.applyWidth(
                    { paddingBottom: 5, paddingTop: 5 },
                    dimensions.width
                  )}
                >
                  {/* Title */}
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Strong Inverse'],
                        fontFamily: 'Inter_400Regular',
                        fontSize: { minWidth: Breakpoints.Laptop, value: 13 },
                        marginBottom: 10,
                        marginTop: 10,
                      },
                      dimensions.width
                    )}
                  >
                    {
                      'In a member-managed LLC, all members (owners) have the authority to make decisions on behalf of the company. \n\nIn a manager-managed LLC, certain members or designated managers are appointed to make decisions on behalf of the company.'
                    }
                  </Text>
                  {/* Management Structure */}
                  <Picker
                    iconColor={theme.colors['Akurie Primary']}
                    iconSize={24}
                    leftIconMode={'inset'}
                    onValueChange={newManagementStructureValue => {
                      try {
                        setManagementStructureValue(
                          newManagementStructureValue
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    options={['Member-Managed', 'Manager-Managed']}
                    placeholder={'Management Type'}
                    placeholderTextColor={theme.colors['Communial_Icon_Gray']}
                    rightIconName={'AntDesign/downcircle'}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Background'],
                        borderColor: theme.colors['Communial_Icon_Gray'],
                        borderRadius: 9,
                        color: theme.colors['Strong'],
                        fontFamily: 'Inter_400Regular',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 16 },
                          { minWidth: Breakpoints.Laptop, value: 14 },
                        ],
                        paddingRight: 15,
                      },
                      dimensions.width
                    )}
                    type={'solid'}
                    value={managementStructureValue}
                  />
                </View>
                {/* First Name 4 */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Akurie green'],
                      fontFamily: 'Inter_600SemiBold',
                      fontSize: [
                        { minWidth: Breakpoints.Laptop, value: 14 },
                        { minWidth: Breakpoints.Mobile, value: 16 },
                      ],
                      marginTop: 15,
                    },
                    dimensions.width
                  )}
                >
                  {'Managers'}
                </Text>
                {/* Name */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      marginBottom: { minWidth: Breakpoints.Desktop, value: 5 },
                      paddingBottom: 5,
                      paddingTop: 10,
                    },
                    dimensions.width
                  )}
                >
                  {/* Full Name */}
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Strong Inverse'],
                        fontFamily: 'Inter_600SemiBold',
                        fontSize: { minWidth: Breakpoints.Laptop, value: 13 },
                        marginBottom: 5,
                        marginTop: 5,
                      },
                      dimensions.width
                    )}
                  >
                    {'Last Name, First Name Middle Initial'}
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          { color: theme.colors['Error'] }
                        ),
                        dimensions.width
                      )}
                    >
                      {'*'}
                    </Text>
                  </Text>
                  {/* fullName */}
                  <TextInput
                    onChangeText={newFullNameValue => {
                      try {
                        setFullNameValue(newFullNameValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    onSubmitEditing={() => {
                      try {
                        /* 'Focus Text Input' action requires configuration: choose a target component */
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'ex. Dowe, John M'}
                    placeholderTextColor={theme.colors['Communial_Icon_Gray']}
                    secureTextEntry={false}
                    spellcheck={true}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Background'],
                        borderBottomWidth: 1,
                        borderColor: theme.colors['Communial_Icon_Gray'],
                        borderLeftWidth: 1,
                        borderRadius: 8,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        fontSize: 16,
                        height: [
                          { minWidth: Breakpoints.Mobile, value: 60 },
                          { minWidth: Breakpoints.Laptop, value: 60 },
                        ],
                        paddingBottom: 10,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 10,
                      },
                      dimensions.width
                    )}
                    textContentType={'password'}
                    value={fullNameValue}
                  />
                </View>
                {/* Password View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      marginBottom: { minWidth: Breakpoints.Desktop, value: 5 },
                      paddingBottom: 5,
                    },
                    dimensions.width
                  )}
                >
                  {/* Address */}
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Strong Inverse'],
                        fontFamily: 'Inter_600SemiBold',
                        fontSize: { minWidth: Breakpoints.Laptop, value: 13 },
                        marginBottom: 5,
                        marginTop: 5,
                      },
                      dimensions.width
                    )}
                  >
                    {'Address'}
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          { color: theme.colors['Error'] }
                        ),
                        dimensions.width
                      )}
                    >
                      {'*'}
                    </Text>
                  </Text>
                  {/* Addr1 */}
                  <TextInput
                    onChangeText={newAddr1Value => {
                      try {
                        setAddr1Value(newAddr1Value);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    onSubmitEditing={() => {
                      try {
                        Keyboard.dismiss();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'ex. 1234 Main Street'}
                    placeholderTextColor={theme.colors['Communial_Icon_Gray']}
                    secureTextEntry={false}
                    spellcheck={true}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Background'],
                        borderBottomWidth: 1,
                        borderColor: theme.colors['Communial_Icon_Gray'],
                        borderLeftWidth: 1,
                        borderRadius: 8,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        fontSize: 16,
                        height: [
                          { minWidth: Breakpoints.Mobile, value: 60 },
                          { minWidth: Breakpoints.Laptop, value: 60 },
                        ],
                        paddingBottom: 10,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 10,
                      },
                      dimensions.width
                    )}
                    textContentType={'password'}
                    value={addr1Value}
                  />
                </View>
                {/* Password View 7 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      marginBottom: { minWidth: Breakpoints.Desktop, value: 5 },
                      paddingBottom: 5,
                    },
                    dimensions.width
                  )}
                >
                  {/* Address */}
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Strong Inverse'],
                        fontFamily: 'Inter_600SemiBold',
                        fontSize: { minWidth: Breakpoints.Laptop, value: 13 },
                        marginBottom: 5,
                        marginTop: 5,
                      },
                      dimensions.width
                    )}
                  >
                    {'Address2'}
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          { color: theme.colors['Error'] }
                        ),
                        dimensions.width
                      )}
                    >
                      {'*'}
                    </Text>
                  </Text>
                  {/* Addr2 */}
                  <TextInput
                    onChangeText={newAddr2Value => {
                      try {
                        setAddr2Value(newAddr2Value);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    onSubmitEditing={() => {
                      try {
                        Keyboard.dismiss();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'ex. Flr, Suite, Apt, etc'}
                    placeholderTextColor={theme.colors['Communial_Icon_Gray']}
                    secureTextEntry={false}
                    spellcheck={true}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Background'],
                        borderBottomWidth: 1,
                        borderColor: theme.colors['Communial_Icon_Gray'],
                        borderLeftWidth: 1,
                        borderRadius: 8,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        fontSize: 16,
                        height: [
                          { minWidth: Breakpoints.Mobile, value: 60 },
                          { minWidth: Breakpoints.Laptop, value: 60 },
                        ],
                        paddingBottom: 10,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 10,
                      },
                      dimensions.width
                    )}
                    textContentType={'password'}
                    value={addr2Value}
                  />
                </View>
                {/* Password View 3 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      marginBottom: { minWidth: Breakpoints.Desktop, value: 5 },
                      paddingBottom: 5,
                    },
                    dimensions.width
                  )}
                >
                  {/* State */}
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Strong Inverse'],
                        fontFamily: 'Inter_600SemiBold',
                        fontSize: { minWidth: Breakpoints.Laptop, value: 13 },
                        marginBottom: 5,
                        marginTop: 5,
                      },
                      dimensions.width
                    )}
                  >
                    {'State'}
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          { color: theme.colors['Error'] }
                        ),
                        dimensions.width
                      )}
                    >
                      {'*'}
                    </Text>
                  </Text>
                  {/* State */}
                  <Picker
                    iconColor={theme.colors['Akurie Primary']}
                    iconSize={24}
                    leftIconMode={'inset'}
                    onValueChange={newStateValue => {
                      try {
                        setStateValue(newStateValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    options={Constants['ak_supportedStates']}
                    placeholder={'Select a State'}
                    placeholderTextColor={theme.colors['Communial_Icon_Gray']}
                    rightIconName={'AntDesign/downcircle'}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Background'],
                        borderColor: theme.colors['Communial_Icon_Gray'],
                        borderRadius: 9,
                        color: theme.colors['Strong'],
                        fontFamily: 'Inter_400Regular',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 16 },
                          { minWidth: Breakpoints.Laptop, value: 14 },
                        ],
                        paddingRight: 15,
                      },
                      dimensions.width
                    )}
                    type={'solid'}
                    value={stateValue}
                  />
                </View>
                {/* Password View 5 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      marginBottom: { minWidth: Breakpoints.Desktop, value: 5 },
                      paddingBottom: 5,
                    },
                    dimensions.width
                  )}
                >
                  {/* City */}
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Strong Inverse'],
                        fontFamily: 'Inter_600SemiBold',
                        fontSize: { minWidth: Breakpoints.Laptop, value: 13 },
                        marginBottom: 5,
                        marginTop: 5,
                      },
                      dimensions.width
                    )}
                  >
                    {'City'}
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          { color: theme.colors['Error'] }
                        ),
                        dimensions.width
                      )}
                    >
                      {'*'}
                    </Text>
                  </Text>
                  {/* City */}
                  <TextInput
                    onChangeText={newCityValue => {
                      try {
                        setCityValue(newCityValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    onSubmitEditing={() => {
                      try {
                        Keyboard.dismiss();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'ex.Chicago'}
                    placeholderTextColor={theme.colors['Communial_Icon_Gray']}
                    secureTextEntry={false}
                    spellcheck={true}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Background'],
                        borderBottomWidth: 1,
                        borderColor: theme.colors['Communial_Icon_Gray'],
                        borderLeftWidth: 1,
                        borderRadius: 8,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        fontSize: 16,
                        height: [
                          { minWidth: Breakpoints.Mobile, value: 60 },
                          { minWidth: Breakpoints.Laptop, value: 60 },
                        ],
                        paddingBottom: 10,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 10,
                      },
                      dimensions.width
                    )}
                    textContentType={'password'}
                    value={cityValue}
                  />
                </View>
                {/* Password View 4 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      marginBottom: [
                        { minWidth: Breakpoints.Mobile, value: 10 },
                        { minWidth: Breakpoints.Desktop, value: 5 },
                      ],
                      paddingBottom: 5,
                    },
                    dimensions.width
                  )}
                >
                  {/* Zip Code */}
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Strong Inverse'],
                        fontFamily: 'Inter_600SemiBold',
                        fontSize: { minWidth: Breakpoints.Laptop, value: 13 },
                        marginBottom: 5,
                        marginTop: 5,
                      },
                      dimensions.width
                    )}
                  >
                    {'Zip Code'}
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          { color: theme.colors['Error'] }
                        ),
                        dimensions.width
                      )}
                    >
                      {'*'}
                    </Text>
                  </Text>
                  {/* ZipCode */}
                  <TextInput
                    onChangeText={newZipCodeValue => {
                      try {
                        setZipCodeValue(newZipCodeValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    onSubmitEditing={() => {
                      try {
                        Keyboard.dismiss();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'ex. 60605'}
                    placeholderTextColor={theme.colors['Communial_Icon_Gray']}
                    secureTextEntry={false}
                    spellcheck={true}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Background'],
                        borderBottomWidth: 1,
                        borderColor: theme.colors['Communial_Icon_Gray'],
                        borderLeftWidth: 1,
                        borderRadius: 8,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        fontSize: 16,
                        height: [
                          { minWidth: Breakpoints.Mobile, value: 60 },
                          { minWidth: Breakpoints.Laptop, value: 60 },
                        ],
                        paddingBottom: 10,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 10,
                      },
                      dimensions.width
                    )}
                    textContentType={'password'}
                    value={zipCodeValue}
                  />
                </View>
                {/* Password View 6 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      marginBottom: [
                        { minWidth: Breakpoints.Mobile, value: 10 },
                        { minWidth: Breakpoints.Desktop, value: 5 },
                      ],
                      paddingBottom: 5,
                    },
                    dimensions.width
                  )}
                >
                  {/* Touchable Frame 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        width: [
                          { minWidth: Breakpoints.Mobile, value: '100%' },
                          { minWidth: Breakpoints.Laptop, value: '100%' },
                        ],
                        zIndex: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        const handler = async () => {
                          try {
                            const addMgrResponse = (
                              await businessDataAkurieAddBusManagersPOST.mutateAsync(
                                {
                                  addr1: addr1Value,
                                  addr2: addr2Value,
                                  city: cityValue,
                                  full_name: fullNameValue,
                                  state: stateValue,
                                  user_id: Constants['ak_userID'],
                                  zipCode: zipCodeValue,
                                }
                              )
                            )?.json;
                            setAdditonalManagersView(true);
                            setFullNameValue('');
                            setAddr1Value('');
                            setAddr2Value('');
                            setStateValue('');
                            setCityValue('');
                            setZipCodeValue('');
                            if (addMgrResponse?.message) {
                              return;
                            }
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderRadius: 0,
                          width: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      {/* CTA Frame */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            backgroundColor: [
                              {
                                minWidth: Breakpoints.Laptop,
                                value: theme.colors['Akurie green'],
                              },
                              {
                                minWidth: Breakpoints.Mobile,
                                value: theme.colors['Medium'],
                              },
                            ],
                            borderRadius: 12,
                            flexDirection: 'row',
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'space-between',
                            },
                            paddingBottom: 12,
                            paddingLeft: 18,
                            paddingRight: 18,
                            paddingTop: 12,
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            zIndex: 10,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Label Frame */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexGrow: 1,
                              flexShrink: 0,
                              justifyContent: 'center',
                              width: {
                                minWidth: Breakpoints.Laptop,
                                value: '100%',
                              },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Continue Label */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: [
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: theme.colors['Strong Inverse'],
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: theme.colors['Akurie Dark Blue'],
                                  },
                                ],
                                fontFamily: 'Inter_400Regular',
                                fontSize: [
                                  { minWidth: Breakpoints.Mobile, value: 15 },
                                  { minWidth: Breakpoints.Laptop, value: 13 },
                                ],
                                lineHeight: 21,
                                marginLeft: [
                                  { minWidth: Breakpoints.Mobile, value: 42 },
                                  { minWidth: Breakpoints.Desktop, value: 0 },
                                  { minWidth: Breakpoints.Laptop, value: 0 },
                                ],
                                textTransform: 'capitalize',
                              },
                              dimensions.width
                            )}
                          >
                            {'Add Another Manager'}
                          </Text>
                        </View>
                        {/* Icon Group Frame */}
                        <>
                          {dimensions.width >= Breakpoints.Laptop ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  marginLeft: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 10,
                                  },
                                  opacity: 1,
                                },
                                dimensions.width
                              )}
                            >
                              <Circle
                                bgColor={theme.colors['Custom Color_18']}
                                size={42}
                                style={StyleSheet.applyWidth(
                                  { backgroundColor: 'rgba(0, 0, 0, 0)' },
                                  dimensions.width
                                )}
                              >
                                {/* Icon Flex Frame */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexGrow: 0,
                                      flexShrink: 0,
                                      paddingBottom: 12,
                                      paddingLeft: 12,
                                      paddingRight: 12,
                                      paddingTop: 12,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Arrow Icon  */}
                                  <Icon
                                    color={theme.colors['Akurie green']}
                                    name={'AntDesign/plus'}
                                    size={18}
                                  />
                                </View>
                              </Circle>
                            </View>
                          )}
                        </>
                      </View>
                    </Touchable>
                  </View>
                </View>
              </View>
              {/* Button Holder View */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    marginBottom: [
                      { minWidth: Breakpoints.Tablet, value: 40 },
                      { minWidth: Breakpoints.Laptop, value: 0 },
                    ],
                    paddingBottom: 10,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                {/* Error Msg 2 */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Error'],
                      fontFamily: 'Inter_400Regular',
                      fontSize: { minWidth: Breakpoints.Laptop, value: 13 },
                      paddingBottom: 10,
                      paddingTop: 10,
                    },
                    dimensions.width
                  )}
                >
                  {errorMsg}
                </Text>
                {/* Button */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderRadius: 0,
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'row',
                      },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'space-evenly',
                      },
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* Touchable Frame */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        width: [
                          { minWidth: Breakpoints.Mobile, value: '100%' },
                          { minWidth: Breakpoints.Laptop, value: '50%' },
                        ],
                        zIndex: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        const handler = async () => {
                          try {
                            (
                              await businessDataAkurieAddBusManagersPOST.mutateAsync(
                                {
                                  addr1: addr1Value,
                                  addr2: addr2Value,
                                  city: cityValue,
                                  full_name: fullNameValue,
                                  state: stateValue,
                                  user_id: Constants['ak_userID'],
                                  zipCode: zipCodeValue,
                                }
                              )
                            )?.json;
                            const mgrTypeResult = (
                              await businessDataAkurieSetManagementTypePOST.mutateAsync(
                                {
                                  managementType: managementStructureValue,
                                  user_id: Constants['ak_userID'],
                                }
                              )
                            )?.json;
                            const ErrorMsgResults2 = mgrTypeResult?.message;
                            setErrorMsg(ErrorMsgResults2);
                            if (ErrorMsgResults2) {
                              return;
                            }
                            const managerResponse = (
                              await BusinessDataAkurieApi.managerCheckGET(
                                Constants,
                                { user_id: Constants['ak_userID'] }
                              )
                            )?.json;
                            const ErrorMsgResults = managerResponse?.message;
                            setErrorMsg(ErrorMsgResults);
                            if (ErrorMsgResults) {
                              return;
                            }
                            navigation.navigate('BusinessLLCSetupInfo3Screen', {
                              statePass: props.route?.params?.state ?? null,
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        { borderRadius: 0 },
                        dimensions.width
                      )}
                    >
                      {/* CTA Frame */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            backgroundColor: [
                              {
                                minWidth: Breakpoints.Laptop,
                                value: theme.colors['Akurie green'],
                              },
                              {
                                minWidth: Breakpoints.Mobile,
                                value: theme.colors['Akurie green'],
                              },
                            ],
                            borderRadius: 12,
                            flexDirection: 'row',
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'space-between',
                            },
                            paddingBottom: 12,
                            paddingLeft: 18,
                            paddingRight: 18,
                            paddingTop: 12,
                            zIndex: 10,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Label Frame */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexGrow: 1,
                              flexShrink: 0,
                              justifyContent: 'center',
                              width: {
                                minWidth: Breakpoints.Laptop,
                                value: '100%',
                              },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Continue Label */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: [
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: theme.colors['Akurie Dark Blue'],
                                  },
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: theme.colors['Akurie Dark Blue'],
                                  },
                                ],
                                fontFamily: 'Inter_400Regular',
                                fontSize: [
                                  { minWidth: Breakpoints.Mobile, value: 15 },
                                  { minWidth: Breakpoints.Laptop, value: 13 },
                                ],
                                lineHeight: 21,
                                marginLeft: [
                                  { minWidth: Breakpoints.Mobile, value: 42 },
                                  { minWidth: Breakpoints.Desktop, value: 0 },
                                  { minWidth: Breakpoints.Laptop, value: 0 },
                                ],
                                textTransform: 'uppercase',
                              },
                              dimensions.width
                            )}
                          >
                            {'Continue'}
                          </Text>
                        </View>
                        {/* Icon Group Frame */}
                        <>
                          {dimensions.width >= Breakpoints.Laptop ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  marginLeft: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 10,
                                  },
                                  opacity: 1,
                                },
                                dimensions.width
                              )}
                            >
                              <Circle
                                bgColor={theme.colors['Custom Color_18']}
                                size={42}
                              >
                                {/* Icon Flex Frame */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexGrow: 0,
                                      flexShrink: 0,
                                      paddingBottom: 12,
                                      paddingLeft: 12,
                                      paddingRight: 12,
                                      paddingTop: 12,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Arrow Icon  */}
                                  <Icon
                                    color={theme.colors['Akurie green']}
                                    name={'AntDesign/arrowright'}
                                    size={18}
                                  />
                                </View>
                              </Circle>
                            </View>
                          )}
                        </>
                      </View>
                    </Touchable>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </KeyboardAwareScrollView>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(BusinessLLCSetupInfo2Screen);
