import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AIActionListAkurieApi from '../apis/AIActionListAkurieApi.js';
import * as BusinessDataAkurieApi from '../apis/BusinessDataAkurieApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Checkbox,
  CircularProgress,
  Icon,
  ScreenContainer,
  Surface,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import * as Linking from 'expo-linking';
import {
  ActivityIndicator,
  FlatList,
  Image,
  ImageBackground,
  StatusBar,
  Text,
  View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const BusinessIdeaSetup12Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [BusName, setBusName] = React.useState('Business Name');
  const [createNameView, setCreateNameView] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');
  const [ideaSolutionView, setIdeaSolutionView] = React.useState(false);
  const [ideaWrongView, setIdeaWrongView] = React.useState(false);
  const [myBusIdea, setMyBusIdea] = React.useState('');
  const [problems, setProblems] = React.useState([]);
  const [textAreaValue, setTextAreaValue] = React.useState(null);
  const [textInputValue, setTextInputValue] = React.useState('');
  const isEqualTo = (thingOne, thingTwo) => {
    return thingOne === thingTwo;
  };
  const aIActionListAkurieCreateProblemSolutionsPOST =
    AIActionListAkurieApi.useCreateProblemSolutionsPOST();
  const businessDataAkurieName$ProblemUpdatePOST =
    BusinessDataAkurieApi.useName$ProblemUpdatePOST();
  const businessDataAkurieProblemSelectPOST =
    BusinessDataAkurieApi.useProblemSelectPOST();
  const businessDataAkurieUnselectProblemPOST =
    BusinessDataAkurieApi.useUnselectProblemPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        setGlobalVariableValue({
          key: 'ak_isLoading',
          value: true,
        });
        const infoResult = (
          await BusinessDataAkurieApi.getIdeaInfoGET(Constants, {
            user_id: Constants['ak_userID'],
          })
        )?.json;
        const solutionsResult = (
          await aIActionListAkurieCreateProblemSolutionsPOST.mutateAsync({
            userId: Constants['ak_userID'],
          })
        )?.json;
        setProblems(solutionsResult);
        (
          await businessDataAkurieName$ProblemUpdatePOST.mutateAsync({
            Industry: null,
            Services_Products: null,
            business_Idea: infoResult?.business_Idea,
            business_Name: infoResult?.business_Name,
            problem_Solving: solutionsResult,
            target_MarketGroups: null,
            users_id: Constants['ak_userID'],
          })
        )?.json;
        setGlobalVariableValue({
          key: 'ak_isLoading',
          value: false,
        });
        /* hidden 'Log to Console' action */
        StatusBar.setBarStyle('light-content');
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasBottomSafeArea={false}
      hasSafeArea={false}
      hasTopSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors['Akurie Dark Blue'] },
        dimensions.width
      )}
    >
      {/* loading_Circle */}
      <>
        {!Constants['ak_isLoading'] ? null : (
          <BlurView
            intensity={80}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.BlurViewStyles(theme)['Blur View'],
                {
                  alignItems: 'center',
                  height: '100%',
                  justifyContent: 'center',
                  position: 'absolute',
                  width: '100%',
                  zIndex: 10,
                }
              ),
              dimensions.width
            )}
            tint={'dark'}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: theme.colors['Akurie Dark Blue'],
                  borderRadius: 19,
                  bottom: 50,
                  height: '40%',
                  justifyContent: 'center',
                  opacity: 1,
                  width: '70%',
                },
                dimensions.width
              )}
            >
              <CircularProgress
                animationDuration={7000}
                color={theme.colors['Akurie green']}
                indeterminate={true}
                isAnimated={true}
                lineCap={'round'}
                showTrack={true}
                startPosition={'bottom'}
                style={StyleSheet.applyWidth(
                  { bottom: -20, height: '80%', width: '80%' },
                  dimensions.width
                )}
                thickness={15}
                trackColor={theme.colors.divider}
                trackLineCap={'round'}
              />
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Strong Inverse'],
                    fontFamily: 'Inter_600SemiBold',
                    fontSize: 20,
                    marginTop: 10,
                  }),
                  dimensions.width
                )}
              >
                {'Building Your Idea'}
              </Text>
            </View>
          </BlurView>
        )}
      </>
      <ImageBackground
        resizeMode={'cover'}
        source={Images.Asset6}
        style={StyleSheet.applyWidth(
          {
            alignItems: [
              { minWidth: Breakpoints.BigScreen, value: 'center' },
              { minWidth: Breakpoints.Laptop, value: 'center' },
              { minWidth: Breakpoints.Tablet, value: 'center' },
            ],
            backgroundColor: {
              minWidth: Breakpoints.Laptop,
              value: theme.colors['Akurie Dark Blue'],
            },
            flexWrap: 'wrap',
            height: '100%',
            justifyContent: [
              { minWidth: Breakpoints.BigScreen, value: 'center' },
              { minWidth: Breakpoints.Laptop, value: 'center' },
              { minWidth: Breakpoints.Tablet, value: 'center' },
              { minWidth: Breakpoints.Mobile, value: 'center' },
            ],
            paddingLeft: [
              { minWidth: Breakpoints.Mobile, value: '5%' },
              { minWidth: Breakpoints.Tablet, value: '15%' },
            ],
            paddingRight: [
              { minWidth: Breakpoints.Mobile, value: '5%' },
              { minWidth: Breakpoints.Tablet, value: '15%' },
            ],
            paddingTop: [
              { minWidth: Breakpoints.Mobile, value: '10%' },
              { minWidth: Breakpoints.Desktop, value: '5%' },
            ],
            width: '100%',
          },
          dimensions.width
        )}
      >
        <KeyboardAwareScrollView
          extraHeight={300}
          keyboardShouldPersistTaps={'always'}
          showsVerticalScrollIndicator={true}
          style={StyleSheet.applyWidth(
            { height: '100%', width: '100%' },
            dimensions.width
          )}
          contentContainerStyle={StyleSheet.applyWidth(
            { paddingTop: 5 },
            dimensions.width
          )}
        >
          {/* View 2 */}
          <>
            {!(dimensions.width >= Breakpoints.Laptop) ? null : (
              <View
                style={StyleSheet.applyWidth(
                  { width: { minWidth: Breakpoints.Laptop, value: '80%' } },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'contain'}
                  source={Images.MainLogoUpdate1u2}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'],
                      {
                        height: { minWidth: Breakpoints.Laptop, value: 50 },
                        width: { minWidth: Breakpoints.Laptop, value: '30%' },
                      }
                    ),
                    dimensions.width
                  )}
                />
              </View>
            )}
          </>
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: [
                  { minWidth: Breakpoints.Tablet, value: 'center' },
                  { minWidth: Breakpoints.Mobile, value: 'center' },
                ],
                backgroundColor: theme.colors['Background'],
                borderRadius: 12,
                height: '100%',
                justifyContent: [
                  { minWidth: Breakpoints.Tablet, value: 'center' },
                  { minWidth: Breakpoints.Mobile, value: 'center' },
                ],
                padding: 20,
                paddingBottom: { minWidth: Breakpoints.Tablet, value: 40 },
                width: { minWidth: Breakpoints.Laptop, value: '100%' },
              },
              dimensions.width
            )}
          >
            {/* Unique Card Frame */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexGrow: 1,
                  flexShrink: 0,
                  height: [
                    { minWidth: Breakpoints.Tablet, value: '70%' },
                    { minWidth: Breakpoints.BigScreen, value: '70%' },
                    { minWidth: Breakpoints.Mobile, value: '100%' },
                  ],
                  marginBottom: { minWidth: Breakpoints.Tablet, value: 30 },
                  marginTop: { minWidth: Breakpoints.Tablet, value: 30 },
                  opacity: { minWidth: Breakpoints.Laptop, value: 0.95 },
                  width: [
                    { minWidth: Breakpoints.BigScreen, value: '70%' },
                    { minWidth: Breakpoints.Laptop, value: '80%' },
                    { minWidth: Breakpoints.Desktop, value: '60%' },
                    { minWidth: Breakpoints.Tablet, value: '70%' },
                    { minWidth: Breakpoints.Mobile, value: '100%' },
                  ],
                },
                dimensions.width
              )}
            >
              {/* Content */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 1,
                    flexShrink: 0,
                    justifyContent: 'center',
                    width: [
                      { minWidth: Breakpoints.Mobile, value: '100%' },
                      { minWidth: Breakpoints.Laptop, value: '80%' },
                      { minWidth: Breakpoints.Desktop, value: '70%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                {/* Text Frame  */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexGrow: 0,
                      flexShrink: 0,
                      paddingBottom: 12,
                      paddingLeft: 5,
                      paddingRight: 5,
                      paddingTop: 5,
                    },
                    dimensions.width
                  )}
                >
                  <Image
                    resizeMode={'cover'}
                    source={Images.Asset2}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        { height: 70, marginBottom: 10, width: 70 }
                      ),
                      dimensions.width
                    )}
                  />
                  {/* Disclaimer */}
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Akurie Primary'],
                        fontFamily: [
                          {
                            minWidth: Breakpoints.Laptop,
                            value: 'Inter_400Regular',
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: 'Inter_500Medium',
                          },
                        ],
                        fontSize: [
                          { minWidth: Breakpoints.Laptop, value: 13 },
                          { minWidth: Breakpoints.Mobile, value: 14 },
                        ],
                        marginBottom: 15,
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {'Step 2 of 5'}
                  </Text>
                  {/* View 3 */}
                  <View
                    style={StyleSheet.applyWidth(
                      { width: '100%' },
                      dimensions.width
                    )}
                  >
                    {/* Almost Done */}
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.strong,
                          fontFamily: 'Inter_600SemiBold',
                          fontSize: [
                            { minWidth: Breakpoints.Mobile, value: 14 },
                            { minWidth: Breakpoints.Laptop, value: 24 },
                          ],
                          paddingLeft: 8,
                          paddingRight: 8,
                          textAlign: 'left',
                        },
                        dimensions.width
                      )}
                    >
                      {'What problem does your business solve?'}
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            { color: theme.colors['Error'] }
                          ),
                          dimensions.width
                        )}
                      >
                        {'*'}
                      </Text>
                    </Text>
                    {/* Almost Done 2 */}
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.strong,
                          fontFamily: 'Inter_400Regular',
                          fontSize: [
                            { minWidth: Breakpoints.Mobile, value: 14 },
                            { minWidth: Breakpoints.Laptop, value: 24 },
                          ],
                          paddingLeft: 8,
                          paddingRight: 8,
                          paddingTop: 5,
                          textAlign: 'left',
                        },
                        dimensions.width
                      )}
                    >
                      {'Please select all that apply.'}
                    </Text>
                  </View>
                  {/* View 4 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        marginTop: 20,
                        paddingTop: 5,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    {/* View 5 */}
                    <>
                      {ideaSolutionView ? null : (
                        <View>
                          <>
                            {ideaWrongView ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  { width: '100%' },
                                  dimensions.width
                                )}
                              ></View>
                            )}
                          </>
                          {/* View 2 */}
                          <>
                            {ideaWrongView ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    marginBottom: 10,
                                    marginTop: 5,
                                    width: '100%',
                                  },
                                  dimensions.width
                                )}
                              >
                                <BusinessDataAkurieApi.FetchGetIdeaInfoGET
                                  user_id={Constants['ak_userID']}
                                >
                                  {({
                                    loading,
                                    error,
                                    data,
                                    refetchGetIdeaInfo,
                                  }) => {
                                    const fetchData = data?.json;
                                    if (loading) {
                                      return <ActivityIndicator />;
                                    }

                                    if (
                                      error ||
                                      data?.status < 200 ||
                                      data?.status >= 300
                                    ) {
                                      return <ActivityIndicator />;
                                    }

                                    return (
                                      <FlatList
                                        data={fetchData?.problem_Solving}
                                        keyExtractor={(listData, index) =>
                                          listData?.id ??
                                          listData?.uuid ??
                                          index.toString()
                                        }
                                        keyboardShouldPersistTaps={'never'}
                                        listKey={'ld2ig0Xp'}
                                        numColumns={1}
                                        onEndReachedThreshold={0.5}
                                        renderItem={({ item, index }) => {
                                          const listData = item;
                                          return (
                                            <Surface
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.SurfaceStyles(
                                                    theme
                                                  )['Surface'],
                                                  {
                                                    borderRadius: 9,
                                                    marginBottom: 5,
                                                    marginTop: 5,
                                                    padding: 5,
                                                    width: '100%',
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                    justifyContent:
                                                      'space-between',
                                                    padding: 10,
                                                    width: '100%',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    { width: '90%' },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <Text
                                                    accessible={true}
                                                    style={StyleSheet.applyWidth(
                                                      StyleSheet.compose(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )['Text'],
                                                        {
                                                          fontFamily:
                                                            'Inter_500Medium',
                                                          paddingBottom: 10,
                                                          paddingRight: 20,
                                                          paddingTop: 10,
                                                        }
                                                      ),
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {listData?.problem}
                                                  </Text>
                                                </View>
                                                <Checkbox
                                                  checkedIcon={
                                                    'AntDesign/checksquare'
                                                  }
                                                  color={
                                                    theme.colors[
                                                      'Akurie Primary'
                                                    ]
                                                  }
                                                  defaultValue={
                                                    listData?.select
                                                  }
                                                  onCheck={() => {
                                                    const handler =
                                                      async () => {
                                                        const checkboxValue =
                                                          undefined;
                                                        try {
                                                          (
                                                            await businessDataAkurieProblemSelectPOST.mutateAsync(
                                                              {
                                                                problemID:
                                                                  listData?.id,
                                                                userID:
                                                                  Constants[
                                                                    'ak_userID'
                                                                  ],
                                                              }
                                                            )
                                                          )?.json;
                                                        } catch (err) {
                                                          console.error(err);
                                                        }
                                                      };
                                                    handler();
                                                  }}
                                                  onPress={newCheckboxValue => {
                                                    const checkboxValue =
                                                      newCheckboxValue;
                                                    try {
                                                      undefined;
                                                    } catch (err) {
                                                      console.error(err);
                                                    }
                                                  }}
                                                  onUncheck={() => {
                                                    const handler =
                                                      async () => {
                                                        const checkboxValue =
                                                          undefined;
                                                        try {
                                                          (
                                                            await businessDataAkurieUnselectProblemPOST.mutateAsync(
                                                              {
                                                                problemID:
                                                                  listData?.id,
                                                                userID:
                                                                  Constants[
                                                                    'ak_userID'
                                                                  ],
                                                              }
                                                            )
                                                          )?.json;
                                                        } catch (err) {
                                                          console.error(err);
                                                        }
                                                      };
                                                    handler();
                                                  }}
                                                  size={30}
                                                  uncheckedColor={
                                                    theme.colors[
                                                      'Akurie Dark Blue'
                                                    ]
                                                  }
                                                  uncheckedIcon={
                                                    'MaterialIcons/check-box-outline-blank'
                                                  }
                                                />
                                              </View>
                                            </Surface>
                                          );
                                        }}
                                        showsHorizontalScrollIndicator={true}
                                        showsVerticalScrollIndicator={true}
                                        style={StyleSheet.applyWidth(
                                          { width: '100%' },
                                          dimensions.width
                                        )}
                                        contentContainerStyle={StyleSheet.applyWidth(
                                          { paddingLeft: 5, paddingRight: 5 },
                                          dimensions.width
                                        )}
                                      />
                                    );
                                  }}
                                </BusinessDataAkurieApi.FetchGetIdeaInfoGET>
                              </View>
                            )}
                          </>
                        </View>
                      )}
                    </>
                    {/* View 3 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'space-evenly',
                          marginTop: 15,
                          paddingLeft: 10,
                          paddingRight: 10,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      {/* Button 2 */}
                      <Button
                        onPress={() => {
                          try {
                            /* hidden 'Set Variable' action */
                            /* hidden 'Set Variable' action */
                            navigation.goBack();
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ButtonStyles(theme)['Button'],
                            {
                              backgroundColor: theme.colors['Medium'],
                              borderRadius: 9,
                              marginTop: 5,
                              minHeight: 50,
                              width: '45%',
                            }
                          ),
                          dimensions.width
                        )}
                        title={'No - Go Back'}
                      />
                      <Button
                        onPress={() => {
                          try {
                            /* hidden 'API Request' action */
                            /* hidden 'API Request' action */
                            /* hidden 'Extract Key' action */
                            /* hidden 'Set Variable' action */
                            /* hidden 'Conditional Stop' action */
                            navigation.navigate('BusinessIdeaSetup13Screen', {
                              user_id_pass: Constants['ak_userID'],
                              entityType:
                                props.route?.params?.entityType ?? null,
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ButtonStyles(theme)['Button'],
                            {
                              backgroundColor: theme.colors['Akurie green'],
                              borderRadius: 9,
                              color: theme.colors['Akurie Dark Blue'],
                              marginTop: 5,
                              minHeight: 50,
                              width: '50%',
                            }
                          ),
                          dimensions.width
                        )}
                        title={'Yes - Next Step'}
                      />
                    </View>
                    {/* View 4 */}
                    <>
                      {!ideaWrongView ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '100%' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  fontFamily: 'Inter_600SemiBold',
                                  paddingLeft: 15,
                                  paddingRight: 15,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'What problem does your idea solve?'}
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  { color: theme.colors['Error'] }
                                ),
                                dimensions.width
                              )}
                            >
                              {'*'}
                            </Text>
                          </Text>
                          {/* Surface 2 */}
                          <Surface
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.SurfaceStyles(theme)['Surface'],
                                {
                                  alignItems: 'center',
                                  backgroundColor: theme.colors['Surface'],
                                  borderRadius: 9,
                                  justifyContent: 'center',
                                  marginBottom: 10,
                                  marginTop: 10,
                                  minHeight: 100,
                                  paddingBottom: 10,
                                  paddingTop: 10,
                                  width: '100%',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            <TextInput
                              changeTextDelay={500}
                              multiline={true}
                              numberOfLines={4}
                              onChangeText={newTextAreaValue => {
                                const textInputValue = newTextAreaValue;
                                try {
                                  setTextAreaValue(newTextAreaValue);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              placeholder={
                                'Our platform addresses the challenge of accessing high-quality, handmade products by providing a centralized marketplace for artisans to showcase their creations.'
                              }
                              placeholderTextColor={theme.colors['Light']}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextInputStyles(theme)[
                                    'Text Area'
                                  ],
                                  {
                                    borderColor: theme.colors['Divider'],
                                    borderRadius: 0,
                                    color: theme.colors['Medium'],
                                    width: '90%',
                                  }
                                ),
                                dimensions.width
                              )}
                              textAlignVertical={'top'}
                              value={textAreaValue}
                            />
                          </Surface>
                          {/* View 2 */}
                          <View
                            style={StyleSheet.applyWidth(
                              { marginBottom: 10, marginTop: 10 },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Error'],
                                    fontFamily: 'Inter_400Regular',
                                    textDecorationLine: 'underline',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {errorMsg}
                            </Text>
                          </View>
                          {/* Button 3 */}
                          <Button
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  const requestResponse = (
                                    await businessDataAkurieName$ProblemUpdatePOST.mutateAsync(
                                      {
                                        business_Name: BusName,
                                        problem_Solving: textAreaValue,
                                        users_id: Constants['ak_userID'],
                                      }
                                    )
                                  )?.json;
                                  const errorMsg = requestResponse?.message;
                                  setErrorMsg(errorMsg);
                                  if (errorMsg) {
                                    return;
                                  }
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ButtonStyles(theme)['Button'],
                                {
                                  backgroundColor:
                                    theme.colors['Akurie Dark Blue'],
                                  borderRadius: 9,
                                  minHeight: 50,
                                }
                              ),
                              dimensions.width
                            )}
                            title={'Continue'}
                          />
                        </View>
                      )}
                    </>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </KeyboardAwareScrollView>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(BusinessIdeaSetup12Screen);
