import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { Touchable, withTheme } from '@draftbit/ui';
import * as Linking from 'expo-linking';
import { Image, View } from 'react-native';

const WebHeader2Block = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();

  return (
    <View
      style={StyleSheet.applyWidth(
        StyleSheet.compose(
          GlobalStyles.ViewStyles(theme)['Akurie Header Desktop Header 9'],
          {
            borderBottomWidth: { minWidth: Breakpoints.Laptop, value: 0.5 },
            borderColor: {
              minWidth: Breakpoints.Laptop,
              value: theme.colors['Akurie green'],
            },
            height: { minWidth: Breakpoints.Laptop, value: 119.6 },
            justifyContent: { minWidth: Breakpoints.Laptop, value: 'center' },
            paddingLeft: { minWidth: Breakpoints.Laptop, value: '10%' },
            paddingRight: { minWidth: Breakpoints.Laptop, value: '10%' },
            width: { minWidth: Breakpoints.Laptop, value: dimensions.width },
          }
        ),
        dimensions.width
      )}
    >
      {/* View 4 */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
            flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
            height: { minWidth: Breakpoints.Laptop, value: 68.48 },
            justifyContent: {
              minWidth: Breakpoints.Laptop,
              value: 'space-between',
            },
            width: { minWidth: Breakpoints.Laptop, value: '74%' },
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              height: { minWidth: Breakpoints.Laptop, value: '100%' },
              width: { minWidth: Breakpoints.Laptop, value: '15%' },
            },
            dimensions.width
          )}
        >
          <Touchable
            onPress={() => {
              try {
                /* 'Open App Link' action requires configuration: select URL */
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              {
                height: { minWidth: Breakpoints.Laptop, value: '100%' },
                width: { minWidth: Breakpoints.Laptop, value: '100%' },
              },
              dimensions.width
            )}
          >
            <Image
              resizeMode={'contain'}
              source={Images.MainLogoUpdate1u2}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                  height: { minWidth: Breakpoints.Laptop, value: '100%' },
                  width: { minWidth: Breakpoints.Laptop, value: '100%' },
                }),
                dimensions.width
              )}
            />
          </Touchable>
        </View>
      </View>
    </View>
  );
};

export default withTheme(WebHeader2Block);
