import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AIActionListAkurieApi from '../apis/AIActionListAkurieApi.js';
import * as BusinessDataAkurieApi from '../apis/BusinessDataAkurieApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  ScreenContainer,
  Surface,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import {
  Image,
  ImageBackground,
  Keyboard,
  StatusBar,
  Text,
  View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const BusinessIdeaSetup1Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [BusName, setBusName] = React.useState('Business Name');
  const [createNameView, setCreateNameView] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');
  const [ideaSolutionView, setIdeaSolutionView] = React.useState(false);
  const [ideaWrongView, setIdeaWrongView] = React.useState(false);
  const [myBusIdea, setMyBusIdea] = React.useState('');
  const [textAreaValue, setTextAreaValue] = React.useState(null);
  const [textInputValue, setTextInputValue] = React.useState('');
  const isEqualTo = (thingOne, thingTwo) => {
    return thingOne === thingTwo;
  };
  const businessDataAkurieBusinessIdeaPOST =
    BusinessDataAkurieApi.useBusinessIdeaPOST();
  const aIActionListAkurieCreateBusNameAIPOST =
    AIActionListAkurieApi.useCreateBusNameAIPOST();
  const businessDataAkurieName$ProblemUpdatePOST =
    BusinessDataAkurieApi.useName$ProblemUpdatePOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'businessStatus',
        value: 'Business Idea',
      });
      setGlobalVariableValue({
        key: 'ak_entityType',
        value: 'Limited Liability Company - LLC',
      });
      StatusBar.setBarStyle('light-content');
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasBottomSafeArea={false}
      hasSafeArea={false}
      hasTopSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors['Akurie Dark Blue'] },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'cover'}
        source={Images.Asset6}
        style={StyleSheet.applyWidth(
          {
            alignItems: [
              { minWidth: Breakpoints.BigScreen, value: 'center' },
              { minWidth: Breakpoints.Laptop, value: 'center' },
              { minWidth: Breakpoints.Tablet, value: 'center' },
            ],
            backgroundColor: {
              minWidth: Breakpoints.Laptop,
              value: theme.colors['Akurie Dark Blue'],
            },
            flexWrap: 'wrap',
            height: '100%',
            justifyContent: [
              { minWidth: Breakpoints.BigScreen, value: 'center' },
              { minWidth: Breakpoints.Laptop, value: 'center' },
              { minWidth: Breakpoints.Tablet, value: 'center' },
              { minWidth: Breakpoints.Mobile, value: 'center' },
            ],
            paddingBottom: '10%',
            paddingLeft: [
              { minWidth: Breakpoints.Mobile, value: '5%' },
              { minWidth: Breakpoints.Tablet, value: '15%' },
            ],
            paddingRight: [
              { minWidth: Breakpoints.Mobile, value: '5%' },
              { minWidth: Breakpoints.Tablet, value: '15%' },
            ],
            paddingTop: [
              { minWidth: Breakpoints.Mobile, value: '10%' },
              { minWidth: Breakpoints.Desktop, value: '5%' },
            ],
            width: '100%',
          },
          dimensions.width
        )}
      >
        <KeyboardAwareScrollView
          extraHeight={300}
          keyboardShouldPersistTaps={'always'}
          showsVerticalScrollIndicator={true}
          style={StyleSheet.applyWidth(
            { height: '100%', width: '100%' },
            dimensions.width
          )}
        >
          {/* View 2 */}
          <>
            {!(dimensions.width >= Breakpoints.Laptop) ? null : (
              <View
                style={StyleSheet.applyWidth(
                  { width: { minWidth: Breakpoints.Laptop, value: '80%' } },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'contain'}
                  source={Images.MainLogoUpdate1u2}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'],
                      {
                        height: { minWidth: Breakpoints.Laptop, value: 50 },
                        width: { minWidth: Breakpoints.Laptop, value: '30%' },
                      }
                    ),
                    dimensions.width
                  )}
                />
              </View>
            )}
          </>
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: [
                  { minWidth: Breakpoints.Tablet, value: 'center' },
                  { minWidth: Breakpoints.Mobile, value: 'center' },
                ],
                backgroundColor: theme.colors['Background'],
                borderRadius: 12,
                height: '100%',
                justifyContent: [
                  { minWidth: Breakpoints.Tablet, value: 'center' },
                  { minWidth: Breakpoints.Mobile, value: 'center' },
                ],
                marginTop: 20,
                padding: 20,
                paddingBottom: { minWidth: Breakpoints.Tablet, value: 40 },
                width: { minWidth: Breakpoints.Laptop, value: '100%' },
              },
              dimensions.width
            )}
          >
            {/* Unique Card Frame */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexGrow: 1,
                  flexShrink: 0,
                  height: [
                    { minWidth: Breakpoints.Tablet, value: '70%' },
                    { minWidth: Breakpoints.BigScreen, value: '70%' },
                    { minWidth: Breakpoints.Mobile, value: '100%' },
                  ],
                  marginBottom: { minWidth: Breakpoints.Tablet, value: 30 },
                  marginTop: { minWidth: Breakpoints.Tablet, value: 30 },
                  opacity: { minWidth: Breakpoints.Laptop, value: 0.95 },
                  width: [
                    { minWidth: Breakpoints.BigScreen, value: '70%' },
                    { minWidth: Breakpoints.Laptop, value: '80%' },
                    { minWidth: Breakpoints.Desktop, value: '60%' },
                    { minWidth: Breakpoints.Tablet, value: '70%' },
                    { minWidth: Breakpoints.Mobile, value: '100%' },
                  ],
                },
                dimensions.width
              )}
            >
              {/* Content */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 1,
                    flexShrink: 0,
                    justifyContent: 'center',
                    width: [
                      { minWidth: Breakpoints.Mobile, value: '100%' },
                      { minWidth: Breakpoints.Laptop, value: '80%' },
                      { minWidth: Breakpoints.Desktop, value: '70%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                {/* Text Frame  */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexGrow: 0,
                      flexShrink: 0,
                      paddingBottom: 12,
                      paddingLeft: 5,
                      paddingRight: 5,
                      paddingTop: 5,
                    },
                    dimensions.width
                  )}
                >
                  <Image
                    resizeMode={'cover'}
                    source={Images.Asset2}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        { height: 70, marginBottom: 10, width: 70 }
                      ),
                      dimensions.width
                    )}
                  />
                  {/* Disclaimer */}
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Akurie Primary'],
                        fontFamily: [
                          {
                            minWidth: Breakpoints.Laptop,
                            value: 'Inter_400Regular',
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: 'Inter_500Medium',
                          },
                        ],
                        fontSize: [
                          { minWidth: Breakpoints.Laptop, value: 13 },
                          { minWidth: Breakpoints.Mobile, value: 14 },
                        ],
                        marginBottom: 15,
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {'Step 1 of 5'}
                  </Text>
                  {/* View 3 */}
                  <View
                    style={StyleSheet.applyWidth(
                      { width: '100%' },
                      dimensions.width
                    )}
                  >
                    {/* Almost Done */}
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.strong,
                          fontFamily: 'Inter_600SemiBold',
                          fontSize: [
                            { minWidth: Breakpoints.Mobile, value: 14 },
                            { minWidth: Breakpoints.Laptop, value: 24 },
                          ],
                          paddingLeft: 15,
                          paddingRight: 15,
                          textAlign: 'left',
                        },
                        dimensions.width
                      )}
                    >
                      {'What is your business idea?'}
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            { color: theme.colors['Error'] }
                          ),
                          dimensions.width
                        )}
                      >
                        {'*'}
                      </Text>
                    </Text>
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'flex-end', marginTop: 5, width: '100%' },
                      dimensions.width
                    )}
                  >
                    <Surface
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.SurfaceStyles(theme)['Surface'],
                          {
                            alignItems: 'center',
                            backgroundColor: theme.colors['Surface'],
                            borderRadius: 9,
                            justifyContent: 'center',
                            marginBottom: 10,
                            marginTop: 10,
                            minHeight: 100,
                            paddingBottom: 10,
                            paddingTop: 10,
                            width: '100%',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      <TextInput
                        autoCorrect={true}
                        changeTextDelay={500}
                        enablesReturnKeyAutomatically={true}
                        multiline={true}
                        numberOfLines={2}
                        onChangeText={newTextAreaValue => {
                          try {
                            setMyBusIdea(newTextAreaValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        placeholder={
                          'Introducing an innovative platform connecting local artisans with consumers seeking unique handmade goods, fostering a vibrant marketplace for bespoke craftsmanship.'
                        }
                        placeholderTextColor={theme.colors['Light']}
                        returnKeyType={'default'}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextInputStyles(theme)['Text Area'],
                            {
                              borderColor: theme.colors['Divider'],
                              borderRadius: 0,
                              color: theme.colors['Medium'],
                              width: '90%',
                            }
                          ),
                          dimensions.width
                        )}
                        textAlignVertical={'top'}
                        value={myBusIdea}
                      />
                    </Surface>
                    <Button
                      onPress={() => {
                        const handler = async () => {
                          try {
                            (
                              await businessDataAkurieBusinessIdeaPOST.mutateAsync(
                                {
                                  business_Idea: myBusIdea,
                                  users_id: Constants['ak_userID'],
                                }
                              )
                            )?.json;
                            setCreateNameView(true);
                            /* hidden 'Set Variable' action */
                            Keyboard.dismiss();
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            borderRadius: 9,
                            fontFamily: 'System',
                            fontSize: 13,
                            fontWeight: '600',
                            width: '40%',
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Confirm'}
                    />
                  </View>
                  {/* BusName */}
                  <>
                    {!createNameView ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', marginTop: 5, width: '100%' },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '100%' },
                            dimensions.width
                          )}
                        >
                          {/* Disclaimer */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors.strong,
                                fontFamily: [
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'Inter_400Regular',
                                  },
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: 'Inter_600SemiBold',
                                  },
                                ],
                                fontSize: [
                                  { minWidth: Breakpoints.Laptop, value: 13 },
                                  { minWidth: Breakpoints.Mobile, value: 14 },
                                ],
                                marginBottom: 5,
                                paddingLeft: 15,
                                paddingRight: 15,
                                textAlign: 'left',
                              },
                              dimensions.width
                            )}
                          >
                            {'What would you call this business? '}
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  { color: theme.colors['Error'] }
                                ),
                                dimensions.width
                              )}
                            >
                              {'*'}
                            </Text>
                          </Text>
                        </View>

                        <Surface
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.SurfaceStyles(theme)['Surface'],
                              {
                                alignItems: 'center',
                                backgroundColor: theme.colors['Surface'],
                                borderRadius: 9,
                                justifyContent: 'center',
                                marginBottom: 10,
                                marginTop: 5,
                                minHeight: 60,
                                paddingBottom: 10,
                                paddingTop: 10,
                                width: '100%',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          <TextInput
                            autoCapitalize={'none'}
                            changeTextDelay={500}
                            onChangeText={newTextInputValue => {
                              try {
                                setBusName(newTextInputValue);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            onChangeTextDelayed={newTextInputValue => {
                              try {
                                setIdeaSolutionView(true);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            onSubmitEditing={() => {
                              try {
                                Keyboard.dismiss();
                                setIdeaSolutionView(true);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            placeholder={'Business Name'}
                            placeholderTextColor={theme.colors['Light']}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextInputStyles(theme)[
                                  'Text Input'
                                ],
                                {
                                  color: theme.colors['Medium'],
                                  minHeight: 50,
                                  width: '90%',
                                }
                              ),
                              dimensions.width
                            )}
                            value={BusName}
                          />
                        </Surface>

                        <Touchable
                          onPress={() => {
                            const handler = async () => {
                              try {
                                const generatedName = (
                                  await aIActionListAkurieCreateBusNameAIPOST.mutateAsync(
                                    { userId: Constants['ak_userID'] }
                                  )
                                )?.json;
                                setBusName(
                                  generatedName?.generatedName?.Response
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                        >
                          {/* View 2 */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'row',
                                justifyContent: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Medium'],
                                    fontFamily: 'Inter_300Light',
                                    fontSize: 13,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Need help with a name?'}
                            </Text>

                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  backgroundColor:
                                    theme.colors['Akurie Dark Blue'],
                                  borderRadius: 9,
                                  flexDirection: 'row',
                                  height: 40,
                                  justifyContent: 'center',
                                  marginLeft: 5,
                                  minHeight: 30,
                                  padding: 2,
                                },
                                dimensions.width
                              )}
                            >
                              <Icon
                                color={theme.colors['Strong_Inverse']}
                                name={'MaterialCommunityIcons/creation'}
                                size={24}
                                style={StyleSheet.applyWidth(
                                  { marginLeft: 10, marginRight: 5 },
                                  dimensions.width
                                )}
                              />
                              <Text
                                accessible={true}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      color: theme.colors['Strong Inverse'],
                                      marginRight: 10,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Generate Name'}
                              </Text>
                            </View>
                          </View>
                          {/* Text 2 */}
                          <Text
                            accessible={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                { color: theme.colors['Error'], marginTop: 10 }
                              ),
                              dimensions.width
                            )}
                          >
                            {errorMsg}
                          </Text>
                          {/* Button 3 */}
                          <>
                            {!myBusIdea ? null : (
                              <Button
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      const requestResponse = (
                                        await businessDataAkurieName$ProblemUpdatePOST.mutateAsync(
                                          {
                                            Industry: null,
                                            Services_Products: null,
                                            business_Idea: myBusIdea,
                                            business_Name: BusName,
                                            problem_Solving: null,
                                            target_MarketGroups: null,
                                            users_id: Constants['ak_userID'],
                                          }
                                        )
                                      )?.json;
                                      const errorMsg = requestResponse?.message;
                                      setErrorMsg(errorMsg);
                                      if (errorMsg) {
                                        return;
                                      }
                                      navigation.navigate(
                                        'BusinessIdeaSetup12Screen'
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ButtonStyles(theme)['Button'],
                                    {
                                      backgroundColor:
                                        theme.colors['Akurie green'],
                                      borderRadius: 9,
                                      color: theme.colors['Akurie Dark Blue'],
                                      marginTop: 10,
                                      minHeight: 50,
                                    }
                                  ),
                                  dimensions.width
                                )}
                                title={'Next Step'}
                              />
                            )}
                          </>
                        </Touchable>
                      </View>
                    )}
                  </>
                </View>
              </View>
            </View>
          </View>
        </KeyboardAwareScrollView>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(BusinessIdeaSetup1Screen);
