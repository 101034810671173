import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AIActionListAkurieApi from '../apis/AIActionListAkurieApi.js';
import * as AuthAkurieApi from '../apis/AuthAkurieApi.js';
import * as BusinessDataAkurieApi from '../apis/BusinessDataAkurieApi.js';
import * as Logs$SupportAkurieApi from '../apis/Logs$SupportAkurieApi.js';
import * as Web$AppServicesApi from '../apis/Web$AppServicesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import formatCurrency from '../global-functions/formatCurrency';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  IconButton,
  Picker,
  ScreenContainer,
  Surface,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  Keyboard,
  StatusBar,
  Text,
  View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const BusinessIdeaSetup3Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [indusrtyData, setIndusrtyData] = React.useState(false);
  const [offerCustomers, setOfferCustomers] = React.useState('');
  const [plan_status, setPlan_status] = React.useState('');
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const isEqualTo = (thingOne, thingTwo) => {
    return thingOne === thingTwo;
  };
  const logs$SupportAkurieBasicActivityPOST =
    Logs$SupportAkurieApi.useBasicActivityPOST();
  const businessDataAkurieCreateIdeaBusinessPOST =
    BusinessDataAkurieApi.useCreateIdeaBusinessPOST();
  const aIActionListAkurieBasicStepsSetupPOST =
    AIActionListAkurieApi.useBasicStepsSetupPOST();
  const web$AppServicesInitializeWebServicesPOST =
    Web$AppServicesApi.useInitializeWebServicesPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      StatusBar.setBarStyle('light-content');
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasBottomSafeArea={false}
      hasSafeArea={false}
      hasTopSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors['Akurie Dark Blue'] },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'cover'}
        source={Images.Asset6}
        style={StyleSheet.applyWidth(
          {
            alignItems: [
              { minWidth: Breakpoints.BigScreen, value: 'center' },
              { minWidth: Breakpoints.Laptop, value: 'center' },
              { minWidth: Breakpoints.Tablet, value: 'center' },
            ],
            backgroundColor: {
              minWidth: Breakpoints.Laptop,
              value: theme.colors['Akurie Dark Blue'],
            },
            flexWrap: 'wrap',
            height: '100%',
            justifyContent: [
              { minWidth: Breakpoints.BigScreen, value: 'center' },
              { minWidth: Breakpoints.Laptop, value: 'center' },
              { minWidth: Breakpoints.Tablet, value: 'center' },
              { minWidth: Breakpoints.Mobile, value: 'center' },
            ],
            paddingBottom: '10%',
            paddingLeft: [
              { minWidth: Breakpoints.Mobile, value: '5%' },
              { minWidth: Breakpoints.Tablet, value: '15%' },
            ],
            paddingRight: [
              { minWidth: Breakpoints.Mobile, value: '5%' },
              { minWidth: Breakpoints.Tablet, value: '15%' },
            ],
            paddingTop: '10%',
            width: '100%',
          },
          dimensions.width
        )}
      >
        <KeyboardAwareScrollView
          extraHeight={300}
          keyboardShouldPersistTaps={'never'}
          showsVerticalScrollIndicator={true}
          style={StyleSheet.applyWidth(
            { height: '100%', width: '100%' },
            dimensions.width
          )}
        >
          {/* View 2 */}
          <>
            {!(dimensions.width >= Breakpoints.Laptop) ? null : (
              <View
                style={StyleSheet.applyWidth(
                  { width: { minWidth: Breakpoints.Laptop, value: '80%' } },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'contain'}
                  source={Images.MainLogoUpdate1u2}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'],
                      {
                        height: { minWidth: Breakpoints.Laptop, value: 50 },
                        width: { minWidth: Breakpoints.Laptop, value: '30%' },
                      }
                    ),
                    dimensions.width
                  )}
                />
              </View>
            )}
          </>
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: [
                  { minWidth: Breakpoints.Tablet, value: 'center' },
                  { minWidth: Breakpoints.Mobile, value: 'center' },
                ],
                backgroundColor: theme.colors['Background'],
                borderRadius: 12,
                height: '100%',
                justifyContent: [
                  { minWidth: Breakpoints.Tablet, value: 'center' },
                  { minWidth: Breakpoints.Mobile, value: 'center' },
                ],
                marginTop: 20,
                padding: 20,
                paddingBottom: { minWidth: Breakpoints.Tablet, value: 40 },
                width: { minWidth: Breakpoints.Laptop, value: '100%' },
              },
              dimensions.width
            )}
          >
            {/* Unique Card Frame */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexGrow: 1,
                  flexShrink: 0,
                  height: [
                    { minWidth: Breakpoints.Tablet, value: '70%' },
                    { minWidth: Breakpoints.BigScreen, value: '70%' },
                    { minWidth: Breakpoints.Mobile, value: '100%' },
                  ],
                  marginBottom: { minWidth: Breakpoints.Tablet, value: 30 },
                  marginTop: { minWidth: Breakpoints.Tablet, value: 30 },
                  opacity: { minWidth: Breakpoints.Laptop, value: 0.95 },
                  width: [
                    { minWidth: Breakpoints.Laptop, value: '80%' },
                    { minWidth: Breakpoints.Desktop, value: '60%' },
                    { minWidth: Breakpoints.Tablet, value: '70%' },
                    { minWidth: Breakpoints.Mobile, value: '100%' },
                  ],
                },
                dimensions.width
              )}
            >
              {/* Content */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 1,
                    flexShrink: 0,
                    justifyContent: 'center',
                    marginTop: { minWidth: Breakpoints.Laptop, value: -12 },
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                <AIActionListAkurieApi.FetchGetIndustryPOST
                  userID={Constants['ak_userID']}
                >
                  {({ loading, error, data, refetchGetIndustry }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <>
                        {/* Text Frame  */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexGrow: 0,
                              flexShrink: 0,
                              paddingBottom: 12,
                              paddingLeft: 5,
                              paddingRight: 5,
                              paddingTop: 5,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Step Count 2 */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Akurie Primary'],
                                fontFamily: [
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'Inter_400Regular',
                                  },
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: 'Inter_500Medium',
                                  },
                                ],
                                fontSize: [
                                  { minWidth: Breakpoints.Laptop, value: 13 },
                                  { minWidth: Breakpoints.Mobile, value: 14 },
                                ],
                                marginBottom: 15,
                                textAlign: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            {'Step 5 of 5'}
                          </Text>
                          {/* View 4 2 */}
                          <View
                            style={StyleSheet.applyWidth(
                              { alignItems: 'center', width: '100%' },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  borderTopLeftRadius: 25,
                                  borderTopRightRadius: 25,
                                  marginBottom: 10,
                                  paddingTop: 5,
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                            >
                              {/* View 3 */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { alignItems: 'center', width: '100%' },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      {
                                        color: theme.colors['Akurie Dark Blue'],
                                        fontFamily: 'Inter_600SemiBold',
                                        fontSize: 24,
                                        marginBottom: 10,
                                        paddingLeft: 15,
                                        textAlign: 'left',
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {"You're Ready!"}
                                </Text>

                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      backgroundColor:
                                        theme.colors['Akurie Dark Blue'],
                                      borderTopLeftRadius: 9,
                                      borderTopRightRadius: 9,
                                      marginTop: 15,
                                      paddingTop: 20,
                                      width: '100%',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Image
                                    resizeMode={'contain'}
                                    source={Images.Asset2}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ImageStyles(theme)[
                                          'Image'
                                        ],
                                        {
                                          height: 100,
                                          marginBottom: 10,
                                          width: 150,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  />
                                  {/* Disclaimer */}
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      {
                                        color: theme.colors['Akurie Primary'],
                                        fontFamily: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 'Inter_400Regular',
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 'Inter_600SemiBold',
                                          },
                                        ],
                                        fontSize: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 13,
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 18,
                                          },
                                        ],
                                        marginBottom: 5,
                                        paddingLeft: 15,
                                        paddingRight: 15,
                                        textAlign: 'center',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'Your Industry Data:'}
                                  </Text>
                                </View>
                                {/* View 2 */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor:
                                        theme.colors['Akurie Dark Blue'],
                                      borderBottomLeftRadius: 9,
                                      borderBottomRightRadius: 9,
                                      marginBottom: 10,
                                      paddingBottom: 30,
                                      paddingLeft: 30,
                                      paddingRight: 30,
                                      paddingTop: 5,
                                      width: '100%',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    accessible={true}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text'],
                                        {
                                          color: theme.colors['Strong Inverse'],
                                          fontFamily: 'Inter_600SemiBold',
                                          fontSize: 14,
                                          textAlign: 'center',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {fetchData?.businessIndustry?.Industry}
                                  </Text>
                                  {/* View 6 */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { marginTop: 10, width: '100%' },
                                      dimensions.width
                                    )}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { width: '100%' },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Disclaimer */}
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          {
                                            color:
                                              theme.colors['Akurie Primary'],
                                            fontFamily: [
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value: 'Inter_400Regular',
                                              },
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: 'Inter_600SemiBold',
                                              },
                                            ],
                                            fontSize: [
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value: 13,
                                              },
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: 18,
                                              },
                                            ],
                                            marginBottom: 5,
                                            textAlign: 'center',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {'Yearly Revenue Target:'}
                                      </Text>
                                    </View>
                                    {/* View 2 */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          marginBottom: 10,
                                          marginTop: 5,
                                          width: '100%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ],
                                            {
                                              color:
                                                theme.colors['Strong Inverse'],
                                              fontFamily: 'Inter_600SemiBold',
                                              fontSize: 14,
                                              textAlign: 'center',
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {formatCurrency(
                                          fetchData?.businessIndustry
                                            ?.desired_gross_after_tax
                                        )}
                                      </Text>
                                    </View>
                                  </View>
                                </View>
                                {/* Text 2 */}
                                <Text
                                  accessible={true}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      {
                                        color: theme.colors['Strong'],
                                        fontFamily: 'Inter_600SemiBold',
                                        fontSize: 14,
                                        marginBottom: 20,
                                        marginTop: 10,
                                        paddingLeft: 15,
                                        textAlign: 'left',
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {"let's get started."}
                                </Text>
                                {/* Text 3 */}
                                <Text
                                  accessible={true}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      {
                                        color: theme.colors['Medium'],
                                        fontFamily: 'Inter_400Regular',
                                        fontSize: 15,
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                        textAlign: 'center',
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {
                                    'We design projects and tasks to help you develop, launch, and adapt to your target market. As your business, industry, and customers evolve, so do we.'
                                  }
                                </Text>
                              </View>
                            </View>
                            {/* View 5 */}
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'center', width: '100%' },
                                dimensions.width
                              )}
                            />
                            {/* View 3 */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                  justifyContent: 'space-evenly',
                                  marginTop: 20,
                                  paddingLeft: 15,
                                  paddingRight: 15,
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                            >
                              <Button
                                onPress={() => {
                                  try {
                                    navigation.navigate(
                                      'BusinessIdeaSetup1Screen'
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ButtonStyles(theme)['Button'],
                                    {
                                      backgroundColor: theme.colors['Medium'],
                                      borderRadius: 9,
                                      fontFamily: 'Inter_700Bold',
                                      marginTop: 5,
                                      minHeight: 50,
                                      width: '40%',
                                    }
                                  ),
                                  dimensions.width
                                )}
                                title={'Start Over'}
                              />
                              {/* Button 2 */}
                              <Button
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      const userResponse = (
                                        await AuthAkurieApi.getSpecificUserGET(
                                          Constants,
                                          { user_id: Constants['ak_userID'] }
                                        )
                                      )?.json;
                                      const busResponse = (
                                        await businessDataAkurieCreateIdeaBusinessPOST.mutateAsync(
                                          {
                                            Business_Name:
                                              fetchData?.businessIndustry
                                                ?.business_Name,
                                            Business_Status:
                                              Constants['businessStatus'],
                                            Entity_Type:
                                              Constants['ak_entityType'],
                                            State: userResponse?.home_state,
                                            User_id: Constants['ak_userID'],
                                            industry_type:
                                              fetchData?.businessIndustry
                                                ?.Industry,
                                          }
                                        )
                                      )?.json;
                                      /* hidden 'Log to Console' action */
                                      (
                                        await aIActionListAkurieBasicStepsSetupPOST.mutateAsync(
                                          { businessID: busResponse?.id }
                                        )
                                      )?.json;
                                      setGlobalVariableValue({
                                        key: 'ak_businessID',
                                        value: busResponse?.id,
                                      });
                                      (
                                        await web$AppServicesInitializeWebServicesPOST.mutateAsync(
                                          { bus_id: busResponse?.id }
                                        )
                                      )?.json;
                                      (
                                        await logs$SupportAkurieBasicActivityPOST.mutateAsync(
                                          {
                                            buttonName: 'Business Idea Created',
                                            session_id: Constants['Session_ID'],
                                          }
                                        )
                                      )?.json;
                                      navigation.navigate(
                                        'PaywallProCurrentMonth2Screen'
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ButtonStyles(theme)['Button'],
                                    {
                                      backgroundColor:
                                        theme.colors['Akurie green'],
                                      borderRadius: 9,
                                      color: theme.colors['Akurie Dark Blue'],
                                      fontFamily: 'Inter_700Bold',
                                      marginTop: 5,
                                      minHeight: 50,
                                      width: '50%',
                                    }
                                  ),
                                  dimensions.width
                                )}
                                title={'Access Akurie'}
                              />
                            </View>
                          </View>
                        </View>
                      </>
                    );
                  }}
                </AIActionListAkurieApi.FetchGetIndustryPOST>
              </View>
            </View>
          </View>
        </KeyboardAwareScrollView>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(BusinessIdeaSetup3Screen);
