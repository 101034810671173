import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as Logs$SupportAkurieApi from '../apis/Logs$SupportAkurieApi.js';
import * as Web$AppServicesApi from '../apis/Web$AppServicesApi.js';
import DesktopHeader13024SignedInBlock from '../components/DesktopHeader13024SignedInBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  CircularProgress,
  Icon,
  IconButton,
  ScreenContainer,
  Surface,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  ScrollView,
  StatusBar,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const MyAkurieWebServicesScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [appPresent, setAppPresent] = React.useState(false);
  const [webBuildBeginView, setWebBuildBeginView] = React.useState(false);
  const [websitePresent, setWebsitePresent] = React.useState(false);
  const logs$SupportAkurieBasicActivityPOST =
    Logs$SupportAkurieApi.useBasicActivityPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      StatusBar.setBarStyle('dark-content');
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
    >
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <DesktopHeader13024SignedInBlock />
        )}
      </>
      <ImageBackground
        resizeMode={'cover'}
        source={Images.FirefoxPHKyvBthWe}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ImageBackgroundStyles(theme)['Image Background'],
            { height: '100%' }
          ),
          dimensions.width
        )}
      >
        {/* Nav Frame 12-27 */}
        <View
          style={StyleSheet.applyWidth(
            {
              borderBottomWidth: 2,
              borderColor: theme.colors['Akurie green'],
              height: 80,
              marginBottom: 15,
              marginTop: 35,
            },
            dimensions.width
          )}
        >
          <ImageBackground
            resizeMode={'cover'}
            source={Images.Asset13}
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors['Akurie Dark Blue'],
                flexDirection: 'row',
                height: '100%',
              },
              dimensions.width
            )}
          >
            {/* Left Side Frame */}
            <View
              style={StyleSheet.applyWidth(
                { paddingLeft: 9, paddingRight: 9 },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    navigation.goBack();
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* Icon Flex Frame */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexGrow: 0,
                      flexShrink: 0,
                      paddingBottom: 12,
                      paddingLeft: 12,
                      paddingRight: 12,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  {/* Icon  */}
                  <Icon
                    color={theme.colors['Background']}
                    name={'Ionicons/arrow-back-outline'}
                    size={24}
                  />
                </View>
              </Touchable>
            </View>
            {/* Middle Frame */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexGrow: 1,
                  flexShrink: 0,
                  justifyContent: 'center',
                },
                dimensions.width
              )}
            >
              {/* Text Frame  */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              />
            </View>
            {/* Left Side Frame */}
            <View
              style={StyleSheet.applyWidth(
                { paddingLeft: 9, paddingRight: 9 },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('UserDashboardScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* Icon Flex Frame */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexGrow: 0,
                      flexShrink: 0,
                      paddingBottom: 12,
                      paddingLeft: 12,
                      paddingRight: 12,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  {/* Icon  */}
                  <Icon
                    color={theme.colors['Background']}
                    name={'AntDesign/home'}
                    size={24}
                  />
                </View>
              </Touchable>
            </View>
          </ImageBackground>
        </View>

        <ScrollView
          bounces={true}
          contentContainerStyle={StyleSheet.applyWidth(
            { alignItems: { minWidth: Breakpoints.Laptop, value: 'center' } },
            dimensions.width
          )}
          showsHorizontalScrollIndicator={true}
          showsVerticalScrollIndicator={true}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                width: [
                  { minWidth: Breakpoints.Laptop, value: '70%' },
                  { minWidth: Breakpoints.Desktop, value: '50%' },
                ],
              },
              dimensions.width
            )}
          >
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center' },
                dimensions.width
              )}
            >
              <Surface
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.SurfaceStyles(theme)['Surface'],
                    {
                      alignItems: 'center',
                      justifyContent: 'center',
                      minHeight: 60,
                      width: '100%',
                    }
                  ),
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Akurie Dark Blue'],
                      fontFamily: 'Inter_400Regular',
                      fontSize: 24,
                    }),
                    dimensions.width
                  )}
                >
                  {'Website & Digital Services'}
                </Text>
              </Surface>
            </View>

            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: theme.colors['Divider'],
                  justifyContent: 'center',
                  marginTop: 15,
                  padding: 30,
                  paddingLeft: { minWidth: Breakpoints.Tablet, value: 120 },
                  paddingRight: { minWidth: Breakpoints.Tablet, value: 120 },
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    fontFamily: 'Inter_500Medium',
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {
                  'Welcome, here you can manage your website, app, and digital services provided by us and our partners.'
                }
              </Text>
            </View>

            <Web$AppServicesApi.FetchGetApp$WebServiceUserGET
              business_id={Constants['ak_businessID']}
              handlers={{
                onData: fetchData => {
                  try {
                    setGlobalVariableValue({
                      key: 'ak_isLoading',
                      value: false,
                    });
                  } catch (err) {
                    console.error(err);
                  }
                },
              }}
            >
              {({ loading, error, data, refetchGetApp$WebServiceUser }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <>
                    {/* View 3 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          justifyContent: 'space-evenly',
                          marginTop: 20,
                          padding: [
                            { minWidth: Breakpoints.Mobile, value: 20 },
                            { minWidth: Breakpoints.Tablet, value: 40 },
                          ],
                          paddingLeft: {
                            minWidth: Breakpoints.Tablet,
                            value: 120,
                          },
                          paddingRight: {
                            minWidth: Breakpoints.Tablet,
                            value: 120,
                          },
                        },
                        dimensions.width
                      )}
                    >
                      {/* My Website */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: theme.colors['Akurie Dark Blue'],
                            borderRadius: 9,
                            justifyContent: 'center',
                            marginBottom: 10,
                            marginTop: 10,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        {/* View 2 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              borderBottomWidth: 0.5,
                              borderColor: theme.colors['Akurie Dark Blue'],
                              flexDirection: 'row',
                              justifyContent: 'center',
                              minHeight: 60,
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  color: theme.colors['Akurie green'],
                                  fontFamily: 'Inter_600SemiBold',
                                  fontSize: 18,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'My Website'}
                          </Text>
                        </View>

                        <View
                          style={StyleSheet.applyWidth(
                            { minHeight: 10, padding: 10, width: '100%' },
                            dimensions.width
                          )}
                        >
                          {/* My Website */}
                          <Surface
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.SurfaceStyles(theme)['Surface'],
                                {
                                  backgroundColor: theme.colors['Background'],
                                  borderRadius: 9,
                                  padding: 10,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {/* Manage Site */}
                            <>
                              {!fetchData?.websiteServices ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      borderBottomWidth: 0.5,
                                      borderColor: theme.colors['Divider'],
                                      flexDirection: 'column',
                                      justifyContent: 'space-between',
                                      marginBottom: 20,
                                      marginTop: 20,
                                      paddingBottom: 5,
                                      paddingTop: 5,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        maxHeight: 30,
                                        minHeight: 30,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Icon
                                      color={theme.colors['Akurie Dark Blue']}
                                      name={'Ionicons/md-globe-outline'}
                                      size={18}
                                      style={StyleSheet.applyWidth(
                                        { marginRight: 10 },
                                        dimensions.width
                                      )}
                                    />
                                    <Text
                                      accessible={true}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ],
                                          {
                                            fontFamily: 'Inter_600SemiBold',
                                            fontSize: 18,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {fetchData?.domainName}
                                    </Text>
                                  </View>
                                  {/* View 3 */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { alignItems: 'center', marginTop: 10 },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ],
                                          { fontFamily: 'Inter_400Regular' }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Type: '}
                                      {fetchData?.websiteType}
                                    </Text>

                                    <View
                                      style={StyleSheet.applyWidth(
                                        { flexDirection: 'row' },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Active */}
                                      <>
                                        {!(
                                          fetchData?.websiteStatus === 'Active'
                                        ) ? null : (
                                          <Icon
                                            color={theme.colors['Akurie green']}
                                            name={'Entypo/controller-record'}
                                            size={18}
                                            style={StyleSheet.applyWidth(
                                              { marginLeft: 5 },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      {/* Pending */}
                                      <>
                                        {!(
                                          fetchData?.websiteStatus === 'Pending'
                                        ) ? null : (
                                          <Icon
                                            color={
                                              theme.colors['Akurie Primary']
                                            }
                                            name={'Entypo/controller-record'}
                                            size={18}
                                            style={StyleSheet.applyWidth(
                                              { marginLeft: 5 },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      {/* Canceled */}
                                      <>
                                        {!(
                                          fetchData?.websiteStatus ===
                                          'Canceled'
                                        ) ? null : (
                                          <Icon
                                            color={theme.colors['Error']}
                                            name={'Entypo/controller-record'}
                                            size={18}
                                            style={StyleSheet.applyWidth(
                                              { marginLeft: 5 },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      {/* Suspended */}
                                      <>
                                        {!(
                                          fetchData?.websiteStatus ===
                                          'Suspended'
                                        ) ? null : (
                                          <Icon
                                            color={theme.colors['Error']}
                                            name={'Entypo/controller-record'}
                                            size={18}
                                            style={StyleSheet.applyWidth(
                                              { marginLeft: 5 },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      {/* Undergoing Maintenance */}
                                      <>
                                        {!(
                                          fetchData?.websiteStatus ===
                                          'Undergoing Maintenance'
                                        ) ? null : (
                                          <Icon
                                            color={
                                              theme.colors[
                                                'Akurie Primary Dark'
                                              ]
                                            }
                                            name={'Entypo/controller-record'}
                                            size={18}
                                            style={StyleSheet.applyWidth(
                                              { marginLeft: 5 },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      {/* Text 2 */}
                                      <Text
                                        accessible={true}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ],
                                          dimensions.width
                                        )}
                                      >
                                        {fetchData?.websiteStatus}
                                      </Text>
                                    </View>
                                  </View>
                                </View>
                              )}
                            </>
                            {/* Create site */}
                            <>
                              {fetchData?.websiteServices ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      flexDirection: 'column',
                                      justifyContent: 'space-between',
                                      marginBottom: 20,
                                      marginTop: 20,
                                      paddingBottom: 5,
                                      paddingTop: 5,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        maxHeight: 30,
                                        minHeight: 30,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Icon
                                      color={theme.colors['Akurie Dark Blue']}
                                      name={'AntDesign/pluscircleo'}
                                      size={18}
                                      style={StyleSheet.applyWidth(
                                        { marginRight: 10 },
                                        dimensions.width
                                      )}
                                    />
                                    <Text
                                      accessible={true}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ],
                                          {
                                            fontFamily: 'Inter_600SemiBold',
                                            fontSize: 18,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Create A Website\n'}
                                    </Text>
                                  </View>
                                  {/* View 3 */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        marginTop: 10,
                                        paddingLeft: 35,
                                        paddingRight: 35,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ],
                                          {
                                            fontFamily: 'Inter_400Regular',
                                            textAlign: 'center',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {
                                        "Your account includes website creation & management. Let's get started!"
                                      }
                                    </Text>

                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          flexDirection: 'row',
                                          marginTop: 20,
                                          width: '70%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Button
                                        onPress={() => {
                                          const handler = async () => {
                                            try {
                                              (
                                                await logs$SupportAkurieBasicActivityPOST.mutateAsync(
                                                  {
                                                    buttonName:
                                                      'Create Website',
                                                    session_id:
                                                      Constants['Session_ID'],
                                                  }
                                                )
                                              )?.json;
                                              setWebBuildBeginView(true);
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          };
                                          handler();
                                        }}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.ButtonStyles(theme)[
                                              'Button'
                                            ],
                                            {
                                              backgroundColor:
                                                theme.colors['Akurie green'],
                                              borderRadius: 9,
                                              color:
                                                theme.colors[
                                                  'Akurie Dark Blue'
                                                ],
                                              fontFamily: 'Inter_700Bold',
                                              width: '100%',
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                        title={'Get Started'}
                                      />
                                    </View>
                                  </View>
                                </View>
                              )}
                            </>
                            {/* View 5 */}
                            <>
                              {!fetchData?.websiteServices ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    { width: '100%' },
                                    dimensions.width
                                  )}
                                >
                                  {/* View 3 */}
                                  <>
                                    {!(
                                      fetchData?.domainPurchase !== 'None'
                                    ) ? null : (
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'center',
                                            borderBottomWidth: 0.5,
                                            borderColor:
                                              theme.colors['Divider'],
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            paddingBottom: 5,
                                            paddingLeft: 10,
                                            paddingRight: 10,
                                            paddingTop: 5,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <View
                                          style={StyleSheet.applyWidth(
                                            { flexDirection: 'row' },
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            accessible={true}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ],
                                                {
                                                  fontFamily:
                                                    'Inter_600SemiBold',
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {'Domain'}
                                          </Text>
                                        </View>
                                        {/* View 3 */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            { alignItems: 'flex-end' },
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            accessible={true}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ],
                                                { fontFamily: 'Inter_300Light' }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {'Expires'}
                                          </Text>
                                          {/* Text 2 */}
                                          <Text
                                            accessible={true}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ],
                                                {
                                                  fontFamily: 'Inter_500Medium',
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {fetchData?.domainExpiration}
                                          </Text>
                                        </View>
                                      </View>
                                    )}
                                  </>
                                  {/* View 5 */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        borderBottomWidth: 0.5,
                                        borderColor: theme.colors['Divider'],
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        paddingBottom: 5,
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                        paddingTop: 5,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { flexDirection: 'row' },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ],
                                            { fontFamily: 'Inter_600SemiBold' }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {'Web Hosting'}
                                      </Text>
                                    </View>
                                    {/* View 3 */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { alignItems: 'flex-end' },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ],
                                            { fontFamily: 'Inter_300Light' }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {'Renewal Date'}
                                      </Text>
                                      {/* Text 2 */}
                                      <Text
                                        accessible={true}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ],
                                            { fontFamily: 'Inter_500Medium' }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {fetchData?.hosting_renewal_date}
                                      </Text>
                                    </View>
                                  </View>

                                  <View>
                                    <Button
                                      onPress={() => {
                                        try {
                                          navigation.navigate(
                                            'ManageMySiteScreen'
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ButtonStyles(theme)[
                                            'Button'
                                          ],
                                          {
                                            borderRadius: 9,
                                            fontFamily: 'Inter_700Bold',
                                            marginTop: 20,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                      title={'Manage Site'}
                                    />
                                  </View>
                                </View>
                              )}
                            </>
                          </Surface>
                        </View>
                      </View>
                      {/* Web Services */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: theme.colors['Akurie Dark Blue'],
                            borderRadius: 9,
                            justifyContent: 'center',
                            marginBottom: 120,
                            marginTop: 10,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        {/* View 2 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              borderBottomWidth: 0.5,
                              borderColor: theme.colors['Akurie Dark Blue'],
                              flexDirection: 'row',
                              justifyContent: 'center',
                              minHeight: 60,
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  color: theme.colors['Akurie green'],
                                  fontFamily: 'Inter_600SemiBold',
                                  fontSize: 18,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'My Web Services'}
                          </Text>
                        </View>

                        <View
                          style={StyleSheet.applyWidth(
                            { minHeight: 10, padding: 10, width: '100%' },
                            dimensions.width
                          )}
                        >
                          {/* Web Services */}
                          <Surface
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.SurfaceStyles(theme)['Surface'],
                                {
                                  backgroundColor: theme.colors['Background'],
                                  borderRadius: 9,
                                  padding: 10,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {/* View 3 */}
                            <>
                              {!fetchData?.additionalServices ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      backgroundColor: theme.colors['Divider'],
                                      borderRadius: 9,
                                      marginTop: 10,
                                      paddingBottom: 10,
                                      paddingLeft: 35,
                                      paddingRight: 35,
                                      paddingTop: 10,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* View 2 */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        padding: 10,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Icon
                                      color={theme.colors['Akurie Dark Blue']}
                                      name={'MaterialIcons/home-repair-service'}
                                      size={24}
                                      style={StyleSheet.applyWidth(
                                        { marginRight: 10 },
                                        dimensions.width
                                      )}
                                    />
                                    <Text
                                      accessible={true}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ],
                                          {
                                            fontFamily: 'Inter_600SemiBold',
                                            fontSize: 16,
                                            textAlign: 'left',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Active Services: '}
                                      {null}
                                      {'\nPrevious Services: '}
                                      {null}
                                    </Text>
                                  </View>

                                  <View
                                    style={StyleSheet.applyWidth(
                                      { flexDirection: 'row', marginTop: 20 },
                                      dimensions.width
                                    )}
                                  >
                                    <Button
                                      onPress={() => {
                                        try {
                                          navigation.navigate(
                                            'ManageMyServicesScreen'
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ButtonStyles(theme)[
                                            'Button'
                                          ],
                                          {
                                            borderRadius: 9,
                                            fontFamily: 'Inter_700Bold',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                      title={'Manage Services'}
                                    />
                                  </View>
                                </View>
                              )}
                            </>
                            {/* View 3 */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  backgroundColor:
                                    theme.colors['Akurie Dark Blue'],
                                  borderBottomWidth: 0.5,
                                  borderColor: theme.colors['Akurie Dark Blue'],
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  marginTop: 20,
                                  minHeight: 60,
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      color: theme.colors['Akurie green'],
                                      fontFamily: 'Inter_600SemiBold',
                                      fontSize: 18,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Additional Services'}
                              </Text>
                            </View>
                            {/* Create site */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  flexDirection: 'column',
                                  justifyContent: 'space-between',
                                  marginBottom: 20,
                                  marginTop: 20,
                                  paddingBottom: 5,
                                  paddingTop: 5,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Touchable 4 */}
                              <Touchable
                                style={StyleSheet.applyWidth(
                                  { width: '100%' },
                                  dimensions.width
                                )}
                              >
                                {/* Surface 4 */}
                                <Surface
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.SurfaceStyles(theme)[
                                        'Surface'
                                      ],
                                      {
                                        alignItems: 'center',
                                        borderRadius: 9,
                                        marginBottom: 5,
                                        marginTop: 5,
                                        padding: 8,
                                        width: '100%',
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {/* View 2 */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          flexDirection: 'row',
                                          justifyContent: 'center',
                                          maxHeight: 30,
                                          minHeight: 30,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Icon
                                        color={theme.colors['Akurie Dark Blue']}
                                        name={'AntDesign/tag'}
                                        size={18}
                                        style={StyleSheet.applyWidth(
                                          { marginRight: 10 },
                                          dimensions.width
                                        )}
                                      />
                                      <Text
                                        accessible={true}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ],
                                            {
                                              fontFamily: 'Inter_600SemiBold',
                                              fontSize: 16,
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {'Technical Consulting'}
                                      </Text>
                                    </View>
                                    {/* Text 2 */}
                                    <Text
                                      accessible={true}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ],
                                          {
                                            fontFamily: 'Inter_400Regular',
                                            fontSize: 14,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'$35.00 per hour'}
                                    </Text>
                                  </View>
                                </Surface>
                              </Touchable>
                              {/* Touchable 3 */}
                              <Touchable
                                style={StyleSheet.applyWidth(
                                  { width: '100%' },
                                  dimensions.width
                                )}
                              >
                                {/* Surface 3 */}
                                <Surface
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.SurfaceStyles(theme)[
                                        'Surface'
                                      ],
                                      {
                                        alignItems: 'center',
                                        borderRadius: 9,
                                        marginBottom: 5,
                                        marginTop: 5,
                                        padding: 8,
                                        width: '100%',
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {/* View 2 */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          flexDirection: 'row',
                                          justifyContent: 'center',
                                          maxHeight: 30,
                                          minHeight: 30,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Icon
                                        color={theme.colors['Akurie Dark Blue']}
                                        name={'AntDesign/tag'}
                                        size={18}
                                        style={StyleSheet.applyWidth(
                                          { marginRight: 10 },
                                          dimensions.width
                                        )}
                                      />
                                      <Text
                                        accessible={true}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ],
                                            {
                                              fontFamily: 'Inter_600SemiBold',
                                              fontSize: 16,
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {'Web UI/UX Design\n'}
                                      </Text>
                                    </View>
                                    {/* Text 2 */}
                                    <Text
                                      accessible={true}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ],
                                          {
                                            fontFamily: 'Inter_400Regular',
                                            fontSize: 14,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'starting at $350.00'}
                                    </Text>
                                  </View>
                                </Surface>
                              </Touchable>
                              {/* Touchable 5 */}
                              <Touchable
                                style={StyleSheet.applyWidth(
                                  { width: '100%' },
                                  dimensions.width
                                )}
                              >
                                {/* Surface 5 */}
                                <Surface
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.SurfaceStyles(theme)[
                                        'Surface'
                                      ],
                                      {
                                        alignItems: 'center',
                                        borderRadius: 9,
                                        marginBottom: 5,
                                        marginTop: 5,
                                        padding: 8,
                                        width: '100%',
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {/* View 2 */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          flexDirection: 'row',
                                          justifyContent: 'center',
                                          maxHeight: 30,
                                          minHeight: 30,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Icon
                                        color={theme.colors['Akurie Dark Blue']}
                                        name={'AntDesign/tag'}
                                        size={18}
                                        style={StyleSheet.applyWidth(
                                          { marginRight: 10 },
                                          dimensions.width
                                        )}
                                      />
                                      <Text
                                        accessible={true}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ],
                                            {
                                              fontFamily: 'Inter_600SemiBold',
                                              fontSize: 16,
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {'Custom Web Integrations'}
                                      </Text>
                                    </View>
                                    {/* Text 2 */}
                                    <Text
                                      accessible={true}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ],
                                          {
                                            fontFamily: 'Inter_400Regular',
                                            fontSize: 14,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'starting at $250.00'}
                                    </Text>
                                  </View>
                                </Surface>
                              </Touchable>
                              {/* View 4 */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    marginTop: 10,
                                    paddingLeft: 35,
                                    paddingRight: 35,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      {
                                        fontFamily: 'Inter_400Regular',
                                        textAlign: 'center',
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {
                                    'Contact your assistant or click below to learn more. About pricing details & financing options. '
                                  }
                                </Text>

                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexDirection: 'row',
                                      marginTop: 20,
                                      width: '95%',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Button
                                    onPress={() => {
                                      const handler = async () => {
                                        try {
                                          await WebBrowser.openBrowserAsync(
                                            'https://calendly.com/akurie/30min'
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      };
                                      handler();
                                    }}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ButtonStyles(theme)[
                                          'Button'
                                        ],
                                        {
                                          backgroundColor:
                                            theme.colors['Akurie green'],
                                          borderRadius: 9,
                                          color:
                                            theme.colors['Akurie Dark Blue'],
                                          fontFamily: 'Inter_700Bold',
                                          width: '100%',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                    title={'Schedule A Setup Meeting'}
                                  />
                                </View>
                              </View>
                            </View>
                            {/* View 5 */}
                            <View
                              style={StyleSheet.applyWidth(
                                { width: '100%' },
                                dimensions.width
                              )}
                            >
                              <View />
                            </View>
                          </Surface>
                        </View>
                      </View>
                    </View>
                  </>
                );
              }}
            </Web$AppServicesApi.FetchGetApp$WebServiceUserGET>
            {/* View 4 */}
            <View />
          </View>
        </ScrollView>
        {/* newBlurView */}
        <>
          {!webBuildBeginView ? null : (
            <BlurView
              intensity={80}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.BlurViewStyles(theme)['Blur View'],
                  {
                    alignItems: 'center',
                    height: '100%',
                    justifyContent: 'center',
                    position: 'absolute',
                    width: '100%',
                    zIndex: 10,
                  }
                ),
                dimensions.width
              )}
              tint={'dark'}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: theme.colors['Akurie Dark Blue'],
                    borderBottomWidth: 0.1,
                    borderColor: theme.colors['Akurie green'],
                    borderRadius: 19,
                    borderTopWidth: 0.1,
                    bottom: 50,
                    height: [
                      { minWidth: Breakpoints.Tablet, value: '80%' },
                      { minWidth: Breakpoints.Laptop, value: '60%' },
                      { minWidth: Breakpoints.Mobile, value: '80%' },
                    ],
                    justifyContent: 'center',
                    marginTop: 50,
                    opacity: 1,
                    width: [
                      { minWidth: Breakpoints.Mobile, value: '90%' },
                      { minWidth: Breakpoints.Tablet, value: '70%' },
                      { minWidth: Breakpoints.Desktop, value: '50%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                <Surface
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.SurfaceStyles(theme)['Surface'],
                      {
                        alignItems: 'center',
                        backgroundColor: theme.colors['Akurie Dark Blue'],
                        borderRadius: 19,
                        height: '90%',
                        justifyContent: 'space-around',
                        opacity: 1,
                        padding: 20,
                        width: '90%',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {/* View 3 */}
                  <View
                    style={StyleSheet.applyWidth(
                      { width: '100%' },
                      dimensions.width
                    )}
                  >
                    {/* View 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'flex-end', width: '100%' },
                        dimensions.width
                      )}
                    >
                      <IconButton
                        color={theme.colors['Akurie green']}
                        icon={'AntDesign/closecircle'}
                        onPress={() => {
                          try {
                            setWebBuildBeginView(false);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        size={26}
                      />
                    </View>

                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          marginBottom: 30,
                          padding: 2,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Akurie green'],
                              fontFamily: 'Inter_600SemiBold',
                              fontSize: 18,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Let the building Begin!'}
                      </Text>
                      <Image
                        resizeMode={'cover'}
                        source={Images.Asset60}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ImageStyles(theme)['Image'],
                            { height: 100, marginBottom: 10, marginTop: 10 }
                          ),
                          dimensions.width
                        )}
                      />
                    </View>
                    {/* View 4 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          marginBottom: 10,
                          minHeight: 30,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        dataDetectorType={'none'}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Strong Inverse'],
                              fontFamily: 'Inter_400Regular',
                              fontSize: 14,
                              marginBottom: 5,
                              textAlign: 'center',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {
                          "At Akurie, our expert developers and AI tools work together to build your website with a focus on Google's SEO best practices and relevant industry keywords. This ensures your site is not only tailored to your business but also optimized for better online visibility. First, we just have a few questions."
                        }
                      </Text>
                    </View>

                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'column',
                          justifyContent: 'flex-start',
                          marginBottom: 10,
                          marginTop: 10,
                          minHeight: 30,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <Button
                        onPress={() => {
                          try {
                            setWebBuildBeginView(false);
                            navigation.navigate('WebsiteBuildInfoScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ButtonStyles(theme)['Button'],
                            {
                              backgroundColor: theme.colors['Akurie green'],
                              borderRadius: 9,
                              color: theme.colors['Akurie Dark Blue'],
                              fontFamily: 'Inter_700Bold',
                              width: '90%',
                            }
                          ),
                          dimensions.width
                        )}
                        title={'Continue'}
                      />
                    </View>
                  </View>
                </Surface>
              </View>
            </BlurView>
          )}
        </>
        {/* loadingView */}
        <>
          {!Constants['ak_isLoading'] ? null : (
            <BlurView
              intensity={80}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.BlurViewStyles(theme)['Blur View'],
                  {
                    alignItems: 'center',
                    height: '100%',
                    justifyContent: 'center',
                    position: 'absolute',
                    width: '100%',
                    zIndex: 10,
                  }
                ),
                dimensions.width
              )}
              tint={'dark'}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: theme.colors['Akurie Dark Blue'],
                    borderRadius: 19,
                    bottom: 50,
                    height: [
                      { minWidth: Breakpoints.Mobile, value: '40%' },
                      { minWidth: Breakpoints.Tablet, value: '60%' },
                      { minWidth: Breakpoints.Laptop, value: '70%' },
                    ],
                    justifyContent: 'center',
                    opacity: 1,
                    width: [
                      { minWidth: Breakpoints.Tablet, value: '60%' },
                      { minWidth: Breakpoints.Laptop, value: '50%' },
                      { minWidth: Breakpoints.Desktop, value: '40%' },
                      { minWidth: Breakpoints.Mobile, value: '70%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                <IconButton
                  color={theme.colors['Akurie Primary']}
                  icon={'Feather/refresh-ccw'}
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'ak_isLoading',
                        value: false,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  size={32}
                  style={StyleSheet.applyWidth(
                    { position: 'absolute', right: 10, top: 10 },
                    dimensions.width
                  )}
                />
                <CircularProgress
                  animationDuration={7000}
                  color={theme.colors['Akurie green']}
                  indeterminate={true}
                  isAnimated={true}
                  lineCap={'round'}
                  showTrack={true}
                  startPosition={'bottom'}
                  style={StyleSheet.applyWidth(
                    { bottom: -20, height: '70%', width: '70%' },
                    dimensions.width
                  )}
                  thickness={15}
                  trackColor={theme.colors.divider}
                  trackLineCap={'round'}
                />
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Strong Inverse'],
                      fontFamily: 'Inter_600SemiBold',
                      fontSize: 20,
                      marginTop: 10,
                    }),
                    dimensions.width
                  )}
                >
                  {'Loading Services'}
                </Text>
              </View>
            </BlurView>
          )}
        </>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(MyAkurieWebServicesScreen);
