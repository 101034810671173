import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AuthAkurieApi from '../apis/AuthAkurieApi.js';
import * as Logs$SupportAkurieApi from '../apis/Logs$SupportAkurieApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import revCatLogin from '../global-functions/revCatLogin';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Checkbox,
  Circle,
  DatePicker,
  Icon,
  IconButton,
  Picker,
  ScreenContainer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import {
  Image,
  ImageBackground,
  Keyboard,
  StatusBar,
  Text,
  View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const Signuppt1Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [ConsentNobox, setConsentNobox] = React.useState(false);
  const [ConsentYesbox, setConsentYesbox] = React.useState(false);
  const [birthdayPickerValue, setBirthdayPickerValue] = React.useState(null);
  const [checkboxRowValue, setCheckboxRowValue] = React.useState('');
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [emailInputValue, setEmailInputValue] = React.useState('');
  const [errorMsg, setErrorMsg] = React.useState('');
  const [jobTitlePickerValue, setJobTitlePickerValue] = React.useState('');
  const [job_titles, setJob_titles] = React.useState([
    'Founder',
    'Owner',
    'President',
    'CEO',
    'Manager',
    'Member',
    'Administrative Assistant',
    'Bookkeeper / Accountant',
    'Office Manager',
    'Web Manager',
  ]);
  const [passwordInputValue, setPasswordInputValue] = React.useState('');
  const logs$SupportAkurieBasicActivityPOST =
    Logs$SupportAkurieApi.useBasicActivityPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      StatusBar.setBarStyle('dark-content');
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasBottomSafeArea={false}
      hasSafeArea={false}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: theme.colors['Background'],
          justifyContent: 'space-between',
        },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'cover'}
        source={Images.FirefoxPHKyvBthWe}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ImageBackgroundStyles(theme)['Image Background'],
            { height: '100%', justifyContent: 'center' }
          ),
          dimensions.width
        )}
      >
        <KeyboardAwareScrollView
          contentContainerStyle={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              justifyContent: { minWidth: Breakpoints.Tablet, value: 'center' },
              marginTop: 10,
              paddingLeft: [
                { minWidth: Breakpoints.Tablet, value: '10%' },
                { minWidth: Breakpoints.Laptop, value: '0%' },
              ],
              paddingRight: [
                { minWidth: Breakpoints.Laptop, value: '0%' },
                { minWidth: Breakpoints.Tablet, value: '10%' },
              ],
              paddingTop: [
                { minWidth: Breakpoints.Tablet, value: 50 },
                { minWidth: Breakpoints.Laptop, value: 80 },
              ],
            },
            dimensions.width
          )}
          enableAutomaticScroll={true}
          enableOnAndroid={false}
          enableResetScrollToCoords={false}
          extraHeight={100}
          extraScrollHeight={100}
          keyboardShouldPersistTaps={'always'}
          showsVerticalScrollIndicator={false}
        >
          {/* akurie pwa header logo */}
          <View
            style={StyleSheet.applyWidth(
              { width: { minWidth: Breakpoints.Laptop, value: '80%' } },
              dimensions.width
            )}
          >
            <Image
              resizeMode={'contain'}
              source={Images.MainLogoUpdate2u}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                  height: { minWidth: Breakpoints.Laptop, value: 50 },
                  width: { minWidth: Breakpoints.Laptop, value: '30%' },
                }),
                dimensions.width
              )}
            />
          </View>

          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: [
                  { minWidth: Breakpoints.Laptop, value: 'center' },
                  { minWidth: Breakpoints.Mobile, value: 'center' },
                ],
                flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
                justifyContent: [
                  { minWidth: Breakpoints.Laptop, value: 'center' },
                  { minWidth: Breakpoints.Mobile, value: 'center' },
                ],
                width: '100%',
              },
              dimensions.width
            )}
          >
            {/* Master Form View 2 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: [
                        {
                          minWidth: Breakpoints.Tablet,
                          value: theme.colors['Akurie Dark Blue'],
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors['Akurie Dark Blue'],
                        },
                      ],
                      borderBottomLeftRadius: {
                        minWidth: Breakpoints.Laptop,
                        value: 12,
                      },
                      borderRadius: [
                        { minWidth: Breakpoints.Tablet, value: 12 },
                        { minWidth: Breakpoints.Mobile, value: 12 },
                        { minWidth: Breakpoints.Laptop, value: 0 },
                      ],
                      borderTopLeftRadius: {
                        minWidth: Breakpoints.Laptop,
                        value: 12,
                      },
                      height: [
                        { minWidth: Breakpoints.Tablet, value: '80%' },
                        { minWidth: Breakpoints.Laptop, value: '85%' },
                        { minWidth: Breakpoints.Desktop, value: '90%' },
                        { minWidth: Breakpoints.BigScreen, value: '85%' },
                      ],
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      marginBottom: [
                        { minWidth: Breakpoints.Mobile, value: 80 },
                        { minWidth: Breakpoints.Laptop, value: 20 },
                      ],
                      marginTop: [
                        { minWidth: Breakpoints.Mobile, value: 80 },
                        { minWidth: Breakpoints.Laptop, value: 20 },
                      ],
                      opacity: [
                        { minWidth: Breakpoints.Tablet, value: 0.95 },
                        { minWidth: Breakpoints.Mobile, value: 0.95 },
                      ],
                      padding: [
                        { minWidth: Breakpoints.Tablet, value: 20 },
                        { minWidth: Breakpoints.Mobile, value: 10 },
                        { minWidth: Breakpoints.BigScreen, value: 40 },
                      ],
                      paddingLeft: [
                        { minWidth: Breakpoints.Mobile, value: 20 },
                        { minWidth: Breakpoints.BigScreen, value: 40 },
                        { minWidth: Breakpoints.Desktop, value: 40 },
                        { minWidth: Breakpoints.Laptop, value: 10 },
                      ],
                      paddingRight: [
                        { minWidth: Breakpoints.Mobile, value: 20 },
                        { minWidth: Breakpoints.BigScreen, value: 40 },
                        { minWidth: Breakpoints.Desktop, value: 40 },
                        { minWidth: Breakpoints.Laptop, value: 10 },
                      ],
                      width: [
                        { minWidth: Breakpoints.Mobile, value: '90%' },
                        { minWidth: Breakpoints.Desktop, value: '25%' },
                        { minWidth: Breakpoints.Laptop, value: '40%' },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        marginBottom: 10,
                        marginTop: 10,
                        padding: 5,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <>
                      {!(dimensions.width >= Breakpoints.Tablet) ? null : (
                        <IconButton
                          color={theme.colors['Akurie green']}
                          icon={'MaterialCommunityIcons/arrow-left-circle'}
                          onPress={() => {
                            try {
                              navigation.goBack();
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          size={40}
                          style={StyleSheet.applyWidth(
                            { marginRight: 10 },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Strong_Inverse'],
                            fontFamily: 'Inter_400Regular',
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 22 },
                              { minWidth: Breakpoints.Laptop, value: 18 },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Create Your Profile'}
                    </Text>
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: [
                          { minWidth: Breakpoints.Mobile, value: 'row' },
                          { minWidth: Breakpoints.Laptop, value: 'column' },
                        ],
                        justifyContent: 'center',
                        marginBottom: 10,
                        marginTop: 10,
                        padding: 5,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: [
                              {
                                minWidth: Breakpoints.Mobile,
                                value: theme.colors['Strong_Inverse'],
                              },
                              {
                                minWidth: Breakpoints.Laptop,
                                value: theme.colors['Akurie Primary'],
                              },
                            ],
                            fontFamily: [
                              {
                                minWidth: Breakpoints.Mobile,
                                value: 'Inter_400Regular',
                              },
                              {
                                minWidth: Breakpoints.Laptop,
                                value: 'Inter_400Regular',
                              },
                              {
                                minWidth: Breakpoints.BigScreen,
                                value: 'Inter_600SemiBold',
                              },
                            ],
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 22 },
                              { minWidth: Breakpoints.Laptop, value: 32 },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {"Let's Get Started!"}
                    </Text>
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        flexDirection: [
                          { minWidth: Breakpoints.Mobile, value: 'row' },
                          { minWidth: Breakpoints.Laptop, value: 'column' },
                        ],
                        justifyContent: 'space-evenly',
                        padding: { minWidth: Breakpoints.Laptop, value: 5 },
                        width: { minWidth: Breakpoints.Laptop, value: '100%' },
                      },
                      dimensions.width
                    )}
                  >
                    {/* First Name View */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          paddingBottom: [
                            { minWidth: Breakpoints.Mobile, value: 5 },
                            { minWidth: Breakpoints.Laptop, value: 10 },
                          ],
                          paddingTop: [
                            { minWidth: Breakpoints.Mobile, value: 5 },
                            { minWidth: Breakpoints.Laptop, value: 10 },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      {/* First Name */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Akurie green'],
                            fontFamily: 'Inter_600SemiBold',
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 16 },
                              { minWidth: Breakpoints.Laptop, value: 18 },
                            ],
                          },
                          dimensions.width
                        )}
                      >
                        {'First Name'}
                      </Text>
                      {/* First Name Pass */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Strong Inverse'],
                            fontFamily: 'Inter_400Regular',
                            fontSize: [
                              { minWidth: Breakpoints.BigScreen, value: 18 },
                              { minWidth: Breakpoints.Mobile, value: 20 },
                              { minWidth: Breakpoints.Laptop, value: 16 },
                            ],
                          },
                          dimensions.width
                        )}
                      >
                        {props.route?.params?.Fname ?? ''}
                      </Text>
                    </View>
                    {/* Last Name View */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          paddingBottom: [
                            { minWidth: Breakpoints.Mobile, value: 5 },
                            { minWidth: Breakpoints.Laptop, value: 10 },
                          ],
                          paddingTop: [
                            { minWidth: Breakpoints.Mobile, value: 5 },
                            { minWidth: Breakpoints.Laptop, value: 10 },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      {/* Last Name */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Akurie green'],
                            fontFamily: 'Inter_600SemiBold',
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 16 },
                              { minWidth: Breakpoints.Laptop, value: 18 },
                            ],
                          },
                          dimensions.width
                        )}
                      >
                        {'Last Name'}
                      </Text>
                      {/* Last Name Pass */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Background'],
                            fontFamily: 'Inter_400Regular',
                            fontSize: [
                              { minWidth: Breakpoints.BigScreen, value: 18 },
                              { minWidth: Breakpoints.Mobile, value: 20 },
                              { minWidth: Breakpoints.Laptop, value: 16 },
                            ],
                          },
                          dimensions.width
                        )}
                      >
                        {props.route?.params?.Lname ?? ''}
                      </Text>
                    </View>
                  </View>
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        marginBottom: {
                          minWidth: Breakpoints.Laptop,
                          value: 10,
                        },
                        marginTop: { minWidth: Breakpoints.Laptop, value: 10 },
                      },
                      dimensions.width
                    )}
                  ></View>
                </View>
              )}
            </>
            {/* Master Form View */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: [
                    {
                      minWidth: Breakpoints.Tablet,
                      value: theme.colors['Akurie Dark Blue'],
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: theme.colors['Akurie Dark Blue'],
                    },
                  ],
                  borderColor: {
                    minWidth: Breakpoints.Laptop,
                    value: theme.colors['Medium'],
                  },
                  borderLeftWidth: { minWidth: Breakpoints.Laptop, value: 0.5 },
                  borderRadius: [
                    { minWidth: Breakpoints.Tablet, value: 12 },
                    { minWidth: Breakpoints.Mobile, value: 12 },
                    { minWidth: Breakpoints.Laptop, value: 12 },
                  ],
                  height: [
                    { minWidth: Breakpoints.Tablet, value: '80%' },
                    { minWidth: Breakpoints.Desktop, value: '95%' },
                    { minWidth: Breakpoints.BigScreen, value: '95%' },
                    { minWidth: Breakpoints.Laptop, value: '90%' },
                  ],
                  justifyContent: [
                    { minWidth: Breakpoints.BigScreen, value: 'center' },
                    { minWidth: Breakpoints.Laptop, value: 'center' },
                  ],
                  marginBottom: [
                    { minWidth: Breakpoints.Mobile, value: 80 },
                    { minWidth: Breakpoints.Laptop, value: 20 },
                  ],
                  marginTop: [
                    { minWidth: Breakpoints.Mobile, value: 80 },
                    { minWidth: Breakpoints.Laptop, value: 20 },
                  ],
                  opacity: [
                    { minWidth: Breakpoints.Tablet, value: 0.95 },
                    { minWidth: Breakpoints.Mobile, value: 0.95 },
                  ],
                  padding: [
                    { minWidth: Breakpoints.Tablet, value: 20 },
                    { minWidth: Breakpoints.Mobile, value: 10 },
                    { minWidth: Breakpoints.BigScreen, value: 40 },
                  ],
                  paddingBottom: 20,
                  paddingLeft: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.BigScreen, value: 40 },
                    { minWidth: Breakpoints.Desktop, value: 40 },
                    { minWidth: Breakpoints.Laptop, value: 20 },
                  ],
                  paddingRight: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.BigScreen, value: 40 },
                    { minWidth: Breakpoints.Desktop, value: 40 },
                    { minWidth: Breakpoints.Laptop, value: 20 },
                  ],
                  width: [
                    { minWidth: Breakpoints.Mobile, value: '90%' },
                    { minWidth: Breakpoints.Laptop, value: '30%' },
                    { minWidth: Breakpoints.Desktop, value: '20%' },
                  ],
                },
                dimensions.width
              )}
            >
              <>
                {dimensions.width >= Breakpoints.Laptop ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        marginBottom: 10,
                        marginTop: 10,
                        padding: 5,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <IconButton
                      color={theme.colors['Akurie Primary']}
                      icon={'MaterialCommunityIcons/arrow-left-circle'}
                      onPress={() => {
                        try {
                          navigation.goBack();
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      size={40}
                      style={StyleSheet.applyWidth(
                        { marginRight: 10 },
                        dimensions.width
                      )}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Strong_Inverse'],
                            fontFamily: 'Inter_400Regular',
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 22 },
                              { minWidth: Breakpoints.Laptop, value: 18 },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Create Your Profile'}
                    </Text>
                  </View>
                )}
              </>
              <>
                {dimensions.width >= Breakpoints.Laptop ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    {/* First Name View */}
                    <View
                      style={StyleSheet.applyWidth(
                        { paddingBottom: 5, paddingTop: 5 },
                        dimensions.width
                      )}
                    >
                      {/* First Name */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Akurie green'],
                            fontFamily: 'Inter_600SemiBold',
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 16 },
                              { minWidth: Breakpoints.Laptop, value: 14 },
                            ],
                          },
                          dimensions.width
                        )}
                      >
                        {'First Name'}
                      </Text>
                      {/* First Name Pass */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Strong Inverse'],
                            fontFamily: 'Inter_400Regular',
                            fontSize: [
                              { minWidth: Breakpoints.Laptop, value: 16 },
                              { minWidth: Breakpoints.BigScreen, value: 18 },
                              { minWidth: Breakpoints.Mobile, value: 20 },
                            ],
                          },
                          dimensions.width
                        )}
                      >
                        {props.route?.params?.Fname ?? ''}
                      </Text>
                    </View>
                    {/* Last Name View */}
                    <View
                      style={StyleSheet.applyWidth(
                        { paddingBottom: 5, paddingTop: 5 },
                        dimensions.width
                      )}
                    >
                      {/* Last Name */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Akurie green'],
                            fontFamily: 'Inter_600SemiBold',
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 16 },
                              { minWidth: Breakpoints.Laptop, value: 14 },
                            ],
                          },
                          dimensions.width
                        )}
                      >
                        {'Last Name'}
                      </Text>
                      {/* Last Name Pass */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Background'],
                            fontFamily: 'Inter_400Regular',
                            fontSize: [
                              { minWidth: Breakpoints.Laptop, value: 16 },
                              { minWidth: Breakpoints.BigScreen, value: 18 },
                              { minWidth: Breakpoints.Mobile, value: 20 },
                            ],
                          },
                          dimensions.width
                        )}
                      >
                        {props.route?.params?.Lname ?? ''}
                      </Text>
                    </View>
                  </View>
                )}
              </>
              {/* Form */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'stretch',
                    marginTop: 10,
                    opacity: 1,
                    width: [
                      { minWidth: Breakpoints.Mobile, value: '100%' },
                      { minWidth: Breakpoints.Laptop, value: '95%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                {/* Email View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    { paddingBottom: 5, paddingTop: 5 },
                    dimensions.width
                  )}
                >
                  {/* Email */}
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Strong Inverse'],
                        fontFamily: 'Inter_600SemiBold',
                        fontSize: { minWidth: Breakpoints.Laptop, value: 13 },
                        marginBottom: 5,
                      },
                      dimensions.width
                    )}
                  >
                    {'Email'}
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          { color: theme.colors['Error'] }
                        ),
                        dimensions.width
                      )}
                    >
                      {'*'}
                    </Text>
                  </Text>
                  {/* Email Input */}
                  <TextInput
                    enablesReturnKeyAutomatically={false}
                    onChangeText={newEmailInputValue => {
                      try {
                        setEmailInputValue(newEmailInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    onSubmitEditing={() => {
                      try {
                        Keyboard.dismiss();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'abc@email.com'}
                    placeholderTextColor={theme.colors['Communial_Icon_Gray']}
                    spellcheck={true}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Background'],
                        borderBottomWidth: 1,
                        borderColor: theme.colors['Communial_Icon_Gray'],
                        borderLeftWidth: 1,
                        borderRadius: 8,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        fontFamily: 'Inter_400Regular',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 16 },
                          { minWidth: Breakpoints.Laptop, value: 14 },
                        ],
                        height: [
                          { minWidth: Breakpoints.Mobile, value: 60 },
                          { minWidth: Breakpoints.Laptop, value: 60 },
                        ],
                        paddingBottom: 12,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 12,
                      },
                      dimensions.width
                    )}
                    textContentType={'emailAddress'}
                    value={emailInputValue}
                  />
                </View>
                {/* Title View */}
                <View
                  style={StyleSheet.applyWidth(
                    { paddingBottom: 5, paddingTop: 5 },
                    dimensions.width
                  )}
                >
                  {/* Title */}
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Strong Inverse'],
                        fontFamily: 'Inter_600SemiBold',
                        fontSize: { minWidth: Breakpoints.Laptop, value: 13 },
                        marginBottom: 5,
                        marginTop: 10,
                      },
                      dimensions.width
                    )}
                  >
                    {'Title'}
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          { color: theme.colors['Error'] }
                        ),
                        dimensions.width
                      )}
                    >
                      {'*'}
                    </Text>
                  </Text>
                  {/* Job Title Picker */}
                  <Picker
                    iconColor={theme.colors['Akurie Primary']}
                    iconSize={24}
                    leftIconMode={'inset'}
                    onValueChange={newJobTitlePickerValue => {
                      try {
                        setJobTitlePickerValue(newJobTitlePickerValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    options={job_titles}
                    placeholder={'Select Your Title / Position'}
                    placeholderTextColor={theme.colors['Communial_Icon_Gray']}
                    rightIconName={'AntDesign/downcircle'}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Background'],
                        borderColor: theme.colors['Communial_Icon_Gray'],
                        color: theme.colors['Strong'],
                        fontFamily: 'Inter_400Regular',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 16 },
                          { minWidth: Breakpoints.Laptop, value: 14 },
                        ],
                        paddingRight: 15,
                      },
                      dimensions.width
                    )}
                    type={'solid'}
                    value={jobTitlePickerValue}
                  />
                </View>
                {/* Birthday View */}
                <View
                  style={StyleSheet.applyWidth(
                    { paddingBottom: 5, paddingTop: 5 },
                    dimensions.width
                  )}
                >
                  {/* Birthday */}
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Strong Inverse'],
                        fontFamily: 'Inter_600SemiBold',
                        fontSize: { minWidth: Breakpoints.Laptop, value: 13 },
                        marginBottom: 5,
                        marginTop: 10,
                      },
                      dimensions.width
                    )}
                  >
                    {'Birthday - Must be 18 years or older.'}
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          { color: theme.colors['Error'] }
                        ),
                        dimensions.width
                      )}
                    >
                      {'*'}
                    </Text>
                  </Text>
                  {/* Birthday Picker */}
                  <DatePicker
                    date={birthdayPickerValue}
                    label={'Date'}
                    leftIconMode={'inset'}
                    mode={'date'}
                    onDateChange={newBirthdayPickerValue => {
                      try {
                        setBirthdayPickerValue(newBirthdayPickerValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Background'],
                        borderColor: theme.colors['Communial_Icon_Gray'],
                        borderRadius: 9,
                        fontFamily: 'Inter_400Regular',
                        fontSize: 16,
                      },
                      dimensions.width
                    )}
                    type={'solid'}
                  />
                </View>
                {/* Password View */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      marginBottom: [
                        { minWidth: Breakpoints.Mobile, value: 10 },
                        { minWidth: Breakpoints.Desktop, value: 5 },
                      ],
                      paddingBottom: 5,
                      paddingTop: 10,
                    },
                    dimensions.width
                  )}
                >
                  {/* Password */}
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Strong Inverse'],
                        fontFamily: 'Inter_600SemiBold',
                        fontSize: { minWidth: Breakpoints.Laptop, value: 13 },
                        marginBottom: 5,
                        marginTop: 5,
                      },
                      dimensions.width
                    )}
                  >
                    {'Password'}
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          { color: theme.colors['Error'] }
                        ),
                        dimensions.width
                      )}
                    >
                      {'*'}
                    </Text>
                  </Text>
                  {/* Password Input */}
                  <TextInput
                    onChangeText={newPasswordInputValue => {
                      try {
                        setPasswordInputValue(newPasswordInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    onSubmitEditing={() => {
                      try {
                        Keyboard.dismiss();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Password'}
                    placeholderTextColor={theme.colors['Communial_Icon_Gray']}
                    secureTextEntry={true}
                    spellcheck={true}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Background'],
                        borderBottomWidth: 1,
                        borderColor: theme.colors['Communial_Icon_Gray'],
                        borderLeftWidth: 1,
                        borderRadius: 8,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        fontSize: 16,
                        height: [
                          { minWidth: Breakpoints.Mobile, value: 60 },
                          { minWidth: Breakpoints.Laptop, value: 60 },
                        ],
                        paddingBottom: 10,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 10,
                      },
                      dimensions.width
                    )}
                    textContentType={'password'}
                    value={passwordInputValue}
                  />
                </View>
              </View>
              {/* Button Holder View */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    marginBottom: [
                      { minWidth: Breakpoints.Tablet, value: 40 },
                      { minWidth: Breakpoints.Laptop, value: 0 },
                    ],
                    paddingBottom: 10,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                {/* Error Msg 2 */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Error'],
                      fontFamily: 'Inter_400Regular',
                      fontSize: { minWidth: Breakpoints.Laptop, value: 13 },
                      paddingBottom: 10,
                      paddingTop: 10,
                    },
                    dimensions.width
                  )}
                >
                  {Constants['ak_errorMessage']}
                </Text>
                {/* Button */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderRadius: 0,
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'row',
                      },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'space-evenly',
                      },
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* Touchable Frame */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        width: [
                          { minWidth: Breakpoints.Mobile, value: '100%' },
                          { minWidth: Breakpoints.Laptop, value: '50%' },
                        ],
                        zIndex: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        const handler = async () => {
                          try {
                            const signupResponse = (
                              await AuthAkurieApi.signupPOST(Constants, {
                                First_name: props.route?.params?.Fname ?? '',
                                Last_name: props.route?.params?.Lname ?? '',
                                Networking_Consent: ConsentYesbox,
                                Password: passwordInputValue,
                                birthday: birthdayPickerValue,
                                email: emailInputValue,
                                home_state:
                                  props.route?.params?.Home_state ?? '',
                                title_or_position: jobTitlePickerValue,
                              })
                            )?.json;
                            const ErrorMsgResults = signupResponse?.message;
                            setGlobalVariableValue({
                              key: 'ak_errorMessage',
                              value: ErrorMsgResults,
                            });
                            if (ErrorMsgResults) {
                              return;
                            }
                            const authUser_id = signupResponse?.authUser?.id;
                            setGlobalVariableValue({
                              key: 'ak_userID',
                              value: authUser_id,
                            });
                            const authToken = signupResponse?.authToken;
                            if (!authToken) {
                              return;
                            }
                            setGlobalVariableValue({
                              key: 'SKYE_AUTHORIZATION_HEADER',
                              value: authToken,
                            });
                            await revCatLogin(
                              setGlobalVariableValue,
                              authUser_id.toString()
                            );
                            (
                              await logs$SupportAkurieBasicActivityPOST.mutateAsync(
                                {
                                  buttonName: 'User signup Complete',
                                  session_id: Constants['Session_ID'],
                                }
                              )
                            )?.json;
                            navigation.navigate('SignupPt12Screen', {
                              user_id_pass: authUser_id,
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        { borderRadius: 0 },
                        dimensions.width
                      )}
                    >
                      {/* CTA Frame */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            backgroundColor: [
                              {
                                minWidth: Breakpoints.Mobile,
                                value: theme.colors['Akurie Primary'],
                              },
                              {
                                minWidth: Breakpoints.Laptop,
                                value: theme.colors['Akurie green'],
                              },
                            ],
                            borderRadius: 12,
                            flexDirection: 'row',
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'space-between',
                            },
                            paddingBottom: 12,
                            paddingLeft: 18,
                            paddingRight: 18,
                            paddingTop: 12,
                            zIndex: 10,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Label Frame */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexGrow: 1,
                              flexShrink: 0,
                              justifyContent: 'center',
                              width: {
                                minWidth: Breakpoints.Laptop,
                                value: '100%',
                              },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Continue Label */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: [
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: theme.colors['Strong Inverse'],
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: theme.colors['Akurie Dark Blue'],
                                  },
                                ],
                                fontFamily: 'Inter_400Regular',
                                fontSize: [
                                  { minWidth: Breakpoints.Mobile, value: 15 },
                                  { minWidth: Breakpoints.Laptop, value: 13 },
                                ],
                                lineHeight: 21,
                                marginLeft: [
                                  { minWidth: Breakpoints.Mobile, value: 42 },
                                  { minWidth: Breakpoints.Desktop, value: 0 },
                                  { minWidth: Breakpoints.Laptop, value: 0 },
                                ],
                                textTransform: 'uppercase',
                              },
                              dimensions.width
                            )}
                          >
                            {'Continue'}
                          </Text>
                        </View>
                        {/* Icon Group Frame */}
                        <>
                          {dimensions.width >= Breakpoints.Laptop ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  marginLeft: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 10,
                                  },
                                  opacity: 1,
                                },
                                dimensions.width
                              )}
                            >
                              <Circle
                                bgColor={theme.colors['Custom Color_18']}
                                size={42}
                              >
                                {/* Icon Flex Frame */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexGrow: 0,
                                      flexShrink: 0,
                                      paddingBottom: 12,
                                      paddingLeft: 12,
                                      paddingRight: 12,
                                      paddingTop: 12,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Arrow Icon  */}
                                  <Icon
                                    color={theme.colors['Akurie green']}
                                    name={'AntDesign/arrowright'}
                                    size={18}
                                  />
                                </View>
                              </Circle>
                            </View>
                          )}
                        </>
                      </View>
                    </Touchable>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </KeyboardAwareScrollView>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(Signuppt1Screen);
