import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as Web$AppServicesApi from '../apis/Web$AppServicesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import HumanReadableDate from '../global-functions/HumanReadableDate';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openImagePickerUtil from '../utils/openImagePicker';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  IconButton,
  Picker,
  ScreenContainer,
  Surface,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import * as Linking from 'expo-linking';
import {
  ActivityIndicator,
  FlatList,
  Image,
  ImageBackground,
  RefreshControl,
  ScrollView,
  StatusBar,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const ManageMySiteScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [appPresent, setAppPresent] = React.useState(false);
  const [currentRequestID, setCurrentRequestID] = React.useState(0);
  const [itemBlur, setItemBlur] = React.useState(false);
  const [requestTextValue, setRequestTextValue] = React.useState('');
  const [requestTypeValue, setRequestTypeValue] = React.useState('');
  const [requestView, setRequestView] = React.useState(false);
  const [websitePresent, setWebsitePresent] = React.useState(false);
  const [refreshingKCQnRXCv, setRefreshingKCQnRXCv] = React.useState(false);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      StatusBar.setBarStyle('dark-content');
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
    >
      <Web$AppServicesApi.FetchGetApp$WebServiceUserGET
        business_id={Constants['ak_businessID']}
      >
        {({ loading, error, data, refetchGetApp$WebServiceUser }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <ImageBackground
              resizeMode={'cover'}
              source={Images.FirefoxPHKyvBthWe}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageBackgroundStyles(theme)['Image Background'],
                  { height: '100%' }
                ),
                dimensions.width
              )}
            >
              {/* blurView With Fields */}
              <>
                {!requestView ? null : (
                  <BlurView
                    intensity={80}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.BlurViewStyles(theme)['Blur View'],
                        {
                          alignItems: 'center',
                          height: '100%',
                          justifyContent: 'center',
                          position: 'absolute',
                          width: '100%',
                          zIndex: 10,
                        }
                      ),
                      dimensions.width
                    )}
                    tint={'default'}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: theme.colors['Akurie Dark Blue'],
                          borderBottomWidth: 0.1,
                          borderColor: theme.colors['Akurie green'],
                          borderRadius: 19,
                          borderTopWidth: 0.1,
                          bottom: 50,
                          height: [
                            { minWidth: Breakpoints.Tablet, value: '80%' },
                            { minWidth: Breakpoints.Laptop, value: '60%' },
                            { minWidth: Breakpoints.Mobile, value: '95%' },
                          ],
                          justifyContent: 'center',
                          marginTop: 100,
                          opacity: 1,
                          width: [
                            { minWidth: Breakpoints.Mobile, value: '90%' },
                            { minWidth: Breakpoints.Tablet, value: '70%' },
                            { minWidth: Breakpoints.Desktop, value: '50%' },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      <Surface
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.SurfaceStyles(theme)['Surface'],
                            {
                              alignItems: 'center',
                              borderRadius: 19,
                              height: '90%',
                              justifyContent: 'center',
                              opacity: 1,
                              padding: 20,
                              width: '90%',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {/* View 2 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'flex-end', width: '100%' },
                            dimensions.width
                          )}
                        >
                          <IconButton
                            color={theme.colors['Akurie Dark Blue']}
                            icon={'AntDesign/closecircle'}
                            onPress={() => {
                              try {
                                setRequestView(false);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            size={26}
                          />
                        </View>

                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              marginBottom: 10,
                              padding: 2,
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  color: theme.colors['Akurie Dark Blue'],
                                  fontFamily: 'Inter_600SemiBold',
                                  fontSize: 18,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Create Request'}
                          </Text>
                        </View>
                        {/* View 5 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'flex-start',
                              marginBottom: 10,
                              marginTop: 30,
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                { fontFamily: 'Inter_500Medium' }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Request or Issue'}
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  { color: theme.colors['Error'] }
                                ),
                                dimensions.width
                              )}
                            >
                              {'*'}
                            </Text>
                          </Text>
                          {/* Request Text */}
                          <TextInput
                            changeTextDelay={500}
                            multiline={true}
                            numberOfLines={4}
                            onChangeText={newRequestTextValue => {
                              try {
                                setRequestTextValue(newRequestTextValue);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            placeholder={
                              'ex. I would like the contact information on my website to be change to abc@email.com and the phone number to be changed to (123) 456-7890.'
                            }
                            placeholderTextColor={theme.colors['Light']}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextInputStyles(theme)[
                                  'Text Area'
                                ],
                                {
                                  borderColor: theme.colors['Divider'],
                                  borderRadius: 0,
                                  width: '100%',
                                }
                              ),
                              dimensions.width
                            )}
                            textAlignVertical={'top'}
                            value={requestTextValue}
                          />
                        </View>
                        {/* View 4 */}
                        <>
                          {!requestTextValue ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'flex-start',
                                  marginBottom: 10,
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    { fontFamily: 'Inter_500Medium' }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Request Type'}
                                <Text
                                  accessible={true}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    dimensions.width
                                  )}
                                >
                                  {'Double click me to edit 👀'}
                                </Text>
                              </Text>
                              {/* RequestType */}
                              <Picker
                                autoDismissKeyboard={true}
                                dropDownBackgroundColor={
                                  theme.colors.background
                                }
                                dropDownBorderColor={theme.colors.divider}
                                dropDownBorderRadius={8}
                                dropDownBorderWidth={1}
                                dropDownTextColor={theme.colors.strong}
                                iconSize={24}
                                leftIconMode={'inset'}
                                mode={'native'}
                                onValueChange={newRequestTypeValue => {
                                  try {
                                    setRequestTypeValue(newRequestTypeValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                options={[
                                  'Content Update',
                                  'Performance Issue',
                                  'Access Issues / Site Unavailable',
                                ]}
                                placeholder={'Select an option'}
                                selectedIconColor={theme.colors.strong}
                                selectedIconName={'Feather/check'}
                                selectedIconSize={20}
                                style={StyleSheet.applyWidth(
                                  {
                                    fontFamily: 'Inter_400Regular',
                                    width: '100%',
                                  },
                                  dimensions.width
                                )}
                                type={'solid'}
                                value={requestTypeValue}
                              />
                            </View>
                          )}
                        </>
                        <>
                          {!requestTypeValue ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'flex-start',
                                  marginBottom: 10,
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    { fontFamily: 'Inter_500Medium' }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Image Attachments:'}
                              </Text>

                              <View
                                style={StyleSheet.applyWidth(
                                  { alignItems: 'center', width: '100%' },
                                  dimensions.width
                                )}
                              >
                                <Touchable
                                  onPress={() => {
                                    try {
                                      Linking.openURL(
                                        'mailto:support@akurie.com'
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                >
                                  <Text
                                    accessible={true}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text'],
                                        {
                                          fontFamily: 'Inter_400Regular',
                                          marginTop: 5,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {
                                      'Please email all assets or images to support@akurie.com'
                                    }
                                  </Text>
                                </Touchable>
                              </View>
                            </View>
                          )}
                        </>
                        {/* View 3 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', width: '100%' },
                            dimensions.width
                          )}
                        >
                          <FlatList
                            data={[]}
                            keyExtractor={(listData, index) =>
                              listData?.id ?? listData?.uuid ?? index.toString()
                            }
                            listKey={'KCQnRXCv'}
                            numColumns={1}
                            onEndReachedThreshold={0.5}
                            refreshControl={
                              <RefreshControl
                                refreshing={refreshingKCQnRXCv}
                                onRefresh={() => {
                                  const handler = async () => {
                                    try {
                                      setRefreshingKCQnRXCv(true);
                                      const resultImage =
                                        await openImagePickerUtil({
                                          mediaTypes: 'All',
                                          allowsEditing: true,
                                          quality: 0.2,
                                          allowsMultipleSelection: false,
                                          permissionErrorMessage:
                                            'Sorry, we need notifications permissions to make this work.',
                                          showAlertOnPermissionError: true,
                                        });

                                      /* 'Set Variable' action requires configuration: choose a variable */ setRefreshingKCQnRXCv(
                                        false
                                      );
                                    } catch (err) {
                                      console.error(err);
                                      setRefreshingKCQnRXCv(false);
                                    }
                                  };
                                  handler();
                                }}
                              />
                            }
                            renderItem={({ item, index }) => {
                              const listData = item;
                              return (
                                <Image
                                  resizeMode={'cover'}
                                  source={Images.AdobeStock345059232}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ImageStyles(theme)['Image'],
                                      { height: 100 }
                                    ),
                                    dimensions.width
                                  )}
                                />
                              );
                            }}
                            showsHorizontalScrollIndicator={true}
                            showsVerticalScrollIndicator={true}
                          />
                        </View>
                        {/* View 3 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'flex-start', width: '100%' },
                            dimensions.width
                          )}
                        >
                          <>
                            {!requestTypeValue ? null : (
                              <Button
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      const requestResponse = (
                                        await Web$AppServicesApi.createRequestPOST(
                                          Constants,
                                          {
                                            business_id:
                                              Constants['ak_businessID'],
                                            request: requestTextValue,
                                            request_Type: requestTypeValue,
                                          }
                                        )
                                      )?.json;
                                      const errMsg = requestResponse?.message;
                                      if (errMsg) {
                                        return;
                                      }
                                      setRequestView(false);
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ButtonStyles(theme)['Button'],
                                    {
                                      backgroundColor:
                                        theme.colors['Akurie Primary'],
                                      borderRadius: 10,
                                      color: theme.colors['Strong_Inverse'],
                                      fontFamily: 'Inter_400Regular',
                                      marginBottom: 10,
                                      marginTop: 20,
                                      width: '100%',
                                    }
                                  ),
                                  dimensions.width
                                )}
                                title={'Submit Request'}
                              />
                            )}
                          </>
                        </View>
                      </Surface>
                    </View>
                  </BlurView>
                )}
              </>
              {/* Nav Frame 12-27 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 2,
                    borderColor: theme.colors['Akurie green'],
                    height: 80,
                    marginBottom: 15,
                    marginTop: 35,
                  },
                  dimensions.width
                )}
              >
                <ImageBackground
                  resizeMode={'cover'}
                  source={Images.Asset13}
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: theme.colors['Akurie Dark Blue'],
                      flexDirection: 'row',
                      height: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* Left Side Frame */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingLeft: 9, paddingRight: 9 },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.goBack();
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {/* Icon Flex Frame */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexGrow: 0,
                            flexShrink: 0,
                            paddingBottom: 12,
                            paddingLeft: 12,
                            paddingRight: 12,
                            paddingTop: 12,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Icon  */}
                        <Icon
                          color={theme.colors['Background']}
                          name={'Ionicons/arrow-back-outline'}
                          size={24}
                        />
                      </View>
                    </Touchable>
                  </View>
                  {/* Middle Frame */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexGrow: 1,
                        flexShrink: 0,
                        justifyContent: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {/* Text Frame  */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flexGrow: 0,
                          flexShrink: 0,
                          paddingBottom: 12,
                          paddingLeft: 12,
                          paddingRight: 12,
                          paddingTop: 12,
                        },
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* Left Side Frame */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingLeft: 9, paddingRight: 9 },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('RootNavigator');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {/* Icon Flex Frame */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexGrow: 0,
                            flexShrink: 0,
                            paddingBottom: 12,
                            paddingLeft: 12,
                            paddingRight: 12,
                            paddingTop: 12,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Icon  */}
                        <Icon
                          color={theme.colors['Background']}
                          name={'AntDesign/home'}
                          size={24}
                        />
                      </View>
                    </Touchable>
                  </View>
                </ImageBackground>
              </View>
              {/* customBlurView */}
              <>
                {!itemBlur ? null : (
                  <BlurView
                    intensity={80}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.BlurViewStyles(theme)['Blur View'],
                        {
                          alignItems: 'center',
                          height: '100%',
                          justifyContent: 'center',
                          position: 'absolute',
                          width: '100%',
                          zIndex: 10,
                        }
                      ),
                      dimensions.width
                    )}
                    tint={'default'}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: theme.colors['Akurie Dark Blue'],
                          borderBottomWidth: 0.1,
                          borderColor: theme.colors['Akurie green'],
                          borderRadius: 19,
                          borderTopWidth: 0.1,
                          bottom: 50,
                          height: [
                            { minWidth: Breakpoints.Tablet, value: '80%' },
                            { minWidth: Breakpoints.Laptop, value: '60%' },
                            { minWidth: Breakpoints.Mobile, value: '90%' },
                          ],
                          justifyContent: 'center',
                          marginTop: 100,
                          opacity: 1,
                          width: [
                            { minWidth: Breakpoints.Mobile, value: '90%' },
                            { minWidth: Breakpoints.Tablet, value: '70%' },
                            { minWidth: Breakpoints.Desktop, value: '50%' },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      <Web$AppServicesApi.FetchGetRequestGET
                        business_id={Constants['ak_businessID']}
                        requestID={currentRequestID}
                      >
                        {({ loading, error, data, refetchGetRequest }) => {
                          const fetchData = data?.json;
                          if (loading) {
                            return <ActivityIndicator />;
                          }

                          if (
                            error ||
                            data?.status < 200 ||
                            data?.status >= 300
                          ) {
                            return <ActivityIndicator />;
                          }

                          return (
                            <Surface
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.SurfaceStyles(theme)['Surface'],
                                  {
                                    alignItems: 'center',
                                    borderRadius: 19,
                                    height: '90%',
                                    justifyContent: 'space-around',
                                    opacity: 1,
                                    padding: 20,
                                    width: '90%',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {/* View 3 */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { width: '100%' },
                                  dimensions.width
                                )}
                              >
                                {/* View 2 */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    { alignItems: 'flex-end', width: '100%' },
                                    dimensions.width
                                  )}
                                >
                                  <IconButton
                                    color={theme.colors['Akurie Dark Blue']}
                                    icon={'AntDesign/closecircle'}
                                    onPress={() => {
                                      try {
                                        setItemBlur(false);
                                        setCurrentRequestID('');
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    size={26}
                                  />
                                </View>

                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      flexDirection: 'row',
                                      justifyContent: 'center',
                                      marginBottom: 30,
                                      padding: 2,
                                      width: '100%',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text'],
                                        {
                                          color:
                                            theme.colors['Akurie Dark Blue'],
                                          fontFamily: 'Inter_600SemiBold',
                                          fontSize: 18,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {'Request ID: '}
                                    {fetchData?.id}
                                  </Text>
                                </View>
                                {/* View 4 */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'flex-start',
                                      borderBottomWidth: 0.5,
                                      borderColor: theme.colors['Divider'],
                                      flexDirection: 'column',
                                      justifyContent: 'flex-start',
                                      marginBottom: 10,
                                      minHeight: 30,
                                      width: '100%',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    accessible={true}
                                    dataDetectorType={'none'}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text'],
                                        {
                                          color:
                                            theme.colors['Akurie Dark Blue'],
                                          fontFamily: 'Inter_600SemiBold',
                                          fontSize: 14,
                                          marginBottom: 5,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {'Request:'}
                                  </Text>
                                  {/* Text 2 */}
                                  <Text
                                    accessible={true}
                                    dataDetectorType={'none'}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text'],
                                        {
                                          color:
                                            theme.colors['Akurie Dark Blue'],
                                          fontFamily: 'Inter_400Regular',
                                          fontSize: 14,
                                          marginBottom: 5,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {fetchData?.requestDetails}
                                  </Text>
                                </View>
                                {/* View 5 */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    { marginBottom: 10 },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    accessible={true}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text'],
                                        { fontFamily: 'Inter_600SemiBold' }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {'Images:'}
                                  </Text>

                                  <View>
                                    <FlatList
                                      data={fetchData?.images}
                                      horizontal={true}
                                      keyExtractor={(listData, index) =>
                                        listData?.id ??
                                        listData?.uuid ??
                                        index.toString()
                                      }
                                      listKey={'1m6JGbFx'}
                                      onEndReachedThreshold={0.5}
                                      renderItem={({ item, index }) => {
                                        const listData = item;
                                        return (
                                          <Image
                                            resizeMode={'cover'}
                                            source={{
                                              uri: `${listData?.image?.url}`,
                                            }}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ImageStyles(theme)[
                                                  'Image'
                                                ],
                                                {
                                                  height: 100,
                                                  marginRight: 5,
                                                  marginTop: 10,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          />
                                        );
                                      }}
                                      showsHorizontalScrollIndicator={true}
                                      showsVerticalScrollIndicator={false}
                                    />
                                  </View>
                                </View>
                                {/* View 2 */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'flex-start',
                                      borderBottomWidth: 0.5,
                                      borderColor: theme.colors['Divider'],
                                      justifyContent: 'center',
                                      marginBottom: 10,
                                      width: '90%',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Text 3 */}
                                  <Text
                                    accessible={true}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text'],
                                        {
                                          color: theme.colors['Strong'],
                                          fontFamily: 'Inter_400Regular',
                                          fontSize: 14,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {'Date Submitted:'}
                                  </Text>
                                  {/* Text 4 */}
                                  <Text
                                    accessible={true}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text'],
                                        {
                                          color: theme.colors['Strong'],
                                          fontFamily: 'Inter_700Bold',
                                          fontSize: 14,
                                          marginBottom: 5,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {fetchData?.created_at}
                                  </Text>
                                </View>
                                {/* View 3 */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'flex-start',
                                      borderBottomWidth: 0.5,
                                      borderColor: theme.colors['Divider'],
                                      justifyContent: 'center',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        maxHeight: 30,
                                        minHeight: 30,
                                        width: '100%',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Text 2 */}
                                    <Text
                                      accessible={true}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ],
                                          {
                                            fontFamily: 'Inter_600SemiBold',
                                            fontSize: 14,
                                            marginRight: 5,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Status:'}
                                    </Text>
                                    {/* Pending */}
                                    <>
                                      {!(
                                        fetchData?.requestStatus === 'Pending'
                                      ) ? null : (
                                        <Icon
                                          color={theme.colors['Akurie Primary']}
                                          name={'Entypo/controller-record'}
                                          size={18}
                                          style={StyleSheet.applyWidth(
                                            { marginRight: 10 },
                                            dimensions.width
                                          )}
                                        />
                                      )}
                                    </>
                                    {/* In-Progress */}
                                    <>
                                      {!(
                                        fetchData?.requestStatus ===
                                        'In-Progress'
                                      ) ? null : (
                                        <Icon
                                          color={
                                            theme.colors[
                                              'Internal_Yellow_Secondary'
                                            ]
                                          }
                                          name={'Entypo/controller-record'}
                                          size={18}
                                          style={StyleSheet.applyWidth(
                                            { marginRight: 10 },
                                            dimensions.width
                                          )}
                                        />
                                      )}
                                    </>
                                    {/* Completed */}
                                    <>
                                      {!(
                                        fetchData?.requestStatus === 'Completed'
                                      ) ? null : (
                                        <Icon
                                          color={
                                            theme.colors['Akurie Other Green']
                                          }
                                          name={'Entypo/controller-record'}
                                          size={18}
                                          style={StyleSheet.applyWidth(
                                            { marginRight: 10 },
                                            dimensions.width
                                          )}
                                        />
                                      )}
                                    </>
                                    {/* Action Required */}
                                    <>
                                      {!(
                                        fetchData?.requestStatus ===
                                        'Action Required'
                                      ) ? null : (
                                        <Icon
                                          color={theme.colors['Error']}
                                          name={'Entypo/controller-record'}
                                          size={18}
                                          style={StyleSheet.applyWidth(
                                            { marginRight: 10 },
                                            dimensions.width
                                          )}
                                        />
                                      )}
                                    </>
                                    <Text
                                      accessible={true}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ],
                                          {
                                            fontFamily: 'Inter_500Medium',
                                            fontSize: 14,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {fetchData?.requestStatus}
                                    </Text>
                                  </View>

                                  <Text
                                    accessible={true}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text'],
                                        { fontFamily: 'Inter_500Medium' }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {'Request Type: '}
                                  </Text>
                                  {/* Text 2 */}
                                  <Text
                                    accessible={true}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text'],
                                        {
                                          fontFamily: 'Inter_500Medium',
                                          marginBottom: 5,
                                          marginTop: 5,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {fetchData?.requestType}
                                  </Text>
                                </View>

                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'flex-start',
                                      borderBottomWidth: 0.5,
                                      borderColor: theme.colors['Divider'],
                                      flexDirection: 'column',
                                      justifyContent: 'flex-start',
                                      marginBottom: 10,
                                      marginTop: 10,
                                      minHeight: 30,
                                      width: '100%',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Text 3 */}
                                  <Text
                                    accessible={true}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text'],
                                        {
                                          fontFamily: 'Inter_500Medium',
                                          marginBottom: 10,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {'Akurie Agent Notes:'}
                                  </Text>

                                  <Text
                                    accessible={true}
                                    dataDetectorType={'none'}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text'],
                                        {
                                          color:
                                            theme.colors['Akurie Dark Blue'],
                                          fontFamily: 'Inter_400Regular',
                                          fontSize: 14,
                                          marginBottom: 10,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {fetchData?.techNotes}
                                  </Text>
                                  {/* Text 2 */}
                                  <Text
                                    accessible={true}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text'],
                                        {
                                          fontFamily: 'Inter_400Regular',
                                          marginBottom: 10,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {'support@akurie.com'}
                                  </Text>
                                </View>
                              </View>
                            </Surface>
                          );
                        }}
                      </Web$AppServicesApi.FetchGetRequestGET>
                    </View>
                  </BlurView>
                )}
              </>
              <ScrollView
                bounces={true}
                showsHorizontalScrollIndicator={true}
                showsVerticalScrollIndicator={true}
              >
                <View>
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center' },
                      dimensions.width
                    )}
                  >
                    <Surface
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.SurfaceStyles(theme)['Surface'],
                          {
                            alignItems: 'center',
                            backgroundColor: theme.colors['Akurie Dark Blue'],
                            justifyContent: 'center',
                            minHeight: 60,
                            width: '100%',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Akurie green'],
                              fontFamily: 'Inter_400Regular',
                              fontSize: 24,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Manage My Site'}
                      </Text>
                    </Surface>
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: theme.colors['Divider'],
                        justifyContent: 'center',
                        marginTop: 15,
                        padding: 30,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            fontFamily: 'Inter_600SemiBold',
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 26 },
                              { minWidth: Breakpoints.Tablet, value: 28 },
                            ],
                            textAlign: 'center',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {fetchData?.Website_name}
                    </Text>
                    {/* Text 2 */}
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            fontFamily: 'Inter_500Medium',
                            fontSize: 14,
                            marginTop: 5,
                            textAlign: 'center',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {fetchData?.domainName}
                    </Text>
                  </View>
                  {/* View 3 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        marginTop: 20,
                        padding: 10,
                        paddingLeft: {
                          minWidth: Breakpoints.Tablet,
                          value: 120,
                        },
                        paddingRight: {
                          minWidth: Breakpoints.Tablet,
                          value: 120,
                        },
                      },
                      dimensions.width
                    )}
                  >
                    {/* My Website */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: theme.colors['Akurie Dark Blue'],
                          borderRadius: 9,
                          justifyContent: 'center',
                          marginBottom: 10,
                          marginTop: 10,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          { minHeight: 10, padding: 10, width: '100%' },
                          dimensions.width
                        )}
                      >
                        {/* My Website */}
                        <Surface
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.SurfaceStyles(theme)['Surface'],
                              {
                                backgroundColor: theme.colors['Background'],
                                borderRadius: 9,
                                padding: 10,
                                paddingLeft: 20,
                                paddingRight: 20,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {/* Manage Site */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                borderBottomWidth: 0.5,
                                borderColor: theme.colors['Divider'],
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                marginBottom: 20,
                                paddingBottom: 5,
                                paddingTop: 5,
                              },
                              dimensions.width
                            )}
                          >
                            {/* View 3 */}
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'center', marginTop: 10 },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      fontFamily: 'Inter_600SemiBold',
                                      fontSize: 16,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Type: '}
                                {fetchData?.websiteType}
                              </Text>
                              {/* Web Status Buttons */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { flexDirection: 'row' },
                                  dimensions.width
                                )}
                              >
                                {/* Active */}
                                <>
                                  {!(
                                    fetchData?.websiteStatus === 'Active'
                                  ) ? null : (
                                    <Icon
                                      color={theme.colors['Akurie green']}
                                      name={'Entypo/controller-record'}
                                      size={18}
                                      style={StyleSheet.applyWidth(
                                        { marginLeft: 5 },
                                        dimensions.width
                                      )}
                                    />
                                  )}
                                </>
                                {/* Pending */}
                                <>
                                  {!(
                                    fetchData?.websiteStatus === 'Pending'
                                  ) ? null : (
                                    <Icon
                                      color={theme.colors['Akurie Primary']}
                                      name={'Entypo/controller-record'}
                                      size={18}
                                      style={StyleSheet.applyWidth(
                                        { marginLeft: 5 },
                                        dimensions.width
                                      )}
                                    />
                                  )}
                                </>
                                {/* Canceled */}
                                <>
                                  {!(
                                    fetchData?.websiteStatus === 'Canceled'
                                  ) ? null : (
                                    <Icon
                                      color={theme.colors['Error']}
                                      name={'Entypo/controller-record'}
                                      size={18}
                                      style={StyleSheet.applyWidth(
                                        { marginLeft: 5 },
                                        dimensions.width
                                      )}
                                    />
                                  )}
                                </>
                                {/* Suspended */}
                                <>
                                  {!(
                                    fetchData?.websiteStatus === 'Suspended'
                                  ) ? null : (
                                    <Icon
                                      color={theme.colors['Error']}
                                      name={'Entypo/controller-record'}
                                      size={18}
                                      style={StyleSheet.applyWidth(
                                        { marginLeft: 5 },
                                        dimensions.width
                                      )}
                                    />
                                  )}
                                </>
                                {/* Undergoing Maintenance */}
                                <>
                                  {!(
                                    fetchData?.websiteStatus ===
                                    'Undergoing Maintenance'
                                  ) ? null : (
                                    <Icon
                                      color={
                                        theme.colors['Akurie Primary Dark']
                                      }
                                      name={'Entypo/controller-record'}
                                      size={18}
                                      style={StyleSheet.applyWidth(
                                        { marginLeft: 5 },
                                        dimensions.width
                                      )}
                                    />
                                  )}
                                </>
                                {/* Text 2 */}
                                <Text
                                  accessible={true}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    dimensions.width
                                  )}
                                >
                                  {fetchData?.websiteStatus}
                                </Text>
                              </View>
                            </View>
                          </View>
                          {/* View 5 */}
                          <View
                            style={StyleSheet.applyWidth(
                              { width: '100%' },
                              dimensions.width
                            )}
                          >
                            {/* View 5 */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  borderBottomWidth: 0.5,
                                  borderColor: theme.colors['Divider'],
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  marginBottom: 10,
                                  paddingBottom: 5,
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                  paddingTop: 5,
                                },
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  { flexDirection: 'row' },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      { fontFamily: 'Inter_600SemiBold' }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'Web Hosting'}
                                </Text>
                              </View>
                              {/* View 3 */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { alignItems: 'flex-end' },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      { fontFamily: 'Inter_300Light' }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'Renewal Date'}
                                </Text>
                                {/* Text 2 */}
                                <Text
                                  accessible={true}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      { fontFamily: 'Inter_500Medium' }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {fetchData?.hosting_renewal_date}
                                </Text>
                              </View>
                            </View>
                            {/* View 3 */}
                            <>
                              {!(
                                fetchData?.domainPurchase !== 'None'
                              ) ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      borderBottomWidth: 0.5,
                                      borderColor: theme.colors['Divider'],
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                      paddingBottom: 5,
                                      paddingLeft: 10,
                                      paddingRight: 10,
                                      paddingTop: 5,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { flexDirection: 'row' },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ],
                                          { fontFamily: 'Inter_600SemiBold' }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Domain'}
                                    </Text>
                                  </View>
                                  {/* View 3 */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { alignItems: 'flex-end' },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ],
                                          { fontFamily: 'Inter_300Light' }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Expires'}
                                    </Text>
                                    {/* Text 2 */}
                                    <Text
                                      accessible={true}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ],
                                          { fontFamily: 'Inter_500Medium' }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {fetchData?.domainExpiration}
                                    </Text>
                                  </View>
                                </View>
                              )}
                            </>
                          </View>
                        </Surface>
                      </View>
                    </View>
                    {/* Web Services */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: theme.colors['Akurie Dark Blue'],
                          borderRadius: 9,
                          justifyContent: 'center',
                          marginBottom: 10,
                          marginTop: 10,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      {/* View 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            borderBottomWidth: 0.5,
                            borderColor: theme.colors['Akurie Dark Blue'],
                            flexDirection: 'row',
                            justifyContent: 'center',
                            marginTop: 10,
                            minHeight: 60,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: theme.colors['Akurie green'],
                                fontFamily: 'Inter_600SemiBold',
                                fontSize: 18,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Website Form Submissions'}
                        </Text>
                        <Icon
                          color={theme.colors['Akurie green']}
                          name={'AntDesign/mail'}
                          size={24}
                          style={StyleSheet.applyWidth(
                            { marginLeft: 20 },
                            dimensions.width
                          )}
                        />
                      </View>

                      <View
                        style={StyleSheet.applyWidth(
                          { minHeight: 10, padding: 10, width: '100%' },
                          dimensions.width
                        )}
                      >
                        {/* Web Services */}
                        <Surface
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.SurfaceStyles(theme)['Surface'],
                              {
                                backgroundColor: theme.colors['Background'],
                                borderRadius: 9,
                                padding: 10,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {/* Create site */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                marginBottom: 20,
                                marginTop: 20,
                                paddingBottom: 5,
                                paddingTop: 5,
                              },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  marginBottom: 20,
                                  padding: 10,
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      fontFamily: 'Inter_500Medium',
                                      textAlign: 'center',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {
                                  "Submissions sent through your website's contact form can be found below."
                                }
                              </Text>
                            </View>
                          </View>
                        </Surface>
                      </View>
                    </View>
                    {/* Web Services 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: theme.colors['Akurie Dark Blue'],
                          borderRadius: 9,
                          justifyContent: 'center',
                          marginBottom: 120,
                          marginTop: 10,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      {/* View 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            borderBottomWidth: 0.5,
                            borderColor: theme.colors['Akurie Dark Blue'],
                            flexDirection: 'row',
                            justifyContent: 'center',
                            marginTop: 10,
                            minHeight: 60,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: theme.colors['Akurie green'],
                                fontFamily: 'Inter_600SemiBold',
                                fontSize: 18,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'My Requests & Support'}
                        </Text>
                        <IconButton
                          color={theme.colors['Akurie green']}
                          icon={'AntDesign/pluscircleo'}
                          onPress={() => {
                            try {
                              setRequestView(true);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          size={32}
                          style={StyleSheet.applyWidth(
                            { marginLeft: 20 },
                            dimensions.width
                          )}
                        />
                      </View>

                      <View
                        style={StyleSheet.applyWidth(
                          { minHeight: 10, padding: 10, width: '100%' },
                          dimensions.width
                        )}
                      >
                        {/* Web Services */}
                        <Surface
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.SurfaceStyles(theme)['Surface'],
                              {
                                backgroundColor: theme.colors['Background'],
                                borderRadius: 9,
                                padding: 10,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {/* Create site */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                marginBottom: 20,
                                marginTop: 20,
                                paddingBottom: 5,
                                paddingTop: 5,
                              },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  marginBottom: 20,
                                  padding: 10,
                                  paddingLeft: 20,
                                  paddingRight: 20,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      fontFamily: 'Inter_500Medium',
                                      textAlign: 'center',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {
                                  'Below are all support and request tickets for your site. Select a ticket to view for more information about it.'
                                }
                              </Text>
                            </View>

                            <Web$AppServicesApi.FetchGetSiteRequestsGET
                              business_id={Constants['ak_businessID']}
                              refetchInterval={2000}
                            >
                              {({
                                loading,
                                error,
                                data,
                                refetchGetSiteRequests,
                              }) => {
                                const fetchData = data?.json;
                                if (loading) {
                                  return <ActivityIndicator />;
                                }

                                if (
                                  error ||
                                  data?.status < 200 ||
                                  data?.status >= 300
                                ) {
                                  return <ActivityIndicator />;
                                }

                                return (
                                  <FlatList
                                    data={fetchData}
                                    keyExtractor={(listData, index) =>
                                      listData?.id ??
                                      listData?.uuid ??
                                      index.toString()
                                    }
                                    listKey={'D5iKbZ0T'}
                                    numColumns={1}
                                    onEndReachedThreshold={0.5}
                                    renderItem={({ item, index }) => {
                                      const listData = item;
                                      return (
                                        <>
                                          {/* Touchable 4 */}
                                          <Touchable
                                            onPress={() => {
                                              try {
                                                setCurrentRequestID(
                                                  listData?.id
                                                );
                                                setItemBlur(true);
                                              } catch (err) {
                                                console.error(err);
                                              }
                                            }}
                                            style={StyleSheet.applyWidth(
                                              { width: '100%' },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Surface 4 */}
                                            <Surface
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.SurfaceStyles(
                                                    theme
                                                  )['Surface'],
                                                  {
                                                    alignItems: 'center',
                                                    backgroundColor:
                                                      theme.colors['Divider'],
                                                    borderRadius: 9,
                                                    flexDirection: 'row',
                                                    justifyContent: 'center',
                                                    marginBottom: 5,
                                                    marginTop: 5,
                                                    minHeight: 100,
                                                    padding: 8,
                                                    paddingLeft: 15,
                                                    paddingRight: 15,
                                                    width: '100%',
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {/* View 2 */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'flex-start',
                                                    justifyContent: 'center',
                                                    width: '65%',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      alignItems: 'flex-start',
                                                      flexDirection: 'column',
                                                      justifyContent:
                                                        'flex-start',
                                                      marginBottom: 5,
                                                      minHeight: 30,
                                                      width: '100%',
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <Text
                                                    accessible={true}
                                                    dataDetectorType={'none'}
                                                    style={StyleSheet.applyWidth(
                                                      StyleSheet.compose(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )['Text'],
                                                        {
                                                          color:
                                                            theme.colors[
                                                              'Akurie Dark Blue'
                                                            ],
                                                          fontFamily:
                                                            'Inter_300Light',
                                                          fontSize: 12,
                                                          marginBottom: 5,
                                                        }
                                                      ),
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {'ID: '}
                                                    {listData?.id}
                                                  </Text>
                                                  {/* Text 2 */}
                                                  <Text
                                                    accessible={true}
                                                    dataDetectorType={'none'}
                                                    style={StyleSheet.applyWidth(
                                                      StyleSheet.compose(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )['Text'],
                                                        {
                                                          color:
                                                            theme.colors[
                                                              'Akurie Dark Blue'
                                                            ],
                                                          fontFamily:
                                                            'Inter_600SemiBold',
                                                          fontSize: 14,
                                                        }
                                                      ),
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {listData?.requestDetails}
                                                  </Text>
                                                </View>
                                                {/* Text 3 */}
                                                <Text
                                                  accessible={true}
                                                  style={StyleSheet.applyWidth(
                                                    StyleSheet.compose(
                                                      GlobalStyles.TextStyles(
                                                        theme
                                                      )['Text'],
                                                      {
                                                        color:
                                                          theme.colors[
                                                            'Strong'
                                                          ],
                                                        fontFamily:
                                                          'Inter_700Bold',
                                                        fontSize: 14,
                                                      }
                                                    ),
                                                    dimensions.width
                                                  )}
                                                >
                                                  {HumanReadableDate(
                                                    listData?.created_at
                                                  )}
                                                </Text>
                                              </View>
                                              {/* View 3 */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'flex-end',
                                                    justifyContent: 'center',
                                                    width: '35%',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Text 2 */}
                                                <Text
                                                  accessible={true}
                                                  style={StyleSheet.applyWidth(
                                                    StyleSheet.compose(
                                                      GlobalStyles.TextStyles(
                                                        theme
                                                      )['Text'],
                                                      {
                                                        fontFamily:
                                                          'Inter_500Medium',
                                                        fontSize: 14,
                                                      }
                                                    ),
                                                    dimensions.width
                                                  )}
                                                >
                                                  {'Status'}
                                                </Text>

                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      alignItems: 'center',
                                                      flexDirection: 'row',
                                                      justifyContent:
                                                        'flex-end',
                                                      marginTop: 5,
                                                      maxHeight: 30,
                                                      minHeight: 30,
                                                      width: '100%',
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* Pending */}
                                                  <>
                                                    {!(
                                                      listData?.requestStatus ===
                                                      'Pending'
                                                    ) ? null : (
                                                      <Icon
                                                        color={
                                                          theme.colors[
                                                            'Akurie Primary'
                                                          ]
                                                        }
                                                        name={
                                                          'Entypo/controller-record'
                                                        }
                                                        size={18}
                                                        style={StyleSheet.applyWidth(
                                                          { marginRight: 10 },
                                                          dimensions.width
                                                        )}
                                                      />
                                                    )}
                                                  </>
                                                  {/* In-Progress */}
                                                  <>
                                                    {!(
                                                      listData?.requestStatus ===
                                                      'In-Progress'
                                                    ) ? null : (
                                                      <Icon
                                                        color={
                                                          theme.colors[
                                                            'Internal_Yellow_Secondary'
                                                          ]
                                                        }
                                                        name={
                                                          'Entypo/controller-record'
                                                        }
                                                        size={18}
                                                        style={StyleSheet.applyWidth(
                                                          { marginRight: 10 },
                                                          dimensions.width
                                                        )}
                                                      />
                                                    )}
                                                  </>
                                                  {/* Completed */}
                                                  <>
                                                    {!(
                                                      listData?.requestStatus ===
                                                      'Completed'
                                                    ) ? null : (
                                                      <Icon
                                                        color={
                                                          theme.colors[
                                                            'Akurie Other Green'
                                                          ]
                                                        }
                                                        name={
                                                          'Entypo/controller-record'
                                                        }
                                                        size={18}
                                                        style={StyleSheet.applyWidth(
                                                          { marginRight: 10 },
                                                          dimensions.width
                                                        )}
                                                      />
                                                    )}
                                                  </>
                                                  {/* User Action Required */}
                                                  <>
                                                    {!(
                                                      listData?.requestStatus ===
                                                      'Action Required'
                                                    ) ? null : (
                                                      <Icon
                                                        color={
                                                          theme.colors['Error']
                                                        }
                                                        name={
                                                          'Entypo/controller-record'
                                                        }
                                                        size={18}
                                                        style={StyleSheet.applyWidth(
                                                          { marginRight: 10 },
                                                          dimensions.width
                                                        )}
                                                      />
                                                    )}
                                                  </>
                                                  <Text
                                                    accessible={true}
                                                    style={StyleSheet.applyWidth(
                                                      StyleSheet.compose(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )['Text'],
                                                        {
                                                          fontFamily:
                                                            'Inter_500Medium',
                                                          fontSize: 14,
                                                        }
                                                      ),
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {listData?.requestStatus}
                                                  </Text>
                                                </View>
                                              </View>
                                            </Surface>
                                          </Touchable>
                                        </>
                                      );
                                    }}
                                    showsHorizontalScrollIndicator={true}
                                    showsVerticalScrollIndicator={true}
                                    style={StyleSheet.applyWidth(
                                      { width: '100%' },
                                      dimensions.width
                                    )}
                                  />
                                );
                              }}
                            </Web$AppServicesApi.FetchGetSiteRequestsGET>
                            {/* View 3 */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  marginTop: 10,
                                  paddingLeft: 35,
                                  paddingRight: 35,
                                },
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  { flexDirection: 'row', marginTop: 20 },
                                  dimensions.width
                                )}
                              >
                                <Button
                                  onPress={() => {
                                    try {
                                      setRequestView(true);
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ButtonStyles(theme)[
                                        'Button'
                                      ],
                                      {
                                        backgroundColor:
                                          theme.colors['Akurie green'],
                                        borderRadius: 9,
                                        color: theme.colors['Akurie Dark Blue'],
                                        fontFamily: 'Inter_700Bold',
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                  title={'Create A New Ticket '}
                                />
                              </View>
                            </View>
                          </View>
                        </Surface>
                      </View>
                    </View>
                  </View>
                  {/* View 4 */}
                  <View />
                </View>
              </ScrollView>
            </ImageBackground>
          );
        }}
      </Web$AppServicesApi.FetchGetApp$WebServiceUserGET>
    </ScreenContainer>
  );
};

export default withTheme(ManageMySiteScreen);
