import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AuthAkurieApi from '../apis/AuthAkurieApi.js';
import * as BusinessDataAkurieApi from '../apis/BusinessDataAkurieApi.js';
import DesktopHeader13024Block from '../components/DesktopHeader13024Block';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  NumberInput,
  ScreenContainer,
  Spacer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ImageBackground, Keyboard, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const AutoFilingSetup1Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [Business_Exist, setBusiness_Exist] = React.useState(true);
  const [General_error_msg, setGeneral_error_msg] = React.useState('');
  const [Param_error_msg, setParam_error_msg] = React.useState('');
  const [address2Value, setAddress2Value] = React.useState('');
  const [addressValue, setAddressValue] = React.useState('');
  const [businessAndSupportEmailValue, setBusinessAndSupportEmailValue] =
    React.useState('');
  const [businessContactPersonValue, setBusinessContactPersonValue] =
    React.useState('');
  const [businessPhoneNumberValue, setBusinessPhoneNumberValue] =
    React.useState('');
  const [cityValue, setCityValue] = React.useState('');
  const [countyValue, setCountyValue] = React.useState('');
  const [error_msg, setError_msg] = React.useState('');
  const [numberInputValue, setNumberInputValue] = React.useState('');
  const [numberInputValue2, setNumberInputValue2] = React.useState('');
  const [pickerValue, setPickerValue] = React.useState('');
  const [stateValue, setStateValue] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const [zipCodeValue, setZipCodeValue] = React.useState('');
  const businessDataAkurieCreateContactInfoPOST =
    BusinessDataAkurieApi.useCreateContactInfoPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const authResponse = (await AuthAkurieApi.meGET(Constants))?.json;
        const tokenExpire = authResponse?.message;
        if (tokenExpire) {
          navigation.navigate('SessionExpiredScreen');
        }
        if (tokenExpire) {
          return;
        }
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);
  const businessAndSupportEmailbJ6O11A1Ref = React.useRef();
  const businessPhoneNumber0XME34t3Ref = React.useRef();
  const addressSRamqJCpRef = React.useRef();
  const address2Er4Xv5f4Ref = React.useRef();
  const city0pW7ZamaRef = React.useRef();
  const countynKeXSGK5Ref = React.useRef();

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors['Background'] },
        dimensions.width
      )}
    >
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <DesktopHeader13024Block />
        )}
      </>
      {/* Nav Frame 12-27 */}
      <View
        style={StyleSheet.applyWidth(
          {
            borderBottomWidth: 2,
            borderColor: theme.colors['Akurie green'],
            height: 80,
            marginBottom: 15,
          },
          dimensions.width
        )}
      >
        <ImageBackground
          resizeMode={'cover'}
          source={Images.Asset13}
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              backgroundColor: theme.colors['Akurie Dark Blue'],
              flexDirection: 'row',
              height: '100%',
              justifyContent: 'space-between',
              paddingLeft: { minWidth: Breakpoints.Laptop, value: '20%' },
              paddingRight: { minWidth: Breakpoints.Laptop, value: '20%' },
            },
            dimensions.width
          )}
        >
          {/* Left Side Frame */}
          <View
            style={StyleSheet.applyWidth(
              { paddingLeft: 9, paddingRight: 9 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Icon Flex Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                {/* Icon  */}
                <Icon
                  color={theme.colors['Background']}
                  name={'Ionicons/arrow-back-outline'}
                  size={24}
                />
              </View>
            </Touchable>
          </View>
          {/* Middle Frame */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexGrow: 1,
                flexShrink: 0,
                justifyContent: 'center',
              },
              dimensions.width
            )}
          >
            {/* Text Frame  */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexGrow: 0,
                  flexShrink: 0,
                  paddingBottom: 12,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 12,
                },
                dimensions.width
              )}
            >
              {/* Text 12 18 Regular */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Akurie green'],
                    fontFamily: 'System',
                    fontSize: 22,
                    fontWeight: '400',
                    lineHeight: 18,
                    paddingTop: 8,
                  },
                  dimensions.width
                )}
              >
                {'Setting Up Auto Filing'}
              </Text>
            </View>
          </View>
          {/* Left Side Frame 2 */}
          <View
            style={StyleSheet.applyWidth(
              { paddingLeft: 9, paddingRight: 9 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('HelpSupportScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Icon Flex Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                {/* Icon  */}
                <Icon
                  color={theme.colors['Background']}
                  name={'Ionicons/help-circle-outline'}
                  size={32}
                />
              </View>
            </Touchable>
          </View>
        </ImageBackground>
      </View>

      <ImageBackground
        resizeMode={'cover'}
        source={Images.FirefoxPHKyvBthWe}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ImageBackgroundStyles(theme)['Image Background'],
            {
              alignItems: { minWidth: Breakpoints.Tablet, value: 'center' },
              backgroundColor: theme.colors['Background'],
              height: '100%',
              justifyContent: 'center',
            }
          ),
          dimensions.width
        )}
      >
        {/* steps */}
        <View
          style={StyleSheet.applyWidth(
            { marginTop: 10, minHeight: 20, width: '100%' },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            allowFontScaling={true}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                fontFamily: 'Inter_600SemiBold',
                textAlign: 'center',
              }),
              dimensions.width
            )}
          >
            {'Step 1 of 3'}
          </Text>
        </View>

        <KeyboardAwareScrollView
          enableOnAndroid={false}
          extraHeight={400}
          style={StyleSheet.applyWidth(
            {
              width: [
                { minWidth: Breakpoints.Tablet, value: '70%' },
                { minWidth: Breakpoints.Laptop, value: '50%' },
                { minWidth: Breakpoints.BigScreen, value: '40%' },
              ],
            },
            dimensions.width
          )}
          contentContainerStyle={StyleSheet.applyWidth(
            {
              justifyContent: 'center',
              padding: 10,
              paddingLeft: 10,
              paddingRight: 10,
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors['Akurie Dark Blue'],
                borderTopLeftRadius: 12,
                borderTopRightRadius: 12,
                paddingBottom: 10,
                paddingLeft: 40,
                paddingRight: 40,
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth({ marginTop: 16 }, dimensions.width)}
            >
              {/* Business Contact Info */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Strong Inverse'],
                    fontFamily: 'Inter_400Regular',
                    fontSize: 22,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {'Essential Contact Info'}
              </Text>
              <Spacer bottom={4} left={8} right={8} top={4} />
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Akurie Primary'],
                    fontFamily: 'Inter_400Regular',
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {
                  'For us to setup automatic filing, we need some contact information about your business.'
                }
              </Text>
            </View>
          </View>

          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors['Akurie Dark Blue'],
                borderBottomLeftRadius: 12,
                borderBottomRightRadius: 12,
                marginBottom: 90,
                paddingBottom: 20,
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 10,
              },
              dimensions.width
            )}
          >
            {/* Form 1 */}
            <>
              {!Business_Exist ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { opacity: 1, width: '90%' },
                    dimensions.width
                  )}
                >
                  {/* View 4 */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingBottom: 5, paddingTop: 5 },
                      dimensions.width
                    )}
                  >
                    {/* Business Contact Person */}
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Strong Inverse'],
                          fontFamily: 'Inter_600SemiBold',
                          paddingBottom: 5,
                          paddingTop: 10,
                        },
                        dimensions.width
                      )}
                    >
                      {'Business Contact Person'}
                    </Text>
                    {/* Business Contact Person */}
                    <TextInput
                      allowFontScaling={true}
                      autoCapitalize={'none'}
                      changeTextDelay={500}
                      multiline={false}
                      onChangeText={newBusinessContactPersonValue => {
                        try {
                          setBusinessContactPersonValue(
                            newBusinessContactPersonValue
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      onSubmitEditing={() => {
                        try {
                          businessAndSupportEmailbJ6O11A1Ref.current.focus();
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'John Dowe'}
                      placeholderTextColor={theme.colors['Light']}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextInputStyles(theme)['Text Input'],
                        dimensions.width
                      )}
                      value={businessContactPersonValue}
                    />
                  </View>
                  {/* View 11 */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingBottom: 5, paddingTop: 5 },
                      dimensions.width
                    )}
                  >
                    {/* Business and Support Email */}
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Strong Inverse'],
                          fontFamily: 'Inter_600SemiBold',
                          paddingBottom: 5,
                          paddingTop: 10,
                        },
                        dimensions.width
                      )}
                    >
                      {'Business / Support Email'}
                    </Text>
                    {/* Business and Support Email */}
                    <TextInput
                      allowFontScaling={true}
                      autoCapitalize={'none'}
                      changeTextDelay={500}
                      multiline={false}
                      onChangeText={newBusinessAndSupportEmailValue => {
                        try {
                          setBusinessAndSupportEmailValue(
                            newBusinessAndSupportEmailValue
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      onSubmitEditing={() => {
                        try {
                          businessPhoneNumber0XME34t3Ref.current.focus();
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'contact@email.com'}
                      placeholderTextColor={theme.colors['Light']}
                      ref={businessAndSupportEmailbJ6O11A1Ref}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextInputStyles(theme)['Text Input'],
                        dimensions.width
                      )}
                      value={businessAndSupportEmailValue}
                    />
                  </View>
                  {/* View 5 */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingBottom: 5, paddingTop: 5 },
                      dimensions.width
                    )}
                  >
                    {/* Phone Number */}
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Strong Inverse'],
                          fontFamily: 'Inter_600SemiBold',
                          paddingBottom: 5,
                          paddingTop: 10,
                        },
                        dimensions.width
                      )}
                    >
                      {'Phone Number'}
                    </Text>
                    {/* Business Phone Number */}
                    <NumberInput
                      allowFontScaling={true}
                      changeTextDelay={500}
                      onChangeText={newBusinessPhoneNumberValue => {
                        try {
                          setBusinessPhoneNumberValue(
                            newBusinessPhoneNumberValue
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      onSubmitEditing={() => {
                        try {
                          addressSRamqJCpRef.current.focus();
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'123.456.7890'}
                      placeholderTextColor={theme.colors['Light']}
                      ref={businessPhoneNumber0XME34t3Ref}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.NumberInputStyles(theme)['Number Input'],
                        dimensions.width
                      )}
                      value={businessPhoneNumberValue}
                    />
                  </View>
                  {/* View 6 */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingBottom: 5, paddingTop: 5 },
                      dimensions.width
                    )}
                  >
                    {/* Address */}
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Strong Inverse'],
                          fontFamily: 'Inter_600SemiBold',
                          paddingBottom: 5,
                          paddingTop: 10,
                        },
                        dimensions.width
                      )}
                    >
                      {'Address'}
                    </Text>
                    {/* Address */}
                    <TextInput
                      allowFontScaling={true}
                      autoCapitalize={'none'}
                      changeTextDelay={500}
                      multiline={false}
                      onChangeText={newAddressValue => {
                        try {
                          setAddressValue(newAddressValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      onSubmitEditing={() => {
                        try {
                          address2Er4Xv5f4Ref.current.focus();
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'1234 Main Street'}
                      placeholderTextColor={theme.colors['Light']}
                      ref={addressSRamqJCpRef}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextInputStyles(theme)['Text Input'],
                        dimensions.width
                      )}
                      value={addressValue}
                    />
                  </View>
                  {/* View 7 */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingBottom: 5, paddingTop: 5 },
                      dimensions.width
                    )}
                  >
                    {/* Address 2 */}
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Strong Inverse'],
                          fontFamily: 'Inter_600SemiBold',
                          paddingBottom: 5,
                          paddingTop: 10,
                        },
                        dimensions.width
                      )}
                    >
                      {'Address 2'}
                    </Text>
                    {/* Address 2 */}
                    <TextInput
                      allowFontScaling={true}
                      autoCapitalize={'none'}
                      changeTextDelay={500}
                      multiline={false}
                      onChangeText={newAddress2Value => {
                        try {
                          setAddress2Value(newAddress2Value);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      onSubmitEditing={() => {
                        try {
                          city0pW7ZamaRef.current.focus();
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Suite 202'}
                      placeholderTextColor={theme.colors['Light']}
                      ref={address2Er4Xv5f4Ref}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextInputStyles(theme)['Text Input'],
                        dimensions.width
                      )}
                      value={address2Value}
                    />
                  </View>
                  {/* View 8 */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingBottom: 5, paddingTop: 5 },
                      dimensions.width
                    )}
                  >
                    {/* City */}
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Strong Inverse'],
                          fontFamily: 'Inter_600SemiBold',
                          paddingBottom: 5,
                          paddingTop: 10,
                        },
                        dimensions.width
                      )}
                    >
                      {'City'}
                    </Text>
                    {/* City */}
                    <TextInput
                      allowFontScaling={true}
                      autoCapitalize={'none'}
                      changeTextDelay={500}
                      multiline={false}
                      onChangeText={newCityValue => {
                        try {
                          setCityValue(newCityValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      onSubmitEditing={() => {
                        try {
                          Keyboard.dismiss();
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Houston'}
                      placeholderTextColor={theme.colors['Light']}
                      ref={city0pW7ZamaRef}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextInputStyles(theme)['Text Input'],
                        dimensions.width
                      )}
                      value={cityValue}
                    />
                  </View>
                  {/* View 10 */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingBottom: 5, paddingTop: 5 },
                      dimensions.width
                    )}
                  >
                    {/* Zip Code */}
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Strong Inverse'],
                          fontFamily: 'Inter_600SemiBold',
                          paddingBottom: 5,
                          paddingTop: 10,
                        },
                        dimensions.width
                      )}
                    >
                      {'Zip Code'}
                    </Text>
                    {/* Zip Code */}
                    <NumberInput
                      allowFontScaling={true}
                      changeTextDelay={500}
                      onChangeText={newZipCodeValue => {
                        try {
                          setZipCodeValue(newZipCodeValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      onSubmitEditing={() => {
                        try {
                          countynKeXSGK5Ref.current.focus();
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'77002'}
                      placeholderTextColor={theme.colors['Light']}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.NumberInputStyles(theme)['Number Input'],
                          { minHeight: 60 }
                        ),
                        dimensions.width
                      )}
                      value={zipCodeValue}
                    />
                  </View>
                  {/* View 12 */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingBottom: 5, paddingTop: 5 },
                      dimensions.width
                    )}
                  >
                    {/* County */}
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Strong Inverse'],
                          fontFamily: 'Inter_600SemiBold',
                          paddingBottom: 5,
                          paddingTop: 10,
                        },
                        dimensions.width
                      )}
                    >
                      {'County'}
                    </Text>
                    {/* County */}
                    <TextInput
                      allowFontScaling={true}
                      autoCapitalize={'none'}
                      changeTextDelay={500}
                      multiline={true}
                      onChangeText={newCountyValue => {
                        try {
                          setCountyValue(newCountyValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      onSubmitEditing={() => {
                        try {
                          Keyboard.dismiss();
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Harris'}
                      placeholderTextColor={theme.colors['Light']}
                      ref={countynKeXSGK5Ref}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextInputStyles(theme)['Text Input'],
                        dimensions.width
                      )}
                      value={countyValue}
                    />
                  </View>

                  <View>
                    {/* Error Message */}
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Error'],
                          fontFamily: 'Inter_400Regular',
                          paddingTop: 10,
                        },
                        dimensions.width
                      )}
                    >
                      {Constants['ak_errorMessage']}
                    </Text>
                  </View>
                  {/* Continue Button */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        paddingBottom: 40,
                        paddingTop: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Button
                      onPress={() => {
                        const handler = async () => {
                          try {
                            const createResult = (
                              await businessDataAkurieCreateContactInfoPOST.mutateAsync(
                                {
                                  Address: addressValue,
                                  Address_2: address2Value,
                                  City: cityValue,
                                  County: countyValue,
                                  business_ID: Constants['ak_businessID'],
                                  business_contact_person:
                                    businessContactPersonValue,
                                  business_email: businessAndSupportEmailValue,
                                  business_zip_code: zipCodeValue,
                                  phone_number: businessPhoneNumberValue,
                                }
                              )
                            )?.json;
                            const msg_error = createResult?.message;
                            setGlobalVariableValue({
                              key: 'ak_errorMessage',
                              value: msg_error,
                            });
                            if (msg_error) {
                              return;
                            }
                            setGlobalVariableValue({
                              key: 'ak_errorMessage',
                              value: false,
                            });
                            navigation.navigate('MyBusinessInfoScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            backgroundColor: theme.colors['Akurie green'],
                            borderRadius: 9,
                            color: theme.colors['Strong'],
                            fontFamily: 'Inter_500Medium',
                            fontSize: 16,
                            height: 50,
                            width: '100%',
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Continue'}
                    />
                  </View>
                </View>
              )}
            </>
          </View>
        </KeyboardAwareScrollView>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(AutoFilingSetup1Screen);
