export default {
  Inter_400Regular: require('../assets/fonts/Inter_400Regular.ttf'),
  Inter_500Medium: require('../assets/fonts/Inter_500Medium.ttf'),
  Inter_600SemiBold: require('../assets/fonts/Inter_600SemiBold.ttf'),
  Inter_700Bold: require('../assets/fonts/Inter_700Bold.ttf'),
  Inter_300Light: require('../assets/fonts/Inter_300Light.ttf'),
  Inter_200ExtraLight: require('../assets/fonts/Inter_200ExtraLight.ttf'),
  Lato_700Bold: require('../assets/fonts/Lato_700Bold.ttf'),
  Merriweather_700Bold: require('../assets/fonts/Merriweather_700Bold.ttf'),
  Nunito_700Bold: require('../assets/fonts/Nunito_700Bold.ttf'),
  Nunito_500Medium: require('../assets/fonts/Nunito_500Medium.ttf'),
  Poppins_500Medium: require('../assets/fonts/Poppins_500Medium.ttf'),
  Poppins_600SemiBold: require('../assets/fonts/Poppins_600SemiBold.ttf'),
  Poppins_400Regular: require('../assets/fonts/Poppins_400Regular.ttf'),
};
