import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AuthAkurieApi from '../apis/AuthAkurieApi.js';
import * as BusinessDataAkurieApi from '../apis/BusinessDataAkurieApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  CheckboxRow,
  Circle,
  Divider,
  Icon,
  IconButton,
  Picker,
  ScreenContainer,
  Surface,
  SwipeableItem,
  SwipeableItemButton,
  SwipeableList,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import {
  ActivityIndicator,
  ImageBackground,
  StatusBar,
  Text,
  View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const OwnershipInformationScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [Addr1Edit, setAddr1Edit] = React.useState('');
  const [Addr2Edit, setAddr2Edit] = React.useState('');
  const [BusEditCheck, setBusEditCheck] = React.useState(false);
  const [BusNameEdit, setBusNameEdit] = React.useState('');
  const [FNameEdit, setFNameEdit] = React.useState('');
  const [LNameEdit, setLNameEdit] = React.useState('');
  const [O2ZipCodeInputValue, setO2ZipCodeInputValue] = React.useState('');
  const [PercentEdit, setPercentEdit] = React.useState('');
  const [PersEditCheck, setPersEditCheck] = React.useState(false);
  const [Zipedit, setZipedit] = React.useState('');
  const [add_owner_view, setAdd_owner_view] = React.useState(false);
  const [birthdayPickerValue, setBirthdayPickerValue] = React.useState(
    new Date()
  );
  const [blur_View, setBlur_View] = React.useState(false);
  const [businessCheckboxRowValue, setBusinessCheckboxRowValue] =
    React.useState('');
  const [checkboxRowValue, setCheckboxRowValue] = React.useState('');
  const [cityEdit, setCityEdit] = React.useState('');
  const [classAccess1, setClassAccess1] = React.useState(true);
  const [classAccess2, setClassAccess2] = React.useState(false);
  const [classAccess3, setClassAccess3] = React.useState(false);
  const [classAccess4, setClassAccess4] = React.useState(false);
  const [current_owner_id, setCurrent_owner_id] = React.useState('');
  const [datePickerValue, setDatePickerValue] = React.useState(new Date());
  const [deleteConfirmCheckboxRowValue, setDeleteConfirmCheckboxRowValue] =
    React.useState('');
  const [edit_owner, setEdit_owner] = React.useState(false);
  const [emailInputValue, setEmailInputValue] = React.useState('');
  const [f21, setF21] = React.useState(false);
  const [f22, setF22] = React.useState(false);
  const [f23, setF23] = React.useState('');
  const [f24, setF24] = React.useState('');
  const [f25, setF25] = React.useState('');
  const [f26, setF26] = React.useState('');
  const [f27, setF27] = React.useState('');
  const [f28, setF28] = React.useState('');
  const [f29, setF29] = React.useState('');
  const [f30, setF30] = React.useState('');
  const [firstNameInputValue, setFirstNameInputValue] = React.useState('');
  const [jobTitlePickerValue, setJobTitlePickerValue] = React.useState('');
  const [lastNameInputValue, setLastNameInputValue] = React.useState('');
  const [
    newOrExistingBusinessPickerValue,
    setNewOrExistingBusinessPickerValue,
  ] = React.useState('');
  const [numberInputValue, setNumberInputValue] = React.useState('');
  const [oAddress2InputValue, setOAddress2InputValue] = React.useState('');
  const [oAddressInputValue, setOAddressInputValue] = React.useState('');
  const [oBusinessNameInputValue, setOBusinessNameInputValue] =
    React.useState('');
  const [oCityInputValue, setOCityInputValue] = React.useState('');
  const [oFirstNameInputValue, setOFirstNameInputValue] = React.useState('');
  const [oLastNameInputValue, setOLastNameInputValue] = React.useState('');
  const [oPercentageInputValue, setOPercentageInputValue] = React.useState('');
  const [oStateInputValue, setOStateInputValue] = React.useState('');
  const [oZipCodeInputValue, setOZipCodeInputValue] = React.useState('');
  const [personCheckboxRowValue, setPersonCheckboxRowValue] =
    React.useState('');
  const [pickerValue, setPickerValue] = React.useState('');
  const [stateEdit, setStateEdit] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const [textInputValue2, setTextInputValue2] = React.useState('');
  const [view_owners, setView_owners] = React.useState(true);
  const businessDataAkurieDeleteOwnerDELETE =
    BusinessDataAkurieApi.useDeleteOwnerDELETE();
  const businessDataAkurieAddingOwnersPOST =
    BusinessDataAkurieApi.useAddingOwnersPOST();
  const businessDataAkurieEditOwnerPUT =
    BusinessDataAkurieApi.useEditOwnerPUT();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const authResponse = (await AuthAkurieApi.meGET(Constants))?.json;
        const tokenExpire = authResponse?.message;
        if (tokenExpire) {
          navigation.navigate('SessionExpiredScreen');
        }
        if (tokenExpire) {
          return;
        }
        StatusBar.setBarStyle('dark-content');
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors.studilyWhite4 },
        dimensions.width
      )}
    >
      {/* Navigation Frame */}
      <View
        style={StyleSheet.applyWidth(
          {
            borderBottomWidth: 2,
            borderColor: theme.colors['Akurie green'],
            height: 80,
            marginBottom: 15,
            marginTop: 15,
          },
          dimensions.width
        )}
      >
        <ImageBackground
          resizeMode={'cover'}
          source={Images.Asset13}
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              backgroundColor: theme.colors['Akurie Dark Blue'],
              flexDirection: 'row',
              height: '100%',
            },
            dimensions.width
          )}
        >
          {/* Left Side Frame */}
          <View
            style={StyleSheet.applyWidth(
              { paddingLeft: 9, paddingRight: 9 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Icon Flex Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                {/* Icon  */}
                <Icon
                  color={theme.colors['Background']}
                  name={'Ionicons/arrow-back-outline'}
                  size={24}
                />
              </View>
            </Touchable>
          </View>
          {/* Middle Frame */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexGrow: 1,
                flexShrink: 0,
                justifyContent: 'center',
              },
              dimensions.width
            )}
          >
            {/* Text Frame  */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexGrow: 0,
                  flexShrink: 0,
                  paddingBottom: 12,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 12,
                },
                dimensions.width
              )}
            >
              {/* Text 12 18 Regular */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Akurie green'],
                    fontFamily: 'System',
                    fontSize: 22,
                    fontWeight: '400',
                    lineHeight: 18,
                    paddingTop: 8,
                  },
                  dimensions.width
                )}
              >
                {'Ownership Info'}
              </Text>
            </View>
          </View>
          {/* Left Side Frame */}
          <View
            style={StyleSheet.applyWidth(
              { paddingLeft: 9, paddingRight: 9 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('HelpSupportScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Icon Flex Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                {/* Icon  */}
                <Icon
                  color={theme.colors['Background']}
                  name={'Ionicons/help-circle-outline'}
                  size={32}
                />
              </View>
            </Touchable>
          </View>
        </ImageBackground>
      </View>

      <ImageBackground
        resizeMode={'stretch'}
        style={StyleSheet.applyWidth(
          { height: '100%', width: '100%' },
          dimensions.width
        )}
      >
        {/* delete blur confirm */}
        <>
          {!blur_View ? null : (
            <BlurView
              intensity={80}
              style={StyleSheet.applyWidth(
                GlobalStyles.BlurViewStyles(theme)['delete blur confirm'],
                dimensions.width
              )}
              tint={'dark'}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    height: '70%',
                    justifyContent: 'flex-start',
                    marginTop: 30,
                    padding: 20,
                  },
                  dimensions.width
                )}
              >
                {/* Card */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: theme.colors['Divider'],
                      borderRadius: 12,
                      height: '100%',
                      minWidth: '90%',
                      overflow: 'hidden',
                    },
                    dimensions.width
                  )}
                >
                  <BusinessDataAkurieApi.FetchGetBusinessOwnerGET
                    owner_id={current_owner_id}
                  >
                    {({ loading, error, data, refetchGetBusinessOwner }) => {
                      const fetchData = data?.json;
                      if (loading) {
                        return <ActivityIndicator />;
                      }

                      if (error || data?.status < 200 || data?.status >= 300) {
                        return <ActivityIndicator />;
                      }

                      return (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={Images.FirefoxPHKyvBthWe}
                          style={StyleSheet.applyWidth(
                            { height: '100%' },
                            dimensions.width
                          )}
                        >
                          <ImageBackground
                            resizeMode={'cover'}
                            source={Images.AdobeStock606166034}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ImageBackgroundStyles(theme)[
                                  'Image Background'
                                ],
                                {
                                  alignItems: 'center',
                                  height: 150,
                                  justifyContent: 'center',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  marginBottom: 10,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      color: theme.colors['Strong_Inverse'],
                                      fontFamily: 'Inter_400Regular',
                                      fontSize: 22,
                                      textAlign: 'center',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Delete Warning'}
                              </Text>
                              <Icon
                                color={theme.colors['Akurie green']}
                                name={'AntDesign/warning'}
                                size={60}
                                style={StyleSheet.applyWidth(
                                  { marginLeft: 15 },
                                  dimensions.width
                                )}
                              />
                            </View>
                          </ImageBackground>

                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  color: theme.colors['Error'],
                                  fontFamily: 'Inter_300Light',
                                  fontSize: 14,
                                  marginTop: 10,
                                  paddingLeft: 24,
                                  textAlign: 'center',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'You are about to delete:'}
                          </Text>

                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  color: theme.colors['Akurie Dark Blue'],
                                  fontFamily: 'Inter_600SemiBold',
                                  fontSize: 14,
                                  marginTop: 10,
                                  textAlign: 'center',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {fetchData?.First_Name} {fetchData?.Last_Name}
                            {fetchData?.Entity_Owner_Name}
                            {'\nOwnership Percentage: %'}
                            {fetchData?.Ownership_Percentage}
                            {'\n'}
                            {fetchData?.City}
                            {','}
                            {fetchData?.State}
                          </Text>
                          {/* Body */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                paddingBottom: 24,
                                paddingLeft: 24,
                                paddingRight: 24,
                                paddingTop: 24,
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors['Custom #101010'],
                                  fontFamily: 'Inter_400Regular',
                                  fontSize: 12,
                                  paddingLeft: 15,
                                  paddingRight: 15,
                                  textAlign: 'center',
                                },
                                dimensions.width
                              )}
                            >
                              {
                                'Please confirm that you would like to permanently delete this owner from your company and account. This means that this person/entity will no longer be included in filings and document filed by Akurie on your behalf. '
                              }
                            </Text>
                            <Divider
                              color={theme.colors.divider}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.DividerStyles(theme)['Divider'],
                                  { marginBottom: 10, marginTop: 10 }
                                ),
                                dimensions.width
                              )}
                            />
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'center', width: '100%' },
                                dimensions.width
                              )}
                            >
                              {/* Delete Confirm Checkbox Row */}
                              <CheckboxRow
                                checkedIcon={
                                  'MaterialCommunityIcons/checkbox-marked'
                                }
                                color={theme.colors['Akurie Dark Blue']}
                                label={'I understand and agree.'}
                                onPress={newDeleteConfirmCheckboxRowValue => {
                                  try {
                                    setDeleteConfirmCheckboxRowValue(
                                      newDeleteConfirmCheckboxRowValue
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                status={deleteConfirmCheckboxRowValue}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.CheckboxRowStyles(theme)[
                                      'Checkbox Row'
                                    ],
                                    {
                                      fontFamily: 'Inter_600SemiBold',
                                      textAlign: 'center',
                                    }
                                  ),
                                  dimensions.width
                                )}
                                uncheckedIcon={
                                  'MaterialCommunityIcons/checkbox-blank-outline'
                                }
                              />
                            </View>
                            {/* Topics */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  flexDirection: 'row',
                                  justifyContent: 'space-around',
                                  marginTop: 10,
                                },
                                dimensions.width
                              )}
                            >
                              <Button
                                onPress={() => {
                                  try {
                                    setBlur_View(false);
                                    setCurrent_owner_id('');
                                    setDeleteConfirmCheckboxRowValue(false);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor:
                                      theme.colors['Akurie Dark Blue'],
                                    borderRadius: 8,
                                    color: theme.colors['Akurie green'],
                                    fontFamily: 'System',
                                    fontWeight: '700',
                                    marginRight: 8,
                                    textAlign: 'center',
                                    width: 130,
                                  },
                                  dimensions.width
                                )}
                                title={'Cancel'}
                              />
                              <>
                                {!deleteConfirmCheckboxRowValue ? null : (
                                  <Button
                                    onPress={() => {
                                      const handler = async () => {
                                        try {
                                          (
                                            await businessDataAkurieDeleteOwnerDELETE.mutateAsync(
                                              { owner_id: current_owner_id }
                                            )
                                          )?.json;
                                          setBlur_View(false);
                                          setCurrent_owner_id('');
                                          setDeleteConfirmCheckboxRowValue(
                                            false
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      };
                                      handler();
                                    }}
                                    style={StyleSheet.applyWidth(
                                      {
                                        backgroundColor: theme.colors['Error'],
                                        borderRadius: 8,
                                        fontFamily: 'System',
                                        fontWeight: '700',
                                        textAlign: 'center',
                                        width: 130,
                                      },
                                      dimensions.width
                                    )}
                                    title={'Delete'}
                                  />
                                )}
                              </>
                            </View>
                          </View>
                        </ImageBackground>
                      );
                    }}
                  </BusinessDataAkurieApi.FetchGetBusinessOwnerGET>
                </View>
              </View>
            </BlurView>
          )}
        </>
        <>
          {blur_View ? null : (
            <KeyboardAwareScrollView
              contentContainerStyle={StyleSheet.applyWidth(
                { justifyContent: 'center' },
                dimensions.width
              )}
              extraHeight={400}
              keyboardShouldPersistTaps={'never'}
              showsVerticalScrollIndicator={true}
            >
              <BusinessDataAkurieApi.FetchGetBusinessGET
                business_id={Constants['ak_businessID']}
              >
                {({ loading, error, data, refetchGetBusiness }) => {
                  const fetchData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['Akurie Dark Blue'],
                          justifyContent: 'center',
                          paddingBottom: 25,
                          paddingLeft: 20,
                          paddingRight: 20,
                          paddingTop: 25,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Custom Color'],
                            fontFamily: 'Inter_500Medium',
                            fontSize: 20,
                            textAlign: 'center',
                          },
                          dimensions.width
                        )}
                      >
                        {fetchData?.user_business_name}
                      </Text>

                      <BusinessDataAkurieApi.FetchGetOwnersGET
                        business_id={Constants['ak_businessID']}
                      >
                        {({ loading, error, data, refetchGetOwners }) => {
                          const fetchData = data?.json;
                          if (loading) {
                            return <ActivityIndicator />;
                          }

                          if (
                            error ||
                            data?.status < 200 ||
                            data?.status >= 300
                          ) {
                            return <ActivityIndicator />;
                          }

                          return (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor:
                                    theme.colors['Akurie Dark Blue'],
                                  borderRadius: 9,
                                  paddingBottom: 10,
                                  paddingLeft: 35,
                                  paddingRight: 35,
                                  paddingTop: 10,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors['Medium Inverse'],
                                    fontFamily: 'Inter_400Regular',
                                    textAlign: 'center',
                                  },
                                  dimensions.width
                                )}
                              >
                                {'Number of Current Owners: '}
                                {fetchData?.Number_Of_Owners}
                              </Text>

                              <Text
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors['Medium Inverse'],
                                    fontFamily: 'System',
                                    fontWeight: '400',
                                  },
                                  dimensions.width
                                )}
                              >
                                {null}
                              </Text>

                              <Text
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors['Medium Inverse'],
                                    fontFamily: 'System',
                                    fontWeight: '400',
                                  },
                                  dimensions.width
                                )}
                              >
                                {null}
                              </Text>
                            </View>
                          );
                        }}
                      </BusinessDataAkurieApi.FetchGetOwnersGET>
                      <>
                        {add_owner_view ? null : (
                          <Button
                            icon={'AntDesign/pluscircleo'}
                            onPress={() => {
                              try {
                                setAdd_owner_view(true);
                                setEdit_owner(false);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ButtonStyles(theme)['Button'],
                                {
                                  backgroundColor: theme.colors['Akurie green'],
                                  borderRadius: 9,
                                  color: theme.colors['Akurie Dark Blue'],
                                  fontFamily: 'Inter_500Medium',
                                  marginTop: 10,
                                }
                              ),
                              dimensions.width
                            )}
                            title={'Add A Owner'}
                          />
                        )}
                      </>
                      <>
                        {!add_owner_view ? null : (
                          <Button
                            icon={'Ionicons/list'}
                            onPress={() => {
                              try {
                                setAdd_owner_view(false);
                                setEdit_owner(false);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ButtonStyles(theme)['Button'],
                                {
                                  backgroundColor:
                                    theme.colors['Akurie Primary'],
                                  borderRadius: 9,
                                  color: theme.colors['Strong Inverse'],
                                  fontFamily: 'Inter_500Medium',
                                  marginTop: 10,
                                }
                              ),
                              dimensions.width
                            )}
                            title={'View All Owners'}
                          />
                        )}
                      </>
                    </View>
                  );
                }}
              </BusinessDataAkurieApi.FetchGetBusinessGET>
              <>
                {edit_owner ? null : (
                  <View>
                    <>
                      {add_owner_view ? null : (
                        <Surface
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: 'rgba(0, 0, 0, 0)',
                              borderRadius: 6,
                              minHeight: 40,
                              opacity: 0.88,
                              paddingBottom: 15,
                              paddingTop: 15,
                            },
                            dimensions.width
                          )}
                        >
                          <View>
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignContent: 'space-between',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                },
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  },
                                  dimensions.width
                                )}
                              >
                                <Icon
                                  name={'AntDesign/doubleleft'}
                                  size={24}
                                  style={StyleSheet.applyWidth(
                                    { marginRight: 5 },
                                    dimensions.width
                                  )}
                                />
                                <Text
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      { fontFamily: 'Inter_400Regular' }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'Delete'}
                                </Text>
                              </View>
                              {/* View 2 */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      { fontFamily: 'Inter_400Regular' }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'Edit'}
                                </Text>
                                {/* Icon 2 */}
                                <Icon
                                  name={'AntDesign/doubleright'}
                                  size={24}
                                  style={StyleSheet.applyWidth(
                                    { marginLeft: 5 },
                                    dimensions.width
                                  )}
                                />
                              </View>
                            </View>

                            <BusinessDataAkurieApi.FetchGetOwnersGET
                              business_id={Constants['ak_businessID']}
                            >
                              {({ loading, error, data, refetchGetOwners }) => {
                                const fetchData = data?.json;
                                if (loading) {
                                  return <ActivityIndicator />;
                                }

                                if (
                                  error ||
                                  data?.status < 200 ||
                                  data?.status >= 300
                                ) {
                                  return <ActivityIndicator />;
                                }

                                return (
                                  <SwipeableList
                                    data={fetchData?.List_Of_Owners}
                                    disableScrollWhenSwiping={true}
                                    estimatedItemSize={50}
                                    keyExtractor={(swipeableListData, index) =>
                                      swipeableListData?.id ??
                                      swipeableListData?.uuid ??
                                      index.toString()
                                    }
                                    listComponent={'FlatList'}
                                    listKey={'Cbxq8Plk'}
                                    numColumns={1}
                                    onEndReachedThreshold={0.5}
                                    renderItem={({ item, index }) => {
                                      const swipeableListData = item;
                                      return (
                                        <SwipeableItem
                                          closeOnPress={true}
                                          friction={20}
                                          onSwipedRight={swipedIndex => {
                                            try {
                                              setEdit_owner(true);
                                              setCurrent_owner_id(
                                                swipeableListData?.id
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          rightSwipeBackgroundColor={
                                            theme.colors['Akurie Dark Blue']
                                          }
                                          rightSwipeColor={
                                            theme.colors['Akurie green']
                                          }
                                          rightSwipeIcon={'AntDesign/edit'}
                                          rightSwipeTitle={'Edit'}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.SwipeableItemStyles(
                                              theme
                                            )['Swipeable Item'],
                                            dimensions.width
                                          )}
                                          swipeActivationPercentage={80}
                                          swipeToClosePercent={50}
                                          swipeToOpenPercent={50}
                                        >
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                borderBottomWidth: 1,
                                                borderColor:
                                                  theme.colors['Divider'],
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start',
                                                margin: 2,
                                                marginTop: 10,
                                                paddingBottom: 10,
                                                paddingLeft: 30,
                                                paddingTop: 10,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <>
                                              {!swipeableListData?.Natural_Person ? null : (
                                                <Icon
                                                  color={
                                                    theme.colors[
                                                      'Akurie Primary'
                                                    ]
                                                  }
                                                  name={'FontAwesome/user'}
                                                  size={24}
                                                  style={StyleSheet.applyWidth(
                                                    { marginRight: 40 },
                                                    dimensions.width
                                                  )}
                                                />
                                              )}
                                            </>
                                            <>
                                              {!swipeableListData?.Business__Other_Entity ? null : (
                                                <Icon
                                                  color={
                                                    theme.colors[
                                                      'Akurie Primary'
                                                    ]
                                                  }
                                                  name={'FontAwesome/building'}
                                                  size={24}
                                                  style={StyleSheet.applyWidth(
                                                    { marginRight: 40 },
                                                    dimensions.width
                                                  )}
                                                />
                                              )}
                                            </>
                                            <View
                                              style={StyleSheet.applyWidth(
                                                { alignItems: 'stretch' },
                                                dimensions.width
                                              )}
                                            >
                                              <Text
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'],
                                                    {
                                                      fontFamily:
                                                        'Inter_400Regular',
                                                      fontSize: 16,
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {swipeableListData?.First_Name}{' '}
                                                {swipeableListData?.Last_Name}
                                              </Text>

                                              <Text
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'],
                                                    {
                                                      fontFamily:
                                                        'Inter_400Regular',
                                                      fontSize: 16,
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {
                                                  swipeableListData?.Entity_Owner_Name
                                                }
                                              </Text>
                                              {/* Text 2 */}
                                              <Text
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'],
                                                    {
                                                      fontFamily:
                                                        'Inter_600SemiBold',
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {'Ownership Percentage: %'}
                                                {
                                                  swipeableListData?.Ownership_Percentage
                                                }
                                              </Text>
                                              {/* Text 3 */}
                                              <Text
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'],
                                                    {
                                                      fontFamily:
                                                        'Inter_300Light',
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {swipeableListData?.City}
                                                {' - '}
                                                {swipeableListData?.State}
                                              </Text>
                                            </View>
                                          </View>
                                          <SwipeableItemButton
                                            backgroundColor={
                                              theme.colors['Error']
                                            }
                                            closeOnPress={true}
                                            color={
                                              theme.colors['Strong Inverse']
                                            }
                                            icon={'FontAwesome/trash-o'}
                                            onPress={() => {
                                              try {
                                                setBlur_View(true);
                                                setCurrent_owner_id(
                                                  swipeableListData?.id
                                                );
                                              } catch (err) {
                                                console.error(err);
                                              }
                                            }}
                                            revealSwipeDirection={'left'}
                                            title={'Remove'}
                                          />
                                        </SwipeableItem>
                                      );
                                    }}
                                    showsHorizontalScrollIndicator={true}
                                    showsVerticalScrollIndicator={true}
                                    style={StyleSheet.applyWidth(
                                      { marginTop: 20 },
                                      dimensions.width
                                    )}
                                  />
                                );
                              }}
                            </BusinessDataAkurieApi.FetchGetOwnersGET>
                          </View>
                        </Surface>
                      )}
                    </>
                  </View>
                )}
              </>
              {/* General Settings Frame */}
              <>
                {!add_owner_view ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderBottomWidth: 1,
                        borderColor: theme.colors.studilyGray3,
                        borderLeftWidth: 1,
                        borderRadius: 6,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        marginBottom: 40,
                        marginTop: 18,
                        paddingBottom: 6,
                        paddingLeft: 6,
                        paddingRight: 6,
                        paddingTop: 6,
                      },
                      dimensions.width
                    )}
                  >
                    <View>
                      {/* Bottom option */}
                      <Surface
                        style={StyleSheet.applyWidth(
                          {
                            borderBottomWidth: 1,
                            borderColor: theme.colors.divider,
                            borderLeftWidth: 1,
                            borderRadius: 10,
                            borderRightWidth: 1,
                            borderTopWidth: 1,
                            justifyContent: 'center',
                            marginLeft: 16,
                            marginRight: 16,
                            marginTop: 25,
                            minHeight: 40,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Owner Type View */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              borderBottomWidth: 1,
                              borderColor: theme.colors.divider,
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              minHeight: 80,
                              paddingBottom: 12,
                              paddingLeft: 16,
                              paddingRight: 16,
                              paddingTop: 12,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Owner Type */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                marginBottom: 10,
                                paddingBottom: 5,
                                paddingTop: 5,
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Owner Type */}
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors['Medium'],
                                  fontFamily: 'System',
                                  fontWeight: '700',
                                  marginBottom: 8,
                                  marginLeft: 5,
                                },
                                dimensions.width
                              )}
                            >
                              {'Owner Type'}
                            </Text>
                            {/* Business Checkbox Row */}
                            <CheckboxRow
                              checkedIcon={'AntDesign/checksquare'}
                              color={theme.colors['Akurie Primary']}
                              label={'Business / Other Entity'}
                              onPress={newBusinessCheckboxRowValue => {
                                try {
                                  setBusinessCheckboxRowValue(
                                    newBusinessCheckboxRowValue
                                  );
                                  setPersonCheckboxRowValue(false);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              status={businessCheckboxRowValue}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.CheckboxRowStyles(theme)[
                                  'Checkbox Row'
                                ],
                                dimensions.width
                              )}
                              uncheckedColor={theme.colors['Akurie Primary']}
                              uncheckedIcon={
                                'MaterialCommunityIcons/checkbox-blank-outline'
                              }
                            />
                            {/* Person Checkbox Row */}
                            <CheckboxRow
                              color={theme.colors['Akurie Primary']}
                              label={'Natural Person'}
                              onPress={newPersonCheckboxRowValue => {
                                try {
                                  setPersonCheckboxRowValue(
                                    newPersonCheckboxRowValue
                                  );
                                  setBusinessCheckboxRowValue(false);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              status={personCheckboxRowValue}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.CheckboxRowStyles(theme)[
                                  'Checkbox Row'
                                ],
                                dimensions.width
                              )}
                              uncheckedColor={theme.colors['Akurie Primary']}
                            />
                          </View>
                        </View>
                        {/* Entity Name View */}
                        <>
                          {!businessCheckboxRowValue ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors.divider,
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  minHeight: 80,
                                  paddingBottom: 12,
                                  paddingLeft: 16,
                                  paddingRight: 16,
                                  paddingTop: 12,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Busines Name */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    paddingBottom: 5,
                                    paddingTop: 5,
                                    width: '100%',
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Business Name */}
                                <Text
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors['Medium'],
                                      fontFamily: 'System',
                                      fontWeight: '700',
                                      marginBottom: 8,
                                      marginLeft: 5,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Entity Name'}
                                </Text>
                                {/* OBusiness Name Input */}
                                <TextInput
                                  enablesReturnKeyAutomatically={false}
                                  onChangeText={newOBusinessNameInputValue => {
                                    try {
                                      setOBusinessNameInputValue(
                                        newOBusinessNameInputValue
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  placeholder={'Enter a value...'}
                                  spellcheck={true}
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor:
                                        theme.colors['Background'],
                                      borderBottomWidth: 1,
                                      borderColor:
                                        theme.colors['Custom #999999'],
                                      borderRadius: 0,
                                      height: 50,
                                      paddingBottom: 12,
                                      paddingLeft: 8,
                                      paddingRight: 8,
                                      paddingTop: 12,
                                    },
                                    dimensions.width
                                  )}
                                  textContentType={'name'}
                                  value={oBusinessNameInputValue}
                                />
                              </View>
                            </View>
                          )}
                        </>
                        {/* First Name View */}
                        <>
                          {!personCheckboxRowValue ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors.divider,
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  minHeight: 80,
                                  paddingBottom: 12,
                                  paddingLeft: 16,
                                  paddingRight: 16,
                                  paddingTop: 12,
                                },
                                dimensions.width
                              )}
                            >
                              {/* First Name View */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    marginBottom: 10,
                                    paddingBottom: 5,
                                    paddingTop: 5,
                                    width: '100%',
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* First Name */}
                                <Text
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors['Medium'],
                                      fontFamily: 'System',
                                      fontWeight: '700',
                                      marginBottom: 8,
                                      marginLeft: 5,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'First Name'}
                                </Text>
                                {/* OFirst Name Input */}
                                <TextInput
                                  enablesReturnKeyAutomatically={false}
                                  onChangeText={newOFirstNameInputValue => {
                                    try {
                                      setOFirstNameInputValue(
                                        newOFirstNameInputValue
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  placeholder={'Enter a value...'}
                                  spellcheck={true}
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor:
                                        theme.colors['Background'],
                                      borderBottomWidth: 1,
                                      borderColor:
                                        theme.colors['Custom #999999'],
                                      borderRadius: 0,
                                      height: 50,
                                      paddingBottom: 12,
                                      paddingLeft: 8,
                                      paddingRight: 8,
                                      paddingTop: 12,
                                    },
                                    dimensions.width
                                  )}
                                  textContentType={'name'}
                                  value={oFirstNameInputValue}
                                />
                              </View>
                            </View>
                          )}
                        </>
                        {/* Last Name View */}
                        <>
                          {!personCheckboxRowValue ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors.divider,
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  minHeight: 80,
                                  paddingBottom: 12,
                                  paddingLeft: 16,
                                  paddingRight: 16,
                                  paddingTop: 12,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Last Name View */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    marginBottom: 10,
                                    paddingBottom: 5,
                                    paddingTop: 5,
                                    width: '100%',
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Last Name */}
                                <Text
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors['Medium'],
                                      fontFamily: 'System',
                                      fontWeight: '700',
                                      marginBottom: 8,
                                      marginLeft: 5,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Last Name'}
                                </Text>
                                {/* OLast Name Input */}
                                <TextInput
                                  enablesReturnKeyAutomatically={false}
                                  onChangeText={newOLastNameInputValue => {
                                    try {
                                      setOLastNameInputValue(
                                        newOLastNameInputValue
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  placeholder={'Enter a value...'}
                                  spellcheck={true}
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor:
                                        theme.colors['Background'],
                                      borderBottomWidth: 1,
                                      borderColor:
                                        theme.colors['Custom #999999'],
                                      borderRadius: 0,
                                      height: 50,
                                      paddingBottom: 12,
                                      paddingLeft: 8,
                                      paddingRight: 8,
                                      paddingTop: 12,
                                    },
                                    dimensions.width
                                  )}
                                  textContentType={'name'}
                                  value={oLastNameInputValue}
                                />
                              </View>
                            </View>
                          )}
                        </>
                        {/* Percentage View */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              borderBottomWidth: 1,
                              borderColor: theme.colors.divider,
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              minHeight: 80,
                              paddingBottom: 12,
                              paddingLeft: 16,
                              paddingRight: 16,
                              paddingTop: 12,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Percentage View */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                marginBottom: 10,
                                paddingBottom: 5,
                                paddingTop: 5,
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Percentage View */}
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors['Medium'],
                                  fontFamily: 'System',
                                  fontWeight: '700',
                                  marginBottom: 8,
                                  marginLeft: 5,
                                },
                                dimensions.width
                              )}
                            >
                              {'Ownership Percentage'}
                            </Text>
                            {/* OPercentage Input */}
                            <TextInput
                              enablesReturnKeyAutomatically={false}
                              keyboardType={'numeric'}
                              onChangeText={newOPercentageInputValue => {
                                try {
                                  setOPercentageInputValue(
                                    newOPercentageInputValue
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              placeholder={'Enter a value...'}
                              spellcheck={true}
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor: theme.colors['Background'],
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors['Custom #999999'],
                                  borderRadius: 0,
                                  height: 50,
                                  paddingBottom: 12,
                                  paddingLeft: 8,
                                  paddingRight: 8,
                                  paddingTop: 12,
                                },
                                dimensions.width
                              )}
                              textContentType={'name'}
                              value={oPercentageInputValue}
                            />
                          </View>
                        </View>
                        {/* Address View */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              borderBottomWidth: 1,
                              borderColor: theme.colors.divider,
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              minHeight: 80,
                              paddingBottom: 12,
                              paddingLeft: 16,
                              paddingRight: 16,
                              paddingTop: 12,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Address View */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                marginBottom: 10,
                                paddingBottom: 5,
                                paddingTop: 5,
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Address  */}
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors['Medium'],
                                  fontFamily: 'System',
                                  fontWeight: '700',
                                  marginBottom: 8,
                                  marginLeft: 5,
                                },
                                dimensions.width
                              )}
                            >
                              {'Address'}
                            </Text>
                            {/* OAddress Input */}
                            <TextInput
                              enablesReturnKeyAutomatically={false}
                              onChangeText={newOAddressInputValue => {
                                try {
                                  setOAddressInputValue(newOAddressInputValue);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              placeholder={'Enter a value...'}
                              spellcheck={true}
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor: theme.colors['Background'],
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors['Custom #999999'],
                                  borderRadius: 0,
                                  height: 50,
                                  paddingBottom: 12,
                                  paddingLeft: 8,
                                  paddingRight: 8,
                                  paddingTop: 12,
                                },
                                dimensions.width
                              )}
                              textContentType={'name'}
                              value={oAddressInputValue}
                            />
                          </View>
                        </View>
                        {/* Address 2 View */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              borderBottomWidth: 1,
                              borderColor: theme.colors.divider,
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              minHeight: 80,
                              paddingBottom: 12,
                              paddingLeft: 16,
                              paddingRight: 16,
                              paddingTop: 12,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Address 2 View */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                marginBottom: 10,
                                paddingBottom: 5,
                                paddingTop: 5,
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Address 2 */}
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors['Medium'],
                                  fontFamily: 'System',
                                  fontWeight: '700',
                                  marginBottom: 8,
                                  marginLeft: 5,
                                },
                                dimensions.width
                              )}
                            >
                              {'Address 2'}
                            </Text>
                            {/* OAddress 2 Input */}
                            <TextInput
                              enablesReturnKeyAutomatically={false}
                              onChangeText={newOAddress2InputValue => {
                                try {
                                  setOAddress2InputValue(
                                    newOAddress2InputValue
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              placeholder={'Enter a value...'}
                              spellcheck={true}
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor: theme.colors['Background'],
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors['Custom #999999'],
                                  borderRadius: 0,
                                  height: 50,
                                  paddingBottom: 12,
                                  paddingLeft: 8,
                                  paddingRight: 8,
                                  paddingTop: 12,
                                },
                                dimensions.width
                              )}
                              textContentType={'name'}
                              value={oAddress2InputValue}
                            />
                          </View>
                        </View>
                        {/* City View */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              borderBottomWidth: 1,
                              borderColor: theme.colors.divider,
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              minHeight: 80,
                              paddingBottom: 12,
                              paddingLeft: 16,
                              paddingRight: 16,
                              paddingTop: 12,
                            },
                            dimensions.width
                          )}
                        >
                          {/* City View */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                marginBottom: 10,
                                paddingBottom: 5,
                                paddingTop: 5,
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* City */}
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors['Medium'],
                                  fontFamily: 'System',
                                  fontWeight: '700',
                                  marginBottom: 8,
                                  marginLeft: 5,
                                },
                                dimensions.width
                              )}
                            >
                              {'City'}
                            </Text>
                            {/* OCity Input */}
                            <TextInput
                              enablesReturnKeyAutomatically={false}
                              onChangeText={newOCityInputValue => {
                                try {
                                  setOCityInputValue(newOCityInputValue);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              placeholder={'Enter a value...'}
                              spellcheck={true}
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor: theme.colors['Background'],
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors['Custom #999999'],
                                  borderRadius: 0,
                                  height: 50,
                                  paddingBottom: 12,
                                  paddingLeft: 8,
                                  paddingRight: 8,
                                  paddingTop: 12,
                                },
                                dimensions.width
                              )}
                              textContentType={'name'}
                              value={oCityInputValue}
                            />
                          </View>
                        </View>
                        {/* State View */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              borderBottomWidth: 1,
                              borderColor: theme.colors.divider,
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              minHeight: 80,
                              paddingBottom: 12,
                              paddingLeft: 16,
                              paddingRight: 16,
                              paddingTop: 12,
                            },
                            dimensions.width
                          )}
                        >
                          {/* State View */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                marginBottom: 10,
                                paddingBottom: 5,
                                paddingTop: 5,
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* State */}
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors['Medium'],
                                  fontFamily: 'System',
                                  fontWeight: '700',
                                  marginBottom: 8,
                                  marginLeft: 5,
                                },
                                dimensions.width
                              )}
                            >
                              {'State'}
                            </Text>
                            {/* OState Input */}
                            <TextInput
                              enablesReturnKeyAutomatically={false}
                              onChangeText={newOStateInputValue => {
                                try {
                                  setOStateInputValue(newOStateInputValue);
                                  setOStateInputValue(newOStateInputValue);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              placeholder={'Enter a value...'}
                              spellcheck={true}
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor: theme.colors['Background'],
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors['Custom #999999'],
                                  borderRadius: 0,
                                  height: 50,
                                  paddingBottom: 12,
                                  paddingLeft: 8,
                                  paddingRight: 8,
                                  paddingTop: 12,
                                },
                                dimensions.width
                              )}
                              textContentType={'name'}
                              value={oStateInputValue}
                            />
                          </View>
                        </View>
                        {/* Zip Code View */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              borderBottomWidth: 1,
                              borderColor: theme.colors.divider,
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              minHeight: 80,
                              paddingBottom: 12,
                              paddingLeft: 16,
                              paddingRight: 16,
                              paddingTop: 12,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Zip Code View */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                marginBottom: 10,
                                paddingBottom: 5,
                                paddingTop: 5,
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Zip Code */}
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors['Medium'],
                                  fontFamily: 'System',
                                  fontWeight: '700',
                                  marginBottom: 8,
                                  marginLeft: 5,
                                },
                                dimensions.width
                              )}
                            >
                              {'Zip Code'}
                            </Text>
                            {/* OZip Code Input */}
                            <TextInput
                              enablesReturnKeyAutomatically={false}
                              keyboardType={'numeric'}
                              onChangeText={newOZipCodeInputValue => {
                                try {
                                  setOZipCodeInputValue(newOZipCodeInputValue);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              placeholder={'Enter a value...'}
                              spellcheck={true}
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor: theme.colors['Background'],
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors['Custom #999999'],
                                  borderRadius: 0,
                                  height: 50,
                                  paddingBottom: 12,
                                  paddingLeft: 8,
                                  paddingRight: 8,
                                  paddingTop: 12,
                                },
                                dimensions.width
                              )}
                              textContentType={'name'}
                              value={oZipCodeInputValue}
                            />
                          </View>
                        </View>
                      </Surface>
                    </View>

                    <View
                      style={StyleSheet.applyWidth(
                        {
                          paddingBottom: 15,
                          paddingLeft: 15,
                          paddingRight: 15,
                          paddingTop: 8,
                        },
                        dimensions.width
                      )}
                    >
                      <View>
                        {/* Form */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              marginBottom: 10,
                              marginTop: 10,
                              opacity: 0.8,
                              paddingLeft: 20,
                              paddingRight: 20,
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            allowFontScaling={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  color: theme.colors['Error'],
                                  fontFamily: 'Inter_400Regular',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {Constants['ak_errorMessage']}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: 30,
                            paddingBottom: 40,
                            paddingLeft: 40,
                            paddingRight: 40,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Button */}
                        <View
                          style={StyleSheet.applyWidth(
                            { borderRadius: 0 },
                            dimensions.width
                          )}
                        >
                          {/* Touchable Frame */}
                          <View
                            style={StyleSheet.applyWidth(
                              { zIndex: 10 },
                              dimensions.width
                            )}
                          >
                            <Touchable
                              onPress={() => {
                                const handler = async () => {
                                  console.log('Touchable ON_PRESS Start');
                                  let error = null;
                                  try {
                                    console.log(
                                      'Start ON_PRESS:0 FETCH_REQUEST'
                                    );
                                    const ownerResponse = (
                                      await businessDataAkurieAddingOwnersPOST.mutateAsync(
                                        {
                                          Address: oAddressInputValue,
                                          Address_2: oAddress2InputValue,
                                          Business__Other_Entity:
                                            businessCheckboxRowValue,
                                          City: oCityInputValue,
                                          Entity_Owner_Name:
                                            oBusinessNameInputValue,
                                          First_Name: oFirstNameInputValue,
                                          Last_Name: oLastNameInputValue,
                                          Natural_Person:
                                            personCheckboxRowValue,
                                          Ownership_Percentage:
                                            oPercentageInputValue,
                                          State: oStateInputValue,
                                          user_business_id:
                                            Constants['ak_businessID'],
                                          zip_code: oZipCodeInputValue,
                                        }
                                      )
                                    )?.json;
                                    console.log(
                                      'Complete ON_PRESS:0 FETCH_REQUEST',
                                      { ownerResponse }
                                    );
                                    console.log('Start ON_PRESS:1 EXTRACT_KEY');
                                    const errorMsg = ownerResponse?.message;
                                    console.log(
                                      'Complete ON_PRESS:1 EXTRACT_KEY',
                                      { errorMsg }
                                    );
                                    console.log(
                                      'Start ON_PRESS:2 SET_VARIABLE'
                                    );
                                    setGlobalVariableValue({
                                      key: 'ak_errorMessage',
                                      value: errorMsg,
                                    });
                                    console.log(
                                      'Complete ON_PRESS:2 SET_VARIABLE'
                                    );
                                    console.log(
                                      'Start ON_PRESS:3 CONDITIONAL_STOP'
                                    );
                                    if (errorMsg) {
                                      return;
                                    }
                                    console.log(
                                      'Complete ON_PRESS:3 CONDITIONAL_STOP'
                                    );
                                    console.log(
                                      'Start ON_PRESS:4 SET_VARIABLE'
                                    );
                                    setGlobalVariableValue({
                                      key: 'ak_errorMessage',
                                      value: false,
                                    });
                                    console.log(
                                      'Complete ON_PRESS:4 SET_VARIABLE'
                                    );
                                    console.log('Start ON_PRESS:5 NAVIGATE');
                                    navigation.navigate('MyBusinessInfoScreen');
                                    console.log('Complete ON_PRESS:5 NAVIGATE');
                                  } catch (err) {
                                    console.error(err);
                                    error = err.message ?? err;
                                  }
                                  console.log(
                                    'Touchable ON_PRESS Complete',
                                    error ? { error } : 'no error'
                                  );
                                };
                                handler();
                              }}
                              style={StyleSheet.applyWidth(
                                { borderRadius: 0 },
                                dimensions.width
                              )}
                            >
                              {/* CTA Frame */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor:
                                      theme.colors['Akurie Dark Blue'],
                                    borderRadius: 12,
                                    flexDirection: 'row',
                                    paddingBottom: 12,
                                    paddingLeft: 18,
                                    paddingRight: 18,
                                    paddingTop: 12,
                                    zIndex: 10,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Label Frame */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      flexGrow: 1,
                                      flexShrink: 0,
                                      justifyContent: 'center',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Label */}
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      {
                                        color: theme.colors['Akurie green'],
                                        fontFamily: 'Inter_500Medium',
                                        fontSize: 15,
                                        lineHeight: 21,
                                        marginLeft: 42,
                                        textTransform: 'uppercase',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'Add Owner'}
                                  </Text>
                                </View>
                                {/* Icon Group Frame */}
                                <View>
                                  <Circle
                                    bgColor={theme.colors.studilyOpacity25}
                                    size={42}
                                  >
                                    {/* Icon Flex Frame */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          flexGrow: 0,
                                          flexShrink: 0,
                                          paddingBottom: 12,
                                          paddingLeft: 12,
                                          paddingRight: 12,
                                          paddingTop: 12,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Icon  */}
                                      <Icon
                                        color={theme.colors.studilyWhite}
                                        name={'AntDesign/arrowright'}
                                        size={18}
                                      />
                                    </View>
                                  </Circle>
                                </View>
                              </View>
                            </Touchable>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                )}
              </>
              {/* View 2 */}
              <>
                {!edit_owner ? null : (
                  <View>
                    <BusinessDataAkurieApi.FetchGetBusinessOwnerGET
                      owner_id={current_owner_id}
                    >
                      {({ loading, error, data, refetchGetBusinessOwner }) => {
                        const fetchData = data?.json;
                        if (loading) {
                          return <ActivityIndicator />;
                        }

                        if (
                          error ||
                          data?.status < 200 ||
                          data?.status >= 300
                        ) {
                          return <ActivityIndicator />;
                        }

                        return (
                          <>
                            {/* General Settings Frame */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor: 'rgba(0, 0, 0, 0)',
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors.studilyGray3,
                                  borderLeftWidth: 1,
                                  borderRadius: 6,
                                  borderRightWidth: 1,
                                  borderTopWidth: 1,
                                  marginBottom: 40,
                                  marginTop: 18,
                                  paddingBottom: 6,
                                  paddingLeft: 6,
                                  paddingRight: 6,
                                  paddingTop: 6,
                                },
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  { flexDirection: 'column' },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      flexDirection: 'row',
                                      height: 80,
                                      justifyContent: 'center',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <IconButton
                                    color={theme.colors['Akurie Primary']}
                                    icon={'AntDesign/closecircle'}
                                    onPress={() => {
                                      try {
                                        setEdit_owner(false);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    size={32}
                                    style={StyleSheet.applyWidth(
                                      { marginRight: 10 },
                                      dimensions.width
                                    )}
                                  />
                                  {/* Text 2 */}
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text'],
                                        {
                                          color:
                                            theme.colors['Akurie Dark Blue'],
                                          fontFamily: 'Inter_400Regular',
                                          fontSize: 18,
                                          textAlign: 'center',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {'Edit Owner'}
                                  </Text>
                                </View>

                                <Surface
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.SurfaceStyles(theme)[
                                        'Surface'
                                      ],
                                      {
                                        alignItems: 'center',
                                        backgroundColor:
                                          theme.colors['Akurie Dark Blue'],
                                        justifyContent: 'center',
                                        marginBottom: 20,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        padding: 8,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ],
                                          {
                                            color:
                                              theme.colors['Strong Inverse'],
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {fetchData?.First_Name}{' '}
                                      {fetchData?.Last_Name}
                                      {fetchData?.Entity_Owner_Name}
                                      {'\nOwnership Percentage: %'}
                                      {fetchData?.Ownership_Percentage}
                                      {'\n'}
                                      {fetchData?.City}
                                      {','}
                                      {fetchData?.State}
                                    </Text>
                                  </View>
                                </Surface>
                                {/* Bottom option */}
                                <Surface
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderBottomWidth: 1,
                                      borderColor: theme.colors.divider,
                                      borderLeftWidth: 1,
                                      borderRadius: 10,
                                      borderRightWidth: 1,
                                      borderTopWidth: 1,
                                      justifyContent: 'center',
                                      marginLeft: 16,
                                      marginRight: 16,
                                      marginTop: 25,
                                      minHeight: 40,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Owner Type View */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        borderBottomWidth: 1,
                                        borderColor: theme.colors.divider,
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        minHeight: 80,
                                        paddingBottom: 12,
                                        paddingLeft: 16,
                                        paddingRight: 16,
                                        paddingTop: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Owner Type */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          marginBottom: 10,
                                          paddingBottom: 5,
                                          paddingTop: 5,
                                          width: '100%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Owner Type */}
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          {
                                            color: theme.colors['Medium'],
                                            fontFamily: 'System',
                                            fontWeight: '700',
                                            marginBottom: 8,
                                            marginLeft: 5,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {'Owner Type'}
                                      </Text>
                                      {/* 2Business Checkbox Row */}
                                      <CheckboxRow
                                        label={'Business / Other Entity'}
                                        onPress={new2BusinessCheckboxRowValue => {
                                          try {
                                            setBusEditCheck(
                                              new2BusinessCheckboxRowValue
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        status={BusEditCheck}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.CheckboxRowStyles(theme)[
                                            'Checkbox Row'
                                          ],
                                          dimensions.width
                                        )}
                                      />
                                      {/* 2Person Checkbox Row */}
                                      <CheckboxRow
                                        label={'Natural Person'}
                                        onPress={new2PersonCheckboxRowValue => {
                                          try {
                                            setPersEditCheck(
                                              new2PersonCheckboxRowValue
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        status={PersEditCheck}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.CheckboxRowStyles(theme)[
                                            'Checkbox Row'
                                          ],
                                          dimensions.width
                                        )}
                                      />
                                    </View>
                                  </View>
                                  {/* Entity Name View */}
                                  <>
                                    {!BusEditCheck ? null : (
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'center',
                                            borderBottomWidth: 1,
                                            borderColor: theme.colors.divider,
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            minHeight: 80,
                                            paddingBottom: 12,
                                            paddingLeft: 16,
                                            paddingRight: 16,
                                            paddingTop: 12,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Busines Name */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              paddingBottom: 5,
                                              paddingTop: 5,
                                              width: '100%',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Business Name */}
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              {
                                                color: theme.colors['Medium'],
                                                fontFamily: 'System',
                                                fontWeight: '700',
                                                marginBottom: 8,
                                                marginLeft: 5,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {'Entity Name'}
                                          </Text>
                                          {/* 2OBusiness Name Input */}
                                          <TextInput
                                            enablesReturnKeyAutomatically={
                                              false
                                            }
                                            onFocus={() => {
                                              try {
                                                setBusNameEdit(undefined);
                                              } catch (err) {
                                                console.error(err);
                                              }
                                            }}
                                            placeholder={'Enter a value...'}
                                            spellcheck={true}
                                            style={StyleSheet.applyWidth(
                                              {
                                                backgroundColor:
                                                  theme.colors['Background'],
                                                borderBottomWidth: 1,
                                                borderColor:
                                                  theme.colors[
                                                    'Custom #999999'
                                                  ],
                                                borderRadius: 0,
                                                height: 50,
                                                paddingBottom: 12,
                                                paddingLeft: 8,
                                                paddingRight: 8,
                                                paddingTop: 12,
                                              },
                                              dimensions.width
                                            )}
                                            textContentType={'name'}
                                            value={BusNameEdit}
                                          />
                                        </View>
                                      </View>
                                    )}
                                  </>
                                  {/* First Name View */}
                                  <>
                                    {!PersEditCheck ? null : (
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'center',
                                            borderBottomWidth: 1,
                                            borderColor: theme.colors.divider,
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            minHeight: 80,
                                            paddingBottom: 12,
                                            paddingLeft: 16,
                                            paddingRight: 16,
                                            paddingTop: 12,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* First Name View */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              marginBottom: 10,
                                              paddingBottom: 5,
                                              paddingTop: 5,
                                              width: '100%',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* First Name */}
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              {
                                                color: theme.colors['Medium'],
                                                fontFamily: 'System',
                                                fontWeight: '700',
                                                marginBottom: 8,
                                                marginLeft: 5,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {'First Name'}
                                          </Text>
                                          {/* 2OFirst Name Input */}
                                          <TextInput
                                            enablesReturnKeyAutomatically={
                                              false
                                            }
                                            onChangeText={new2OFirstNameInputValue => {
                                              try {
                                                setFNameEdit(
                                                  new2OFirstNameInputValue
                                                );
                                              } catch (err) {
                                                console.error(err);
                                              }
                                            }}
                                            placeholder={'Enter a value...'}
                                            spellcheck={true}
                                            style={StyleSheet.applyWidth(
                                              {
                                                backgroundColor:
                                                  theme.colors['Background'],
                                                borderBottomWidth: 1,
                                                borderColor:
                                                  theme.colors[
                                                    'Custom #999999'
                                                  ],
                                                borderRadius: 0,
                                                height: 50,
                                                paddingBottom: 12,
                                                paddingLeft: 8,
                                                paddingRight: 8,
                                                paddingTop: 12,
                                              },
                                              dimensions.width
                                            )}
                                            textContentType={'name'}
                                            value={FNameEdit}
                                          />
                                        </View>
                                      </View>
                                    )}
                                  </>
                                  {/* Last Name View */}
                                  <>
                                    {!PersEditCheck ? null : (
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'center',
                                            borderBottomWidth: 1,
                                            borderColor: theme.colors.divider,
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            minHeight: 80,
                                            paddingBottom: 12,
                                            paddingLeft: 16,
                                            paddingRight: 16,
                                            paddingTop: 12,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Last Name View */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              marginBottom: 10,
                                              paddingBottom: 5,
                                              paddingTop: 5,
                                              width: '100%',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Last Name */}
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              {
                                                color: theme.colors['Medium'],
                                                fontFamily: 'System',
                                                fontWeight: '700',
                                                marginBottom: 8,
                                                marginLeft: 5,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {'Last Name'}
                                          </Text>
                                          {/* 2OLast Name Input */}
                                          <TextInput
                                            enablesReturnKeyAutomatically={
                                              false
                                            }
                                            onChangeText={new2OLastNameInputValue => {
                                              try {
                                                setLNameEdit(
                                                  new2OLastNameInputValue
                                                );
                                              } catch (err) {
                                                console.error(err);
                                              }
                                            }}
                                            placeholder={'Enter a value...'}
                                            spellcheck={true}
                                            style={StyleSheet.applyWidth(
                                              {
                                                backgroundColor:
                                                  theme.colors['Background'],
                                                borderBottomWidth: 1,
                                                borderColor:
                                                  theme.colors[
                                                    'Custom #999999'
                                                  ],
                                                borderRadius: 0,
                                                height: 50,
                                                paddingBottom: 12,
                                                paddingLeft: 8,
                                                paddingRight: 8,
                                                paddingTop: 12,
                                              },
                                              dimensions.width
                                            )}
                                            textContentType={'name'}
                                            value={LNameEdit}
                                          />
                                        </View>
                                      </View>
                                    )}
                                  </>
                                  {/* Percentage View */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        borderBottomWidth: 1,
                                        borderColor: theme.colors.divider,
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        minHeight: 80,
                                        paddingBottom: 12,
                                        paddingLeft: 16,
                                        paddingRight: 16,
                                        paddingTop: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Percentage View */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          marginBottom: 10,
                                          paddingBottom: 5,
                                          paddingTop: 5,
                                          width: '100%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Percentage View */}
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          {
                                            color: theme.colors['Medium'],
                                            fontFamily: 'System',
                                            fontWeight: '700',
                                            marginBottom: 8,
                                            marginLeft: 5,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {'Ownership Percentage'}
                                      </Text>
                                      {/* 2OPercentage Input */}
                                      <TextInput
                                        enablesReturnKeyAutomatically={false}
                                        keyboardType={'numeric'}
                                        onChangeText={new2OPercentageInputValue => {
                                          try {
                                            setPercentEdit(
                                              new2OPercentageInputValue
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        placeholder={'Enter a value...'}
                                        spellcheck={true}
                                        style={StyleSheet.applyWidth(
                                          {
                                            backgroundColor:
                                              theme.colors['Background'],
                                            borderBottomWidth: 1,
                                            borderColor:
                                              theme.colors['Custom #999999'],
                                            borderRadius: 0,
                                            height: 50,
                                            paddingBottom: 12,
                                            paddingLeft: 8,
                                            paddingRight: 8,
                                            paddingTop: 12,
                                          },
                                          dimensions.width
                                        )}
                                        textContentType={'name'}
                                        value={PercentEdit}
                                      />
                                    </View>
                                  </View>
                                  {/* Address View */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        borderBottomWidth: 1,
                                        borderColor: theme.colors.divider,
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        minHeight: 80,
                                        paddingBottom: 12,
                                        paddingLeft: 16,
                                        paddingRight: 16,
                                        paddingTop: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Address View */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          marginBottom: 10,
                                          paddingBottom: 5,
                                          paddingTop: 5,
                                          width: '100%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Address  */}
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          {
                                            color: theme.colors['Medium'],
                                            fontFamily: 'System',
                                            fontWeight: '700',
                                            marginBottom: 8,
                                            marginLeft: 5,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {'Address'}
                                      </Text>
                                      {/* 2OAddress Input */}
                                      <TextInput
                                        enablesReturnKeyAutomatically={false}
                                        onChangeText={new2OAddressInputValue => {
                                          try {
                                            setAddr1Edit(
                                              new2OAddressInputValue
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        placeholder={'Enter a value...'}
                                        spellcheck={true}
                                        style={StyleSheet.applyWidth(
                                          {
                                            backgroundColor:
                                              theme.colors['Background'],
                                            borderBottomWidth: 1,
                                            borderColor:
                                              theme.colors['Custom #999999'],
                                            borderRadius: 0,
                                            height: 50,
                                            paddingBottom: 12,
                                            paddingLeft: 8,
                                            paddingRight: 8,
                                            paddingTop: 12,
                                          },
                                          dimensions.width
                                        )}
                                        textContentType={'name'}
                                        value={Addr1Edit}
                                      />
                                    </View>
                                  </View>
                                  {/* Address 2 View */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        borderBottomWidth: 1,
                                        borderColor: theme.colors.divider,
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        minHeight: 80,
                                        paddingBottom: 12,
                                        paddingLeft: 16,
                                        paddingRight: 16,
                                        paddingTop: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Address 2 View */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          marginBottom: 10,
                                          paddingBottom: 5,
                                          paddingTop: 5,
                                          width: '100%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Address 2 */}
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          {
                                            color: theme.colors['Medium'],
                                            fontFamily: 'System',
                                            fontWeight: '700',
                                            marginBottom: 8,
                                            marginLeft: 5,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {'Address 2'}
                                      </Text>
                                      {/* 2OAddress 2 Input */}
                                      <TextInput
                                        enablesReturnKeyAutomatically={false}
                                        onChangeText={new2OAddress2InputValue => {
                                          try {
                                            setAddr2Edit(
                                              new2OAddress2InputValue
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        placeholder={'Enter a value...'}
                                        spellcheck={true}
                                        style={StyleSheet.applyWidth(
                                          {
                                            backgroundColor:
                                              theme.colors['Background'],
                                            borderBottomWidth: 1,
                                            borderColor:
                                              theme.colors['Custom #999999'],
                                            borderRadius: 0,
                                            height: 50,
                                            paddingBottom: 12,
                                            paddingLeft: 8,
                                            paddingRight: 8,
                                            paddingTop: 12,
                                          },
                                          dimensions.width
                                        )}
                                        textContentType={'name'}
                                        value={Addr2Edit}
                                      />
                                    </View>
                                  </View>
                                  {/* City View */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        borderBottomWidth: 1,
                                        borderColor: theme.colors.divider,
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        minHeight: 80,
                                        paddingBottom: 12,
                                        paddingLeft: 16,
                                        paddingRight: 16,
                                        paddingTop: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* City View */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          marginBottom: 10,
                                          paddingBottom: 5,
                                          paddingTop: 5,
                                          width: '100%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* City */}
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          {
                                            color: theme.colors['Medium'],
                                            fontFamily: 'System',
                                            fontWeight: '700',
                                            marginBottom: 8,
                                            marginLeft: 5,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {'City'}
                                      </Text>
                                      {/* 2OCity Input */}
                                      <TextInput
                                        enablesReturnKeyAutomatically={false}
                                        onChangeText={new2OCityInputValue => {
                                          try {
                                            setCityEdit(new2OCityInputValue);
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        placeholder={'Enter a value...'}
                                        spellcheck={true}
                                        style={StyleSheet.applyWidth(
                                          {
                                            backgroundColor:
                                              theme.colors['Background'],
                                            borderBottomWidth: 1,
                                            borderColor:
                                              theme.colors['Custom #999999'],
                                            borderRadius: 0,
                                            height: 50,
                                            paddingBottom: 12,
                                            paddingLeft: 8,
                                            paddingRight: 8,
                                            paddingTop: 12,
                                          },
                                          dimensions.width
                                        )}
                                        textContentType={'name'}
                                        value={cityEdit}
                                      />
                                    </View>
                                  </View>
                                  {/* State View */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'stretch',
                                        borderBottomWidth: 1,
                                        borderColor: theme.colors.divider,
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        minHeight: 80,
                                        paddingBottom: 12,
                                        paddingLeft: 16,
                                        paddingRight: 16,
                                        paddingTop: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* statepicker */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.ViewStyles(theme)[
                                          'statepicker'
                                        ],
                                        dimensions.width
                                      )}
                                    >
                                      {/* State */}
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          {
                                            color: theme.colors['Medium'],
                                            fontFamily: 'Inter_600SemiBold',
                                            marginBottom: 8,
                                            marginLeft: 5,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {'State'}
                                      </Text>
                                      {/* State Picker */}
                                      <Picker
                                        iconColor={
                                          theme.colors['Akurie Primary']
                                        }
                                        iconSize={24}
                                        label={'Select an Option'}
                                        leftIconMode={'inset'}
                                        onValueChange={newStatePickerValue => {
                                          try {
                                            setStateEdit(newStatePickerValue);
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        options={
                                          Constants['aku_supported_states_vari']
                                        }
                                        placeholder={'Select an option'}
                                        placeholderTextColor={
                                          theme.colors['Custom #666666']
                                        }
                                        rightIconName={'AntDesign/downcircle'}
                                        style={StyleSheet.applyWidth(
                                          {
                                            backgroundColor:
                                              theme.colors['Background'],
                                            color:
                                              theme.colors['Custom #101010'],
                                            paddingRight: 15,
                                            width: '100%',
                                          },
                                          dimensions.width
                                        )}
                                        type={'solid'}
                                        value={stateEdit}
                                      />
                                    </View>
                                  </View>
                                  {/* Zip Code View */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        borderBottomWidth: 1,
                                        borderColor: theme.colors.divider,
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        minHeight: 80,
                                        paddingBottom: 12,
                                        paddingLeft: 16,
                                        paddingRight: 16,
                                        paddingTop: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Zip Code View */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          marginBottom: 10,
                                          paddingBottom: 5,
                                          paddingTop: 5,
                                          width: '100%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Zip Code */}
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          {
                                            color: theme.colors['Medium'],
                                            fontFamily: 'System',
                                            fontWeight: '700',
                                            marginBottom: 8,
                                            marginLeft: 5,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {'Zip Code'}
                                      </Text>
                                      {/* O2Zip Code Input */}
                                      <TextInput
                                        enablesReturnKeyAutomatically={false}
                                        keyboardType={'numeric'}
                                        onChangeText={newO2ZipCodeInputValue => {
                                          try {
                                            setZipedit(newO2ZipCodeInputValue);
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        placeholder={'Enter a value...'}
                                        spellcheck={true}
                                        style={StyleSheet.applyWidth(
                                          {
                                            backgroundColor:
                                              theme.colors['Background'],
                                            borderBottomWidth: 1,
                                            borderColor:
                                              theme.colors['Custom #999999'],
                                            borderRadius: 0,
                                            height: 50,
                                            paddingBottom: 12,
                                            paddingLeft: 8,
                                            paddingRight: 8,
                                            paddingTop: 12,
                                          },
                                          dimensions.width
                                        )}
                                        textContentType={'name'}
                                        value={Zipedit}
                                      />
                                    </View>
                                  </View>
                                </Surface>
                              </View>

                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    paddingBottom: 15,
                                    paddingLeft: 15,
                                    paddingRight: 15,
                                    paddingTop: 8,
                                  },
                                  dimensions.width
                                )}
                              >
                                <View>
                                  {/* Form */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        opacity: 0.8,
                                        paddingLeft: 20,
                                        paddingRight: 20,
                                      },
                                      dimensions.width
                                    )}
                                  />
                                </View>

                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      marginBottom: 30,
                                      paddingBottom: 40,
                                      paddingLeft: 40,
                                      paddingRight: 40,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Button */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { borderRadius: 0 },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Touchable Frame */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { zIndex: 10 },
                                        dimensions.width
                                      )}
                                    >
                                      <Touchable
                                        onPress={() => {
                                          const handler = async () => {
                                            try {
                                              (
                                                await businessDataAkurieEditOwnerPUT.mutateAsync(
                                                  {
                                                    Address: Addr1Edit,
                                                    Address_2: Addr2Edit,
                                                    Business__Other_Entity:
                                                      BusEditCheck,
                                                    City: cityEdit,
                                                    Entity_Owner_Name:
                                                      BusNameEdit,
                                                    First_Name: FNameEdit,
                                                    Last_Name: LNameEdit,
                                                    Natural_Person:
                                                      PersEditCheck,
                                                    Ownership_Percentage:
                                                      PercentEdit,
                                                    State: stateEdit,
                                                    owner_id: fetchData?.id,
                                                    zip_code: Zipedit,
                                                  }
                                                )
                                              )?.json;
                                              setEdit_owner(false);
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          };
                                          handler();
                                        }}
                                        style={StyleSheet.applyWidth(
                                          { borderRadius: 0 },
                                          dimensions.width
                                        )}
                                      >
                                        {/* CTA Frame */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              backgroundColor:
                                                theme.colors[
                                                  'Akurie Dark Blue'
                                                ],
                                              borderRadius: 12,
                                              flexDirection: 'row',
                                              paddingBottom: 12,
                                              paddingLeft: 18,
                                              paddingRight: 18,
                                              paddingTop: 12,
                                              zIndex: 10,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Label Frame */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                flexGrow: 1,
                                                flexShrink: 0,
                                                justifyContent: 'center',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Label */}
                                            <Text
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color:
                                                    theme.colors[
                                                      'Akurie green'
                                                    ],
                                                  fontFamily: 'Inter_500Medium',
                                                  fontSize: 15,
                                                  lineHeight: 21,
                                                  marginLeft: 42,
                                                  textTransform: 'uppercase',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {'Update Info'}
                                            </Text>
                                          </View>
                                          {/* Icon Group Frame */}
                                          <View>
                                            <Circle
                                              bgColor={
                                                theme.colors.studilyOpacity25
                                              }
                                              size={42}
                                            >
                                              {/* Icon Flex Frame */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    flexGrow: 0,
                                                    flexShrink: 0,
                                                    paddingBottom: 12,
                                                    paddingLeft: 12,
                                                    paddingRight: 12,
                                                    paddingTop: 12,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Icon  */}
                                                <Icon
                                                  color={
                                                    theme.colors.studilyWhite
                                                  }
                                                  name={'AntDesign/arrowright'}
                                                  size={18}
                                                />
                                              </View>
                                            </Circle>
                                          </View>
                                        </View>
                                      </Touchable>
                                    </View>
                                  </View>
                                </View>
                              </View>
                            </View>
                          </>
                        );
                      }}
                    </BusinessDataAkurieApi.FetchGetBusinessOwnerGET>
                  </View>
                )}
              </>
            </KeyboardAwareScrollView>
          )}
        </>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(OwnershipInformationScreen);
