import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import encodeQueryParam from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const checkNeededFilingInfoGET = (
  Constants,
  { bus_id },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:NZhZrRrh/filings/infocheck/${
      typeof bus_id === 'string' ? bus_id : JSON.stringify(bus_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useCheckNeededFilingInfoGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Business_Filing', args],
    () => checkNeededFilingInfoGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Business_Filings']),
    }
  );
};

export const FetchCheckNeededFilingInfoGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  bus_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useCheckNeededFilingInfoGET(
    { bus_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchCheckNeededFilingInfo: refetch,
  });
};

export const createBusinessFilingsPOST = (
  Constants,
  { bus_id },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:NZhZrRrh/filings/create/initial/${
      typeof bus_id === 'string' ? bus_id : JSON.stringify(bus_id ?? '')
    }`,
    {
      body: JSON.stringify({ key: 'value' }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useCreateBusinessFilingsPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      createBusinessFilingsPOST(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Business_Filing', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Business_Filing');
        queryClient.invalidateQueries('Business_Filings');
      },
    }
  );
};

export const FetchCreateBusinessFilingsPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  bus_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateBusinessFilingsPOST(
    { bus_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchCreateBusinessFilings: refetch,
  });
};

export const deleteFilingsAfterUpdateDELETE = (
  Constants,
  { business_id },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:NZhZrRrh/business_filings/delete/refresh/${
      typeof business_id === 'string'
        ? business_id
        : JSON.stringify(business_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  ).then(res => handleResponse(res, handlers));

export const useDeleteFilingsAfterUpdateDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteFilingsAfterUpdateDELETE(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Business_Filing', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Business_Filing');
        queryClient.invalidateQueries('Business_Filings');
      },
    }
  );
};

export const deleteAFilingDELETE = (Constants, { filing_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:NZhZrRrh/filings/delete/${
      typeof filing_id === 'string'
        ? filing_id
        : JSON.stringify(filing_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  ).then(res => handleResponse(res, handlers));

export const useDeleteAFilingDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteAFilingDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Business_Filing', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Business_Filing');
        queryClient.invalidateQueries('Business_Filings');
      },
    }
  );
};

export const getFiledFilingsGET = (Constants, { bus_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:NZhZrRrh/filings/get/filed/${
      typeof bus_id === 'string' ? bus_id : JSON.stringify(bus_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetFiledFilingsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Business_Filings', args],
    () => getFiledFilingsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetFiledFilingsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  bus_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetFiledFilingsGET(
    { bus_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetFiledFilings: refetch });
};

export const getFilings$NumbersGET = (Constants, { bus_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:NZhZrRrh/filings/get/${
      typeof bus_id === 'string' ? bus_id : JSON.stringify(bus_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetFilings$NumbersGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Business_Filings', args],
    () => getFilings$NumbersGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetFilings$NumbersGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  bus_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetFilings$NumbersGET(
    { bus_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetFilings$Numbers: refetch });
};

export const getOneFilingGET = (Constants, { filing_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:NZhZrRrh/filings/get/one/${
      typeof filing_id === 'string'
        ? filing_id
        : JSON.stringify(filing_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetOneFilingGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Business_Filing', args],
    () => getOneFilingGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Business_Filings']),
    }
  );
};

export const FetchGetOneFilingGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  filing_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetOneFilingGET(
    { filing_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetOneFiling: refetch });
};

export const undoFilingPATCH = (Constants, { filings_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:NZhZrRrh/filings/update/unfile/${
      typeof filings_id === 'string'
        ? filings_id
        : JSON.stringify(filings_id ?? '')
    }`,
    {
      body: JSON.stringify({ key: 'value' }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
    }
  ).then(res => handleResponse(res, handlers));

export const useUndoFilingPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => undoFilingPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Business_Filing', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Business_Filing');
        queryClient.invalidateQueries('Business_Filings');
      },
    }
  );
};

export const updateFilingCompletedPATCH = (
  Constants,
  { filings_id },
  handlers = {}
) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:NZhZrRrh/filings/update/filed`, {
    body: JSON.stringify({ filings_id: filings_id }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }).then(res => handleResponse(res, handlers));

export const useUpdateFilingCompletedPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      updateFilingCompletedPATCH(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Business_Filing', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Business_Filing');
        queryClient.invalidateQueries('Business_Filings');
      },
    }
  );
};
