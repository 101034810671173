import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { CircularProgress, IconButton, withTheme } from '@draftbit/ui';
import { BlurView } from 'expo-blur';
import { Text, View } from 'react-native';

const LoadingViewBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  return (
    <BlurView
      intensity={80}
      style={StyleSheet.applyWidth(
        StyleSheet.compose(GlobalStyles.BlurViewStyles(theme)['Blur View'], {
          alignItems: 'center',
          height: '100%',
          justifyContent: 'center',
          position: 'absolute',
          width: '100%',
          zIndex: 10,
        }),
        dimensions.width
      )}
      tint={'dark'}
    >
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            backgroundColor: theme.colors['Akurie Dark Blue'],
            borderRadius: 19,
            bottom: 50,
            height: [
              { minWidth: Breakpoints.Mobile, value: '40%' },
              { minWidth: Breakpoints.Tablet, value: '60%' },
              { minWidth: Breakpoints.Laptop, value: '70%' },
            ],
            justifyContent: 'center',
            opacity: 1,
            width: [
              { minWidth: Breakpoints.Tablet, value: '60%' },
              { minWidth: Breakpoints.Laptop, value: '50%' },
              { minWidth: Breakpoints.Desktop, value: '40%' },
              { minWidth: Breakpoints.Mobile, value: '70%' },
            ],
          },
          dimensions.width
        )}
      >
        <IconButton
          color={theme.colors['Akurie Primary']}
          icon={'Feather/refresh-ccw'}
          onPress={() => {
            try {
              setGlobalVariableValue({
                key: 'ak_isLoading',
                value: false,
              });
            } catch (err) {
              console.error(err);
            }
          }}
          size={32}
          style={StyleSheet.applyWidth(
            { position: 'absolute', right: 10, top: 10 },
            dimensions.width
          )}
        />
        <CircularProgress
          animationDuration={7000}
          color={theme.colors['Akurie green']}
          indeterminate={true}
          isAnimated={true}
          lineCap={'round'}
          showTrack={true}
          startPosition={'bottom'}
          style={StyleSheet.applyWidth(
            { bottom: -20, height: '70%', width: '70%' },
            dimensions.width
          )}
          thickness={15}
          trackColor={theme.colors.divider}
          trackLineCap={'round'}
        />
        <Text
          accessible={true}
          allowFontScaling={true}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
              color: theme.colors['Strong Inverse'],
              fontFamily: 'Inter_600SemiBold',
              fontSize: 20,
              marginTop: 10,
            }),
            dimensions.width
          )}
        >
          {'Loading Your Business'}
        </Text>
      </View>
    </BlurView>
  );
};

export default withTheme(LoadingViewBlock);
