import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as Logs$SupportAkurieApi from '../apis/Logs$SupportAkurieApi.js';
import MobileHeaderBlock from '../components/MobileHeaderBlock';
import WebHeader2Block from '../components/WebHeader2Block';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Divider,
  Icon,
  Link,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';
import { Image, ImageBackground, Text, View } from 'react-native';

const PurchaseCompleteScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [Manage_bus_view, setManage_bus_view] = React.useState(false);
  const [accessLock_Docs_Filing, setAccessLock_Docs_Filing] =
    React.useState(false);
  const [accessLock_Plans_Goals, setAccessLock_Plans_Goals] =
    React.useState(false);
  const [accessLock_Web_Services, setAccessLock_Web_Services] =
    React.useState(false);
  const [autoFilingButton, setAutoFilingButton] = React.useState(false);
  const [changebus_view, setChangebus_view] = React.useState(false);
  const [financials_available, setFinancials_available] = React.useState('');
  const [goal_view, setGoal_view] = React.useState(false);
  const [loading_goals, setLoading_goals] = React.useState(false);
  const [selectProfile2Value, setSelectProfile2Value] = React.useState('');
  const [selectProfile2Value2, setSelectProfile2Value2] = React.useState('');
  const [topMenu_view, setTopMenu_view] = React.useState(false);
  const [user_bus, setUser_bus] = React.useState('');
  const [user_first_name, setUser_first_name] = React.useState('');
  const [user_last_name, setUser_last_name] = React.useState('');
  const [userbusinessPro_Picker, setUserbusinessPro_Picker] =
    React.useState('');
  const [usersBusinesses, setUsersBusinesses] = React.useState([]);
  const logs$SupportAkurieBasicActivityPOST =
    Logs$SupportAkurieApi.useBasicActivityPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      console.log('Screen ON_SCREEN_FOCUS Start');
      let error = null;
      try {
        if (!isFocused) {
          return;
        }
        console.log('Start ON_SCREEN_FOCUS:0 FETCH_REQUEST');
        (
          await logs$SupportAkurieBasicActivityPOST.mutateAsync({
            buttonName: 'Payment Success',
            session_id: Constants['Session_ID'],
          })
        )?.json;
        console.log('Complete ON_SCREEN_FOCUS:0 FETCH_REQUEST');
        console.log('Start ON_SCREEN_FOCUS:1 WEBBROWSER_OPENBROWSERASYNC');
        await WebBrowser.openBrowserAsync(
          'https://buy.stripe.com/4gw14S9Gk4Ci2Qg001'
        );
        console.log('Complete ON_SCREEN_FOCUS:1 WEBBROWSER_OPENBROWSERASYNC');
        console.log('Start ON_SCREEN_FOCUS:2 LINKING_OPENURL');
        /* hidden 'Open App Link' action */ console.log(
          'Complete ON_SCREEN_FOCUS:2 LINKING_OPENURL'
        );
      } catch (err) {
        console.error(err);
        error = err.message ?? err;
      }
      console.log(
        'Screen ON_SCREEN_FOCUS Complete',
        error ? { error } : 'no error'
      );
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          alignSelf: 'stretch',
          backgroundColor: theme.colors['Akurie Dark Blue'],
        },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'cover'}
        source={Images.FirefoxPHKyvBthWe}
        style={StyleSheet.applyWidth(
          { backgroundColor: theme.colors['Background'], height: '100%' },
          dimensions.width
        )}
      >
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <MobileHeaderBlock />
          )}
        </>
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: { minWidth: Breakpoints.Laptop, value: 'stretch' },
              flexDirection: { minWidth: Breakpoints.Laptop, value: 'column' },
              height: [
                { minWidth: Breakpoints.Mobile, value: '100%' },
                { minWidth: Breakpoints.Laptop, value: dimensions.height },
              ],
              marginBottom: { minWidth: Breakpoints.Tablet, value: 100 },
              width: [
                { minWidth: Breakpoints.Laptop, value: '100%' },
                { minWidth: Breakpoints.Desktop, value: '100%' },
              ],
            },
            dimensions.width
          )}
        >
          {/* View 2 */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                height: '80%',
                justifyContent: 'center',
                padding: 25,
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: theme.colors['Akurie Dark Blue'],
                  borderRadius: 120,
                  height: 120,
                  justifyContent: 'center',
                  width: 120,
                },
                dimensions.width
              )}
            >
              <Icon
                color={theme.colors['Akurie green']}
                name={'AntDesign/checkcircle'}
                size={80}
              />
            </View>

            <Text
              accessible={true}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  fontFamily: 'Inter_400Regular',
                  fontSize: 32,
                  marginBottom: 10,
                  marginTop: 10,
                }),
                dimensions.width
              )}
            >
              {'Setup Complete'}
            </Text>
            <Divider
              color={theme.colors['Akurie Primary']}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.DividerStyles(theme)['Divider'],
                  { height: 2, marginBottom: 20, marginTop: 10, width: '75%' }
                ),
                dimensions.width
              )}
            />
            {/* View 2 */}
            <View>
              {/* Text 2 */}
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    fontFamily: 'Inter_700Bold',
                    fontSize: 14,
                    marginBottom: 15,
                    marginTop: 40,
                    paddingLeft: 30,
                    paddingRight: 30,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {'For the best experience, please \ndownload the Akurie app.'}
              </Text>

              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: 'row',
                    justifyContent: 'center',
                    marginBottom: 25,
                    marginTop: 10,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    const handler = async () => {
                      try {
                        Linking.openURL(
                          'https://apps.apple.com/us/app/akurie/id6449500157'
                        );
                        (
                          await logs$SupportAkurieBasicActivityPOST.mutateAsync(
                            { buttonName: 'Open Apple Store Link' }
                          )
                        )?.json;
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { padding: 5 },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'cover'}
                      source={Images.Apple}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          { height: 44.5, width: 150 }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                </Touchable>
                {/* Touchable 2 */}
                <Touchable
                  onPress={() => {
                    const handler = async () => {
                      try {
                        (
                          await logs$SupportAkurieBasicActivityPOST.mutateAsync(
                            { buttonName: 'Open Google Play Link ' }
                          )
                        )?.json;
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { padding: 5 },
                      dimensions.width
                    )}
                  >
                    {/* Image 2 */}
                    <Image
                      resizeMode={'cover'}
                      source={Images.Google}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          { height: 44.5, opacity: 0.3, width: 150 }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                </Touchable>
              </View>
              <Link
                accessible={true}
                onPress={() => {
                  try {
                    /* hidden 'Navigate' action */
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'], {
                    marginTop: 25,
                    textAlign: 'center',
                    textDecorationLine: 'underline',
                  }),
                  dimensions.width
                )}
                title={"No Thanks, I'll Stay On Web."}
              />
            </View>
          </View>
        </View>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(PurchaseCompleteScreen);
