import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AuthAkurieApi from '../apis/AuthAkurieApi.js';
import * as BusinessDataAkurieApi from '../apis/BusinessDataAkurieApi.js';
import * as Filings$DocsApi from '../apis/Filings$DocsApi.js';
import * as Logs$SupportAkurieApi from '../apis/Logs$SupportAkurieApi.js';
import * as PlaidAuthAkurieApi from '../apis/PlaidAuthAkurieApi.js';
import DesktopHeader13024SignedInBlock from '../components/DesktopHeader13024SignedInBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Circle,
  CircularProgress,
  Divider,
  Icon,
  IconButton,
  Link,
  NumberInput,
  Picker,
  ScreenContainer,
  Surface,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StatusBar,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const UserDashboardScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [Manage_bus_view, setManage_bus_view] = React.useState(false);
  const [accessLock_Docs_Filing, setAccessLock_Docs_Filing] =
    React.useState(false);
  const [accessLock_Plans_Goals, setAccessLock_Plans_Goals] =
    React.useState(false);
  const [accessLock_Web_Services, setAccessLock_Web_Services] =
    React.useState(false);
  const [autoFilingButton, setAutoFilingButton] = React.useState(false);
  const [callAssistant, setCallAssistant] = React.useState(false);
  const [changebus_view, setChangebus_view] = React.useState(false);
  const [financials_available, setFinancials_available] = React.useState('');
  const [getStartedView, setGetStartedView] = React.useState(false);
  const [goal_view, setGoal_view] = React.useState(false);
  const [loading_goals, setLoading_goals] = React.useState(false);
  const [meetAssistant, setMeetAssistant] = React.useState(false);
  const [numberInputValue, setNumberInputValue] = React.useState('');
  const [selectProfile2Value, setSelectProfile2Value] = React.useState('');
  const [selectProfile2Value2, setSelectProfile2Value2] = React.useState('');
  const [topMenu_view, setTopMenu_view] = React.useState(false);
  const [tutorialCompleted, setTutorialCompleted] = React.useState(false);
  const [user_bus, setUser_bus] = React.useState('');
  const [user_first_name, setUser_first_name] = React.useState('');
  const [user_last_name, setUser_last_name] = React.useState('');
  const [userbusinessPro_Picker, setUserbusinessPro_Picker] =
    React.useState('');
  const [usersBusinesses, setUsersBusinesses] = React.useState([]);
  const logs$SupportAkurieBasicActivityPOST =
    Logs$SupportAkurieApi.useBasicActivityPOST();
  const authAkurieCompleteTutorialPUT = AuthAkurieApi.useCompleteTutorialPUT();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        /* hidden 'API Request' action */
        /* hidden 'Extract Key' action */
        /* hidden 'Navigate' action */
        /* hidden 'Conditional Stop' action */
        setGlobalVariableValue({
          key: 'subActive',
          value: true,
        });
        const user_id = (() => {
          if (Constants['ak_userID'] === '') {
            return setGlobalVariableValue({
              key: 'ak_userID',
              value: props.route?.params?.userId ?? '',
            });
          }
        })();
        if (Constants['ak_businessID'] === '') {
          setGlobalVariableValue({
            key: 'ak_businessID',
            value: props.route?.params?.business_id ?? '',
          });
        }
        /* hidden 'API Request' action */
        /* hidden 'Set Variable' action */
        const userResponse = (
          await AuthAkurieApi.getSpecificUserGET(Constants, {
            user_id: Constants['ak_userID'],
          })
        )?.json;
        const emailResult = userResponse?.email;
        /* hidden 'Extract Key' action */
        /* hidden 'Navigate' action */
        const subResult = userResponse?.Subscription_Type;
        setGlobalVariableValue({
          key: 'ak_subscriptionType',
          value: subResult,
        });
        const businessResponse = (
          await BusinessDataAkurieApi.getBusinessGET(Constants, {
            business_id: Constants['ak_businessID'],
          })
        )?.json;
        /* hidden 'API Request' action */
        /* hidden 'Set Variable' action */
        const businessName = businessResponse?.user_business_name;
        setGlobalVariableValue({
          key: 'ak_businessName',
          value: businessName,
        });
        const businessStatus = businessResponse?.Business_Status;
        setGlobalVariableValue({
          key: 'ak_businessStatus',
          value: businessStatus,
        });
        const stateFormed = businessResponse?.business_state;
        setGlobalVariableValue({
          key: 'ak_businessState',
          value: stateFormed,
        });
        const entityResult = businessResponse?.entity_type;
        setGlobalVariableValue({
          key: 'ak_entityType',
          value: entityResult,
        });
        const checkResult = (
          await Filings$DocsApi.checkNeededFilingInfoGET(Constants, {
            bus_id: Constants['ak_businessID'],
          })
        )?.json;
        const contactInfo = checkResult?.Business_Data_Contact_Info_1;
        const ownerInfo = checkResult?.Business_Ownership_1;
        const registeredagentInfo = checkResult?.Business_Registered_Agent_1;
        setGlobalVariableValue({
          key: 'contactInfo',
          value: contactInfo,
        });
        setGlobalVariableValue({
          key: 'ownershipInfo',
          value: ownerInfo,
        });
        setGlobalVariableValue({
          key: 'registeredagentInfo',
          value: registeredagentInfo,
        });
        setTutorialCompleted(userResponse?.Tutorial_Completed);
        setGlobalVariableValue({
          key: 'ak_isLoading',
          value: false,
        });
        StatusBar.setBarStyle('light-content');
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          alignSelf: 'stretch',
          backgroundColor: theme.colors['Akurie Dark Blue'],
        },
        dimensions.width
      )}
    >
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <DesktopHeader13024SignedInBlock />
        )}
      </>
      <ImageBackground
        resizeMode={'cover'}
        source={Images.FirefoxPHKyvBthWe}
        style={StyleSheet.applyWidth(
          { backgroundColor: theme.colors['Background'], height: '100%' },
          dimensions.width
        )}
      >
        {/* Top Menu */}
        <>
          {!topMenu_view ? null : (
            <BlurView
              intensity={80}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.BlurViewStyles(theme)['Blur View'],
                  {
                    alignItems: 'center',
                    bottom: { minWidth: Breakpoints.Tablet, value: 60 },
                    height: [
                      { minWidth: Breakpoints.Mobile, value: '100%' },
                      { minWidth: Breakpoints.Tablet, value: '95%' },
                    ],
                    justifyContent: 'flex-start',
                    marginTop: 100,
                    opacity: 1,
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                    zIndex: 8,
                  }
                ),
                dimensions.width
              )}
              tint={'dark'}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: theme.colors['Akurie Dark Blue'],
                    borderBottomWidth: 0.1,
                    borderColor: theme.colors['Akurie green'],
                    borderTopLeftRadius: 12,
                    borderTopWidth: 0.1,
                    height: '100%',
                    justifyContent: 'flex-start',
                    opacity: 1,
                    paddingLeft: 10,
                    position: 'absolute',
                    right: 0,
                    top: { minWidth: Breakpoints.Tablet, value: 130 },
                    width: '90%',
                  },
                  dimensions.width
                )}
              >
                {/* Profile Header */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderColor: theme.colors['Akurie green'],
                      justifyContent: 'center',
                      marginBottom: 10,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* Fetch 3  */}
                  <AuthAkurieApi.FetchGetSimpleUserDataGET
                    id={Constants['ak_userID']}
                  >
                    {({ loading, error, data, refetchGetSimpleUserData }) => {
                      const fetch3Data = data?.json;
                      if (loading) {
                        return <ActivityIndicator />;
                      }

                      if (error || data?.status < 200 || data?.status >= 300) {
                        return <ActivityIndicator />;
                      }

                      return (
                        <>
                          <Text
                            accessible={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  color: theme.colors['Strong Inverse'],
                                  fontFamily: 'Inter_700Bold',
                                  fontSize: 21,
                                  marginTop: 20,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {fetch3Data?.First_name} {fetch3Data?.Last_name}
                          </Text>

                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'row',
                                marginBottom: 20,
                                marginTop: 3,
                                paddingLeft: 10,
                                paddingRight: 10,
                              },
                              dimensions.width
                            )}
                          >
                            <Icon
                              color={theme.colors['Akurie Primary']}
                              name={'Foundation/torso-business'}
                              size={15}
                            />
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Strong Inverse'],
                                    fontFamily: 'Inter_600SemiBold',
                                    fontSize: 16,
                                    paddingLeft: 8,
                                    paddingRight: 5,
                                    textAlign: 'center',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {fetch3Data?.title_or_position}
                              {' - '}
                              {Constants['ak_businessName']}
                            </Text>
                          </View>
                          {/* Text 3 */}
                          <Text
                            accessible={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  color: theme.colors['Akurie green'],
                                  fontFamily: 'Inter_600SemiBold',
                                  fontSize: 16,
                                  paddingBottom: 8,
                                  paddingTop: 2,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Current Plan: '}
                            {fetch3Data?.Subscription_Type}
                          </Text>
                          {/* Text 2 */}
                          <Text
                            accessible={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  color: theme.colors['Divider'],
                                  fontFamily: 'Inter_300Light',
                                  paddingBottom: 8,
                                  paddingTop: 2,
                                  textAlign: 'center',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'UUID: '}
                            {fetch3Data?.user_id_code}
                          </Text>
                        </>
                      );
                    }}
                  </AuthAkurieApi.FetchGetSimpleUserDataGET>
                </View>

                <ImageBackground
                  resizeMode={'cover'}
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.BigScreen,
                        value: 'stretch',
                      },
                      backgroundColor: theme.colors['Surface'],
                      borderColor: theme.colors['Akurie green'],
                      borderTopLeftRadius: 12,
                      borderTopWidth: 2,
                      height: '98%',
                      paddingLeft: 5,
                      paddingRight: 5,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  <>
                    {dimensions.width >= Breakpoints.Laptop ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { marginTop: 20, width: '100%' },
                          dimensions.width
                        )}
                      ></View>
                    )}
                  </>
                  {/* View 2 */}
                  <>
                    {changebus_view ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            marginTop: {
                              minWidth: Breakpoints.BigScreen,
                              value: 40,
                            },
                            paddingLeft: [
                              { minWidth: Breakpoints.Desktop, value: '15%' },
                              { minWidth: Breakpoints.Laptop, value: '10%' },
                              { minWidth: Breakpoints.BigScreen, value: '20%' },
                            ],
                            paddingRight: [
                              { minWidth: Breakpoints.Desktop, value: '15%' },
                              { minWidth: Breakpoints.Laptop, value: '10%' },
                              { minWidth: Breakpoints.BigScreen, value: '20%' },
                            ],
                          },
                          dimensions.width
                        )}
                      >
                        {/* Top options 2 */}
                        <Surface
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: theme.colors['Akurie Dark Blue'],
                              borderBottomWidth: 1,
                              borderColor: theme.colors['Divider'],
                              borderLeftWidth: 1,
                              borderRadius: 10,
                              borderRightWidth: 1,
                              borderTopWidth: 1,
                              justifyContent: 'center',
                              marginLeft: 5,
                              marginRight: 5,
                              marginTop: 10,
                              minHeight: 40,
                            },
                            dimensions.width
                          )}
                        >
                          <Touchable
                            onPress={() => {
                              try {
                                navigation.navigate(
                                  'PaywallProCurrentMonth3Screen'
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors['Divider'],
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  paddingBottom: 12,
                                  paddingLeft: 16,
                                  paddingRight: 16,
                                  paddingTop: 12,
                                },
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  },
                                  dimensions.width
                                )}
                              >
                                <Icon
                                  color={theme.colors['Akurie green']}
                                  name={'MaterialCommunityIcons/account-box'}
                                  size={24}
                                />
                                <Text
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors['Akurie green'],
                                      fontFamily: 'Inter_500Medium',
                                      fontSize: 15,
                                      paddingLeft: 16,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Manage Current Subscription'}
                                </Text>
                              </View>
                              <Icon
                                color={theme.colors['Akurie green']}
                                name={'Entypo/chevron-right'}
                                size={24}
                              />
                            </View>
                          </Touchable>
                        </Surface>
                        {/* Top options */}
                        <Surface
                          style={StyleSheet.applyWidth(
                            {
                              borderBottomWidth: 1,
                              borderColor: theme.colors['Divider'],
                              borderLeftWidth: 1,
                              borderRadius: 10,
                              borderRightWidth: 1,
                              borderTopWidth: 1,
                              justifyContent: 'center',
                              marginLeft: 5,
                              marginRight: 5,
                              marginTop: 16,
                              minHeight: 40,
                            },
                            dimensions.width
                          )}
                        >
                          <Touchable
                            onPress={() => {
                              try {
                                navigation.navigate(
                                  'PersonalProfileInfoScreen'
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors['Divider'],
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  paddingBottom: 12,
                                  paddingLeft: 16,
                                  paddingRight: 16,
                                  paddingTop: 12,
                                },
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  },
                                  dimensions.width
                                )}
                              >
                                <Icon
                                  color={theme.colors['Akurie Dark Blue']}
                                  name={'FontAwesome/user-circle'}
                                  size={24}
                                />
                                <Text
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors.medium,
                                      fontFamily: 'Inter_500Medium',
                                      fontSize: 15,
                                      paddingLeft: 16,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Update Personal Information'}
                                </Text>
                              </View>
                              <Icon
                                color={theme.colors['Akurie Dark Blue']}
                                name={'Entypo/chevron-right'}
                                size={24}
                              />
                            </View>
                          </Touchable>
                        </Surface>
                        {/* Top options */}
                        <Surface
                          style={StyleSheet.applyWidth(
                            {
                              borderBottomWidth: 1,
                              borderColor: theme.colors['Divider'],
                              borderLeftWidth: 1,
                              borderRadius: 10,
                              borderRightWidth: 1,
                              borderTopWidth: 1,
                              justifyContent: 'center',
                              marginLeft: 5,
                              marginRight: 5,
                              marginTop: 10,
                              minHeight: 40,
                            },
                            dimensions.width
                          )}
                        >
                          <Touchable
                            onPress={() => {
                              try {
                                navigation.navigate(
                                  'UpdateBusinessInformationScreen'
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors['Divider'],
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  paddingBottom: 12,
                                  paddingLeft: 16,
                                  paddingRight: 16,
                                  paddingTop: 12,
                                },
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  },
                                  dimensions.width
                                )}
                              >
                                <Icon
                                  color={theme.colors['Akurie Dark Blue']}
                                  name={'Ionicons/business'}
                                  size={24}
                                />
                                <Text
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors.medium,
                                      fontFamily: 'Inter_500Medium',
                                      fontSize: 15,
                                      paddingLeft: 16,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Update Business Information'}
                                </Text>
                              </View>
                              <Icon
                                color={theme.colors['Akurie Dark Blue']}
                                name={'Entypo/chevron-right'}
                                size={24}
                              />
                            </View>
                          </Touchable>
                        </Surface>
                        {/* Bottom option */}
                        <Surface
                          style={StyleSheet.applyWidth(
                            {
                              borderBottomWidth: 1,
                              borderColor: theme.colors.divider,
                              borderLeftWidth: 1,
                              borderRadius: 10,
                              borderRightWidth: 1,
                              borderTopWidth: 1,
                              justifyContent: 'center',
                              marginLeft: 5,
                              marginRight: 5,
                              marginTop: 25,
                              minHeight: 40,
                            },
                            dimensions.width
                          )}
                        >
                          <Touchable
                            onPress={() => {
                              try {
                                navigation.navigate('HelpSupportScreen');
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors.divider,
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  paddingBottom: 12,
                                  paddingLeft: 16,
                                  paddingRight: 16,
                                  paddingTop: 12,
                                },
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  },
                                  dimensions.width
                                )}
                              >
                                <Icon
                                  color={theme.colors['Akurie Dark Blue']}
                                  name={'Feather/help-circle'}
                                  size={24}
                                />
                                <Text
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors.medium,
                                      fontFamily: 'Inter_500Medium',
                                      fontSize: 15,
                                      paddingLeft: 16,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {' Help & Support'}
                                </Text>
                              </View>
                              <Icon
                                color={theme.colors['Akurie Dark Blue']}
                                name={'Entypo/chevron-right'}
                                size={24}
                              />
                            </View>
                          </Touchable>

                          <Touchable
                            onPress={() => {
                              try {
                                navigation.navigate('SecurityScreen');
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors.divider,
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  paddingBottom: 12,
                                  paddingLeft: 16,
                                  paddingRight: 16,
                                  paddingTop: 12,
                                },
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  },
                                  dimensions.width
                                )}
                              >
                                <Icon
                                  color={theme.colors['Akurie Dark Blue']}
                                  name={'Entypo/key'}
                                  size={24}
                                />
                                <Text
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors.medium,
                                      fontFamily: 'Inter_500Medium',
                                      fontSize: 15,
                                      paddingLeft: 16,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Security'}
                                </Text>
                              </View>
                              <Icon
                                color={theme.colors['Akurie Dark Blue']}
                                name={'Entypo/chevron-right'}
                                size={24}
                              />
                            </View>
                          </Touchable>

                          <Touchable
                            onPress={() => {
                              try {
                                navigation.navigate('GeneralSettingsScreen');
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors.divider,
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  paddingBottom: 12,
                                  paddingLeft: 16,
                                  paddingRight: 16,
                                  paddingTop: 12,
                                },
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  },
                                  dimensions.width
                                )}
                              >
                                <Icon
                                  color={theme.colors['Akurie Dark Blue']}
                                  name={'Ionicons/ios-settings-sharp'}
                                  size={24}
                                />
                                <Text
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors.medium,
                                      fontFamily: 'Inter_500Medium',
                                      fontSize: 15,
                                      paddingLeft: 16,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Account Settings & Policies'}
                                </Text>
                              </View>
                              <Icon
                                color={theme.colors['Akurie Dark Blue']}
                                name={'Entypo/chevron-right'}
                                size={24}
                              />
                            </View>
                          </Touchable>
                        </Surface>

                        <View
                          style={StyleSheet.applyWidth(
                            { marginLeft: 16, marginRight: 16, marginTop: 20 },
                            dimensions.width
                          )}
                        >
                          {/* Sign Out Touchable  */}
                          <Touchable
                            onPress={() => {
                              try {
                                setGlobalVariableValue({
                                  key: 'SKYE_AUTHORIZATION_HEADER',
                                  value: 'False',
                                });
                                navigation.navigate(
                                  'SplashScreenWelcomeScreen'
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            {/* Button Wrapper */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor: theme.colors['Error'],
                                  borderRadius: 8,
                                  flexGrow: 1,
                                  flexShrink: 0,
                                  justifyContent: 'center',
                                  marginBottom: 10,
                                  minHeight: 54,
                                  paddingLeft: 15,
                                  paddingRight: 15,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Sign Out Link */}
                              <Link
                                onPress={() => {
                                  try {
                                    setTopMenu_view(false);
                                    setGlobalVariableValue({
                                      key: 'SKYE_AUTHORIZATION_HEADER',
                                      value: '',
                                    });
                                    navigation.navigate('LoginScreen');
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors['Strong Inverse'],
                                    fontFamily: 'Inter_400Regular',
                                    paddingBottom: 10,
                                    paddingTop: 10,
                                    textAlign: 'center',
                                  },
                                  dimensions.width
                                )}
                                title={'Sign Out'}
                              />
                            </View>
                          </Touchable>
                        </View>
                      </View>
                    )}
                  </>
                </ImageBackground>
              </View>
            </BlurView>
          )}
        </>
        {/* Blur View 2 */}
        <>
          {!Manage_bus_view ? null : (
            <BlurView
              intensity={80}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.BlurViewStyles(theme)['Blur View'],
                  {
                    alignItems: 'center',
                    height: '100%',
                    justifyContent: 'flex-start',
                    padding: { minWidth: Breakpoints.Laptop, value: '10%' },
                    paddingTop: 250,
                    position: 'absolute',
                    width: '100%',
                    zIndex: 10,
                  }
                ),
                dimensions.width
              )}
              tint={'default'}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: theme.colors['Akurie Dark Blue'],
                    borderBottomWidth: 0.1,
                    borderColor: theme.colors['Akurie green'],
                    borderRadius: 19,
                    borderTopWidth: 0.1,
                    bottom: [
                      { minWidth: Breakpoints.Mobile, value: 50 },
                      { minWidth: Breakpoints.Laptop, value: 30 },
                    ],
                    height: 450,
                    justifyContent: 'center',
                    opacity: 1,
                    width: [
                      { minWidth: Breakpoints.Desktop, value: '80%' },
                      { minWidth: Breakpoints.BigScreen, value: '70%' },
                      { minWidth: Breakpoints.Mobile, value: '80%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                <Surface
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.SurfaceStyles(theme)['Surface'],
                      {
                        alignItems: 'center',
                        borderRadius: 19,
                        height: '80%',
                        justifyContent: 'center',
                        opacity: 1,
                        padding: 20,
                        width: '90%',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {/* View 2 */}
                  <>
                    {changebus_view ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'flex-end', width: '100%' },
                          dimensions.width
                        )}
                      >
                        <IconButton
                          color={theme.colors['Akurie Dark Blue']}
                          icon={'AntDesign/closecircle'}
                          onPress={() => {
                            try {
                              setManage_bus_view(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          size={26}
                        />
                      </View>
                    )}
                  </>
                  <BusinessDataAkurieApi.FetchGetBusinessGET
                    business_id={Constants['ak_businessID']}
                  >
                    {({ loading, error, data, refetchGetBusiness }) => {
                      const fetchData = data?.json;
                      if (loading) {
                        return <ActivityIndicator />;
                      }

                      if (error || data?.status < 200 || data?.status >= 300) {
                        return <ActivityIndicator />;
                      }

                      return (
                        <View
                          style={StyleSheet.applyWidth(
                            { marginBottom: 20, marginTop: 5 },
                            dimensions.width
                          )}
                        >
                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  fontFamily: 'Inter_600SemiBold',
                                  fontSize: 16,
                                  marginBottom: 5,
                                  textAlign: 'center',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {fetchData?.user_business_name}
                          </Text>

                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  fontFamily: 'Inter_600SemiBold',
                                  fontSize: 14,
                                  textAlign: 'center',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {fetchData?.entity_type}
                            {'\n'}
                            {fetchData?.business_state}
                            {' - ID: '}
                            {Constants['ak_businessID']}
                          </Text>
                        </View>
                      );
                    }}
                  </BusinessDataAkurieApi.FetchGetBusinessGET>
                  <>
                    {changebus_view ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '100%' },
                          dimensions.width
                        )}
                      >
                        <Button
                          onPress={() => {
                            try {
                              navigation.navigate('MyBusinessInfoScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'],
                              {
                                backgroundColor: theme.colors['Akurie Primary'],
                                borderRadius: 9,
                                color: theme.colors['Strong_Inverse'],
                                fontFamily: 'Inter_400Regular',
                                marginBottom: 10,
                                width: '90%',
                              }
                            ),
                            dimensions.width
                          )}
                          title={'Update Business Info'}
                        />
                        <Button
                          onPress={() => {
                            try {
                              /* Navigate action skipped because Dissolving My Business is hidden */
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'],
                              {
                                backgroundColor: theme.colors['Error'],
                                borderRadius: 9,
                                fontFamily: 'Inter_400Regular',
                                marginBottom: 10,
                                width: '90%',
                              }
                            ),
                            dimensions.width
                          )}
                          title={'Dissolve Business '}
                        />
                      </View>
                    )}
                  </>
                  <>
                    {dimensions.width >= Breakpoints.Laptop ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { width: '98%' },
                          dimensions.width
                        )}
                      ></View>
                    )}
                  </>
                </Surface>
              </View>
            </BlurView>
          )}
        </>
        {/* Intro Welcome */}
        <>
          {!Constants['welcomeMessage'] ? null : (
            <BlurView
              intensity={80}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.BlurViewStyles(theme)['Blur View'],
                  {
                    alignItems: 'center',
                    height: '100%',
                    justifyContent: 'flex-start',
                    padding: { minWidth: Breakpoints.Laptop, value: '10%' },
                    paddingTop: 250,
                    position: 'absolute',
                    width: '100%',
                    zIndex: 10,
                  }
                ),
                dimensions.width
              )}
              tint={'default'}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: theme.colors['Akurie Dark Blue'],
                    borderBottomWidth: 0.1,
                    borderColor: theme.colors['Akurie green'],
                    borderRadius: 19,
                    borderTopWidth: 0.1,
                    bottom: [
                      { minWidth: Breakpoints.Mobile, value: 50 },
                      { minWidth: Breakpoints.Laptop, value: 30 },
                    ],
                    height: 550,
                    justifyContent: 'center',
                    opacity: 1,
                    width: [
                      { minWidth: Breakpoints.Desktop, value: '80%' },
                      { minWidth: Breakpoints.BigScreen, value: '70%' },
                      { minWidth: Breakpoints.Mobile, value: '80%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                <Surface
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.SurfaceStyles(theme)['Surface'],
                      {
                        alignItems: 'center',
                        borderRadius: 19,
                        height: '90%',
                        justifyContent: 'center',
                        opacity: 1,
                        padding: 20,
                        width: '90%',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {/* View 2 */}
                  <>
                    {changebus_view ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'flex-end', width: '100%' },
                          dimensions.width
                        )}
                      ></View>
                    )}
                  </>
                  <Image
                    resizeMode={'contain'}
                    source={Images.MainLogoUpdate2u}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        { height: 60, width: '80%' }
                      ),
                      dimensions.width
                    )}
                  />
                  <View
                    style={StyleSheet.applyWidth(
                      { marginBottom: 20, marginTop: 5 },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            fontFamily: 'Inter_600SemiBold',
                            fontSize: 16,
                            marginBottom: 5,
                            textAlign: 'center',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Welcome to Akurie'}
                    </Text>

                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            fontFamily: 'Inter_600SemiBold',
                            fontSize: 14,
                            textAlign: 'center',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {"Let's get started! "}
                    </Text>
                    {/* Fetch component: no endpoint configured */ null}
                  </View>
                  <>
                    {changebus_view ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '100%' },
                          dimensions.width
                        )}
                      >
                        <Button
                          onPress={() => {
                            try {
                              setMeetAssistant(true);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'],
                              {
                                backgroundColor:
                                  theme.colors['Akurie Dark Blue'],
                                borderRadius: 9,
                                color: theme.colors['Akurie green'],
                                fontFamily: 'Inter_400Regular',
                                marginBottom: 10,
                                width: '90%',
                              }
                            ),
                            dimensions.width
                          )}
                          title={'Meet My Assistant'}
                        />
                        <>
                          {!meetAssistant ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                { width: '90%' },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      fontFamily: 'Inter_400Regular',
                                      marginBottom: 10,
                                      marginTop: 10,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {
                                  'Schedule a call with your Akurie Assistant. They can help you with tasks within your account, scheduling services, and more. '
                                }
                              </Text>
                              <Button
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      await WebBrowser.openBrowserAsync(
                                        'https://calendly.com/akurie/30min'
                                      );
                                      setGlobalVariableValue({
                                        key: 'welcomeMessage',
                                        value: false,
                                      });
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ButtonStyles(theme)['Button'],
                                    {
                                      backgroundColor:
                                        theme.colors['Akurie Primary'],
                                      borderRadius: 9,
                                      fontFamily: 'Inter_700Bold',
                                    }
                                  ),
                                  dimensions.width
                                )}
                                title={'Schedule Call'}
                              />
                            </View>
                          )}
                        </>
                      </View>
                    )}
                  </>
                  <Link
                    accessible={true}
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'welcomeMessage',
                          value: false,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.LinkStyles(theme)['Link'],
                        {
                          fontFamily: 'Inter_400Regular',
                          marginTop: 30,
                          textAlign: 'center',
                          textDecorationLine: 'underline',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'No Thanks. Continue'}
                  />
                  <>
                    {dimensions.width >= Breakpoints.Laptop ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { width: '98%' },
                          dimensions.width
                        )}
                      ></View>
                    )}
                  </>
                </Surface>
              </View>
            </BlurView>
          )}
        </>
        {/* Intro Welcome 2 */}
        <>
          {!callAssistant ? null : (
            <BlurView
              intensity={80}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.BlurViewStyles(theme)['Blur View'],
                  {
                    alignItems: 'center',
                    height: '100%',
                    justifyContent: 'flex-start',
                    padding: { minWidth: Breakpoints.Laptop, value: '10%' },
                    paddingTop: 250,
                    position: 'absolute',
                    width: '100%',
                    zIndex: 10,
                  }
                ),
                dimensions.width
              )}
              tint={'default'}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: theme.colors['Akurie Dark Blue'],
                    borderBottomWidth: 0.1,
                    borderColor: theme.colors['Akurie green'],
                    borderRadius: 19,
                    borderTopWidth: 0.1,
                    bottom: [
                      { minWidth: Breakpoints.Mobile, value: 50 },
                      { minWidth: Breakpoints.Laptop, value: 30 },
                    ],
                    height: 550,
                    justifyContent: 'center',
                    opacity: 1,
                    width: [
                      { minWidth: Breakpoints.Desktop, value: '80%' },
                      { minWidth: Breakpoints.BigScreen, value: '70%' },
                      { minWidth: Breakpoints.Mobile, value: '80%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                <Surface
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.SurfaceStyles(theme)['Surface'],
                      {
                        alignItems: 'center',
                        borderRadius: 19,
                        height: '90%',
                        justifyContent: 'center',
                        opacity: 1,
                        padding: 20,
                        width: '90%',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {/* View 2 */}
                  <>
                    {changebus_view ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'flex-end', width: '100%' },
                          dimensions.width
                        )}
                      ></View>
                    )}
                  </>
                  <Image
                    resizeMode={'contain'}
                    source={Images.MainLogoUpdate2u}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        { height: 60, width: '80%' }
                      ),
                      dimensions.width
                    )}
                  />
                  <View
                    style={StyleSheet.applyWidth(
                      { marginBottom: 20, marginTop: 5 },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            fontFamily: 'Inter_600SemiBold',
                            fontSize: 16,
                            marginBottom: 5,
                            textAlign: 'center',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Chat with your assistant.'}
                    </Text>
                  </View>
                  <>
                    {changebus_view ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '100%' },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '90%' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  fontFamily: 'Inter_400Regular',
                                  marginBottom: 10,
                                  marginTop: 10,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {
                              'Schedule a call with your Akurie Assistant. They can help you with tasks within your account, scheduling services, and more. '
                            }
                          </Text>
                          <Button
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  await WebBrowser.openBrowserAsync(
                                    'https://calendly.com/akurie/30min'
                                  );
                                  setCallAssistant(false);
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ButtonStyles(theme)['Button'],
                                {
                                  backgroundColor:
                                    theme.colors['Akurie Primary'],
                                  borderRadius: 9,
                                  fontFamily: 'Inter_700Bold',
                                  marginTop: 20,
                                }
                              ),
                              dimensions.width
                            )}
                            title={'Schedule Call'}
                          />
                          {/* Button 2 */}
                          <Button
                            onPress={() => {
                              try {
                                setCallAssistant(false);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ButtonStyles(theme)['Button'],
                                {
                                  backgroundColor: theme.colors['Error'],
                                  borderRadius: 9,
                                  fontFamily: 'Inter_700Bold',
                                  marginTop: 10,
                                }
                              ),
                              dimensions.width
                            )}
                            title={'Cancel'}
                          />
                        </View>
                      </View>
                    )}
                  </>
                  <>
                    {dimensions.width >= Breakpoints.Laptop ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { width: '98%' },
                          dimensions.width
                        )}
                      ></View>
                    )}
                  </>
                </Surface>
              </View>
            </BlurView>
          )}
        </>
        {/* Access Lock - Business Plans & Goals */}
        <>
          {!accessLock_Plans_Goals ? null : (
            <BlurView
              intensity={80}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.BlurViewStyles(theme)['Blur View'],
                  {
                    alignItems: 'center',
                    height: '100%',
                    justifyContent: 'flex-start',
                    padding: { minWidth: Breakpoints.Laptop, value: '10%' },
                    paddingTop: 250,
                    position: 'absolute',
                    width: '100%',
                    zIndex: 10,
                  }
                ),
                dimensions.width
              )}
              tint={'dark'}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: theme.colors['Akurie green'],
                    borderBottomWidth: 0.1,
                    borderColor: theme.colors['Akurie green'],
                    borderRadius: 19,
                    borderTopWidth: 0.1,
                    bottom: [
                      { minWidth: Breakpoints.Laptop, value: 30 },
                      { minWidth: Breakpoints.Mobile, value: 200 },
                    ],
                    height: 700,
                    justifyContent: 'center',
                    opacity: 1,
                    width: [
                      { minWidth: Breakpoints.Desktop, value: '80%' },
                      { minWidth: Breakpoints.BigScreen, value: '70%' },
                      { minWidth: Breakpoints.Mobile, value: '90%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                <Surface
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.SurfaceStyles(theme)['Surface'],
                      {
                        alignItems: 'center',
                        backgroundColor: theme.colors['Akurie Dark Blue'],
                        borderRadius: 19,
                        height: '90%',
                        justifyContent: 'space-around',
                        opacity: 1,
                        padding: 20,
                        width: '90%',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {/* View 2 */}
                  <>
                    {changebus_view ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'flex-end', width: '100%' },
                          dimensions.width
                        )}
                      >
                        <IconButton
                          color={theme.colors['Akurie green']}
                          icon={'AntDesign/closecircle'}
                          onPress={() => {
                            try {
                              setAccessLock_Plans_Goals(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          size={26}
                        />
                      </View>
                    )}
                  </>
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        marginBottom: 10,
                        padding: 2,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Divider'],
                            fontFamily: 'Inter_600SemiBold',
                            fontSize: 18,
                            textAlign: 'center',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Business Goals & Planning (GAP)'}
                    </Text>
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '98%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'cover'}
                      source={Images.Asset14}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          { height: 130, width: 130 }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* View 4 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Strong Inverse'],
                            fontFamily: 'Inter_400Regular',
                            textAlign: 'center',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        'This tool turns your goals and deadlines into a clear list of steps. If you fall behind, your Akurie assistant will reach out to offer help and keep you on track towards your objectives. This combines technology with personalized support to ensure your success.'
                      }
                    </Text>
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      { marginBottom: 20, marginTop: 20 },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Strong Inverse'],
                            fontFamily: 'Inter_600SemiBold',
                            fontSize: 14,
                            marginBottom: 5,
                            textAlign: 'center',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Upgrade to enjoy this feature and more!'}
                    </Text>
                  </View>
                  <>
                    {changebus_view ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '100%' },
                          dimensions.width
                        )}
                      >
                        <Button
                          onPress={() => {
                            try {
                              /* Navigate action skipped because Paywall Pro Current Month is hidden */ setAccessLock_Plans_Goals(
                                false
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'],
                              {
                                backgroundColor:
                                  theme.colors['Akurie Dark Blue'],
                                borderColor: theme.colors['Akurie green'],
                                borderRadius: 9,
                                borderWidth: 0.5,
                                color: theme.colors['Akurie green'],
                                fontFamily: 'Inter_400Regular',
                                marginBottom: 10,
                                width: '90%',
                              }
                            ),
                            dimensions.width
                          )}
                          title={'Upgrade to Assistant Pro'}
                        />
                        <Button
                          onPress={() => {
                            try {
                              setAccessLock_Plans_Goals(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'],
                              {
                                backgroundColor: theme.colors['Medium'],
                                borderRadius: 9,
                                fontFamily: 'Inter_400Regular',
                                marginBottom: 10,
                                width: '90%',
                              }
                            ),
                            dimensions.width
                          )}
                          title={'Continue With Current Plan'}
                        />
                      </View>
                    )}
                  </>
                </Surface>
              </View>
            </BlurView>
          )}
        </>
        {/* Access Lock - Business Documents & Filings */}
        <>
          {!accessLock_Docs_Filing ? null : (
            <BlurView
              intensity={80}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.BlurViewStyles(theme)['Blur View'],
                  {
                    alignItems: 'center',
                    height: '100%',
                    justifyContent: 'flex-start',
                    padding: { minWidth: Breakpoints.Laptop, value: '10%' },
                    paddingTop: 250,
                    position: 'absolute',
                    width: '100%',
                    zIndex: 10,
                  }
                ),
                dimensions.width
              )}
              tint={'dark'}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: theme.colors['Akurie green'],
                    borderBottomWidth: 0.1,
                    borderColor: theme.colors['Akurie green'],
                    borderRadius: 19,
                    borderTopWidth: 0.1,
                    bottom: [
                      { minWidth: Breakpoints.Laptop, value: 30 },
                      { minWidth: Breakpoints.Mobile, value: 200 },
                    ],
                    height: 700,
                    justifyContent: 'center',
                    opacity: 1,
                    width: [
                      { minWidth: Breakpoints.Desktop, value: '80%' },
                      { minWidth: Breakpoints.BigScreen, value: '70%' },
                      { minWidth: Breakpoints.Mobile, value: '90%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                <Surface
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.SurfaceStyles(theme)['Surface'],
                      {
                        alignItems: 'center',
                        backgroundColor: theme.colors['Akurie Dark Blue'],
                        borderRadius: 19,
                        height: '90%',
                        justifyContent: 'space-around',
                        opacity: 1,
                        padding: 18,
                        width: '90%',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {/* View 2 */}
                  <>
                    {changebus_view ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'flex-end', width: '100%' },
                          dimensions.width
                        )}
                      >
                        <IconButton
                          color={theme.colors['Akurie green']}
                          icon={'AntDesign/closecircle'}
                          onPress={() => {
                            try {
                              setAccessLock_Docs_Filing(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          size={26}
                        />
                      </View>
                    )}
                  </>
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        marginBottom: 10,
                        padding: 2,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Divider'],
                            fontFamily: 'Inter_600SemiBold',
                            fontSize: 18,
                            textAlign: 'center',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Business Documents & Filings'}
                    </Text>
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '98%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'contain'}
                      source={Images.Asset29}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          { height: 130, width: 130 }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* View 4 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Strong Inverse'],
                            fontFamily: 'Inter_400Regular',
                            textAlign: 'center',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        'This tool is designed to simplify your state report filings and ensure compliance. Just input your required information, and Akurie will not only track your filing deadlines but also handle the filings for you, charging only the state fees. Stay compliant effortlessly.'
                      }
                    </Text>
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      { marginBottom: 20, marginTop: 20 },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Strong Inverse'],
                            fontFamily: 'Inter_600SemiBold',
                            fontSize: 14,
                            marginBottom: 5,
                            textAlign: 'center',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Upgrade to enjoy this feature and more!'}
                    </Text>
                  </View>
                  <>
                    {changebus_view ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '100%' },
                          dimensions.width
                        )}
                      >
                        <Button
                          onPress={() => {
                            try {
                              /* Navigate action skipped because Paywall Pro Current Month is hidden */ setAccessLock_Docs_Filing(
                                false
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'],
                              {
                                backgroundColor:
                                  theme.colors['Akurie Dark Blue'],
                                borderColor: theme.colors['Akurie green'],
                                borderRadius: 9,
                                borderWidth: 0.5,
                                color: theme.colors['Akurie green'],
                                fontFamily: 'Inter_400Regular',
                                marginBottom: 10,
                                width: '90%',
                              }
                            ),
                            dimensions.width
                          )}
                          title={'Upgrade to Assistant Pro'}
                        />
                        <Button
                          onPress={() => {
                            try {
                              setAccessLock_Docs_Filing(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'],
                              {
                                backgroundColor: theme.colors['Medium'],
                                borderRadius: 9,
                                fontFamily: 'Inter_400Regular',
                                marginBottom: 10,
                                width: '90%',
                              }
                            ),
                            dimensions.width
                          )}
                          title={'Continue With Current Plan'}
                        />
                      </View>
                    )}
                  </>
                </Surface>
              </View>
            </BlurView>
          )}
        </>
        {/* GetStarted View */}
        <>
          {!getStartedView ? null : (
            <BlurView
              intensity={80}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.BlurViewStyles(theme)['Blur View'],
                  {
                    alignItems: 'center',
                    height: '100%',
                    justifyContent: 'flex-start',
                    padding: { minWidth: Breakpoints.Laptop, value: '10%' },
                    paddingTop: 250,
                    position: 'absolute',
                    width: '100%',
                    zIndex: 10,
                  }
                ),
                dimensions.width
              )}
              tint={'light'}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: theme.colors['Akurie green'],
                    borderBottomWidth: 0.1,
                    borderColor: theme.colors['Akurie green'],
                    borderRadius: 19,
                    borderTopWidth: 0.1,
                    bottom: [
                      { minWidth: Breakpoints.Laptop, value: 30 },
                      { minWidth: Breakpoints.Mobile, value: 200 },
                    ],
                    height: 700,
                    justifyContent: 'center',
                    opacity: 1,
                    width: [
                      { minWidth: Breakpoints.Desktop, value: '80%' },
                      { minWidth: Breakpoints.BigScreen, value: '70%' },
                      { minWidth: Breakpoints.Mobile, value: '90%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                <Surface
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.SurfaceStyles(theme)['Surface'],
                      {
                        alignItems: 'center',
                        backgroundColor: theme.colors['Background'],
                        borderRadius: 19,
                        height: '95%',
                        justifyContent: 'flex-start',
                        opacity: 1,
                        padding: 18,
                        width: '90%',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {/* View 2 */}
                  <>
                    {changebus_view ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'flex-end',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <IconButton
                          color={theme.colors['Akurie Dark Blue']}
                          icon={'AntDesign/closecircle'}
                          onPress={() => {
                            try {
                              setGetStartedView(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          size={32}
                        />
                      </View>
                    )}
                  </>
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        marginBottom: 10,
                        padding: 2,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Akurie Dark Blue'],
                            fontFamily: 'Inter_600SemiBold',
                            fontSize: 18,
                            textAlign: 'center',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Getting Started'}
                    </Text>
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '98%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'contain'}
                      source={Images.Asset9}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          { height: 150, width: 180 }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      { marginBottom: 10, marginTop: 10 },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Akurie Dark Blue'],
                            fontFamily: 'Inter_600SemiBold',
                            fontSize: 14,
                            marginBottom: 5,
                            textAlign: 'center',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {"Let's start with whats important to you."}
                    </Text>
                  </View>
                  <>
                    {changebus_view ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '100%' },
                          dimensions.width
                        )}
                      >
                        <Button
                          onPress={() => {
                            try {
                              setGetStartedView(false);
                              navigation.navigate(
                                'BusinessGoalsActionPlansScreen'
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'],
                              {
                                backgroundColor:
                                  theme.colors['Akurie Dark Blue'],
                                borderRadius: 9,
                                color: theme.colors['Akurie Primary'],
                                fontFamily: 'Inter_400Regular',
                                marginBottom: 10,
                                minHeight: 60,
                                width: '90%',
                              }
                            ),
                            dimensions.width
                          )}
                          title={'Start Business Growth Planning'}
                        />
                        {/* Button 3 */}
                        <Button
                          onPress={() => {
                            try {
                              setGetStartedView(false);
                              navigation.navigate('MyAkurieWebServicesScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'],
                              {
                                backgroundColor:
                                  theme.colors['Akurie Dark Blue'],
                                borderRadius: 9,
                                color: theme.colors['Akurie Primary'],
                                fontFamily: 'Inter_400Regular',
                                marginBottom: 10,
                                minHeight: 60,
                                width: '90%',
                              }
                            ),
                            dimensions.width
                          )}
                          title={'Get My Business Website'}
                        />
                        {/* Button 2 */}
                        <Button
                          onPress={() => {
                            try {
                              setGetStartedView(false);
                              setCallAssistant(true);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'],
                              {
                                backgroundColor:
                                  theme.colors['Akurie Dark Blue'],
                                borderRadius: 9,
                                color: theme.colors['Akurie Primary'],
                                fontFamily: 'Inter_400Regular',
                                marginBottom: 10,
                                minHeight: 60,
                                width: '90%',
                              }
                            ),
                            dimensions.width
                          )}
                          title={'Speak With My Assistant'}
                        />
                        {/* Button 4 */}
                        <Button
                          onPress={() => {
                            try {
                              setGetStartedView(false);
                              navigation.navigate('MyFilingsReportsScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'],
                              {
                                backgroundColor:
                                  theme.colors['Akurie Dark Blue'],
                                borderRadius: 9,
                                color: theme.colors['Akurie Primary'],
                                fontFamily: 'Inter_400Regular',
                                marginBottom: 10,
                                minHeight: 60,
                                width: '90%',
                              }
                            ),
                            dimensions.width
                          )}
                          title={'Annual Report / Business Status'}
                        />
                        {/* Button 5 */}
                        <Button
                          onPress={() => {
                            try {
                              setGetStartedView(false);
                              navigation.navigate('ProfessionalServicesScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'],
                              {
                                backgroundColor:
                                  theme.colors['Akurie Dark Blue'],
                                borderRadius: 9,
                                color: theme.colors['Akurie Primary'],
                                fontFamily: 'Inter_400Regular',
                                marginBottom: 10,
                                minHeight: 60,
                                width: '90%',
                              }
                            ),
                            dimensions.width
                          )}
                          title={'Taxes & Professional Services'}
                        />
                      </View>
                    )}
                  </>
                </Surface>
              </View>
            </BlurView>
          )}
        </>
        {/* Access Lock - Akurie Web Services */}
        <>
          {!accessLock_Web_Services ? null : (
            <BlurView
              intensity={80}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.BlurViewStyles(theme)['Blur View'],
                  {
                    alignItems: 'center',
                    height: '100%',
                    justifyContent: 'flex-start',
                    padding: { minWidth: Breakpoints.Laptop, value: '10%' },
                    paddingTop: 250,
                    position: 'absolute',
                    width: '100%',
                    zIndex: 10,
                  }
                ),
                dimensions.width
              )}
              tint={'dark'}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: theme.colors['Akurie green'],
                    borderBottomWidth: 0.1,
                    borderColor: theme.colors['Akurie green'],
                    borderRadius: 19,
                    borderTopWidth: 0.1,
                    bottom: [
                      { minWidth: Breakpoints.Laptop, value: 30 },
                      { minWidth: Breakpoints.Mobile, value: 200 },
                    ],
                    height: 700,
                    justifyContent: 'center',
                    opacity: 1,
                    width: [
                      { minWidth: Breakpoints.Desktop, value: '80%' },
                      { minWidth: Breakpoints.BigScreen, value: '70%' },
                      { minWidth: Breakpoints.Mobile, value: '90%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                <Surface
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.SurfaceStyles(theme)['Surface'],
                      {
                        alignItems: 'center',
                        backgroundColor: theme.colors['Akurie Dark Blue'],
                        borderRadius: 19,
                        height: '90%',
                        justifyContent: 'space-around',
                        opacity: 1,
                        padding: 18,
                        width: '90%',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {/* View 2 */}
                  <>
                    {changebus_view ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'flex-end', width: '100%' },
                          dimensions.width
                        )}
                      >
                        <IconButton
                          color={theme.colors['Akurie green']}
                          icon={'AntDesign/closecircle'}
                          onPress={() => {
                            try {
                              setAccessLock_Web_Services(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          size={26}
                        />
                      </View>
                    )}
                  </>
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        marginBottom: 10,
                        padding: 2,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Divider'],
                            fontFamily: 'Inter_600SemiBold',
                            fontSize: 18,
                            textAlign: 'center',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Akurie Web Services'}
                    </Text>
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '98%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'contain'}
                      source={Images.Asset21}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          { height: 130, width: 130 }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* View 4 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Strong Inverse'],
                            fontFamily: 'Inter_400Regular',
                            textAlign: 'center',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        "This tool effortlessly auto-generates a custom website for your business, perfectly aligned with your brand and your industry's specific standards. Enjoy the convenience of having all your Akurie web services, from expert consulting to dynamic content updates, seamlessly integrated and managed."
                      }
                    </Text>
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      { marginBottom: 20, marginTop: 20 },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Strong Inverse'],
                            fontFamily: 'Inter_600SemiBold',
                            fontSize: 14,
                            marginBottom: 5,
                            textAlign: 'center',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Upgrade to enjoy this feature and more!'}
                    </Text>
                  </View>
                  <>
                    {changebus_view ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '100%' },
                          dimensions.width
                        )}
                      >
                        <Button
                          onPress={() => {
                            try {
                              /* Navigate action skipped because Paywall Pro Current Month is hidden */ setAccessLock_Web_Services(
                                false
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'],
                              {
                                backgroundColor:
                                  theme.colors['Akurie Dark Blue'],
                                borderColor: theme.colors['Akurie green'],
                                borderRadius: 9,
                                borderWidth: 0.5,
                                color: theme.colors['Akurie green'],
                                fontFamily: 'Inter_400Regular',
                                marginBottom: 10,
                                width: '90%',
                              }
                            ),
                            dimensions.width
                          )}
                          title={'Upgrade to Assistant Pro'}
                        />
                        <Button
                          onPress={() => {
                            try {
                              setAccessLock_Web_Services(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'],
                              {
                                backgroundColor: theme.colors['Medium'],
                                borderRadius: 9,
                                fontFamily: 'Inter_400Regular',
                                marginBottom: 10,
                                width: '90%',
                              }
                            ),
                            dimensions.width
                          )}
                          title={'Continue With Current Plan'}
                        />
                      </View>
                    )}
                  </>
                </Surface>
              </View>
            </BlurView>
          )}
        </>
        <>
          {!Constants['ak_isLoading'] ? null : (
            <BlurView
              intensity={80}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.BlurViewStyles(theme)['Blur View'],
                  {
                    alignItems: 'center',
                    height: '100%',
                    justifyContent: 'center',
                    position: 'absolute',
                    width: '100%',
                    zIndex: 10,
                  }
                ),
                dimensions.width
              )}
              tint={'dark'}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: theme.colors['Akurie Dark Blue'],
                    borderRadius: 19,
                    bottom: 50,
                    height: [
                      { minWidth: Breakpoints.Mobile, value: '40%' },
                      { minWidth: Breakpoints.Tablet, value: '60%' },
                      { minWidth: Breakpoints.Laptop, value: '70%' },
                    ],
                    justifyContent: 'center',
                    opacity: 1,
                    width: [
                      { minWidth: Breakpoints.Tablet, value: '60%' },
                      { minWidth: Breakpoints.Laptop, value: '50%' },
                      { minWidth: Breakpoints.Desktop, value: '40%' },
                      { minWidth: Breakpoints.Mobile, value: '70%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                <IconButton
                  color={theme.colors['Akurie Primary']}
                  icon={'Feather/refresh-ccw'}
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'ak_isLoading',
                        value: false,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  size={32}
                  style={StyleSheet.applyWidth(
                    { position: 'absolute', right: 10, top: 10 },
                    dimensions.width
                  )}
                />
                <CircularProgress
                  animationDuration={7000}
                  color={theme.colors['Akurie green']}
                  indeterminate={true}
                  isAnimated={true}
                  lineCap={'round'}
                  showTrack={true}
                  startPosition={'bottom'}
                  style={StyleSheet.applyWidth(
                    { bottom: -20, height: '70%', width: '70%' },
                    dimensions.width
                  )}
                  thickness={15}
                  trackColor={theme.colors.divider}
                  trackLineCap={'round'}
                />
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Strong Inverse'],
                      fontFamily: 'Inter_600SemiBold',
                      fontSize: 20,
                      marginTop: 10,
                    }),
                    dimensions.width
                  )}
                >
                  {'Loading Your Business'}
                </Text>
              </View>
            </BlurView>
          )}
        </>
        <>
          {dimensions.width >= Breakpoints.Laptop ? null : (
            <ScrollView
              bounces={true}
              contentContainerStyle={StyleSheet.applyWidth(
                {
                  paddingBottom: 25,
                  paddingLeft: [
                    { minWidth: Breakpoints.Laptop, value: '10%' },
                    { minWidth: Breakpoints.Desktop, value: '15%' },
                  ],
                  paddingRight: [
                    { minWidth: Breakpoints.Laptop, value: '10%' },
                    { minWidth: Breakpoints.Desktop, value: '15%' },
                  ],
                },
                dimensions.width
              )}
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
            >
              {/* Greeting View 2 */}
              <>
                {dimensions.width >= Breakpoints.Laptop ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'stretch',
                        backgroundColor: theme.colors['Akurie Dark Blue'],
                        borderBottomWidth: 2,
                        borderColor: theme.colors['Akurie green'],
                        flexDirection: 'row',
                        height: '10%',
                        justifyContent: 'space-between',
                        paddingLeft: 30,
                        paddingRight: 30,
                        width: '100%',
                        zIndex: 30,
                      },
                      dimensions.width
                    )}
                  >
                    {/* View 3 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: [
                            { minWidth: Breakpoints.Mobile, value: 'center' },
                            { minWidth: Breakpoints.Tablet, value: 'center' },
                          ],
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          width: '60%',
                        },
                        dimensions.width
                      )}
                    >
                      <Image
                        resizeMode={'contain'}
                        source={Images.RingIcon}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ImageStyles(theme)['Image'],
                            {
                              height: [
                                { minWidth: Breakpoints.Desktop, value: 100 },
                                { minWidth: Breakpoints.Mobile, value: 100 },
                                { minWidth: Breakpoints.Tablet, value: 80 },
                              ],
                              marginRight: 10,
                              width: [
                                { minWidth: Breakpoints.Desktop, value: 180 },
                                { minWidth: Breakpoints.BigScreen, value: 180 },
                                { minWidth: Breakpoints.Mobile, value: '20%' },
                                { minWidth: Breakpoints.Laptop, value: 180 },
                                { minWidth: Breakpoints.Tablet, value: 180 },
                              ],
                            }
                          ),
                          dimensions.width
                        )}
                      />
                      <View>
                        <AuthAkurieApi.FetchGetSimpleUserDataGET
                          id={Constants['ak_userID']}
                        >
                          {({
                            loading,
                            error,
                            data,
                            refetchGetSimpleUserData,
                          }) => {
                            const fetchData = data?.json;
                            if (loading) {
                              return <ActivityIndicator />;
                            }

                            if (
                              error ||
                              data?.status < 200 ||
                              data?.status >= 300
                            ) {
                              return <ActivityIndicator />;
                            }

                            return (
                              <>
                                <>
                                  {topMenu_view ? null : (
                                    <Text
                                      accessible={true}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ],
                                          {
                                            color:
                                              theme.colors['Strong Inverse'],
                                            fontFamily: 'Inter_400Regular',
                                            fontSize: 18,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {fetchData?.First_name}{' '}
                                      {fetchData?.Last_name}
                                      {','}
                                    </Text>
                                  )}
                                </>
                                {/* Text 2 */}
                                <Text
                                  accessible={true}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      {
                                        color: theme.colors['Akurie green'],
                                        fontFamily: 'Inter_300Light',
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'Welcome to Akurie'}
                                </Text>
                              </>
                            );
                          }}
                        </AuthAkurieApi.FetchGetSimpleUserDataGET>
                      </View>
                    </View>
                    {/* View 2 */}
                    <>
                      {changebus_view ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'flex-end',
                              justifyContent: 'center',
                            },
                            dimensions.width
                          )}
                        >
                          <>
                            {topMenu_view ? null : (
                              <Circle
                                size={13}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.CircleStyles(theme)['Circle'],
                                    {
                                      backgroundColor:
                                        theme.colors['Akurie Primary'],
                                    }
                                  ),
                                  dimensions.width
                                )}
                              ></Circle>
                            )}
                          </>
                          <>
                            {topMenu_view ? null : (
                              <IconButton
                                color={theme.colors['Akurie green']}
                                icon={'FontAwesome/user-o'}
                                onPress={() => {
                                  try {
                                    setTopMenu_view(true);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                size={32}
                                style={StyleSheet.applyWidth(
                                  {
                                    marginLeft: 10,
                                    position: 'relative',
                                    top: -6,
                                  },
                                  dimensions.width
                                )}
                              />
                            )}
                          </>
                          {/* Icon Button 2 */}
                          <>
                            {!topMenu_view ? null : (
                              <IconButton
                                color={theme.colors['Akurie green']}
                                icon={'AntDesign/close'}
                                onPress={() => {
                                  try {
                                    setTopMenu_view(false);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                size={36}
                                style={StyleSheet.applyWidth(
                                  {
                                    marginLeft: 10,
                                    position: 'relative',
                                    top: -6,
                                  },
                                  dimensions.width
                                )}
                              />
                            )}
                          </>
                        </View>
                      )}
                    </>
                  </View>
                )}
              </>
              {/* GreetView */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    marginBottom: [
                      { minWidth: Breakpoints.Tablet, value: 40 },
                      { minWidth: Breakpoints.Mobile, value: 30 },
                    ],
                    marginTop: [
                      { minWidth: Breakpoints.Tablet, value: 40 },
                      { minWidth: Breakpoints.BigScreen, value: 40 },
                      { minWidth: Breakpoints.Desktop, value: 40 },
                      { minWidth: Breakpoints.Laptop, value: 40 },
                      { minWidth: Breakpoints.Mobile, value: 15 },
                    ],
                    paddingLeft: [
                      { minWidth: Breakpoints.Mobile, value: 30 },
                      { minWidth: Breakpoints.Tablet, value: 80 },
                    ],
                    paddingRight: [
                      { minWidth: Breakpoints.Mobile, value: 30 },
                      { minWidth: Breakpoints.Tablet, value: 80 },
                    ],
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderRadius: 9,
                      paddingLeft: {
                        minWidth: Breakpoints.BigScreen,
                        value: 50,
                      },
                      paddingRight: {
                        minWidth: Breakpoints.BigScreen,
                        value: 50,
                      },
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View 3 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignContent: 'stretch',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        marginBottom: 20,
                        marginTop: 20,
                        maxWidth: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center' },
                        dimensions.width
                      )}
                    />
                    {/* Fetch 2 */}
                    <BusinessDataAkurieApi.FetchGetBusinessGET
                      business_id={Constants['ak_businessID']}
                    >
                      {({ loading, error, data, refetchGetBusiness }) => {
                        const fetch2Data = data?.json;
                        if (loading) {
                          return <ActivityIndicator />;
                        }

                        if (
                          error ||
                          data?.status < 200 ||
                          data?.status >= 300
                        ) {
                          return <ActivityIndicator />;
                        }

                        return (
                          <>
                            <>
                              {!Constants['ak_businessID'] ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'flex-start',
                                      flexDirection: 'column',
                                      flexWrap: 'wrap',
                                      justifyContent: 'space-between',
                                      maxWidth: '90%',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Business Name */}
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignSelf: 'center',
                                        color: theme.colors['Akurie Dark Blue'],
                                        fontFamily: [
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 'Inter_600SemiBold',
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 'Inter_400Regular',
                                          },
                                        ],
                                        fontSize: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 20,
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 22,
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: 26,
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 24,
                                          },
                                        ],
                                        marginBottom: 10,
                                        marginTop: 10,
                                        textAlign: 'center',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {fetch2Data?.user_business_name}
                                  </Text>
                                </View>
                              )}
                            </>
                            <Divider
                              color={theme.colors['Akurie Primary']}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.DividerStyles(theme)['Divider'],
                                  { width: '40%' }
                                ),
                                dimensions.width
                              )}
                            />
                            {/* Sub Info */}
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  alignSelf: 'center',
                                  color: theme.colors['Akurie Dark Blue'],
                                  fontFamily: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 'Inter_600SemiBold',
                                    },
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: 'Inter_300Light',
                                    },
                                  ],
                                  fontSize: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 22,
                                    },
                                    { minWidth: Breakpoints.Tablet, value: 14 },
                                    { minWidth: Breakpoints.Mobile, value: 14 },
                                  ],
                                  marginTop: 10,
                                  textAlign: 'center',
                                },
                                dimensions.width
                              )}
                            >
                              {' '}
                              {fetch2Data?.entity_type}
                            </Text>

                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Light'],
                                    fontFamily: 'Inter_400Regular',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Current Company ID-'}
                              {fetch2Data?.id}
                            </Text>
                          </>
                        );
                      }}
                    </BusinessDataAkurieApi.FetchGetBusinessGET>
                  </View>
                </View>
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', width: '100%' },
                    dimensions.width
                  )}
                >
                  <>
                    {tutorialCompleted ? null : (
                      <Touchable
                        onPress={() => {
                          const handler = async () => {
                            try {
                              setGetStartedView(true);
                              (
                                await logs$SupportAkurieBasicActivityPOST.mutateAsync(
                                  {
                                    buttonName: 'Getting Started Button',
                                    session_id: Constants['Session_ID'],
                                  }
                                )
                              )?.json;
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        style={StyleSheet.applyWidth(
                          { width: '85%' },
                          dimensions.width
                        )}
                      >
                        {/* Surface 2 */}
                        <Surface
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.SurfaceStyles(theme)['Surface'],
                              {
                                alignItems: 'center',
                                alignSelf: 'center',
                                backgroundColor: theme.colors['Akurie green'],
                                borderRadius: 9,
                                justifyContent: 'center',
                                margin: 15,
                                minHeight: 60,
                                padding: 5,
                                width: '100%',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'row',
                                justifyContent: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Akurie Dark Blue'],
                                    fontFamily: 'Inter_500Medium',
                                    marginRight: 5,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Getting Started With Akurie'}
                            </Text>
                            <Icon
                              color={theme.colors['Akurie Dark Blue']}
                              name={'MaterialCommunityIcons/launch'}
                              size={24}
                            />
                          </View>
                        </Surface>
                      </Touchable>
                    )}
                  </>
                  <BusinessDataAkurieApi.FetchBusinessTableCheckFormationGET
                    bus_id={Constants['ak_businessID']}
                  >
                    {({
                      loading,
                      error,
                      data,
                      refetchBusinessTableCheckFormation,
                    }) => {
                      const fetchData = data?.json;
                      if (loading) {
                        return <ActivityIndicator />;
                      }

                      if (error || data?.status < 200 || data?.status >= 300) {
                        return <ActivityIndicator />;
                      }

                      return (
                        <>
                          {/* Touchable 3 */}
                          <>
                            {!fetchData?.Table_Result ? null : (
                              <Touchable
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      (
                                        await logs$SupportAkurieBasicActivityPOST.mutateAsync(
                                          {
                                            buttonName:
                                              'Getting Started Button',
                                            session_id: Constants['Session_ID'],
                                          }
                                        )
                                      )?.json;
                                      navigation.navigate(
                                        'BusinessLLCSetupInfo1Screen',
                                        { userID: Constants['ak_userID'] }
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                                style={StyleSheet.applyWidth(
                                  { width: '85%' },
                                  dimensions.width
                                )}
                              >
                                {/* Surface 2 */}
                                <Surface
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.SurfaceStyles(theme)[
                                        'Surface'
                                      ],
                                      {
                                        alignItems: 'center',
                                        alignSelf: 'center',
                                        backgroundColor:
                                          theme.colors['Akurie Primary'],
                                        borderRadius: 9,
                                        justifyContent: 'center',
                                        margin: 15,
                                        minHeight: 60,
                                        padding: 5,
                                        width: '100%',
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ],
                                          {
                                            color:
                                              theme.colors['Strong Inverse'],
                                            fontFamily: 'Inter_500Medium',
                                            marginRight: 5,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'File Business With State'}
                                    </Text>
                                    <Icon
                                      color={theme.colors['Strong Inverse']}
                                      name={'AntDesign/copy1'}
                                      size={24}
                                    />
                                  </View>
                                </Surface>
                              </Touchable>
                            )}
                          </>
                        </>
                      );
                    }}
                  </BusinessDataAkurieApi.FetchBusinessTableCheckFormationGET>
                  {/* Touchable 2 */}
                  <>
                    {!tutorialCompleted ? null : (
                      <Touchable
                        onPress={() => {
                          try {
                            setCallAssistant(true);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          { width: '85%' },
                          dimensions.width
                        )}
                      >
                        {/* Surface 2 */}
                        <Surface
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.SurfaceStyles(theme)['Surface'],
                              {
                                alignItems: 'center',
                                alignSelf: 'center',
                                backgroundColor:
                                  theme.colors['Akurie Dark Blue'],
                                borderRadius: 9,
                                justifyContent: 'center',
                                margin: 15,
                                minHeight: 60,
                                padding: 5,
                                width: '100%',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'row',
                                justifyContent: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Strong Inverse'],
                                    fontFamily: 'Inter_500Medium',
                                    marginRight: 5,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Chat With My Assistant'}
                            </Text>
                            <Icon
                              color={theme.colors['Akurie green']}
                              name={'Ionicons/call-outline'}
                              size={24}
                            />
                          </View>
                        </Surface>
                      </Touchable>
                    )}
                  </>
                  <>
                    {tutorialCompleted ? null : (
                      <Link
                        accessible={true}
                        onPress={() => {
                          const handler = async () => {
                            try {
                              (
                                await authAkurieCompleteTutorialPUT.mutateAsync(
                                  { users_id: Constants['ak_userID'] }
                                )
                              )?.json;
                              setTutorialCompleted(true);
                              (
                                await logs$SupportAkurieBasicActivityPOST.mutateAsync(
                                  {
                                    buttonName: 'Dismissed Get Started ',
                                    session_id: Constants['Session_ID'],
                                  }
                                )
                              )?.json;
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.LinkStyles(theme)['Link'],
                            {
                              fontFamily: 'Inter_400Regular',
                              textDecorationLine: 'underline',
                            }
                          ),
                          dimensions.width
                        )}
                        title={'Dismiss Getting Started'}
                      />
                    )}
                  </>
                </View>
              </View>
              {/* Filings */}
              <>
                {Constants['ak_subscriptionType'] === 'Akurie Free' ? null : (
                  <Filings$DocsApi.FetchGetFilings$NumbersGET
                    bus_id={Constants['ak_businessID']}
                  >
                    {({ loading, error, data, refetchGetFilings$Numbers }) => {
                      const filingsData = data?.json;
                      if (loading) {
                        return <ActivityIndicator />;
                      }

                      if (error || data?.status < 200 || data?.status >= 300) {
                        return <ActivityIndicator />;
                      }

                      return <></>;
                    }}
                  </Filings$DocsApi.FetchGetFilings$NumbersGET>
                )}
              </>
              {/* Content */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Akurie Dark Blue'],
                    borderRadius: [
                      { minWidth: Breakpoints.Mobile, value: 25 },
                      { minWidth: Breakpoints.Tablet, value: 30 },
                    ],
                    flex: 1,
                    padding: [
                      { minWidth: Breakpoints.Mobile, value: 10 },
                      { minWidth: Breakpoints.Tablet, value: 30 },
                    ],
                    paddingBottom: [
                      { minWidth: Breakpoints.Mobile, value: 100 },
                      { minWidth: Breakpoints.Tablet, value: 140 },
                    ],
                    paddingLeft: [
                      { minWidth: Breakpoints.Tablet, value: 120 },
                      { minWidth: Breakpoints.Mobile, value: 10 },
                    ],
                    paddingRight: [
                      { minWidth: Breakpoints.Tablet, value: 120 },
                      { minWidth: Breakpoints.Mobile, value: 10 },
                    ],
                  },
                  dimensions.width
                )}
              >
                {/* Third Menu Row */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Tablet,
                        value: 'center',
                      },
                      flexDirection: 'column',
                      justifyContent: 'space-evenly',
                      marginTop: [
                        { minWidth: Breakpoints.Mobile, value: 5 },
                        { minWidth: Breakpoints.Tablet, value: 30 },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  {/* My Business Info */}
                  <Touchable
                    onPress={() => {
                      const handler = async () => {
                        try {
                          (
                            await logs$SupportAkurieBasicActivityPOST.mutateAsync(
                              {
                                buttonName: 'View Business Info ',
                                session_id: Constants['Session_ID'],
                              }
                            )
                          )?.json;
                          navigation.navigate('MyBusinessInfoScreen');
                          /* hidden 'Set Variable' action */
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        width: [
                          { minWidth: Breakpoints.Mobile, value: '100%' },
                          { minWidth: Breakpoints.Tablet, value: '99%' },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    {/* Banner */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          margin: 5,
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: theme.colors['Akurie Primary'],
                            borderRadius: 15,
                            height: 130,
                            justifyContent: 'center',
                            paddingBottom: 10,
                            paddingLeft: 30,
                            paddingRight: 10,
                            paddingTop: 10,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        {/* Title */}
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors['Strong_Inverse'],
                              fontFamily: 'Inter_500Medium',
                              fontSize: 17,
                              textAlign: 'left',
                            },
                            dimensions.width
                          )}
                        >
                          {'My Business\nInformation & Identity'}
                        </Text>
                        {/* Title */}
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors['Akurie Dark Blue'],
                              fontFamily: 'Inter_500Medium',
                              fontSize: 14,
                              marginTop: 5,
                              textAlign: 'left',
                            },
                            dimensions.width
                          )}
                        >
                          {'Company Info & Branding'}
                        </Text>
                      </View>
                      <Icon
                        color={theme.colors['Akurie green']}
                        name={'Ionicons/finger-print'}
                        size={60}
                        style={StyleSheet.applyWidth(
                          { position: 'absolute', right: 60 },
                          dimensions.width
                        )}
                      />
                    </View>
                  </Touchable>
                </View>
                {/* Menu Pro 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexDirection: 'row',
                      justifyContent: 'space-evenly',
                      marginTop: 5,
                    },
                    dimensions.width
                  )}
                >
                  {/* Action Plan */}
                  <Touchable
                    onPress={() => {
                      const handler = async () => {
                        console.log('Action Plan ON_PRESS Start');
                        let error = null;
                        try {
                          console.log('Start ON_PRESS:0 SET_VARIABLE');
                          if (
                            Constants['ak_subscriptionType'] === 'Akurie Free'
                          ) {
                            setAccessLock_Plans_Goals(true);
                            console.log('Complete ON_PRESS:0 SET_VARIABLE');
                          } else {
                            console.log(
                              'Skipped ON_PRESS:0 SET_VARIABLE: condition not met'
                            );
                          }
                          console.log('Start ON_PRESS:1 CONDITIONAL_STOP');
                          if (
                            Constants['ak_subscriptionType'] === 'Akurie Free'
                          ) {
                            return;
                          }
                          console.log('Complete ON_PRESS:1 CONDITIONAL_STOP');
                          console.log('Start ON_PRESS:2 NAVIGATE');
                          navigation.navigate('BusinessGoalsActionPlansScreen');
                          console.log('Complete ON_PRESS:2 NAVIGATE');
                          console.log('Start ON_PRESS:3 SET_VARIABLE');
                          if (
                            Constants['ak_subscriptionType'] !== 'Akurie Free'
                          ) {
                            setGlobalVariableValue({
                              key: 'ak_isLoading',
                              value: true,
                            });
                            console.log('Complete ON_PRESS:3 SET_VARIABLE');
                          } else {
                            console.log(
                              'Skipped ON_PRESS:3 SET_VARIABLE: condition not met'
                            );
                          }
                          console.log('Start ON_PRESS:4 FETCH_REQUEST');
                          (
                            await logs$SupportAkurieBasicActivityPOST.mutateAsync(
                              {
                                buttonName: 'Business Goals',
                                session_id: Constants['Session_ID'],
                              }
                            )
                          )?.json;
                          console.log('Complete ON_PRESS:4 FETCH_REQUEST');
                          console.log('Start ON_PRESS:5 CONSOLE_LOG');
                          /* hidden 'Log to Console' action */ console.log(
                            'Complete ON_PRESS:5 CONSOLE_LOG'
                          );
                        } catch (err) {
                          console.error(err);
                          error = err.message ?? err;
                        }
                        console.log(
                          'Action Plan ON_PRESS Complete',
                          error ? { error } : 'no error'
                        );
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        width: [
                          { minWidth: Breakpoints.Tablet, value: '48%' },
                          { minWidth: Breakpoints.Mobile, value: '48%' },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    <Surface
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.SurfaceStyles(theme)['Surface'],
                          {
                            alignItems: 'center',
                            backgroundColor: theme.colors['Akurie Primary'],
                            borderRadius: 15,
                            flexDirection: 'column',
                            justifyContent: 'center',
                            minHeight: 175,
                            minWidth: 170,
                            padding: 5,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {/* Banner */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center',
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              paddingBottom: 10,
                              paddingLeft: 10,
                              paddingRight: 10,
                              paddingTop: 20,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Title */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Background'],
                                fontFamily: 'Inter_500Medium',
                                fontSize: 16,
                                textAlign: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            {'Business Growth\n& Planning'}
                          </Text>
                        </View>
                        <Image
                          resizeMode={'contain'}
                          source={Images.Asset26}
                          style={StyleSheet.applyWidth(
                            { height: 80, width: 80 },
                            dimensions.width
                          )}
                        />
                      </View>
                    </Surface>
                  </Touchable>
                  {/* Docs & Filings */}
                  <Touchable
                    onPress={() => {
                      const handler = async () => {
                        try {
                          (
                            await logs$SupportAkurieBasicActivityPOST.mutateAsync(
                              {
                                buttonName: 'Filing & Reports',
                                session_id: Constants['Session_ID'],
                              }
                            )
                          )?.json;
                          if (
                            Constants['ak_subscriptionType'] === 'Akurie Free'
                          ) {
                            setAccessLock_Docs_Filing(true);
                          }
                          if (
                            Constants['ak_subscriptionType'] !== 'Akurie Free'
                          ) {
                            setGlobalVariableValue({
                              key: 'ak_isLoading',
                              value: true,
                            });
                          }
                          if (
                            Constants['ak_subscriptionType'] === 'Assistant Pro'
                          ) {
                            navigation.navigate('MyFilingsReportsScreen');
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        width: [
                          { minWidth: Breakpoints.Mobile, value: '48%' },
                          { minWidth: Breakpoints.Tablet, value: '48%' },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    <Surface
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.SurfaceStyles(theme)['Surface'],
                          {
                            backgroundColor: theme.colors['Akurie Primary'],
                            borderRadius: 15,
                            justifyContent: 'center',
                            minHeight: 175,
                            minWidth: 170,
                            padding: 5,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {/* Banner */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center',
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              justifyContent: 'center',
                              paddingBottom: 10,
                              paddingLeft: 10,
                              paddingRight: 10,
                              paddingTop: 20,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Title */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Background'],
                                fontFamily: 'Inter_500Medium',
                                fontSize: 16,
                                textAlign: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            {'Compliance &\nAnnual Filings'}
                          </Text>
                        </View>
                        <Image
                          resizeMode={'contain'}
                          source={Images.Asset14}
                          style={StyleSheet.applyWidth(
                            { height: 80, width: 80 },
                            dimensions.width
                          )}
                        />
                      </View>
                    </Surface>
                  </Touchable>
                </View>
                {/* Menu Pro */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexDirection: 'row',
                      justifyContent: 'space-evenly',
                      marginTop: 10,
                    },
                    dimensions.width
                  )}
                >
                  {/* Digital Web Services */}
                  <Touchable
                    onPress={() => {
                      const handler = async () => {
                        try {
                          (
                            await logs$SupportAkurieBasicActivityPOST.mutateAsync(
                              {
                                buttonName: 'Web Services',
                                session_id: Constants['Session_ID'],
                              }
                            )
                          )?.json;
                          if (
                            Constants['ak_subscriptionType'] === 'Assistant Pro'
                          ) {
                            navigation.navigate('MyAkurieWebServicesScreen');
                          }
                          if (
                            Constants['ak_subscriptionType'] === 'Akurie Free'
                          ) {
                            setAccessLock_Web_Services(true);
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        width: [
                          { minWidth: Breakpoints.Mobile, value: '48%' },
                          { minWidth: Breakpoints.Tablet, value: '48%' },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    <Surface
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.SurfaceStyles(theme)['Surface'],
                          {
                            backgroundColor: theme.colors['Akurie Primary'],
                            borderRadius: 15,
                            justifyContent: 'center',
                            minHeight: 175,
                            minWidth: 170,
                            padding: 5,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {/* Banner */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center',
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              justifyContent: 'center',
                              paddingBottom: 10,
                              paddingLeft: 10,
                              paddingRight: 10,
                              paddingTop: 20,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Title */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Background'],
                                fontFamily: 'Inter_500Medium',
                                fontSize: 16,
                                textAlign: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            {'My Website &\nSocial Media'}
                          </Text>
                        </View>
                        {/* View 3 */}
                        <>
                          {!(
                            Constants['the_financials'] === 'Locked'
                          ) ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                            ></View>
                          )}
                        </>
                        <Image
                          resizeMode={'contain'}
                          source={Images.Asset60}
                          style={StyleSheet.applyWidth(
                            { height: 80, width: 80 },
                            dimensions.width
                          )}
                        />
                      </View>
                    </Surface>
                  </Touchable>
                  {/* Resource Center */}
                  <Touchable
                    onPress={() => {
                      const handler = async () => {
                        try {
                          /* hidden 'API Request' action */
                          /* hidden 'Extract Key' action */
                          /* hidden 'Navigate' action */
                          /* hidden 'Conditional Stop' action */
                          (
                            await logs$SupportAkurieBasicActivityPOST.mutateAsync(
                              {
                                buttonName: 'Professional Services',
                                session_id: Constants['Session_ID'],
                              }
                            )
                          )?.json;
                          navigation.navigate('ProfessionalServicesScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      { width: '48%' },
                      dimensions.width
                    )}
                  >
                    <Surface
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.SurfaceStyles(theme)['Surface'],
                          {
                            alignItems: 'center',
                            backgroundColor: theme.colors['Akurie Primary'],
                            borderRadius: 15,
                            flexDirection: 'column',
                            justifyContent: 'center',
                            minHeight: 175,
                            minWidth: 170,
                            padding: 5,
                            paddingBottom: 5,
                            paddingTop: 5,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {/* Banner */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center',
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              justifyContent: 'center',
                              paddingBottom: 10,
                              paddingLeft: 10,
                              paddingRight: 10,
                              paddingTop: 10,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Title */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Background'],
                                fontFamily: 'Inter_500Medium',
                                fontSize: 16,
                                textAlign: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            {'Professional\nServices'}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', marginLeft: 10 },
                          dimensions.width
                        )}
                      >
                        <Image
                          resizeMode={'contain'}
                          source={Images.Asset29}
                          style={StyleSheet.applyWidth(
                            { height: 80, width: 80 },
                            dimensions.width
                          )}
                        />
                      </View>
                    </Surface>
                  </Touchable>
                </View>
                {/* Akurie Networking */}
                <Touchable
                  onPress={() => {
                    const handler = async () => {
                      try {
                        /* hidden 'API Request' action */
                        /* hidden 'Extract Key' action */
                        /* hidden 'Navigate' action */
                        /* hidden 'Conditional Stop' action */
                        (
                          await logs$SupportAkurieBasicActivityPOST.mutateAsync(
                            {
                              buttonName: 'Networking Pass',
                              session_id: Constants['Session_ID'],
                            }
                          )
                        )?.json;
                        navigation.navigate('NetworkingIDPassScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  style={StyleSheet.applyWidth(
                    { marginTop: 5 },
                    dimensions.width
                  )}
                >
                  {/* Banner 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        margin: 5,
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          borderColor: theme.colors['Strong Inverse'],
                          borderRadius: 15,
                          borderWidth: 1,
                          height: 120,
                          justifyContent: 'center',
                          paddingBottom: 10,
                          paddingLeft: 30,
                          paddingRight: 10,
                          paddingTop: 20,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      {/* Title */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Strong_Inverse'],
                            fontFamily: 'Inter_500Medium',
                            fontSize: 16,
                          },
                          dimensions.width
                        )}
                      >
                        {'Akurie Networking\n& Event Opportunites'}
                      </Text>
                      {/* Title */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Strong Inverse'],
                            fontFamily: 'Inter_500Medium',
                            fontSize: 12,
                            marginTop: 5,
                          },
                          dimensions.width
                        )}
                      >
                        {'Connect with local businesses'}
                      </Text>
                    </View>
                    <Image
                      resizeMode={'contain'}
                      source={Images.Asset30}
                      style={StyleSheet.applyWidth(
                        {
                          height: 90,
                          position: 'absolute',
                          right: 30,
                          width: 90,
                        },
                        dimensions.width
                      )}
                    />
                  </View>
                </Touchable>
              </View>
            </ScrollView>
          )}
        </>
        {/* Scroll View 2 */}
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <ScrollView
              bounces={true}
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
              style={StyleSheet.applyWidth(
                {
                  height: {
                    minWidth: Breakpoints.Laptop,
                    value: dimensions.height,
                  },
                  width: {
                    minWidth: Breakpoints.Laptop,
                    value: dimensions.width,
                  },
                },
                dimensions.width
              )}
              contentContainerStyle={StyleSheet.applyWidth(
                {
                  alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                },
                dimensions.width
              )}
            >
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: {
                      minWidth: Breakpoints.Laptop,
                      value: 'stretch',
                    },
                    flexDirection: {
                      minWidth: Breakpoints.Laptop,
                      value: 'row',
                    },
                    height: {
                      minWidth: Breakpoints.Laptop,
                      value: dimensions.height,
                    },
                    marginBottom: { minWidth: Breakpoints.Tablet, value: 100 },
                    width: [
                      { minWidth: Breakpoints.Laptop, value: '85%' },
                      { minWidth: Breakpoints.Desktop, value: '70%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                {/* Content */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      backgroundColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors['Akurie Dark Blue'],
                        },
                        {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors['Akurie Dark Blue'],
                        },
                      ],
                      borderRadius: 0,
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      marginLeft: [
                        { minWidth: Breakpoints.Laptop, value: 40 },
                        { minWidth: Breakpoints.Desktop, value: 0 },
                        { minWidth: Breakpoints.BigScreen, value: 40 },
                      ],
                      width: { minWidth: Breakpoints.Laptop, value: '25%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* Header Greeting */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: 'column',
                        justifyContent: 'space-evenly',
                        marginBottom: 3,
                        position: {
                          minWidth: Breakpoints.Laptop,
                          value: 'absolute',
                        },
                        top: [
                          { minWidth: Breakpoints.BigScreen, value: 90 },
                          { minWidth: Breakpoints.Laptop, value: 20 },
                        ],
                        width: { minWidth: Breakpoints.Laptop, value: '100%' },
                      },
                      dimensions.width
                    )}
                  >
                    <Surface
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.SurfaceStyles(theme)['Surface'],
                          {
                            alignItems: 'center',
                            backgroundColor: [
                              {
                                minWidth: Breakpoints.Mobile,
                                value: theme.colors['Medium'],
                              },
                              {
                                minWidth: Breakpoints.Laptop,
                                value: theme.colors['Medium'],
                              },
                            ],
                            flexDirection: 'row',
                            justifyContent: [
                              { minWidth: Breakpoints.Mobile, value: 'center' },
                              {
                                minWidth: Breakpoints.BigScreen,
                                value: 'center',
                              },
                            ],
                            minHeight: [
                              { minWidth: Breakpoints.Mobile, value: 50 },
                              { minWidth: Breakpoints.Laptop, value: 70 },
                              { minWidth: Breakpoints.Desktop, value: 90 },
                            ],
                            minWidth: [
                              { minWidth: Breakpoints.Mobile, value: 180 },
                              { minWidth: Breakpoints.Laptop, value: '100%' },
                            ],
                            paddingBottom: 5,
                            paddingTop: 5,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {/* Banner */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            marginRight: 10,
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              justifyContent: 'center',
                              paddingBottom: 10,
                              paddingLeft: 10,
                              paddingRight: 10,
                              paddingTop: 10,
                            },
                            dimensions.width
                          )}
                        >
                          <AuthAkurieApi.FetchGetSpecificUserGET
                            user_id={Constants['ak_userID']}
                          >
                            {({
                              loading,
                              error,
                              data,
                              refetchGetSpecificUser,
                            }) => {
                              const fetchData = data?.json;
                              if (loading) {
                                return <ActivityIndicator />;
                              }

                              if (
                                error ||
                                data?.status < 200 ||
                                data?.status >= 300
                              ) {
                                return <ActivityIndicator />;
                              }

                              return (
                                <>
                                  {/* Title */}
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      {
                                        color: theme.colors['Background'],
                                        fontFamily: 'Inter_500Medium',
                                        fontSize: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 16,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 18,
                                          },
                                        ],
                                        textAlign: 'center',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'Welcome '}
                                    {fetchData?.First_name}
                                    {'!'}
                                  </Text>
                                </>
                              );
                            }}
                          </AuthAkurieApi.FetchGetSpecificUserGET>
                        </View>
                      </View>
                    </Surface>
                  </View>
                  {/* Menu Pro */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: [
                          { minWidth: Breakpoints.Mobile, value: 'row' },
                          { minWidth: Breakpoints.Laptop, value: 'column' },
                        ],
                        justifyContent: 'space-evenly',
                        marginTop: [
                          { minWidth: Breakpoints.Mobile, value: 10 },
                          { minWidth: Breakpoints.Tablet, value: 80 },
                        ],
                        width: { minWidth: Breakpoints.Laptop, value: '90%' },
                      },
                      dimensions.width
                    )}
                  >
                    {/* My Business */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('MyBusinessInfoScreen');
                          setGlobalVariableValue({
                            key: 'ak_isLoading',
                            value: true,
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          width: [
                            { minWidth: Breakpoints.Mobile, value: '48%' },
                            { minWidth: Breakpoints.Tablet, value: '48%' },
                            { minWidth: Breakpoints.Laptop, value: '100%' },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      <Surface
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.SurfaceStyles(theme)['Surface'],
                            {
                              backgroundColor: theme.colors['Akurie Primary'],
                              borderRadius: 15,
                              justifyContent: 'center',
                              minHeight: [
                                { minWidth: Breakpoints.Mobile, value: 185 },
                                { minWidth: Breakpoints.Laptop, value: 90 },
                              ],
                              minWidth: [
                                { minWidth: Breakpoints.Mobile, value: 180 },
                                { minWidth: Breakpoints.Laptop, value: '100%' },
                              ],
                              padding: 5,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {/* Banner */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: [
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'column',
                                },
                                { minWidth: Breakpoints.Laptop, value: 'row' },
                              ],
                              justifyContent: 'center',
                            },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingBottom: [
                                  { minWidth: Breakpoints.Mobile, value: 10 },
                                  { minWidth: Breakpoints.Laptop, value: 20 },
                                ],
                                paddingLeft: 10,
                                paddingRight: 10,
                                paddingTop: 20,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Title */}
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors['Background'],
                                  fontFamily: [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: 'Inter_500Medium',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'Inter_400Regular',
                                    },
                                  ],
                                  fontSize: [
                                    { minWidth: Breakpoints.Mobile, value: 16 },
                                    { minWidth: Breakpoints.Laptop, value: 14 },
                                  ],
                                  textAlign: 'center',
                                },
                                dimensions.width
                              )}
                            >
                              {'My Business\nInformation'}
                            </Text>
                          </View>
                        </View>
                      </Surface>
                    </Touchable>
                    {/* Action Plan */}
                    <Touchable
                      onPress={() => {
                        try {
                          if (
                            Constants['ak_subscriptionType'] === 'Assistant Pro'
                          ) {
                            navigation.navigate(
                              'BusinessGoalsActionPlansScreen'
                            );
                          }
                          setGlobalVariableValue({
                            key: 'ak_isLoading',
                            value: true,
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          marginTop: {
                            minWidth: Breakpoints.Laptop,
                            value: 10,
                          },
                          width: [
                            { minWidth: Breakpoints.Mobile, value: '48%' },
                            { minWidth: Breakpoints.Tablet, value: '48%' },
                            { minWidth: Breakpoints.Laptop, value: '100%' },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      <Surface
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.SurfaceStyles(theme)['Surface'],
                            {
                              backgroundColor: theme.colors['Akurie Primary'],
                              borderRadius: 15,
                              justifyContent: 'center',
                              minHeight: [
                                { minWidth: Breakpoints.Mobile, value: 185 },
                                { minWidth: Breakpoints.Laptop, value: 90 },
                              ],
                              minWidth: [
                                { minWidth: Breakpoints.Mobile, value: 180 },
                                { minWidth: Breakpoints.Laptop, value: '100%' },
                              ],
                              padding: 5,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {/* Banner */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: [
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'column',
                                },
                                { minWidth: Breakpoints.Laptop, value: 'row' },
                              ],
                              justifyContent: 'center',
                            },
                            dimensions.width
                          )}
                        >
                          <>
                            {!(
                              Constants['business_action_plan'] === 'Locked'
                            ) ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  },
                                  dimensions.width
                                )}
                              ></View>
                            )}
                          </>
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                paddingBottom: 10,
                                paddingLeft: 10,
                                paddingRight: 10,
                                paddingTop: 20,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Title */}
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors['Background'],
                                  fontFamily: [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: 'Inter_500Medium',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'Inter_400Regular',
                                    },
                                  ],
                                  fontSize: [
                                    { minWidth: Breakpoints.Mobile, value: 16 },
                                    { minWidth: Breakpoints.Laptop, value: 14 },
                                  ],
                                  textAlign: 'center',
                                },
                                dimensions.width
                              )}
                            >
                              {'Business \nAction Plan'}
                            </Text>
                          </View>
                        </View>
                      </Surface>
                    </Touchable>
                  </View>
                  {/* Menu Pro 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: [
                          { minWidth: Breakpoints.Mobile, value: 'row' },
                          { minWidth: Breakpoints.Laptop, value: 'column' },
                        ],
                        justifyContent: 'space-evenly',
                        marginTop: 10,
                        width: { minWidth: Breakpoints.Laptop, value: '90%' },
                      },
                      dimensions.width
                    )}
                  >
                    {/* The Financials */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('ResourceCenterScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          width: [
                            { minWidth: Breakpoints.Mobile, value: '48%' },
                            { minWidth: Breakpoints.Tablet, value: '48%' },
                            { minWidth: Breakpoints.Laptop, value: '100%' },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      <Surface
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.SurfaceStyles(theme)['Surface'],
                            {
                              backgroundColor: theme.colors['Akurie Primary'],
                              borderRadius: 15,
                              justifyContent: 'center',
                              minHeight: [
                                { minWidth: Breakpoints.Mobile, value: 185 },
                                { minWidth: Breakpoints.Laptop, value: 90 },
                              ],
                              minWidth: 180,
                              padding: 5,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {/* Banner */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: [
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'column',
                                },
                                { minWidth: Breakpoints.Laptop, value: 'row' },
                              ],
                              justifyContent: 'center',
                            },
                            dimensions.width
                          )}
                        >
                          {/* View 3 */}
                          <>
                            {!(
                              Constants['the_financials'] === 'Locked'
                            ) ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  },
                                  dimensions.width
                                )}
                              ></View>
                            )}
                          </>
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingBottom: 10,
                                paddingLeft: 10,
                                paddingRight: 10,
                                paddingTop: 20,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Title */}
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors['Background'],
                                  fontFamily: [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: 'Inter_500Medium',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'Inter_400Regular',
                                    },
                                  ],
                                  fontSize: [
                                    { minWidth: Breakpoints.Mobile, value: 16 },
                                    { minWidth: Breakpoints.Laptop, value: 14 },
                                  ],
                                  textAlign: 'center',
                                },
                                dimensions.width
                              )}
                            >
                              {'The\nFinancials'}
                            </Text>
                          </View>
                        </View>
                      </Surface>
                    </Touchable>
                    {/* Docs & Filings */}
                    <Touchable
                      onPress={() => {
                        try {
                          if (
                            Constants['ak_subscriptionType'] === 'Assistant Pro'
                          ) {
                            navigation.navigate('MyFilingsReportsScreen');
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          marginTop: {
                            minWidth: Breakpoints.Laptop,
                            value: 10,
                          },
                          width: [
                            { minWidth: Breakpoints.Mobile, value: '48%' },
                            { minWidth: Breakpoints.Tablet, value: '48%' },
                            { minWidth: Breakpoints.Laptop, value: '100%' },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      <Surface
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.SurfaceStyles(theme)['Surface'],
                            {
                              backgroundColor: theme.colors['Akurie Primary'],
                              borderRadius: 15,
                              justifyContent: 'center',
                              minHeight: [
                                { minWidth: Breakpoints.Mobile, value: 185 },
                                { minWidth: Breakpoints.Laptop, value: 90 },
                              ],
                              minWidth: [
                                { minWidth: Breakpoints.Mobile, value: 180 },
                                { minWidth: Breakpoints.Laptop, value: '100%' },
                              ],
                              padding: 5,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {/* Banner */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: [
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'column',
                                },
                                { minWidth: Breakpoints.Laptop, value: 'row' },
                              ],
                              justifyContent: 'center',
                            },
                            dimensions.width
                          )}
                        >
                          {/* View 4 */}
                          <>
                            {!(
                              Constants['business_docs_filings'] === 'Locked'
                            ) ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  },
                                  dimensions.width
                                )}
                              ></View>
                            )}
                          </>
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingBottom: 10,
                                paddingLeft: 10,
                                paddingRight: 10,
                                paddingTop: 20,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Title */}
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors['Background'],
                                  fontFamily: [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: 'Inter_500Medium',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'Inter_400Regular',
                                    },
                                  ],
                                  fontSize: [
                                    { minWidth: Breakpoints.Mobile, value: 16 },
                                    { minWidth: Breakpoints.Laptop, value: 14 },
                                  ],
                                  textAlign: 'center',
                                },
                                dimensions.width
                              )}
                            >
                              {'Documents\n& Filings'}
                            </Text>
                          </View>
                        </View>
                      </Surface>
                    </Touchable>
                  </View>
                  {/* Menu Pro 3 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: [
                          { minWidth: Breakpoints.Mobile, value: 'row' },
                          { minWidth: Breakpoints.Laptop, value: 'column' },
                        ],
                        justifyContent: 'space-evenly',
                        marginBottom: {
                          minWidth: Breakpoints.Laptop,
                          value: 45,
                        },
                        marginTop: 10,
                        width: { minWidth: Breakpoints.Laptop, value: '90%' },
                      },
                      dimensions.width
                    )}
                  >
                    {/* Website Management */}
                    <Touchable
                      onPress={() => {
                        try {
                          if (
                            Constants['ak_subscriptionType'] === 'Assistant Pro'
                          ) {
                            navigation.navigate('MyAkurieWebServicesScreen');
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          width: [
                            { minWidth: Breakpoints.Mobile, value: '48%' },
                            { minWidth: Breakpoints.Tablet, value: '48%' },
                            { minWidth: Breakpoints.Laptop, value: '100%' },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      <Surface
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.SurfaceStyles(theme)['Surface'],
                            {
                              backgroundColor: theme.colors['Akurie Primary'],
                              borderRadius: 15,
                              justifyContent: 'center',
                              minHeight: [
                                { minWidth: Breakpoints.Mobile, value: 185 },
                                { minWidth: Breakpoints.Laptop, value: 90 },
                              ],
                              minWidth: [
                                { minWidth: Breakpoints.Mobile, value: 180 },
                                { minWidth: Breakpoints.Laptop, value: '100%' },
                              ],
                              padding: 5,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {/* Banner */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: [
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'column',
                                },
                                { minWidth: Breakpoints.Laptop, value: 'row' },
                              ],
                              justifyContent: 'center',
                            },
                            dimensions.width
                          )}
                        >
                          {/* View 5 */}
                          <>
                            {!(Constants['my_website'] === 'Locked') ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  },
                                  dimensions.width
                                )}
                              ></View>
                            )}
                          </>
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingBottom: 10,
                                paddingLeft: 10,
                                paddingRight: 10,
                                paddingTop: 20,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Title */}
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors['Background'],
                                  fontFamily: [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: 'Inter_500Medium',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'Inter_400Regular',
                                    },
                                  ],
                                  fontSize: [
                                    { minWidth: Breakpoints.Mobile, value: 16 },
                                    { minWidth: Breakpoints.Laptop, value: 14 },
                                  ],
                                  textAlign: 'center',
                                },
                                dimensions.width
                              )}
                            >
                              {'My Akurie\nWeb Services'}
                            </Text>
                          </View>
                        </View>
                      </Surface>
                    </Touchable>
                    {/* Sign Out Button */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('SplashScreenWelcomeScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          marginTop: {
                            minWidth: Breakpoints.Laptop,
                            value: 20,
                          },
                          width: [
                            { minWidth: Breakpoints.Mobile, value: '48%' },
                            { minWidth: Breakpoints.Tablet, value: '48%' },
                            { minWidth: Breakpoints.Laptop, value: '100%' },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      <Surface
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.SurfaceStyles(theme)['Surface'],
                            {
                              alignItems: {
                                minWidth: Breakpoints.Laptop,
                                value: 'center',
                              },
                              backgroundColor: [
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: theme.colors['Akurie Primary'],
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Akurie green'],
                                },
                              ],
                              borderRadius: [
                                { minWidth: Breakpoints.Mobile, value: 15 },
                                { minWidth: Breakpoints.Laptop, value: 9 },
                              ],
                              justifyContent: 'center',
                              minHeight: [
                                { minWidth: Breakpoints.Mobile, value: 185 },
                                { minWidth: Breakpoints.Laptop, value: 50 },
                              ],
                              minWidth: [
                                { minWidth: Breakpoints.Mobile, value: 180 },
                                { minWidth: Breakpoints.Laptop, value: 30 },
                              ],
                              padding: 5,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {/* Title */}
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: [
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: theme.colors['Background'],
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Akurie Dark Blue'],
                                },
                              ],
                              fontFamily: [
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'Inter_500Medium',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Inter_400Regular',
                                },
                              ],
                              fontSize: [
                                { minWidth: Breakpoints.Mobile, value: 16 },
                                { minWidth: Breakpoints.Laptop, value: 14 },
                              ],
                              textAlign: 'center',
                            },
                            dimensions.width
                          )}
                        >
                          {'Sign Out'}
                        </Text>
                      </Surface>
                    </Touchable>
                  </View>
                </View>

                <View
                  style={StyleSheet.applyWidth(
                    {
                      paddingLeft: {
                        minWidth: Breakpoints.Laptop,
                        value: '5%',
                      },
                      paddingRight: {
                        minWidth: Breakpoints.Laptop,
                        value: '5%',
                      },
                      width: { minWidth: Breakpoints.Laptop, value: '70%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* GreetView */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderRadius: {
                          minWidth: Breakpoints.Laptop,
                          value: 9,
                        },
                        marginBottom: [
                          { minWidth: Breakpoints.Tablet, value: 20 },
                          { minWidth: Breakpoints.Mobile, value: 10 },
                        ],
                        marginTop: [
                          { minWidth: Breakpoints.Mobile, value: 20 },
                          { minWidth: Breakpoints.Tablet, value: 20 },
                        ],
                        padding: { minWidth: Breakpoints.Laptop, value: 10 },
                        paddingLeft: 30,
                        paddingRight: 30,
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: {
                            minWidth: Breakpoints.Laptop,
                            value: 'stretch',
                          },
                          paddingLeft: {
                            minWidth: Breakpoints.BigScreen,
                            value: 50,
                          },
                          paddingRight: {
                            minWidth: Breakpoints.BigScreen,
                            value: 50,
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'flex-end',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Touchable
                          onPress={() => {
                            try {
                              setManage_bus_view(true);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              width: [
                                { minWidth: Breakpoints.Tablet, value: '15%' },
                                { minWidth: Breakpoints.Mobile, value: 110 },
                                { minWidth: Breakpoints.Laptop, value: 110 },
                              ],
                            },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                backgroundColor:
                                  theme.colors['Akurie Dark Blue'],
                                borderRadius: 11,
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                padding: 3,
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Strong_Inverse'],
                                    fontFamily: 'Inter_300Light',
                                    marginLeft: 10,
                                    marginRight: 10,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Manage'}
                            </Text>
                            <Icon
                              color={theme.colors['Akurie green']}
                              name={'FontAwesome/gear'}
                              size={24}
                            />
                          </View>
                        </Touchable>
                      </View>

                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: {
                              minWidth: Breakpoints.Laptop,
                              value: 'row',
                            },
                            marginTop: {
                              minWidth: Breakpoints.BigScreen,
                              value: 20,
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Image
                          resizeMode={'cover'}
                          source={Images.AdobeStock345059232}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ImageStyles(theme)['Image'],
                              {
                                height: [
                                  { minWidth: Breakpoints.Laptop, value: 120 },
                                  { minWidth: Breakpoints.Desktop, value: 150 },
                                ],
                                marginRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                width: [
                                  { minWidth: Breakpoints.Laptop, value: 120 },
                                  { minWidth: Breakpoints.Desktop, value: 150 },
                                ],
                              }
                            ),
                            dimensions.width
                          )}
                        />
                        {/* View 3 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'row',
                              justifyContent: 'center',
                            },
                            dimensions.width
                          )}
                        >
                          <BusinessDataAkurieApi.FetchGetBusinessGET
                            business_id={Constants['ak_businessID']}
                          >
                            {({ loading, error, data, refetchGetBusiness }) => {
                              const fetchData = data?.json;
                              if (loading) {
                                return <ActivityIndicator />;
                              }

                              if (
                                error ||
                                data?.status < 200 ||
                                data?.status >= 300
                              ) {
                                return <ActivityIndicator />;
                              }

                              return (
                                <>
                                  {!Constants['ak_businessID'] ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'flex-start',
                                          flexDirection: 'column',
                                          flexWrap: 'wrap',
                                          justifyContent: 'space-between',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Greet */}
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          {
                                            color:
                                              theme.colors['Akurie Dark Blue'],
                                            fontFamily: 'Inter_200ExtraLight',
                                            fontSize: [
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: 14,
                                              },
                                              {
                                                minWidth: Breakpoints.Tablet,
                                                value: 16,
                                              },
                                            ],
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {'Current Business:'}
                                      </Text>
                                      {/* Business Name */}
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignSelf: 'stretch',
                                            color: theme.colors['Strong'],
                                            fontFamily: [
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: 'Inter_300Light',
                                              },
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value: 'Inter_600SemiBold',
                                              },
                                            ],
                                            fontSize: [
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value: 20,
                                              },
                                              {
                                                minWidth: Breakpoints.Tablet,
                                                value: 26,
                                              },
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: 24,
                                              },
                                            ],
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {fetchData?.user_business_name}
                                      </Text>
                                      {/* Sub Info */}
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          {
                                            color: theme.colors['Strong'],
                                            fontFamily: [
                                              {
                                                minWidth: Breakpoints.BigScreen,
                                                value: 'Inter_600SemiBold',
                                              },
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: 'Inter_400Regular',
                                              },
                                            ],
                                            fontSize: [
                                              {
                                                minWidth: Breakpoints.BigScreen,
                                                value: 22,
                                              },
                                              {
                                                minWidth: Breakpoints.Tablet,
                                                value: 14,
                                              },
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: 12,
                                              },
                                            ],
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {'ID: '}
                                        {fetchData?.id}
                                        {' - '}
                                        {fetchData?.state_formed_in}
                                        {' - '}
                                        {fetchData?.entity_type}
                                      </Text>
                                    </View>
                                  )}
                                </>
                              );
                            }}
                          </BusinessDataAkurieApi.FetchGetBusinessGET>
                        </View>
                      </View>
                    </View>
                  </View>
                  {/* Stats View */}
                  <>
                    {!Constants['ak_bankdataPresent'] ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { marginTop: 35 },
                          dimensions.width
                        )}
                      >
                        <>
                          {!Constants['ak_bankdataPresent'] ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor:
                                    theme.colors['Akurie Dark Blue'],
                                  borderRadius: 0,
                                  borderTopLeftRadius: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 12,
                                  },
                                  borderTopRightRadius: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 12,
                                  },
                                  height: 100,
                                  justifyContent: 'center',
                                  opacity: [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: 0.8,
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 0.9,
                                    },
                                  ],
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                            />
                          )}
                        </>
                        {/* Fetch Revenue */}
                        <>
                          {!Constants['ak_bankdataPresent'] ? null : (
                            <PlaidAuthAkurieApi.FetchMonthlyTransactions$TotalsGET
                              User_business_ID={Constants['ak_businessID']}
                              handlers={{
                                on4xx: fetchRevenueData => {
                                  try {
                                    setGlobalVariableValue({
                                      key: 'ak_isLoading',
                                      value: false,
                                    });
                                  } catch (err) {
                                    console.error(err);
                                  }
                                },
                                on5xx: fetchRevenueData => {
                                  try {
                                    setGlobalVariableValue({
                                      key: 'ak_isLoading',
                                      value: false,
                                    });
                                  } catch (err) {
                                    console.error(err);
                                  }
                                },
                                onData: fetchRevenueData => {
                                  try {
                                    if (
                                      Constants['ak_bankdataPresent'] === true
                                    ) {
                                      setGlobalVariableValue({
                                        key: 'ak_isLoading',
                                        value: false,
                                      });
                                    }
                                  } catch (err) {
                                    console.error(err);
                                  }
                                },
                              }}
                            >
                              {({
                                loading,
                                error,
                                data,
                                refetchMonthlyTransactions$Totals,
                              }) => {
                                const fetchRevenueData = data?.json;
                                if (loading) {
                                  return <ActivityIndicator />;
                                }

                                if (
                                  error ||
                                  data?.status < 200 ||
                                  data?.status >= 300
                                ) {
                                  return <ActivityIndicator />;
                                }

                                return (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        borderRadius: 16,
                                        flexDirection: 'row',
                                        height: 100,
                                        justifyContent: 'center',
                                        left: 0,
                                        marginLeft: 16,
                                        marginRight: 16,
                                        opacity: 1,
                                        position: 'absolute',
                                        right: 0,
                                        top: 0,
                                        zIndex: 1,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          flex: 1,
                                          flexDirection: 'row',
                                          justifyContent: 'center',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Divider
                                        color={theme.colors['Akurie green']}
                                        style={StyleSheet.applyWidth(
                                          {
                                            height: 40,
                                            position: 'absolute',
                                            right: 0,
                                            width: 1,
                                          },
                                          dimensions.width
                                        )}
                                      />
                                      <Touchable
                                        onPress={() => {
                                          try {
                                            /* Navigate action skipped because Spending Overview is hidden */ setGlobalVariableValue(
                                              {
                                                key: 'ak_isLoading',
                                                value: true,
                                              }
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                      >
                                        {/* This Month Revenue */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              flexDirection: 'column',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              {
                                                color:
                                                  theme.colors[
                                                    'Custom Color_2'
                                                  ],
                                                fontFamily: 'Inter_400Regular',
                                                fontSize: {
                                                  minWidth:
                                                    Breakpoints.BigScreen,
                                                  value: 14,
                                                },
                                                textAlign: 'center',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {"This Month's\nRevenue"}
                                          </Text>

                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignContent: 'center',
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                                marginTop: 8,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color:
                                                    theme.colors[
                                                      'Custom Color_2'
                                                    ],
                                                  fontFamily: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value:
                                                        'Inter_600SemiBold',
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: 'Inter_500Medium',
                                                    },
                                                  ],
                                                  fontSize: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: 20,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value: 16,
                                                    },
                                                  ],
                                                  marginLeft: 5,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {'$'}
                                            </Text>

                                            <Text
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color:
                                                    theme.colors[
                                                      'Custom Color_2'
                                                    ],
                                                  fontFamily: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value:
                                                        'Inter_600SemiBold',
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: 'Inter_500Medium',
                                                    },
                                                  ],
                                                  fontSize: 16,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {fetchRevenueData?.inflowResult}
                                            </Text>
                                          </View>
                                        </View>
                                      </Touchable>
                                    </View>

                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          flex: 1,
                                          flexDirection: 'row',
                                          justifyContent: 'center',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Divider 2 */}
                                      <Divider
                                        color={theme.colors['Akurie green']}
                                        style={StyleSheet.applyWidth(
                                          {
                                            height: 40,
                                            position: 'absolute',
                                            right: 0,
                                            width: 1,
                                          },
                                          dimensions.width
                                        )}
                                      />
                                      <Touchable
                                        onPress={() => {
                                          try {
                                            /* Navigate action skipped because Spending Overview is hidden */ setGlobalVariableValue(
                                              {
                                                key: 'ak_isLoading',
                                                value: true,
                                              }
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                      >
                                        {/* This Month Spending */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            { alignItems: 'center' },
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignSelf: 'center',
                                                color:
                                                  theme.colors[
                                                    'Custom Color_2'
                                                  ],
                                                fontFamily: 'Inter_400Regular',
                                                textAlign: 'center',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {"This Month's\nSpending"}
                                          </Text>

                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignContent: 'center',
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                marginTop: 8,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color:
                                                    theme.colors[
                                                      'Custom Color_2'
                                                    ],
                                                  fontFamily: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value:
                                                        'Inter_600SemiBold',
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: 'Inter_500Medium',
                                                    },
                                                  ],
                                                  fontSize: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: 20,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value: 16,
                                                    },
                                                  ],
                                                  marginLeft: 5,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {'$'}
                                            </Text>

                                            <Text
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color:
                                                    theme.colors[
                                                      'Custom Color_2'
                                                    ],
                                                  fontFamily: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value:
                                                        'Inter_600SemiBold',
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: 'Inter_500Medium',
                                                    },
                                                  ],
                                                  fontSize: 16,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {fetchRevenueData?.outflowResult}
                                            </Text>
                                          </View>
                                        </View>
                                      </Touchable>
                                    </View>
                                    {/* View 2 */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          flex: 1,
                                          flexDirection: 'row',
                                          justifyContent: 'center',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Touchable
                                        onPress={() => {
                                          try {
                                            /* Navigate action skipped because Spending Overview is hidden */ setGlobalVariableValue(
                                              {
                                                key: 'ak_isLoading',
                                                value: true,
                                              }
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                      >
                                        {/* This Month Profit */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            { alignItems: 'center' },
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignSelf: 'center',
                                                color:
                                                  theme.colors[
                                                    'Custom Color_2'
                                                  ],
                                                fontFamily: 'Inter_400Regular',
                                                textAlign: 'center',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {"This Month's\nProfit"}
                                          </Text>

                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignContent: 'center',
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                marginTop: 8,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color:
                                                    theme.colors[
                                                      'Custom Color_2'
                                                    ],
                                                  fontFamily: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value:
                                                        'Inter_600SemiBold',
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: 'Inter_500Medium',
                                                    },
                                                  ],
                                                  fontSize: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: 20,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value: 16,
                                                    },
                                                  ],
                                                  marginLeft: 5,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {'$'}
                                            </Text>

                                            <Text
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color:
                                                    theme.colors[
                                                      'Custom Color_2'
                                                    ],
                                                  fontFamily: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value:
                                                        'Inter_600SemiBold',
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: 'Inter_500Medium',
                                                    },
                                                  ],
                                                  fontSize: 16,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {fetchRevenueData?.profitResult}
                                            </Text>
                                          </View>
                                        </View>
                                      </Touchable>
                                    </View>
                                  </View>
                                );
                              }}
                            </PlaidAuthAkurieApi.FetchMonthlyTransactions$TotalsGET>
                          )}
                        </>
                      </View>
                    )}
                  </>
                  {/* Filings */}
                  <>
                    {Constants['ak_subscriptionType'] ===
                    'Akurie Free' ? null : (
                      <Filings$DocsApi.FetchGetFilings$NumbersGET
                        bus_id={Constants['ak_businessID']}
                      >
                        {({
                          loading,
                          error,
                          data,
                          refetchGetFilings$Numbers,
                        }) => {
                          const filingsData = data?.json;
                          if (loading) {
                            return <ActivityIndicator />;
                          }

                          if (
                            error ||
                            data?.status < 200 ||
                            data?.status >= 300
                          ) {
                            return <ActivityIndicator />;
                          }

                          return (
                            <>
                              {!Constants['ak_businessStatus'] ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      backgroundColor:
                                        theme.colors['Akurie Dark Blue'],
                                      borderBottomLeftRadius: [
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 12,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 12,
                                        },
                                      ],
                                      borderBottomRightRadius: [
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 12,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 12,
                                        },
                                      ],
                                      borderColor: theme.colors['Akurie green'],
                                      borderTopWidth: 1,
                                      flexDirection: 'row',
                                      justifyContent: 'center',
                                      paddingBottom: 20,
                                      paddingLeft: 40,
                                      paddingRight: 40,
                                      paddingTop: 20,
                                      width: '100%',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Touchable
                                    onPress={() => {
                                      try {
                                        navigation.navigate(
                                          'MyFilingsReportsScreen'
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    style={StyleSheet.applyWidth(
                                      { marginRight: '6%' },
                                      dimensions.width
                                    )}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          flexDirection: 'column',
                                          justifyContent: 'center',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          {
                                            color:
                                              theme.colors['Custom Color_2'],
                                            fontFamily: 'Inter_400Regular',
                                            textAlign: 'center',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {'Upcoming Filings'}
                                      </Text>

                                      <Text
                                        style={StyleSheet.applyWidth(
                                          {
                                            color:
                                              theme.colors['Custom Color_2'],
                                            fontFamily: 'Inter_600SemiBold',
                                            fontSize: 20,
                                            marginTop: 5,
                                            textAlign: 'center',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {filingsData?.Number_Of_Filings}
                                      </Text>
                                    </View>
                                  </Touchable>
                                  <Divider
                                    color={theme.colors['Akurie green']}
                                    style={StyleSheet.applyWidth(
                                      { height: 40, width: 1 },
                                      dimensions.width
                                    )}
                                  />
                                  <Touchable
                                    onPress={() => {
                                      try {
                                        navigation.navigate(
                                          'MyFilingsReportsScreen'
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    style={StyleSheet.applyWidth(
                                      { marginLeft: '5%' },
                                      dimensions.width
                                    )}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          flexDirection: 'column',
                                          justifyContent: 'center',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          {
                                            color:
                                              theme.colors['Custom Color_2'],
                                            fontFamily: 'Inter_400Regular',
                                            textAlign: 'center',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {'Completed Filings'}
                                      </Text>

                                      <Text
                                        style={StyleSheet.applyWidth(
                                          {
                                            color:
                                              theme.colors['Custom Color_2'],
                                            fontFamily: 'Inter_600SemiBold',
                                            fontSize: 20,
                                            marginTop: 5,
                                            textAlign: 'center',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {filingsData?.Number_Of_Filed_Docs}
                                      </Text>
                                    </View>
                                  </Touchable>
                                </View>
                              )}
                            </>
                          );
                        }}
                      </Filings$DocsApi.FetchGetFilings$NumbersGET>
                    )}
                  </>
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'space-evenly',
                        },
                        marginTop: { minWidth: Breakpoints.Laptop, value: 40 },
                        width: { minWidth: Breakpoints.Laptop, value: '100%' },
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('NetworkingIDPassScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <Surface
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.SurfaceStyles(theme)['Surface'],
                            {
                              alignItems: {
                                minWidth: Breakpoints.Laptop,
                                value: 'center',
                              },
                              backgroundColor: {
                                minWidth: Breakpoints.Laptop,
                                value: theme.colors['Akurie Dark Blue'],
                              },
                              borderRadius: {
                                minWidth: Breakpoints.Laptop,
                                value: 9,
                              },
                              height: {
                                minWidth: Breakpoints.Laptop,
                                value: 180,
                              },
                              justifyContent: {
                                minWidth: Breakpoints.Laptop,
                                value: 'center',
                              },
                              width: [
                                { minWidth: Breakpoints.Desktop, value: 200 },
                                { minWidth: Breakpoints.Laptop, value: 180 },
                              ],
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        <Image
                          resizeMode={'contain'}
                          source={Images.Asset30}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ImageStyles(theme)['Image'],
                              {
                                height: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 100,
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        />
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Akurie green'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Inter_400Regular',
                                },
                                marginTop: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textAlign: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'My Akurie Networking\n& Event Pass'}
                        </Text>
                      </Surface>
                    </Touchable>
                    {/* Touchable 2 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('AskMyAssistantScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {/* Surface 2 */}
                      <Surface
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.SurfaceStyles(theme)['Surface'],
                            {
                              alignItems: {
                                minWidth: Breakpoints.Laptop,
                                value: 'center',
                              },
                              backgroundColor: {
                                minWidth: Breakpoints.Laptop,
                                value: theme.colors['Akurie Dark Blue'],
                              },
                              borderRadius: {
                                minWidth: Breakpoints.Laptop,
                                value: 9,
                              },
                              height: {
                                minWidth: Breakpoints.Laptop,
                                value: 180,
                              },
                              justifyContent: {
                                minWidth: Breakpoints.Laptop,
                                value: 'center',
                              },
                              width: {
                                minWidth: Breakpoints.Laptop,
                                value: 180,
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        <Image
                          resizeMode={'contain'}
                          source={Images.Asset900}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ImageStyles(theme)['Image'],
                              {
                                height: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 100,
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        />
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Akurie green'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Inter_400Regular',
                                },
                                marginTop: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textAlign: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Chat With\nAkurie Assistant'}
                        </Text>
                      </Surface>
                    </Touchable>
                    {/* Touchable 3 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('ResourceCenterScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {/* Surface 3 */}
                      <Surface
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.SurfaceStyles(theme)['Surface'],
                            {
                              alignItems: {
                                minWidth: Breakpoints.Laptop,
                                value: 'center',
                              },
                              backgroundColor: {
                                minWidth: Breakpoints.Laptop,
                                value: theme.colors['Akurie Dark Blue'],
                              },
                              borderRadius: {
                                minWidth: Breakpoints.Laptop,
                                value: 9,
                              },
                              height: {
                                minWidth: Breakpoints.Laptop,
                                value: 180,
                              },
                              justifyContent: {
                                minWidth: Breakpoints.Laptop,
                                value: 'center',
                              },
                              width: {
                                minWidth: Breakpoints.Laptop,
                                value: 180,
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        <Image
                          resizeMode={'contain'}
                          source={Images.Asset16}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ImageStyles(theme)['Image'],
                              {
                                height: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 100,
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        />
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Akurie green'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Inter_400Regular',
                                },
                                marginTop: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textAlign: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Financial Resources'}
                        </Text>
                      </Surface>
                    </Touchable>
                  </View>
                </View>
              </View>
            </ScrollView>
          )}
        </>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(UserDashboardScreen);
