import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as Logs$SupportAkurieApi from '../apis/Logs$SupportAkurieApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openImagePickerUtil from '../utils/openImagePicker';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  NumberInput,
  Picker,
  ScreenContainer,
  Surface,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Image, ImageBackground, StatusBar, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const HelpSupportScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [Submission_message, setSubmission_message] = React.useState(false);
  const [callOptionPickerValue, setCallOptionPickerValue] = React.useState('');
  const [call_option, setCall_option] = React.useState(['Yes', 'No']);
  const [image_upload, setImage_upload] = React.useState('');
  const [issuePickerValue, setIssuePickerValue] = React.useState('');
  const [issue_options, setIssue_options] = React.useState([
    'App Crashing / Freezing',
    'Information Not Visible',
    'Link / Button Not Working',
    'Incorrect Data',
    'Other',
  ]);
  const [notesInputValue, setNotesInputValue] = React.useState('');
  const [numberInputValue, setNumberInputValue] = React.useState('');
  const [numberInputValue2, setNumberInputValue2] = React.useState('');
  const [phoneNumberInputValue, setPhoneNumberInputValue] = React.useState('');
  const [pickerValue, setPickerValue] = React.useState('');
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const [uploaded_image, setUploaded_image] = React.useState('');
  const logs$SupportAkurieBasicActivityPOST =
    Logs$SupportAkurieApi.useBasicActivityPOST();
  const logs$SupportAkurieCreateSupportTicketPOST =
    Logs$SupportAkurieApi.useCreateSupportTicketPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        (
          await logs$SupportAkurieBasicActivityPOST.mutateAsync({
            buttonName: 'Help & Support',
            session_id: Constants['Session_ID'],
          })
        )?.json;
        setUploaded_image('');
        StatusBar.setBarStyle('dark-content');
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer hasTopSafeArea={true}>
      {/* site header update 12-8 */}
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <View
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ViewStyles(theme)[
                  'Akurie Header Desktop Header 9'
                ],
                {
                  borderBottomWidth: {
                    minWidth: Breakpoints.Laptop,
                    value: 0.5,
                  },
                  borderColor: {
                    minWidth: Breakpoints.Laptop,
                    value: theme.colors['Akurie green'],
                  },
                  height: { minWidth: Breakpoints.Laptop, value: 119.6 },
                  justifyContent: {
                    minWidth: Breakpoints.Laptop,
                    value: 'center',
                  },
                  paddingLeft: { minWidth: Breakpoints.Laptop, value: '0%' },
                  paddingRight: { minWidth: Breakpoints.Laptop, value: '0%' },
                  width: {
                    minWidth: Breakpoints.Laptop,
                    value: dimensions.width,
                  },
                }
              ),
              dimensions.width
            )}
          >
            {/* View 4 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                  flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
                  height: { minWidth: Breakpoints.Laptop, value: 68.48 },
                  justifyContent: {
                    minWidth: Breakpoints.Laptop,
                    value: 'space-between',
                  },
                  width: { minWidth: Breakpoints.Laptop, value: '74%' },
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    height: { minWidth: Breakpoints.Laptop, value: '100%' },
                    width: { minWidth: Breakpoints.Laptop, value: '16.93%' },
                  },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'contain'}
                  source={Images.MainLogoUpdate1u2}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'],
                      {
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        width: { minWidth: Breakpoints.Laptop, value: '100%' },
                      }
                    ),
                    dimensions.width
                  )}
                />
              </View>
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                    flexDirection: {
                      minWidth: Breakpoints.Laptop,
                      value: 'row',
                    },
                    height: { minWidth: Breakpoints.Laptop, value: '80%' },
                    justifyContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'space-evenly',
                    },
                    marginRight: { minWidth: Breakpoints.Desktop, value: 0 },
                    width: { minWidth: Breakpoints.Laptop, value: '55.9%' },
                  },
                  dimensions.width
                )}
              >
                <Touchable
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: {
                        minWidth: Breakpoints.Laptop,
                        value: 3,
                      },
                      borderColor: {
                        minWidth: Breakpoints.Laptop,
                        value: theme.colors['Akurie green'],
                      },
                      height: { minWidth: Breakpoints.Laptop, value: '100%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['Akurie green'],
                            },
                            fontFamily: {
                              minWidth: Breakpoints.Laptop,
                              value: 'Inter_500Medium',
                            },
                            fontSize: {
                              minWidth: Breakpoints.Laptop,
                              value: 14,
                            },
                            paddingLeft: {
                              minWidth: Breakpoints.Desktop,
                              value: 10,
                            },
                            paddingRight: {
                              minWidth: Breakpoints.Desktop,
                              value: 10,
                            },
                            textAlign: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Dashboard'}
                    </Text>
                  </View>
                </Touchable>
                {/* Touchable 2 */}
                <Touchable
                  style={StyleSheet.applyWidth(
                    { height: { minWidth: Breakpoints.Laptop, value: '100%' } },
                    dimensions.width
                  )}
                >
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['Strong Inverse'],
                            },
                            fontFamily: {
                              minWidth: Breakpoints.Laptop,
                              value: 'Inter_500Medium',
                            },
                            fontSize: {
                              minWidth: Breakpoints.Laptop,
                              value: 14,
                            },
                            paddingLeft: {
                              minWidth: Breakpoints.Desktop,
                              value: 10,
                            },
                            paddingRight: {
                              minWidth: Breakpoints.Desktop,
                              value: 10,
                            },
                            textAlign: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Events'}
                    </Text>
                  </View>
                </Touchable>
                {/* Touchable 3 */}
                <Touchable
                  style={StyleSheet.applyWidth(
                    { height: { minWidth: Breakpoints.Laptop, value: '100%' } },
                    dimensions.width
                  )}
                >
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['Strong Inverse'],
                            },
                            fontFamily: {
                              minWidth: Breakpoints.Laptop,
                              value: 'Inter_500Medium',
                            },
                            fontSize: {
                              minWidth: Breakpoints.Laptop,
                              value: 14,
                            },
                            paddingLeft: {
                              minWidth: Breakpoints.Desktop,
                              value: 10,
                            },
                            paddingRight: {
                              minWidth: Breakpoints.Desktop,
                              value: 10,
                            },
                            textAlign: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Business Info'}
                    </Text>
                  </View>
                </Touchable>
                {/* Touchable 4 */}
                <Touchable
                  style={StyleSheet.applyWidth(
                    { height: { minWidth: Breakpoints.Laptop, value: '100%' } },
                    dimensions.width
                  )}
                >
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['Strong Inverse'],
                            },
                            fontFamily: {
                              minWidth: Breakpoints.Laptop,
                              value: 'Inter_500Medium',
                            },
                            fontSize: {
                              minWidth: Breakpoints.Laptop,
                              value: 14,
                            },
                            paddingLeft: {
                              minWidth: Breakpoints.Desktop,
                              value: 10,
                            },
                            paddingRight: {
                              minWidth: Breakpoints.Desktop,
                              value: 10,
                            },
                            textAlign: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Financials'}
                    </Text>
                  </View>
                </Touchable>
                {/* Touchable 5 */}
                <Touchable
                  style={StyleSheet.applyWidth(
                    { height: { minWidth: Breakpoints.Laptop, value: '100%' } },
                    dimensions.width
                  )}
                >
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['Strong Inverse'],
                            },
                            fontFamily: {
                              minWidth: Breakpoints.Laptop,
                              value: 'Inter_500Medium',
                            },
                            fontSize: {
                              minWidth: Breakpoints.Laptop,
                              value: 14,
                            },
                            paddingLeft: {
                              minWidth: Breakpoints.Desktop,
                              value: 10,
                            },
                            paddingRight: {
                              minWidth: Breakpoints.Desktop,
                              value: 10,
                            },
                            textAlign: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Docs & Filing'}
                    </Text>
                  </View>
                </Touchable>
              </View>
              {/* View 3 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: [
                      { minWidth: Breakpoints.BigScreen, value: 'center' },
                      { minWidth: Breakpoints.Desktop, value: 'center' },
                    ],
                    flexDirection: [
                      { minWidth: Breakpoints.BigScreen, value: 'row' },
                      { minWidth: Breakpoints.Desktop, value: 'row' },
                      { minWidth: Breakpoints.Laptop, value: 'row' },
                    ],
                    justifyContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'flex-end',
                    },
                    minHeight: { minWidth: Breakpoints.Laptop, value: 45 },
                    width: { minWidth: Breakpoints.Laptop, value: '27.06%' },
                  },
                  dimensions.width
                )}
              >
                {/* Button 3 */}
                <Button
                  onPress={() => {
                    try {
                      navigation.navigate('DesktopMyAccountScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'],
                      {
                        backgroundColor: {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors['Akurie green'],
                        },
                        borderRadius: {
                          minWidth: Breakpoints.Laptop,
                          value: 9,
                        },
                        color: {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors['Akurie Dark Blue'],
                        },
                        fontFamily: [
                          {
                            minWidth: Breakpoints.Laptop,
                            value: 'Inter_400Regular',
                          },
                          {
                            minWidth: Breakpoints.BigScreen,
                            value: 'Inter_400Regular',
                          },
                        ],
                        fontSize: { minWidth: Breakpoints.Laptop, value: 13 },
                        width: { minWidth: Breakpoints.Laptop, value: '50%' },
                      }
                    ),
                    dimensions.width
                  )}
                  title={'My Account'}
                />
              </View>
            </View>
          </View>
        )}
      </>
      {/* Navigation Frame */}
      <View
        style={StyleSheet.applyWidth(
          {
            borderBottomWidth: 2,
            borderColor: theme.colors['Akurie green'],
            height: [
              { minWidth: Breakpoints.Mobile, value: 120 },
              { minWidth: Breakpoints.Laptop, value: 60 },
            ],
            marginTop: { minWidth: Breakpoints.Laptop, value: 20 },
          },
          dimensions.width
        )}
      >
        <ImageBackground
          resizeMode={'contain'}
          source={Images.Asset13}
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              backgroundColor: theme.colors['Akurie Dark Blue'],
              flexDirection: 'row',
              height: '100%',
            },
            dimensions.width
          )}
        >
          {/* Left Side Frame */}
          <>
            {dimensions.width >= Breakpoints.Laptop ? null : (
              <View
                style={StyleSheet.applyWidth(
                  { paddingLeft: 9, paddingRight: 9 },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    try {
                      setSubmission_message(false);
                      navigation.goBack();
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* Icon Flex Frame */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexGrow: 0,
                        flexShrink: 0,
                        paddingBottom: 12,
                        paddingLeft: 12,
                        paddingRight: 12,
                        paddingTop: 12,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Icon  */}
                    <Icon
                      color={theme.colors['Background']}
                      name={'Ionicons/arrow-back-outline'}
                      size={24}
                    />
                  </View>
                </Touchable>
              </View>
            )}
          </>
          {/* Middle Frame */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: [
                  { minWidth: Breakpoints.Mobile, value: 'flex-start' },
                  { minWidth: Breakpoints.Laptop, value: 'center' },
                  { minWidth: Breakpoints.Tablet, value: 'center' },
                ],
                backgroundColor: 'rgba(8, 31, 61, 0.4)',
                flexGrow: 1,
                flexShrink: 0,
                justifyContent: 'center',
              },
              dimensions.width
            )}
          >
            {/* Text Frame  */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexGrow: 0,
                  flexShrink: 0,
                  justifyContent: 'center',
                  paddingBottom: 12,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 12,
                },
                dimensions.width
              )}
            >
              {/* Text 12 18 Regular */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Akurie green'],
                    fontFamily: 'Inter_400Regular',
                    fontSize: 22,
                  },
                  dimensions.width
                )}
              >
                {'Help & Support Center'}
              </Text>
            </View>
          </View>
          {/* RIght Side Frame */}
          <>
            {dimensions.width >= Breakpoints.Laptop ? null : (
              <View
                style={StyleSheet.applyWidth(
                  { paddingLeft: 9, paddingRight: 9 },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    try {
                      navigation.navigate('UserDashboardScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* Icon Flex Frame */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexGrow: 0,
                        flexShrink: 0,
                        paddingBottom: 12,
                        paddingLeft: 12,
                        paddingRight: 12,
                        paddingTop: 12,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Icon  */}
                    <Icon
                      color={theme.colors['Background']}
                      name={'AntDesign/home'}
                      size={24}
                    />
                  </View>
                </Touchable>
              </View>
            )}
          </>
        </ImageBackground>
      </View>

      <KeyboardAwareScrollView
        extraHeight={500}
        keyboardShouldPersistTaps={'never'}
        showsVerticalScrollIndicator={true}
      >
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: { minWidth: Breakpoints.Tablet, value: 'center' },
              height: [
                { minWidth: Breakpoints.Mobile, value: '100%' },
                { minWidth: Breakpoints.Tablet, value: '100%' },
              ],
              justifyContent: 'center',
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                height: '100%',
                justifyContent: 'center',
                paddingBottom: 10,
                paddingLeft: [
                  { minWidth: Breakpoints.Mobile, value: 35 },
                  { minWidth: Breakpoints.Laptop, value: '10%' },
                ],
                paddingRight: [
                  { minWidth: Breakpoints.Mobile, value: 35 },
                  { minWidth: Breakpoints.Laptop, value: '10%' },
                ],
                paddingTop: 10,
                width: [
                  { minWidth: Breakpoints.Mobile, value: '100%' },
                  { minWidth: Breakpoints.Tablet, value: '80%' },
                  { minWidth: Breakpoints.Desktop, value: '60%' },
                ],
              },
              dimensions.width
            )}
          >
            {/* Top */}
            <View
              style={StyleSheet.applyWidth(
                {
                  marginTop: 40,
                  width: { minWidth: Breakpoints.Laptop, value: '100%' },
                },
                dimensions.width
              )}
            >
              {/* Content Wrapper */}
              <View>
                {/* Issue Type */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Custom #101010'],
                      fontFamily: 'Inter_500Medium',
                      fontSize: 14,
                      marginTop: 10,
                    },
                    dimensions.width
                  )}
                >
                  {'Issue Type:'}
                </Text>
                {/* Required */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Custom #101010'],
                      fontFamily: 'Inter_200ExtraLight',
                      fontSize: 12,
                      marginBottom: 3,
                    },
                    dimensions.width
                  )}
                >
                  {'Required*'}
                </Text>
                {/* Issue */}
                <Surface
                  elevation={3}
                  style={StyleSheet.applyWidth(
                    { borderRadius: 9, marginBottom: 8 },
                    dimensions.width
                  )}
                >
                  {/* Issue Picker */}
                  <Picker
                    iconColor={theme.colors['Akurie Primary']}
                    iconSize={24}
                    leftIconMode={'inset'}
                    onValueChange={newIssuePickerValue => {
                      try {
                        setIssuePickerValue(newIssuePickerValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    options={issue_options}
                    placeholder={'Select an option'}
                    rightIconName={'AntDesign/downcircle'}
                    style={StyleSheet.applyWidth(
                      {
                        borderColor: theme.colors['Divider'],
                        color: theme.colors['Strong'],
                        fontFamily: 'Inter_400Regular',
                        paddingRight: 8,
                      },
                      dimensions.width
                    )}
                    type={'solid'}
                    value={issuePickerValue}
                  />
                </Surface>
                {/* Would you like a call */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Custom #101010'],
                      fontFamily: 'Inter_500Medium',
                      fontSize: 14,
                      marginBottom: 3,
                      paddingTop: 4,
                    },
                    dimensions.width
                  )}
                >
                  {'Would you like to request a call?'}
                </Text>
                {/* Call Wanted */}
                <Surface
                  elevation={3}
                  style={StyleSheet.applyWidth(
                    { borderRadius: 9, marginBottom: 12 },
                    dimensions.width
                  )}
                >
                  {/* Call Option Picker */}
                  <Picker
                    iconColor={theme.colors['Akurie Primary']}
                    iconSize={24}
                    leftIconMode={'inset'}
                    onValueChange={newCallOptionPickerValue => {
                      try {
                        setCallOptionPickerValue(newCallOptionPickerValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    options={call_option}
                    placeholder={'Select an option'}
                    rightIconName={'AntDesign/downcircle'}
                    style={StyleSheet.applyWidth(
                      {
                        borderColor: theme.colors['Divider'],
                        color: theme.colors['Strong'],
                        fontFamily: 'Inter_400Regular',
                        paddingRight: 8,
                      },
                      dimensions.width
                    )}
                    type={'solid'}
                    value={callOptionPickerValue}
                  />
                </Surface>
                {/* Phone Number */}
                <>
                  {!(callOptionPickerValue === 'Yes') ? null : (
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Custom #101010'],
                          fontFamily: 'Inter_400Regular',
                          fontSize: 14,
                          marginBottom: 3,
                          paddingTop: 4,
                        },
                        dimensions.width
                      )}
                    >
                      {'What number would you like us to call?'}
                    </Text>
                  )}
                </>
                {/* Phone Number */}
                <>
                  {!(callOptionPickerValue === 'Yes') ? null : (
                    <Surface
                      elevation={3}
                      style={StyleSheet.applyWidth(
                        { borderRadius: 9, marginBottom: 12 },
                        dimensions.width
                      )}
                    >
                      {/* Phone Number Input */}
                      <NumberInput
                        editable={true}
                        keyboardType={'number-pad'}
                        onChangeText={newPhoneNumberInputValue => {
                          try {
                            setPhoneNumberInputValue(newPhoneNumberInputValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        placeholder={'###-###-####'}
                        style={StyleSheet.applyWidth(
                          {
                            borderBottomWidth: 1,
                            borderColor: theme.colors['Communial_Icon_Gray'],
                            borderLeftWidth: 1,
                            borderRadius: 8,
                            borderRightWidth: 1,
                            borderTopWidth: 1,
                            fontFamily: 'Inter_400Regular',
                            minHeight: 60,
                            paddingBottom: 8,
                            paddingLeft: 12,
                            paddingRight: 8,
                            paddingTop: 8,
                          },
                          dimensions.width
                        )}
                        value={phoneNumberInputValue}
                      />
                    </Surface>
                  )}
                </>
                {/* Screenshot of Issue  */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Custom #101010'],
                      fontFamily: 'Inter_500Medium',
                      fontSize: 14,
                      marginBottom: 3,
                      paddingTop: 4,
                    },
                    dimensions.width
                  )}
                >
                  {'Screenshot Of Issue'}
                </Text>

                <Touchable
                  onPress={() => {
                    const handler = async () => {
                      console.log('Touchable ON_PRESS Start');
                      let error = null;
                      try {
                        console.log('Start ON_PRESS:0 OPEN_IMAGE_PICKER');
                        const imageUpload = await openImagePickerUtil({
                          mediaTypes: 'Images',
                          allowsEditing: true,
                          quality: 0.2,
                          allowsMultipleSelection: false,
                        });
                        console.log('Complete ON_PRESS:0 OPEN_IMAGE_PICKER', {
                          imageUpload,
                        });
                        console.log('Start ON_PRESS:1 SET_VARIABLE');
                        setImage_upload(imageUpload);
                        console.log('Complete ON_PRESS:1 SET_VARIABLE');
                      } catch (err) {
                        console.error(err);
                        error = err.message ?? err;
                      }
                      console.log(
                        'Touchable ON_PRESS Complete',
                        error ? { error } : 'no error'
                      );
                    };
                    handler();
                  }}
                >
                  {/* Notes */}
                  <Surface
                    elevation={3}
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        borderRadius: 9,
                        flexDirection: 'row',
                        marginBottom: 12,
                        minHeight: 100,
                      },
                      dimensions.width
                    )}
                  >
                    {/* View 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        { height: '100%', width: '25%' },
                        dimensions.width
                      )}
                    >
                      <>
                        {image_upload ? null : (
                          <Image
                            resizeMode={'cover'}
                            source={Images.AdobeStock345059232}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ImageStyles(theme)['Image'],
                                { height: 90, width: 90 }
                              ),
                              dimensions.width
                            )}
                          />
                        )}
                      </>
                      {/* Image 2 */}
                      <>
                        {!image_upload ? null : (
                          <Image
                            resizeMode={'cover'}
                            source={{ uri: `${image_upload}` }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ImageStyles(theme)['Image'],
                                { height: 90, width: 90 }
                              ),
                              dimensions.width
                            )}
                          />
                        )}
                      </>
                    </View>

                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', width: '75%' },
                        dimensions.width
                      )}
                    >
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors.strong,
                            fontFamily: 'Inter_400Regular',
                            fontSize: 12,
                            textAlign: 'center',
                          },
                          dimensions.width
                        )}
                      >
                        {'Upload a screenshot of the issue.'}
                      </Text>
                    </View>
                  </Surface>
                </Touchable>

                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'flex-end' },
                    dimensions.width
                  )}
                >
                  <>
                    {!image_upload ? null : (
                      <Button
                        onPress={() => {
                          try {
                            setImage_upload(false);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ButtonStyles(theme)['Button'],
                            {
                              backgroundColor: theme.colors['Error'],
                              fontFamily: 'Inter_400Regular',
                            }
                          ),
                          dimensions.width
                        )}
                        title={'Clear'}
                      />
                    )}
                  </>
                </View>
                {/* Additional Notes */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Custom #101010'],
                      fontFamily: 'Inter_500Medium',
                      fontSize: 14,
                      paddingTop: 4,
                    },
                    dimensions.width
                  )}
                >
                  {'Short Description'}
                </Text>
                {/* Required */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Custom #101010'],
                      fontFamily: 'Inter_200ExtraLight',
                      fontSize: 12,
                      marginBottom: 3,
                    },
                    dimensions.width
                  )}
                >
                  {'Required*'}
                </Text>
                {/* Notes */}
                <Surface
                  elevation={3}
                  style={StyleSheet.applyWidth(
                    { borderRadius: 9, marginBottom: 3, minHeight: 80 },
                    dimensions.width
                  )}
                >
                  {/* Notes Input */}
                  <TextInput
                    editable={true}
                    multiline={true}
                    numberOfLines={2}
                    onChangeText={newNotesInputValue => {
                      try {
                        setNotesInputValue(newNotesInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={
                      'Please provide any information that could be helpful.'
                    }
                    style={StyleSheet.applyWidth(
                      {
                        borderColor: theme.colors['Communial_Icon_Gray'],
                        borderRadius: 8,
                        fontFamily: 'Inter_300Light',
                        height: 80,
                        paddingBottom: 5,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                      },
                      dimensions.width
                    )}
                    textAlignVertical={'top'}
                    value={notesInputValue}
                  />
                </Surface>
                <>
                  {Constants['ak_errorMessage'] ? null : (
                    <View>
                      {/* Text 2 */}
                      <>
                        {!Submission_message ? null : (
                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  color: theme.colors['Akurie Primary'],
                                  fontFamily: 'Inter_400Regular',
                                  marginTop: 10,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Your support ticket has been submitted.'}
                          </Text>
                        )}
                      </>
                    </View>
                  )}
                </>
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Error'],
                      fontFamily: 'Inter_400Regular',
                      marginTop: 10,
                    }),
                    dimensions.width
                  )}
                >
                  {Constants['ak_errorMessage']}
                </Text>
                <Button
                  onPress={() => {
                    const handler = async () => {
                      try {
                        const ticketResponse = (
                          await logs$SupportAkurieCreateSupportTicketPOST.mutateAsync(
                            {
                              File: image_upload,
                              Notes: notesInputValue,
                              call_requested: callOptionPickerValue,
                              contact_number: phoneNumberInputValue,
                              issue_problem: issuePickerValue,
                              user_business_basic_data_id:
                                Constants['ak_businessID'],
                              user_id: Constants['ak_userID'],
                            }
                          )
                        )?.json;
                        const errorMsg = ticketResponse?.message;
                        setGlobalVariableValue({
                          key: 'ak_errorMessage',
                          value: errorMsg,
                        });
                        setUploaded_image(false);
                        setIssuePickerValue('');
                        setCallOptionPickerValue(' ');
                        setPhoneNumberInputValue('');
                        setNotesInputValue('');
                        setSubmission_message(true);
                        navigation.navigate('RootNavigator');
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: theme.colors['Akurie green'],
                      borderRadius: 12,
                      color: theme.colors['Strong'],
                      fontFamily: 'Inter_700Bold',
                      fontSize: 16,
                      marginBottom: 20,
                      marginTop: 20,
                      minHeight: 50,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                  title={'Submit'}
                />
              </View>
            </View>
          </View>
        </View>
      </KeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(HelpSupportScreen);
