import React from 'react';
import * as AuthAkurieApi from '../apis/AuthAkurieApi.js';
import * as Logs$SupportAkurieApi from '../apis/Logs$SupportAkurieApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import getPushTokenUtil from '../utils/getPushToken';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Circle,
  Icon,
  Link,
  ScreenContainer,
  Spacer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Image, Platform, StatusBar, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const AllowNotificationsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [textInputValue, setTextInputValue] = React.useState('');
  const [textInputValue2, setTextInputValue2] = React.useState('');
  const logs$SupportAkurieBasicActivityPOST =
    Logs$SupportAkurieApi.useBasicActivityPOST();
  const authAkurieGetExpoPushTokenPOST =
    AuthAkurieApi.useGetExpoPushTokenPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      StatusBar.setBarStyle('light-content');
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors['Akurie Dark Blue'] },
        dimensions.width
      )}
    >
      <KeyboardAwareScrollView
        contentContainerStyle={StyleSheet.applyWidth(
          {
            flex: 1,
            justifyContent: 'center',
            paddingBottom: 36,
            paddingLeft: 36,
            paddingRight: 36,
            paddingTop: 36,
          },
          dimensions.width
        )}
        keyboardShouldPersistTaps={'never'}
        showsVerticalScrollIndicator={true}
      >
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center' },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center' },
              dimensions.width
            )}
          >
            <Image
              resizeMode={'contain'}
              source={Images.AdobeStock461741933Icon}
              style={StyleSheet.applyWidth(
                { height: 250, width: 300 },
                dimensions.width
              )}
            />
          </View>
          <Spacer bottom={24} left={8} right={8} top={24} />
          {/* Heading */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center' },
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Akurie Primary'],
                  fontFamily: 'Inter_400Regular',
                  fontSize: 32,
                  textDecorationLine: 'none',
                },
                dimensions.width
              )}
            >
              {'Notifications'}
            </Text>

            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Akurie green'],
                  fontFamily: 'Inter_300Light',
                  fontSize: 14,
                  marginTop: 8,
                  textAlign: 'center',
                },
                dimensions.width
              )}
            >
              {
                'Stay up-to-date with news about your business, local resources, and important networking events.'
              }
            </Text>
          </View>
          <Spacer bottom={24} left={8} right={8} top={24} />
          {/* Button */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: 'row', justifyContent: 'center', width: '80%' },
              dimensions.width
            )}
          >
            {/* Touchable Frame */}
            <View
              style={StyleSheet.applyWidth(
                { width: '100%', zIndex: 10 },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  const handler = async () => {
                    try {
                      (
                        await logs$SupportAkurieBasicActivityPOST.mutateAsync({
                          buttonName: 'Allow Notifications',
                          session_id: Constants['Session_ID'],
                        })
                      )?.json;
                      const checkResponse = (
                        await AuthAkurieApi.tokenCheckGET(Constants, {
                          user_id: props.route?.params?.idPass ?? '',
                        })
                      )?.json;
                      if (checkResponse?.message) {
                        navigation.navigate('PaywallScreen');
                      }
                      if (checkResponse?.message) {
                        return;
                      }
                      const pushResult = await getPushTokenUtil({
                        failMessage:
                          'Failed to get push token for push notification!',
                        deviceMessage:
                          'Must use physical device for Push Notifications',
                      });

                      (
                        await authAkurieGetExpoPushTokenPOST.mutateAsync({
                          pushToken: pushResult,
                          user_id: props.route?.params?.idPass ?? '',
                        })
                      )?.json;
                      navigation.navigate('PaywallProCurrentMonth2Screen');
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
              >
                {/* CTA Frame */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: theme.colors['Custom Color'],
                      borderRadius: 12,
                      flexDirection: 'row',
                      minHeight: 60,
                      paddingBottom: 12,
                      paddingLeft: 18,
                      paddingRight: 18,
                      paddingTop: 12,
                      zIndex: 10,
                    },
                    dimensions.width
                  )}
                >
                  {/* Label Frame */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        flexGrow: 1,
                        flexShrink: 0,
                        justifyContent: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {/* Label */}
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.studilyWhite3,
                          fontFamily: 'Inter_400Regular',
                          fontSize: 15,
                          lineHeight: 21,
                          textTransform: 'uppercase',
                        },
                        dimensions.width
                      )}
                    >
                      {'Allow'}
                    </Text>
                  </View>
                </View>
              </Touchable>
            </View>
          </View>
          <Spacer bottom={12} left={8} right={8} top={12} />
          {/* Resend */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center' },
              dimensions.width
            )}
          >
            <Link
              onPress={() => {
                const handler = async () => {
                  try {
                    (
                      await logs$SupportAkurieBasicActivityPOST.mutateAsync({
                        buttonName: 'Dont Allow Notifications',
                        session_id: Constants['Session_ID'],
                      })
                    )?.json;
                    navigation.navigate('PaywallProCurrentMonth2Screen');
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Strong Inverse'],
                  fontFamily: 'Inter_600SemiBold',
                  fontSize: 16,
                  marginTop: 8,
                  textDecorationLine: 'underline',
                },
                dimensions.width
              )}
              title={"DON'T ALLOW"}
            />
          </View>
        </View>
      </KeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(AllowNotificationsScreen);
