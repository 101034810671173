import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AuthAkurieApi from '../apis/AuthAkurieApi.js';
import * as BusinessDataAkurieApi from '../apis/BusinessDataAkurieApi.js';
import * as Logs$SupportAkurieApi from '../apis/Logs$SupportAkurieApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Icon,
  IconButton,
  Link,
  ScreenContainer,
  Surface,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Image, ImageBackground, Keyboard, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const ProfessionalServicesScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [BookkeepingServicesValue, setBookkeepingServicesValue] =
    React.useState('');
  const [ITServicesValue, setITServicesValue] = React.useState('');
  const [LegalServicesValue, setLegalServicesValue] = React.useState('');
  const [TaxServicesDescValue, setTaxServicesDescValue] = React.useState('');
  const [serviceFourView, setServiceFourView] = React.useState(false);
  const [serviceOneView, setServiceOneView] = React.useState(false);
  const [serviceThreeView, setServiceThreeView] = React.useState(false);
  const [serviceTwoView, setServiceTwoView] = React.useState(false);
  const [successMsg1, setSuccessMsg1] = React.useState(false);
  const [successMsg2, setSuccessMsg2] = React.useState(false);
  const [successMsg3, setSuccessMsg3] = React.useState(false);
  const [successMsg4, setSuccessMsg4] = React.useState(false);
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState('');
  const logs$SupportAkurieLoginLogPOST =
    Logs$SupportAkurieApi.useLoginLogPOST();
  const businessDataAkurieProfessionalServiceRequestPOST =
    BusinessDataAkurieApi.useProfessionalServiceRequestPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (tokenExpire) {
        return;
      }
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors.studilyWhite4 },
        dimensions.width
      )}
    >
      {/* site header update 11-14--2 */}
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <View
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ViewStyles(theme)[
                  'Akurie Header Desktop Header 9'
                ],
                {
                  borderBottomWidth: {
                    minWidth: Breakpoints.Laptop,
                    value: 0.5,
                  },
                  borderColor: {
                    minWidth: Breakpoints.Laptop,
                    value: theme.colors['Akurie green'],
                  },
                  height: { minWidth: Breakpoints.Laptop, value: 110 },
                  paddingLeft: { minWidth: Breakpoints.Laptop, value: '8%' },
                  paddingRight: { minWidth: Breakpoints.Laptop, value: '8%' },
                }
              ),
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  marginRight: { minWidth: Breakpoints.Laptop, value: 20 },
                  width: { minWidth: Breakpoints.Laptop, value: '20%' },
                },
                dimensions.width
              )}
            >
              <Image
                resizeMode={'contain'}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    height: { minWidth: Breakpoints.Laptop, value: 50 },
                    width: { minWidth: Breakpoints.Laptop, value: '100%' },
                  }),
                  dimensions.width
                )}
              />
            </View>
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                  flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
                  height: { minWidth: Breakpoints.Laptop, value: '80%' },
                  justifyContent: {
                    minWidth: Breakpoints.Laptop,
                    value: 'space-around',
                  },
                  marginRight: [
                    { minWidth: Breakpoints.Laptop, value: 20 },
                    { minWidth: Breakpoints.Desktop, value: 0 },
                  ],
                  width: { minWidth: Breakpoints.Laptop, value: '60%' },
                },
                dimensions.width
              )}
            >
              <Link
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'], {
                    color: {
                      minWidth: Breakpoints.Laptop,
                      value: theme.colors['Strong_Inverse'],
                    },
                    fontFamily: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: 'Inter_500Medium',
                      },
                      {
                        minWidth: Breakpoints.BigScreen,
                        value: 'Inter_400Regular',
                      },
                    ],
                    fontSize: [
                      { minWidth: Breakpoints.Laptop, value: 12 },
                      { minWidth: Breakpoints.Desktop, value: 14 },
                    ],
                  }),
                  dimensions.width
                )}
                title={'Dashboard'}
              />
              <Link
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'], {
                    color: {
                      minWidth: Breakpoints.Laptop,
                      value: theme.colors['Strong_Inverse'],
                    },
                    fontFamily: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: 'Inter_500Medium',
                      },
                      {
                        minWidth: Breakpoints.BigScreen,
                        value: 'Inter_400Regular',
                      },
                    ],
                    fontSize: [
                      { minWidth: Breakpoints.Laptop, value: 12 },
                      { minWidth: Breakpoints.Desktop, value: 14 },
                    ],
                  }),
                  dimensions.width
                )}
                title={'Networking Events'}
              />
              <Link
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'], {
                    color: {
                      minWidth: Breakpoints.Laptop,
                      value: theme.colors['Strong_Inverse'],
                    },
                    fontFamily: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: 'Inter_500Medium',
                      },
                      {
                        minWidth: Breakpoints.BigScreen,
                        value: 'Inter_400Regular',
                      },
                    ],
                    fontSize: [
                      { minWidth: Breakpoints.Laptop, value: 12 },
                      { minWidth: Breakpoints.Desktop, value: 14 },
                    ],
                  }),
                  dimensions.width
                )}
                title={'My Business info'}
              />
              <Link
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'], {
                    color: {
                      minWidth: Breakpoints.Laptop,
                      value: theme.colors['Strong_Inverse'],
                    },
                    fontFamily: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: 'Inter_500Medium',
                      },
                      {
                        minWidth: Breakpoints.BigScreen,
                        value: 'Inter_400Regular',
                      },
                    ],
                    fontSize: [
                      { minWidth: Breakpoints.Laptop, value: 12 },
                      { minWidth: Breakpoints.Desktop, value: 14 },
                    ],
                  }),
                  dimensions.width
                )}
                title={'The Financials'}
              />
              <Link
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'], {
                    color: {
                      minWidth: Breakpoints.Laptop,
                      value: theme.colors['Strong_Inverse'],
                    },
                    fontFamily: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: 'Inter_500Medium',
                      },
                      {
                        minWidth: Breakpoints.BigScreen,
                        value: 'Inter_400Regular',
                      },
                    ],
                    fontSize: [
                      { minWidth: Breakpoints.Laptop, value: 12 },
                      { minWidth: Breakpoints.Desktop, value: 14 },
                    ],
                  }),
                  dimensions.width
                )}
                title={'Docs & Filings'}
              />
            </View>
            {/* View 3 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: [
                    { minWidth: Breakpoints.BigScreen, value: 'center' },
                    { minWidth: Breakpoints.Desktop, value: 'center' },
                  ],
                  flexDirection: [
                    { minWidth: Breakpoints.BigScreen, value: 'row' },
                    { minWidth: Breakpoints.Desktop, value: 'row' },
                    { minWidth: Breakpoints.Laptop, value: 'row' },
                  ],
                  justifyContent: {
                    minWidth: Breakpoints.Laptop,
                    value: 'center',
                  },
                  marginLeft: { minWidth: Breakpoints.Desktop, value: 20 },
                  marginRight: { minWidth: Breakpoints.Desktop, value: 20 },
                  width: [
                    { minWidth: Breakpoints.Laptop, value: '30%' },
                    { minWidth: Breakpoints.Desktop, value: '20%' },
                    { minWidth: Breakpoints.BigScreen, value: '15%' },
                  ],
                },
                dimensions.width
              )}
            >
              {/* Touchable Frame 2 */}
              <>
                {!(
                  Constants['ak_subscriptionType'] === 'Akurie Free'
                ) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'flex-end',
                        },
                        marginRight: { minWidth: Breakpoints.Laptop, value: 5 },
                        width: [
                          { minWidth: Breakpoints.Laptop, value: '50%' },
                          { minWidth: Breakpoints.Mobile, value: '100%' },
                        ],
                        zIndex: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        const handler = async () => {
                          try {
                            const loginResponse = (
                              await AuthAkurieApi.loginPOST(Constants, {})
                            )?.json;
                            const authToken = loginResponse?.authToken;
                            const Error_Msg = loginResponse?.message;
                            setGlobalVariableValue({
                              key: 'ak_errorMessage',
                              value: Error_Msg,
                            });
                            if (Error_Msg) {
                              return;
                            }
                            setGlobalVariableValue({
                              key: 'SKYE_AUTHORIZATION_HEADER',
                              value: 'Bearer ' + authToken,
                            });
                            const currentUserid = loginResponse?.authUser?.id;
                            setGlobalVariableValue({
                              key: 'ak_userID',
                              value: currentUserid,
                            });
                            const Businessid = loginResponse?.businessData.id;
                            setGlobalVariableValue({
                              key: 'ak_businessID',
                              value: Businessid,
                            });
                            (
                              await logs$SupportAkurieLoginLogPOST.mutateAsync({
                                user_id: currentUserid,
                              })
                            )?.json;
                            setGlobalVariableValue({
                              key: 'ak_errorMessage',
                              value: false,
                            });
                            setGlobalVariableValue({
                              key: 'ak_isLoading',
                              value: true,
                            });
                            navigation.navigate('RootNavigator');
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        { borderRadius: 0 },
                        dimensions.width
                      )}
                    >
                      {/* CTA Frame */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: [
                              {
                                minWidth: Breakpoints.Mobile,
                                value: theme.colors['Akurie Primary'],
                              },
                              {
                                minWidth: Breakpoints.Laptop,
                                value: theme.colors['Akurie green'],
                              },
                            ],
                            borderRadius: 12,
                            flexDirection: 'row',
                            maxWidth: {
                              minWidth: Breakpoints.Laptop,
                              value: 120,
                            },
                            minHeight: {
                              minWidth: Breakpoints.Laptop,
                              value: 45,
                            },
                            paddingBottom: [
                              { minWidth: Breakpoints.Mobile, value: 12 },
                              { minWidth: Breakpoints.Laptop, value: 6 },
                              { minWidth: Breakpoints.BigScreen, value: 12 },
                              { minWidth: Breakpoints.Desktop, value: 2 },
                            ],
                            paddingLeft: [
                              { minWidth: Breakpoints.Mobile, value: 18 },
                              { minWidth: Breakpoints.Laptop, value: 12 },
                            ],
                            paddingRight: [
                              { minWidth: Breakpoints.Mobile, value: 18 },
                              { minWidth: Breakpoints.Laptop, value: 12 },
                            ],
                            paddingTop: [
                              { minWidth: Breakpoints.Mobile, value: 12 },
                              { minWidth: Breakpoints.Laptop, value: 6 },
                              { minWidth: Breakpoints.BigScreen, value: 12 },
                              { minWidth: Breakpoints.Desktop, value: 2 },
                            ],
                            zIndex: 10,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Label Frame */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexGrow: 1,
                              flexShrink: 0,
                              justifyContent: 'center',
                            },
                            dimensions.width
                          )}
                        >
                          {/* Cotinue */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: [
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: theme.colors['Strong Inverse'],
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: theme.colors['Akurie Dark Blue'],
                                  },
                                ],
                                fontFamily: [
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: 'Inter_500Medium',
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'Inter_400Regular',
                                  },
                                ],
                                fontSize: [
                                  { minWidth: Breakpoints.Mobile, value: 15 },
                                  { minWidth: Breakpoints.Laptop, value: 13 },
                                ],
                                lineHeight: 21,
                                paddingBottom: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 3,
                                },
                                paddingTop: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 3,
                                },
                                textTransform: 'uppercase',
                              },
                              dimensions.width
                            )}
                          >
                            {'Try For Free'}
                          </Text>
                        </View>
                      </View>
                    </Touchable>
                  </View>
                )}
              </>
              {/* Touchable Frame 3 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: {
                      minWidth: Breakpoints.Laptop,
                      value: 'flex-start',
                    },
                    justifyContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                    width: [
                      { minWidth: Breakpoints.Mobile, value: '100%' },
                      { minWidth: Breakpoints.Laptop, value: '50%' },
                    ],
                    zIndex: 10,
                  },
                  dimensions.width
                )}
              ></View>
            </View>
          </View>
        )}
      </>
      {/* Navigation Frame */}
      <View
        style={StyleSheet.applyWidth(
          {
            borderBottomWidth: 2,
            borderColor: theme.colors['Akurie green'],
            height: 100,
          },
          dimensions.width
        )}
      >
        <ImageBackground
          resizeMode={'cover'}
          source={Images.Asset13}
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              backgroundColor: theme.colors['Akurie Dark Blue'],
              borderBottomWidth: 1,
              borderColor: theme.colors['Akurie green'],
              flexDirection: 'row',
              height: '100%',
            },
            dimensions.width
          )}
        >
          {/* Left Side Frame */}
          <View
            style={StyleSheet.applyWidth(
              { paddingLeft: 9, paddingRight: 9 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Icon Flex Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                {/* Icon  */}
                <Icon
                  color={theme.colors['Background']}
                  name={'Ionicons/arrow-back-outline'}
                  size={24}
                />
              </View>
            </Touchable>
          </View>
          {/* Middle Frame */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'flex-start',
                flexGrow: 1,
                flexShrink: 0,
                justifyContent: 'center',
              },
              dimensions.width
            )}
          >
            {/* Text Frame  */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexGrow: 0,
                  flexShrink: 0,
                  marginLeft: 30,
                  paddingBottom: 12,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 12,
                },
                dimensions.width
              )}
            ></View>
          </View>
          {/* Left Side Frame 2 */}
          <View
            style={StyleSheet.applyWidth(
              { paddingLeft: 9, paddingRight: 9 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('HelpSupportScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Icon Flex Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                {/* Icon  */}
                <Icon
                  color={theme.colors['Background']}
                  name={'Ionicons/help-circle-outline'}
                  size={32}
                />
              </View>
            </Touchable>
          </View>
        </ImageBackground>
      </View>

      <ImageBackground
        resizeMode={'cover'}
        source={Images.FirefoxPHKyvBthWe}
        style={StyleSheet.applyWidth(
          {
            backgroundColor: theme.colors['Akurie Dark Blue'],
            height: '100%',
            justifyContent: 'center',
            width: '100%',
          },
          dimensions.width
        )}
      >
        {/* menu title holder */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'column',
              height: 100,
              justifyContent: 'center',
              opacity: 1,
              width: '100%',
            },
            dimensions.width
          )}
        >
          <Text
            style={StyleSheet.applyWidth(
              {
                color: theme.colors['Akurie Dark Blue'],
                fontFamily: 'Inter_400Regular',
                fontSize: 24,
                textAlign: 'center',
              },
              dimensions.width
            )}
          >
            {'Professional Services'}
          </Text>
          <Divider
            color={theme.colors['Akurie Primary']}
            style={StyleSheet.applyWidth(
              { height: 3, marginTop: 5 },
              dimensions.width
            )}
          />
        </View>

        <KeyboardAwareScrollView
          contentContainerStyle={StyleSheet.applyWidth(
            { marginBottom: 150, marginTop: 80, paddingTop: 50 },
            dimensions.width
          )}
          extraScrollHeight={200}
          keyboardShouldPersistTaps={'always'}
          showsVerticalScrollIndicator={true}
        >
          {/* new content menu */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: { minWidth: Breakpoints.Tablet, value: 'center' },
                borderRadius: 0,
                borderTopLeftRadius: 30,
                borderTopRightRadius: 30,
                flex: 1,
                height: 400,
                justifyContent: [
                  { minWidth: Breakpoints.Mobile, value: 'center' },
                  { minWidth: Breakpoints.BigScreen, value: 'center' },
                  { minWidth: Breakpoints.Tablet, value: 'center' },
                ],
                marginLeft: { minWidth: Breakpoints.BigScreen, value: 10 },
                marginRight: { minWidth: Breakpoints.BigScreen, value: 10 },
                marginTop: 15,
                opacity: 0.95,
                paddingLeft: [
                  { minWidth: Breakpoints.Laptop, value: '10%' },
                  { minWidth: Breakpoints.Desktop, value: '15%' },
                ],
                paddingRight: [
                  { minWidth: Breakpoints.Laptop, value: '10%' },
                  { minWidth: Breakpoints.Desktop, value: '15%' },
                ],
              },
              dimensions.width
            )}
          >
            {/* Third Menu Row 4 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  marginBottom: 10,
                  marginTop: 10,
                  paddingLeft: 10,
                  paddingRight: 10,
                  width: { minWidth: Breakpoints.Tablet, value: '80%' },
                },
                dimensions.width
              )}
            >
              <Surface
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.SurfaceStyles(theme)['Surface'],
                    {
                      alignItems: 'center',
                      backgroundColor: theme.colors['Akurie Dark Blue'],
                      borderColor: theme.colors['Akurie green'],
                      borderRadius: 15,
                      borderWidth: [
                        { minWidth: Breakpoints.Tablet, value: 0.5 },
                        { minWidth: Breakpoints.Mobile, value: 0.5 },
                      ],
                      flexDirection: 'column',
                      justifyContent: 'center',
                      marginBottom: 5,
                      marginTop: 5,
                      minWidth: 180,
                    }
                  ),
                  dimensions.width
                )}
              >
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexDirection: 'row',
                      marginBottom: 5,
                      marginTop: 5,
                      paddingLeft: 30,
                      paddingRight: 30,
                    },
                    dimensions.width
                  )}
                >
                  {/* Banner */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        marginRight: 10,
                        width: '80%',
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'flex-start',
                          justifyContent: 'center',
                          paddingBottom: 10,
                          paddingLeft: 10,
                          paddingRight: 10,
                          paddingTop: 10,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Title */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Background'],
                            fontFamily: 'Inter_600SemiBold',
                            fontSize: 18,
                            textAlign: 'center',
                          },
                          dimensions.width
                        )}
                      >
                        {'Tax Services'}
                      </Text>
                      {/* Title 2 */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Akurie green'],
                            fontFamily: 'Inter_500Medium',
                            fontSize: 14,
                            marginTop: 5,
                            textAlign: 'left',
                          },
                          dimensions.width
                        )}
                      >
                        {'Filing, Preparation, and Planning'}
                      </Text>
                    </View>
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 10,
                        width: '18%',
                      },
                      dimensions.width
                    )}
                  >
                    <Checkbox
                      checkedIcon={'AntDesign/upcircle'}
                      color={theme.colors['Akurie Primary']}
                      onCheck={() => {
                        const checkboxValue = undefined;
                        try {
                          setServiceOneView(true);
                          setServiceTwoView(false);
                          setServiceThreeView(false);
                          setServiceFourView(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      onPress={newCheckboxValue => {
                        const checkboxValue = newCheckboxValue;
                        try {
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      onUncheck={() => {
                        const checkboxValue = undefined;
                        try {
                          setServiceOneView(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      size={32}
                      status={checkboxValue}
                      uncheckedColor={theme.colors['Akurie Primary']}
                      uncheckedIcon={'AntDesign/downcircle'}
                    />
                  </View>
                </View>
                {/* Surface 2 */}
                <>
                  {!serviceOneView ? null : (
                    <Surface
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.SurfaceStyles(theme)['Surface'],
                          {
                            alignItems: 'center',
                            backgroundColor: theme.colors['Background'],
                            borderBottomLeftRadius: 15,
                            borderBottomRightRadius: 15,
                            borderWidth: {
                              minWidth: Breakpoints.Tablet,
                              value: 0.5,
                            },
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            minHeight: 100,
                            minWidth: 180,
                            paddingBottom: 5,
                            paddingLeft: 30,
                            paddingRight: 30,
                            paddingTop: 5,
                            width: '100%',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {/* Banner */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            marginRight: 10,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'flex-start',
                              justifyContent: 'center',
                              paddingBottom: 10,
                              paddingLeft: 10,
                              paddingRight: 10,
                              paddingTop: 10,
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        >
                          {/* Title */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Strong'],
                                fontFamily: 'Inter_500Medium',
                                fontSize: 16,
                                textAlign: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            {'How can we help?'}
                          </Text>
                          {/* Title 2 */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Strong'],
                                fontFamily: 'Inter_500Medium',
                                fontSize: 12,
                                marginTop: 5,
                                textAlign: 'left',
                              },
                              dimensions.width
                            )}
                          >
                            {
                              'Describe what you need done and your assistant will connect you with the perfect tax professional.'
                            }
                          </Text>
                          <TextInput
                            allowFontScaling={true}
                            changeTextDelay={500}
                            multiline={true}
                            numberOfLines={4}
                            onChangeText={newTextAreaValue => {
                              try {
                                setTaxServicesDescValue(newTextAreaValue);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            placeholder={
                              'Seeking one-time tax services to address specific tax-related queries or to file taxes for a singular event or circumstance. We require expertise in navigating tax laws and regulations for this isolated instance, ensuring compliance and maximizing potential tax benefits.'
                            }
                            placeholderTextColor={theme.colors['Light']}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextInputStyles(theme)[
                                  'Text Area'
                                ],
                                {
                                  borderRadius: 0,
                                  marginTop: 10,
                                  width: '100%',
                                }
                              ),
                              dimensions.width
                            )}
                            textAlignVertical={'top'}
                            value={TaxServicesDescValue}
                            webShowOutline={true}
                          />
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                borderRadius: 9,
                                marginTop: 10,
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            <Button
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    const requestResponse = (
                                      await businessDataAkurieProfessionalServiceRequestPOST.mutateAsync(
                                        {
                                          Description: TaxServicesDescValue,
                                          Service_Type: 'Tax Services',
                                          business_data_id:
                                            Constants['ak_businessID'],
                                          users_id: Constants['ak_userID'],
                                        }
                                      )
                                    )?.json;
                                    const errorMsg = requestResponse?.message;
                                    Keyboard.dismiss();
                                    if (errorMsg) {
                                      return;
                                    }
                                    setTaxServicesDescValue(null);
                                    setSuccessMsg1(true);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ButtonStyles(theme)['Button'],
                                  {
                                    backgroundColor:
                                      theme.colors['Akurie Primary'],
                                    fontFamily: 'Inter_700Bold',
                                    width: '70%',
                                  }
                                ),
                                dimensions.width
                              )}
                              title={'Request Service'}
                            />
                            <>
                              {!successMsg1 ? null : (
                                <Text
                                  accessible={true}
                                  allowFontScaling={true}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      { marginTop: 5 }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'Your request has been sent!'}
                                </Text>
                              )}
                            </>
                          </View>
                        </View>
                      </View>
                    </Surface>
                  )}
                </>
              </Surface>
              {/* Surface 2 */}
              <Surface
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.SurfaceStyles(theme)['Surface'],
                    {
                      alignItems: 'center',
                      backgroundColor: theme.colors['Akurie Dark Blue'],
                      borderColor: theme.colors['Akurie green'],
                      borderRadius: 15,
                      borderWidth: [
                        { minWidth: Breakpoints.Tablet, value: 0.5 },
                        { minWidth: Breakpoints.Mobile, value: 0.5 },
                      ],
                      flexDirection: 'column',
                      justifyContent: 'center',
                      marginBottom: 5,
                      marginTop: 5,
                      minWidth: 180,
                    }
                  ),
                  dimensions.width
                )}
              >
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexDirection: 'row',
                      marginBottom: 5,
                      marginTop: 5,
                      paddingLeft: 30,
                      paddingRight: 30,
                    },
                    dimensions.width
                  )}
                >
                  {/* Banner */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        marginRight: 10,
                        width: '80%',
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'flex-start',
                          justifyContent: 'center',
                          paddingBottom: 10,
                          paddingLeft: 10,
                          paddingRight: 10,
                          paddingTop: 10,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Title */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Background'],
                            fontFamily: 'Inter_600SemiBold',
                            fontSize: 18,
                            textAlign: 'center',
                          },
                          dimensions.width
                        )}
                      >
                        {'Bookkeeping Services'}
                      </Text>
                      {/* Title 2 */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Akurie green'],
                            fontFamily: 'Inter_500Medium',
                            fontSize: 14,
                            marginTop: 5,
                            textAlign: 'left',
                          },
                          dimensions.width
                        )}
                      >
                        {'Budgeting, Forecasting, and Reports'}
                      </Text>
                    </View>
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 10,
                        width: '18%',
                      },
                      dimensions.width
                    )}
                  >
                    <Checkbox
                      checkedIcon={'AntDesign/upcircle'}
                      color={theme.colors['Akurie Primary']}
                      onCheck={() => {
                        const checkboxValue = undefined;
                        try {
                          setServiceTwoView(true);
                          setServiceOneView(false);
                          setServiceThreeView(false);
                          setServiceFourView(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      onPress={newCheckboxValue => {
                        const checkboxValue = newCheckboxValue;
                        try {
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      onUncheck={() => {
                        const checkboxValue = undefined;
                        try {
                          setServiceTwoView(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      size={32}
                      status={checkboxValue}
                      uncheckedColor={theme.colors['Akurie Primary']}
                      uncheckedIcon={'AntDesign/downcircle'}
                    />
                  </View>
                </View>
                {/* Surface 2 */}
                <>
                  {!serviceTwoView ? null : (
                    <Surface
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.SurfaceStyles(theme)['Surface'],
                          {
                            alignItems: 'center',
                            backgroundColor: theme.colors['Background'],
                            borderBottomLeftRadius: 15,
                            borderBottomRightRadius: 15,
                            borderWidth: {
                              minWidth: Breakpoints.Tablet,
                              value: 0.5,
                            },
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            minHeight: 100,
                            minWidth: 180,
                            paddingBottom: 5,
                            paddingLeft: 30,
                            paddingRight: 30,
                            paddingTop: 5,
                            width: '100%',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {/* Banner */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            marginRight: 10,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'flex-start',
                              justifyContent: 'center',
                              paddingBottom: 10,
                              paddingLeft: 10,
                              paddingRight: 10,
                              paddingTop: 10,
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        >
                          {/* Title */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Strong'],
                                fontFamily: 'Inter_500Medium',
                                fontSize: 16,
                                textAlign: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            {'How can we help?'}
                          </Text>
                          {/* Title 2 */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Strong'],
                                fontFamily: 'Inter_500Medium',
                                fontSize: 12,
                                marginTop: 5,
                                textAlign: 'left',
                              },
                              dimensions.width
                            )}
                          >
                            {
                              'Describe what you need done and your assistant will connect you with the perfect Bookkeeping professional.'
                            }
                          </Text>
                          <TextInput
                            allowFontScaling={true}
                            changeTextDelay={500}
                            multiline={true}
                            numberOfLines={4}
                            onChangeText={newTextAreaValue => {
                              try {
                                setBookkeepingServicesValue(newTextAreaValue);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            placeholder={
                              ' In need of one-time bookkeeping services to reconcile and organize financial records for a specific project or period. Seeking a proficient bookkeeper to ensure accuracy and clarity in financial documentation for a one-off engagement.'
                            }
                            placeholderTextColor={theme.colors['Light']}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextInputStyles(theme)[
                                  'Text Area'
                                ],
                                {
                                  borderRadius: 0,
                                  marginTop: 10,
                                  width: '100%',
                                }
                              ),
                              dimensions.width
                            )}
                            textAlignVertical={'top'}
                            value={BookkeepingServicesValue}
                            webShowOutline={true}
                          />
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                borderRadius: 9,
                                marginTop: 10,
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            <Button
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    const requestResponse = (
                                      await businessDataAkurieProfessionalServiceRequestPOST.mutateAsync(
                                        {
                                          Description: BookkeepingServicesValue,
                                          Service_Type: 'BookKeeping',
                                          business_data_id:
                                            Constants['ak_businessID'],
                                          users_id: Constants['ak_userID'],
                                        }
                                      )
                                    )?.json;
                                    const errorMsg = requestResponse?.message;
                                    Keyboard.dismiss();
                                    if (errorMsg) {
                                      return;
                                    }
                                    setBookkeepingServicesValue(null);
                                    setSuccessMsg2(true);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ButtonStyles(theme)['Button'],
                                  {
                                    backgroundColor:
                                      theme.colors['Akurie Primary'],
                                    fontFamily: 'Inter_700Bold',
                                    width: '70%',
                                  }
                                ),
                                dimensions.width
                              )}
                              title={'Request Service'}
                            />
                            <>
                              {!successMsg2 ? null : (
                                <Text
                                  accessible={true}
                                  allowFontScaling={true}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      { marginTop: 5 }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'Your request has been sent!'}
                                </Text>
                              )}
                            </>
                          </View>
                        </View>
                      </View>
                    </Surface>
                  )}
                </>
              </Surface>
              {/* Surface 3 */}
              <Surface
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.SurfaceStyles(theme)['Surface'],
                    {
                      alignItems: 'center',
                      backgroundColor: theme.colors['Akurie Dark Blue'],
                      borderColor: theme.colors['Akurie green'],
                      borderRadius: 15,
                      borderWidth: [
                        { minWidth: Breakpoints.Tablet, value: 0.5 },
                        { minWidth: Breakpoints.Mobile, value: 0.5 },
                      ],
                      flexDirection: 'column',
                      justifyContent: 'center',
                      marginBottom: 5,
                      marginTop: 5,
                      minWidth: 180,
                    }
                  ),
                  dimensions.width
                )}
              >
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexDirection: 'row',
                      marginBottom: 5,
                      marginTop: 5,
                      paddingLeft: 30,
                      paddingRight: 30,
                    },
                    dimensions.width
                  )}
                >
                  {/* Banner */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        marginRight: 10,
                        width: '80%',
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'flex-start',
                          justifyContent: 'center',
                          paddingBottom: 10,
                          paddingLeft: 10,
                          paddingRight: 10,
                          paddingTop: 10,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Title */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Background'],
                            fontFamily: 'Inter_600SemiBold',
                            fontSize: 18,
                            textAlign: 'center',
                          },
                          dimensions.width
                        )}
                      >
                        {'Legal Services'}
                      </Text>
                      {/* Title 2 */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Akurie green'],
                            fontFamily: 'Inter_500Medium',
                            fontSize: 14,
                            marginTop: 5,
                            textAlign: 'left',
                          },
                          dimensions.width
                        )}
                      >
                        {'Contracts, Agreements, Litigation, and more '}
                      </Text>
                    </View>
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 10,
                        width: '18%',
                      },
                      dimensions.width
                    )}
                  >
                    <Checkbox
                      checkedIcon={'AntDesign/upcircle'}
                      color={theme.colors['Akurie Primary']}
                      onCheck={() => {
                        const checkboxValue = undefined;
                        try {
                          setServiceThreeView(true);
                          setServiceOneView(false);
                          setServiceTwoView(false);
                          setServiceFourView(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      onPress={newCheckboxValue => {
                        const checkboxValue = newCheckboxValue;
                        try {
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      onUncheck={() => {
                        const checkboxValue = undefined;
                        try {
                          setServiceThreeView(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      size={32}
                      status={checkboxValue}
                      uncheckedColor={theme.colors['Akurie Primary']}
                      uncheckedIcon={'AntDesign/downcircle'}
                    />
                  </View>
                </View>
                {/* Surface 2 */}
                <>
                  {!serviceThreeView ? null : (
                    <Surface
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.SurfaceStyles(theme)['Surface'],
                          {
                            alignItems: 'center',
                            backgroundColor: theme.colors['Background'],
                            borderBottomLeftRadius: 15,
                            borderBottomRightRadius: 15,
                            borderWidth: {
                              minWidth: Breakpoints.Tablet,
                              value: 0.5,
                            },
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            minHeight: 100,
                            minWidth: 180,
                            paddingBottom: 5,
                            paddingLeft: 30,
                            paddingRight: 30,
                            paddingTop: 5,
                            width: '100%',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {/* Banner */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            marginRight: 10,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'flex-start',
                              justifyContent: 'center',
                              paddingBottom: 10,
                              paddingLeft: 10,
                              paddingRight: 10,
                              paddingTop: 10,
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        >
                          {/* Title */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Strong'],
                                fontFamily: 'Inter_500Medium',
                                fontSize: 16,
                                textAlign: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            {'How can we help?'}
                          </Text>
                          {/* Title 2 */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Strong'],
                                fontFamily: 'Inter_500Medium',
                                fontSize: 12,
                                marginTop: 5,
                                textAlign: 'left',
                              },
                              dimensions.width
                            )}
                          >
                            {
                              'Describe what you need done and your assistant will connect you with the legal professional.'
                            }
                          </Text>
                          <TextInput
                            allowFontScaling={true}
                            changeTextDelay={500}
                            multiline={true}
                            numberOfLines={4}
                            onChangeText={newTextAreaValue => {
                              try {
                                setLegalServicesValue(newTextAreaValue);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            placeholder={
                              ' In search of one-time legal services to address a specific legal matter or issue requiring professional expertise and guidance. Seeking a skilled attorney or legal firm to provide comprehensive advice, representation, and assistance tailored to this singular requirement.'
                            }
                            placeholderTextColor={theme.colors['Light']}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextInputStyles(theme)[
                                  'Text Area'
                                ],
                                {
                                  borderRadius: 0,
                                  marginTop: 10,
                                  width: '100%',
                                }
                              ),
                              dimensions.width
                            )}
                            textAlignVertical={'top'}
                            value={LegalServicesValue}
                            webShowOutline={true}
                          />
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                borderRadius: 9,
                                marginTop: 10,
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            <Button
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    const requestResponse = (
                                      await businessDataAkurieProfessionalServiceRequestPOST.mutateAsync(
                                        {
                                          Description: LegalServicesValue,
                                          Service_Type: 'Legal ',
                                          business_data_id:
                                            Constants['ak_businessID'],
                                          users_id: Constants['ak_userID'],
                                        }
                                      )
                                    )?.json;
                                    const errorMsg = requestResponse?.message;
                                    Keyboard.dismiss();
                                    if (errorMsg) {
                                      return;
                                    }
                                    setLegalServicesValue(null);
                                    setSuccessMsg3(true);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ButtonStyles(theme)['Button'],
                                  {
                                    backgroundColor:
                                      theme.colors['Akurie Primary'],
                                    fontFamily: 'Inter_700Bold',
                                    width: '70%',
                                  }
                                ),
                                dimensions.width
                              )}
                              title={'Request Service'}
                            />
                            <>
                              {!successMsg3 ? null : (
                                <Text
                                  accessible={true}
                                  allowFontScaling={true}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      { marginTop: 5 }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'Your request has been sent!'}
                                </Text>
                              )}
                            </>
                          </View>
                        </View>
                      </View>
                    </Surface>
                  )}
                </>
              </Surface>
              {/* Surface 4 */}
              <Surface
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.SurfaceStyles(theme)['Surface'],
                    {
                      alignItems: 'center',
                      backgroundColor: theme.colors['Akurie Dark Blue'],
                      borderColor: theme.colors['Akurie green'],
                      borderRadius: 15,
                      borderWidth: [
                        { minWidth: Breakpoints.Tablet, value: 0.5 },
                        { minWidth: Breakpoints.Mobile, value: 0.5 },
                      ],
                      flexDirection: 'column',
                      justifyContent: 'center',
                      marginBottom: 40,
                      marginTop: 5,
                      minWidth: 180,
                    }
                  ),
                  dimensions.width
                )}
              >
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexDirection: 'row',
                      marginBottom: 5,
                      marginTop: 5,
                      paddingLeft: 30,
                      paddingRight: 30,
                    },
                    dimensions.width
                  )}
                >
                  {/* Banner */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        marginRight: 10,
                        width: '80%',
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'flex-start',
                          justifyContent: 'center',
                          paddingBottom: 10,
                          paddingLeft: 10,
                          paddingRight: 10,
                          paddingTop: 10,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Title */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Background'],
                            fontFamily: 'Inter_600SemiBold',
                            fontSize: 18,
                            textAlign: 'center',
                          },
                          dimensions.width
                        )}
                      >
                        {' IT Consulting'}
                      </Text>
                      {/* Title 2 */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Akurie green'],
                            fontFamily: 'Inter_500Medium',
                            fontSize: 14,
                            marginTop: 5,
                            textAlign: 'left',
                          },
                          dimensions.width
                        )}
                      >
                        {
                          'Cloud Computing, SaaS Adoption, and IT Infrastructure'
                        }
                      </Text>
                    </View>
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 10,
                        width: '18%',
                      },
                      dimensions.width
                    )}
                  >
                    <Checkbox
                      checkedIcon={'AntDesign/upcircle'}
                      color={theme.colors['Akurie Primary']}
                      onCheck={() => {
                        try {
                          setServiceFourView(true);
                          setServiceThreeView(false);
                          setServiceTwoView(false);
                          setServiceOneView(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      onUncheck={() => {
                        try {
                          setServiceFourView(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      size={32}
                      status={serviceFourView}
                      uncheckedColor={theme.colors['Akurie Primary']}
                      uncheckedIcon={'AntDesign/downcircle'}
                    />
                  </View>
                </View>
                {/* Surface 2 */}
                <>
                  {!serviceFourView ? null : (
                    <Surface
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.SurfaceStyles(theme)['Surface'],
                          {
                            alignItems: 'center',
                            backgroundColor: theme.colors['Background'],
                            borderBottomLeftRadius: 15,
                            borderBottomRightRadius: 15,
                            borderWidth: {
                              minWidth: Breakpoints.Tablet,
                              value: 0.5,
                            },
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            minHeight: 100,
                            minWidth: 180,
                            paddingBottom: 5,
                            paddingLeft: 30,
                            paddingRight: 30,
                            paddingTop: 5,
                            width: '100%',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {/* Banner */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            marginRight: 10,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'flex-start',
                              justifyContent: 'center',
                              paddingBottom: 10,
                              paddingLeft: 10,
                              paddingRight: 10,
                              paddingTop: 10,
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        >
                          {/* Title */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Strong'],
                                fontFamily: 'Inter_500Medium',
                                fontSize: 16,
                                textAlign: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            {'How can we help?'}
                          </Text>
                          {/* Title 2 */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Strong'],
                                fontFamily: 'Inter_500Medium',
                                fontSize: 12,
                                marginTop: 5,
                                textAlign: 'left',
                              },
                              dimensions.width
                            )}
                          >
                            {
                              'Describe what you need done and your assistant will connect you with the perfect IT professional.'
                            }
                          </Text>
                          <TextInput
                            allowFontScaling={true}
                            changeTextDelay={500}
                            multiline={true}
                            numberOfLines={4}
                            onChangeText={newTextAreaValue => {
                              try {
                                setITServicesValue(newTextAreaValue);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            placeholder={
                              "We're seeking one-time IT services to resolve a specific technical issue or to implement a temporary solution for an urgent project. Looking for an experienced IT consultant or service provider to address this isolated need promptly and effectively, ensuring minimal disruption to our operations."
                            }
                            placeholderTextColor={theme.colors['Light']}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextInputStyles(theme)[
                                  'Text Area'
                                ],
                                {
                                  borderRadius: 0,
                                  marginTop: 10,
                                  width: '100%',
                                }
                              ),
                              dimensions.width
                            )}
                            textAlignVertical={'top'}
                            value={ITServicesValue}
                            webShowOutline={true}
                          />
                          {/* View 2 */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                borderRadius: 9,
                                marginTop: 10,
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            <Button
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    const requestResponse = (
                                      await businessDataAkurieProfessionalServiceRequestPOST.mutateAsync(
                                        {
                                          Description: ITServicesValue,
                                          Service_Type: 'IT Services',
                                          business_data_id:
                                            Constants['ak_businessID'],
                                          users_id: Constants['ak_userID'],
                                        }
                                      )
                                    )?.json;
                                    const errorMsg = requestResponse?.message;
                                    Keyboard.dismiss();
                                    if (errorMsg) {
                                      return;
                                    }
                                    setITServicesValue(null);
                                    setSuccessMsg4(true);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ButtonStyles(theme)['Button'],
                                  {
                                    backgroundColor:
                                      theme.colors['Akurie Primary'],
                                    fontFamily: 'Inter_700Bold',
                                    width: '70%',
                                  }
                                ),
                                dimensions.width
                              )}
                              title={'Request Service'}
                            />
                            <>
                              {!successMsg4 ? null : (
                                <Text
                                  accessible={true}
                                  allowFontScaling={true}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      { marginTop: 5 }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'Your request has been sent!'}
                                </Text>
                              )}
                            </>
                          </View>
                        </View>
                      </View>
                    </Surface>
                  )}
                </>
              </Surface>
            </View>
          </View>
        </KeyboardAwareScrollView>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(ProfessionalServicesScreen);
