import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AIActionListAkurieApi from '../apis/AIActionListAkurieApi.js';
import * as AuthAkurieApi from '../apis/AuthAkurieApi.js';
import * as BusinessPlanning$GoalsAkurieApi from '../apis/BusinessPlanning$GoalsAkurieApi.js';
import * as Logs$SupportAkurieApi from '../apis/Logs$SupportAkurieApi.js';
import DesktopHeader13024SignedInBlock from '../components/DesktopHeader13024SignedInBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  CircularProgress,
  DatePicker,
  Divider,
  Icon,
  IconButton,
  KeyboardAvoidingView,
  Picker,
  ScreenContainer,
  Surface,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import {
  ActivityIndicator,
  FlatList,
  Image,
  ImageBackground,
  Keyboard,
  ScrollView,
  StatusBar,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const BusinessGoalsActionPlansScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [Edit_Goal_List_Form_View, setEdit_Goal_List_Form_View] =
    React.useState(false);
  const [Edit_goal_idpass, setEdit_goal_idpass] = React.useState('');
  const [Loading, setLoading] = React.useState(true);
  const [New_Goal_List_Form_View, setNew_Goal_List_Form_View] =
    React.useState(false);
  const [Priority_type, setPriority_type] = React.useState([
    'High',
    'Normal',
    'Low',
  ]);
  const [Related_Business_Comps, setRelated_Business_Comps] = React.useState([
    'Strategy',
    'Organizational Structure',
    'Processes and Operations',
    'People and Culture',
    'Technology and Infrastructure',
    'Governance and Risk Management',
    'Performance Management',
    'Networking and Partnerships',
    'Customer Relationship Management',
    'Financial Management',
  ]);
  const [Uncompleted_view, setUncompleted_view] = React.useState(true);
  const [accessLock_Docs_Filing, setAccessLock_Docs_Filing] =
    React.useState(false);
  const [accessLock_Plans_Goals, setAccessLock_Plans_Goals] =
    React.useState(false);
  const [accessLock_Web_Services, setAccessLock_Web_Services] =
    React.useState(false);
  const [actionPriority, setActionPriority] = React.useState('');
  const [ai_Error, setAi_Error] = React.useState('');
  const [checkboxRowValue, setCheckboxRowValue] = React.useState('');
  const [completionStatusValue, setCompletionStatusValue] = React.useState('');
  const [datePickerValue, setDatePickerValue] = React.useState(null);
  const [dueDatePickerValue, setDueDatePickerValue] = React.useState(
    new Date()
  );
  const [edCompletionStatusValue, setEdCompletionStatusValue] =
    React.useState(false);
  const [edDueDatePickerValue, setEdDueDatePickerValue] = React.useState(null);
  const [edGoalDetailsValue, setEdGoalDetailsValue] = React.useState('');
  const [edRelatedBusinessComponentValue, setEdRelatedBusinessComponentValue] =
    React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [goalDetailsValue, setGoalDetailsValue] = React.useState('');
  const [pickerValue, setPickerValue] = React.useState('');
  const [pickerValue2, setPickerValue2] = React.useState('');
  const [plan_status, setPlan_status] = React.useState('');
  const [priorityPickerValue, setPriorityPickerValue] = React.useState('');
  const [processing_task, setProcessing_task] = React.useState(false);
  const [relatedBusinessComponentValue, setRelatedBusinessComponentValue] =
    React.useState('');
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const [textInputValue2, setTextInputValue2] = React.useState('');
  const [textInputValue3, setTextInputValue3] = React.useState('');
  const [todoDetailsValue, setTodoDetailsValue] = React.useState('');
  const aIActionListAkurieCreateAiGoalPOST =
    AIActionListAkurieApi.useCreateAiGoalPOST();
  const logs$SupportAkurieBasicActivityPOST =
    Logs$SupportAkurieApi.useBasicActivityPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const goalsResult = (
          await BusinessPlanning$GoalsAkurieApi.getBusinessGoalsGET(Constants, {
            bus_id: Constants['ak_businessID'],
          })
        )?.json;
        const plan_status = goalsResult?.message;
        setPlan_status(plan_status);
        setGlobalVariableValue({
          key: 'ak_isLoading',
          value: false,
        });
        StatusBar.setBarStyle('dark-content');
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={true}
      style={StyleSheet.applyWidth(
        { alignItems: 'stretch', justifyContent: 'flex-start' },
        dimensions.width
      )}
    >
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <DesktopHeader13024SignedInBlock />
        )}
      </>
      <ImageBackground
        resizeMode={'stretch'}
        style={StyleSheet.applyWidth(
          { height: '100%', width: '100%' },
          dimensions.width
        )}
      >
        <>
          {!Edit_Goal_List_Form_View ? null : (
            <BlurView
              intensity={80}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.BlurViewStyles(theme)['Blur View'],
                  {
                    alignItems: [
                      { minWidth: Breakpoints.Tablet, value: 'center' },
                      { minWidth: Breakpoints.Mobile, value: 'center' },
                    ],
                    height: '100%',
                    justifyContent: 'center',
                    position: 'absolute',
                    width: '100%',
                    zIndex: 10,
                  }
                ),
                dimensions.width
              )}
              tint={'dark'}
            >
              <KeyboardAvoidingView
                behavior={'padding'}
                enabled={false}
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', height: '100%', width: '100%' },
                  dimensions.width
                )}
              >
                {/* Edit Goal */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: theme.colors['Background'],
                      borderRadius: 12,
                      bottom: [
                        { minWidth: Breakpoints.Tablet, value: 210 },
                        { minWidth: Breakpoints.Laptop, value: 120 },
                        { minWidth: Breakpoints.BigScreen, value: 350 },
                      ],
                      justifyContent: 'center',
                      marginTop: 40,
                      paddingLeft: 20,
                      paddingRight: 20,
                      position: 'absolute',
                      width: [
                        { minWidth: Breakpoints.Mobile, value: '90%' },
                        { minWidth: Breakpoints.Tablet, value: '80%' },
                        { minWidth: Breakpoints.Desktop, value: '60%' },
                        { minWidth: Breakpoints.BigScreen, value: '50%' },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  {/* View 3 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'flex-end',
                        justifyContent: 'center',
                        marginTop: 20,
                        paddingBottom: 20,
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <IconButton
                      color={theme.colors['Akurie Dark Blue']}
                      icon={'AntDesign/closecircleo'}
                      onPress={() => {
                        try {
                          setEdit_Goal_List_Form_View(false);
                          setUncompleted_view(true);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      size={28}
                    />
                  </View>
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: theme.colors['Akurie Dark Blue'],
                        borderRadius: 12,
                        flexDirection: 'row',
                        height: 65,
                        justifyContent: 'center',
                        marginBottom: 20,
                        padding: 20,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Akurie green'],
                            fontFamily: 'Inter_400Regular',
                            fontSize: 22,
                            textAlign: 'center',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Action Plan Creator'}
                    </Text>
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      { marginBottom: 10, marginTop: 20 },
                      dimensions.width
                    )}
                  >
                    {/* Text 2 */}
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.strong,
                          fontFamily: 'Inter_500Medium',
                          fontSize: 14,
                          marginBottom: 5,
                          marginLeft: 8,
                          textAlign: 'center',
                        },
                        dimensions.width
                      )}
                    >
                      {
                        'Every business owner has a goal they want to reach. Enter your goal, its priority, and a due date to create a plan on how to reach it.'
                      }
                    </Text>

                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'flex-start',
                          backgroundColor: theme.colors['Background'],
                          borderRadius: 9,
                          flexDirection: 'column',
                          justifyContent: 'center',
                          marginTop: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors.strong,
                            fontFamily: 'Inter_600SemiBold',
                            fontSize: 14,
                            marginLeft: 8,
                            padding: 8,
                          },
                          dimensions.width
                        )}
                      >
                        {'I want to:'}
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              { color: theme.colors['Error'] }
                            ),
                            dimensions.width
                          )}
                        >
                          {'*'}
                        </Text>
                      </Text>
                      <TextInput
                        autoCapitalize={'none'}
                        changeTextDelay={500}
                        enablesReturnKeyAutomatically={true}
                        multiline={true}
                        numberOfLines={3}
                        onChangeText={newTextInputValue => {
                          const textInputValue = newTextInputValue;
                          try {
                            setTextInputValue3(newTextInputValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        onSubmitEditing={() => {
                          const textInputValue = undefined;
                          try {
                            Keyboard.dismiss();
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        placeholder={'ex. Develop an online presence...'}
                        placeholderTextColor={theme.colors['Light']}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextInputStyles(theme)['Text Input'],
                            { fontSize: 14, maxWidth: '100%', width: '100%' }
                          ),
                          dimensions.width
                        )}
                        value={textInputValue3}
                      />
                    </View>
                  </View>

                  <View>
                    <>
                      {!textInputValue3 ? null : (
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors.strong,
                              fontFamily: 'Inter_600SemiBold',
                              marginBottom: 5,
                              marginLeft: 8,
                            },
                            dimensions.width
                          )}
                        >
                          {'Due Date:'}
                          <Text
                            accessible={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                { color: theme.colors['Error'] }
                              ),
                              dimensions.width
                            )}
                          >
                            {'*'}
                          </Text>
                        </Text>
                      )}
                    </>
                    {/* edDue Date Picker */}
                    <>
                      {!textInputValue3 ? null : (
                        <DatePicker
                          date={edDueDatePickerValue}
                          label={'Date'}
                          leftIconMode={'inset'}
                          mode={'date'}
                          onDateChange={newEdDueDatePickerValue => {
                            try {
                              setEdDueDatePickerValue(newEdDueDatePickerValue);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            { borderColor: theme.colors['Custom #acacac'] },
                            dimensions.width
                          )}
                          type={'solid'}
                        />
                      )}
                    </>
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      { marginBottom: 15, marginTop: 30 },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Error'],
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginBottom: 10,
                            marginLeft: 5,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {errorMessage}
                    </Text>
                    <>
                      {!processing_task ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              marginBottom: 20,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Text 3 */}
                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  color: theme.colors['Akurie Dark Blue'],
                                  fontFamily: 'Inter_600SemiBold',
                                  fontSize: 16,
                                  marginBottom: 10,
                                  marginLeft: 5,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Processing Request '}
                          </Text>

                          <View
                            style={StyleSheet.applyWidth(
                              { height: 40, marginLeft: 10, width: 40 },
                              dimensions.width
                            )}
                          >
                            <CircularProgress
                              animationDuration={3000}
                              color={theme.colors['Akurie Primary']}
                              indeterminate={true}
                              isAnimated={true}
                              lineCap={'round'}
                              maximumValue={100}
                              showTrack={true}
                              startPosition={'top'}
                              thickness={5}
                              trackColor={theme.colors.divider}
                              trackLineCap={'round'}
                              trackThickness={10}
                            />
                          </View>
                        </View>
                      )}
                    </>
                    {/* Text 2 */}
                    <>
                      {!ai_Error ? null : (
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: theme.colors['Error'],
                                fontFamily: 'Inter_600SemiBold',
                                fontSize: 16,
                                marginBottom: 10,
                                marginLeft: 5,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Please try your request again.'}
                        </Text>
                      )}
                    </>
                    <>
                      {!edDueDatePickerValue ? null : (
                        <Button
                          onPress={() => {
                            const handler = async () => {
                              console.log('Button ON_PRESS Start');
                              let error = null;
                              try {
                                console.log('Start ON_PRESS:0 SET_VARIABLE');
                                setProcessing_task(true);
                                console.log('Complete ON_PRESS:0 SET_VARIABLE');
                                console.log('Start ON_PRESS:1 FETCH_REQUEST');
                                const actionPlan_Result = (
                                  await aIActionListAkurieCreateAiGoalPOST.mutateAsync(
                                    {
                                      busID: Constants['ak_businessID'],
                                      date: edDueDatePickerValue,
                                      prompt: textInputValue3,
                                    }
                                  )
                                )?.json;
                                console.log(
                                  'Complete ON_PRESS:1 FETCH_REQUEST',
                                  { actionPlan_Result }
                                );
                                console.log('Start ON_PRESS:2 EXTRACT_KEY');
                                const errorMsg = actionPlan_Result?.message;
                                console.log('Complete ON_PRESS:2 EXTRACT_KEY', {
                                  errorMsg,
                                });
                                console.log('Start ON_PRESS:3 SET_VARIABLE');
                                setAi_Error(errorMsg);
                                console.log('Complete ON_PRESS:3 SET_VARIABLE');
                                console.log(
                                  'Start ON_PRESS:4 CONDITIONAL_STOP'
                                );
                                if (errorMsg) {
                                  return;
                                }
                                console.log(
                                  'Complete ON_PRESS:4 CONDITIONAL_STOP'
                                );
                                console.log('Start ON_PRESS:5 SET_VARIABLE');
                                setAi_Error('');
                                console.log('Complete ON_PRESS:5 SET_VARIABLE');
                                console.log('Start ON_PRESS:6 SET_VARIABLE');
                                setTextInputValue3('');
                                console.log('Complete ON_PRESS:6 SET_VARIABLE');
                                console.log('Start ON_PRESS:7 SET_VARIABLE');
                                setEdit_Goal_List_Form_View(false);
                                console.log('Complete ON_PRESS:7 SET_VARIABLE');
                                console.log('Start ON_PRESS:8 SET_VARIABLE');
                                setEdDueDatePickerValue(null);
                                console.log('Complete ON_PRESS:8 SET_VARIABLE');
                                console.log('Start ON_PRESS:9 SET_VARIABLE');
                                undefined;
                                console.log('Complete ON_PRESS:9 SET_VARIABLE');
                              } catch (err) {
                                console.error(err);
                                error = err.message ?? err;
                              }
                              console.log(
                                'Button ON_PRESS Complete',
                                error ? { error } : 'no error'
                              );
                            };
                            handler();
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: theme.colors['Akurie green'],
                              borderRadius: 12,
                              color: theme.colors['Strong'],
                              fontFamily: 'System',
                              fontWeight: '700',
                              height: 60,
                              textAlign: 'center',
                            },
                            dimensions.width
                          )}
                          title={'Create Plan'}
                        />
                      )}
                    </>
                    {/* Button 2 */}
                    <>
                      {!ai_Error ? null : (
                        <Button
                          onPress={() => {
                            const handler = async () => {
                              console.log('Button 2 ON_PRESS Start');
                              let error = null;
                              try {
                                console.log('Start ON_PRESS:0 SET_VARIABLE');
                                setAi_Error(false);
                                console.log('Complete ON_PRESS:0 SET_VARIABLE');
                                console.log('Start ON_PRESS:1 SET_VARIABLE');
                                setProcessing_task(true);
                                console.log('Complete ON_PRESS:1 SET_VARIABLE');
                                console.log('Start ON_PRESS:2 FETCH_REQUEST');
                                const actionPlan_Result = (
                                  await aIActionListAkurieCreateAiGoalPOST.mutateAsync(
                                    {
                                      busID: Constants['ak_businessID'],
                                      date: edDueDatePickerValue,
                                      prompt: textInputValue3,
                                    }
                                  )
                                )?.json;
                                console.log(
                                  'Complete ON_PRESS:2 FETCH_REQUEST',
                                  { actionPlan_Result }
                                );
                                console.log('Start ON_PRESS:3 EXTRACT_KEY');
                                const errorMsg = actionPlan_Result?.message;
                                console.log('Complete ON_PRESS:3 EXTRACT_KEY', {
                                  errorMsg,
                                });
                                console.log('Start ON_PRESS:4 SET_VARIABLE');
                                setAi_Error(errorMsg);
                                console.log('Complete ON_PRESS:4 SET_VARIABLE');
                                console.log(
                                  'Start ON_PRESS:5 CONDITIONAL_STOP'
                                );
                                if (errorMsg) {
                                  return;
                                }
                                console.log(
                                  'Complete ON_PRESS:5 CONDITIONAL_STOP'
                                );
                                console.log('Start ON_PRESS:6 SET_VARIABLE');
                                setGlobalVariableValue({
                                  key: 'ak_errorMessage',
                                  value: false,
                                });
                                console.log('Complete ON_PRESS:6 SET_VARIABLE');
                                console.log('Start ON_PRESS:7 SET_VARIABLE');
                                setTextInputValue3(null);
                                console.log('Complete ON_PRESS:7 SET_VARIABLE');
                                console.log('Start ON_PRESS:8 SET_VARIABLE');
                                setEdit_Goal_List_Form_View(false);
                                console.log('Complete ON_PRESS:8 SET_VARIABLE');
                                console.log('Start ON_PRESS:9 SET_VARIABLE');
                                setEdDueDatePickerValue(null);
                                console.log('Complete ON_PRESS:9 SET_VARIABLE');
                                console.log('Start ON_PRESS:11 SET_VARIABLE');
                                setPlan_status(plan_status);
                                console.log(
                                  'Complete ON_PRESS:11 SET_VARIABLE'
                                );
                              } catch (err) {
                                console.error(err);
                                error = err.message ?? err;
                              }
                              console.log(
                                'Button 2 ON_PRESS Complete',
                                error ? { error } : 'no error'
                              );
                            };
                            handler();
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: theme.colors['Error'],
                              borderRadius: 12,
                              color: theme.colors['Strong Inverse'],
                              fontFamily: 'System',
                              fontWeight: '700',
                              height: 60,
                              textAlign: 'center',
                            },
                            dimensions.width
                          )}
                          title={'Retry Request'}
                        />
                      )}
                    </>
                  </View>
                </View>
              </KeyboardAvoidingView>
            </BlurView>
          )}
        </>
        {/* loading_Circle */}
        <>
          {!Constants['ak_isLoading'] ? null : (
            <BlurView
              intensity={80}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.BlurViewStyles(theme)['Blur View'],
                  {
                    alignItems: 'center',
                    height: '100%',
                    justifyContent: 'center',
                    position: 'absolute',
                    width: '100%',
                    zIndex: 10,
                  }
                ),
                dimensions.width
              )}
              tint={'dark'}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: theme.colors['Akurie Dark Blue'],
                    borderRadius: 19,
                    bottom: 50,
                    height: [
                      { minWidth: Breakpoints.Mobile, value: '40%' },
                      { minWidth: Breakpoints.Laptop, value: '60%' },
                      { minWidth: Breakpoints.Desktop, value: '60%' },
                    ],
                    justifyContent: 'center',
                    opacity: 1,
                    width: [
                      { minWidth: Breakpoints.Mobile, value: '70%' },
                      { minWidth: Breakpoints.Tablet, value: '50%' },
                      { minWidth: Breakpoints.Desktop, value: '40%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                <CircularProgress
                  animationDuration={7000}
                  color={theme.colors['Akurie green']}
                  indeterminate={true}
                  isAnimated={true}
                  lineCap={'round'}
                  showTrack={true}
                  startPosition={'bottom'}
                  style={StyleSheet.applyWidth(
                    { bottom: -20, height: '80%', width: '80%' },
                    dimensions.width
                  )}
                  thickness={15}
                  trackColor={theme.colors.divider}
                  trackLineCap={'round'}
                />
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Strong Inverse'],
                      fontFamily: 'Inter_600SemiBold',
                      fontSize: 20,
                      marginTop: 10,
                    }),
                    dimensions.width
                  )}
                >
                  {'Setting Goals'}
                </Text>
              </View>
            </BlurView>
          )}
        </>
        {/* Main Header w /c  */}
        <View>
          {/* Navigation Frame */}
          <View
            style={StyleSheet.applyWidth(
              {
                borderBottomWidth: 3,
                borderColor: theme.colors['Akurie green'],
                height: 90,
                marginTop: 15,
              },
              dimensions.width
            )}
          >
            <ImageBackground
              resizeMode={'cover'}
              source={Images.Asset13}
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: theme.colors['Akurie Dark Blue'],
                  flexDirection: 'row',
                  height: '100%',
                  justifyContent: 'space-between',
                  paddingLeft: { minWidth: Breakpoints.Laptop, value: '20%' },
                  paddingRight: { minWidth: Breakpoints.Laptop, value: '20%' },
                },
                dimensions.width
              )}
            >
              {/* Left Side Frame */}
              <View
                style={StyleSheet.applyWidth(
                  { paddingLeft: 9, paddingRight: 9 },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    try {
                      navigation.goBack();
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* Icon Flex Frame */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexGrow: 0,
                        flexShrink: 0,
                        paddingBottom: 12,
                        paddingLeft: 12,
                        paddingRight: 12,
                        paddingTop: 12,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Icon  */}
                    <Icon
                      color={theme.colors['Background']}
                      name={'Ionicons/arrow-back-outline'}
                      size={24}
                    />
                  </View>
                </Touchable>
              </View>
              {/* Middle Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexGrow: 1,
                    flexShrink: 0,
                    justifyContent: 'center',
                  },
                  dimensions.width
                )}
              >
                {/* Text Frame  */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexGrow: 0,
                      flexShrink: 0,
                      marginLeft: 20,
                      paddingBottom: 12,
                      paddingLeft: 12,
                      paddingRight: 12,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                ></View>
              </View>
              {/* Left Side Frame */}
              <View
                style={StyleSheet.applyWidth(
                  { paddingLeft: 9, paddingRight: 9 },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    try {
                      navigation.navigate('HelpSupportScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* Icon Flex Frame */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexGrow: 0,
                        flexShrink: 0,
                        paddingBottom: 12,
                        paddingLeft: 12,
                        paddingRight: 12,
                        paddingTop: 12,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Icon  */}
                    <Icon
                      color={theme.colors['Background']}
                      name={'Ionicons/help-circle-outline'}
                      size={32}
                    />
                  </View>
                </Touchable>
              </View>
            </ImageBackground>
          </View>
        </View>
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', width: '100%', zIndex: 1 },
            dimensions.width
          )}
        />
        <KeyboardAvoidingView
          behavior={'padding'}
          enabled={true}
          style={StyleSheet.applyWidth(
            {
              alignItems: [
                { minWidth: Breakpoints.Laptop, value: 'center' },
                { minWidth: Breakpoints.Tablet, value: 'center' },
              ],
              paddingLeft: { minWidth: Breakpoints.Laptop, value: '20%' },
              paddingRight: { minWidth: Breakpoints.Laptop, value: '20%' },
            },
            dimensions.width
          )}
        >
          <ScrollView
            bounces={true}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            style={StyleSheet.applyWidth(
              {
                width: [
                  { minWidth: Breakpoints.Laptop, value: '100%' },
                  { minWidth: Breakpoints.Tablet, value: '70%' },
                ],
              },
              dimensions.width
            )}
            contentContainerStyle={StyleSheet.applyWidth(
              { alignItems: 'stretch', marginBottom: 200 },
              dimensions.width
            )}
          >
            {/* View 4 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['Akurie Dark Blue'],
                  marginBottom: 10,
                },
                dimensions.width
              )}
            >
              <View>
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        justifyContent: 'center',
                        minHeight: 90,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Akurie Primary'],
                            fontFamily: 'Inter_600SemiBold',
                            fontSize: 22,
                            textAlign: 'center',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'My Projects & Tasks'}
                    </Text>
                    {/* Text 2 */}
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Strong Inverse'],
                            fontFamily: 'Inter_600SemiBold',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Planning for success.'}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            {/* View 3 */}
            <View
              style={StyleSheet.applyWidth(
                { marginLeft: 5, marginRight: 5 },
                dimensions.width
              )}
            >
              <View>
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        justifyContent: 'center',
                        minHeight: 60,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            fontFamily: 'Inter_600SemiBold',
                            fontSize: 18,
                            textAlign: 'center',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Most Recent Project'}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <>
              {!plan_status ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center' },
                    dimensions.width
                  )}
                >
                  <Icon name={'Ionicons/create-outline'} size={200} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        { fontFamily: 'Inter_400Regular', fontSize: 16 }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Every goal is reached one step at a time.'}
                  </Text>
                  <Button
                    icon={'AntDesign/pluscircleo'}
                    onPress={() => {
                      try {
                        setEdit_Goal_List_Form_View(true);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        {
                          backgroundColor: theme.colors['Akurie green'],
                          borderRadius: 9,
                          color: theme.colors['Akurie Dark Blue'],
                          marginTop: 20,
                          padding: 15,
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Create Your First Goal'}
                  />
                </View>
              )}
            </>
            <Touchable
              onPress={() => {
                const handler = async () => {
                  try {
                    (
                      await logs$SupportAkurieBasicActivityPOST.mutateAsync({
                        buttonName: 'View Created Goal',
                      })
                    )?.json;
                    const businessResult = (
                      await BusinessPlanning$GoalsAkurieApi.getBusinessGoalsGET(
                        Constants,
                        { bus_id: Constants['ak_businessID'] }
                      )
                    )?.json;
                    const idResult = businessResult?.id?.id;
                    navigation.navigate('DetailedActionPlanScreen', {
                      item_id: businessResult?.id,
                    });
                    console.log(idResult);
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
            >
              <Surface
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.SurfaceStyles(theme)['Surface'],
                    {
                      alignSelf: 'center',
                      borderBottomWidth: 1,
                      borderColor: theme.colors['Akurie Primary'],
                      borderRadius: 9,
                      marginBottom: 10,
                      width: '95%',
                    }
                  ),
                  dimensions.width
                )}
              >
                <BusinessPlanning$GoalsAkurieApi.FetchGetBusinessGoalsGET
                  bus_id={Constants['ak_businessID']}
                >
                  {({ loading, error, data, refetchGetBusinessGoals }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <>
                        {/* Goal View */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              marginTop: 10,
                              paddingBottom: 10,
                            },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                marginLeft: 10,
                                marginRight: 10,
                                padding: 10,
                                paddingLeft: 20,
                                paddingRight: 20,
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* View 4 */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                            >
                              {/* View 3 */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    backgroundColor:
                                      theme.colors['Akurie Dark Blue'],
                                    borderRadius: 9,
                                    padding: 5,
                                    width: '55%',
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors['Akurie green'],
                                      fontFamily: 'Inter_500Medium',
                                      fontSize: 14,
                                      textAlign: 'left',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Due Date: '}
                                  {(fetchData && fetchData[0])?.due_date}
                                </Text>
                              </View>

                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    marginRight: 5,
                                  },
                                  dimensions.width
                                )}
                              >
                                <>
                                  {!(
                                    (fetchData && fetchData[0])
                                      ?.creationMethod === 'Manually Created'
                                  ) ? null : (
                                    <Icon
                                      color={theme.colors['Akurie Dark Blue']}
                                      name={'Ionicons/create-outline'}
                                      size={24}
                                    />
                                  )}
                                </>
                                {/* Icon 2 */}
                                <>
                                  {!(
                                    (fetchData && fetchData[0])
                                      ?.creationMethod === 'Automated'
                                  ) ? null : (
                                    <Icon
                                      color={theme.colors['Akurie Dark Blue']}
                                      name={'MaterialIcons/auto-awesome'}
                                      size={24}
                                    />
                                  )}
                                </>
                              </View>
                            </View>

                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                  justifyContent: 'flex-start',
                                  marginTop: 10,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors['Strong'],
                                    fontFamily: 'Inter_500Medium',
                                    fontSize: 14,
                                  },
                                  dimensions.width
                                )}
                              >
                                {'Priority: '}
                                {(fetchData && fetchData[0])?.priority}
                              </Text>
                            </View>
                            {/* View 2 */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  marginTop: 10,
                                },
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  { width: '80%' },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors['Akurie Dark Blue'],
                                      fontFamily: 'Inter_600SemiBold',
                                      fontSize: 16,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {(fetchData && fetchData[0])?.Main_Goal}{' '}
                                </Text>
                              </View>
                              <IconButton
                                color={theme.colors['Akurie Dark Blue']}
                                icon={'AntDesign/rightcircle'}
                                size={26}
                                style={StyleSheet.applyWidth(
                                  {
                                    marginBottom: 2,
                                    marginLeft: 2,
                                    marginRight: 5,
                                    marginTop: 2,
                                  },
                                  dimensions.width
                                )}
                              />
                            </View>

                            <View
                              style={StyleSheet.applyWidth(
                                { marginTop: 10 },
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    marginTop: 5,
                                  },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    { alignItems: 'stretch' },
                                    dimensions.width
                                  )}
                                >
                                  <BusinessPlanning$GoalsAkurieApi.FetchGoalStatusGET
                                    goal_id={(fetchData && fetchData[0])?.id}
                                  >
                                    {({
                                      loading,
                                      error,
                                      data,
                                      refetchGoalStatus,
                                    }) => {
                                      const fetchData = data?.json;
                                      if (loading) {
                                        return <ActivityIndicator />;
                                      }

                                      if (
                                        error ||
                                        data?.status < 200 ||
                                        data?.status >= 300
                                      ) {
                                        return <ActivityIndicator />;
                                      }

                                      return (
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            {
                                              color: theme.colors['Strong'],
                                              fontFamily: 'Inter_400Regular',
                                              fontSize: 16,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {fetchData?.completedCount}
                                          {' of '}
                                          {fetchData?.totalCount}
                                          {' Steps Completed'}
                                        </Text>
                                      );
                                    }}
                                  </BusinessPlanning$GoalsAkurieApi.FetchGoalStatusGET>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                      </>
                    );
                  }}
                </BusinessPlanning$GoalsAkurieApi.FetchGetBusinessGoalsGET>
                <View />
              </Surface>
            </Touchable>
            {/* View 2 */}
            <>
              {plan_status ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'space-around',
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Button
                    icon={'AntDesign/pluscircleo'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          (
                            await logs$SupportAkurieBasicActivityPOST.mutateAsync(
                              {
                                buttonName: 'Create Goal',
                                session_id: Constants['Session_ID'],
                              }
                            )
                          )?.json;
                          setEdit_Goal_List_Form_View(true);
                          setProcessing_task(false);
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        {
                          backgroundColor: theme.colors['Akurie green'],
                          borderRadius: 12,
                          color: theme.colors['Akurie Dark Blue'],
                          fontFamily: 'Inter_500Medium',
                          fontSize: 12,
                          padding: 15,
                          width: '48%',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Create A Project'}
                  />
                  <Button
                    icon={'Ionicons/ios-chatbox-ellipses-outline'}
                    onPress={() => {
                      try {
                        navigation.navigate('AskMyAssistantScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        {
                          backgroundColor: theme.colors['Akurie Primary'],
                          borderRadius: 12,
                          color: theme.colors['Strong_Inverse'],
                          fontFamily: 'Inter_500Medium',
                          fontSize: 12,
                          padding: 15,
                          width: '48%',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Contact Assistant'}
                  />
                </View>
              )}
            </>
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['Divider'],
                  borderTopLeftRadius: 25,
                  borderTopRightRadius: 25,
                  paddingTop: 15,
                },
                dimensions.width
              )}
            >
              {/* View 3 */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 10 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Akurie Dark Blue'],
                      fontFamily: 'Inter_400Regular',
                      fontSize: 16,
                      marginBottom: 10,
                      textAlign: 'center',
                    }),
                    dimensions.width
                  )}
                >
                  {'All Projects'}
                </Text>
              </View>
              {/* Fetch 2 */}
              <>
                {plan_status ? null : (
                  <BusinessPlanning$GoalsAkurieApi.FetchGetBusinessGoalsGET
                    bus_id={Constants['ak_businessID']}
                    handlers={{
                      on4xx: fetch2Data => {
                        try {
                          setGlobalVariableValue({
                            key: 'ak_isLoading',
                            value: false,
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      },
                      on5xx: fetch2Data => {
                        try {
                          setGlobalVariableValue({
                            key: 'ak_isLoading',
                            value: false,
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      },
                      onData: fetch2Data => {
                        try {
                          setGlobalVariableValue({
                            key: 'ak_isLoading',
                            value: false,
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      },
                    }}
                  >
                    {({ loading, error, data, refetchGetBusinessGoals }) => {
                      const fetch2Data = data?.json;
                      if (loading) {
                        return <ActivityIndicator />;
                      }

                      if (error || data?.status < 200 || data?.status >= 300) {
                        return <ActivityIndicator />;
                      }

                      return (
                        <FlatList
                          contentContainerStyle={StyleSheet.applyWidth(
                            { alignItems: 'stretch' },
                            dimensions.width
                          )}
                          data={fetch2Data}
                          keyExtractor={(listData, index) =>
                            listData?.id ?? listData?.uuid ?? index.toString()
                          }
                          listKey={'gwBcNhbE'}
                          numColumns={1}
                          onEndReachedThreshold={0.5}
                          renderItem={({ item, index }) => {
                            const listData = item;
                            return (
                              <Touchable
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      (
                                        await logs$SupportAkurieBasicActivityPOST.mutateAsync(
                                          { buttonName: 'View Created Goal' }
                                        )
                                      )?.json;
                                      navigation.navigate(
                                        'DetailedActionPlanScreen',
                                        { item_id: listData?.id }
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                              >
                                <Surface
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.SurfaceStyles(theme)[
                                        'Surface'
                                      ],
                                      {
                                        alignSelf: 'center',
                                        borderBottomWidth: 1,
                                        borderColor:
                                          theme.colors['Akurie Primary'],
                                        borderRadius: 9,
                                        marginBottom: 10,
                                        width: '95%',
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  <BusinessPlanning$GoalsAkurieApi.FetchGetBusinessGoalsGET
                                    bus_id={listData?.id}
                                  >
                                    {({
                                      loading,
                                      error,
                                      data,
                                      refetchGetBusinessGoals,
                                    }) => {
                                      const fetchData = data?.json;
                                      if (loading) {
                                        return <ActivityIndicator />;
                                      }

                                      if (
                                        error ||
                                        data?.status < 200 ||
                                        data?.status >= 300
                                      ) {
                                        return <ActivityIndicator />;
                                      }

                                      return (
                                        <>
                                          {/* Goal View */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                marginTop: 10,
                                                paddingBottom: 10,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  marginLeft: 10,
                                                  marginRight: 10,
                                                  padding: 10,
                                                  paddingLeft: 20,
                                                  paddingRight: 20,
                                                  width: '100%',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* View 4 */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    flexDirection: 'row',
                                                    justifyContent:
                                                      'space-between',
                                                    width: '100%',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* View 3 */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      alignItems: 'center',
                                                      backgroundColor:
                                                        theme.colors[
                                                          'Akurie Dark Blue'
                                                        ],
                                                      borderRadius: 9,
                                                      padding: 5,
                                                      width: '55%',
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <Text
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color:
                                                          theme.colors[
                                                            'Akurie green'
                                                          ],
                                                        fontFamily:
                                                          'Inter_500Medium',
                                                        fontSize: 14,
                                                        textAlign: 'left',
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {'Due Date: '}
                                                    {listData?.due_date}
                                                  </Text>
                                                </View>

                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      alignItems: 'center',
                                                      flexDirection: 'row',
                                                      marginRight: 5,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <>
                                                    {!(
                                                      listData?.creationMethod ===
                                                      'Manually Created'
                                                    ) ? null : (
                                                      <Icon
                                                        color={
                                                          theme.colors[
                                                            'Akurie Dark Blue'
                                                          ]
                                                        }
                                                        name={
                                                          'Ionicons/create-outline'
                                                        }
                                                        size={24}
                                                      />
                                                    )}
                                                  </>
                                                  {/* Icon 2 */}
                                                  <>
                                                    {!(
                                                      listData?.creationMethod ===
                                                      'Automated'
                                                    ) ? null : (
                                                      <Icon
                                                        color={
                                                          theme.colors[
                                                            'Akurie Dark Blue'
                                                          ]
                                                        }
                                                        name={
                                                          'MaterialIcons/auto-awesome'
                                                        }
                                                        size={24}
                                                      />
                                                    )}
                                                  </>
                                                </View>
                                              </View>

                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                    justifyContent:
                                                      'flex-start',
                                                    marginTop: 10,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                <Text
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      color:
                                                        theme.colors['Strong'],
                                                      fontFamily:
                                                        'Inter_500Medium',
                                                      fontSize: 14,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {'Priority: '}
                                                  {listData?.priority}
                                                </Text>
                                                <>
                                                  {!(
                                                    listData?.priority === 'Low'
                                                  ) ? null : (
                                                    <Icon
                                                      color={
                                                        theme.colors[
                                                          'Akurie Primary'
                                                        ]
                                                      }
                                                      name={
                                                        'AntDesign/exclamationcircle'
                                                      }
                                                      size={20}
                                                      style={StyleSheet.applyWidth(
                                                        { marginLeft: 10 },
                                                        dimensions.width
                                                      )}
                                                    />
                                                  )}
                                                </>
                                                {/* Icon 4 */}
                                                <>
                                                  {!(
                                                    listData?.priority ===
                                                    'Medium'
                                                  ) ? null : (
                                                    <Icon
                                                      color={
                                                        theme.colors[
                                                          'Internal_Yellow_Secondary'
                                                        ]
                                                      }
                                                      name={
                                                        'AntDesign/exclamationcircle'
                                                      }
                                                      size={20}
                                                      style={StyleSheet.applyWidth(
                                                        { marginLeft: 10 },
                                                        dimensions.width
                                                      )}
                                                    />
                                                  )}
                                                </>
                                                {/* Icon 3 */}
                                                <>
                                                  {!(
                                                    listData?.priority ===
                                                    'High'
                                                  ) ? null : (
                                                    <Icon
                                                      color={
                                                        theme.colors[
                                                          'Internal_Orange_Secondary_Alt'
                                                        ]
                                                      }
                                                      name={
                                                        'AntDesign/exclamationcircle'
                                                      }
                                                      size={20}
                                                      style={StyleSheet.applyWidth(
                                                        { marginLeft: 10 },
                                                        dimensions.width
                                                      )}
                                                    />
                                                  )}
                                                </>
                                                {/* Icon 2 */}
                                                <>
                                                  {!(
                                                    listData?.priority ===
                                                    'Urgent'
                                                  ) ? null : (
                                                    <Icon
                                                      color={
                                                        theme.colors['Error']
                                                      }
                                                      name={
                                                        'AntDesign/exclamationcircle'
                                                      }
                                                      size={20}
                                                      style={StyleSheet.applyWidth(
                                                        { marginLeft: 10 },
                                                        dimensions.width
                                                      )}
                                                    />
                                                  )}
                                                </>
                                              </View>
                                              {/* View 2 */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                    justifyContent:
                                                      'space-between',
                                                    marginTop: 10,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    { width: '80%' },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <Text
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color:
                                                          theme.colors[
                                                            'Akurie Dark Blue'
                                                          ],
                                                        fontFamily:
                                                          'Inter_600SemiBold',
                                                        fontSize: 16,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {listData?.Main_Goal}{' '}
                                                  </Text>
                                                </View>
                                                <IconButton
                                                  color={
                                                    theme.colors[
                                                      'Akurie Dark Blue'
                                                    ]
                                                  }
                                                  icon={'AntDesign/rightcircle'}
                                                  size={26}
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      marginBottom: 2,
                                                      marginLeft: 2,
                                                      marginRight: 5,
                                                      marginTop: 2,
                                                    },
                                                    dimensions.width
                                                  )}
                                                />
                                              </View>

                                              <View
                                                style={StyleSheet.applyWidth(
                                                  { marginTop: 10 },
                                                  dimensions.width
                                                )}
                                              >
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      flexDirection: 'row',
                                                      justifyContent:
                                                        'flex-start',
                                                      marginTop: 5,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      { alignItems: 'stretch' },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <BusinessPlanning$GoalsAkurieApi.FetchGoalStatusGET
                                                      goal_id={listData?.id}
                                                    >
                                                      {({
                                                        loading,
                                                        error,
                                                        data,
                                                        refetchGoalStatus,
                                                      }) => {
                                                        const fetchData =
                                                          data?.json;
                                                        if (loading) {
                                                          return (
                                                            <ActivityIndicator />
                                                          );
                                                        }

                                                        if (
                                                          error ||
                                                          data?.status < 200 ||
                                                          data?.status >= 300
                                                        ) {
                                                          return (
                                                            <ActivityIndicator />
                                                          );
                                                        }

                                                        return (
                                                          <Text
                                                            style={StyleSheet.applyWidth(
                                                              {
                                                                color:
                                                                  theme.colors[
                                                                    'Strong'
                                                                  ],
                                                                fontFamily:
                                                                  'Inter_400Regular',
                                                                fontSize: 16,
                                                              },
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {
                                                              fetchData?.completedCount
                                                            }
                                                            {' of '}
                                                            {
                                                              fetchData?.totalCount
                                                            }
                                                            {' Steps Completed'}
                                                          </Text>
                                                        );
                                                      }}
                                                    </BusinessPlanning$GoalsAkurieApi.FetchGoalStatusGET>
                                                  </View>
                                                </View>
                                              </View>
                                            </View>
                                          </View>
                                        </>
                                      );
                                    }}
                                  </BusinessPlanning$GoalsAkurieApi.FetchGetBusinessGoalsGET>
                                  <View />
                                </Surface>
                              </Touchable>
                            );
                          }}
                          showsHorizontalScrollIndicator={true}
                          showsVerticalScrollIndicator={true}
                        />
                      );
                    }}
                  </BusinessPlanning$GoalsAkurieApi.FetchGetBusinessGoalsGET>
                )}
              </>
            </View>
          </ScrollView>
        </KeyboardAvoidingView>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(BusinessGoalsActionPlansScreen);
