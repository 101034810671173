import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AuthAkurieApi from '../apis/AuthAkurieApi.js';
import * as Logs$SupportAkurieApi from '../apis/Logs$SupportAkurieApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as RevenueCat from '../custom-files/RevenueCat';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  IconButton,
  Link,
  ScreenContainer,
  Surface,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import {
  ActivityIndicator,
  Alert,
  FlatList,
  Image,
  ImageBackground,
  Platform,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const PaywallProCurrentMonth3Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [currentMOfferings, setCurrentMOfferings] = React.useState([]);
  const [currentOfferings, setCurrentOfferings] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [planCompareView, setPlanCompareView] = React.useState(false);
  const purchaseItem = async item => {
    try {
      setIsLoading(true);
      const { customerInfo, productIdentifier: packageName } =
        await RevenueCat.Purchases.purchasePackage(item);

      return { packageName, customerInfo };
      // navigate
    } catch (e) {
      if (!e.userCancelled) {
        Alert.alert('Subscription Canceled!');
      }

      return { packageName: false, customerInfo: false };
    } finally {
      setIsLoading(false);
    }
    return {};
  };
  React.useEffect(() => {
    let offerings;

    const fetchOfferings = async () => {
      try {
        setIsLoading(true);
        offerings = await RevenueCat.Purchases.getOfferings();

        if (
          offerings.all['Assistant Pro Monthly'].availablePackages.length !== 0
        ) {
          setCurrentOfferings(
            offerings.all['Assistant Pro Monthly'].availablePackages
          );
        }
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    };

    fetchOfferings();
  }, []);
  const logs$SupportAkurieLoginLogPOST =
    Logs$SupportAkurieApi.useLoginLogPOST();

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        },
        dimensions.width
      )}
    >
      {/* site header update 11-14  */}
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <View
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ViewStyles(theme)[
                  'Akurie Header Desktop Header 9'
                ],
                {
                  borderBottomWidth: {
                    minWidth: Breakpoints.Laptop,
                    value: 0.5,
                  },
                  borderColor: {
                    minWidth: Breakpoints.Laptop,
                    value: theme.colors['Akurie green'],
                  },
                  height: { minWidth: Breakpoints.Laptop, value: 110 },
                  paddingLeft: { minWidth: Breakpoints.Laptop, value: '8%' },
                  paddingRight: { minWidth: Breakpoints.Laptop, value: '8%' },
                  width: { minWidth: Breakpoints.Laptop, value: '100%' },
                }
              ),
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  marginRight: { minWidth: Breakpoints.Laptop, value: 20 },
                  width: { minWidth: Breakpoints.Laptop, value: '20%' },
                },
                dimensions.width
              )}
            >
              <Image
                resizeMode={'contain'}
                source={Images.MainLogoUpdate1u2}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    height: { minWidth: Breakpoints.Laptop, value: 50 },
                    width: { minWidth: Breakpoints.Laptop, value: '100%' },
                  }),
                  dimensions.width
                )}
              />
            </View>
            {/* View 3 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: [
                    { minWidth: Breakpoints.BigScreen, value: 'center' },
                    { minWidth: Breakpoints.Desktop, value: 'center' },
                  ],
                  flexDirection: [
                    { minWidth: Breakpoints.BigScreen, value: 'row' },
                    { minWidth: Breakpoints.Desktop, value: 'row' },
                    { minWidth: Breakpoints.Laptop, value: 'row' },
                  ],
                  justifyContent: {
                    minWidth: Breakpoints.Laptop,
                    value: 'center',
                  },
                  marginLeft: { minWidth: Breakpoints.Desktop, value: 20 },
                  marginRight: { minWidth: Breakpoints.Desktop, value: 20 },
                  width: [
                    { minWidth: Breakpoints.Laptop, value: '30%' },
                    { minWidth: Breakpoints.Desktop, value: '20%' },
                    { minWidth: Breakpoints.BigScreen, value: '15%' },
                  ],
                },
                dimensions.width
              )}
            >
              {/* Touchable Frame 3 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: {
                      minWidth: Breakpoints.Laptop,
                      value: 'flex-start',
                    },
                    width: [
                      { minWidth: Breakpoints.Mobile, value: '100%' },
                      { minWidth: Breakpoints.Laptop, value: '50%' },
                    ],
                    zIndex: 10,
                  },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    const handler = async () => {
                      try {
                        const loginResponse = (
                          await AuthAkurieApi.loginPOST(Constants, {})
                        )?.json;
                        const authToken = loginResponse?.authToken;
                        const Error_Msg = loginResponse?.message;
                        setGlobalVariableValue({
                          key: 'ak_errorMessage',
                          value: Error_Msg,
                        });
                        if (Error_Msg) {
                          return;
                        }
                        setGlobalVariableValue({
                          key: 'SKYE_AUTHORIZATION_HEADER',
                          value: 'Bearer ' + authToken,
                        });
                        const currentUserid = loginResponse?.authUser?.id;
                        setGlobalVariableValue({
                          key: 'ak_userID',
                          value: currentUserid,
                        });
                        const Businessid = loginResponse?.businessData.id;
                        setGlobalVariableValue({
                          key: 'ak_businessID',
                          value: Businessid,
                        });
                        (
                          await logs$SupportAkurieLoginLogPOST.mutateAsync({
                            user_id: currentUserid,
                          })
                        )?.json;
                        setGlobalVariableValue({
                          key: 'ak_errorMessage',
                          value: false,
                        });
                        setGlobalVariableValue({
                          key: 'ak_isLoading',
                          value: true,
                        });
                        navigation.navigate('RootNavigator');
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  style={StyleSheet.applyWidth(
                    { borderRadius: 0 },
                    dimensions.width
                  )}
                >
                  {/* CTA Frame */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors['Akurie Primary'],
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Akurie Primary'],
                          },
                        ],
                        borderRadius: 12,
                        flexDirection: 'row',
                        maxWidth: { minWidth: Breakpoints.Laptop, value: 120 },
                        minHeight: { minWidth: Breakpoints.Laptop, value: 45 },
                        paddingBottom: [
                          { minWidth: Breakpoints.Mobile, value: 12 },
                          { minWidth: Breakpoints.Laptop, value: 6 },
                          { minWidth: Breakpoints.BigScreen, value: 12 },
                          { minWidth: Breakpoints.Desktop, value: 2 },
                        ],
                        paddingLeft: [
                          { minWidth: Breakpoints.Mobile, value: 18 },
                          { minWidth: Breakpoints.Laptop, value: 12 },
                        ],
                        paddingRight: [
                          { minWidth: Breakpoints.Mobile, value: 18 },
                          { minWidth: Breakpoints.Laptop, value: 12 },
                        ],
                        paddingTop: [
                          { minWidth: Breakpoints.Mobile, value: 12 },
                          { minWidth: Breakpoints.Laptop, value: 6 },
                          { minWidth: Breakpoints.BigScreen, value: 12 },
                          { minWidth: Breakpoints.Desktop, value: 2 },
                        ],
                        zIndex: 10,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Label Frame */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexGrow: 1,
                          flexShrink: 0,
                          justifyContent: 'center',
                        },
                        dimensions.width
                      )}
                    >
                      {/* Cotinue */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: [
                              {
                                minWidth: Breakpoints.Mobile,
                                value: theme.colors['Strong Inverse'],
                              },
                              {
                                minWidth: Breakpoints.Laptop,
                                value: theme.colors['Akurie Dark Blue'],
                              },
                            ],
                            fontFamily: [
                              {
                                minWidth: Breakpoints.Mobile,
                                value: 'Inter_500Medium',
                              },
                              {
                                minWidth: Breakpoints.Laptop,
                                value: 'Inter_400Regular',
                              },
                            ],
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 15 },
                              { minWidth: Breakpoints.Laptop, value: 13 },
                            ],
                            lineHeight: 21,
                            paddingBottom: {
                              minWidth: Breakpoints.Laptop,
                              value: 3,
                            },
                            paddingTop: {
                              minWidth: Breakpoints.Laptop,
                              value: 3,
                            },
                            textTransform: 'uppercase',
                          },
                          dimensions.width
                        )}
                      >
                        {'Sign Out'}
                      </Text>
                    </View>
                  </View>
                </Touchable>
              </View>
            </View>
          </View>
        )}
      </>
      {/* Image Background 2 */}
      <ImageBackground
        resizeMode={'cover'}
        source={Images.FirefoxPHKyvBthWe}
        style={StyleSheet.applyWidth(
          {
            alignItems: { minWidth: Breakpoints.BigScreen, value: 'stretch' },
            height: '100%',
            width: '100%',
          },
          dimensions.width
        )}
      >
        {/* updateCompareplanView */}
        <>
          {!planCompareView ? null : (
            <BlurView
              intensity={80}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.BlurViewStyles(theme)['Blur View'],
                  {
                    alignItems: 'center',
                    height: '100%',
                    justifyContent: 'center',
                    position: 'absolute',
                    width: '100%',
                    zIndex: 10,
                  }
                ),
                dimensions.width
              )}
              tint={'default'}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: theme.colors['Akurie Dark Blue'],
                    borderBottomWidth: 0.1,
                    borderColor: theme.colors['Akurie green'],
                    borderRadius: 19,
                    borderTopWidth: 0.1,
                    bottom: 50,
                    height: [
                      { minWidth: Breakpoints.Tablet, value: '80%' },
                      { minWidth: Breakpoints.Laptop, value: '60%' },
                      { minWidth: Breakpoints.Mobile, value: '90%' },
                    ],
                    justifyContent: 'center',
                    marginTop: 120,
                    opacity: 1,
                    width: [
                      { minWidth: Breakpoints.Tablet, value: '70%' },
                      { minWidth: Breakpoints.Mobile, value: '90%' },
                      { minWidth: Breakpoints.Desktop, value: '50%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                <Surface
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.SurfaceStyles(theme)['Surface'],
                      {
                        alignItems: 'center',
                        borderRadius: 19,
                        height: '90%',
                        justifyContent: 'space-around',
                        opacity: 1,
                        padding: 20,
                        width: '95%',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'flex-end',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Akurie Primary'],
                            fontFamily: 'Inter_600SemiBold',
                            fontSize: 18,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Compare Plans'}
                    </Text>
                    <IconButton
                      color={theme.colors['Akurie Dark Blue']}
                      icon={'AntDesign/closecircle'}
                      onPress={() => {
                        try {
                          setPlanCompareView(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      size={26}
                    />
                  </View>
                  {/* View 14 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    {/* View 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          borderBottomWidth: 0.5,
                          flexDirection: 'row',
                          height: 40,
                          justifyContent: 'space-evenly',
                          paddingLeft: 5,
                          paddingRight: 5,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          { width: '50%' },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              { fontFamily: 'Inter_500Medium' }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Features'}
                        </Text>
                      </View>
                      {/* View 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              { fontFamily: 'Inter_500Medium' }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Free'}
                        </Text>
                      </View>
                      {/* View 3 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              { fontFamily: 'Inter_500Medium' }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Lite'}
                        </Text>
                      </View>
                      {/* View 4 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              { fontFamily: 'Inter_500Medium' }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Plus'}
                        </Text>
                      </View>
                      {/* View 5 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              { fontFamily: 'Inter_500Medium' }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Pro'}
                        </Text>
                      </View>
                    </View>
                    {/* View 3 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          borderBottomWidth: 1,
                          borderColor: theme.colors['Divider'],
                          flexDirection: 'row',
                          height: 40,
                          justifyContent: 'space-evenly',
                          marginBottom: 15,
                          marginTop: 10,
                          paddingBottom: 5,
                          paddingLeft: 5,
                          paddingRight: 5,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          { maxWidth: '50%', width: '50%' },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              { fontFamily: 'Inter_400Regular' }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Networking Event \nAccess'}
                        </Text>
                      </View>
                      {/* View 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        <Icon name={'Entypo/dot-single'} size={24} />
                      </View>
                      {/* View 3 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        {/* Icon 2 */}
                        <Icon name={'Entypo/dot-single'} size={24} />
                      </View>
                      {/* View 4 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        {/* Icon 3 */}
                        <Icon name={'Entypo/dot-single'} size={24} />
                      </View>
                      {/* View 5 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        {/* Icon 4 */}
                        <Icon name={'Entypo/dot-single'} size={24} />
                      </View>
                    </View>
                    {/* View 4 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          borderBottomWidth: 1,
                          borderColor: theme.colors['Divider'],
                          flexDirection: 'row',
                          height: 45,
                          justifyContent: 'space-evenly',
                          marginBottom: 15,
                          paddingBottom: 5,
                          paddingLeft: 5,
                          paddingRight: 5,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          { width: '50%' },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              { fontFamily: 'Inter_400Regular' }
                            ),
                            dimensions.width
                          )}
                        >
                          {'50 State Filing \nInfo & Data'}
                        </Text>
                      </View>
                      {/* View 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        <Icon name={'Entypo/dot-single'} size={24} />
                      </View>
                      {/* View 3 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        {/* Icon 2 */}
                        <Icon name={'Entypo/dot-single'} size={24} />
                      </View>
                      {/* View 4 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        {/* Icon 3 */}
                        <Icon name={'Entypo/dot-single'} size={24} />
                      </View>
                      {/* View 5 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        {/* Icon 4 */}
                        <Icon name={'Entypo/dot-single'} size={24} />
                      </View>
                    </View>
                    {/* View 5 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          borderBottomWidth: 1,
                          borderColor: theme.colors['Divider'],
                          flexDirection: 'row',
                          height: 45,
                          justifyContent: 'space-evenly',
                          marginBottom: 15,
                          paddingBottom: 5,
                          paddingLeft: 5,
                          paddingRight: 5,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          { width: '50%' },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              { fontFamily: 'Inter_400Regular' }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Information Management'}
                        </Text>
                      </View>
                      {/* View 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        <Icon name={'Entypo/dot-single'} size={24} />
                      </View>
                      {/* View 3 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        {/* Icon 2 */}
                        <Icon name={'Entypo/dot-single'} size={24} />
                      </View>
                      {/* View 4 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        {/* Icon 3 */}
                        <Icon name={'Entypo/dot-single'} size={24} />
                      </View>
                      {/* View 5 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        {/* Icon 4 */}
                        <Icon name={'Entypo/dot-single'} size={24} />
                      </View>
                    </View>
                    {/* View 6 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          borderBottomWidth: 1,
                          borderColor: theme.colors['Divider'],
                          flexDirection: 'row',
                          height: 45,
                          justifyContent: 'space-evenly',
                          marginBottom: 10,
                          paddingBottom: 5,
                          paddingLeft: 5,
                          paddingRight: 5,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          { width: '50%' },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              { fontFamily: 'Inter_400Regular' }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Business Filing\nTracker'}
                        </Text>
                      </View>
                      {/* View 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        <Icon size={24} />
                      </View>
                      {/* View 3 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        {/* Icon 2 */}
                        <Icon name={'Entypo/dot-single'} size={24} />
                      </View>
                      {/* View 4 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        {/* Icon 3 */}
                        <Icon name={'Entypo/dot-single'} size={24} />
                      </View>
                      {/* View 5 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        {/* Icon 4 */}
                        <Icon name={'Entypo/dot-single'} size={24} />
                      </View>
                    </View>
                    {/* View 7 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          borderBottomWidth: 0.5,
                          borderColor: theme.colors['Divider'],
                          flexDirection: 'row',
                          height: 45,
                          justifyContent: 'space-evenly',
                          marginBottom: 10,
                          paddingBottom: 5,
                          paddingLeft: 5,
                          paddingRight: 5,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          { width: '50%' },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              { fontFamily: 'Inter_400Regular' }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Financial Resources'}
                        </Text>
                      </View>
                      {/* View 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        <Icon size={24} />
                      </View>
                      {/* View 3 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        {/* Icon 2 */}
                        <Icon size={24} />
                      </View>
                      {/* View 4 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        {/* Icon 3 */}
                        <Icon name={'Entypo/dot-single'} size={24} />
                      </View>
                      {/* View 5 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        {/* Icon 4 */}
                        <Icon name={'Entypo/dot-single'} size={24} />
                      </View>
                    </View>
                    {/* View 13 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          borderBottomWidth: 0.5,
                          borderColor: theme.colors['Divider'],
                          flexDirection: 'row',
                          height: 45,
                          justifyContent: 'space-evenly',
                          marginBottom: 10,
                          paddingBottom: 5,
                          paddingLeft: 5,
                          paddingRight: 5,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          { width: '50%' },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              { fontFamily: 'Inter_400Regular' }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Live Banking Info'}
                        </Text>
                      </View>
                      {/* View 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        <Icon size={24} />
                      </View>
                      {/* View 3 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        {/* Icon 2 */}
                        <Icon size={24} />
                      </View>
                      {/* View 4 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        {/* Icon 3 */}
                        <Icon name={'Entypo/dot-single'} size={24} />
                      </View>
                      {/* View 5 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        {/* Icon 4 */}
                        <Icon name={'Entypo/dot-single'} size={24} />
                      </View>
                    </View>
                    {/* View 12 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          borderBottomWidth: 0.5,
                          borderColor: theme.colors['Divider'],
                          flexDirection: 'row',
                          height: 45,
                          justifyContent: 'space-evenly',
                          marginBottom: 15,
                          paddingBottom: 5,
                          paddingLeft: 5,
                          paddingRight: 5,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          { width: '50%' },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              { fontFamily: 'Inter_400Regular' }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Website Support\n& Management**'}
                        </Text>
                      </View>
                      {/* View 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        <Icon size={24} />
                      </View>
                      {/* View 3 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        {/* Icon 2 */}
                        <Icon size={24} />
                      </View>
                      {/* View 4 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        {/* Icon 3 */}
                        <Icon name={'Entypo/dot-single'} size={24} />
                      </View>
                      {/* View 5 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        {/* Icon 4 */}
                        <Icon name={'Entypo/dot-single'} size={24} />
                      </View>
                    </View>
                    {/* View 9 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          borderBottomWidth: 0.5,
                          borderColor: theme.colors['Divider'],
                          flexDirection: 'row',
                          height: 45,
                          justifyContent: 'space-evenly',
                          marginBottom: 15,
                          paddingBottom: 5,
                          paddingLeft: 5,
                          paddingRight: 5,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          { width: '50%' },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              { fontFamily: 'Inter_400Regular' }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Standard Business\nWebsite Creation'}
                        </Text>
                      </View>
                      {/* View 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        <Icon size={24} />
                      </View>
                      {/* View 3 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        {/* Icon 2 */}
                        <Icon size={24} />
                      </View>
                      {/* View 4 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        {/* Icon 3 */}
                        <Icon name={'Entypo/dot-single'} size={24} />
                      </View>
                      {/* View 5 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        {/* Icon 4 */}
                        <Icon name={'Entypo/dot-single'} size={24} />
                      </View>
                    </View>
                    {/* View 8 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          borderBottomWidth: 0.5,
                          borderColor: theme.colors['Divider'],
                          flexDirection: 'row',
                          height: 45,
                          justifyContent: 'space-evenly',
                          marginBottom: 15,
                          paddingBottom: 5,
                          paddingLeft: 5,
                          paddingRight: 5,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          { width: '50%' },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              { fontFamily: 'Inter_400Regular' }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Goal & Accountability\nAssistant '}
                        </Text>
                      </View>
                      {/* View 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        <Icon size={24} />
                      </View>
                      {/* View 3 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        {/* Icon 2 */}
                        <Icon size={24} />
                      </View>
                      {/* View 4 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        {/* Icon 3 */}
                        <Icon size={24} />
                      </View>
                      {/* View 5 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        {/* Icon 4 */}
                        <Icon name={'Entypo/dot-single'} size={24} />
                      </View>
                    </View>
                    {/* View 10 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          borderBottomWidth: 0.5,
                          borderColor: theme.colors['Divider'],
                          flexDirection: 'row',
                          height: 45,
                          justifyContent: 'space-evenly',
                          marginBottom: 15,
                          paddingBottom: 5,
                          paddingLeft: 5,
                          paddingRight: 5,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          { width: '50%' },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              { fontFamily: 'Inter_400Regular' }
                            ),
                            dimensions.width
                          )}
                        >
                          {'E-commerce\nWebsite Creation'}
                        </Text>
                      </View>
                      {/* View 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        <Icon size={24} />
                      </View>
                      {/* View 3 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        {/* Icon 2 */}
                        <Icon size={24} />
                      </View>
                      {/* View 4 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        {/* Icon 3 */}
                        <Icon size={24} />
                      </View>
                      {/* View 5 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '12.5%' },
                          dimensions.width
                        )}
                      >
                        {/* Icon 4 */}
                        <Icon name={'Entypo/dot-single'} size={24} />
                      </View>
                    </View>
                  </View>
                </Surface>
              </View>
            </BlurView>
          )}
        </>
        {/* Profile Header */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              backgroundColor: theme.colors['Akurie Dark Blue'],
              borderBottomWidth: 2,
              borderColor: theme.colors['Akurie green'],
              justifyContent: 'center',
              width: '100%',
            },
            dimensions.width
          )}
        >
          {/* View 2 */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: [
                  { minWidth: Breakpoints.Tablet, value: 'center' },
                  { minWidth: Breakpoints.Mobile, value: 'space-evenly' },
                ],
                marginBottom: 20,
                marginTop: [
                  { minWidth: Breakpoints.Laptop, value: 20 },
                  { minWidth: Breakpoints.Mobile, value: 60 },
                ],
                paddingLeft: 10,
                paddingRight: 10,
                width: '100%',
              },
              dimensions.width
            )}
          >
            <>
              {dimensions.width >= Breakpoints.Laptop ? null : (
                <IconButton
                  color={theme.colors['Strong Inverse']}
                  icon={'AntDesign/arrowleft'}
                  onPress={() => {
                    try {
                      navigation.goBack();
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  size={32}
                  style={StyleSheet.applyWidth(
                    { marginLeft: { minWidth: Breakpoints.Tablet, value: 20 } },
                    dimensions.width
                  )}
                />
              )}
            </>
            <Text
              accessible={true}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Akurie green'],
                  fontFamily: [
                    { minWidth: Breakpoints.Laptop, value: 'Inter_400Regular' },
                    { minWidth: Breakpoints.Mobile, value: 'Inter_500Medium' },
                  ],
                  fontSize: [
                    { minWidth: Breakpoints.Laptop, value: 18 },
                    { minWidth: Breakpoints.Mobile, value: 20 },
                  ],
                }),
                dimensions.width
              )}
            >
              {'Manage My Subscription'}
            </Text>
            {/* Icon Button 2 */}
            <>
              {dimensions.width >= Breakpoints.Laptop ? null : (
                <IconButton
                  color={theme.colors['Strong Inverse']}
                  icon={'Ionicons/help-circle-outline'}
                  onPress={() => {
                    try {
                      navigation.navigate('HelpSupportScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  size={32}
                  style={StyleSheet.applyWidth(
                    { marginLeft: { minWidth: Breakpoints.Tablet, value: 20 } },
                    dimensions.width
                  )}
                />
              )}
            </>
          </View>
        </View>
        {/* View 3 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: [
                { minWidth: Breakpoints.Laptop, value: 'center' },
                { minWidth: Breakpoints.Tablet, value: 'center' },
                { minWidth: Breakpoints.Mobile, value: 'stretch' },
              ],
              height: { minWidth: Breakpoints.Tablet, value: '85%' },
              justifyContent: [
                { minWidth: Breakpoints.Mobile, value: 'space-around' },
                { minWidth: Breakpoints.Tablet, value: 'center' },
              ],
              marginTop: { minWidth: Breakpoints.BigScreen, value: 40 },
              paddingLeft: [
                { minWidth: Breakpoints.Desktop, value: '15%' },
                { minWidth: Breakpoints.Laptop, value: '10%' },
                { minWidth: Breakpoints.BigScreen, value: '20%' },
              ],
              paddingRight: [
                { minWidth: Breakpoints.Desktop, value: '15%' },
                { minWidth: Breakpoints.Laptop, value: '10%' },
                { minWidth: Breakpoints.BigScreen, value: '20%' },
              ],
              paddingTop: 10,
              width: '100%',
            },
            dimensions.width
          )}
        >
          {/* View 3 */}
          <View
            style={StyleSheet.applyWidth(
              {
                width: [
                  { minWidth: Breakpoints.Laptop, value: '60%' },
                  { minWidth: Breakpoints.Tablet, value: '60%' },
                ],
              },
              dimensions.width
            )}
          >
            {/* Paywall pt1 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: theme.colors['Akurie Dark Blue'],
                  borderColor: theme.colors['Akurie green'],
                  justifyContent: 'center',
                  width: '100%',
                },
                dimensions.width
              )}
            >
              <AuthAkurieApi.FetchGetSpecificUserGET
                user_id={Constants['ak_userID']}
              >
                {({ loading, error, data, refetchGetSpecificUser }) => {
                  const fetchData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <>
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Akurie green'],
                            fontFamily: 'Inter_600SemiBold',
                            fontSize: 16,
                            marginBottom: 10,
                            marginTop: 10,
                            paddingBottom: 8,
                            paddingTop: 8,
                            textAlign: 'center',
                          },
                          dimensions.width
                        )}
                      >
                        {'Current Plan: '}
                        {fetchData?.Subscription_Type}
                      </Text>
                    </>
                  );
                }}
              </AuthAkurieApi.FetchGetSpecificUserGET>
            </View>
            {/* View 2 2 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  marginTop: 20,
                  padding: 20,
                  width: '100%',
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', width: '100%' },
                  dimensions.width
                )}
              >
                <Surface
                  elevation={1}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.SurfaceStyles(theme)['Surface'],
                      {
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderRadius: 9,
                        marginBottom: 5,
                        width: { minWidth: Breakpoints.Tablet, value: '100%' },
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        marginBottom: 10,
                        padding: 10,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'contain'}
                      source={Images.Asset23}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            height: 70,
                            marginLeft: 5,
                            marginRight: 10,
                            width: 70,
                          }
                        ),
                        dimensions.width
                      )}
                    />
                    <View
                      style={StyleSheet.applyWidth(
                        { width: '75%' },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Akurie Primary'],
                              fontFamily: 'Inter_600SemiBold',
                              fontSize: 16,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Business Growth and Planning'}
                      </Text>
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Strong'],
                              fontFamily: 'Inter_400Regular',
                              fontSize: 13,
                              marginTop: 5,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {
                          "We help your business grow, launch, and adapt to your customer's needs with automation and step-by-step guidance."
                        }
                      </Text>
                    </View>
                  </View>
                </Surface>
                {/* Surface 2 */}
                <Surface
                  elevation={1}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.SurfaceStyles(theme)['Surface'],
                      {
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderRadius: 9,
                        marginBottom: 5,
                        width: { minWidth: Breakpoints.Tablet, value: '100%' },
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        marginBottom: 10,
                        padding: 10,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'contain'}
                      source={Images.Asset60}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            height: 70,
                            marginLeft: 5,
                            marginRight: 10,
                            width: 70,
                          }
                        ),
                        dimensions.width
                      )}
                    />
                    <View
                      style={StyleSheet.applyWidth(
                        { width: '75%' },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Akurie Primary'],
                              fontFamily: 'Inter_600SemiBold',
                              fontSize: 16,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'My Website & Social Media'}
                      </Text>
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Strong'],
                              fontFamily: 'Inter_400Regular',
                              fontSize: 13,
                              marginTop: 5,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {
                          'Get a free SEO-optimized website along with automated web and social media analytics with detailed insights.'
                        }
                      </Text>
                    </View>
                  </View>
                </Surface>
                {/* Surface 3 */}
                <Surface
                  elevation={1}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.SurfaceStyles(theme)['Surface'],
                      {
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderRadius: 9,
                        marginBottom: 5,
                        width: { minWidth: Breakpoints.Tablet, value: '100%' },
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {/* View 3 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        marginBottom: 10,
                        padding: 10,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'contain'}
                      source={Images.Asset29}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            height: 70,
                            marginLeft: 5,
                            marginRight: 10,
                            width: 70,
                          }
                        ),
                        dimensions.width
                      )}
                    />
                    <View
                      style={StyleSheet.applyWidth(
                        { width: '75%' },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Akurie Primary'],
                              fontFamily: 'Inter_600SemiBold',
                              fontSize: 16,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Professional Services'}
                      </Text>
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Strong'],
                              fontFamily: 'Inter_400Regular',
                              fontSize: 13,
                              marginTop: 5,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {
                          'Upon request, we match your business with the best CPAs, Lawyers, and other specialists for your needs.'
                        }
                      </Text>
                    </View>
                  </View>
                </Surface>

                <Surface
                  elevation={1}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.SurfaceStyles(theme)['Surface'],
                      {
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderRadius: 9,
                        width: { minWidth: Breakpoints.Tablet, value: '100%' },
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {/* View 4 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        marginBottom: 10,
                        padding: 10,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'contain'}
                      source={Images.Asset14}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            height: 70,
                            marginLeft: 5,
                            marginRight: 10,
                            width: 70,
                          }
                        ),
                        dimensions.width
                      )}
                    />
                    <View
                      style={StyleSheet.applyWidth(
                        { width: '75%' },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Akurie Primary'],
                              fontFamily: 'Inter_600SemiBold',
                              fontSize: 16,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Compliance & Annual Filings'}
                      </Text>
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Strong'],
                              fontFamily: 'Inter_400Regular',
                              fontSize: 13,
                              marginTop: 5,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {
                          'We manage state-required business forms such as annual reports and business formation / organization documents at no cost except state fees.'
                        }
                      </Text>
                    </View>
                  </View>
                </Surface>
              </View>
            </View>
            <FlatList
              data={currentOfferings}
              keyExtractor={(listData, index) =>
                listData?.id ?? listData?.uuid ?? index.toString()
              }
              keyboardShouldPersistTaps={'never'}
              listKey={'rID9EdP1'}
              numColumns={1}
              onEndReachedThreshold={0.5}
              renderItem={({ item, index }) => {
                const listData = item;
                return (
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', flexDirection: 'column' },
                      dimensions.width
                    )}
                  >
                    {/* Event Block 6 */}
                    <Touchable
                      onPress={() => {
                        const handler = async () => {
                          try {
                            const cusIn = await purchaseItem(listData);
                            if (cusIn) {
                              return;
                            }
                            navigation.navigate('UserDashboardScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        { width: '100%' },
                        dimensions.width
                      )}
                    >
                      {/* Banner */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginBottom: 5,
                            marginLeft: 15,
                            marginRight: 15,
                            marginTop: 5,
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              backgroundColor: theme.colors['Akurie Dark Blue'],
                              borderRadius: 15,
                              height: 60,
                              justifyContent: 'center',
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        >
                          {/* Title */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Akurie green'],
                                fontFamily: 'Inter_500Medium',
                                fontSize: 14,
                              },
                              dimensions.width
                            )}
                          >
                            {'Cancel Assistant Pro Plan'}
                          </Text>
                        </View>
                      </View>
                    </Touchable>
                  </View>
                );
              }}
              showsHorizontalScrollIndicator={true}
              showsVerticalScrollIndicator={true}
            />
          </View>
        </View>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(PaywallProCurrentMonth3Screen);
