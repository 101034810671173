import React from 'react';
import * as AuthAkurieApi from '../apis/AuthAkurieApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { Touchable, withTheme } from '@draftbit/ui';
import { useNavigation } from '@react-navigation/native';
import { Image, Text, View } from 'react-native';

const NetworkingBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const navigation = useNavigation();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  return (
    <Touchable
      onPress={() => {
        try {
          navigation.navigate('Signuppt1Screen');
        } catch (err) {
          console.error(err);
        }
      }}
    >
      {/* Banner */}
      <View
        style={StyleSheet.applyWidth(
          { flexDirection: 'row', justifyContent: 'space-between' },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors['Akurie green'],
              borderRadius: 15,
              height: 100,
              justifyContent: 'center',
              paddingBottom: 10,
              paddingLeft: 20,
              paddingRight: 10,
              paddingTop: 10,
              width: '100%',
            },
            dimensions.width
          )}
        >
          {/* Title */}
          <Text
            style={StyleSheet.applyWidth(
              {
                color: theme.colors['Akurie Dark Blue'],
                fontFamily: 'Inter_500Medium',
                fontSize: 18,
              },
              dimensions.width
            )}
          >
            {'Have You Been Invited?'}
          </Text>
          {/* Title */}
          <Text
            style={StyleSheet.applyWidth(
              {
                color: theme.colors['Akurie Dark Blue'],
                fontFamily: 'Inter_500Medium',
                fontSize: 12,
                marginTop: 5,
              },
              dimensions.width
            )}
          >
            {'Join us at the next Akurie networking event.'}
          </Text>
        </View>
        <Image
          resizeMode={'contain'}
          source={Images.Asset30}
          style={StyleSheet.applyWidth(
            {
              bottom: 15,
              height: 70,
              position: 'absolute',
              right: 10,
              width: 70,
            },
            dimensions.width
          )}
        />
      </View>
    </Touchable>
  );
};

export default withTheme(NetworkingBlock);
