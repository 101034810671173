import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BusinessDataAkurieApi from '../apis/BusinessDataAkurieApi.js';
import * as Logs$SupportAkurieApi from '../apis/Logs$SupportAkurieApi.js';
import * as StateBusinessDataAkurieApi from '../apis/StateBusinessDataAkurieApi.js';
import * as StripeAkurieApi from '../apis/StripeAkurieApi.js';
import * as StripeV2AkurieApi from '../apis/StripeV2AkurieApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Checkbox,
  Circle,
  CircularProgress,
  Icon,
  IconButton,
  ScreenContainer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  Keyboard,
  Text,
  View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const BusinessLLCSetupInfo3Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [errorMsg, setErrorMsg] = React.useState('');
  const [initialsConfirmationValue, setInitialsConfirmationValue] =
    React.useState('');
  const businessDataAkurieFormationConfirmationPOST =
    BusinessDataAkurieApi.useFormationConfirmationPOST();
  const stripeV2AkurieCreatingPrice$ProductPOST =
    StripeV2AkurieApi.useCreatingPrice$ProductPOST();
  const stripeV2AkurieCreateCheckoutSessionPOST =
    StripeV2AkurieApi.useCreateCheckoutSessionPOST();
  const logs$SupportAkurieBasicActivityPOST =
    Logs$SupportAkurieApi.useBasicActivityPOST();
  const stripeAkurieCreateSessionPOST = StripeAkurieApi.useCreateSessionPOST();

  return (
    <ScreenContainer
      hasBottomSafeArea={false}
      hasSafeArea={false}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: theme.colors['Background'],
          justifyContent: 'space-between',
        },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'cover'}
        source={Images.Asset6}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ImageBackgroundStyles(theme)['Image Background'],
            { height: '100%', justifyContent: 'center' }
          ),
          dimensions.width
        )}
      >
        <KeyboardAwareScrollView
          contentContainerStyle={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              justifyContent: { minWidth: Breakpoints.Tablet, value: 'center' },
              marginTop: 10,
              paddingLeft: [
                { minWidth: Breakpoints.Tablet, value: '10%' },
                { minWidth: Breakpoints.Laptop, value: '0%' },
              ],
              paddingRight: [
                { minWidth: Breakpoints.Laptop, value: '0%' },
                { minWidth: Breakpoints.Tablet, value: '10%' },
              ],
              paddingTop: [
                { minWidth: Breakpoints.Tablet, value: 50 },
                { minWidth: Breakpoints.Laptop, value: 0 },
                { minWidth: Breakpoints.Desktop, value: 40 },
              ],
            },
            dimensions.width
          )}
          enableAutomaticScroll={true}
          enableOnAndroid={false}
          enableResetScrollToCoords={false}
          extraHeight={100}
          extraScrollHeight={100}
          keyboardShouldPersistTaps={'always'}
          showsVerticalScrollIndicator={false}
        >
          {/* View 2 */}
          <>
            {!(dimensions.width >= Breakpoints.Laptop) ? null : (
              <View
                style={StyleSheet.applyWidth(
                  { width: { minWidth: Breakpoints.Laptop, value: '80%' } },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'contain'}
                  source={Images.MainLogoUpdate2u}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'],
                      {
                        height: { minWidth: Breakpoints.Laptop, value: 50 },
                        width: { minWidth: Breakpoints.Laptop, value: '30%' },
                      }
                    ),
                    dimensions.width
                  )}
                />
              </View>
            )}
          </>
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: [
                  { minWidth: Breakpoints.Laptop, value: 'center' },
                  { minWidth: Breakpoints.Mobile, value: 'center' },
                ],
                flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
                justifyContent: [
                  { minWidth: Breakpoints.Laptop, value: 'center' },
                  { minWidth: Breakpoints.Mobile, value: 'center' },
                ],
                marginTop: { minWidth: Breakpoints.Desktop, value: 30 },
                width: '100%',
              },
              dimensions.width
            )}
          >
            {/* Master Form View 2 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: [
                        {
                          minWidth: Breakpoints.Tablet,
                          value: theme.colors['Akurie Dark Blue'],
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors['Akurie Dark Blue'],
                        },
                      ],
                      borderBottomLeftRadius: {
                        minWidth: Breakpoints.Laptop,
                        value: 12,
                      },
                      borderRadius: [
                        { minWidth: Breakpoints.Tablet, value: 12 },
                        { minWidth: Breakpoints.Mobile, value: 12 },
                        { minWidth: Breakpoints.Laptop, value: 0 },
                      ],
                      borderTopLeftRadius: {
                        minWidth: Breakpoints.Laptop,
                        value: 12,
                      },
                      height: [
                        { minWidth: Breakpoints.Tablet, value: '80%' },
                        { minWidth: Breakpoints.Laptop, value: '85%' },
                        { minWidth: Breakpoints.Desktop, value: '90%' },
                        { minWidth: Breakpoints.BigScreen, value: '85%' },
                      ],
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      marginBottom: [
                        { minWidth: Breakpoints.Mobile, value: 80 },
                        { minWidth: Breakpoints.Laptop, value: 20 },
                      ],
                      marginTop: [
                        { minWidth: Breakpoints.Mobile, value: 80 },
                        { minWidth: Breakpoints.Laptop, value: 20 },
                      ],
                      opacity: [
                        { minWidth: Breakpoints.Tablet, value: 0.95 },
                        { minWidth: Breakpoints.Mobile, value: 0.95 },
                      ],
                      padding: [
                        { minWidth: Breakpoints.Tablet, value: 20 },
                        { minWidth: Breakpoints.Mobile, value: 10 },
                        { minWidth: Breakpoints.BigScreen, value: 40 },
                      ],
                      paddingLeft: [
                        { minWidth: Breakpoints.Mobile, value: 20 },
                        { minWidth: Breakpoints.BigScreen, value: 40 },
                        { minWidth: Breakpoints.Desktop, value: 40 },
                        { minWidth: Breakpoints.Laptop, value: 10 },
                      ],
                      paddingRight: [
                        { minWidth: Breakpoints.Mobile, value: 20 },
                        { minWidth: Breakpoints.BigScreen, value: 40 },
                        { minWidth: Breakpoints.Desktop, value: 40 },
                        { minWidth: Breakpoints.Laptop, value: 10 },
                      ],
                      width: [
                        { minWidth: Breakpoints.Mobile, value: '90%' },
                        { minWidth: Breakpoints.Desktop, value: '25%' },
                        { minWidth: Breakpoints.Laptop, value: '40%' },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        marginBottom: 10,
                        marginTop: 10,
                        padding: 5,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <>
                      {!(dimensions.width >= Breakpoints.Tablet) ? null : (
                        <IconButton
                          color={theme.colors['Akurie green']}
                          icon={'MaterialCommunityIcons/arrow-left-circle'}
                          onPress={() => {
                            try {
                              navigation.goBack();
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          size={40}
                          style={StyleSheet.applyWidth(
                            { marginRight: 10 },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Strong_Inverse'],
                            fontFamily: 'Inter_400Regular',
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 22 },
                              { minWidth: Breakpoints.Laptop, value: 18 },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Return to Business Info'}
                    </Text>
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: [
                          { minWidth: Breakpoints.Mobile, value: 'row' },
                          { minWidth: Breakpoints.Laptop, value: 'column' },
                        ],
                        justifyContent: 'center',
                        marginBottom: 10,
                        marginTop: 10,
                        padding: 5,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: [
                              {
                                minWidth: Breakpoints.Mobile,
                                value: theme.colors['Strong_Inverse'],
                              },
                              {
                                minWidth: Breakpoints.Laptop,
                                value: theme.colors['Akurie Primary'],
                              },
                            ],
                            fontFamily: [
                              {
                                minWidth: Breakpoints.Mobile,
                                value: 'Inter_400Regular',
                              },
                              {
                                minWidth: Breakpoints.Laptop,
                                value: 'Inter_400Regular',
                              },
                              {
                                minWidth: Breakpoints.BigScreen,
                                value: 'Inter_600SemiBold',
                              },
                            ],
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 22 },
                              { minWidth: Breakpoints.Laptop, value: 32 },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Almost Done'}
                    </Text>
                  </View>
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        marginBottom: {
                          minWidth: Breakpoints.Laptop,
                          value: 10,
                        },
                        marginTop: { minWidth: Breakpoints.Laptop, value: 10 },
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'contain'}
                      source={Images.Asset29}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: 150,
                            },
                            width: { minWidth: Breakpoints.Laptop, value: 150 },
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                </View>
              )}
            </>
            {/* Master Form View */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: [
                    {
                      minWidth: Breakpoints.Tablet,
                      value: theme.colors['Akurie Dark Blue'],
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: theme.colors['Akurie Dark Blue'],
                    },
                  ],
                  borderColor: {
                    minWidth: Breakpoints.Laptop,
                    value: theme.colors['Medium'],
                  },
                  borderLeftWidth: { minWidth: Breakpoints.Laptop, value: 0.5 },
                  borderRadius: [
                    { minWidth: Breakpoints.Tablet, value: 12 },
                    { minWidth: Breakpoints.Mobile, value: 12 },
                    { minWidth: Breakpoints.Laptop, value: 12 },
                  ],
                  height: [
                    { minWidth: Breakpoints.Tablet, value: '80%' },
                    { minWidth: Breakpoints.Desktop, value: '95%' },
                    { minWidth: Breakpoints.BigScreen, value: '95%' },
                    { minWidth: Breakpoints.Laptop, value: '90%' },
                  ],
                  justifyContent: [
                    { minWidth: Breakpoints.BigScreen, value: 'center' },
                    { minWidth: Breakpoints.Laptop, value: 'center' },
                  ],
                  marginBottom: [
                    { minWidth: Breakpoints.Mobile, value: 80 },
                    { minWidth: Breakpoints.Laptop, value: 20 },
                  ],
                  marginTop: [
                    { minWidth: Breakpoints.Mobile, value: 80 },
                    { minWidth: Breakpoints.Laptop, value: 20 },
                  ],
                  opacity: [
                    { minWidth: Breakpoints.Tablet, value: 0.95 },
                    { minWidth: Breakpoints.Mobile, value: 0.95 },
                  ],
                  padding: [
                    { minWidth: Breakpoints.Tablet, value: 20 },
                    { minWidth: Breakpoints.Mobile, value: 10 },
                    { minWidth: Breakpoints.BigScreen, value: 40 },
                  ],
                  paddingBottom: 20,
                  paddingLeft: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.BigScreen, value: 40 },
                    { minWidth: Breakpoints.Desktop, value: 40 },
                    { minWidth: Breakpoints.Laptop, value: 20 },
                  ],
                  paddingRight: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.BigScreen, value: 40 },
                    { minWidth: Breakpoints.Desktop, value: 40 },
                    { minWidth: Breakpoints.Laptop, value: 20 },
                  ],
                  width: [
                    { minWidth: Breakpoints.Mobile, value: '90%' },
                    { minWidth: Breakpoints.Laptop, value: '30%' },
                    { minWidth: Breakpoints.Desktop, value: '20%' },
                  ],
                },
                dimensions.width
              )}
            >
              <>
                {dimensions.width >= Breakpoints.Laptop ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        marginBottom: 10,
                        marginTop: 10,
                        padding: 5,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <IconButton
                      color={theme.colors['Akurie Primary']}
                      icon={'MaterialCommunityIcons/arrow-left-circle'}
                      onPress={() => {
                        try {
                          navigation.goBack();
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      size={40}
                      style={StyleSheet.applyWidth(
                        { marginRight: 10 },
                        dimensions.width
                      )}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Strong_Inverse'],
                            fontFamily: 'Inter_400Regular',
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 22 },
                              { minWidth: Breakpoints.Laptop, value: 18 },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Creating Your LLC'}
                    </Text>
                  </View>
                )}
              </>
              <>
                {dimensions.width >= Breakpoints.Laptop ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: 'row',
                        justifyContent: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    {/* First Name View */}
                    <View
                      style={StyleSheet.applyWidth(
                        { paddingBottom: 5, paddingTop: 5, width: '100%' },
                        dimensions.width
                      )}
                    >
                      {/* First Name */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Akurie green'],
                            fontFamily: 'Inter_600SemiBold',
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 16 },
                              { minWidth: Breakpoints.Laptop, value: 14 },
                            ],
                          },
                          dimensions.width
                        )}
                      >
                        {'Articles of Organization Info'}
                      </Text>
                      {/* First Name 2 */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Strong Inverse'],
                            fontFamily: 'Inter_400Regular',
                            fontSize: [
                              { minWidth: Breakpoints.Laptop, value: 14 },
                              { minWidth: Breakpoints.Mobile, value: 14 },
                            ],
                            marginTop: 5,
                            textAlign: 'left',
                          },
                          dimensions.width
                        )}
                      >
                        {
                          'This information will be used in the documents we file with the state.'
                        }
                      </Text>
                    </View>
                  </View>
                )}
              </>
              <StateBusinessDataAkurieApi.FetchLLCFormationCostGET
                state={props.route?.params?.statePass ?? 'Illinois'}
              >
                {({ loading, error, data, refetchLLCFormationCost }) => {
                  const fetchData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <>
                      {/* Form */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'stretch',
                            marginTop: 10,
                            opacity: 1,
                            width: [
                              { minWidth: Breakpoints.Mobile, value: '100%' },
                              { minWidth: Breakpoints.Laptop, value: '95%' },
                            ],
                          },
                          dimensions.width
                        )}
                      >
                        {/* First Name 4 */}
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors['Akurie green'],
                              fontFamily: 'Inter_600SemiBold',
                              fontSize: [
                                { minWidth: Breakpoints.Laptop, value: 14 },
                                { minWidth: Breakpoints.Mobile, value: 16 },
                              ],
                              marginTop: 15,
                            },
                            dimensions.width
                          )}
                        >
                          {'Fees & Costs'}
                        </Text>
                        {/* Email View 5 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { paddingTop: 5 },
                            dimensions.width
                          )}
                        >
                          {/* Email */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Akurie Primary'],
                                fontFamily: 'Inter_600SemiBold',
                                fontSize: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 13,
                                },
                                marginBottom: 5,
                                marginTop: 5,
                              },
                              dimensions.width
                            )}
                          >
                            {props.route?.params?.statePass ?? 'Illinois'}
                            {' LLC Organization Filing Cost '}
                          </Text>
                          {/* Email 2 */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Strong Inverse'],
                                fontFamily: 'Inter_600SemiBold',
                                fontSize: [
                                  { minWidth: Breakpoints.Laptop, value: 13 },
                                  { minWidth: Breakpoints.Mobile, value: 16 },
                                ],
                                marginBottom: 5,
                                marginTop: 3,
                              },
                              dimensions.width
                            )}
                          >
                            {'$'}
                            {fetchData?.Formation_Cost}
                          </Text>
                        </View>
                        {/* Email View 6 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { paddingTop: 5 },
                            dimensions.width
                          )}
                        >
                          {/* Email */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Akurie Primary'],
                                fontFamily: 'Inter_600SemiBold',
                                fontSize: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 13,
                                },
                                marginBottom: 5,
                                marginTop: 5,
                              },
                              dimensions.width
                            )}
                          >
                            {'Digital / Card Processing Fee'}
                          </Text>
                          {/* Email 2 */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Strong Inverse'],
                                fontFamily: 'Inter_600SemiBold',
                                fontSize: [
                                  { minWidth: Breakpoints.Laptop, value: 13 },
                                  { minWidth: Breakpoints.Mobile, value: 16 },
                                ],
                                marginBottom: 5,
                                marginTop: 3,
                              },
                              dimensions.width
                            )}
                          >
                            {'$'}
                            {fetchData?.processing_fee}
                          </Text>
                        </View>
                        {/* Email View 7 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { paddingTop: 5 },
                            dimensions.width
                          )}
                        >
                          {/* Email */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Akurie Primary'],
                                fontFamily: 'Inter_600SemiBold',
                                fontSize: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 13,
                                },
                                marginBottom: 5,
                                marginTop: 5,
                              },
                              dimensions.width
                            )}
                          >
                            {'Akurie Assistant Fee'}
                          </Text>
                          {/* Email 2 */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Strong Inverse'],
                                fontFamily: 'Inter_600SemiBold',
                                fontSize: [
                                  { minWidth: Breakpoints.Laptop, value: 13 },
                                  { minWidth: Breakpoints.Mobile, value: 16 },
                                ],
                                marginBottom: 5,
                                marginTop: 3,
                              },
                              dimensions.width
                            )}
                          >
                            {'$0.00 - Yes, Zero.'}
                          </Text>
                          {/* Email 3 */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Strong Inverse'],
                                fontFamily: 'Inter_400Regular',
                                fontSize: [
                                  { minWidth: Breakpoints.Laptop, value: 13 },
                                  { minWidth: Breakpoints.Mobile, value: 20 },
                                ],
                                marginBottom: 5,
                                marginTop: 10,
                              },
                              dimensions.width
                            )}
                          >
                            {'Total: $'}
                            {fetchData?.Total}
                          </Text>
                        </View>
                        {/* First Name 3 */}
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors['Akurie green'],
                              fontFamily: 'Inter_600SemiBold',
                              fontSize: [
                                { minWidth: Breakpoints.Laptop, value: 14 },
                                { minWidth: Breakpoints.Mobile, value: 16 },
                              ],
                              marginTop: 15,
                            },
                            dimensions.width
                          )}
                        >
                          {'Confirmation'}
                        </Text>
                        {/* Title View */}
                        <View
                          style={StyleSheet.applyWidth(
                            { paddingBottom: 5, paddingTop: 5 },
                            dimensions.width
                          )}
                        >
                          {/* Title */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Strong Inverse'],
                                fontFamily: 'Inter_400Regular',
                                fontSize: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 13,
                                },
                                marginBottom: 10,
                                marginTop: 10,
                              },
                              dimensions.width
                            )}
                          >
                            {
                              'By signing my initials below, I acknowledge that I have reviewed all provided information, confirm its accuracy, and understand the costs and fees associated with this service.\n\nYour documents will be submitted to the state of '
                            }
                            {'Illinois'}
                            {
                              ' upon your membership confirmation and payment processing.'
                            }
                          </Text>
                        </View>
                        {/* Password View */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              marginBottom: {
                                minWidth: Breakpoints.Desktop,
                                value: 5,
                              },
                              paddingBottom: 5,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Password */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Strong Inverse'],
                                fontFamily: 'Inter_600SemiBold',
                                fontSize: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 13,
                                },
                                marginBottom: 5,
                                marginTop: 5,
                              },
                              dimensions.width
                            )}
                          >
                            {'Your Initials'}
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  { color: theme.colors['Error'] }
                                ),
                                dimensions.width
                              )}
                            >
                              {'*'}
                            </Text>
                          </Text>
                          {/* initialsConfirmation */}
                          <TextInput
                            onChangeText={newInitialsConfirmationValue => {
                              try {
                                setInitialsConfirmationValue(
                                  newInitialsConfirmationValue
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            onSubmitEditing={() => {
                              try {
                                Keyboard.dismiss();
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            placeholder={'Ex. AB'}
                            placeholderTextColor={
                              theme.colors['Communial_Icon_Gray']
                            }
                            secureTextEntry={false}
                            spellcheck={true}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Background'],
                                borderBottomWidth: 1,
                                borderColor:
                                  theme.colors['Communial_Icon_Gray'],
                                borderLeftWidth: 1,
                                borderRadius: 8,
                                borderRightWidth: 1,
                                borderTopWidth: 1,
                                fontSize: 16,
                                height: [
                                  { minWidth: Breakpoints.Mobile, value: 60 },
                                  { minWidth: Breakpoints.Laptop, value: 60 },
                                ],
                                paddingBottom: 10,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 10,
                              },
                              dimensions.width
                            )}
                            textContentType={'password'}
                            value={initialsConfirmationValue}
                          />
                        </View>
                      </View>
                    </>
                  );
                }}
              </StateBusinessDataAkurieApi.FetchLLCFormationCostGET>
              {/* Button Holder View */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    marginBottom: [
                      { minWidth: Breakpoints.Tablet, value: 40 },
                      { minWidth: Breakpoints.Laptop, value: 0 },
                    ],
                    paddingBottom: 10,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                {/* Error Msg 2 */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Error'],
                      fontFamily: 'Inter_400Regular',
                      fontSize: { minWidth: Breakpoints.Laptop, value: 13 },
                      paddingBottom: 10,
                      paddingTop: 10,
                    },
                    dimensions.width
                  )}
                >
                  {errorMsg}
                </Text>
                {/* Button */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderRadius: 0,
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'row',
                      },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'space-evenly',
                      },
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* Touchable Frame */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        width: [
                          { minWidth: Breakpoints.Mobile, value: '100%' },
                          { minWidth: Breakpoints.Laptop, value: '50%' },
                        ],
                        zIndex: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        const handler = async () => {
                          try {
                            const initialsResult = (
                              await businessDataAkurieFormationConfirmationPOST.mutateAsync(
                                {
                                  user_id: Constants['ak_userID'],
                                  user_initials: initialsConfirmationValue,
                                }
                              )
                            )?.json;
                            const ErrorMsgResults = initialsResult?.message;
                            setErrorMsg(ErrorMsgResults);
                            if (ErrorMsgResults) {
                              return;
                            }
                            undefined;
                            const llcPricingData = (
                              await StateBusinessDataAkurieApi.lLCFormationCostGET(
                                Constants,
                                {
                                  state:
                                    props.route?.params?.statePass ??
                                    'Illinois',
                                }
                              )
                            )?.json;
                            const productSetupData = (
                              await stripeV2AkurieCreatingPrice$ProductPOST.mutateAsync(
                                {
                                  description:
                                    'EIN & LLC Filing and Documents ',
                                  price: llcPricingData?.TrueTotal,
                                  productName:
                                    props.route?.params?.statePass ??
                                    'Illinois',
                                }
                              )
                            )?.json;
                            const sessionResponse = (
                              await stripeV2AkurieCreateCheckoutSessionPOST.mutateAsync(
                                { priceID: productSetupData?.api1 }
                              )
                            )?.json;
                            await WebBrowser.openBrowserAsync(
                              `${sessionResponse?.response?.result?.url}`
                            );
                            (
                              await logs$SupportAkurieBasicActivityPOST.mutateAsync(
                                {
                                  buttonName: 'businessFormationCheckout',
                                  session_id: Constants['Session_ID'],
                                }
                              )
                            )?.json;
                            /* hidden 'API Request' action */
                            setGlobalVariableValue({
                              key: 'ak_isLoading',
                              value: false,
                            });
                            if (Constants['subActive'] === false) {
                              navigation.navigate(
                                'PaywallProCurrentMonth2Screen'
                              );
                            }
                            if (Constants['subActive'] === true) {
                              navigation.navigate('UserDashboardScreen');
                            }
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        { borderRadius: 0 },
                        dimensions.width
                      )}
                    >
                      {/* CTA Frame */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            backgroundColor: [
                              {
                                minWidth: Breakpoints.Laptop,
                                value: theme.colors['Akurie green'],
                              },
                              {
                                minWidth: Breakpoints.Mobile,
                                value: theme.colors['Akurie green'],
                              },
                            ],
                            borderRadius: 12,
                            flexDirection: 'row',
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'space-between',
                            },
                            minHeight: 60,
                            paddingBottom: 12,
                            paddingLeft: 18,
                            paddingRight: 18,
                            paddingTop: 12,
                            zIndex: 10,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Label Frame */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexGrow: 1,
                              flexShrink: 0,
                              justifyContent: 'center',
                              width: {
                                minWidth: Breakpoints.Laptop,
                                value: '100%',
                              },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Continue Label */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: [
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: theme.colors['Akurie Dark Blue'],
                                  },
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: theme.colors['Akurie Dark Blue'],
                                  },
                                ],
                                fontFamily: 'Inter_400Regular',
                                fontSize: [
                                  { minWidth: Breakpoints.Mobile, value: 15 },
                                  { minWidth: Breakpoints.Laptop, value: 13 },
                                ],
                                lineHeight: 21,
                                marginLeft: [
                                  { minWidth: Breakpoints.Desktop, value: 0 },
                                  { minWidth: Breakpoints.Laptop, value: 0 },
                                ],
                                textTransform: 'uppercase',
                              },
                              dimensions.width
                            )}
                          >
                            {'Confirm & Checkout'}
                          </Text>
                        </View>
                      </View>
                    </Touchable>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </KeyboardAwareScrollView>
      </ImageBackground>
      {/* loading_Circle */}
      <>
        {!Constants['ak_isLoading'] ? null : (
          <BlurView
            intensity={80}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.BlurViewStyles(theme)['Blur View'],
                {
                  alignItems: 'center',
                  height: '100%',
                  justifyContent: 'center',
                  position: 'absolute',
                  width: '100%',
                  zIndex: 10,
                }
              ),
              dimensions.width
            )}
            tint={'dark'}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: theme.colors['Akurie Dark Blue'],
                  borderRadius: 19,
                  bottom: 50,
                  height: '40%',
                  justifyContent: 'center',
                  opacity: 1,
                  width: '70%',
                },
                dimensions.width
              )}
            >
              <CircularProgress
                animationDuration={7000}
                color={theme.colors['Akurie green']}
                indeterminate={true}
                isAnimated={true}
                lineCap={'round'}
                showTrack={true}
                startPosition={'bottom'}
                style={StyleSheet.applyWidth(
                  { bottom: -20, height: '80%', width: '80%' },
                  dimensions.width
                )}
                thickness={15}
                trackColor={theme.colors.divider}
                trackLineCap={'round'}
              />
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Strong Inverse'],
                    fontFamily: 'Inter_600SemiBold',
                    fontSize: 20,
                    marginTop: 10,
                  }),
                  dimensions.width
                )}
              >
                {'Processing Request'}
              </Text>
            </View>
          </BlurView>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(BusinessLLCSetupInfo3Screen);
