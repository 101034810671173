/**
 * Learn more about deep linking with React Navigation.
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import * as Linking from "expo-linking";
import { Platform } from "react-native";
function renderLinkingPrefix() {
  try {
    return Linking.createURL("/");
  } catch (e) {
    return "draftbit://";
  }
}

const prefix = renderLinkingPrefix();
const linking = {
  prefixes: [prefix],
  config: {
    screens: {
      AkurieMobileAppPromoScreen: "AkurieMobileAppPromo/:escape?",
      AkurieSecuredCardScreen: "AkurieSecuredCard/:escape?",
      AllowNotificationsScreen: "AllowNotifications/:escape?",
      ApplyAkurieCardScreen: "ApplyAkurieCard/:escape?",
      AskMyAssistantScreen: "AskMyAssistant/:escape?",
      AutoFilingSetup1Screen: "AutoFilingSetup1/:escape?",
      AutoFilingSetup2Screen: "AutoFilingSetup2/:escape?",
      AutoFilingSetup3Screen: "AutoFilingSetup3/:escape?",
      BusinessCorpSetupInfo1Screen: "BusinessCorpSetupInfo1/:escape?",
      BusinessCorpSetupInfo2Screen: "BusinessCorpSetupInfo2/:escape?",
      BusinessCorpSetupInfo3Screen: "BusinessCorpSetupInfo3/:escape?",
      BusinessFundingScreen: "BusinessFunding/:escape?",
      BusinessGoalsActionPlansScreen: "BusinessGoalsActionPlans/:escape?",
      BusinessIdeaSetup1Screen: "BusinessIdeaSetup1/:escape?",
      BusinessIdeaSetup2Screen: "BusinessIdeaSetup2/:escape?",
      BusinessIdeaSetup3Screen: "BusinessIdeaSetup3/:escape?",
      BusinessInfoScreen: "BusinessInfo/:escape?",
      BusinessLLCSetupInfo1Screen: "BusinessLLCSetupInfo1/:escape?",
      BusinessLLCSetupInfo2Screen: "BusinessLLCSetupInfo2/:escape?",
      BusinessLLCSetupInfo3Screen: "BusinessLLCSetupInfo3/:escape?",
      BusinessOwnerCheck1Screen: "BusinessOwnerCheck1/:escape?",
      DesktopMyAccountScreen: "DesktopMyAccount/:escape?",
      DetailedActionPlanScreen: "DetailedActionPlan/:escape?",
      EmailVerificationScreen: "EmailVerification/:escape?",
      EstimatedTaxCalculatorScreen: "EstimatedTaxCalculator/:escape?",
      GeneralSettingsScreen: "GeneralSettings/:escape?",
      HelpAndSupportNonAuthScreen: "HelpAndSupportNonAuth/:escape?",
      HelpSupportScreen: "HelpSupport/:escape?",
      LoginScreen: "Login/:escape?",
      ManageMyServicesScreen: "ManageMyServices/:escape?",
      ManageMySiteScreen: "ManageMySite/:escape?",
      MyAkurieWebServicesScreen: "MyAkurieWebServices/:escape?",
      MyBusinessInfoScreen: "MyBusinessInfo/:escape?",
      MyFilingsReportsScreen: "MyFilingsReports/:escape?",
      NetworkingIDPassScreen: "networkingpass/:busine/:userId?",
      NotificationScreen: "Notification/:escape?",
      OwnershipInformationScreen: "OwnershipInformation/:escape?",
      PasswordChangeScreen: "PasswordChange/:escape?",
      PasswordResetScreen: "PasswordReset/:escape?",
      Paywall2Screen: "Paywall2/:escape?",
      PaywallProCurrentMonth2Screen: "PaywallProCurrentMonth2/:escape?",
      PaywallProCurrentMonth3Screen: "PaywallProCurrentMonth3/:escape?",
      PaywallProCurrentMonthScreen: "PaywallProCurrentMonth/:escape?",
      PaywallProCurrentYScreen: "PaywallProCurrentY/:escape?",
      PaywallScreen: "Paywall/:escape?",
      PersonalProfileInfoScreen: "PersonalProfileInfo/:escape?",
      ProblemSolutionReviewScreen: "ProblemSolutionReview/:escape?",
      ProfessionalServicesScreen: "ProfessionalServices/:escape?",
      PurchaseCompleteScreen: "PurchaseComplete/:escape?",
      PurchaseFailPageScreen: "PurchaseFailPage/:escape?",
      RegisteredAgentInformationScreen: "RegisteredAgentInformation/:escape?",
      ResourceCenterScreen: "ResourceCenter/:escape?",
      SecurityScreen: "Security/:escape?",
      SessionExpiredScreen: "SessionExpired/:escape?",
      SignUpScreen: "SignUp/:escape?",
      SignupPt12Screen: "SignupPt12/:escape?",
      Signuppt1Screen: "Signuppt1/:escape?",
      Signuppt2Screen: "Signuppt2/:escape?",
      SplashScreenWelcomeScreen: "SplashScreenWelcome/:escape?",
      UpdateBusinessInformationScreen: "UpdateBusinessInformation/:escape?",
      UserDashboardScreen: "userdash/:business_id?/:su/:userId?",
      WebsiteBuildInfo3Screen: "WebsiteBuildInfo3/:escape?",
      WebsiteBuildInfo4Screen: "WebsiteBuildInfo4/:escape?",
      WebsiteBuildInfoScreen: "WebsiteBuildInfo/:escape?",
      WhatWeDoSignUpScreen: "WhatWeDoSignUp/:escape?",

      BottomTabNavigator: {
        screens: {
          UserDashboardScreen: "userdash/:business_id?/:subType?/:userId?",
          HelpSupportScreen: "help",
        },
      },
    },
  },
};

export default linking;