import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AuthAkurieApi from '../apis/AuthAkurieApi.js';
import * as Logs$SupportAkurieApi from '../apis/Logs$SupportAkurieApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Checkbox,
  Circle,
  Icon,
  IconButton,
  Link,
  Picker,
  ScreenContainer,
  Surface,
  Switch,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  ScrollView,
  StatusBar,
  Text,
  View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const PersonalProfileInfoScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [Owner_type, setOwner_type] = React.useState(['New', 'Existing']);
  const [Personal_editform, setPersonal_editform] = React.useState(false);
  const [birthdayPickerValue, setBirthdayPickerValue] = React.useState(
    new Date()
  );
  const [classAccess1, setClassAccess1] = React.useState(true);
  const [classAccess2, setClassAccess2] = React.useState(false);
  const [classAccess3, setClassAccess3] = React.useState(false);
  const [classAccess4, setClassAccess4] = React.useState(false);
  const [confirmationText, setConfirmationText] = React.useState('');
  const [datePickerValue, setDatePickerValue] = React.useState(new Date());
  const [emailInputValue, setEmailInputValue] = React.useState('');
  const [firstNameInputValue, setFirstNameInputValue] = React.useState('');
  const [g_error, setG_error] = React.useState('');
  const [homeStatePickerValue, setHomeStatePickerValue] = React.useState('');
  const [jobTitlePickerValue, setJobTitlePickerValue] = React.useState('');
  const [job_titles, setJob_titles] = React.useState([
    'Founder',
    'Owner',
    'President',
    'CEO',
    'Manager',
    'Member',
  ]);
  const [lastNameInputValue, setLastNameInputValue] = React.useState('');
  const [
    newOrExistingBusinessPickerValue,
    setNewOrExistingBusinessPickerValue,
  ] = React.useState('');
  const [numberInputValue, setNumberInputValue] = React.useState('');
  const [param_error, setParam_error] = React.useState('');
  const [pickerValue, setPickerValue] = React.useState('');
  const [pickerValue2, setPickerValue2] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const [userDelete, setUserDelete] = React.useState(false);
  const [yesCheckboxValue, setYesCheckboxValue] = React.useState(false);
  const authAkurieDeleteUserAccountDELETE =
    AuthAkurieApi.useDeleteUserAccountDELETE();
  const logs$SupportAkurieLoginLogPOST =
    Logs$SupportAkurieApi.useLoginLogPOST();
  const authAkurieProfileUpdatePATCH = AuthAkurieApi.useProfileUpdatePATCH();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      console.log('Screen ON_SCREEN_FOCUS Start');
      let error = null;
      try {
        if (!isFocused) {
          return;
        }
        console.log('Start ON_SCREEN_FOCUS:0 FETCH_REQUEST');
        const authResponse = (await AuthAkurieApi.meGET(Constants))?.json;
        console.log('Complete ON_SCREEN_FOCUS:0 FETCH_REQUEST', {
          authResponse,
        });
        console.log('Start ON_SCREEN_FOCUS:1 EXTRACT_KEY');
        const tokenExpire = authResponse?.message;
        console.log('Complete ON_SCREEN_FOCUS:1 EXTRACT_KEY', { tokenExpire });
        console.log('Start ON_SCREEN_FOCUS:2 NAVIGATE');
        if (tokenExpire) {
          navigation.navigate('SessionExpiredScreen');
          console.log('Complete ON_SCREEN_FOCUS:2 NAVIGATE');
        } else {
          console.log('Skipped ON_SCREEN_FOCUS:2 NAVIGATE: condition not met');
        }
        console.log('Start ON_SCREEN_FOCUS:3 CONDITIONAL_STOP');
        if (tokenExpire) {
          return;
        }
        console.log('Complete ON_SCREEN_FOCUS:3 CONDITIONAL_STOP');
        StatusBar.setBarStyle('dark-content');
      } catch (err) {
        console.error(err);
        error = err.message ?? err;
      }
      console.log(
        'Screen ON_SCREEN_FOCUS Complete',
        error ? { error } : 'no error'
      );
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors.studilyWhite4 },
        dimensions.width
      )}
    >
      {/* Delete Confirm Box */}
      <>
        {!userDelete ? null : (
          <BlurView
            intensity={80}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.BlurViewStyles(theme)['Blur View'],
                {
                  alignItems: 'center',
                  height: '100%',
                  justifyContent: 'center',
                  width: '100%',
                  zIndex: 3,
                }
              ),
              dimensions.width
            )}
            tint={'dark'}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  height: '80%',
                  justifyContent: 'flex-start',
                  marginTop: 60,
                  width: [
                    { minWidth: Breakpoints.Tablet, value: '60%' },
                    { minWidth: Breakpoints.Desktop, value: '50%' },
                    { minWidth: Breakpoints.Mobile, value: '80%' },
                  ],
                },
                dimensions.width
              )}
            >
              {/* Card */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Divider'],
                    borderRadius: 12,
                    height: '90%',
                    minWidth: '90%',
                    overflow: 'hidden',
                  },
                  dimensions.width
                )}
              >
                <AuthAkurieApi.FetchGetSpecificUserGET
                  user_id={Constants['ak_userID']}
                >
                  {({ loading, error, data, refetchGetSpecificUser }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <ImageBackground
                        resizeMode={'cover'}
                        source={Images.FirefoxPHKyvBthWe}
                        style={StyleSheet.applyWidth(
                          { height: '100%' },
                          dimensions.width
                        )}
                      >
                        <ImageBackground
                          resizeMode={'cover'}
                          source={Images.AdobeStock606166034}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ImageBackgroundStyles(theme)[
                                'Image Background'
                              ],
                              {
                                alignItems: 'center',
                                height: 250,
                                justifyContent: 'center',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                marginBottom: 30,
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Strong_Inverse'],
                                    fontFamily: 'Inter_400Regular',
                                    fontSize: 22,
                                    textAlign: 'center',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Warning Deleting'}
                            </Text>
                            <Icon
                              color={theme.colors['Akurie green']}
                              name={'AntDesign/warning'}
                              size={32}
                              style={StyleSheet.applyWidth(
                                { marginLeft: 15 },
                                dimensions.width
                              )}
                            />
                          </View>

                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  color: theme.colors['Strong_Inverse'],
                                  fontFamily: 'Inter_400Regular',
                                  fontSize: 16,
                                  textAlign: 'center',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'User Account: '}
                            {fetchData?.First_name} {fetchData?.Last_name}
                            {'\nID: '}
                            {fetchData?.id}
                          </Text>

                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  color: theme.colors['Akurie Primary'],
                                  fontFamily: 'Inter_300Light',
                                  fontSize: 14,
                                  textAlign: 'center',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Filing File ID: '}
                            {fetchData?.user_id_code}
                          </Text>
                        </ImageBackground>
                        {/* Body */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              paddingBottom: 24,
                              paddingLeft: 22,
                              paddingRight: 22,
                              paddingTop: 24,
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Custom #101010'],
                                fontFamily: 'Inter_400Regular',
                                fontSize: 14,
                                paddingLeft: 15,
                                paddingRight: 15,
                                textAlign: 'left',
                              },
                              dimensions.width
                            )}
                          >
                            {
                              "Please confirm that you would like to delete your Akurie account as well as all associated data. This also includes business data, filing requests, connect contacts, and your Akurie event Pass. This is permanent and your account can't be recovered. "
                            }
                          </Text>
                          {/* Text 2 */}
                          <Text
                            accessible={true}
                            allowFontScaling={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  color: theme.colors['Error'],
                                  fontFamily: 'Inter_600SemiBold',
                                  marginTop: 20,
                                  paddingLeft: 15,
                                  paddingRight: 15,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Type "Delete" to confirm.'}
                          </Text>
                          <TextInput
                            allowFontScaling={true}
                            autoCapitalize={'none'}
                            changeTextDelay={500}
                            onChangeText={newTextInputValue => {
                              try {
                                setConfirmationText(newTextInputValue);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            placeholder={'Enter confirmation text'}
                            placeholderTextColor={theme.colors['Light']}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextInputStyles(theme)[
                                  'Text Input'
                                ],
                                {
                                  borderColor: theme.colors['Strong'],
                                  height: 60,
                                }
                              ),
                              dimensions.width
                            )}
                            value={confirmationText}
                          />
                          {/* Topics */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-around',
                                marginTop: 16,
                              },
                              dimensions.width
                            )}
                          >
                            <Button
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor:
                                    theme.colors['Akurie Dark Blue'],
                                  borderRadius: 8,
                                  color: theme.colors['Akurie green'],
                                  fontFamily: 'System',
                                  fontWeight: '700',
                                  marginRight: 8,
                                  textAlign: 'center',
                                  width: 130,
                                },
                                dimensions.width
                              )}
                              title={'Cancel'}
                            />
                            <>
                              {!(confirmationText === 'Delete') ? null : (
                                <Button
                                  onPress={() => {
                                    const handler = async () => {
                                      try {
                                        (
                                          await authAkurieDeleteUserAccountDELETE.mutateAsync(
                                            { users_id: Constants['ak_userID'] }
                                          )
                                        )?.json;
                                        navigation.navigate(
                                          'SplashScreenWelcomeScreen'
                                        );
                                        setGlobalVariableValue({
                                          key: 'SKYE_AUTHORIZATION_HEADER',
                                          value: false,
                                        });
                                        setUserDelete(true);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    };
                                    handler();
                                  }}
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor: theme.colors['Error'],
                                      borderRadius: 8,
                                      fontFamily: 'System',
                                      fontWeight: '700',
                                      textAlign: 'center',
                                      width: 130,
                                    },
                                    dimensions.width
                                  )}
                                  title={'Delete'}
                                />
                              )}
                            </>
                          </View>
                        </View>
                      </ImageBackground>
                    );
                  }}
                </AuthAkurieApi.FetchGetSpecificUserGET>
              </View>
            </View>
          </BlurView>
        )}
      </>
      {/* site header update 11-14--2 */}
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <View
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ViewStyles(theme)[
                  'Akurie Header Desktop Header 9'
                ],
                {
                  borderBottomWidth: {
                    minWidth: Breakpoints.Laptop,
                    value: 0.5,
                  },
                  borderColor: {
                    minWidth: Breakpoints.Laptop,
                    value: theme.colors['Akurie green'],
                  },
                  height: { minWidth: Breakpoints.Laptop, value: 110 },
                  paddingLeft: { minWidth: Breakpoints.Laptop, value: '8%' },
                  paddingRight: { minWidth: Breakpoints.Laptop, value: '8%' },
                }
              ),
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  marginRight: { minWidth: Breakpoints.Laptop, value: 20 },
                  width: { minWidth: Breakpoints.Laptop, value: '20%' },
                },
                dimensions.width
              )}
            >
              <Image
                resizeMode={'contain'}
                source={Images.MainLogoUpdate1u2}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    height: { minWidth: Breakpoints.Laptop, value: 50 },
                    width: { minWidth: Breakpoints.Laptop, value: '100%' },
                  }),
                  dimensions.width
                )}
              />
            </View>
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                  flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
                  height: { minWidth: Breakpoints.Laptop, value: '80%' },
                  justifyContent: {
                    minWidth: Breakpoints.Laptop,
                    value: 'space-around',
                  },
                  marginRight: [
                    { minWidth: Breakpoints.Laptop, value: 20 },
                    { minWidth: Breakpoints.Desktop, value: 0 },
                  ],
                  width: { minWidth: Breakpoints.Laptop, value: '60%' },
                },
                dimensions.width
              )}
            >
              <Link
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'], {
                    color: {
                      minWidth: Breakpoints.Laptop,
                      value: theme.colors['Strong_Inverse'],
                    },
                    fontFamily: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: 'Inter_500Medium',
                      },
                      {
                        minWidth: Breakpoints.BigScreen,
                        value: 'Inter_400Regular',
                      },
                    ],
                    fontSize: [
                      { minWidth: Breakpoints.Laptop, value: 12 },
                      { minWidth: Breakpoints.Desktop, value: 14 },
                    ],
                  }),
                  dimensions.width
                )}
                title={'Dashboard'}
              />
              <Link
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'], {
                    color: {
                      minWidth: Breakpoints.Laptop,
                      value: theme.colors['Strong_Inverse'],
                    },
                    fontFamily: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: 'Inter_500Medium',
                      },
                      {
                        minWidth: Breakpoints.BigScreen,
                        value: 'Inter_400Regular',
                      },
                    ],
                    fontSize: [
                      { minWidth: Breakpoints.Laptop, value: 12 },
                      { minWidth: Breakpoints.Desktop, value: 14 },
                    ],
                  }),
                  dimensions.width
                )}
                title={'Networking Events'}
              />
              <Link
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'], {
                    color: {
                      minWidth: Breakpoints.Laptop,
                      value: theme.colors['Strong_Inverse'],
                    },
                    fontFamily: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: 'Inter_500Medium',
                      },
                      {
                        minWidth: Breakpoints.BigScreen,
                        value: 'Inter_400Regular',
                      },
                    ],
                    fontSize: [
                      { minWidth: Breakpoints.Laptop, value: 12 },
                      { minWidth: Breakpoints.Desktop, value: 14 },
                    ],
                  }),
                  dimensions.width
                )}
                title={'My Business info'}
              />
              <Link
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'], {
                    color: {
                      minWidth: Breakpoints.Laptop,
                      value: theme.colors['Strong_Inverse'],
                    },
                    fontFamily: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: 'Inter_500Medium',
                      },
                      {
                        minWidth: Breakpoints.BigScreen,
                        value: 'Inter_400Regular',
                      },
                    ],
                    fontSize: [
                      { minWidth: Breakpoints.Laptop, value: 12 },
                      { minWidth: Breakpoints.Desktop, value: 14 },
                    ],
                  }),
                  dimensions.width
                )}
                title={'The Financials'}
              />
              <Link
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'], {
                    color: {
                      minWidth: Breakpoints.Laptop,
                      value: theme.colors['Strong_Inverse'],
                    },
                    fontFamily: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: 'Inter_500Medium',
                      },
                      {
                        minWidth: Breakpoints.BigScreen,
                        value: 'Inter_400Regular',
                      },
                    ],
                    fontSize: [
                      { minWidth: Breakpoints.Laptop, value: 12 },
                      { minWidth: Breakpoints.Desktop, value: 14 },
                    ],
                  }),
                  dimensions.width
                )}
                title={'Docs & Filings'}
              />
            </View>
            {/* View 3 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: [
                    { minWidth: Breakpoints.BigScreen, value: 'center' },
                    { minWidth: Breakpoints.Desktop, value: 'center' },
                  ],
                  flexDirection: [
                    { minWidth: Breakpoints.BigScreen, value: 'row' },
                    { minWidth: Breakpoints.Desktop, value: 'row' },
                    { minWidth: Breakpoints.Laptop, value: 'row' },
                  ],
                  justifyContent: {
                    minWidth: Breakpoints.Laptop,
                    value: 'center',
                  },
                  marginLeft: { minWidth: Breakpoints.Desktop, value: 20 },
                  marginRight: { minWidth: Breakpoints.Desktop, value: 20 },
                  width: [
                    { minWidth: Breakpoints.Laptop, value: '30%' },
                    { minWidth: Breakpoints.Desktop, value: '20%' },
                    { minWidth: Breakpoints.BigScreen, value: '15%' },
                  ],
                },
                dimensions.width
              )}
            >
              {/* Touchable Frame 2 */}
              <>
                {!(
                  Constants['ak_subscriptionType'] === 'Akurie Free'
                ) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'flex-end',
                        },
                        marginRight: { minWidth: Breakpoints.Laptop, value: 5 },
                        width: [
                          { minWidth: Breakpoints.Laptop, value: '50%' },
                          { minWidth: Breakpoints.Mobile, value: '100%' },
                        ],
                        zIndex: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        const handler = async () => {
                          try {
                            const loginResponse = (
                              await AuthAkurieApi.loginPOST(Constants, {})
                            )?.json;
                            const authToken = loginResponse?.authToken;
                            const Error_Msg = loginResponse?.message;
                            setGlobalVariableValue({
                              key: 'ak_errorMessage',
                              value: Error_Msg,
                            });
                            if (Error_Msg) {
                              return;
                            }
                            setGlobalVariableValue({
                              key: 'SKYE_AUTHORIZATION_HEADER',
                              value: 'Bearer ' + authToken,
                            });
                            const currentUserid = loginResponse?.authUser?.id;
                            setGlobalVariableValue({
                              key: 'ak_userID',
                              value: currentUserid,
                            });
                            const Businessid = loginResponse?.businessData.id;
                            setGlobalVariableValue({
                              key: 'ak_businessID',
                              value: Businessid,
                            });
                            (
                              await logs$SupportAkurieLoginLogPOST.mutateAsync({
                                user_id: currentUserid,
                              })
                            )?.json;
                            setGlobalVariableValue({
                              key: 'ak_errorMessage',
                              value: false,
                            });
                            setGlobalVariableValue({
                              key: 'ak_isLoading',
                              value: true,
                            });
                            navigation.navigate('RootNavigator');
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        { borderRadius: 0 },
                        dimensions.width
                      )}
                    >
                      {/* CTA Frame */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: [
                              {
                                minWidth: Breakpoints.Mobile,
                                value: theme.colors['Akurie Primary'],
                              },
                              {
                                minWidth: Breakpoints.Laptop,
                                value: theme.colors['Akurie green'],
                              },
                            ],
                            borderRadius: 12,
                            flexDirection: 'row',
                            maxWidth: {
                              minWidth: Breakpoints.Laptop,
                              value: 120,
                            },
                            minHeight: {
                              minWidth: Breakpoints.Laptop,
                              value: 45,
                            },
                            paddingBottom: [
                              { minWidth: Breakpoints.Mobile, value: 12 },
                              { minWidth: Breakpoints.Laptop, value: 6 },
                              { minWidth: Breakpoints.BigScreen, value: 12 },
                              { minWidth: Breakpoints.Desktop, value: 2 },
                            ],
                            paddingLeft: [
                              { minWidth: Breakpoints.Mobile, value: 18 },
                              { minWidth: Breakpoints.Laptop, value: 12 },
                            ],
                            paddingRight: [
                              { minWidth: Breakpoints.Mobile, value: 18 },
                              { minWidth: Breakpoints.Laptop, value: 12 },
                            ],
                            paddingTop: [
                              { minWidth: Breakpoints.Mobile, value: 12 },
                              { minWidth: Breakpoints.Laptop, value: 6 },
                              { minWidth: Breakpoints.BigScreen, value: 12 },
                              { minWidth: Breakpoints.Desktop, value: 2 },
                            ],
                            zIndex: 10,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Label Frame */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexGrow: 1,
                              flexShrink: 0,
                              justifyContent: 'center',
                            },
                            dimensions.width
                          )}
                        >
                          {/* Cotinue */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: [
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: theme.colors['Strong Inverse'],
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: theme.colors['Akurie Dark Blue'],
                                  },
                                ],
                                fontFamily: [
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: 'Inter_500Medium',
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'Inter_400Regular',
                                  },
                                ],
                                fontSize: [
                                  { minWidth: Breakpoints.Mobile, value: 15 },
                                  { minWidth: Breakpoints.Laptop, value: 13 },
                                ],
                                lineHeight: 21,
                                paddingBottom: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 3,
                                },
                                paddingTop: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 3,
                                },
                                textTransform: 'uppercase',
                              },
                              dimensions.width
                            )}
                          >
                            {'Try For Free'}
                          </Text>
                        </View>
                      </View>
                    </Touchable>
                  </View>
                )}
              </>
              {/* Touchable Frame 3 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: {
                      minWidth: Breakpoints.Laptop,
                      value: 'flex-start',
                    },
                    justifyContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                    width: [
                      { minWidth: Breakpoints.Mobile, value: '100%' },
                      { minWidth: Breakpoints.Laptop, value: '50%' },
                    ],
                    zIndex: 10,
                  },
                  dimensions.width
                )}
              ></View>
            </View>
          </View>
        )}
      </>
      {/* Navigation Frame */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors['Akurie Dark Blue'],
                borderBottomWidth: 2,
                borderColor: theme.colors['Akurie green'],
                height: 80,
                marginBottom: 15,
                marginTop: 15,
              },
              dimensions.width
            )}
          >
            <ImageBackground
              resizeMode={'cover'}
              source={Images.Asset13}
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  height: '100%',
                  justifyContent: 'flex-start',
                },
                dimensions.width
              )}
            >
              {/* Left Side Frame */}
              <View
                style={StyleSheet.applyWidth(
                  { paddingLeft: 9, paddingRight: 9 },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    try {
                      navigation.goBack();
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* Icon Flex Frame */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexGrow: 0,
                        flexShrink: 0,
                        paddingBottom: 12,
                        paddingLeft: 12,
                        paddingRight: 12,
                        paddingTop: 12,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Icon  */}
                    <Icon
                      color={theme.colors['Background']}
                      name={'Ionicons/arrow-back-outline'}
                      size={24}
                    />
                  </View>
                </Touchable>
              </View>
              {/* Middle Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: 'rgba(8, 31, 61, 0.5)',
                    flexGrow: 1,
                    flexShrink: 0,
                    justifyContent: 'center',
                  },
                  dimensions.width
                )}
              >
                {/* Text Frame  */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexGrow: 0,
                      flexShrink: 0,
                      paddingBottom: 12,
                      paddingLeft: 12,
                      paddingRight: 12,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  {/* Text 12 18 Regular */}
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Akurie green'],
                        fontFamily: 'System',
                        fontSize: 22,
                        fontWeight: '400',
                        paddingTop: 8,
                      },
                      dimensions.width
                    )}
                  >
                    {'Personal Settings'}
                  </Text>
                </View>
              </View>
              {/* Right Side Frame */}
              <View
                style={StyleSheet.applyWidth(
                  { paddingLeft: 9, paddingRight: 9 },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    try {
                      navigation.navigate('HelpSupportScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* Icon Flex Frame */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexGrow: 0,
                        flexShrink: 0,
                        paddingBottom: 12,
                        paddingLeft: 12,
                        paddingRight: 12,
                        paddingTop: 12,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Icon  */}
                    <Icon
                      color={theme.colors['Background']}
                      name={'Ionicons/help-circle-outline'}
                      size={32}
                    />
                  </View>
                </Touchable>
              </View>
            </ImageBackground>
          </View>
        )}
      </>
      {/* View 2 */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 10,
            marginTop: 10,
            padding: 5,
          },
          dimensions.width
        )}
      >
        <AuthAkurieApi.FetchGetSpecificUserGET user_id={Constants['ak_userID']}>
          {({ loading, error, data, refetchGetSpecificUser }) => {
            const fetchData = data?.json;
            if (loading) {
              return <ActivityIndicator />;
            }

            if (error || data?.status < 200 || data?.status >= 300) {
              return <ActivityIndicator />;
            }

            return (
              <>
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Strong'],
                      fontFamily: 'Inter_600SemiBold',
                      fontSize: [
                        { minWidth: Breakpoints.Mobile, value: 22 },
                        { minWidth: Breakpoints.Tablet, value: 26 },
                      ],
                    }),
                    dimensions.width
                  )}
                >
                  {fetchData?.First_name}
                </Text>
                {/* Text 2 */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Light'],
                      fontFamily: 'Inter_400Regular',
                      fontSize: 12,
                      marginTop: 5,
                    }),
                    dimensions.width
                  )}
                >
                  {'App ID: '}
                  {'6451-6511-3210'}
                </Text>
              </>
            );
          }}
        </AuthAkurieApi.FetchGetSpecificUserGET>
      </View>

      <ImageBackground
        resizeMode={'stretch'}
        style={StyleSheet.applyWidth(
          { height: '100%', width: '100%' },
          dimensions.width
        )}
      >
        <ScrollView
          bounces={true}
          showsHorizontalScrollIndicator={true}
          showsVerticalScrollIndicator={true}
        >
          <KeyboardAwareScrollView
            extraScrollHeight={300}
            keyboardShouldPersistTaps={'never'}
            showsVerticalScrollIndicator={true}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['Custom Color_18'],
                  marginTop: 20,
                  paddingLeft: 20,
                  paddingRight: 20,
                },
                dimensions.width
              )}
            >
              <AuthAkurieApi.FetchGetSpecificUserGET
                user_id={Constants['ak_userID']}
              >
                {({ loading, error, data, refetchGetSpecificUser }) => {
                  const fetchData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          borderRadius: 9,
                          marginBottom: 25,
                          marginTop: 25,
                          paddingBottom: 10,
                          paddingLeft: 35,
                          paddingRight: 35,
                          paddingTop: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Akurie green'],
                            fontFamily: 'Inter_500Medium',
                            fontSize: 18,
                          },
                          dimensions.width
                        )}
                      >
                        {'Current Information:'}
                      </Text>

                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Communial_Icon_Gray'],
                            fontFamily: 'Inter_400Regular',
                            fontSize: 16,
                            marginBottom: 20,
                          },
                          dimensions.width
                        )}
                      >
                        {fetchData?.New_or_existing_business_owner}
                        {' Business Owner'}
                      </Text>

                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Strong_Inverse'],
                            fontFamily: 'Inter_400Regular',
                            fontSize: 14,
                          },
                          dimensions.width
                        )}
                      >
                        {'Position / Title - '}
                        {fetchData?.title_or_position}
                      </Text>

                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Strong_Inverse'],
                            fontFamily: 'Inter_400Regular',
                            fontSize: 14,
                          },
                          dimensions.width
                        )}
                      >
                        {'Home State: '}
                        {fetchData?.home_state}{' '}
                      </Text>

                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Strong Inverse'],
                            fontFamily: 'Inter_400Regular',
                            fontSize: 14,
                            marginTop: 20,
                          },
                          dimensions.width
                        )}
                      >
                        {'Birthday: '}
                        {fetchData?.birthday}
                      </Text>

                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Strong Inverse'],
                            fontFamily: 'Inter_400Regular',
                            fontSize: 14,
                          },
                          dimensions.width
                        )}
                      >
                        {'Email: '}
                        {fetchData?.email}
                      </Text>
                    </View>
                  );
                }}
              </AuthAkurieApi.FetchGetSpecificUserGET>
            </View>
            <>
              {Personal_editform ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: 35,
                    },
                    dimensions.width
                  )}
                >
                  <Button
                    icon={'MaterialCommunityIcons/account-edit'}
                    onPress={() => {
                      try {
                        setPersonal_editform(true);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        {
                          backgroundColor: theme.colors['Akurie Dark Blue'],
                          borderRadius: 12,
                          color: theme.colors['Strong_Inverse'],
                          fontFamily: 'Inter_600SemiBold',
                          height: 60,
                          width: '80%',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Edit User Personal Info'}
                  />
                </View>
              )}
            </>
            {/* General Settings Frame */}
            <>
              {!Personal_editform ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: theme.colors.studilyGray3,
                      borderLeftWidth: 1,
                      borderRadius: 6,
                      borderRightWidth: 1,
                      borderTopWidth: 1,
                      marginBottom: 18,
                      marginTop: 18,
                      paddingBottom: 6,
                      paddingLeft: 6,
                      paddingRight: 6,
                      paddingTop: 6,
                    },
                    dimensions.width
                  )}
                >
                  <View>
                    {/* Bottom option */}
                    <Surface
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: 1,
                          borderColor: theme.colors.divider,
                          borderLeftWidth: 1,
                          borderRadius: 10,
                          borderRightWidth: 1,
                          borderTopWidth: 1,
                          justifyContent: 'center',
                          marginLeft: 16,
                          marginRight: 16,
                          marginTop: 25,
                          minHeight: 40,
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            borderBottomWidth: 1,
                            borderColor: theme.colors.divider,
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            minHeight: 80,
                            paddingBottom: 12,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 12,
                          },
                          dimensions.width
                        )}
                      >
                        {/* networking disclaimer */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              borderBottomWidth: 0.5,
                              borderColor: [
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Akurie green'],
                                },
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: theme.colors['Akurie Dark Blue'],
                                },
                              ],
                              borderTopWidth: 0.5,
                              marginBottom: 10,
                              marginTop: [
                                { minWidth: Breakpoints.Mobile, value: 10 },
                                { minWidth: Breakpoints.Laptop, value: 20 },
                              ],
                              padding: 5,
                              paddingBottom: 10,
                              paddingLeft: 10,
                              paddingRight: 10,
                              paddingTop: 10,
                              width: {
                                minWidth: Breakpoints.Laptop,
                                value: '100%',
                              },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Networking Disclaimer */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Medium'],
                                fontFamily: [
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: 'Inter_700Bold',
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'Inter_400Regular',
                                  },
                                ],
                                fontSize: [
                                  { minWidth: Breakpoints.Mobile, value: 16 },
                                  { minWidth: Breakpoints.Laptop, value: 13 },
                                ],
                                marginBottom: 10,
                                textAlign: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            {'Networking - Contact Share Consent'}
                          </Text>
                          {/* Networking Disclaimer 2 */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Medium'],
                                fontFamily: 'Inter_400Regular',
                                fontSize: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 13,
                                },
                                marginBottom: 10,
                                textAlign: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            {
                              'Are you ok with Akurie Inc. sharing information provided about you and your business at our networking events?'
                            }
                          </Text>

                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'center',
                                marginTop: 20,
                              },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  marginRight: 15,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      color: theme.colors['Medium'],
                                      fontFamily: 'Inter_700Bold',
                                      fontSize: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 16,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 13,
                                        },
                                      ],
                                      marginLeft: 10,
                                      marginRight: 10,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Yes'}
                              </Text>
                              {/* YesCheckbox */}
                              <Checkbox
                                checkedIcon={
                                  'MaterialCommunityIcons/checkbox-marked'
                                }
                                color={theme.colors['Akurie Dark Blue']}
                                onPress={newYesCheckboxValue => {
                                  try {
                                    setYesCheckboxValue(newYesCheckboxValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                size={32}
                                status={yesCheckboxValue}
                                uncheckedColor={
                                  theme.colors['Akurie Dark Blue']
                                }
                                uncheckedIcon={
                                  'MaterialCommunityIcons/checkbox-blank-outline'
                                }
                              />
                            </View>
                            {/* View 2 */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  marginLeft: 15,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      color: theme.colors['Medium'],
                                      fontFamily: 'Inter_700Bold',
                                      fontSize: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 16,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 13,
                                        },
                                      ],
                                      marginLeft: 10,
                                      marginRight: 10,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'No'}
                              </Text>
                              {/* NoCheckbox */}
                              <Checkbox
                                checkedIcon={
                                  'MaterialCommunityIcons/checkbox-marked'
                                }
                                color={theme.colors['Akurie Dark Blue']}
                                size={32}
                                uncheckedColor={
                                  theme.colors['Akurie Dark Blue']
                                }
                                uncheckedIcon={
                                  'MaterialCommunityIcons/checkbox-blank-outline'
                                }
                              />
                            </View>
                          </View>
                        </View>
                      </View>

                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            borderBottomWidth: 1,
                            borderColor: theme.colors.divider,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            minHeight: 80,
                            paddingBottom: 12,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 12,
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { flexDirection: 'column', width: '100%' },
                            dimensions.width
                          )}
                        >
                          {/* First Name View */}
                          <View
                            style={StyleSheet.applyWidth(
                              { paddingBottom: 5, paddingTop: 5 },
                              dimensions.width
                            )}
                          >
                            {/* First Name */}
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors['Medium'],
                                  fontFamily: 'Inter_700Bold',
                                  marginBottom: 8,
                                  marginLeft: 5,
                                },
                                dimensions.width
                              )}
                            >
                              {'First Name'}
                            </Text>
                            {/* First Name Input */}
                            <TextInput
                              enablesReturnKeyAutomatically={false}
                              onChangeText={newFirstNameInputValue => {
                                try {
                                  setFirstNameInputValue(
                                    newFirstNameInputValue
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              placeholder={'First Name'}
                              placeholderTextColor={
                                theme.colors['Communial_Icon_Gray']
                              }
                              spellcheck={true}
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor: theme.colors['Background'],
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors['Medium'],
                                  borderRadius: 0,
                                  height: 50,
                                  paddingBottom: 12,
                                  paddingLeft: 8,
                                  paddingRight: 8,
                                  paddingTop: 12,
                                },
                                dimensions.width
                              )}
                              textContentType={'name'}
                              value={firstNameInputValue}
                            />
                          </View>
                        </View>
                      </View>

                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            borderBottomWidth: 1,
                            borderColor: theme.colors.divider,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            minHeight: 80,
                            paddingBottom: 12,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 12,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Last Name View */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              marginBottom: 10,
                              paddingBottom: 5,
                              paddingTop: 5,
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        >
                          {/* Last Name */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Medium'],
                                fontFamily: 'Inter_700Bold',
                                marginBottom: 8,
                                marginLeft: 5,
                              },
                              dimensions.width
                            )}
                          >
                            {'Last Name'}
                          </Text>
                          {/* Last Name Input */}
                          <TextInput
                            enablesReturnKeyAutomatically={false}
                            onChangeText={newLastNameInputValue => {
                              try {
                                setLastNameInputValue(newLastNameInputValue);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            placeholder={'Last Name'}
                            placeholderTextColor={
                              theme.colors['Communial_Icon_Gray']
                            }
                            spellcheck={true}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Background'],
                                borderBottomWidth: 1,
                                borderColor: theme.colors['Medium'],
                                borderRadius: 0,
                                height: 50,
                                paddingBottom: 12,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 12,
                              },
                              dimensions.width
                            )}
                            textContentType={'name'}
                            value={lastNameInputValue}
                          />
                        </View>
                      </View>

                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            borderBottomWidth: 1,
                            borderColor: theme.colors.divider,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            minHeight: 80,
                            paddingBottom: 12,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 12,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Email View */}
                        <View
                          style={StyleSheet.applyWidth(
                            { paddingBottom: 5, paddingTop: 5, width: '100%' },
                            dimensions.width
                          )}
                        >
                          {/* Last Name */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Medium'],
                                fontFamily: 'Inter_700Bold',
                                marginBottom: 8,
                                marginLeft: 5,
                              },
                              dimensions.width
                            )}
                          >
                            {'Email'}
                          </Text>
                          {/* Email Input */}
                          <TextInput
                            enablesReturnKeyAutomatically={false}
                            onChangeText={newEmailInputValue => {
                              try {
                                setEmailInputValue(newEmailInputValue);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            placeholder={'your@email.com'}
                            placeholderTextColor={
                              theme.colors['Communial_Icon_Gray']
                            }
                            spellcheck={true}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Background'],
                                borderBottomWidth: 1,
                                borderColor: theme.colors['Medium'],
                                borderRadius: 0,
                                height: 50,
                                paddingBottom: 12,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 12,
                              },
                              dimensions.width
                            )}
                            textContentType={'emailAddress'}
                            value={emailInputValue}
                          />
                        </View>
                      </View>

                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            borderBottomWidth: 1,
                            borderColor: theme.colors.divider,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            minHeight: 80,
                            paddingBottom: 12,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 12,
                          },
                          dimensions.width
                        )}
                      >
                        {/* State Picker */}
                        <View
                          style={StyleSheet.applyWidth(
                            { paddingBottom: 5, paddingTop: 5, width: '100%' },
                            dimensions.width
                          )}
                        >
                          {/* Home State */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Medium'],
                                fontFamily: 'Inter_700Bold',
                                marginBottom: 8,
                                marginLeft: 5,
                              },
                              dimensions.width
                            )}
                          >
                            {'State'}
                          </Text>
                          {/* Home State Picker */}
                          <Picker
                            iconColor={theme.colors['Custom Color_19']}
                            iconSize={24}
                            leftIconMode={'inset'}
                            onValueChange={newHomeStatePickerValue => {
                              try {
                                setHomeStatePickerValue(
                                  newHomeStatePickerValue
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            options={Constants['aku_supported_states_vari']}
                            placeholder={'Select a State'}
                            rightIconName={'AntDesign/downcircle'}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Background'],
                                paddingRight: 15,
                              },
                              dimensions.width
                            )}
                            type={'solid'}
                            value={homeStatePickerValue}
                          />
                        </View>
                      </View>

                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            borderBottomWidth: 1,
                            borderColor: theme.colors.divider,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            minHeight: 80,
                            paddingBottom: 12,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 12,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Title View */}
                        <View
                          style={StyleSheet.applyWidth(
                            { paddingBottom: 5, paddingTop: 5, width: '100%' },
                            dimensions.width
                          )}
                        >
                          {/* Title */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Medium'],
                                fontFamily: 'Inter_700Bold',
                                marginBottom: 8,
                                marginLeft: 5,
                              },
                              dimensions.width
                            )}
                          >
                            {'Title'}
                          </Text>
                          {/* Job Title Picker */}
                          <Picker
                            iconColor={theme.colors['Custom Color_19']}
                            iconSize={24}
                            leftIconMode={'inset'}
                            onValueChange={newJobTitlePickerValue => {
                              try {
                                setJobTitlePickerValue(newJobTitlePickerValue);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            options={job_titles}
                            placeholder={'Select a Title'}
                            placeholderTextColor={
                              theme.colors['Communial_Icon_Gray']
                            }
                            rightIconName={'AntDesign/downcircle'}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Background'],
                                color: theme.colors['Strong'],
                                paddingRight: 15,
                              },
                              dimensions.width
                            )}
                            type={'solid'}
                            value={jobTitlePickerValue}
                          />
                        </View>
                      </View>
                    </Surface>
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        marginBottom: 100,
                        paddingBottom: 15,
                        paddingLeft: 15,
                        paddingRight: 15,
                        paddingTop: 8,
                      },
                      dimensions.width
                    )}
                  >
                    <View>
                      {/* Form */}
                      <View
                        style={StyleSheet.applyWidth(
                          { opacity: 0.8, paddingLeft: 20, paddingRight: 20 },
                          dimensions.width
                        )}
                      />
                    </View>

                    <View
                      style={StyleSheet.applyWidth(
                        {
                          paddingBottom: 40,
                          paddingLeft: 40,
                          paddingRight: 40,
                          paddingTop: 40,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Button */}
                      <View
                        style={StyleSheet.applyWidth(
                          { borderRadius: 0 },
                          dimensions.width
                        )}
                      >
                        {/* Touchable Frame */}
                        <View
                          style={StyleSheet.applyWidth(
                            { zIndex: 10 },
                            dimensions.width
                          )}
                        >
                          <Touchable
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  const pro_update = (
                                    await authAkurieProfileUpdatePATCH.mutateAsync(
                                      {
                                        First_name: firstNameInputValue,
                                        Last_name: lastNameInputValue,
                                        Networking_Consent: yesCheckboxValue,
                                        email: emailInputValue,
                                        home_state: homeStatePickerValue,
                                        title_or_position: jobTitlePickerValue,
                                        user_id: Constants['ak_userID'],
                                      }
                                    )
                                  )?.json;
                                  setPersonal_editform(false);
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            style={StyleSheet.applyWidth(
                              { borderRadius: 0 },
                              dimensions.width
                            )}
                          >
                            {/* CTA Frame */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor:
                                    theme.colors['Akurie Primary'],
                                  borderRadius: 12,
                                  flexDirection: 'row',
                                  paddingBottom: 12,
                                  paddingLeft: 18,
                                  paddingRight: 18,
                                  paddingTop: 12,
                                  zIndex: 10,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Label Frame */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexGrow: 1,
                                    flexShrink: 0,
                                    justifyContent: 'center',
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Label */}
                                <Text
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors['Strong Inverse'],
                                      fontFamily: 'Inter_700Bold',
                                      fontSize: 15,
                                      lineHeight: 21,
                                      marginLeft: 42,
                                      textTransform: 'uppercase',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Update Info'}
                                </Text>
                              </View>
                              {/* Icon Group Frame */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { opacity: 0.5 },
                                  dimensions.width
                                )}
                              >
                                <Circle
                                  bgColor={theme.colors['Custom Color_18']}
                                  size={42}
                                >
                                  {/* Icon Flex Frame */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        flexGrow: 0,
                                        flexShrink: 0,
                                        paddingBottom: 12,
                                        paddingLeft: 12,
                                        paddingRight: 12,
                                        paddingTop: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Icon  */}
                                    <Icon
                                      color={theme.colors['Strong Inverse']}
                                      name={'AntDesign/arrowright'}
                                      size={18}
                                    />
                                  </View>
                                </Circle>
                              </View>
                            </View>
                          </Touchable>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              )}
            </>
          </KeyboardAwareScrollView>
        </ScrollView>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(PersonalProfileInfoScreen);
