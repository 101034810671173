import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import encodeQueryParam from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const completeGoalStepPOST = (
  Constants,
  { goal_id, stepID },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:JkWhWPLh/businessPlans/Update/completed`,
    {
      body: JSON.stringify({ goal_id: goal_id, stepID: stepID }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useCompleteGoalStepPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      completeGoalStepPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Goals', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Goal');
        queryClient.invalidateQueries('Goals');
      },
    }
  );
};

export const FetchCompleteGoalStepPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  goal_id,
  stepID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCompleteGoalStepPOST(
    { goal_id, stepID },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCompleteGoalStep: refetch });
};

export const getBusinessGoalsGET = (Constants, { bus_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:JkWhWPLh/businessPlans/Get/goals/${
      typeof bus_id === 'string' ? bus_id : JSON.stringify(bus_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetBusinessGoalsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Goals', args],
    () => getBusinessGoalsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetBusinessGoalsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  bus_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetBusinessGoalsGET(
    { bus_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetBusinessGoals: refetch });
};

export const getSpecificGoalGET = (Constants, { goal_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:JkWhWPLh/businessPlans/Get/goalSpecific/${
      typeof goal_id === 'string' ? goal_id : JSON.stringify(goal_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetSpecificGoalGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Goal', args],
    () => getSpecificGoalGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Goals']),
    }
  );
};

export const FetchGetSpecificGoalGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  goal_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetSpecificGoalGET(
    { goal_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetSpecificGoal: refetch });
};

export const goalStatusGET = (Constants, { goal_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:JkWhWPLh/businessPlans/Get/goalSteps_Status/${
      typeof goal_id === 'string' ? goal_id : JSON.stringify(goal_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGoalStatusGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Goals', args],
    () => goalStatusGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGoalStatusGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  goal_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGoalStatusGET(
    { goal_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGoalStatus: refetch });
};

export const incompleteGoalStepPOST = (
  Constants,
  { goal_id, stepID },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:JkWhWPLh/businessPlans/Update/uncomplete`,
    {
      body: JSON.stringify({ goal_id: goal_id, stepID: stepID }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['SKYE_AUTHORIZATION_HEADER'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useIncompleteGoalStepPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      incompleteGoalStepPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Goals', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Goal');
        queryClient.invalidateQueries('Goals');
      },
    }
  );
};

export const FetchIncompleteGoalStepPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  goal_id,
  stepID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useIncompleteGoalStepPOST(
    { goal_id, stepID },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchIncompleteGoalStep: refetch });
};
