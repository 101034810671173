import * as StyleSheet from './utils/StyleSheet';

import Breakpoints from './utils/Breakpoints';

export const ViewStyles = theme =>
  StyleSheet.create({
    'Akurie Card': { borderRadius: 9, marginTop: 10 },
    'Akurie Header Desktop Header': {
      alignContent: { minWidth: Breakpoints.Laptop, value: 'space-between' },
      alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
      backgroundColor: {
        minWidth: Breakpoints.Laptop,
        value: theme.colors['Akurie Dark Blue'],
      },
      flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
      height: [
        { minWidth: Breakpoints.Laptop, value: 120 },
        { minWidth: Breakpoints.BigScreen, value: 105 },
      ],
      justifyContent: { minWidth: Breakpoints.Laptop, value: 'space-between' },
      paddingBottom: [
        { minWidth: Breakpoints.Laptop, value: 15 },
        { minWidth: Breakpoints.Desktop, value: 20 },
      ],
      paddingLeft: { minWidth: Breakpoints.Laptop, value: '15%' },
      paddingRight: { minWidth: Breakpoints.Laptop, value: '15%' },
      paddingTop: [
        { minWidth: Breakpoints.Laptop, value: 15 },
        { minWidth: Breakpoints.Desktop, value: 20 },
      ],
    },
    'Akurie Header Desktop Header 10': {
      alignContent: { minWidth: Breakpoints.Laptop, value: 'space-between' },
      alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
      backgroundColor: {
        minWidth: Breakpoints.Laptop,
        value: theme.colors['Akurie Dark Blue'],
      },
      flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
      height: [
        { minWidth: Breakpoints.Laptop, value: 120 },
        { minWidth: Breakpoints.BigScreen, value: 105 },
      ],
      justifyContent: { minWidth: Breakpoints.Laptop, value: 'space-between' },
      paddingBottom: [
        { minWidth: Breakpoints.Laptop, value: 15 },
        { minWidth: Breakpoints.Desktop, value: 20 },
      ],
      paddingLeft: { minWidth: Breakpoints.Laptop, value: '15%' },
      paddingRight: { minWidth: Breakpoints.Laptop, value: '15%' },
      paddingTop: [
        { minWidth: Breakpoints.Laptop, value: 15 },
        { minWidth: Breakpoints.Desktop, value: 20 },
      ],
    },
    'Akurie Header Desktop Header 11': {
      alignContent: { minWidth: Breakpoints.Laptop, value: 'space-between' },
      alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
      backgroundColor: {
        minWidth: Breakpoints.Laptop,
        value: theme.colors['Akurie Dark Blue'],
      },
      flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
      height: [
        { minWidth: Breakpoints.Laptop, value: 120 },
        { minWidth: Breakpoints.BigScreen, value: 105 },
      ],
      justifyContent: { minWidth: Breakpoints.Laptop, value: 'space-between' },
      paddingBottom: [
        { minWidth: Breakpoints.Laptop, value: 15 },
        { minWidth: Breakpoints.Desktop, value: 20 },
      ],
      paddingLeft: { minWidth: Breakpoints.Laptop, value: '15%' },
      paddingRight: { minWidth: Breakpoints.Laptop, value: '15%' },
      paddingTop: [
        { minWidth: Breakpoints.Laptop, value: 15 },
        { minWidth: Breakpoints.Desktop, value: 20 },
      ],
    },
    'Akurie Header Desktop Header 12': {
      alignContent: { minWidth: Breakpoints.Laptop, value: 'space-between' },
      alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
      backgroundColor: {
        minWidth: Breakpoints.Laptop,
        value: theme.colors['Akurie Dark Blue'],
      },
      flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
      height: [
        { minWidth: Breakpoints.Laptop, value: 120 },
        { minWidth: Breakpoints.BigScreen, value: 105 },
      ],
      justifyContent: { minWidth: Breakpoints.Laptop, value: 'space-between' },
      paddingBottom: [
        { minWidth: Breakpoints.Laptop, value: 15 },
        { minWidth: Breakpoints.Desktop, value: 20 },
      ],
      paddingLeft: { minWidth: Breakpoints.Laptop, value: '15%' },
      paddingRight: { minWidth: Breakpoints.Laptop, value: '15%' },
      paddingTop: [
        { minWidth: Breakpoints.Laptop, value: 15 },
        { minWidth: Breakpoints.Desktop, value: 20 },
      ],
    },
    'Akurie Header Desktop Header 2': {
      alignContent: { minWidth: Breakpoints.Laptop, value: 'space-between' },
      alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
      backgroundColor: {
        minWidth: Breakpoints.Laptop,
        value: theme.colors['Akurie Dark Blue'],
      },
      flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
      height: [
        { minWidth: Breakpoints.Laptop, value: 120 },
        { minWidth: Breakpoints.BigScreen, value: 105 },
      ],
      justifyContent: { minWidth: Breakpoints.Laptop, value: 'space-between' },
      paddingBottom: [
        { minWidth: Breakpoints.Laptop, value: 15 },
        { minWidth: Breakpoints.Desktop, value: 20 },
      ],
      paddingLeft: { minWidth: Breakpoints.Laptop, value: '15%' },
      paddingRight: { minWidth: Breakpoints.Laptop, value: '15%' },
      paddingTop: [
        { minWidth: Breakpoints.Laptop, value: 15 },
        { minWidth: Breakpoints.Desktop, value: 20 },
      ],
    },
    'Akurie Header Desktop Header 3': {
      alignContent: { minWidth: Breakpoints.Laptop, value: 'space-between' },
      alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
      backgroundColor: {
        minWidth: Breakpoints.Laptop,
        value: theme.colors['Akurie Dark Blue'],
      },
      flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
      height: [
        { minWidth: Breakpoints.Laptop, value: 120 },
        { minWidth: Breakpoints.BigScreen, value: 105 },
      ],
      justifyContent: { minWidth: Breakpoints.Laptop, value: 'space-between' },
      paddingBottom: [
        { minWidth: Breakpoints.Laptop, value: 15 },
        { minWidth: Breakpoints.Desktop, value: 20 },
      ],
      paddingLeft: { minWidth: Breakpoints.Laptop, value: '15%' },
      paddingRight: { minWidth: Breakpoints.Laptop, value: '15%' },
      paddingTop: [
        { minWidth: Breakpoints.Laptop, value: 15 },
        { minWidth: Breakpoints.Desktop, value: 20 },
      ],
    },
    'Akurie Header Desktop Header 4': {
      alignContent: { minWidth: Breakpoints.Laptop, value: 'space-between' },
      alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
      backgroundColor: {
        minWidth: Breakpoints.Laptop,
        value: theme.colors['Akurie Dark Blue'],
      },
      flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
      height: [
        { minWidth: Breakpoints.Laptop, value: 120 },
        { minWidth: Breakpoints.BigScreen, value: 105 },
      ],
      justifyContent: { minWidth: Breakpoints.Laptop, value: 'space-between' },
      paddingBottom: [
        { minWidth: Breakpoints.Laptop, value: 15 },
        { minWidth: Breakpoints.Desktop, value: 20 },
      ],
      paddingLeft: { minWidth: Breakpoints.Laptop, value: '15%' },
      paddingRight: { minWidth: Breakpoints.Laptop, value: '15%' },
      paddingTop: [
        { minWidth: Breakpoints.Laptop, value: 15 },
        { minWidth: Breakpoints.Desktop, value: 20 },
      ],
    },
    'Akurie Header Desktop Header 5': {
      alignContent: { minWidth: Breakpoints.Laptop, value: 'space-between' },
      alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
      backgroundColor: {
        minWidth: Breakpoints.Laptop,
        value: theme.colors['Akurie Dark Blue'],
      },
      flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
      height: [
        { minWidth: Breakpoints.Laptop, value: 120 },
        { minWidth: Breakpoints.BigScreen, value: 105 },
      ],
      justifyContent: { minWidth: Breakpoints.Laptop, value: 'space-between' },
      paddingBottom: [
        { minWidth: Breakpoints.Laptop, value: 15 },
        { minWidth: Breakpoints.Desktop, value: 20 },
      ],
      paddingLeft: { minWidth: Breakpoints.Laptop, value: '15%' },
      paddingRight: { minWidth: Breakpoints.Laptop, value: '15%' },
      paddingTop: [
        { minWidth: Breakpoints.Laptop, value: 15 },
        { minWidth: Breakpoints.Desktop, value: 20 },
      ],
    },
    'Akurie Header Desktop Header 6': {
      alignContent: { minWidth: Breakpoints.Laptop, value: 'space-between' },
      alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
      backgroundColor: {
        minWidth: Breakpoints.Laptop,
        value: theme.colors['Akurie Dark Blue'],
      },
      flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
      height: [
        { minWidth: Breakpoints.Laptop, value: 120 },
        { minWidth: Breakpoints.BigScreen, value: 105 },
      ],
      justifyContent: { minWidth: Breakpoints.Laptop, value: 'space-between' },
      paddingBottom: [
        { minWidth: Breakpoints.Laptop, value: 15 },
        { minWidth: Breakpoints.Desktop, value: 20 },
      ],
      paddingLeft: { minWidth: Breakpoints.Laptop, value: '15%' },
      paddingRight: { minWidth: Breakpoints.Laptop, value: '15%' },
      paddingTop: [
        { minWidth: Breakpoints.Laptop, value: 15 },
        { minWidth: Breakpoints.Desktop, value: 20 },
      ],
    },
    'Akurie Header Desktop Header 7': {
      alignContent: { minWidth: Breakpoints.Laptop, value: 'space-between' },
      alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
      backgroundColor: {
        minWidth: Breakpoints.Laptop,
        value: theme.colors['Akurie Dark Blue'],
      },
      flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
      height: [
        { minWidth: Breakpoints.Laptop, value: 120 },
        { minWidth: Breakpoints.BigScreen, value: 105 },
      ],
      justifyContent: { minWidth: Breakpoints.Laptop, value: 'space-between' },
      paddingBottom: [
        { minWidth: Breakpoints.Laptop, value: 15 },
        { minWidth: Breakpoints.Desktop, value: 20 },
      ],
      paddingLeft: { minWidth: Breakpoints.Laptop, value: '15%' },
      paddingRight: { minWidth: Breakpoints.Laptop, value: '15%' },
      paddingTop: [
        { minWidth: Breakpoints.Laptop, value: 15 },
        { minWidth: Breakpoints.Desktop, value: 20 },
      ],
    },
    'Akurie Header Desktop Header 8': {
      alignContent: { minWidth: Breakpoints.Laptop, value: 'space-between' },
      alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
      backgroundColor: {
        minWidth: Breakpoints.Laptop,
        value: theme.colors['Akurie Dark Blue'],
      },
      flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
      height: [
        { minWidth: Breakpoints.Laptop, value: 120 },
        { minWidth: Breakpoints.BigScreen, value: 105 },
      ],
      justifyContent: { minWidth: Breakpoints.Laptop, value: 'space-between' },
      paddingBottom: [
        { minWidth: Breakpoints.Laptop, value: 15 },
        { minWidth: Breakpoints.Desktop, value: 20 },
      ],
      paddingLeft: { minWidth: Breakpoints.Laptop, value: '15%' },
      paddingRight: { minWidth: Breakpoints.Laptop, value: '15%' },
      paddingTop: [
        { minWidth: Breakpoints.Laptop, value: 15 },
        { minWidth: Breakpoints.Desktop, value: 20 },
      ],
    },
    'Akurie Header Desktop Header 9': {
      alignContent: { minWidth: Breakpoints.Laptop, value: 'space-between' },
      alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
      backgroundColor: {
        minWidth: Breakpoints.Laptop,
        value: theme.colors['Akurie Dark Blue'],
      },
      flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
      height: [
        { minWidth: Breakpoints.Laptop, value: 120 },
        { minWidth: Breakpoints.BigScreen, value: 105 },
      ],
      justifyContent: { minWidth: Breakpoints.Laptop, value: 'space-between' },
      paddingBottom: [
        { minWidth: Breakpoints.Laptop, value: 15 },
        { minWidth: Breakpoints.Desktop, value: 20 },
      ],
      paddingLeft: { minWidth: Breakpoints.Laptop, value: '15%' },
      paddingRight: { minWidth: Breakpoints.Laptop, value: '15%' },
      paddingTop: [
        { minWidth: Breakpoints.Laptop, value: 15 },
        { minWidth: Breakpoints.Desktop, value: 20 },
      ],
    },
    'Business Suite Details': {
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginBottom: { minWidth: Breakpoints.Tablet, value: 20 },
      marginTop: { minWidth: Breakpoints.Tablet, value: 20 },
    },
    'EST tax title box': {
      alignItems: 'center',
      borderBottomWidth: 1,
      borderColor: theme.colors['Secondary_2'],
      justifyContent: 'center',
    },
    'Login Header Text': {
      alignItems: 'flex-start',
      justifyContent: { minWidth: Breakpoints.Laptop, value: 'flex-start' },
    },
    'Main Header': {
      alignContent: { minWidth: Breakpoints.Laptop, value: 'space-between' },
      alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
      backgroundColor: {
        minWidth: Breakpoints.Laptop,
        value: theme.colors['Custom Color_38'],
      },
      flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
      justifyContent: { minWidth: Breakpoints.Laptop, value: 'space-between' },
      paddingBottom: { minWidth: Breakpoints.Laptop, value: 15 },
      paddingLeft: { minWidth: Breakpoints.Laptop, value: 30 },
      paddingRight: { minWidth: Breakpoints.Laptop, value: 30 },
      paddingTop: { minWidth: Breakpoints.Laptop, value: 15 },
    },
    'Search Block': {
      backgroundColor: theme.colors['Background'],
      padding: 20,
    },
    'Search Block 2': {
      backgroundColor: theme.colors['Background'],
      padding: 20,
    },
    'Search Block 3': {
      backgroundColor: theme.colors['Background'],
      padding: 20,
    },
    'Tax info boxes': {
      flexDirection: 'row',
      justifyContent: 'center',
      marginBottom: 10,
      marginTop: 10,
      paddingBottom: 5,
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 5,
    },
    'bus pro2': { alignItems: 'center', justifyContent: 'center', padding: 5 },
    bus1: {
      alignItems: 'center',
      backgroundColor: theme.colors['Divider'],
      borderRadius: 9,
      flexDirection: 'column',
      height: 80,
      justifyContent: 'space-between',
      paddingBottom: 10,
      paddingLeft: 25,
      paddingRight: 25,
      paddingTop: 10,
    },
    bus2: {
      alignItems: 'center',
      backgroundColor: theme.colors['Divider'],
      borderRadius: 9,
      flexDirection: 'column',
      height: 80,
      justifyContent: 'space-between',
      paddingBottom: 10,
      paddingLeft: 25,
      paddingRight: 25,
      paddingTop: 10,
    },
    'business profile changer': {
      marginBottom: 15,
      marginTop: 15,
      paddingLeft: 35,
      paddingRight: 35,
    },
    'business profile selector': {
      marginBottom: 15,
      marginTop: 15,
      paddingLeft: 35,
      paddingRight: 35,
    },
    business_info_card: {
      alignItems: 'flex-start',
      backgroundColor: theme.colors['Custom Color_20'],
      borderRadius: 9,
      justifyContent: 'space-evenly',
      marginBottom: 8,
      marginTop: 8,
      paddingBottom: 15,
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 15,
    },
    'fed filings': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderBottomWidth: 1,
      borderColor: theme.colors.studilyGray3,
      borderLeftWidth: 1,
      borderRadius: 6,
      borderRightWidth: 1,
      borderTopWidth: 1,
      marginBottom: 18,
      marginTop: 10,
      paddingBottom: 6,
      paddingLeft: 3,
      paddingRight: 3,
    },
    'fed filings 2': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderBottomWidth: 1,
      borderColor: theme.colors.studilyGray3,
      borderLeftWidth: 1,
      borderRadius: 6,
      borderRightWidth: 1,
      borderTopWidth: 1,
      marginBottom: 18,
      marginTop: 10,
      paddingBottom: 6,
      paddingLeft: 3,
      paddingRight: 3,
    },
    'goal layout view': {
      alignItems: 'center',
      borderBottomWidth: 1,
      borderColor: theme.colors['Akurie Primary'],
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 10,
      paddingBottom: 10,
    },
    'header picker view': {
      alignItems: 'center',
      backgroundColor: theme.colors['Divider'],
      borderRadius: 9,
      flexDirection: 'column',
      height: 80,
      justifyContent: 'space-between',
      paddingBottom: 10,
      paddingLeft: 25,
      paddingRight: 25,
      paddingTop: 10,
    },
    'logo header holder': {
      justifyContent: 'space-evenly',
      opacity: 0.92,
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 24,
    },
    'lower nav menu': {
      backgroundColor: theme.colors['Background'],
      flexDirection: 'row',
      height: 70,
    },
    'lower nav menu 2': {
      backgroundColor: theme.colors['Background'],
      flexDirection: 'row',
      height: 70,
    },
    'new logo box': {
      alignItems: 'center',
      justifyContent: 'center',
      width: '50%',
    },
    'plaid buttons': {
      backgroundColor: '"rgba(0, 0, 0, 0)"',
      paddingBottom: 36,
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 24,
    },
    'stat boxes': {
      alignItems: 'center',
      backgroundColor: theme.colors['Akurie Dark Blue'],
      borderColor: theme.colors['Akurie green'],
      borderTopWidth: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      paddingBottom: 20,
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 20,
      width: '100%',
    },
    'state filings': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderRadius: 6,
      marginBottom: 5,
      marginTop: 5,
      paddingBottom: 6,
      paddingLeft: 3,
      paddingRight: 3,
      paddingTop: 6,
    },
    statepicker: { paddingBottom: 5, paddingTop: 5, width: '100%' },
    'styled bus pro changer': { marginTop: 20 },
    'styled profile chg updated': { marginTop: 20 },
    'tax menu navigator': {
      backgroundColor: theme.colors['Communial_Icon_Dark'],
      flexDirection: 'row',
      height: 70,
    },
  });

export const TextStyles = theme => StyleSheet.create({});

export const FetchStyles = theme =>
  StyleSheet.create({ Fetch: { minHeight: 40 } });

export const FlatListStyles = theme => StyleSheet.create({ List: { flex: 1 } });

export const ButtonStyles = theme =>
  StyleSheet.create({
    Button: {
      backgroundColor: theme.colors.primary,
      fontFamily: 'System',
      fontWeight: '700',
      textAlign: 'center',
    },
  });

export const ActivityIndicatorStyles = theme =>
  StyleSheet.create({ 'Activity Indicator': { height: 36, width: 36 } });

export const WebViewStyles = theme =>
  StyleSheet.create({ 'HTML View': { flex: 1 }, 'Web View': { flex: 1 } });

export const SurfaceStyles = theme =>
  StyleSheet.create({ Surface: { minHeight: 40 } });

export const ImageBackgroundStyles = theme => StyleSheet.create({});

export const ImageStyles = theme =>
  StyleSheet.create({ Image: { width: 100 } });

export const TextInputStyles = theme =>
  StyleSheet.create({
    'Text Area': {
      borderBottomWidth: 1,
      borderRadius: 8,
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
    'Text Input': {
      backgroundColor: theme.colors['Background'],
      borderBottomWidth: 1,
      borderColor: theme.colors.divider,
      borderRadius: 3,
      fontFamily: 'Inter_400Regular',
      minHeight: 60,
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
  });

export const LinkStyles = theme =>
  StyleSheet.create({ Link: { color: theme.colors.primary } });

export const DeckSwiperStyles = theme =>
  StyleSheet.create({ 'Deck Swiper': { position: 'absolute' } });

export const DeckSwiperCardStyles = theme =>
  StyleSheet.create({
    'Deck Swiper Card': {
      alignItems: 'center',
      borderWidth: 2,
      justifyContent: 'center',
      padding: 20,
    },
  });

export const SwipeableItemStyles = theme =>
  StyleSheet.create({ 'Swipeable Item': { overflow: 'hidden' } });

export const CheckboxRowStyles = theme =>
  StyleSheet.create({ 'Checkbox Row': { minHeight: 50 } });

export const TableStyles = theme => StyleSheet.create({ Table: { flex: 1 } });

export const TableCellStyles = theme =>
  StyleSheet.create({ 'Table Cell': { flex: 1, flexDirection: 'row' } });

export const BlurViewStyles = theme =>
  StyleSheet.create({
    'Blur View': { flexBasis: 0, flexGrow: 1, flexShrink: 1 },
    'delete blur confirm': { height: '100%', width: '100%' },
  });

export const DividerStyles = theme =>
  StyleSheet.create({ Divider: { height: 1 } });

export const CircleStyles = theme =>
  StyleSheet.create({
    Circle: { alignItems: 'center', justifyContent: 'center' },
  });

export const ActionSheetItemStyles = theme =>
  StyleSheet.create({ 'Action Sheet Item': { textAlign: 'center' } });

export const H4Styles = theme =>
  StyleSheet.create({
    H4: { color: theme.colors.strong, fontSize: 16, fontWeight: 'bold' },
  });

export const H5Styles = theme =>
  StyleSheet.create({
    H5: { color: theme.colors.strong, fontSize: 13.28, fontWeight: 'bold' },
  });

export const LinearGradientStyles = theme => StyleSheet.create({});

export const PinInputStyles = theme =>
  StyleSheet.create({
    'Pin Input': {
      alignItems: 'center',
      borderColor: theme.colors.medium,
      borderRadius: 5,
      borderWidth: 1,
      color: theme.colors.strong,
      flex: 1,
      fontSize: 25,
      justifyContent: 'center',
      marginLeft: 5,
      marginRight: 5,
      maxHeight: 70,
      maxWidth: 70,
      padding: 5,
    },
  });

export const NumberInputStyles = theme =>
  StyleSheet.create({
    'Number Input': {
      backgroundColor: theme.colors['Background'],
      borderBottomWidth: 1,
      borderRadius: 3,
      fontFamily: 'Inter_400Regular',
      height: 60,
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
  });
