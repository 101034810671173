import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AuthAkurieApi from '../apis/AuthAkurieApi.js';
import DesktopHeader13024Block from '../components/DesktopHeader13024Block';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Circle,
  Icon,
  ScreenContainer,
  Surface,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ImageBackground, StatusBar, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const PasswordChangeScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [auth_needed, setAuth_needed] = React.useState(true);
  const [classAccess1, setClassAccess1] = React.useState(true);
  const [classAccess2, setClassAccess2] = React.useState(false);
  const [classAccess3, setClassAccess3] = React.useState(false);
  const [classAccess4, setClassAccess4] = React.useState(false);
  const [confirmNewPasswordInputValue, setConfirmNewPasswordInputValue] =
    React.useState('');
  const [currentPasswordInputValue, setCurrentPasswordInputValue] =
    React.useState('');
  const [datePickerValue, setDatePickerValue] = React.useState(new Date());
  const [entercurrpassValue, setEntercurrpassValue] = React.useState('');
  const [error_msg, setError_msg] = React.useState('');
  const [error_msg_auth, setError_msg_auth] = React.useState('');
  const [newPasswordInputValue, setNewPasswordInputValue] = React.useState('');
  const [numberInputValue, setNumberInputValue] = React.useState('');
  const [password_guide, setPassword_guide] = React.useState(false);
  const [pickerValue, setPickerValue] = React.useState('');
  const [success_msg, setSuccess_msg] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState('');
  const [verifyPass, setVerifyPass] = React.useState(false);
  const authAkurieNewPasswordPOST = AuthAkurieApi.useNewPasswordPOST();
  const authAkurieUserPasswordUpdatePATCH =
    AuthAkurieApi.useUserPasswordUpdatePATCH();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        setGlobalVariableValue({
          key: 'ak_errorMessage',
          value: '',
        });
        const authResponse = (await AuthAkurieApi.meGET(Constants))?.json;
        const tokenExpire = authResponse?.message;
        if (tokenExpire) {
          navigation.navigate('SessionExpiredScreen');
        }
        if (tokenExpire) {
          return;
        }
        setAuth_needed(true);
        StatusBar.setBarStyle('dark-content');
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors.studilyWhite4 },
        dimensions.width
      )}
    >
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <DesktopHeader13024Block />
        )}
      </>
      {/* Navigation Frame */}
      <View
        style={StyleSheet.applyWidth(
          {
            borderBottomWidth: 2,
            borderColor: theme.colors['Akurie green'],
            height: 100,
          },
          dimensions.width
        )}
      >
        <ImageBackground
          resizeMode={'cover'}
          source={Images.Asset13}
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              backgroundColor: theme.colors['Akurie Dark Blue'],
              borderBottomWidth: 1,
              borderColor: theme.colors['Akurie green'],
              flexDirection: 'row',
              height: '100%',
              justifyContent: 'space-between',
            },
            dimensions.width
          )}
        >
          {/* Left Side Frame */}
          <>
            {success_msg ? null : (
              <View
                style={StyleSheet.applyWidth(
                  { paddingLeft: 9, paddingRight: 9 },
                  dimensions.width
                )}
              />
            )}
          </>
          {/* Middle Frame */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: 'rgba(8, 31, 61, 0.5)',
                flexGrow: 1,
                flexShrink: 0,
                justifyContent: 'center',
              },
              dimensions.width
            )}
          >
            {/* Text Frame  */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexGrow: 0,
                  flexShrink: 0,
                  paddingBottom: 12,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 12,
                },
                dimensions.width
              )}
            >
              {/* Text 12 18 Regular */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Strong Inverse'],
                    fontFamily: 'Inter_400Regular',
                    fontSize: 22,
                    lineHeight: 18,
                    paddingTop: 8,
                  },
                  dimensions.width
                )}
              >
                {'Password & Security'}
              </Text>
            </View>
          </View>
          {/* Left Side Frame */}
          <View
            style={StyleSheet.applyWidth(
              { paddingLeft: 9, paddingRight: 9 },
              dimensions.width
            )}
          />
        </ImageBackground>
      </View>

      <ImageBackground
        resizeMode={'stretch'}
        style={StyleSheet.applyWidth(
          { height: '100%', width: '100%' },
          dimensions.width
        )}
      >
        <KeyboardAwareScrollView
          contentContainerStyle={StyleSheet.applyWidth(
            {
              paddingLeft: [
                { minWidth: Breakpoints.Laptop, value: '10%' },
                { minWidth: Breakpoints.Desktop, value: '15%' },
                { minWidth: Breakpoints.BigScreen, value: '20%' },
              ],
              paddingRight: [
                { minWidth: Breakpoints.Laptop, value: '10%' },
                { minWidth: Breakpoints.Desktop, value: '15%' },
                { minWidth: Breakpoints.BigScreen, value: '20%' },
              ],
            },
            dimensions.width
          )}
          extraScrollHeight={200}
          keyboardShouldPersistTaps={'never'}
          showsVerticalScrollIndicator={true}
        >
          {/* General Settings Frame */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignSelf: { minWidth: Breakpoints.Desktop, value: 'center' },
                backgroundColor: 'rgba(0, 0, 0, 0)',
                borderRadius: 0,
                marginBottom: 18,
                paddingBottom: 6,
                paddingLeft: 6,
                paddingRight: 6,
                paddingTop: 6,
                width: { minWidth: Breakpoints.Desktop, value: '50%' },
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  paddingBottom: 15,
                  paddingLeft: 15,
                  paddingRight: 15,
                  paddingTop: 8,
                },
                dimensions.width
              )}
            >
              <View>
                {/* View 2 */}
                <>
                  {!success_msg ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginTop: 50,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={theme.colors['Akurie green']}
                        name={'AntDesign/checkcircleo'}
                        size={160}
                      />
                    </View>
                  )}
                </>
                <>
                  {!password_guide ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['Custom Color_18'],
                          borderRadius: 9,
                          marginBottom: 15,
                          marginTop: 25,
                          paddingBottom: 10,
                          paddingLeft: 35,
                          paddingRight: 35,
                          paddingTop: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Akurie Primary'],
                            fontFamily: 'Inter_600SemiBold',
                            fontSize: 16,
                            marginBottom: 8,
                          },
                          dimensions.width
                        )}
                      >
                        {'Create A Strong Password:'}
                      </Text>

                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Strong Inverse'],
                            fontFamily: 'Inter_400Regular',
                            marginBottom: 8,
                          },
                          dimensions.width
                        )}
                      >
                        {
                          'Password security starts with creating a strong password.\n\nMinimum of 10 Characters.\n\nRequires:\nAn Uppercase Letter\nA Lowercase Letter \nA Number \nA Symbol'
                        }
                      </Text>
                    </View>
                  )}
                </>
                <>
                  {!success_msg ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', justifyContent: 'center' },
                        dimensions.width
                      )}
                    >
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Akurie Primary'],
                            fontFamily: 'System',
                            fontSize: 22,
                            fontWeight: '600',
                            paddingTop: 10,
                            textAlign: 'center',
                          },
                          dimensions.width
                        )}
                      >
                        {'Your password has been \nchanged successfully. '}
                      </Text>
                      <Button
                        onPress={() => {
                          try {
                            navigation.navigate('RootNavigator');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ButtonStyles(theme)['Button'],
                            {
                              backgroundColor: theme.colors['Akurie green'],
                              color: theme.colors['Akurie Dark Blue'],
                              fontFamily: 'Inter_600SemiBold',
                              marginTop: 20,
                            }
                          ),
                          dimensions.width
                        )}
                        title={'Return to Dashboard'}
                      />
                    </View>
                  )}
                </>
                <>
                  {!auth_needed ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginTop: 120,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={theme.colors['Akurie Primary']}
                        name={'MaterialCommunityIcons/account-lock'}
                        size={150}
                      />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              fontFamily: 'Inter_600SemiBold',
                              marginTop: 20,
                              textAlign: 'center',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {
                          'Before you can continue please \nenter your password.'
                        }
                      </Text>
                    </View>
                  )}
                </>
                {/* Change-Form */}
                <>
                  {success_msg ? null : (
                    <Surface
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: 1,
                          borderColor: theme.colors.divider,
                          borderLeftWidth: 1,
                          borderRadius: 10,
                          borderRightWidth: 1,
                          borderTopWidth: 1,
                          justifyContent: 'center',
                          marginTop: 30,
                          minHeight: 40,
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            borderBottomWidth: 1,
                            borderColor: theme.colors.divider,
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            marginBottom: 8,
                            marginTop: 8,
                            minHeight: 80,
                            paddingBottom: 12,
                            paddingLeft: 30,
                            paddingRight: 30,
                            paddingTop: 12,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Password View 2 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              marginBottom: 10,
                              paddingBottom: 5,
                              paddingTop: 5,
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        >
                          {/* New Password */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Medium'],
                                fontFamily: 'Inter_600SemiBold',
                              },
                              dimensions.width
                            )}
                          >
                            {'New Password'}
                          </Text>
                          {/* New Password Input */}
                          <TextInput
                            onChangeText={newNewPasswordInputValue => {
                              try {
                                setNewPasswordInputValue(
                                  newNewPasswordInputValue
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            placeholder={'Enter new password'}
                            placeholderTextColor={
                              theme.colors['Communial_Icon_Gray']
                            }
                            secureTextEntry={true}
                            spellcheck={true}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Background'],
                                borderBottomWidth: 1,
                                borderColor: theme.colors['Medium'],
                                borderRadius: 0,
                                fontFamily: 'Inter_400Regular',
                                height: 50,
                                paddingBottom: 10,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 10,
                              },
                              dimensions.width
                            )}
                            textContentType={'password'}
                            value={newPasswordInputValue}
                          />
                        </View>
                      </View>

                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            borderColor: theme.colors.divider,
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            marginBottom: 15,
                            marginTop: 8,
                            minHeight: 80,
                            paddingBottom: 12,
                            paddingLeft: 30,
                            paddingRight: 30,
                            paddingTop: 12,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Password View 3 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              marginBottom: 10,
                              paddingBottom: 5,
                              paddingTop: 5,
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        >
                          {/* Confirm New Password */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Medium'],
                                fontFamily: 'Inter_600SemiBold',
                              },
                              dimensions.width
                            )}
                          >
                            {'Confirm New Password'}
                          </Text>
                          {/* Confirm New Password Input */}
                          <TextInput
                            onChangeText={newConfirmNewPasswordInputValue => {
                              try {
                                setConfirmNewPasswordInputValue(
                                  newConfirmNewPasswordInputValue
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            placeholder={'Confirm new password'}
                            placeholderTextColor={
                              theme.colors['Communial_Icon_Gray']
                            }
                            secureTextEntry={true}
                            spellcheck={true}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Background'],
                                borderBottomWidth: 1,
                                borderColor: theme.colors['Medium'],
                                borderRadius: 0,
                                fontFamily: 'Inter_400Regular',
                                height: 50,
                                paddingBottom: 10,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 10,
                              },
                              dimensions.width
                            )}
                            textContentType={'password'}
                            value={confirmNewPasswordInputValue}
                          />
                        </View>
                      </View>
                      <>
                        {verifyPass ? null : (
                          <Button
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  const passchangeResponse = (
                                    await authAkurieNewPasswordPOST.mutateAsync(
                                      {
                                        Password: newPasswordInputValue,
                                        Password_Confirm:
                                          confirmNewPasswordInputValue,
                                      }
                                    )
                                  )?.json;
                                  const errorMsg = passchangeResponse?.message;
                                  setGlobalVariableValue({
                                    key: 'ak_errorMessage',
                                    value: errorMsg,
                                  });
                                  if (errorMsg) {
                                    return;
                                  }
                                  setGlobalVariableValue({
                                    key: 'SKYE_AUTHORIZATION_HEADER',
                                    value: false,
                                  });
                                  navigation.navigate('LoginScreen');
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ButtonStyles(theme)['Button'],
                                {
                                  backgroundColor:
                                    theme.colors['Akurie Dark Blue'],
                                  borderRadius: 9,
                                  color: theme.colors['Akurie green'],
                                  fontFamily: 'Inter_500Medium',
                                  fontSize: 16,
                                  height: 50,
                                }
                              ),
                              dimensions.width
                            )}
                            title={'Continue'}
                          />
                        )}
                      </>
                      {/* Button 2 */}
                      <>
                        {!verifyPass ? null : (
                          <Button
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  const passupdateResponse = (
                                    await authAkurieUserPasswordUpdatePATCH.mutateAsync(
                                      {
                                        confirm_password:
                                          confirmNewPasswordInputValue,
                                        new_password: newPasswordInputValue,
                                        password: entercurrpassValue,
                                        user_id: Constants['ak_userID'],
                                      }
                                    )
                                  )?.json;
                                  const errorMsg = passupdateResponse?.message;
                                  setGlobalVariableValue({
                                    key: 'ak_errorMessage',
                                    value: errorMsg,
                                  });
                                  if (errorMsg) {
                                    return;
                                  }
                                  setGlobalVariableValue({
                                    key: 'ak_errorMessage',
                                    value: false,
                                  });
                                  navigation.navigate('RootNavigator');
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ButtonStyles(theme)['Button'],
                                {
                                  backgroundColor: theme.colors['Akurie green'],
                                  color: theme.colors['Strong'],
                                  fontFamily: 'Inter_500Medium',
                                  fontSize: 16,
                                  height: 50,
                                }
                              ),
                              dimensions.width
                            )}
                            title={'Continue'}
                          />
                        )}
                      </>
                    </Surface>
                  )}
                </>
                {/* Form */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      marginBottom: 8,
                      marginTop: 8,
                      opacity: 0.8,
                      paddingLeft: 20,
                      paddingRight: 20,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Error'],
                        fontFamily: 'Inter_400Regular',
                        paddingTop: 10,
                      },
                      dimensions.width
                    )}
                  >
                    {Constants['ak_errorMessage']}
                  </Text>
                </View>
              </View>
              <>
                {success_msg ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        paddingBottom: 40,
                        paddingLeft: 40,
                        paddingRight: 40,
                        paddingTop: 10,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Button */}
                    <>
                      {auth_needed ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', borderRadius: 0 },
                            dimensions.width
                          )}
                        >
                          {/* Touchable Frame */}
                          <View
                            style={StyleSheet.applyWidth(
                              { zIndex: 10 },
                              dimensions.width
                            )}
                          >
                            <Touchable
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    const pass_update = (
                                      await authAkurieUserPasswordUpdatePATCH.mutateAsync(
                                        {
                                          confirm_password:
                                            confirmNewPasswordInputValue,
                                          new_password: newPasswordInputValue,
                                          password: entercurrpassValue,
                                        }
                                      )
                                    )?.json;
                                    const error_msg = pass_update?.message;
                                    if (error_msg) {
                                      return;
                                    }
                                    setError_msg(error_msg);
                                    setSuccess_msg(true);
                                    setPassword_guide(false);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                              style={StyleSheet.applyWidth(
                                { borderRadius: 0 },
                                dimensions.width
                              )}
                            >
                              {/* CTA Frame */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    backgroundColor:
                                      theme.colors['Akurie Primary'],
                                    borderRadius: 12,
                                    flexDirection: 'row',
                                    paddingBottom: 12,
                                    paddingRight: 12,
                                    paddingTop: 12,
                                    width: 300,
                                    zIndex: 10,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Label Frame */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      flexGrow: 1,
                                      flexShrink: 0,
                                      justifyContent: 'center',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Label */}
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      {
                                        color: theme.colors['Strong Inverse'],
                                        fontFamily: 'Inter_700Bold',
                                        fontSize: 15,
                                        lineHeight: 21,
                                        marginLeft: 42,
                                        textTransform: 'uppercase',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'Update Password'}
                                  </Text>
                                </View>
                                {/* Icon Group Frame */}
                                <View>
                                  <Circle
                                    bgColor={theme.colors['Custom Color_18']}
                                    size={42}
                                  >
                                    {/* Icon Flex Frame */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          flexGrow: 0,
                                          flexShrink: 0,
                                          paddingBottom: 12,
                                          paddingLeft: 12,
                                          paddingRight: 12,
                                          paddingTop: 12,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Icon  */}
                                      <Icon
                                        color={theme.colors['Custom Color_19']}
                                        name={'AntDesign/arrowright'}
                                        size={18}
                                      />
                                    </View>
                                  </Circle>
                                </View>
                              </View>
                            </Touchable>
                          </View>
                        </View>
                      )}
                    </>
                  </View>
                )}
              </>
            </View>
          </View>
          <Surface
            style={StyleSheet.applyWidth(
              {
                backgroundColor: 'rgba(0, 0, 0, 0)',
                borderRadius: 6,
                minHeight: 40,
                opacity: 0.88,
              },
              dimensions.width
            )}
          />
        </KeyboardAwareScrollView>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(PasswordChangeScreen);
